import { DependencyList, useEffect, useMemo, useRef } from 'react';
import { differenceWith, isEqual } from 'lodash';
import shallowequal from 'shallowequal';
import Debug from 'debug';

import { Tool } from './tool';
import { ToolContext } from './tool-context';

const debug = Debug('basic.arg-toolbar.UseToolItems');

export function useToolItems<T>(toolContext: ToolContext<T> | undefined, computeTools: () => Tool<T>[], deps?: DependencyList): void {
    const previousItemsRef = useRef<Tool<T>[]>();

    const tools = useMemo<Tool<T>[]>(() => {
        const tools = computeTools();

        debug('useToolItems', 'Memo Tools=', tools);

        return tools;
    }, [computeTools, deps]);

    useEffect(() => {
        if (!toolContext) {
            return;
        }

        previousItemsRef.current = [...tools];
        tools.forEach((tool) => {
            debug('useToolItems', 'Add tools=', tool);
            toolContext.addItem(tool, undefined);
        });

        return () => {
            tools.forEach((tool) => {
                debug('useToolItems', 'Remove tools=', tool);
                toolContext.removeItem(tool);
            });
        };
    }, [toolContext]);

    useEffect(() => {
        if (isEqual(tools, previousItemsRef.current) || !toolContext) {
            debug('useToolItems', 'No modifications');

            return;
        }

        if (!previousItemsRef.current) {
            previousItemsRef.current = [...tools];
            tools.forEach((tool) => {
                debug('useToolItems', 'ADD tool=', tool);
                toolContext.addItem(tool, undefined);
            });

            return;
        }

        const diff = differenceWith(tools, previousItemsRef.current, (item1, item2) => shallowequal(item1, item2));
        debug('useToolItems', 'Diff=', diff);

        previousItemsRef.current = [...tools];
        diff.forEach((tool) => {
            debug('useToolItems', 'Update', tool);
            toolContext.updateItem(tool, tool);
        });
    }, [tools]);
}
