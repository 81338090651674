import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Range from '@ckeditor/ckeditor5-engine/src/view/range';
import Debug from 'debug';

import { VERTICES_DND_TYPE, VerticesDndDataTransfer } from '../../../../model/exploration-dnd-types';
import { fillFromFilter } from '../../../../common/data-transfer';
import { UniverseConnector } from '../../../../utils/connector/universe-connector';
import { createFilterFromObjectId } from '../../../../utils/constraints';

const debug = Debug('argonode:exploration:brief:VertexClipboard');

export function vertexClipboardInstall(plugin: Plugin) {
    const editor = plugin.editor;
    const view = editor.editing.view;
    const viewDocument = view.document;

    // Processing pasted or dropped content.
    plugin.listenTo(viewDocument, 'clipboardInput', (evt, data) => {
        if (data.content) {
            return;
        }

        const jsonVerticesData = data.dataTransfer.getData(VERTICES_DND_TYPE);

        if (!jsonVerticesData) {
            return;
        }

        const verticesData: VerticesDndDataTransfer = JSON.parse(jsonVerticesData);

        UniverseConnector.getInstance().getUniverseVertices(verticesData.universeId, verticesData.filter, 20).then((result) => {
            editor.model.change(writer => {
                if (data.targetRanges) {
                    writer.setSelection(data.targetRanges.map((viewRange: StaticRange) => {
                        return editor.editing.mapper.toModelRange(viewRange as any as Range);
                    }));
                }

                result.forEach((vertex) => {
                    const attrs = {
                        id: `id${Date.now()}-${vertex.id}`,
                        vertexId: vertex.id,
                        caseId: verticesData.fromCaseId,
                        universeId: verticesData.universeId,
                    };
                    editor.execute('insertArgonos-vertex', { detail: attrs });
                });
            });
        });

        evt.stop();
    });

    // Processing copied, pasted or dragged content.
    plugin.listenTo(viewDocument, 'clipboardOutput', (evt, data) => {
        debug('clipboardOutput', 'Data=', data);
        if (data.content.childCount != 1) {
            return;
        }

        const viewElement = data.content.getChild(0);
        debug('clipboardOutput', 'ViewElement=', viewElement, viewElement.getCustomProperty('argonos-vertex'));
        if (!viewElement.getCustomProperty('argonos-vertex')) {
            return;
        }

        const vertexId = viewElement.getAttribute('data-vertex-id');
        const fromExplorationId = viewElement.getAttribute('data-from-exploration-id');
        const fromCaseId = viewElement.getAttribute('data-from-case-id');
        const universeId = viewElement.getAttribute('data-universe-id');

        const filter = createFilterFromObjectId(vertexId);

        fillFromFilter(data.dataTransfer, undefined, filter, universeId, fromExplorationId, fromCaseId, 1);
    });
}
