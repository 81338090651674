import { useCallback, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import * as UUID from 'uuid';

import {
    ArgButton,
    ClassValue,
    SelectionProvider,
    useClassNames,
} from 'src/components/basic';
import { FormElement, FormElementId, FormTab, FormTabs } from '../../../../../components/common/forms/model';
import { FormActionsEngine } from '../../actions/form-actions-engine';
import { useFormRepository } from '../../use-form-repository';
import { createFormRenamePageElement } from '../../actions/rename-page';
import { createFormRemovePageElements } from '../../actions/remove-page';
import { createFormAddPageElements } from '../../actions/add-page';
import { createFormMovePageElement } from '../../actions/move-page';
import { FormPagesPanelItem } from './form-pages-panel-item';
import { FAKE_TAB_ID } from '../../utils';

import './form-pages-panel.less';

const messages = defineMessages({
    title: {
        id: 'exploration.forms.form-pages-panel.Title',
        defaultMessage: 'Pages',
    },
    addPage: {
        id: 'exploration.forms.form-pages-panel.AddPage',
        defaultMessage: 'Add page',
    },
    pageName: {
        id: 'exploration.forms.form-pages-panel.firstPageName',
        defaultMessage: 'Page {nb}',
    },
});

interface FormItemsPanelProps {
    className?: ClassValue;

    formActionsEngine: FormActionsEngine;
    selectionProvider?: SelectionProvider<FormElement>;
}

export function FormPagesPanel(props: FormItemsPanelProps) {
    const {
        className,
        formActionsEngine,
        selectionProvider,
    } = props;

    const intl = useIntl();

    const classNames = useClassNames('exploration-form-pages-panel');

    const formDocument = useFormRepository(formActionsEngine.repository);

    const children = formDocument.children;

    const computeTabs = useCallback(() => {
        const tabsElement = children.find((node): node is FormTabs => node.type === 'tabs');
        if (!tabsElement) {
            const tab: FormTab = {
                id: FAKE_TAB_ID,
                type: 'tab',
                name: intl.formatMessage(messages.pageName, { nb: 1 }),
                children: [],
            };

            return [tab];
        }

        return tabsElement.children as FormTab[];
    }, [children, intl]);

    const [editedTab, setEditedTab] = useState<FormTab>();
    const [tabs, setTabs] = useState(computeTabs);

    useEffect(() => {
        setTabs(computeTabs());
    }, [computeTabs]);

    const handleRemovePage = useCallback((tabId: FormElementId) => {
        const tab = tabs.find((tab) => tab.id === tabId);
        if (!tab) {
            return;
        }
        const action = createFormRemovePageElements(formDocument, [tab], selectionProvider);

        formActionsEngine.do(action).catch((error) => {
            console.error(error);
        });
    }, [formActionsEngine, formDocument, selectionProvider, tabs]);

    const handleTabNameChange = useCallback((name: string | null, tab: FormTab) => {
        if (!name) {
            return;
        }
        const action = createFormRenamePageElement(tab, name, selectionProvider);

        formActionsEngine.do(action).catch((error) => {
            console.error(error);
        });
    }, [formActionsEngine, selectionProvider]);

    const handleAddPage = useCallback((index?: number) => {
        const pageNumber = (index ?? tabs.length) + 1;
        const tab: FormTab = {
            id: UUID.v4(),
            type: 'tab',
            name: intl.formatMessage(messages.pageName, { nb: pageNumber }),
            children: [],
        };

        const defaultPageName = intl.formatMessage(messages.pageName, { nb: 1 });

        const action = createFormAddPageElements(formDocument, [tab], index, selectionProvider, defaultPageName);

        formActionsEngine.do(action).catch((error) => {
            console.error(error);
        });

        setEditedTab(tab);
    }, [formDocument, formActionsEngine, intl, selectionProvider, tabs.length]);

    const movePolicies = (dragIndex: number, hoverIndex: number) => {
        const swapTabs = [...tabs];
        [ swapTabs[dragIndex], swapTabs[hoverIndex] ] = [ swapTabs[hoverIndex], swapTabs[dragIndex] ];
        setTabs(swapTabs);
    };

    const onReorder = (dragItem: { id: string | number; index: number }) => {
        const tabsElement = children.find((node): node is FormTabs => node.type === 'tabs');
        const draggedTab = tabs.find((tab) => tab.id === dragItem.id);
        if (dragItem.index !== undefined && tabsElement && draggedTab) {
            const action = createFormMovePageElement(tabsElement, draggedTab, dragItem.index, selectionProvider);

            formActionsEngine.do(action).catch((error) => {
                console.error(error);
            });
        }
    };

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-title')}>
                <FormattedMessage {...messages.title} />
            </div>

            <div className={classNames('&-body')}>
                <ul className={classNames('&-body-list')}>
                    {tabs.map((tab, index) => (
                        <FormPagesPanelItem
                            key={tab.id}
                            tab={tab}
                            index={index}
                            movePolicies={movePolicies}
                            onReorder={onReorder}
                            handleRemovePage={handleRemovePage}
                            handleTabNameChange={handleTabNameChange}
                            editMode={editedTab === tab}
                            setEditMode={setEditedTab}
                        />
                    ))}
                </ul>
                <ArgButton
                    type='link'
                    size='medium'
                    icon='icon-plus'
                    className={classNames('&-add-button')}
                    onClick={() => handleAddPage()}
                    label={messages.addPage}
                />
            </div>
        </div>
    );
}
