import { defineMessages, MessageDescriptor } from 'react-intl';

import { getProgrammingLanguageFileExtension } from './utils';

const messages = defineMessages({
    csharp: {
        id: 'preparation.expression-language.constants.CSharp',
        defaultMessage: 'C#',
    },
    python: {
        id: 'preparation.expression-language.constants.Python',
        defaultMessage: 'Python',
    },
    javascript: {
        id: 'preparation.expression-language.constants.Javascript',
        defaultMessage: 'Javascript',
    },
    xml: {
        id: 'preparation.expression-language.constants.Xml',
        defaultMessage: 'Xml',
    },
});

export enum ExpressionLanguage {
    CSharp = 'csharp',
    Python = 'python',
    Javascript = 'javascript',
    Xml = 'xml',
}

export interface ExpressionLanguageDescription {
    label: MessageDescriptor;
    icon: string;
    extension: string;
}

export const EXPRESSION_LANGUAGE_CONFIG_PATH = 'preparation.expression-language.default';

export const DEFAULT_EXPRESSION_LANGUAGE: ExpressionLanguage = ExpressionLanguage.Python;

export const EXPRESSION_LANGUAGES: Record<ExpressionLanguage, ExpressionLanguageDescription> = {
    [ExpressionLanguage.CSharp]: {
        label: messages.csharp,
        icon: 'icon-language-csharp',
        extension: '.cs',
    },
    [ExpressionLanguage.Python]: {
        label: messages.python,
        icon: 'icon-language-python',
        extension: '.py',
    },
    [ExpressionLanguage.Javascript]: {
        label: messages.javascript,
        icon: 'icon-language-javascript',
        extension: '.js',
    },
    [ExpressionLanguage.Xml]: {
        label: messages.xml,
        icon: 'icon-language-xml',
        extension: '.xml',
    },
};

export const SUPPORTED_EXPRESSION_LANGUAGES: ExpressionLanguage[] = [
    ExpressionLanguage.Python,
    ExpressionLanguage.CSharp,
    ExpressionLanguage.Javascript,
];

export const SUPPORTED_EXPRESSION_LANGUAGES_FILE_EXT = SUPPORTED_EXPRESSION_LANGUAGES.map(getProgrammingLanguageFileExtension);
