import { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { FormikHelpers, useFormik } from 'formik';

import {
    ArgButton,
    ArgInputSearch,
    ArgInputText,
    ArgInputTextArea,
    ArgModal,
    ArgTabKey,
    ArgTabsSubLevel,
    ProgressMonitor,
    SubProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { ArgFormLabel } from '../../../../components/basic';
import { UsersList } from '../users-list';
import { GroupsList } from '../groups-list';
import { AddGroupDTO, Role, RolesScope } from 'src/settings/models/dtoApi';
import { getRolesFromRoleIds } from 'src/settings/users/utils';
import { Group, User } from 'src/model/user';
import { AssignRoles } from 'src/settings/roles/components/assign-roles';
import userGroupsConnector from 'src/settings/connectors/user-groups-connector';
import { SettingsConnector } from '../../../connectors/settings-connector';

import './create-group-modal.less';

export const messages = defineMessages({
    title: {
        id: 'settings.create-group-modal.title',
        defaultMessage: 'Create New Group',
    },
    description: {
        id: 'settings.create-group-modal.description',
        defaultMessage:
            'Please supply the following information. The fields with an asterisk are required',
    },
    fieldName: {
        id: 'settings.create-group-modal.field.name',
        defaultMessage: 'Name',
    },
    required: {
        id: 'settings.create-group-modal.required',
        defaultMessage: 'Required',
    },
    fieldDescription: {
        id: 'settings.create-group-modal.field.description',
        defaultMessage: 'Description',
    },
    fieldRoles: {
        id: 'settings.create-group-modal.field.roles',
        defaultMessage: 'Roles',
    },
    submit: {
        id: 'settings.create-group-modal.submitButton',
        defaultMessage: 'Create',
    },
    cancel: {
        id: 'settings.create-group-modal.cancelButton',
        defaultMessage: 'Cancel',
    },
    addGroupErrorMsg: {
        id: 'settings.error-message.adding-group',
        defaultMessage: 'Something went wrong while adding the group',
    },
    tabMembers: {
        id: 'settings.create-group-modal.tab-members',
        defaultMessage: 'Members',
    },
    tabGroups: {
        id: 'settings.create-group-modal.tab-groups',
        defaultMessage: 'Groups',
    },
    nameTaken: {
        id: 'settings.create-group-modal.nameTaken',
        defaultMessage: 'Group names must be unique.',
    },
});

interface FormFields {
    name?: string;
    description: string;
    roleIds: string[];
    groupIds: string[];
    userIds: string[];
    ownerIds: string[];
}

type FormFieldsErrors = Partial<Record<keyof FormFields, boolean>>;

export interface CreateGroupModalProps {
    closeModal: () => void;
    users: User[];
    groups: Group[];
    userRoles: Role[];
    setGroups: React.Dispatch<React.SetStateAction<Group[]>>;
}

enum Tabs {
    Users = 'users',
    Groups = 'groups',
}

export const CreateGroupModal = (props: CreateGroupModalProps) => {
    const {
        closeModal,
        users,
        groups,
        userRoles,
        setGroups,
    } = props;

    const intl = useIntl();
    const [activeTab, setActiveTab] = useState<ArgTabKey>('users');
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState<string>();

    const notifications = useArgNotifications();

    const handleSearch = useCallback((value: string) => {
        setSearch(value);
    }, []);

    const tabs = [
        {
            key: Tabs.Users,
            title: intl.formatMessage(messages.tabMembers),
        },
        {
            key: Tabs.Groups,
            title: intl.formatMessage(messages.tabGroups),
        },
    ];

    const classNames = useClassNames('settings-create-group-modal');

    const [submitForm] = useCallbackAsync(async (progressMonitor: ProgressMonitor, values: FormFields, { resetForm }: FormikHelpers<FormFields>) => {
        if (!values.name) {
            return;
        }
        setLoading(true);
        const addGroupPayload: AddGroupDTO = {
            name: values.name,
            description: values.description,
            ownerIds: [],
        };
        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            const newUserGroup = await userGroupsConnector.addGroup(addGroupPayload, sub1);
            if (values.userIds.length > 0 || values.groupIds.length > 0) {
                const sub2 = new SubProgressMonitor(progressMonitor, 1);
                await userGroupsConnector.addGroupMembers(newUserGroup.id, {
                    userIds: values.userIds,
                    groupIds: values.groupIds,
                },
                sub2);
            }

            const roles = getRolesFromRoleIds(userRoles, values.roleIds);
            const rolesScopes = [...new Set(roles.map(role => role.scope as RolesScope))];

            const apiCalls = rolesScopes.map((scope) => {
                const sub3 = new SubProgressMonitor(progressMonitor, 1);
                const scopedRoleIds = roles.filter(role => role.scope === scope).map(role => role.id);

                return SettingsConnector.getInstance().editGroupRoles(newUserGroup.id, scopedRoleIds, scope, sub3);
            });

            await Promise.all(apiCalls);

            const sub4 = new SubProgressMonitor(progressMonitor, 1);
            const newGroups = await userGroupsConnector.getGroups(sub4);
            setGroups(newGroups);
            resetForm();
            closeModal();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.addGroupErrorMsg }, error as Error);
        }
        setLoading(false);
    }, [userRoles, setGroups, closeModal, notifications]);

    const validateForm = (values: FormFields) => {
        const errors: FormFieldsErrors = {};
        if (!values.name) {
            errors.name = true;
        }

        return errors;
    };
    const {
        handleSubmit,
        handleChange,
        setFieldValue,
        values: formValues,
        errors: formErrors,
    } = useFormik<FormFields>({
        initialValues: { description: '', roleIds: [], ownerIds: [], groupIds: [], userIds: [] },
        validateOnChange: false,
        validate: validateForm,
        onSubmit: submitForm,
    });

    const [createDisabled, setCreateDisabled] = useState(false);

    const duplicateName =
        !!formValues.name && !!groups.map((group) => group.name).includes(formValues.name);

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={closeModal}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={closeModal}
                        label={messages.cancel}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        label={messages.submit}
                        data-testid='create'
                        onClick={() => handleSubmit()}
                        disabled={duplicateName}
                        tooltip={duplicateName ? intl.formatMessage(messages.nameTaken) : undefined}
                    />
                </div>
            }
        >
            <form autoComplete='off' onSubmit={() => handleSubmit()}>
                <p>{intl.formatMessage(messages.description)}</p>

                {/*** OO: TODO REMOVE addedRow ***/}
                <ArgFormLabel
                    propertyName={messages.fieldName}
                    addedRow={true}
                    required={messages.required}
                >
                    <ArgInputText
                        autoFocus={true}
                        data-testid='name'
                        value={formValues.name}
                        state={formErrors.name ? 'invalid' : undefined}
                        onInputChange={handleChange('name')}
                    />
                </ArgFormLabel>
                <ArgFormLabel
                    propertyName={messages.fieldDescription}
                    addedRow={true}
                >
                    <ArgInputTextArea
                        value={formValues.description}
                        state={formErrors.description ? 'invalid' : undefined}
                        onInputChange={handleChange('description')}
                        data-testid='description'
                    />
                </ArgFormLabel>

                <div className={classNames('&-list-top-bar')}>
                    <ArgTabsSubLevel
                        className={classNames('&-tabs')}
                        tabs={tabs}
                        activeTabKey={activeTab}
                        onChange={(tabKey) => {
                            tabKey && setActiveTab(tabKey);
                        }}
                    />
                    <ArgInputSearch
                        onInputChange={handleSearch}
                        className={classNames('&-search-bar')}
                    />
                </div>

                {activeTab === Tabs.Users && (
                    <div className={classNames('&-list')}>
                        <UsersList
                            userIdsSelected={formValues.userIds}
                            handleCheckboxSelection={(checked: boolean, id: string) => {
                                if (checked) {
                                    setFieldValue('userIds', [...formValues.userIds, id]);
                                } else {
                                    setFieldValue(
                                        'userIds',
                                        formValues.userIds.filter((userId) => userId !== id)
                                    );
                                }
                            }}
                            users={users}
                            search={search!}
                        />
                    </div>
                )}
                {activeTab === Tabs.Groups && (
                    <div className={classNames('&-list')}>
                        <GroupsList
                            groupIdsDisabled={[]}
                            groupIdsSelected={formValues.groupIds}
                            handleCheckboxSelection={(checked: boolean, id: string) => {
                                if (checked) {
                                    setFieldValue('groupIds', [...formValues.groupIds, id]);
                                } else {
                                    setFieldValue(
                                        'groupIds',
                                        formValues.groupIds.filter((groupId) => groupId !== id)
                                    );
                                }
                            }}
                            groups={groups}
                            search={search!}
                        />
                    </div>
                )}
                <ArgFormLabel propertyName={intl.formatMessage(messages.fieldRoles)} addedRow={true}>
                    <AssignRoles
                        allRoles={userRoles}
                        value={getRolesFromRoleIds(userRoles, formValues.roleIds)}
                        onChange={(roleNamesSelected) =>
                            setFieldValue('roleIds', roleNamesSelected.map((role) => role.id))
                        }
                    />
                </ArgFormLabel>
            </form>
        </ArgModal>
    );
};
