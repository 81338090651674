import React, { KeyboardEvent, MouseEvent, useState } from 'react';
import { map } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMenu } from '../arg-menu/arg-menu';
import { renderIcon } from '../arg-icon/arg-icon';
import { ArgButton } from '../arg-button/arg-button';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgTabMenuItem, ArgTabMenuItems } from './arg-tabs-types';
import { ArgMenuItem } from '../arg-menu/arg-menu-item';
import { ArgMenuItemDivider } from '../arg-menu/arg-menu-item-divider';

import './arg-tab-menu.less';

type ArgTabMenuProps = {
    items: ArgTabMenuItems;
    className?: ClassValue;
};

export function ArgTabMenu(props: ArgTabMenuProps) {
    const { items, className } = props;

    const [visible, setVisible] = useState<boolean>(false);

    const classNames = useClassNames('arg-tab-menu');

    const actionsMenu = (
        <ArgMenu data-testid='actions-button-menu' className={classNames('&')}>
            {map(items, ({ onClick, key, label, icon, tooltip, disabled, hasDivider }: ArgTabMenuItem, idx: number) => {
                const _key = key || idx;
                const _label = renderText(label);

                function _onClick(event: MouseEvent | KeyboardEvent) {
                    onClick?.(event);

                    setVisible(false);
                }

                const _icon = renderIcon(icon, classNames('&-menu-item-icon'));

                const testId = `arg-tab-menu-item-${_key}`;

                return (
                    <React.Fragment key={_key}>
                        {hasDivider && <ArgMenuItemDivider />}
                        <ArgMenuItem
                            icon={_icon}
                            onClick={_onClick}
                            data-testid={testId}
                            className={classNames('&-item')}
                            disabled={disabled}
                            tooltip={tooltip}
                            label={_label} />
                    </React.Fragment>
                );
            })}
        </ArgMenu>
    );

    return (
        <ArgButton
            size='medium'
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={visible}
            data-testid='actions-button'
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
            className={classNames('&-btn', className)}
            popoverClassName={classNames('&-popover')}
        />
    );
}
