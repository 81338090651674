import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { FormikHelpers, useFormik } from 'formik';
import { useParams } from 'react-router-dom';

import {
    ArgButton,
    ArgInputText,
    ArgInputTextArea,
    ArgModal,
    useClassNames,
    useArgNotifications,
} from 'src/components/basic';
import { Policy } from 'src/settings/models/dtoApi';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';
import { ArgFormLabel } from 'src/components/basic/arg-form/arg-form-label';

import './edit-policy-modal.less';

interface FormFields {
    name?: string;
    description?: string;
}

type FormFieldsErrors = Partial<Record<keyof FormFields, boolean>>;

export interface EditPolicyModalProps {
    visible: boolean;
    closeModal: () => void;
    policy: Policy;
    setPolicies: React.Dispatch<React.SetStateAction<Policy[]>>;
}

export const messages = defineMessages({
    modalTitle: {
        id: 'settings.edit-policy-modal.title',
        defaultMessage: 'Edit policy',
    },
    modalDescription: {
        id: 'settings.edit-policy-modal.description',
        defaultMessage: 'Name and describe this policy',
    },
    nameField: {
        id: 'settings.edit-policy-modal.field.name',
        defaultMessage: 'Name',
    },
    required: {
        id: 'settings.edit-policy-modal.required',
        defaultMessage: 'Required',
    },
    descriptionField: {
        id: 'settings.edit-policy-modal.field.description',
        defaultMessage: 'Description',
    },
    submit: {
        id: 'settings.edit-policy-modal.submitButton',
        defaultMessage: 'Modify',
    },
    cancel: {
        id: 'settings.edit-policy-modal.cancelButton',
        defaultMessage: 'Cancel',
    },
    editPolicyErrorMsg: {
        id: 'settings.error-message.editing-policy',
        defaultMessage: 'Something went wrong while editing the policy',
    },
});

export const EditPolicyModal: React.FC<EditPolicyModalProps> = ({
    visible,
    closeModal,
    setPolicies,
    policy,
}) => {
    const intl = useIntl();
    const classNames = useClassNames('edit-policy-modal');
    const notifications = useArgNotifications();

    const [loading, setLoading] = useState(false);
    const { universeId } = useParams<{ ontologyId: string; universeId: string }>();

    const submitForm = async (values: FormFields, { resetForm }: FormikHelpers<FormFields>) => {
        if (!values.name || !universeId) {
            return;
        }
        setLoading(true);
        try {
            await explorationSettingsConnector.patchPolicyNameAndDescription(
                policy.id,
                values.name,
                values.description
            );
            setPolicies(await explorationSettingsConnector.getPolicies(universeId));
            resetForm();
            closeModal();
        } catch (error) {
            notifications.snackError({ message: messages.editPolicyErrorMsg }, error as Error);
        }
        setLoading(false);
    };

    const validateFrom = (values: FormFields) => {
        const errors: FormFieldsErrors = {};
        if (!values.name) {
            errors.name = true;
        }

        return errors;
    };

    const {
        handleSubmit,
        handleChange,
        values: formValues,
        errors: formErrors,
    } = useFormik<FormFields>({
        initialValues: { name: policy.name, description: policy.description || undefined },
        validateOnChange: false,
        validate: validateFrom,
        onSubmit: submitForm,
        enableReinitialize: true,
    });

    return (
        <ArgModal
            size='medium'
            title={messages.modalTitle}
            visible={visible}
            onClose={closeModal}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={closeModal}
                        label={messages.cancel}
                        disabled={loading}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        onClick={() => handleSubmit()}
                        label={messages.submit}
                        loading={loading}
                        disabled={loading}
                        data-testid='edit'
                    />
                </div>
            }
        >
            <form onSubmit={() => handleSubmit()} autoComplete='off'>
                <p>{intl.formatMessage(messages.modalDescription)}</p>
                <ArgFormLabel
                    propertyName={messages.nameField}
                    required={messages.required}
                >
                    <ArgInputText
                        value={formValues.name}
                        onInputChange={handleChange('name')}
                        data-testid='name'
                        autoFocus={true}
                        state={formErrors.name ? 'invalid' : undefined}
                    />
                </ArgFormLabel>

                <ArgFormLabel
                    propertyName={messages.descriptionField}
                    addedRow={true}
                >
                    <ArgInputTextArea
                        value={formValues.description}
                        onInputChange={handleChange('description')}
                        data-testid='description'
                    />
                </ArgFormLabel>
            </form>
        </ArgModal>
    );
};
