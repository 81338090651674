import { defineMessages } from 'react-intl';

import { defineKeyBindings, GLOBAL_SCOPE_ID, KeyBindingScopeDescriptor } from 'src/components/basic';
import { IS_MAC_OS_ENVIRONMENT } from 'src/components/basic/keybindings/utils';

const messages = defineMessages({
    globalScope: {
        id: 'global.keybindings.GlobalScope',
        defaultMessage: 'Global',
    },
    multipleSelection: {
        id: 'global.keybindings.MultipleSelection',
        defaultMessage: 'Multiple selection',
    },
    delete: {
        id: 'global.keybindings.Delete',
        defaultMessage: 'Delete',
    },
    undo: {
        id: 'global.keybindings.Undo',
        defaultMessage: 'Undo',
    },
    redo: {
        id: 'global.keybindings.Redo',
        defaultMessage: 'Redo',
    },
    logout: {
        id: 'global.keybindings.global.Logout',
        defaultMessage: 'Logout',
    },
    copy: {
        id: 'global.keybindings.Copy',
        defaultMessage: 'Copy',
    },
    cut: {
        id: 'global.keybindings.Cut',
        defaultMessage: 'Cut',
    },
    paste: {
        id: 'global.keybindings.Paste',
        defaultMessage: 'Paste',
    },
    selectAll: {
        id: 'global.keybindings.SelectAll',
        defaultMessage: 'Select all',
    },
});

export const GLOBAL_SCOPE: KeyBindingScopeDescriptor = {
    id: GLOBAL_SCOPE_ID,
    name: messages.globalScope,
};

export const KEY_BINDINGS = defineKeyBindings({
    multipleSelection: {
        id: 'global.MultipleSelection',
        scope: GLOBAL_SCOPE,
        name: messages.multipleSelection,
        defaultKeys: 'ctrl+🖰',
        locked: true,
        global: true,
    },
    delete: {
        id: 'global.Delete',
        scope: GLOBAL_SCOPE,
        name: messages.delete,
        defaultKeys: IS_MAC_OS_ENVIRONMENT ? 'Backspace' : 'Delete',
        locked: true,
        global: true,
    },
    undo: {
        id: 'global.Undo',
        scope: GLOBAL_SCOPE,
        name: messages.undo,
        defaultKeys: 'ctrl+Z',
        global: true,
    },
    redo: {
        id: 'global.Redo',
        scope: GLOBAL_SCOPE,
        name: messages.redo,
        defaultKeys: 'ctrl+Y',
        global: true,
    },
    logout: {
        id: 'global.Logout',
        scope: GLOBAL_SCOPE,
        name: messages.logout,
        defaultKeys: 'alt+ctrl+shift+L',
        global: true,
    },
    copy: {
        id: 'global.Copy',
        scope: GLOBAL_SCOPE,
        name: messages.copy,
        defaultKeys: 'ctrl+C',
        global: true,
    },
    cut: {
        id: 'global.Cut',
        scope: GLOBAL_SCOPE,
        name: messages.cut,
        defaultKeys: 'ctrl+X',
        global: true,
    },
    paste: {
        id: 'global.Paste',
        scope: GLOBAL_SCOPE,
        name: messages.paste,
        defaultKeys: 'ctrl+V',
        global: true,
    },
    selectAll: {
        id: 'global.SelectAll',
        scope: GLOBAL_SCOPE,
        name: messages.selectAll,
        defaultKeys: 'ctrl+A',
        global: true,
    },
});

