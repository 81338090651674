import { cloneDeep, set } from 'lodash';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { ArgCombo, useClassNames } from 'src/components/basic';
import { DropdownTooltipField } from '../../../../common/dropdown-tooltip-field/dropdown-tooltip-field';
import { ReadOnlyFilterInput } from 'src/settings/universes/common/rules/read-only-filter-input/read-only-filter-input';
import { useValuationRuleStateContext } from 'src/settings/universes/common/providers/policy-rules-provider';

import './valuation-rule-effect-block.less';

const messages = defineMessages({
    metaProperty: {
        id: 'settings.valuation-rule-effect.metaProperty',
        defaultMessage: 'Meta properties',
    },
    property: {
        id: 'settings.valuation-rule-effect.property',
        defaultMessage: 'Properties',
    },
    select: {
        id: 'settings.valuation-rule-effect.select',
        defaultMessage: 'Select',
    },
});

enum ValueType {
    Property = 'property',
    MetaProperty = 'metaProperty',
}

const ITEMS = [ValueType.Property, ValueType.MetaProperty];

interface MetaOrPropertyDropdownProps {
    targetType: 'object' | 'property' | undefined;
    index: number;
}

export const MetaOrPropertyDropdown = ({
    targetType,
    index,
}: MetaOrPropertyDropdownProps) => {
    const intl = useIntl();
    const classNames = useClassNames('settings-valuation-rule-effect-block');
    const { setRule, editable } = useValuationRuleStateContext();

    const value = targetType === 'property' ? ValueType.MetaProperty : targetType === 'object' ? ValueType.Property : undefined;
    const displayedValue = targetType === 'property' ? messages.metaProperty : targetType === 'object' ? messages.property : undefined;

    return editable ? (
        <ArgCombo<ValueType>
            className={classNames('&-object-property-field')}
            placeholder={intl.formatMessage(messages.select)}
            items={ITEMS}
            value={value}
            cardinality='one'
            size='small'
            popoverClassName='arg-input-popover-no-max-width'
            getItemLabel={(item) =>
                <FormattedMessage {...(item === ValueType.MetaProperty ? messages.metaProperty : messages.property)} />}
            onChange={(value) => {
                setRule((currentRule) => {
                    const newTargetType = value === ValueType.MetaProperty ? 'property' : 'object';

                    if (targetType === newTargetType) {
                        return currentRule;
                    }

                    return set(cloneDeep(currentRule), `Effects[${index}]`, {
                        [newTargetType]: { match: [] },
                    });
                });
            }}
            renderInput={() => (
                <DropdownTooltipField
                    className={classNames('&-object-property-field')}
                    value={displayedValue && <FormattedMessage {...displayedValue} />}
                />
            )}
        />
    ) : (
        <ReadOnlyFilterInput className={classNames('&-object-property-field')} value={displayedValue} />
    );
};
