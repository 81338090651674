
import { Plugin } from '@ckeditor/ckeditor5-core';
import ResizeScreenshotCommand from './resize-screenshot-command';
import { ScreenshotEditing } from '../screenshot-editing';

export default class ScreenshotResizeUi extends Plugin {
    /**
     * @inheritDoc
     */
    static get requires() {
        return [ScreenshotEditing];
    }

    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'argonos-resource-screenshot:UI';
    }

    /**
     * @inheritDoc
     */
    constructor(editor) {
        super(editor);

        editor.config.define('screenshot', {
            resizeUnit: '%',
            resizeOptions: [{
                name: 'resizeScreenshot:original',
                value: null,
                icon: 'original',
            },
            {
                name: 'resizeScreenshot:25',
                value: '25',
                icon: 'small',
            },
            {
                name: 'resizeScreenshot:50',
                value: '50',
                icon: 'medium',
            },
            {
                name: 'resizeScreenshot:75',
                value: '75',
                icon: 'large',
            }],
        });
    }

    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;
        const resizeImageCommand = new ResizeScreenshotCommand(editor);

        editor.commands.add('resizeScreenshot', resizeImageCommand);
    }
}
