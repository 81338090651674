import { LoadingPane } from '../common/panes/loading-pane';
import { ArgRenderedText, ClassValue, useClassNames } from '../basic';

import './loading-suspense.less';

interface LoadingSuspenseProps {
    className?: ClassValue;
    message?: ArgRenderedText;
    messageValues?: Record<string, any>;
}

export function LoadingSuspense(props: LoadingSuspenseProps) {
    const {
        className,
        message,
        messageValues,
    } = props;

    const classNames = useClassNames('common-loading-suspense');

    return (
        <div className={classNames('&', className)}>
            <LoadingPane
                className={classNames('&-loading')}
                message={message}
                messageValues={messageValues}
            />
        </div>
    );
}
