import { chain } from 'lodash';
import { useCallback, useMemo } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
    ArgButton,
    ArgToolItemRenderContext,
    ArgUser,
    KeyBindingDescriptor,
    ProgressMonitor,
    Tool,
    ToolContext,
    useArgModalContext,
    useCallbackAsync,
    useClassNames,
    useToolItem,
} from 'src/components/basic';
import { useGetMe } from 'src/contexts/user-context';
import { useUserLocale } from 'src/contexts/user-locale-context';
import { Environment } from 'src/utils/environment';
import { KeyBindingPanel } from '../keybindings-panel/keybinding-panel';
import { useToolItems } from '../../basic/arg-toolbar/use-tool-items';
import { ArgPopoverRenderer } from 'src/components/basic/arg-popover/types';
import { Connector } from '../../../utils/connector';

const messages = defineMessages({
    userLocale: {
        id: 'common.top-bar.user-dropdown.UserLocale',
        defaultMessage: 'Language: {userLocale}',
    },
    french: {
        id: 'common.top-bar.user-dropdown.French',
        defaultMessage: 'French',
    },
    english: {
        id: 'common.top-bar.user-dropdown.English',
        defaultMessage: 'English',
    },
    changePassword: {
        id: 'common.top-bar.user-dropdown.changePassword',
        defaultMessage: 'Change Password',
    },
    logout: {
        id: 'common.top-bar.user-dropdown.logout',
        defaultMessage: 'Logout',
    },
    shortcuts: {
        id: 'common.top-bar.user-dropdown.KeyShortcuts',
        defaultMessage: 'Keyboard Shortcuts',
    },
});

const locales: Record<string, MessageDescriptor> = {
    'fr': messages.french,
    'en': messages.english,
};

export function useUserDropdown<T = undefined>(
    toolContext: ToolContext<T>,
    path: string,
    order?: number,
    logoutKeyBinding?: KeyBindingDescriptor
) {
    const classNames = useClassNames('top-bar-user-item');

    const modalContext = useArgModalContext();
    const { me } = useGetMe();
    const intl = useIntl();
    const navigate = useNavigate();

    const renderUser = useCallback(() => {
        return (
            <ArgUser
                user={me}
                tooltip={false}
                className={classNames('&-user')}
                size='large'
            />
        );
    }, [classNames, me]);

    const renderCustomButton = useCallback((tool: Tool<T>, envContext: T, context: ArgToolItemRenderContext, popover?: ArgPopoverRenderer) => {
        return (
            <ArgButton
                className={classNames('&')}
                type='ghost'
                size='medium'
                right='dropdown'
                label={renderUser}
                popover={popover}
            />
        );
    }, [classNames, renderUser]);

    useToolItem<T>(toolContext, {
        path,
        order,
        type: 'custom',
    }, {
        customRender: renderCustomButton,
    });

    useToolItem(toolContext, {
        path: `${path}/begin`,
        order: 100,
        type: 'group',
    });

    const userLocaleContext = useUserLocale();

    const userLocale = useMemo<string>(() => {
        if (userLocaleContext?.userLocale && locales[userLocaleContext.userLocale]) {
            return intl.formatMessage(locales[userLocaleContext.userLocale]);
        }

        return userLocaleContext?.userLocale;
    }, [userLocaleContext.userLocale]);

    useToolItem(toolContext, {
        path: `${path}/begin/locales`,
        type: 'menu',
        order: 1,
        label: messages.userLocale,
    }, {
        label: intl.formatMessage(messages.userLocale, { userLocale }),
    });

    const [handleLocaleClick] = useCallbackAsync(async (progressMonitor: ProgressMonitor, tool: Tool<T>) => {
        await userLocaleContext.changeUserLocale(tool.key as string);
    }, [userLocaleContext]);

    const localesToolItems = useMemo<Tool<T>[]>(() => {
        const ret = chain(locales)
            .mapValues((language, locale) => {
                const tool: Tool<T> = {
                    key: locale,
                    path: `${path}/begin/locales/${locale}`,
                    label: language,
                    onClick: handleLocaleClick,
                };

                return tool;
            })
            .values()
            .value();

        return ret;
    }, [handleLocaleClick, path]);

    useToolItems(toolContext, (): Tool<T>[] => {
        return localesToolItems;
    }, []);

    const handleShortcutsClick = useCallback(() => {
        modalContext.open('shortcuts', <KeyBindingPanel
            onClose={() => modalContext.close('shortcuts')}
        />);
    }, [modalContext]);

    useToolItem(toolContext, {
        path: `${path}/begin/shortcuts`,
        type: 'button',
        label: messages.shortcuts,
        order: 2,
    }, {
        onClick: handleShortcutsClick,
    });

    const handleChangePassword = useCallback(() => {
        Connector.getInstance().openChangePasswordWindow();
    }, []);

    useToolItem(toolContext, {
        path: `${path}/begin/changePassword`,
        type: 'button',
        label: messages.changePassword,
        order: 3,
    }, {
        onClick: handleChangePassword,
        visible: Environment.apiOAuth && !me.identityIssuer,
    });

    useToolItem(toolContext, {
        path: `${path}/end`,
        type: 'group',
        order: 1000,
    });

    const handleLogout = useCallback(() => {
        navigate('/logout');
    }, [navigate]);

    useToolItem(toolContext, {
        path: `${path}/end/logout`,
        type: 'button',
        label: messages.logout,
        keyBinding: logoutKeyBinding,
    }, {
        onClick: handleLogout,
    });
}
