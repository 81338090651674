import { cloneDeep, set } from 'lodash';
import { Dispatch, useCallback, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
    ArgButton,
    ArgIcon,
    ProgressMonitor,
    ThreeDotsLoading,
    useCallbackAsync,
    useClassNames,
    useArgNotifications,
} from 'src/components/basic';
import { PolicyRuleBlockTarget } from '../../../../common/rules/policy-rule/policy-rule-block-target';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';
import { isEmptyObject, isValidTarget, isValidValuationEffect } from '../../../../common/policy-utils';
import { ValuationPolicyProcessed, ValuationRuleProcessed } from 'src/settings/models/valuation-policy';
import {
    ValuationPolicyRuleBlockEffect,
} from '../valuation-policy-rule-block-effect/valuation-policy-rule-block-effect';
import { useOntologyContext, ValuationRuleStateContext } from 'src/settings/universes/common/providers/policy-rules-provider';

import './valuation-policy-rule-block.less';

const messages = defineMessages({
    rule: {
        id: 'settings.valuation-policy-rule-page.rule',
        defaultMessage: 'Rule',
    },
    invalidRule: {
        id: 'settings.valuation-policy-rule-block-target.invalidRule',
        defaultMessage:
            'The rule target has been created on another system and its details cannot be displayed through this interface',
    },
    savingPolicyError: {
        id: 'settings.valuation-policy-rule-block-target.savingPolicyError',
        defaultMessage: 'An error occurred while saving the policy',
    },
    invalidRuleTitle: {
        id: 'settings.valuation-policy-rule-block-target.invalidRuleTitle',
        defaultMessage: 'Error in the logical expression',
    },
    invalidRuleDescription: {
        id: 'settings.valuation-policy-rule-block-target.invalidRuleDescription',
        defaultMessage: 'Unable to validate the rule',
    },
    cancel: {
        id: 'settings.valuation-policy-rule-block-target.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.valuation-policy-rule-block-target.delete',
        defaultMessage: 'Delete',
    },
    confirm: {
        id: 'settings.valuation-policy-rule-block-target.confirm',
        defaultMessage: 'Validate',
    },
});

interface ValuationPolicyRuleBlockProps {
    rule: ValuationRuleProcessed;
    index: number;
    setPolicy: Dispatch<ValuationPolicyProcessed>;
    editable: boolean;
    setEditable: (value: boolean) => void;
    policy: ValuationPolicyProcessed;
}

export function ValuationPolicyRuleBlock(props: ValuationPolicyRuleBlockProps) {
    const {
        rule,
        index,
        setPolicy,
        editable,
        setEditable,
        policy,
    } = props;

    const notifications = useArgNotifications();

    const classNames = useClassNames('settings-valuation-policy-rule-block');
    const [expanded, setExpanded] = useState(true);
    const [ruleState, setRuleState] = useState(rule);

    const invalidRule = ruleState.Targets.length !== 1;

    const { ontologySchema, userProfileFields } = useOntologyContext();

    const [handleSaveRule, saveRuleProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const canBeSaved =
            ruleState.Targets.every((target) => isValidTarget(target) || isEmptyObject(target))
            && ruleState.Effects.every((effect) => isValidValuationEffect(effect));

        if (!canBeSaved) {
            notifications.snackError({
                message: messages.invalidRuleTitle,
                description: messages.invalidRuleDescription,
            });
        } else {
            try {
                const newPolicy = set(cloneDeep(policy), `statement.Actions[${index}]`, ruleState);
                await explorationSettingsConnector.editValuationPolicy(policy.id, newPolicy, progressMonitor);
                setPolicy(newPolicy);
                setEditable(false);
            } catch (error) {
                if (progressMonitor.isCancelled) {
                    throw error;
                }
                notifications.snackError({ message: messages.savingPolicyError }, error as Error);
                throw error;
            }
        }
    }, [ruleState, notifications, policy, index, setPolicy, setEditable]);

    const handleResetRule = useCallback(() => {
        setRuleState(rule);
        setEditable(false);
    }, [rule, setEditable]);

    const [handleRuleDeletion, deleteRuleProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const newPolicy = set(
                cloneDeep(policy),
                'statement.Actions',
                policy.statement.Actions.filter((act, idx) => idx !== index)
            );
            await explorationSettingsConnector.editValuationPolicy(policy.id, newPolicy, progressMonitor);
            setPolicy(newPolicy);
            setEditable(false);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.savingPolicyError }, error as Error);
            throw error;
        }
    }, [policy, index, setPolicy, setEditable]);

    const loading = saveRuleProgressMonitor?.isRunning || deleteRuleProgressMonitor?.isRunning;

    return (
        <ValuationRuleStateContext.Provider value={{ rule: ruleState, setRule: setRuleState, editable }}>
            <div className={classNames('&-container')}>
                <div className={classNames('&-header')}>
                    <div className={classNames('&-header-left')}>
                        <div className={classNames('&-rule-title', editable && '&-color-editable')}>
                            <FormattedMessage {...messages.rule} />
                            {' '}
                            {index + 1}
                        </div>
                        <div
                            onClick={() => setExpanded(!expanded)}
                            className={classNames('&-icon')}
                        >
                            <ArgIcon
                                name={expanded ? 'icon-cheveron-up' : 'icon-cheveron-down'}
                                size={20}
                            />
                        </div>
                    </div>
                    <div className={classNames('&-header-right')}>
                        {editable && loading && <ThreeDotsLoading />}
                        {editable && !loading && (
                            <>
                                <ArgButton
                                    size='medium'
                                    type='ghost'
                                    tooltip={messages.confirm}
                                    icon='icon-checkmark1'
                                    onClick={handleSaveRule}
                                />
                                <ArgButton
                                    size='medium'
                                    type='ghost'
                                    tooltip={messages.cancel}
                                    icon='icon-cross'
                                    onClick={handleResetRule}
                                />
                                <ArgButton
                                    size='medium'
                                    type='ghost'
                                    tooltip={messages.delete}
                                    icon='icon-trash'
                                    onClick={handleRuleDeletion}
                                />
                            </>
                        )}
                        {!editable && (
                            <div onClick={() => setEditable(true)} className={classNames('&-icon')}>
                                <ArgIcon name='icon-edit-pencil' size={14} />
                            </div>
                        )}
                    </div>
                </div>
                {expanded && !invalidRule && (
                    <div
                        className={classNames(
                            '&-target-effect-container',
                            editable && '&-editable'
                        )}
                    >
                        {ruleState.Targets.map((target, policyIndex) => {
                            return (
                                <div key={policyIndex} className={classNames('&-target-container', editable && '&-editable')}>
                                    <PolicyRuleBlockTarget
                                        editable={editable}
                                        policyTargetsPath='Targets'
                                        target={target}
                                        policyIndex={policyIndex}
                                        onRuleChange={setRuleState}
                                        schema={ontologySchema}
                                        userProfileFields={userProfileFields}
                                    />
                                </div>
                            );
                        })}
                        <div className={classNames('&-effect-container', editable && '&-editable')}>
                            <ValuationPolicyRuleBlockEffect effects={ruleState.Effects} />
                        </div>
                    </div>
                )}
                {expanded && invalidRule && (
                    <div className={classNames('&-invalidRule')}>
                        <FormattedMessage {...messages.invalidRule} />
                    </div>
                )}
            </div>
        </ValuationRuleStateContext.Provider>
    );
}
