import { useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages } from 'react-intl';

import { useArgNotifications, useClassNames } from 'src/components/basic';
import { useScriptSandbox } from 'src/components/basic/arg-hooks/use-script-sandbox';
import { FormIFrame } from 'src/components/common/forms/model';

import './form-iframe-component.less';

const messages = defineMessages({
    computeUrlError: {
        id: 'exploration.forms.editableRenderFactory.ComputeUrlError',
        defaultMessage: 'An error occurred while computing the URL',
    },
});


export interface FormIframeComponentProps {
    formIFrame: FormIFrame;
    scriptContext?: string;
    border?: boolean;
}

export function FormIframeComponent(props: FormIframeComponentProps) {
    const { formIFrame, scriptContext, border } = props;

    const classNames = useClassNames('form-iframe-component');
    const notifications = useArgNotifications();
    const containerRef = useRef<HTMLDivElement>(null);
    const [computedUrl, setComputedUrl] = useState<string | undefined>(undefined);

    const { name, url } = formIFrame;
    const invalid = !url;

    const handOnSuccess = useCallback((output: string) => {
        setComputedUrl(output);
    }, []);

    const handOnError = useCallback((error: any) => {
        console.error(error);
        notifications.snackError({ message: messages.computeUrlError }, error as Error);
    }, [notifications]);

    const runScript = useScriptSandbox({
        containerRef,
        onSuccess: handOnSuccess,
        onError: handOnError,
    });

    useEffect(() => {
        if (!url) {
            return;
        }
        const script = /^http/.test(url) ? `return '${url}';` : url;
        const context = scriptContext ? `${scriptContext}\n` : '';

        runScript(`${context}${script}`);
    }, [url, runScript, scriptContext]);

    const cls = { border, invalid };

    return (
        <div className={classNames('&', cls)} ref={containerRef}>
            {name && <span className={classNames('&-title')}>{name}</span>}
            <iframe src={computedUrl} />
        </div>
    );
}
