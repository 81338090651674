import { useCallback, useState } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgFormLabel,
    ArgInputText,
    ClassValue,
    ProgressMonitor,
    useClassNames,
} from 'src/components/basic';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';
import { ProceoGroup } from '../../../model/group';

import './hierarchy-group-modal.less';

const CLASSNAME = 'proceo-hierarchy-group-modal';

interface HierarchyCreateGroupModalProps {
    className?: ClassValue;
    onClose: () => void;
    group?: ProceoGroup;
    onSubmit: (progressMonitor: ProgressMonitor, name: string) => Promise<void>;
}

const messages = defineMessages({
    createGroupModalTitle: {
        id: 'proceo.hierarchy.modal.group.form.CreateGroupModalTitle',
        defaultMessage: 'Create a group',
    },
    editGroupModalTitle: {
        id: 'proceo.hierarchy.modal.group.form.EditGroupModalTitle',
        defaultMessage: 'Edit a group',
    },
    createGroupHierarchyElementError: {
        id: 'proceo.hierarchy.modal.group.form.CreateHierarchyElementError',
        defaultMessage: 'Failed to create the group',
    },

    createNameTitle: {
        id: 'proceo.hierarchy.modal.group.form.CreateNameTitle',
        defaultMessage: 'Name',
    },
    createNamePlaceholder: {
        id: 'proceo.hierarchy.modal.group.form.CreateNamePlaceholder',
        defaultMessage: 'name',
    },
});

export function HierarchyGroupModal(props: HierarchyCreateGroupModalProps) {
    const {
        className,
        onClose,
        onSubmit,
        group,
    } = props;

    const classNames = useClassNames(CLASSNAME);
    const isEditMode = !!group;

    const [name, setName] = useState(group?.displayName || '');

    const modalContent = (
        <div className={classNames('&-fields')}>
            <ArgFormLabel
                key='name'
                className={classNames('&-name-field')}
                size='medium'
                propertyName={messages.createNameTitle}
            >
                <ArgInputText
                    value={name}
                    onInputChange={(val) => setName(val)}
                    className={classNames('&-header-search')}
                    placeholder={messages.createNamePlaceholder}
                />
            </ArgFormLabel>
        </div>
    );

    const handleOnConfirm = useCallback(async (progressMonitor: ProgressMonitor) => {
        await onSubmit(progressMonitor, name);
    }, [onSubmit, name]);

    return <ConfirmModal
        title={isEditMode ? messages.editGroupModalTitle : messages.createGroupModalTitle}
        type='validate'
        onClose={onClose}
        onConfirm={handleOnConfirm}
        wrapChildrenInAForm={true}
        bodyClassName={classNames('&-confim-modal-body')}
        confirmDisabled={!(name)}
    >
        <div className={classNames('&', className)}>
            {modalContent}
        </div>
    </ConfirmModal>;
}
