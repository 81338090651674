import { DowncastWriter, Element } from '@ckeditor/ckeditor5-engine';
import ContainerElement from '@ckeditor/ckeditor5-engine/src/view/containerelement';
import ViewElement from '@ckeditor/ckeditor5-engine/src/view/element';
import { WidgetResize } from '@ckeditor/ckeditor5-widget';
import { Plugin } from '@ckeditor/ckeditor5-core';
import ModelElement from '@ckeditor/ckeditor5-engine/src/model/element';

export function postEditingDowncast(plugin: Plugin, viewWriter: DowncastWriter, containerElement: ContainerElement, viewElement: ViewElement, modelElement: ModelElement) {
    const editor = plugin.editor;

    const domConverter = editor.editing.view.domConverter;
    const mapper = editor.editing.mapper;
    const editingView = editor.editing.view;

    editor.plugins
        .get(WidgetResize)
        .attachTo({
            unit: 'px',

            modelElement: modelElement,
            viewElement: containerElement,
            editor,

            getHandleHost(domWidgetElement) {
                let host = domWidgetElement.querySelector('CANVAS');
                if (host) {
                    return host as HTMLElement;
                }

                host = domWidgetElement.querySelector('.exploration-brief-editable-exploration2-map');
                if (host) {
                    return host as HTMLElement;
                }

                return domWidgetElement;
            },
            getResizeHost() {
                // Return the model image element parent to avoid setting an inline element (<a>/<span>) as a resize host.
                const viewElement = mapper.toViewElement(modelElement.parent as Element);
                const resizeHost = domConverter.viewToDom(viewElement!, document) as any as HTMLElement;

                const host = resizeHost.querySelector('.exploration2-graph-chart') as HTMLElement;

                return host;
            },
            // TODO consider other positions.
            isCentered() {
                const imageStyle = modelElement.getAttribute('imageStyle');

                return !imageStyle || imageStyle == 'block' || imageStyle == 'alignCenter';
            },

            onCommit(newValue) {
                //console.log('COMMIT', newValue);

                // Get rid of the CSS class in case the command execution that follows is unsuccessful
                // (e.g. Track Changes can override it and the new dimensions will not apply). Otherwise,
                // the presence of the class and the absence of the width style will cause it to take 100%
                // of the horizontal space.
                editingView.change(writer => {
                    //writer.removeClass(RESIZED_IMAGE_CLASS, widgetView);
                });

                editor.execute('resizeExploration2', { width: newValue });
            },
        });
}
