import { Menu } from 'antd';
import { useState, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ContextualVariable } from 'src/exploration/model/contextual-variable';
import {
    ArgButton,
    ArgMenu,
    useArgModalContext,
    useClassNames,
} from '../../../components/basic';
import { DeleteVariableModal } from './delete-variable-modal';
import { EditVariableModal } from './edit-variable-modal';

export enum ContextualVariableMenuActions {
    Edit = 'Edit',
    AddSubGroup = 'AddSubGroup',
    Import = 'Import',
    Export = 'Export',
    Delete = 'Delete',
    MoveTo = 'MoveTo',
}

type ContextualVariableMenuProps = {
    visibleCondition?: (key: ContextualVariableMenuActions) => boolean;
    variable?: ContextualVariable;
    setContextualVariables: React.Dispatch<React.SetStateAction<ContextualVariable[]>>;
};

const messages = defineMessages({
    edit: {
        id: 'settings.contextual-variables.menu.edit',
        defaultMessage: 'Edit',
    },
    addSubGroup: {
        id: 'settings.contextual-variables.menu.add-sub-group',
        defaultMessage: 'Add a sub group',
    },
    delete: {
        id: 'settings.contextual-variables.menu.delete',
        defaultMessage: 'Delete',
    },
});


enum ActionType {
    Edit = 'edit-variable',
    Delete = 'delete-variable'
}

export const ContextualVariableMenu = ({
    visibleCondition = () => true,
    variable,
    setContextualVariables,
}: ContextualVariableMenuProps) => {
    const classNames = useClassNames('contextual-variable-menu');
    const intl = useIntl();
    const modalContext = useArgModalContext();
    const [visible, setVisible] = useState<boolean>(false);

    const handleItemActionsMenuClick = () => {
        setVisible(false);
    };

    const handleActionVariable = useCallback((actionType: ActionType) => {
        if (!variable) {
            return;
        }

        const Component = actionType === ActionType.Edit ? EditVariableModal : DeleteVariableModal;
        modalContext.open(actionType,
            <Component
                variable={variable}
                closeModal={() => modalContext.close(actionType)}
                setContextualVariables={setContextualVariables}
            />
        );
    }, [variable, setContextualVariables, modalContext]);

    const handleEditVariable = useCallback(() => {
        handleActionVariable(ActionType.Edit);
    }, [handleActionVariable]);

    const handleDeleteVariable = useCallback(() => {
        handleActionVariable(ActionType.Delete);
    }, [handleActionVariable]);

    const actionsMenu = (
        <ArgMenu
            className={classNames('&-menu')}
            data-testid='actions-contextual-variable-button-menu'
            onClick={() => handleItemActionsMenuClick()}
        >
            {visibleCondition(ContextualVariableMenuActions.Edit) && (
                <Menu.Item
                    key={ContextualVariableMenuActions.Edit}
                    data-testid='menu-item-edit'
                    onClick={(e) => {
                        e.domEvent.preventDefault();
                        e.domEvent.stopPropagation();
                        handleEditVariable();
                    }}
                    className={classNames('&-menu-item', 'edit')}
                >
                    <span>{intl.formatMessage(messages.edit)}</span>
                </Menu.Item>
            )}

            {visibleCondition(ContextualVariableMenuActions.Delete) && (
                <Menu.Item
                    key={ContextualVariableMenuActions.Delete}
                    data-testid='menu-item-delete'
                    onClick={(e) => {
                        e.domEvent.preventDefault();
                        e.domEvent.stopPropagation();
                        handleDeleteVariable();
                    }}
                    className={classNames('&-menu-item', 'delete')}
                >
                    <span>{intl.formatMessage(messages.delete)}</span>
                </Menu.Item>
            )}
        </ArgMenu>
    );

    return (
        <>
            <ArgButton
                type='ghost'
                icon='icon-options'
                popover={actionsMenu}
                popoverTrigger='click'
                popoverVisible={visible}
                className={classNames('&')}
                data-testid='actions-contextual-variable-menu'
                popoverPlacement='bottomLeft'
                onPopoverVisibleChange={setVisible}
                popoverClassName={classNames('&-popover')}
            />
        </>
    );
};
