import { compact, map } from 'lodash';

import { ArgUserId, ProgressMonitor } from '../../../components/basic';
import { CasePieceType } from '../../../model/case-piece-type';
import { getProceoApi } from '../../../utils/connectors/api-url';
import { ArgonosFoldersConnector, FilterCasePieceCondition } from '../../../utils/connectors/argonos-folders-connector';
import { PROCEO_GROUP_CUSTOM_FIELDS_DEFINITION, ProceoGroupCustomFieldNames, ProceoGroupCustomFields } from '../../constants/group-custom-fields';
import { Folder, FolderId } from '../../../model/folder';
import { mapProceoBaseFolder, mapProceoGroup } from './mappers';
import { FolderType } from '../../model/folder';
import { ProceoGroup } from '../../model/group';
import { FolderCasePiece } from '../../../model/folder-case-piece';
import { ProceoBaseFolderCustomFieldNames } from '../../constants/base-folder-custom-fields';
import { UsersAdminConnector } from 'src/utils/connectors/users-admin-connector';
import { PublicUser } from 'src/model/user';


export type ProceoGroupFilter = Partial<Record<ProceoGroupCustomFieldNames, any[]>>;
type CaseFilter = Record<ProceoBaseFolderCustomFieldNames._Type, [FolderType.Group] & ProceoGroupFilter>;
type GroupCustomFields = Record<ProceoBaseFolderCustomFieldNames._Type, FolderType.Group & ProceoGroupCustomFields>;

export class ProceoGroupsConnector extends ArgonosFoldersConnector {
    private static instance: ProceoGroupsConnector;

    static getInstance(): ProceoGroupsConnector {
        if (!ProceoGroupsConnector.instance) {
            ProceoGroupsConnector.instance = new ProceoGroupsConnector('proceo.groups', getProceoApi(), undefined, PROCEO_GROUP_CUSTOM_FIELDS_DEFINITION);
        }

        return ProceoGroupsConnector.instance;
    }

    override async getFolder(folderId: FolderId,
        markVisit: boolean,
        includingChildrenTypes: CasePieceType[] | undefined,
        listPermissions: boolean,
        casePieceFilterCondition: FilterCasePieceCondition | undefined,
        prev: Folder | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<ProceoGroup> {
        const folder = await super.getFolder(folderId, markVisit, includingChildrenTypes, listPermissions, casePieceFilterCondition, prev, progressMonitor) as ProceoGroup;
        if (folder.folderType !== FolderType.Group) {
            throw new Error('A group is expected!');
        }

        return folder;
    }


    async getFolders(includingChildrenTypes: CasePieceType[] | undefined,
        includePrivateCase: boolean,
        listPermissions: boolean,
        casePieceFilterCondition: FilterCasePieceCondition | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<ProceoGroup[]> {
        const filters: CaseFilter = {
            [ProceoBaseFolderCustomFieldNames._Type]: [FolderType.Group],
        };
        const groups = await super.getFilteredFolders(includingChildrenTypes, false, listPermissions, casePieceFilterCondition, filters, progressMonitor) as ProceoGroup[];

        return groups;
    }

    override async getFilteredFolders(
        includingChildrenTypes: CasePieceType[] | undefined,
        includePrivateCase: boolean,
        listPermissions: boolean,
        casePieceFilterCondition: FilterCasePieceCondition | undefined,
        filters?: ProceoGroupFilter,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<ProceoGroup[]> {
        const _filters: CaseFilter = {
            ...filters,
            [ProceoBaseFolderCustomFieldNames._Type]: [FolderType.Group],
        };
        const folders = await super.getFilteredFolders(includingChildrenTypes, includePrivateCase, listPermissions, casePieceFilterCondition, _filters, progressMonitor) as ProceoGroup[];

        return folders;
    }

    protected internalMapFolder(raw: any, filterCasePieceCondition?: FilterCasePieceCondition) {
        const folder = super.internalMapFolder(raw, filterCasePieceCondition);
        const proceoBaseFolder = mapProceoBaseFolder(folder);
        const ret = mapProceoGroup(proceoBaseFolder);

        return ret;
    }

    override async createFolder(
        newCaseName: string | null,
        newCasePath?: string,
        description?: string,
        customFields?: ProceoGroupCustomFields,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const _customFields: GroupCustomFields = { ...customFields, [ProceoBaseFolderCustomFieldNames._Type]: FolderType.Group };
        const ret = await super.createFolder(newCaseName, newCasePath, description, _customFields, progressMonitor) as ProceoGroup;

        return ret;
    }

    override updateFolder(
        caseId: FolderId,
        newCaseName: string,
        description?: string,
        customFields?: ProceoGroupCustomFields,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<FolderCasePiece> {
        const _customFields: GroupCustomFields = { ...customFields, [ProceoBaseFolderCustomFieldNames._Type]: FolderType.Group };
        const ret = super.updateFolder(caseId, newCaseName, description, _customFields, progressMonitor);

        return ret;
    }

    async getGroupUsers(caseId: FolderId): Promise<PublicUser[]> {
        const response = await this.request(`/groups/${caseId}/users`, {});
        const userIds: string[] = response.userIds;

        const responses = await Promise.allSettled(
            userIds.map(id => UsersAdminConnector.getInstance().getPublicUser(id))
        );

        // Some users may have been deleted (and not yet cleaned up) hence simply ignore.
        return compact(responses.map(res => (res.status === 'fulfilled' ? res.value : null)));
    }

    async saveGroupUsers(groupId: FolderId, data: { [userId: string]: 'Add' | 'Delete' }) {
        const promises = map(data, (action, userId) => (
            action === 'Add' ?
                this.request(`/groups/${groupId}/user/${userId}`, { method: 'PUT' }) :
                this.request(`/groups/${groupId}/users/${userId}`, { method: 'DELETE' })
        ));

        await Promise.allSettled(promises);
    }

    // Get users' group id
    async getUserGroupId(userId: ArgUserId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<FolderId|undefined> {
        const response = await this.request(`/groups/users/${userId}`, {}, progressMonitor);
        const groupIds: string[] = response.groupIds;

        // For now, we expect user to belong to a single group. The more general case is still to be refined.
        return groupIds[0];
    }
}
