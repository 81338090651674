import { getDataExplorationApi } from '../../../utils/connectors/api-url';
import { ArgonosPieceConnector } from '../../../utils/connectors/argonos-casepiece-connector';


export class ExplorationPieceConnector extends ArgonosPieceConnector {
    private static instance: ExplorationPieceConnector;

    static getInstance(): ExplorationPieceConnector {
        if (!ExplorationPieceConnector.instance) {
            ExplorationPieceConnector.instance = new ExplorationPieceConnector('exploration.pieced', getDataExplorationApi());
        }

        return ExplorationPieceConnector.instance;
    }
}
