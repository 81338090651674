import { defineMessages, FormatDateOptions, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import { ArgRelativeTime, ArgUserInfo, ClassValue, highlightSplit, useClassNames } from '../basic';
import { formatUser } from '../../utils/formatter';
import { useGetMe } from '../../contexts/user-context';

import './date-by-user.less';

export const DATE_FORMAT: FormatDateOptions = {
    dateStyle: 'long',
    timeStyle: 'short',
};

export const messages = defineMessages({
    by: {
        id: 'common.date-by-user.By',
        defaultMessage: 'by',
    },
});

export interface DateByUserProps {
    user?: ArgUserInfo | false;
    date: Date;
    relative?: boolean;
    datePrefixMessage?: MessageDescriptor;
    relativeDateStyle?: Intl.RelativeTimeFormatStyle;
    className?: ClassValue;
    search?: string;
}

export function DateByUser(props: DateByUserProps) {
    const {
        user,
        date,
        relative,
        className,
        search,
        datePrefixMessage,
        relativeDateStyle,
    } = props;

    const intl = useIntl();

    const classNames = useClassNames('common-date-by-user');

    const { me } = useGetMe();

    return (
        <span data-testid='date-by-user' className={classNames('&', className)}>
            {relative ? (
                <span className={classNames('&-date-wrapper')}>
                    {!!datePrefixMessage && <span
                        className={classNames('&-date-prefix')}
                    >
                        <FormattedMessage {...datePrefixMessage} />
                    </span>
                    }
                    <ArgRelativeTime
                        date={date}
                        className={classNames('&-date')}
                        style={relativeDateStyle ? relativeDateStyle : 'long'}
                        numeric='auto'
                    />
                </span>
            ) : (
                <span data-testid='date-by-user-date' className={classNames('&-date')}>
                    {!!datePrefixMessage && <span
                        className={classNames('&-date-prefix')}><FormattedMessage {...datePrefixMessage} /></span>}
                    {intl.formatDate(date, DATE_FORMAT)}
                </span>
            )}
            {user && (
                <span data-testid='date-by-user-author' className={classNames('&-username')}>
                    <span className={classNames('&-by')}>{intl.formatMessage(messages.by)}</span>

                    {highlightSplit(formatUser(intl, user, me), search)}
                </span>
            )}
        </span>
    );
}
