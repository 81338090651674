import { cloneDeep, set, sum } from 'lodash';

import { RuleTarget } from '../../../models/policy';
import {
    TREE_BLOCK_HEIGHT,
    isUniverseTypeAndPropertyFilter,
    isUniverseItemObject,
    isEmptyObject,
} from '../policy-utils';
import { Tree } from './tree';

interface TreeBlocksProps<T> {
    targets: RuleTarget[];
    block: 'and' | 'or';
    currentPath: string;
    onChange: React.Dispatch<React.SetStateAction<T>>;
    editable: boolean;
}

export const TreeBlocks = <T extends { Targets: RuleTarget[] }>(props: TreeBlocksProps<T>) => {
    const { targets, block, currentPath, onChange, editable } = props;
    const sizes = targets.map((expression) => getRecursiveSizes(expression, editable));

    const changeOperator = () => {
        if (!editable) return;
        onChange((currentRule) => {
            const newOperator = block === 'and' ? 'or' : 'and';

            return set(cloneDeep(currentRule), currentPath, {
                [newOperator]: targets,
            });
        });
    };

    return <Tree sizes={sizes} block={block} changeOperator={changeOperator} />;
};

const getRecursiveSizes = (target: RuleTarget, editable: boolean): number => {
    const buttonsExtraHeight = editable ? 21 : 7.5;

    if (
        isUniverseItemObject(target) ||
        isUniverseTypeAndPropertyFilter(target) ||
        isEmptyObject(target)
    ) {
        return TREE_BLOCK_HEIGHT;
    }
    if (target.and) {
        return (
            sum(target.and.map((expression) => getRecursiveSizes(expression, editable))) +
            buttonsExtraHeight
        );
    }
    if (target.or) {
        return (
            sum(target.or.map((expression) => getRecursiveSizes(expression, editable))) +
            buttonsExtraHeight
        );
    }

    return 0;
};
