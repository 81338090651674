import { Plugin } from '@ckeditor/ckeditor5-core';
import ResizeExploration2Command from './resize-exploration2-command';
import Exploration2Resize from './exploration2-resize';


export default class Exploration2ResizeEditing extends Plugin {
    /**
     * @inheritDoc
     */
    static get requires() {
        return [Exploration2Resize];
    }

    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'argonos-exploration:resize-editing';
    }

    /**
     * @inheritDoc
     */
    constructor(editor) {
        super(editor);

        editor.config.define('exploration2', {
            resizeUnit: '%',
            resizeOptions: [{
                name: 'resizeExploration2:100',
                value: '100',
                icon: 'original',
            },
            {
                name: 'resizeExploration2:25',
                value: '25',
                icon: 'small',
            },
            {
                name: 'resizeExploration2:50',
                value: '50',
                icon: 'medium',
            },
            {
                name: 'resizeExploration2:75',
                value: '75',
                icon: 'large',
            }],
        });
    }

    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;
        const resizeExploration2Command = new ResizeExploration2Command(editor);

        editor.commands.add('resizeExploration2', resizeExploration2Command);
    }
}
