import { Menu } from 'antd';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
    ArgIcon,
    ArgMenu,
    ArgMenuItem,
    ArgTooltip2,
    ProgressMonitor,
    highlightSplit,
    useArgModalContext,
    useClassNames,
    ClassValue,
} from 'src/components/basic';
import { Role, RoleId, RolesScope } from 'src/settings/models/dtoApi';
import { AddRoleModal } from '../components/add-role-modal';
import { RoleActionsMenu } from '../components/role-actions-menu';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';

import './roles-list.less';

const messages = defineMessages({
    addRole: {
        id: 'settings.roles-list.add-role',
        defaultMessage: 'Add role',
    },
});

export interface RoleListProps {
    className?: ClassValue;
    searchToken?: string;
    roles?: Role[];
    selectedRole?: Role;
    setSelectedRole: Dispatch<SetStateAction<Role | undefined>>;
    rolesScope: RolesScope;
    handleOnRoleChange: (progressMonitor: ProgressMonitor, roleId?: RoleId) => void;
}

export function RolesList(props: RoleListProps) {
    const {
        className,
        searchToken,
        roles,
        selectedRole,
        setSelectedRole,
        rolesScope,
        handleOnRoleChange,
    } = props;

    const modalContext = useArgModalContext();

    const classNames = useClassNames('settings-roles-list');

    const canEditRoles = useHasPermission<SettingsPermissions>('admin.user.role.edition');

    useEffect(() => {
        if (roles === undefined || roles.length === 0) {
            setSelectedRole(undefined);

            return;
        }

        if (!selectedRole || !roles?.find((role) => role.id === selectedRole.id && role.isDeleted === false) || selectedRole.isDeleted) {
            setSelectedRole(roles[0]);
        }
    }, [roles, selectedRole]);

    const selectedKey = selectedRole ? [selectedRole.id] : undefined;

    const menuItems = useCallback(() => {
        if (!roles) {
            return;
        }

        return roles.map((role) => {
            return <ArgMenuItem
                key={role.id}
                className={classNames('&-menu-item', role.isDeleted ? 'deleted' : '')}
                onClick={() => {
                    if (!role.isDeleted) {
                        setSelectedRole(role);
                    }
                }}>
                <div className={classNames('&-menu-item-title')}>
                    <ArgTooltip2
                        key={`${role.id}-title`}
                        title={role.displayName}
                    >
                        <div className={classNames('&-menu-item-title-name')}>
                            <div className='ellipsis'>
                                {highlightSplit(role.displayName, searchToken)}
                            </div>
                            {role.hasDraft && <div className={classNames({ '&-role-is-draft': role.hasDraft })}></div>}
                        </div>
                    </ArgTooltip2>
                    <ArgTooltip2
                        key={`${role.id}-desc`}
                        title={role.description}
                    >
                        <span className={classNames('&-menu-item-title-description', 'ellipsis')}>{role.description}</span>
                    </ArgTooltip2>
                </div>
                {!role.readOnly && !role.isDeleted && <RoleActionsMenu
                    role={role}
                    rolesScope={rolesScope}
                    handleOnRoleChange={handleOnRoleChange}
                />}
            </ArgMenuItem>;
        });
    }, [classNames, handleOnRoleChange, roles, rolesScope, searchToken, setSelectedRole]);

    return <ArgMenu mode='inline' selectedKeys={selectedKey} className={classNames('&', className)}>
        {menuItems()}
        {canEditRoles && (
            <Menu.Item
                key='settings-roles-add-button'
                className={classNames('&-add')}
                onClick={() => {
                    modalContext.open('settings-add-role-modal',
                        <AddRoleModal
                            rolesScope={rolesScope}
                            handleOnRoleCreated={(roleId, progressMonitor) => {
                                handleOnRoleChange(progressMonitor, roleId);
                            }}
                            onClose={() => {
                                modalContext.close('settings-add-role-modal');
                            }}
                            isRoleNameInvalid={(name?: string) => {
                                if (!roles) {
                                    return false;
                                }

                                return roles?.findIndex((role) => role.displayName === name) >= 0;
                            }}
                        />
                    );
                }}
            >
                <ArgIcon name='icon-plus1' />
                <FormattedMessage {...messages.addRole} />
            </Menu.Item>
        )}
    </ArgMenu>;
}
