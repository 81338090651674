
import { ProgressMonitor, highlightSplit, useClassNames } from 'src/components/basic';
import { DateByUser } from 'src/components/common/date-by-user';
import { BriefTemplate } from 'src/exploration/model/template';
import { TemplateActionsMenu } from '../../../settings/configuration/components/library-configuration/actions/template-action-menu';

import './brief-template-list-item.less';

export interface BriefTemplateListItemProps {
    template: BriefTemplate;
    displayContextualMenuButton?: boolean;
    onClick: (template: BriefTemplate) => void;
    onDeleteTemplateConfirm?: (progressMonitor: ProgressMonitor, template: BriefTemplate) => Promise<void>;
    onRenameTemplateConfirm?: (progressMonitor: ProgressMonitor, template: BriefTemplate, newName: string) => Promise<void>;
    selected?: boolean;
    searchToken?: string;
}

export function BriefTemplateListItem(props: BriefTemplateListItemProps) {
    const {
        template,
        displayContextualMenuButton,
        onClick,
        onDeleteTemplateConfirm,
        onRenameTemplateConfirm,
        selected,
        searchToken,
    }: BriefTemplateListItemProps = props;

    const classNames = useClassNames('exploration-brief-template-list-item');

    return (
        <div
            className={classNames('&', {
                selected: selected,
            })}
        >
            <div className={classNames('body')}
                 onClick={() => {
                     onClick(template);
                 }}
            >
                <div className='display-name'>{highlightSplit(template.name, searchToken)}</div>
                <div className='info'>
                    <DateByUser
                        className='info'
                        date={new Date(template.lastUpdatedDate ?? template.createdDate!)}
                        relative={true}
                        user={template.lastUpdatedBy}
                    />
                </div>
            </div>
            <div>
                {displayContextualMenuButton &&
                    <TemplateActionsMenu className={classNames('&-contextual-menu')}
                                         template={template}
                                         size='medium'
                                         onDeleteTemplateConfirm={onDeleteTemplateConfirm}
                                         onRenameTemplateConfirm={onRenameTemplateConfirm}
                    />
                }
            </div>
        </div>
    );
}
