import React from 'react';
import { map } from 'lodash';

import { ArgButton, ArgIcon, useClassNames } from '../../../../basic';
import { PAGE_COUNT } from './icon-picker';

import './icon-displayer.less';

interface IconDisplayerProps {
    icons: string[];
    selectedIcon?: string;
    page: number;
    pagesCount: number;

    onNextPageClick?: () => void;
    onPreviousPageClick?: () => void;
    onIconChange?: (iconName: string) => void;
}
export const forbiddenIcons = ['long-arrow-left', 'long-arrow-right'];

export const IconDisplayer = ({
    icons,
    selectedIcon,
    page,
    pagesCount,
    onNextPageClick,
    onPreviousPageClick,
    onIconChange,
}: IconDisplayerProps) => {
    const classNames = useClassNames('icon-displayer');

    const cls = (iconName: string) => ({
        selected: selectedIcon === iconName,
    });


    const iconsListToDisplay = icons.filter((icon) => !forbiddenIcons.includes(icon)).slice(PAGE_COUNT * (page - 1), PAGE_COUNT * page);

    if (icons.length === 0) {
        return (
            <>
                <div> Your search did not match any content.</div>
                <div> Please try another term.</div>
            </>
        );
    }

    return (
        <div className={classNames('&')}>
            <ArgButton
                type='ghost'
                disabled={page === 1}
                size='medium'
                icon={
                    <ArgIcon
                        className={classNames('&-left-pager', { disabled: page === 1 })}
                        name='icon-triangle-left'
                    />
                }
                onClick={onPreviousPageClick}
            />

            <div className={classNames('&-items-container')}>
                {map(iconsListToDisplay, (iconName) => {
                    return (
                        <ArgButton
                            key={iconName}
                            type='ghost'
                            size='medium'
                            icon={
                                <ArgIcon
                                    key={iconName}
                                    className={classNames('&-item', cls(iconName))}
                                    name={iconName}
                                    size='20px'
                                />
                            }
                            onClick={() => onIconChange?.(iconName)}
                        />
                    );
                })}
            </div>

            <ArgButton
                type='ghost'
                disabled={page === pagesCount}
                size='medium'
                icon={
                    <ArgIcon
                        className={classNames('&-right-pager', { disabled: page === pagesCount })}
                        name='icon-triangle-right'
                    />
                }
                onClick={onNextPageClick}
            />
        </div>
    );
};
