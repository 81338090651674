import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ArgRelativeTime, ArgTableColumn3, ArgUser, useMemoAsync } from 'src/components/basic';
import { User } from 'src/model/user';
import { UserActionsMenu } from '../user-action-menu/user-action-menu';
import { useHasAnyPermissions } from '../../../../contexts/user-permission';
import { SettingsPermissions } from '../../../permissions/permissions';
import { UsersConnector } from '../../../../utils/connectors/users-connector';

import './users-table.less';

const messages = defineMessages({
    by: {
        id: 'settings.users.users-table-last-modified-by',
        defaultMessage: 'By ',
    },
    active: {
        id: 'settings.users.users-table-active',
        defaultMessage: 'Active',
    },
    inactive: {
        id: 'settings.users.users-table-inactive',
        defaultMessage: 'Inactive',
    },
    fullName: {
        id: 'settings.users.users-table-column.name',
        defaultMessage: 'Full name',
    },
    username: {
        id: 'settings.users.users-table-column.username',
        defaultMessage: 'Username',
    },
    identityIssuer: {
        id: 'settings.users.users-table-column.identityIssuer',
        defaultMessage: 'Identity Issuer',
    },
    status: {
        id: 'settings.users.users-table-column.status',
        defaultMessage: 'Status',
    },
    lastUpdatedDate: {
        id: 'settings.users.users-table-column.lastUpdatedDate',
        defaultMessage: 'Last Modified',
    },
});

export const useTableColumns = (): ArgTableColumn3<User>[] => {
    const intl = useIntl();

    const canEditUsers = useHasAnyPermissions<SettingsPermissions>('admin.user.edition', 'admin.user.management');

    const [userProfilesFields] = useMemoAsync(async (progressMonitor) => {
        try {
            const _userProfilesFields = await UsersConnector.getInstance().getUserProfileFields(progressMonitor);

            return _userProfilesFields;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }
            console.error(error);
        }
    }, []);

    const columns = useMemo<ArgTableColumn3<User>[]>(() => {
        const ret: ArgTableColumn3<User>[] = [];

        ret.push(
            {
                key: 'icon',
                sortable: false,
                columnName: ' ',
                title: ' ',
                dataIndex: 'id',
                width: 45,
                resizable: false,
                render: function display(id, user) {
                    return <ArgUser user={user} label={false} />;
                },
            },
            {
                key: 'displayName',
                sortable: true,
                columnName: 'Full name',
                title: messages.fullName,
                dataIndex: 'displayName',
            },
            {
                key: 'userName',
                sortable: true,
                columnName: 'Username',
                title: messages.username,
                dataIndex: 'userName',
            },
            {
                key: 'identityIssuer',
                sortable: true,
                columnName: 'Identity Issuer',
                title: messages.identityIssuer,
                dataIndex: 'identityIssuer',
                render: function display(identityIssuer) {
                    return <span>{identityIssuer || 'Local'}</span>;
                },
            },
            {
                key: 'status',
                sortable: true,
                columnName: 'Status',
                title: messages.status,
                dataIndex: 'isActive',
                render: function display(status) {
                    return (
                        <span>
                            {status
                                ? intl.formatMessage(messages.active)
                                : intl.formatMessage(messages.inactive)}
                        </span>
                    );
                },
            },
            {
                key: 'lastModified',
                sortable: true,
                columnName: 'Last Modified',
                title: messages.lastUpdatedDate,
                dataIndex: 'lastUpdatedDate',
                render: function display(data, user) {
                    return user.lastUpdatedDate ? (
                        <div className='users-table-last-modified'>
                            <ArgRelativeTime date={new Date(user.lastUpdatedDate)} numeric='auto' />
                            <span className='users-table-last-modified-user'>
                                {intl.formatMessage(messages.by)}
                                {user.lastUpdatedBy?.displayName}
                            </span>
                        </div>
                    ) : (
                        <div />
                    );
                },
                defaultSortOrder: 'ascend',
                sorter: (a, b) => {
                    if (a.lastUpdatedDate && b.lastUpdatedDate) {
                        const first = new Date(a.lastUpdatedDate.toString());
                        const second = new Date(b.lastUpdatedDate.toString());

                        return second.getTime() - first.getTime();
                    }

                    return -1;
                },
            }
        );

        if (canEditUsers) {
            ret.push(
                {
                    key: 'actions',
                    columnName: ' ',
                    title: ' ',
                    dataIndex: 'actions',
                    sortable: false,
                    render: function display(data: string, user: User) {
                        return <UserActionsMenu user={user} userProfilesFields={userProfilesFields} />;
                    },
                }
            );
        }

        return ret;
    }, [canEditUsers, intl, userProfilesFields]);

    return columns;
};
