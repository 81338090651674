import { toNumber } from 'lodash';

import { Environment } from '../../utils/environment';
import { DataViewMode } from '../model/view-mode';
import { GraphLayout } from '../model/graph-layout';

export class ExplorationEnvironment extends Environment {
    static document_list_display_limit =
        (process.env.NODE_ENV === 'development'
            ? toNumber(process.env.REACT_APP_DOCUMENT_LIST_DISPLAY_LIMIT)
            : window.argonodeConfig?.documentListDisplayLimit) || 5;

    static picture_row_count_display_limit =
        (process.env.NODE_ENV === 'development'
            ? toNumber(process.env.REACT_APP_PICTURE_ROW_COUNT_DISPLAY_LIMIT)
            : window.argonodeConfig?.pictureRowCountDisplayLimit) || 2;

    static property_list_display_limit =
        (process.env.NODE_ENV === 'development'
            ? toNumber(process.env.REACT_APP_PROPERTY_LIST_DISPLAY_LIMIT)
            : window.argonodeConfig?.propertyListDisplayLimit) || 10;

    static openInformationPanelByDefault = false;
    static openTimelineByDefault = false;

    static defaultViewMode: DataViewMode = {
        graph: { layout: GraphLayout.Organic },
    };
}
