import { LegalFramework, LegalRegime, ProceoFolder } from './folder';
import { RoleAddress } from './address';
import { ArgUserId, ArgUserInfo } from '../../components/basic';
import { PersonId, ProceoPerson } from './person';
import { RawAct, RawFullAct } from '../utils/connectors/raws';
import { Offence, OffenceId } from './reference';
import { Address } from '../../model/address';
import { PublicUser } from '../../model/user';
import { Service } from './service';
import { Gender, Grade } from '../utils/user-utils';
import { InterpreterUsageId } from './interpreter';

export type ActId = string;
export type ActTypeId = string;
export type ActTemplateId = string;

export enum CreationActMode {
    /**
     * Create an act using an existing act template model
     */
    TEMPLATE = 'TEMPLATE',
    /**
     * Creates an act using the wizard to create a GAV, this is a sample of type of creation to do
     */
    PLACEMENT_GAV_WIZARD = 'PLACEMENT_GAV_WIZARD',
}

/**
 * Describe the nature of an act
 */
export enum ActNature {
    PV = 'PV',
    REPORT = 'REPORT',
    OTHER = 'OTHER',
}

/**
 * Describe the type of signature expected for an act
 */
export enum SignatureType {
    ALWAYS= 'ALWAYS',
    NEVER = 'NEVER',
    SOMETIMES = 'SOMETIMES',
}

/**
 * Describe if the begin hour of act should be required, optional or should not be set for an act
 */
export enum BeginHour {
    REQUIRED = 'REQUIRED',
    OPTIONAL = 'OPTIONAL',
    NEVER = 'NEVER',
}

export enum ActDeletionPermission {
    /**
     * Anybody can delete this type of acte
     */
    ANY = 'ANY',
    /**
     * User must be the acte creator to delete this type of acte
     */
    CREATOR_OR_PROCEDURIER = 'CREATOR_OR_PROCEDURIER',
}

export enum ActCreationPermission {
    /**
     * Anybody can create this type of acte
     */
    ANY = 'ANY',
    /**
     * User must be APJ or OPJ to create this type of acte
     */
    APJ_OR_OPJ = 'APL_OR_OPJ',
    /**
     * User must be OPJ to create this type of acte
     */
    OPJ = 'OPJ',
}

export enum ActVisibility {
    /**
     * All user can see the type of the act
     */
    National = 'National',
    /**
     * Only user of the same service can see the type of act
     */
    Service = 'Service',
    /**
     * The type of the act is visible the the current user only
     */
    Personal = 'Personal',
}


/**
 * Describe the type of an act. The type contain some information about the act to be written in
 * the case (Folder).
 */
export interface ActType {
    id: ActTypeId;
    name: string;
    /**
     * @default false
     */
    outOfFolder?: boolean;
    //signatureType: SignatureType;
    // beginHour: BeginHour;
    nature: ActNatureType;
    actCreationPermission: ActCreationPermission;
    actDeletionPermission: ActDeletionPermission;
}

/**
 * Model of an act that contain the type of the act
 *
 */
export interface ActTemplate {
    id: ActTemplateId,
    name: string;
    creationMode: CreationActMode;
    /**
     * default template to use to generate the act
     */
    document: string;
    custom: boolean;
    visibility: ActVisibility;
    actType: ActType;
    createdDate: Date;
    createdBy: ArgUserInfo;
    actTypeId?: ActTypeId;
    lastUpdatedDate?: Date;
    lastUpdatedBy?: ArgUserInfo;
}

export interface ActTemplateToCreate {
    name?: string;
    content?: string;
    isCustom?: boolean;
    creationMode?: CreationActMode;
    visibility?: ActVisibility;
    actTypeId?: ActTypeId;
}

/**
 * To generate alerts from an act, we will use the type of an act. And to recognise of type of Act,
 * this enum can be used by the rules engine
 */
export enum ActNatureType {
    UNDEFINED = 'UNDEFINED',
    SAISINE = 'SAISINE',
    PV_BLANC = 'PV_BLANC',
    REPORT = 'REPORT',
    REQUISITION = 'REQUISITION',
    NOTIF_PLACEMENT_GAV = 'NOTIF_PLACEMENT_GAV',
    PLAINTE = 'PLAINTE',
    AUDITION = 'AUDITION',
    FICHE_SCELLE = 'FICHE_SCELLE',
    SERMENT_INTERPRETER = 'SERMENT_INTERPRETER',
    AUTRE = 'AUTRE',
}

export enum ActNatureTypeDisplayName {
    UNDEFINED = 'UNDEFINED',
    SAISINE = 'SAISINE',
    PV_BLANC = 'AUTRE',
    REPORT = 'REPORT',
    NOTIF_PLACEMENT_GAV = 'NOTIF_PLACEMENT_GAV',
    PLAINTE = 'PLAINTE',
    AUDITION = 'AUDITION',
    FICHE_SCELLE = 'FICHE_SCELLE',
    SERMENT_INTERPRETER = 'SERMENT_INTERPRETER',
    AUTRE = 'AUTRE',
    REQUISITION = 'REQUISITION',
}

export enum DeclarantSignature {
    NONE = 'None',
    ACCEPTED = 'Accepted',
    DENIED = 'Denied',
}

export enum DeclarantReading {
    NONE = 'None',
    HIMSELF = 'Himself',
    OURSELVES = 'Ourselves',
    INTERPRETER = 'Interpreter',
}

export interface ActAddress {
    address: Address;
    role: RoleAddress;
}

export interface ActToCreate {
    name: string;
    displayName: string;
    actTypeId: ActTypeId;
    actTemplateId?: ActTemplateId;
    editorId?: ArgUserId;
    legalFramework: LegalFramework,
    legalRegime: LegalRegime,
    offenceIds: OffenceId[];
    personIds: PersonId[];
    assistantIds?: ArgUserId[];
    interpreterUsageIds?: InterpreterUsageId[];
    createdPlace?: string;
    status: ActStatus;
    startActDate?: Date;
    endActDate?: Date;
    declarantSignature?: DeclarantSignature;
    declarantReading?: DeclarantReading;
    //stakeHolders: StakeHolder[];
    //language?: Language;
    //oathCourtInterpreter?: Court; //tribunal d'assermentation de l'interprete
    /**
     * serment prealablement prete, l'interprete doit preter serment s'il n'est pas assermente.
     * Un PV de serment dans ce cas doit etre genere
     */
    //isInterpreterOath?: boolean;
}

export interface Act extends Omit<RawFullAct, | 'createdDate' | 'lastUpdatedDate' | 'startActDate' | 'endActDate' | 'persons' | 'offences' | 'actTemplate'> {
    createdDate: Date;
    lastUpdatedDate?: Date;
    startActDate: Date;
    endActDate?: Date;
    persons?: ProceoPerson[];
    offences?: Offence[];
    actTemplate?: ActTemplate;
}

export interface ActLight extends Omit<RawAct, | 'createdDate' | 'lastUpdatedDate' | 'startActDate' | 'endActDate'> {
    createdDate: Date;
    lastUpdatedDate?: Date;
    startActDate: Date;
    endActDate?: Date;
}

export enum ActStatus {
    Draft = 'Draft',
    Running = 'Running',
    Done = 'Done',
    ToValidate = 'ToValidate',
    Corrections = 'Corrections',
    Validate = 'Validate',
    Signed = 'Signed',
    Transmitted = 'Transmitted',
    Trash = 'Trash',
}

// TODO: To be fulfilled with other "patchable" properties of Act
export enum ActJson {
    personIds ='personIds',
}

export enum ActTemplateSortBy {
    Name = 'Name',
}

/**
 * Define some extra data according to an act
 */
export interface ActInfo extends ActEditorInfo {
    folder?: ProceoFolder; //the procefolder parent if the act is not isolated
    assistants?: ActAssistant[]; //the information about assistants of the act
}

export interface ActEditorInfo {
    editor: PublicUser; // the editor of the act
    editorGender: Gender; //the editor gender
    editorGrade: Grade; // the editor grade
    editorRoles: string[]; //the editor roles
    editorService: Service | null; //the service of the editor
}

/**
 * Define necessary information about assistant displayed in the content of an act
 */
export interface ActAssistant {
    user: PublicUser;
    service: Service | null;
    grade: Grade;
    gender: Gender;
}
