import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import type { Identifier } from 'dnd-core';
import { defineMessages } from 'react-intl';

import { ArgIcon, ARG_BYPASS_DND_DISABLER_CLASSNAME, useClassNames } from 'src/components/basic';
import { FormContainer, FormElement } from 'src/components/common/forms/model';
import { FormRenderContext } from 'src/components/common/forms/render-factory';
import { createFormMoveElements } from '../actions/move-elements';
import { OntologyFormContext } from '../types';
import { DraggabeFormElements, canDropChildrenInside } from '../utils';
import { FormRepository } from '../actions/form-actions-engine';
import { useDragging } from './dragging-context';
import { EmptyPane } from 'src/components/common/panes/empty-pane';

import './form-droppable-container.less';

const messages = defineMessages({
    noProperties: {
        id: 'common.forms.editable-form.NoProperties',
        defaultMessage: 'No properties',
    },
});

export interface FormDroppableContainerProps {
    children: React.ReactNode;
    formContainer: FormContainer;
    formContext: FormRenderContext;
    ontologyContext: OntologyFormContext;
}

export function FormDroppableContainer(props: FormDroppableContainerProps) {
    const { children, formContainer, formContext, ontologyContext } = props;
    const { formActionsEngine } = ontologyContext;
    const { setInternalFormDocument, formDocument } = formContext;

    const classNames = useClassNames('form-droppable-container');
    const draggingId = useDragging();
    const addRef = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<FormElement, void, { handlerId: Identifier | null }>({
        accept: DraggabeFormElements,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop(item) {
            const hoverIndex = formContainer.children.indexOf(item);

            if (hoverIndex === -1) {
                return;
            }

            const childrensWithoutMovedElement = formContainer.children.filter((formEl) => formEl.id !== item.id);
            const newFormContainer = { ...formContainer, children: childrensWithoutMovedElement };
            const action = createFormMoveElements(newFormContainer, [item], hoverIndex);
            formActionsEngine.do(action).catch(console.error);
        },
        hover(item, monitor) {
            if (formContainer.children.indexOf(item) !== -1) {
                return;
            }

            const addRect = addRef.current?.getBoundingClientRect();
            const coords = monitor.getClientOffset();

            if (!coords || !addRect) {
                return;
            }

            const { x, y } = coords;

            if (
                x < addRect.left
                || x > addRect.right
                || y < addRect.top
                || y > addRect.bottom
            ) {
                return;
            }

            const tempForm = new FormRepository(formDocument);
            tempForm.add(formContainer, [item], formContainer.children.length);
            setInternalFormDocument(tempForm.formDocument);
        },
    });

    const more = formContainer.children.length === 0
        || formContainer.children.every((formEl) => canDropChildrenInside(formEl));

    const isDragging = draggingId && draggingId !== formContainer.id;

    const cls = {
        candrop: isDragging,
    };

    const isDocument = formContainer.type === 'document';
    const haveChild = !!children && (Array.isArray(children) && children.length > 0);

    return (
        <div ref={drop} className={classNames('&', cls, ARG_BYPASS_DND_DISABLER_CLASSNAME)} data-handler-id={handlerId}>
            {children}
            {(isDragging && more) && (
                <div ref={addRef} className={classNames('&-add-item')}>
                    <ArgIcon name='icon-add-outline' />
                </div>
            )}
            {(isDocument && !haveChild && !isDragging) && (
                <EmptyPane
                    className={classNames('&-document-children-empty-pane')}
                    message={messages.noProperties}
                    size='extra-large'
                />
            )}
        </div>
    );
}
