import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Policy } from 'src/settings/models/dtoApi';
import {
    ArgCheckboxMinus,
    normalizeText,
    ProgressMonitor,
    useCallbackAsync,
    useClassNames, useArgNotifications,
} from 'src/components/basic';
import { PolicyComponent } from './policy-component';
import { ARG_BYPASS_DND_DISABLER_CLASSNAME } from '../../../../../components/basic/arg-dnd/disable-dnd-container';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';

interface CreatePolicyTableProps {
    policies: Policy[];
    setPolicies: Dispatch<SetStateAction<Policy[]>>;
    searchInputText: string | null;
}

export const messages = defineMessages({
    orderColumn: {
        id: 'settings.policies-list.order',
        defaultMessage: 'Order',
    },
    nameColumn: {
        id: 'settings.policies-list.name',
        defaultMessage: 'Name',
    },
    descriptionColumn: {
        id: 'settings.policies-list.description',
        defaultMessage: 'Description',
    },
    state: {
        id: 'settings.policies-list.state',
        defaultMessage: 'State',
    },
    lastPublished: {
        id: 'settings.policies-list.lastPublished',
        defaultMessage: 'Last published',
    },
    statusColumn: {
        id: 'settings.policies-list.field.status',
        defaultMessage: 'Status',
    },
    reorderPoliciesError: {
        id: 'settings.policies-list.field.reorderPoliciesError',
        defaultMessage: 'Something went wrong while reordering the policies',
    },
});

export function PoliciesList(props: CreatePolicyTableProps) {
    const {
        policies,
        setPolicies,
        searchInputText,
    } = props;

    const notifications = useArgNotifications();
    const [policiesIdSelected, setPoliciesIdSelected] = useState<Set<string>>(new Set());

    const classNames = useClassNames('settings-policy-component');

    const handleAllPoliciesSelection = () => {
        if (policiesIdSelected.size > 0) {
            setPoliciesIdSelected(new Set());

            return;
        }
        const policiesIds = policies.map((policy) => policy.id);
        setPoliciesIdSelected(new Set(policiesIds));
    };

    const getAllPoliciesCheckboxValue = () => {
        if (policiesIdSelected.size === 0) {
            return false;
        }
        if (policiesIdSelected.size === policies.length) {
            return true;
        }

        return 'minus';
    };

    const movePolicies = useCallback((dragIndex: number, hoverIndex: number) => {
        setPolicies((prevPoliciesList) => {
            const newPoliciesList = cloneDeep(prevPoliciesList);
            newPoliciesList.splice(dragIndex, 1);
            newPoliciesList.splice(hoverIndex, 0, prevPoliciesList[dragIndex]);

            return newPoliciesList;
        });
    }, []);

    const [reorderPolicies] = useCallbackAsync(async (progressMonitor: ProgressMonitor, policies: Policy[]) => {
        try {
            await explorationSettingsConnector.reorderPolicies(policies.map((policy) => policy.id), progressMonitor);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.reorderPoliciesError }, error as Error);
        }
    }, []);

    const filterPolicies = useMemo<Policy[]>(() => {
        if (!searchInputText) {
            return policies;
        }

        const normalizedSearchInputText = normalizeText(searchInputText);

        const ret = policies.filter(
            (policy) =>
                normalizeText(policy.name).includes(normalizedSearchInputText) ||
                (policy.description && normalizeText(policy.description).includes(normalizedSearchInputText))
        );

        return ret;
    }, [searchInputText, policies]);

    return (
        <DndProvider backend={HTML5Backend}>
            <div className={ARG_BYPASS_DND_DISABLER_CLASSNAME}>
                <div className={classNames('&-headers')}>
                    <div className={classNames('&-checkbox')}>
                        <ArgCheckboxMinus
                            size='node'
                            value={getAllPoliciesCheckboxValue()}
                            onChange={() => handleAllPoliciesSelection()}
                        />
                    </div>
                    <div className={classNames('&-fields-headers')}>
                        <div className={classNames('&-index')}>
                            <FormattedMessage {...messages.orderColumn} />
                        </div>
                        <div className={classNames('&-fields-headers')}>
                            <div className={classNames('&-name')}>
                                <FormattedMessage {...messages.nameColumn} />
                            </div>
                            <div className={classNames('&-description')}>
                                <FormattedMessage {...messages.descriptionColumn} />
                            </div>
                            <div className={classNames('&-update-status')}>
                                <FormattedMessage {...messages.state} />
                            </div>
                            <div className={classNames('&-last-modified')}>
                                <FormattedMessage {...messages.lastPublished} />
                            </div>
                            <div className={classNames('&-enabled')}>
                                <FormattedMessage {...messages.statusColumn} />
                            </div>
                        </div>
                        <div className={classNames('&-kebab')} />
                    </div>
                </div>
                {filterPolicies.map((policy, index) => (
                    <PolicyComponent
                        key={policy.id}
                        selected={policiesIdSelected.has(policy.id)}
                        visualIndex={filterPolicies.length - index}
                        index={index}
                        policy={policy}
                        setPoliciesIdSelected={setPoliciesIdSelected}
                        setPolicies={setPolicies}
                        movePolicies={movePolicies}
                        onReorder={() => reorderPolicies(policies)}
                        textToHighlight={searchInputText}
                    />
                ))}
            </div>
        </DndProvider>
    );
}
