import { defineMessages } from 'react-intl';

import { RolesScope } from '../models/dtoApi';
import { getArgonosModuleById, listArgonosModules } from '../../components/application/modules-manager';
import { ArgonosModule } from '../../components/application/modules';

const messages = defineMessages({
    settingsApplication: {
        id: 'settings.roles.scopes.Settings',
        defaultMessage: 'Settings',
    },
});

export function getTagIcon(rolesScope: RolesScope) {
    const argonosModule = listArgonosModules().find((m: ArgonosModule) => m.scope === rolesScope).value();

    return argonosModule?.iconURL || '';
}

export function getTagBackgroundColor(rolesScope: RolesScope) {
    const argonosModule = listArgonosModules().find((m: ArgonosModule) => m.scope === rolesScope).value();

    if (argonosModule?.color) {
        return argonosModule.color;
    }

    return getArgonosModuleById('chapsVision.Settings')?.color;
}

export function getScopeDisplayName(rolesScope: RolesScope) {
    const argonosModule = listArgonosModules().find((m: ArgonosModule) => m.scope === rolesScope).value();

    if (argonosModule?.name) {
        return argonosModule.name;
    }

    return messages.settingsApplication;
}
