import { useContext, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import {
    ArgButton,
    ArgInputSearch,
    ArgTable3,
    ArgTableColumn3,
    ArgUploaderButton,
    ClassValue,
    ProgressMonitor,
    SelectionProvider,
    SubProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
    useSelectionList,
} from 'src/components/basic';
import { EmptyPane } from '../../../components/common/panes/empty-pane';
import { UserProfileField } from '../../../model/user-metadata';
import { PropertiesDataFilter, PropertiesDataProvider } from '../providers/properties-data-provider';
import { useTableColumns } from './columns';
import { PropertyActionsButtonMenu } from './property-actions-button-menu';
import configurationSettingsConnector from '../../../utils/connectors/configuration-connector';
import { ConfigurationsScope, ConfigurationType } from '../../configuration/configuration-type';
import { ImportAction, ImportExportOptions } from '../../../model/configuration';
import { downloadBlob } from '../../../utils/file';
import { UsersAndGroupsStateContext } from '../../users/providers/usersState';
import {
    ConfigurationErrorNotificationDescription,
} from '../../configuration/components/configuration-error-notification-description/configuration-error-notification-description';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';
import { UsersConnector } from '../../../utils/connectors/users-connector';

import './properties-table.less';

const CLASSNAME = 'settings-properties-table';

const SUPPORTED_IMPORT_MIME_TYPES = [
    'application/x-zip-compressed', 'application/zip',
];

const CONFIGURATION_SCOPE: ConfigurationsScope = 'settings';

const messages = defineMessages({
    newProperty: {
        id: 'settings.properties.new',
        defaultMessage: 'Create',
    },
    searchPlaceHolder: {
        id: 'settings.properties.search',
        defaultMessage: 'Search...',
    },
    noProperties: {
        id: 'settings.properties.noProperties',
        defaultMessage: 'No properties',
    },
    propertiesEmpty: {
        id: 'settings.properties.propertiesEmpty',
        defaultMessage: 'Create a new property.',
    },
    invalidFileType: {
        id: 'settings.properties.invalidFileType',
        defaultMessage: 'Invalid file type',
    },
    importPropertiesError: {
        id: 'settings.properties.importPropertiesError',
        defaultMessage: 'An error occurred while importing the properties',
    },
    import: {
        id: 'settings.properties.import',
        defaultMessage: 'Import',
    },
    export: {
        id: 'settings.properties.export',
        defaultMessage: 'Export',
    },
    exportSucceed: {
        id: 'settings.properties.exportSucceed',
        defaultMessage: 'Properties have successfully been exported',
    },
    exportError: {
        id: 'settings.properties.exportError',
        defaultMessage: 'An error occurred while exporting the properties',
    },
    importSucceed: {
        id: 'settings.properties.importSucceed',
        defaultMessage: 'Configurations have successfully been imported',
    },
    importConfigurationError: {
        id: 'settings.properties.importConfigurationError',
        defaultMessage: 'Cannot import configurations',
    },
    importConfigurationDescriptionError: {
        id: 'settings.properties.importConfigurationDescriptionError',
        defaultMessage: '{count, plural, =1 {1 error detected } other {{count} errors detected}}',
    },
});

export interface PropertiesTableProps {
    properties: PropertiesDataProvider;
    className?: ClassValue;
    selectionProvider: SelectionProvider<UserProfileField>;
    handleAddProperty: () => void;
}

export function PropertiesTable(props: PropertiesTableProps) {
    const {
        className,
        properties,
        selectionProvider,
        handleAddProperty,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const canEditProperties = useHasPermission<SettingsPermissions>('admin.user.fields.management');
    const canImportExport = useHasPermission<SettingsPermissions>('admin.import.export.settings');

    const { setProperties } = useContext(UsersAndGroupsStateContext);

    const [selectedPropertiesId] = useSelectionList(selectionProvider);

    const selectedProperties = useMemo(() => (
        properties.getData().filter((property) => selectedPropertiesId.includes(property.id))
    ), [selectedPropertiesId, properties]);

    const columns: ArgTableColumn3<UserProfileField>[] = useTableColumns();
    const [filter, setFilter] = useState<PropertiesDataFilter>({});

    const notifications = useArgNotifications();

    const [importProperties] = useCallbackAsync(async (progressMonitor: ProgressMonitor, file: Blob) => {
        if (!file || !SUPPORTED_IMPORT_MIME_TYPES.includes(file.type)) {
            notifications.snackError({ message: messages.invalidFileType });

            throw new Error('Invalid type');
        }

        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            const manifest = await configurationSettingsConnector.importManifest(file, CONFIGURATION_SCOPE, sub1);
            const confType = manifest.configurations.find((conf) => conf.type === ConfigurationType.UserProfiles);

            const configurationTypeKeys = confType?.configurations.map((conf) => {
                return conf.id;
            });

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            const importResult = await configurationSettingsConnector.importConfigurations(
                file,
                {
                    options: [
                        {
                            type: ConfigurationType.UserProfiles,
                            configurationKeys: configurationTypeKeys?.length ? configurationTypeKeys : [],
                            options: {},
                        },
                    ],
                },
                ImportAction.UpdateExisting,
                CONFIGURATION_SCOPE,
                sub2
            );

            const sub3 = new SubProgressMonitor(progressMonitor, 1);
            const newProperties = await UsersConnector.getInstance().getUserProfileFields(sub3);
            setProperties(newProperties);

            if (isEmpty(importResult) || !importResult.errors) {
                notifications.snackInfo({ message: messages.importSucceed });

                return;
            }
            const nbImportErrors = importResult.errors.length;

            notifications.notifError({
                message: messages.importConfigurationError,
                description: messages.importConfigurationDescriptionError,
                details: <ConfigurationErrorNotificationDescription errors={importResult.errors} />,
            }, undefined, { count: nbImportErrors });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.importPropertiesError }, error as Error);
            throw error;
        }
    }, [notifications, setProperties]);

    const [handleExportProperties] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const propertiesToExport = properties.getData().filter((property) => selectionProvider.list().includes(property.id));

            if (!propertiesToExport) {
                return;
            }

            const importExportOptions: ImportExportOptions = {
                options: [{
                    type: ConfigurationType.UserProfiles,
                    configurationKeys: ['user-profiles-fields-bundle'],
                    options: {},
                }],
            };

            const ret: Blob = await configurationSettingsConnector.exportConfigurations(importExportOptions, CONFIGURATION_SCOPE, progressMonitor);

            downloadBlob(`properties-${new Date().getTime()}.zip`, ret);

            notifications.snackInfo({ message: messages.exportSucceed });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.exportError }, error as Error);
            throw error;
        }
    }, [notifications, properties, selectionProvider]);

    return (
        <>
            <div className={classNames('&-actions')}>
                <div className={classNames('&-actions-left')}>
                    <ArgInputSearch
                        onInputChange={(search) => {
                            setFilter((prevState) => ({
                                ...prevState,
                                search,
                            }));
                        }}
                        placeholder={messages.searchPlaceHolder}
                        className={classNames('&-actions-search')}
                    />
                    {canEditProperties && <PropertyActionsButtonMenu
                        selectedProperties={selectedProperties}
                    />}
                </div>
                <div className={classNames('&-actions-right')}>
                    {canImportExport && (
                        <>
                            <ArgUploaderButton
                                type='ghost'
                                size='medium'
                                icon='icon-download'
                                className={classNames('&-import-button')}
                                label={messages.import}
                                acceptedFiles='.zip'
                                method={(file: Blob) => importProperties(file)}
                            />
                            <ArgButton
                                className={classNames('&-export-button')}
                                icon='icon-upload'
                                size='medium'
                                type='ghost'
                                onClick={handleExportProperties}
                                label={messages.export}
                            />
                        </>
                    )}
                    {canEditProperties && <ArgButton
                        size='medium'
                        type='primary'
                        icon='icon-plus'
                        label={messages.newProperty}
                        onClick={handleAddProperty}
                    />}
                </div>
            </div>

            {properties.getData().length === 0 ? (
                <EmptyPane className={classNames('&-body-content')}>
                    <div className={classNames('&-body-content-empty')}>
                        <div className={classNames('&-body-content-empty-title')}>
                            <FormattedMessage {...messages.noProperties} />
                        </div>
                        <div className={classNames('&-body-content-empty-details')}>
                            <FormattedMessage {...messages.propertiesEmpty} />
                        </div>
                    </div>
                </EmptyPane>
            ) : (
                <ArgTable3<UserProfileField>
                    className={classNames('&', className)}
                    columns={columns}
                    initialItemsCount={properties.rowCount!}
                    headerHeight={45}
                    rowHeight={45}
                    dataProvider={properties}
                    filter={filter}
                    adjustColumns={true}
                    selectionProvider={canEditProperties ? selectionProvider : undefined}
                />
            )}
        </>
    );
}
