import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
    ArgUserId,
    ProgressMonitor,
    SelectionProvider,
    useArgNotifications,
    useClassNames,
    useDataProvider,
    useMemoAsync,
    useSelectionList,
} from 'src/components/basic';
import { UserDataFilter, UsersDataProvider } from '../providers/users-data-provider';
import { TableToolbar } from '../components/table-toolbar/table-toolbar';
import { User } from 'src/model/user';
import { DeleteUsersModal } from '../components/multi-user-delete-modal/multi-user-delete-modal';
import { UsersAndGroupsStateContext } from '../providers/usersState';
import { PageHeadersButtons } from './users-and-groups-panel';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { UsersTable } from '../components/users-table/users-table';
import { UserPanel } from '../components/user-panel/user-panel';
import { LoadingPane } from '../../../components/common/panes/loading-pane';
import { ErrorPane } from '../../../components/common/panes/error-pane';
import { UsersConnector } from '../../../utils/connectors/users-connector';

import './users-view.less';

const FORCE_LOADING = false;
const FORCE_ERROR = false;

const messages = defineMessages({
    noUsers: {
        id: 'settings.users.noUsers',
        defaultMessage: 'No users',
    },
    emptyUsers: {
        id: 'settings.users.emptyUsers',
        defaultMessage: 'Create a new user.',
    },
    loadUserProfilesFieldsError: {
        id: 'settings.users.loadUserProfilesFieldsError',
        defaultMessage: 'An error has occured while loading user profiles fields',
    },
});

const USER_TABLE_ID = 'settings-user-table';

export interface UsersViewProps {
    getUsersMonitor: ProgressMonitor | undefined;
    pageTabs: ReactNode;
    headerButtonSelected: string;
    onUserClick: () => void;
}

export function UsersView(props: UsersViewProps) {
    const {
        getUsersMonitor,
        pageTabs,
        headerButtonSelected,
        onUserClick,
    } = props;

    const classNames = useClassNames('settings-users-view');
    const [filter, setFilter] = useState<UserDataFilter>({});
    const { users, loadingUsers, errorUsers } = useContext(UsersAndGroupsStateContext);
    const notifications = useArgNotifications();

    const usersDataProvider = useMemo(() => new UsersDataProvider(users, false), [users]);
    useDataProvider(usersDataProvider);

    const usersSelectionProvider = useMemo(() => new SelectionProvider<User>(USER_TABLE_ID, (user) => user.id.toString()), []);

    const [selectedUsers] = useSelectionList(usersSelectionProvider);
    const [deleteUsersModalOpen, setDeleteUsersModalOpen] = useState(false);
    const [panelUserSelectedId, setPanelUserSelectedId] = useState<ArgUserId>();

    const [userProfilesFields] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const _userProfilesFields = await UsersConnector.getInstance().getUserProfileFields(progressMonitor);

            return _userProfilesFields;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }

            notifications.snackError({ message: messages.loadUserProfilesFieldsError }, error as Error);

            return;
        }
    }, []);

    const panelUserSelected = useMemo(() => {
        return users.find((user) => user.id === panelUserSelectedId);
    }, [panelUserSelectedId, users]);

    const onUserClicked = useCallback((userId: ArgUserId) => {
        setPanelUserSelectedId(userId);
        onUserClick();
    }, [onUserClick]);

    const selectedUsersList = useMemo(() => {
        return usersSelectionProvider
            .list()
            .map((id) => {
                return users.find((user) => user.id === id);
            })
            .filter((entry): entry is User => !!entry);
    }, [users, usersSelectionProvider]);

    let body: ReactNode = null;
    if (loadingUsers?.isRunning || FORCE_LOADING) {
        body = <div className={classNames('&-body', 'loading')}>
            <LoadingPane />
        </div>;
    } else if (errorUsers || FORCE_ERROR) {
        body = <div className={classNames('&-body', 'error')}>
            <ErrorPane />
        </div>;
    } else if (users.length === 0) {
        body = <EmptyPane className={classNames('&-body-content')}>
            <div className={classNames('&-body-content-empty')}>
                <div className={classNames('&-body-content-empty-title')}>
                    <FormattedMessage {...messages.noUsers} />
                </div>
                <div className={classNames('&-body-content-empty-details')}>
                    <FormattedMessage {...messages.emptyUsers} />
                </div>
            </div>
        </EmptyPane>;
    } else {
        body = <div className={classNames('&-body')}>
            <UsersTable
                filter={filter}
                users={usersDataProvider}
                selectionSource={USER_TABLE_ID}
                selectionProvider={usersSelectionProvider}
                progressMonitor={getUsersMonitor}
                onUserClicked={onUserClicked}
            />
        </div>;
    }


    return (
        <>
            <div className={classNames('&')}>
                {pageTabs}
                <TableToolbar
                    setFilter={setFilter}
                    usersSelectionProvider={usersSelectionProvider}
                    userProfilesFields={userProfilesFields}
                />
                {body}
            </div>

            <DeleteUsersModal
                visible={deleteUsersModalOpen}
                users={selectedUsersList}
                closeModal={() => {
                    setDeleteUsersModalOpen(false);
                }}
            />

            {headerButtonSelected === PageHeadersButtons.INFO && (
                <div className={classNames('&-user-panel')}>
                    <UserPanel user={panelUserSelected} userProfilesFields={userProfilesFields} />
                </div>
            )}
        </>
    );
}
