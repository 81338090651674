import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty, pick } from 'lodash';


import { ArgPlaceholderText, ArgRadio, useClassNames } from '../../../../basic';
import { RuleSet, AdvancedStyleType, DiscreteValue, Interval } from '../graph-style';
import { getConditionAsGradient, getConditionAsInterval, getInvalidFields, getSpecificProps } from './utils';
import { IntervalDateControl } from './interval-date-control';
import { GradientDateControl } from './gradient-date-control';
import { RangeDateControlMessages } from '../advanced-body';
import { useGetPropertyBounds, GetGradientBounds } from '../use-get-property-bounds';
import { dayjs } from '../../../../basic/utils/dayjs';
import { Property } from 'src/components/common/controls/controls-type';

import './range-date-control.less';

export interface Messages {
    startDatePlaceholder: ArgPlaceholderText;
    endDatePlaceholder: ArgPlaceholderText;
    maxPlaceholder: ArgPlaceholderText;
    minPlaceholder: ArgPlaceholderText;
}

interface RangeDateControlProps {
    ruleSets: RuleSet[] |undefined;
    type: AdvancedStyleType;
    property: Property;
    getGradientBounds?: GetGradientBounds,
    onStyleChange?: (label: AdvancedStyleType, style: Record<string, any>, index: number) => Promise<void>;
    onRangeValueChange?: (
        advancedStyleType: AdvancedStyleType,
        side: 'left' | 'right',
        value: dayjs.Dayjs | null,
        index: number
    ) => Promise<void>;

    canBeRemoved?: boolean;
    onRemoveComponent?: (advancedStyleType: AdvancedStyleType, index: number) => Promise<void>;
    toggleGradientInterval: (
        advancedStyleType: AdvancedStyleType,
        gradientOrInterval: 'interval' | 'gradient',
        property: string
    ) => Promise<void>;
    onGradientPropertyRangeValueChange: (
        type: 'property' | 'size',
        side: 'left' | 'right',
        value: number | dayjs.Dayjs | null
    ) => Promise<void>;
    messages: RangeDateControlMessages;
}

export const RangeDateControl = ({
    ruleSets,
    type,
    property,
    getGradientBounds,
    onStyleChange,
    onRangeValueChange,
    onRemoveComponent,
    toggleGradientInterval,
    onGradientPropertyRangeValueChange,
    messages,
}: RangeDateControlProps) => {
    const classNames = useClassNames('range-date-control');
    const [isGradient, setIsGradient] = useState(true);

    const setControlAsGradient = () => {
        !isGradient && toggleGradientInterval('size', 'gradient', property.name);
        setIsGradient(true);
    };

    const setControlAsInterval = () => {
        isGradient && toggleGradientInterval('size', 'interval', property.name);
        setIsGradient(false);
    };

    const { propertyBounds } = useGetPropertyBounds<dayjs.Dayjs>(
        property.name,
        'date',
        getGradientBounds,
        setControlAsInterval
    );

    if (ruleSets === undefined || ruleSets.length === 0) {
        return null;
    }

    let intervalContent: React.ReactNode = null;
    let gradientContent: React.ReactNode = null;
    const conditionGradient = getConditionAsGradient(ruleSets[0])?.gradient;
    const conditionInterval = getConditionAsInterval(ruleSets[0])?.interval;
    const doesContainsUndefinedValueStyle =
        isEmpty(ruleSets?.[ruleSets.length - 1].condition) ||
        (ruleSets?.[ruleSets.length - 1].condition as DiscreteValue)?.value === null;

    if (!!conditionInterval || doesContainsUndefinedValueStyle) {
        isGradient && setIsGradient(false);
        const intervalDateControlMessages = pick(messages, [
            'startDatePlaceholder',
            'endDatePlaceholder',
            'undefinedStyleRulePlaceholder',
        ]);
        const errors = getInvalidFields(ruleSets);

        intervalContent = (
            <>
                {ruleSets?.map((ruleSet, index) => {
                    const invalidFields: Record<number,
                        {
                            invalidLeft: boolean;
                            invalidRight: boolean;
                        }> = ruleSets !== undefined ? errors[index] : {};

                    return (
                        <IntervalDateControl
                            property={property}
                            key={ruleSet.userDefinedContent.id}
                            type={type}
                            index={index}
                            left={
                                (ruleSet.condition as Interval)?.interval?.left
                                    ? dayjs.utc((ruleSet.condition as Interval)?.interval?.left)
                                    : undefined
                            }
                            right={
                                (ruleSet.condition as Interval)?.interval?.right
                                    ? dayjs.utc((ruleSet.condition as Interval)?.interval?.right)
                                    : undefined
                            }
                            onStyleChange={onStyleChange}
                            onRangeValueChange={onRangeValueChange}
                            onRemoveComponent={onRemoveComponent}
                            canBeRemoved={ruleSets.length > 1 && !ruleSet?.isUndefinedRuleSet}
                            {...getSpecificProps(type, ruleSet?.userDefinedContent)}
                            messages={intervalDateControlMessages}
                            isUndefinedStyleRule={ruleSet?.isUndefinedRuleSet}
                            {...invalidFields}
                        />
                    );
                })}
                {!isEmpty(errors)
                    ? <div className={classNames('&', 'field-error')}>
                        <FormattedMessage {...messages.errorOverlap} />
                    </div>
                    : null
                }
            </>
        );
    }

    if (conditionGradient) {
        !isGradient && setIsGradient(true);
        gradientContent = (
            <>
                {ruleSets?.map((ruleSet) => {
                    return (
                        <GradientDateControl
                            key={ruleSet.userDefinedContent.id}
                            sliderLeftBound={propertyBounds.leftBound}
                            sliderRightBound={propertyBounds.rightBound}
                            minPropertyValue={conditionGradient?.left ? dayjs.utc(conditionGradient?.left) : undefined}
                            maxPropertyValue={
                                conditionGradient?.right ? dayjs.utc(conditionGradient?.right) : undefined
                            }
                            minSizeValue={ruleSet?.userDefinedContent?.gradientSize?.[0]}
                            maxSizeValue={ruleSet?.userDefinedContent?.gradientSize?.[1]}
                            onGradientPropertyRangeValueChange={onGradientPropertyRangeValueChange}
                            messages={messages}
                        />
                    );
                })}
            </>
        );
    }

    return (
        <div className={classNames('&')}>
            {type === 'size' && (
                <>
                    <ArgRadio
                        className={classNames('&-gradient-radio')}
                        value={isGradient}
                        label={messages?.gradientLabel ?? 'Gradient'}
                        onChange={setControlAsGradient}
                    />
                    {gradientContent}
                    <ArgRadio
                        className={classNames('&-interval-radio')}
                        value={!isGradient}
                        label={messages?.intervalLabel ?? 'Interval'}
                        onChange={setControlAsInterval}
                    />
                </>
            )}
            {intervalContent}
        </div>
    );
};
