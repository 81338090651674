import React, { CSSProperties, MouseEvent, ReactNode, useCallback } from 'react';
import { Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import Debug from 'debug';
import { isNumber, isString } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMessageValues, ArgRenderedIcon, ArgRenderedText, ArgTooltipPlacement } from '../types';
import { renderIcon } from '../arg-icon/arg-icon';
import { renderText } from '../utils/message-descriptor-formatters';
import { ButtonClickEvent } from '../arg-button/arg-button';
import { ArgTooltip2 } from '../arg-tooltip/arg-tooltip2';
import { isProgressMonitor, ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ThreeDotsLoading } from '../arg-loading/three-dots-loading';

import './arg-menu-item.less';

const debug = Debug('argonode:components:ArgMenuItem');

export interface ArgMenuItemProps {
    className?: ClassValue;

    id?: string;

    disabled?: boolean;

    onClick?: (event: ButtonClickEvent) => (Promise<void> | void);

    onMouseEnter?: (event: MouseEvent) => void;

    children?: ReactNode;

    label?: ArgRenderedText;

    messageValues?: ArgMessageValues;

    icon?: ArgRenderedIcon; // Iconname

    tooltip?: ArgRenderedText;

    tooltipPlacement?: ArgTooltipPlacement;

    paddingLeft?: number | string;

    loading?: boolean | ProgressMonitor;
}

export function ArgMenuItem(props: ArgMenuItemProps) {
    const {
        className,
        label,
        messageValues,
        onClick,
        onMouseEnter,
        icon,
        tooltip,
        children,
        tooltipPlacement,
        paddingLeft,
        loading,
        ...otherProps
    } = props;

    const classNames = useClassNames('arg-menu-item');

    const handleClick = useCallback(async (event: MenuInfo) => {
        debug('handleClick', 'domEvent=', event.domEvent.defaultPrevented);

        if (!onClick) {
            return;
        }

        if (event.domEvent.defaultPrevented) {
            return;
        }

        event.domEvent.preventDefault();

        await onClick(event.domEvent);
    }, [onClick]);

    const handleMouseEnter = useCallback((info: {
        key: string;
        domEvent: MouseEvent;
    }) => {
        onMouseEnter?.(info.domEvent);
    }, [onMouseEnter]);

    const _icon: ReactNode = renderIcon(icon, classNames('&-icon'));

    const isLoading = loading === true || (isProgressMonitor(loading) ? loading.isRunning : false);

    let body: ReactNode = (
        <>
            {label && <span className={classNames('&-label')}>
                {renderText(label, messageValues)}
            </span>}
            {isLoading && <span className={classNames('&-loading')}>
                <ThreeDotsLoading />
            </span>}
            {children}
        </>
    );

    if (tooltip) {
        body = (
            <ArgTooltip2
                placement={tooltipPlacement ? tooltipPlacement : 'bottom'}
                title={tooltip}
                messageValues={messageValues}
            >
                {body}
            </ArgTooltip2>
        );
    }

    const style: CSSProperties = {};

    if (isNumber(paddingLeft)) {
        style.paddingLeft = `${paddingLeft}px`;
    } else if (isString(paddingLeft)) {
        style.paddingLeft = paddingLeft;
    }

    return (
        <Menu.Item
            {...otherProps}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            className={classNames('&', className)}
            icon={_icon}
            style={style}
        >
            {body}
        </Menu.Item>
    );
}
