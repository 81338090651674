import { defineMessages } from 'react-intl';

import { useCallbackAsync, useClassNames, useArgNotifications } from 'src/components/basic';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';
import { FormPolicy } from 'src/settings/models/form-policy';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';

import './form-policies-deletion-modal.less';

const messages = defineMessages({
    deleteTitle: {
        id: 'settings.form-policies.deletion-modal.deleteTitle',
        defaultMessage: 'Delete this form policy',
    },
    deleteMessage: {
        id: 'settings.form-policies.deletion-modal.deleteMessage',
        defaultMessage: 'Are you sure you want to delete the form policy "{name}"?',
    },
    deleteMultipleTitle: {
        id: 'settings.form-policies.deletion-modal.deleteMultipleTitle',
        defaultMessage: 'Delete {count} form policies',
    },
    deleteMultipleMessage: {
        id: 'settings.form-policies.deletion-modal.deleteMultipleMessage',
        defaultMessage: 'Are you sure you want to delete these {count} form policies?',
    },
    deleteError: {
        id: 'settings.form-policies.deletion-modal.deleteError',
        defaultMessage: 'An error occurred while deleting the {count, plural, one {form policy} other {form policies}}',
    },
    delete: {
        id: 'settings.form-policies.deletion-modal.delete',
        defaultMessage: 'Delete',
    },
});

interface FormPoliciesDeletionModalProps {
    formPolicies: FormPolicy[];
    onSuccess?: () => void;
    onClose: () => void;
}

export function FormPoliciesDeletionModal(props: FormPoliciesDeletionModalProps) {
    const { formPolicies, onClose, onSuccess } = props;

    const notifications = useArgNotifications();
    const classNames = useClassNames('settings-form-policies-deletion-modal');

    const [onDelete] = useCallbackAsync(async (progressMonitor) => {
        try {
            if (formPolicies.length === 1) {
                const formPolicyId = formPolicies[0].id;
                await explorationSettingsConnector.deleteFormPolicy(formPolicyId, progressMonitor);
            } else {
                const formPolicyIds = formPolicies.map((formPolicy) => formPolicy.id);
                // TODO: uncomment when the backend is ready
                // await settingsConnector.deleteFormPolicies(formPolicyIds, progressMonitor);
            }
            onSuccess?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.deleteError }, error as Error, { count: formPolicies.length });
            throw error;
        }
    }, [onSuccess, formPolicies]);

    const title = props.formPolicies.length > 1 ? messages.deleteMultipleTitle : messages.deleteTitle;
    const message = props.formPolicies.length > 1 ? messages.deleteMultipleMessage : messages.deleteMessage;

    const messageValues = {
        count: props.formPolicies.length,
        name: props.formPolicies.length === 1 ? props.formPolicies[0].name : undefined,
    };

    return (
        <ConfirmModal
            className={classNames('&')}
            type='delete'
            title={title}
            alertMessage={message}
            messageValues={messageValues}
            onConfirm={onDelete}
            onClose={onClose}
            confirmText={messages.delete}
        />
    );
}
