import React, { ReactNode } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';

import { ArgButton, ClassValue } from '../../basic';

const messages = defineMessages({
    addRange: {
        id: 'common.controls.range-picker.addRange',
        defaultMessage: 'Add range',
    },
});

export interface AddFieldProps {
    handleAddPropertyField?: () => void,
    title?: ReactNode | MessageDescriptor;
    className?: ClassValue;
    disabled?: boolean;
}

export function AddField(props: AddFieldProps) {
    const {
        handleAddPropertyField,
        title,
        className,
        disabled,
    } = props;

    return (
        <ArgButton
            className={className}
            type='link'
            size='medium'
            onClick={handleAddPropertyField}
            label={title || messages.addRange}
            icon='icon-plus'
            data-testid='add-property-field-button'
            disabled={disabled}
        />
    );
}
