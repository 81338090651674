import Debug from 'debug';
import { isEmpty, forEach, kebabCase } from 'lodash';

import { ProgressMonitor, SubProgressMonitor } from '../../components/basic';
import { Tag, TagProperties } from '../../exploration/features/tag/types';
import { VertexId } from '../../exploration/model/vertex';
import { CasePieceType } from '../../model/case-piece-type';
import { FolderId } from '../../model/folder';
import { ResourceId, ResourceCasePiece, ListResourceOperationsResponse, ResourceOperationId, PDF_PREVIEW_METADATA, TagId } from '../../model/resource';
import { DEFAULT_CONTENT_TYPE, JsonChange, createPatchRequest, ConnectorRequestInit } from '../connector';
import { RtApi } from '../rt-states/rt-api';
import { ArgonosPieceConnector } from './argonos-casepiece-connector';
import { BaseConnector } from './base-connector';
import { CasePieceMapper, mapResource, mapTag, registerCasePieceType } from './mappers';


export const debug = Debug('argonode:utils:CaseConnector');

export class ResourceConnector extends BaseConnector {
    #argonosPieceConnector: ArgonosPieceConnector;
    constructor(
        name: string, api: string | undefined,
        argonosPieceConnector: ArgonosPieceConnector,
        customMapper?: CasePieceMapper<ResourceCasePiece>,
        progressMonitorRtApi?: RtApi) {
        super(name, api, progressMonitorRtApi);
        this.#argonosPieceConnector = argonosPieceConnector;

        if (customMapper) {
            const mapper: CasePieceMapper<ResourceCasePiece> = (ret, entityProperties) => {
                defaultResourceMapper(ret, entityProperties);
                customMapper(ret, entityProperties);
            };
            registerCasePieceType('resource', CasePieceType.Resource, mapper);
        }
    }

    computeResourceUrl(resourceId: ResourceId) {
        const ret = `${this.api}/resources/${resourceId}`;

        return ret;
    }

    async uploadCaseResource(
        caseId: FolderId | undefined,
        filename: string,
        description: string | undefined,
        blob: Blob,
        metadatas?: Record<string, string>,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ResourceId> {
        const url = '/resources';

        const headers: Record<string, string> = {
            'Content-Type': blob.type || DEFAULT_CONTENT_TYPE,
            'Content-Length': String(blob.size),
        };

        if (!isEmpty(metadatas)) {
            forEach(metadatas, (value, key) => {
                headers[`Chapsvision-Meta-${kebabCase(key)}`] = encodeURIComponent(value);
            });
        }

        if (filename) {
            headers['X-Filename'] = encodeURIComponent(filename);
        }
        if (description) {
            headers['X-Description'] = encodeURIComponent(description);
        }

        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        const response = await this.request(
            url,
            {
                body: blob,
                method: 'POST',
                headers,
                loadLocationContentOn201: false,
                verifyJSONResponse: true,
            },
            sub1
        );

        const resourceId: ResourceId = response.id;


        if (caseId) {
            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            await this.#argonosPieceConnector.addPiece(caseId, resourceId, CasePieceType.Resource, sub2);
        }

        return resourceId;
    }

    async geResourceCasePieces(caseId: FolderId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<ResourceCasePiece[]> {
        const ret = await this.#argonosPieceConnector.getPieces(
            caseId,
            [CasePieceType.Resource],
            true,
            true,
            progressMonitor
        );

        return ret as ResourceCasePiece[];
    }

    async getResourceCasePiece(caseId: FolderId, resourceId: ResourceId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<ResourceCasePiece> {
        const ret = await this.#argonosPieceConnector.getPiece(caseId, resourceId, true, true, progressMonitor);

        if (ret.type !== CasePieceType.Resource) {
            throw new Error('Invalid resource piece');
        }

        return ret as ResourceCasePiece;
    }

    async getResource(resourceId: ResourceId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<ResourceCasePiece> {
        const url = `/resources/${encodeURIComponent(resourceId)}/metadata`;

        const response = await this.request(url, {
            method: 'GET',
            verifyJSONResponse: true,
        }, progressMonitor);

        const ret = mapResource(response, resourceId);

        return ret;
    }

    async renameResource(
        resourceId: ResourceId,
        newName: string | undefined,
        newDescription: string | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const changes: JsonChange[] = [];
        if (newName) {
            changes.push({ path: 'name', value: newName });
        }
        if (newDescription) {
            changes.push({ path: 'description', value: newDescription });
        }

        if (!changes.length) {
            return;
        }

        const url = `/resources/${encodeURIComponent(resourceId)}/metadata`;

        const options = createPatchRequest('Change name and/or description', ...changes);

        await this.request(url, options, progressMonitor);
    }

    async deleteResource(
        resourceId: ResourceId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/resources/${encodeURIComponent(resourceId)}`;

        await this.request(url, {
            method: 'DELETE',
        }, progressMonitor);
    }

    async deleteResources(
        caseResourceIds: ResourceId[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        progressMonitor.beginTask('delete resources', caseResourceIds.length);

        const ps: Promise<void>[] = caseResourceIds.map((caseResourceId) => {
            const sub = new SubProgressMonitor(progressMonitor, 1);
            const p = this.deleteResource(caseResourceId, sub);

            return p;
        });

        await Promise.all(ps);

        debug('deleteResources', 'Resources deleted:', caseResourceIds);
    }

    async visitCaseResource(
        caseId: FolderId,
        resourceId: ResourceId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        await this.#argonosPieceConnector.visitPiece(caseId, resourceId, progressMonitor);
    }

    async getResourceOperations(
        resourceId: ResourceId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ListResourceOperationsResponse> {
        const url = `/resources/${encodeURIComponent(resourceId)}/operations`;

        const response = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        return response;
    }

    async startResourceOperations(
        resourceId: ResourceId,
        operationId: ResourceOperationId,
        configuration: any,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/resources/${encodeURIComponent(resourceId)}/operations/${encodeURIComponent(operationId)}`;

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: configuration,
        };

        await this.request(url, options, progressMonitor);
    }

    async associateTagToResource(
        resourceId: ResourceId,
        vertexId: VertexId,
        tag: TagProperties,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/resources/${encodeURIComponent(resourceId)}/tag`;

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                objectId: vertexId,
                json: tag,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async updateAssociation(
        resourceId: ResourceId,
        vertexId: VertexId,
        tagId: TagId,
        tag: TagProperties,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/resources/${encodeURIComponent(resourceId)}/tag/${encodeURIComponent(tagId)}`;

        const options: ConnectorRequestInit = {
            method: 'PUT',
            json: {
                objectId: vertexId,
                json: tag,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async getAssociations(
        resourceId: ResourceId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Tag> {
        const url = `/resources/${encodeURIComponent(resourceId)}/tags`;

        const options: ConnectorRequestInit = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const response = await this.request(url, options, progressMonitor);

        const ret = mapTag(response);

        return ret;
    }

    async deleteAssociation(
        resourceId: ResourceId,
        tagId: TagId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/resources/${encodeURIComponent(resourceId)}/tag/${encodeURIComponent(tagId)}`;

        const options: ConnectorRequestInit = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }
}

const defaultResourceMapper: CasePieceMapper<ResourceCasePiece> = (ret: ResourceCasePiece, entityProperties: Record<string, any> | undefined) => {
    if (entityProperties?.blobMetadata) {
        ret.metadata = entityProperties.blobMetadata;
    }

    const pdfPreview = ret.metadata?.[PDF_PREVIEW_METADATA];
    if (pdfPreview) {
        ret.previews = {
            ...ret.previews,
            pdf: pdfPreview,
        };
    }
};

registerCasePieceType('resource', CasePieceType.Resource, defaultResourceMapper);

