import { maxBy } from 'lodash';

import { ClassValue, useClassNames } from '../../../basic';
import { ValueSpreadEntry } from '../controls-type';

import './range-picker-control-histogram.less';

interface RangePickerControlHistogramProps {
    valueSpread: ValueSpreadEntry<unknown>[];
    className?: ClassValue;
}

export function RangePickerControlHistogram(props: RangePickerControlHistogramProps) {
    const { valueSpread, className } = props;
    const maxValue = maxBy(valueSpread, (entry) => entry.count)?.count;

    const classNames = useClassNames('range-picker-control-histogram');

    return (
        <div
            className={classNames('&', className)}
            data-testid='range-picker-control-histogram-test'
        >
            {valueSpread.map((value, index) => {
                const elementHeight = maxValue ? 50 * value.count / maxValue : maxValue;

                return (
                    <div
                        key={index}
                        className={classNames('&-histogram-bar')}
                        style={{ height: elementHeight }}
                        data-testid={`histogram-bar-${value.count}-test`}
                    />
                );
            })}
        </div>
    );
}
