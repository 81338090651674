import React, { useMemo } from 'react';
import { Hue } from 'react-color/lib/components/common';
import { HueProps } from 'react-color/lib/components/common/Hue';
import { ColorFormats } from 'tinycolor2';

import { useClassNames } from '../../../../basic';
import { CustomHueSlider } from './custom-hue-slider';

import './color-palette-picker-hue.less';

interface ColorPalettePickerHueProps extends Omit<HueProps, 'color'> {
    hsl?: ColorFormats.HSLA;
}

export const ColorPalettePickerHue = (props: ColorPalettePickerHueProps) => {
    const classNames = useClassNames('color-palette-picker-hue');
    const cleanProps = { ...props, className: undefined };
    // The type transformation is due to React Color types issue. See (https://github.com/casesandberg/react-color/blob/master/examples/custom-pointer/src/MyPicker.js)
    const customPointer = useMemo<React.ReactNode>(() => (cleanProps?.pointer ?? CustomHueSlider as unknown as React.ReactNode), [cleanProps?.pointer]);

    return (
        <div className={classNames('&', props?.className)}>
            <Hue {...cleanProps} pointer={customPointer} />
        </div>
    );
};
