import { Operation } from 'fast-json-patch';

import { CaseId } from 'src/model/folder-case-piece';
import { VertexId } from './vertex';
import { Filter } from './filter';
import { UniverseId } from './universe';

export interface VisualizeVertexQueryOptions {
    listAttachments?: boolean;
    listRelatedVertices?: boolean;
}

export interface SearchVertexFilter {
    vertexFilter: {
        labelConstraint: {
            labels: string[],
        },
    };

}

export interface ExplorationObjectTypesTypeItem {
    label: string;
    verticesCount: number;
}

export interface CreateExplorationFromSearchQueryBodyType {
    explorationName: string;
    description?: string;
    origin: string;
    initialQuery: {
        searchTerm: string;
        caseId: CaseId;
    };
    universeId: UniverseId;
    universeFilter: Filter
}

export interface CreateBriefRequestBody {
    brief: {
        name: string,
        editor: {
            name: EditorName,
        },
    },
    universe: {
        id: UniverseId,
    },
}

export interface UpdateBriefRequestBody {
    brief: {
        name: string,
    },
}

export interface JsonPatchOperationRequest {
    comment?: string;
    changes?: Operation[];
}

export enum EditorName {
    CKEditor = 'CKEditor',
}

export interface ExplorationSearchResponse {
    count: number;
    vertices?: SearchedVertex[];
}

export interface SearchedVertex {
    id: VertexId;
    rank: number;
}

export interface ExplorationSearchResults<T> {
    vertices: T[];
    count: number;
}

export enum UpdateExplorationType {
    Add,
    Delete,
    DeleteOthers,
}
