import { useCallback } from 'react';

import {
    ArgTable3,
    ArgTableColumn3,
    ArgUserId,
    ClassValue,
    ProgressMonitor,
    SelectionProvider,
    ToolContext,
    useClassNames,
} from 'src/components/basic';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { UserDataFilter, UsersDataProvider } from '../../providers/users-data-provider';
import { User } from 'src/model/user';
import { useTableColumns } from './columns';
import { useHasAnyPermissions } from '../../../../contexts/user-permission';
import { SettingsPermissions } from '../../../permissions/permissions';

import './users-table.less';

const FORCE_LOADING = false;

export const SETTINGS_TABLE_SELECTION_SOURCE = 'settings-table';

export interface UserTableProps {
    users: UsersDataProvider;
    selectionSource: string;
    selectionProvider: SelectionProvider<User>;
    toolbarContext?: ToolContext;
    className?: ClassValue;
    progressMonitor?: ProgressMonitor;
    filter: UserDataFilter;
    onUserClicked: (userId: ArgUserId) => void;
}

export function UsersTable(props: UserTableProps) {
    const {
        className,
        users,
        selectionSource,
        selectionProvider,
        progressMonitor,
        filter,
        onUserClicked,
    } = props;

    const classNames = useClassNames('users-table');

    const canEditUsers = useHasAnyPermissions<SettingsPermissions>('admin.user.edition', 'admin.user.management');

    const columns: ArgTableColumn3<User>[] = useTableColumns();

    const handleRowClick = useCallback((event: React.MouseEvent<Element, MouseEvent>, row: User, rowIndex: number, dataColumn?: string | undefined) => {
        selectionProvider.set(row, selectionSource);

        if (columns.find((column) => column.key === dataColumn)) {
            onUserClicked(row.id);
        }
    }, [columns, onUserClicked, selectionProvider, selectionSource]);

    if (progressMonitor?.isRunning || FORCE_LOADING) {
        return (
            <div className={classNames('&', className, 'loading')}>
                <LoadingPane progressMonitor={progressMonitor} />
            </div>
        );
    }

    return (
        <ArgTable3<User>
            className={classNames('&', className)}
            columns={columns}
            initialItemsCount={users.rowCount}
            headerHeight={45}
            rowHeight={45}
            dataProvider={users}
            filter={filter}
            selectionProvider={canEditUsers ? selectionProvider : undefined}
            adjustColumns={true}
            onRowClick={handleRowClick}
        />
    );
}
