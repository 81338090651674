import { createContext, useContext } from 'react';
import { Outlet } from 'react-router';

import { ArgonosModule } from './modules';

export const CurrentArgonosModuleContext = createContext<ArgonosModule | undefined>(undefined);

export function useCurrentArgonosModule() {
    const context = useContext(CurrentArgonosModuleContext);

    if (!context) {
        throw new Error('Using CurrentModule context outside its provider');
    }

    return context;
}

export function CurrentArgonosModuleLayout({ module }: { module: ArgonosModule }) {
    return (
        <CurrentArgonosModuleContext.Provider value={module}>
            <Outlet />
        </CurrentArgonosModuleContext.Provider>
    );
}
