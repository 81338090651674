import React, { Dispatch, SetStateAction } from 'react';
import { defineMessages } from 'react-intl';
import * as uuid from 'uuid';

import {
    ArgButton,
    ArgInputSearch,
    ProgressMonitor,
    useCallbackAsync,
    useClassNames,
    useArgNotifications,
} from 'src/components/basic';
import { RuleProcessed } from '../../../../../models/policy';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';
import { ValuationPolicyProcessed } from 'src/settings/models/valuation-policy';

import './valuation-rules-page-toolbar.less';

const messages = defineMessages({
    newRule: {
        id: 'settings.valuation-policy-rules-page.toolbar.new',
        defaultMessage: 'New',
    },
    import: {
        id: 'settings.valuation-policy-rules-page.toolbar.import',
        defaultMessage: 'Import',
    },
    searchPlaceholder: {
        id: 'settings.valuation-policy-rules-page.toolbar.search-bar.placeholder',
        defaultMessage: 'Search...',
    },
    savingPolicyError: {
        id: 'settings.valuation-policy-rules-page.toolbar.savingPolicyError',
        defaultMessage: 'An error occurred while saving the policy',
    },
});

interface RulesPageToolbarProps {
    search: string | null;
    policy: ValuationPolicyProcessed;
    setSearch: Dispatch<SetStateAction<string | null>>;
    setPolicy: Dispatch<SetStateAction<ValuationPolicyProcessed | undefined>>;
    editableRuleIds: Set<string>;
}

export const ValuationRulesPageToolbar = ({
    search,
    setSearch,
    setPolicy,
    policy,
    editableRuleIds,
}: RulesPageToolbarProps) => {
    const notifications = useArgNotifications();

    const classNames = useClassNames('settings-valuation-rules-page-toolbar');

    const [handleAddRule, addRuleProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const newRule: RuleProcessed = {
                InferenceDetection: false,
                Targets: [{ object: {} }],
                Effects: [],
                id: uuid.v4(),
            };
            const newPolicy = {
                ...policy,
                statement: { ...policy.statement, Actions: [...policy.statement.Actions, newRule] },
            };

            await explorationSettingsConnector.editValuationPolicy(policy.id, newPolicy, progressMonitor);

            setPolicy(newPolicy);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.savingPolicyError }, error as Error);
            throw error;
        }
    }, [policy, setPolicy]);

    return (
        <div className={classNames('&')}>
            <div className={classNames('&-buttons-group')}>
                <div className={classNames('&-search-bar-container')}>
                    <ArgInputSearch
                        className={classNames('&-search-bar')}
                        placeholder={messages.searchPlaceholder}
                        value={search || undefined}
                        onChange={(value) => setSearch(value)}
                    />
                </div>
            </div>
            <div className={classNames('&-buttons-group')}>
                <ArgButton
                    size='medium'
                    type='primary'
                    icon='icon-plus'
                    label={messages.newRule}
                    onClick={handleAddRule}
                    disabled={addRuleProgressMonitor?.isRunning || editableRuleIds.size > 0}
                    loading={addRuleProgressMonitor?.isRunning}
                />
            </div>
        </div>
    );
};
