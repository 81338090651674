import { isUndefined } from 'lodash';

import { Scope, ScopeFilter, ScopeProcessed } from '../../../../models/policy';
import { getScopeValues } from '../application-condition-expression-of-logic/utils';

export const isValidScope = (scope: Scope | ScopeProcessed): boolean => {
    if ('and' in scope) {
        return scope.and.every((expression) => isValidScope(expression));
    }
    if ('or' in scope) {
        return scope.or.every((expression) => isValidScope(expression));
    }

    if (isValidScopeFilter(scope)) {
        return true;
    }

    return false;
};

export const isValidScopeFilter = (scope: ScopeFilter) => {
    const type = scope.Type;

    if (type === 'always') {
        return true;
    }

    const { dataKey, dataOperator, dataValue } = getScopeValues(scope.Data);

    return !!type && !!dataKey && !!dataOperator && !isUndefined(dataValue);
};
