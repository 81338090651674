import { merge } from 'lodash';
import * as UUID from 'uuid';

import {
    BaseFormElement,
    FormContainer,
    FormDocument,
    FormElement,
    FormElementId,
    FormProperty,
} from '../../../components/common/forms/model';
import { immutableSet, immutableUpdate } from '../../../components/basic';
import { OntologyObjectType } from 'src/settings/universes/ontology/types';
import { BasicTag } from 'src/components/basic';
import { OntologyTypeKind } from './types';
import { DEFAULT_VERTEX_STYLE } from 'src/exploration/constants/default-vertex-style';
import { DEFAULT_EDGE_STYLE } from 'src/exploration/constants/default-edge-style';
import { FormDisplayTemplate } from 'src/exploration/model/form-display-template';
import { VertexStyle } from 'src/exploration/model/vertex';
import { EdgeStyle } from 'src/exploration/model/edge';
import { FullOntologyObjectType } from '../ontology/types';
import { isFormContainer, walk } from 'src/components/common/forms/utils';

export type FormParentMap = Map<FormElement, FormContainer>;

export function canDropChildrenInside(element: BaseFormElement): element is FormContainer {
    return isFormContainer(element) && element.type !== 'composite';
}

export function isFormProperty(element: BaseFormElement): element is FormProperty {
    return element.type === 'property';
}

export function isFormDocument(element: BaseFormElement): element is FormDocument {
    return element.type === 'document';
}

export function computeElementPath(element: FormElement, parentMap: Map<FormElement, FormContainer>, propertyName?: string) {
    const path: (string | number)[] = [];

    for (let node: FormElement | undefined = element; node;) {
        const parent = parentMap.get(node);
        if (!parent) {
            break;
        }

        const index = parent.children.indexOf(node);
        if (index < 0) {
            console.error('*** panic', element, parentMap);
            break;
        }

        path.unshift(index);
        path.unshift('children');

        node = parent as FormElement;
    }

    if (propertyName !== undefined) {
        path.push(propertyName);
    }

    return path;
}

export function changeFormElement(formDocument: FormDocument, formElement: FormElement, propertyName: string, propertyValue: any): FormDocument {
    const parentMap = computeParentMap(formDocument);

    const path = computeElementPath(formElement, parentMap, propertyName);

    const newDocument = immutableSet(formDocument, path, propertyValue);

    return newDocument;
}

export function removeFormElement(formDocument: FormDocument, formElementId: FormElementId): FormDocument {
    let found = false;
    let newDocument: FormDocument = formDocument;

    function walk(node: FormContainer, path: (string | number)[]) {
        if (found) {
            return;
        }

        const index = node.children.findIndex((c) => {
            if (c.id === formElementId) {
                return true;
            }

            return false;
        });

        if (index < 0) {
            node.children.forEach((child, index) => {
                if (found) {
                    return;
                }

                if (!isFormContainer(child)) {
                    return;
                }

                walk(child, [...path, index, 'children']);
            });

            return;
        }
        found = true;

        newDocument = immutableUpdate(newDocument, path, (prev) => {
            const newList = [...prev];
            newList.splice(index, 1);

            return newList;
        });
    }

    walk(newDocument, ['children']);

    if (!found) {
        return formDocument;
    }

    return newDocument;
}

export function computeParentMap(formDocument: FormDocument): FormParentMap {
    const parentMap = new Map();

    function walk(parent: FormContainer) {
        parent.children.forEach((child) => {
            parentMap.set(child, parent);

            if (isFormContainer(child)) {
                walk(child);
            }
        });
    }

    walk(formDocument);

    return parentMap;
}

export function computeInitialDocument(ontologyVertexType: OntologyObjectType) {
    const formProperties = ontologyVertexType.properties.map<FormElement>((property) => ({
        type: 'property',
        propertyName: property.name,
        id: property.name,
        name: property.displayName,
    }));

    formProperties.push({
        type: 'uuid',
        id: UUID.v4(),
    });

    const formDocument: FormDocument = {
        id: 'root',
        type: 'document',
        children: formProperties,
    };

    return formDocument;
}

export function findFormElement(formDocument: FormDocument, formElementId: FormElementId): FormElement | undefined {
    const ret = walk(formDocument, (formElement: FormElement) => {
        if (formElement.id === formElementId) {
            return formElement;
        }

        return undefined;
    });

    return ret;
}

export const renderItemTag = (itemType: FullOntologyObjectType | undefined, kind: OntologyTypeKind) => {
    const defaultItemTypeStyle = kind === 'Vertex' ? DEFAULT_VERTEX_STYLE : DEFAULT_EDGE_STYLE;
    const styles = itemType?.style ? merge({}, defaultItemTypeStyle, itemType.style) : defaultItemTypeStyle;

    return (
        <BasicTag
            label={itemType?.displayName}
            icon={kind === 'Vertex' ? (styles as VertexStyle).iconName : 'icon-dot-arrow-right'}
            iconColor='#FFFFFF'
            backgroundColor={kind === 'Vertex' ? (styles as VertexStyle).iconColor : (styles as Required<EdgeStyle>).color}
            tooltip={itemType?.displayName}
        />
    );
};

export const isFormDisplayTemplate = (displayTemplate: any): displayTemplate is FormDisplayTemplate => {
    return displayTemplate?.key !== undefined && displayTemplate?.displayName !== undefined && isFormDocument(displayTemplate?.formDocument);
};

export const DraggabeFormElements = ['property', 'composite', 'section', 'button', 'uuid', 'label', 'image', 'iframe'];

export const FAKE_TAB_ID = 'fake-tab-id';
