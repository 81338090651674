import { LatLngExpression } from 'leaflet';
import { isEmpty, isNil } from 'lodash';

// Components and hooks
import { LatitudeAndLongitude } from '../model/geolocation-json';
import { latLngExpressionToLatLng } from './latLngExpressionToLatLng';

export const latLngToLatitudeAndLongitude = (value?: LatLngExpression): LatitudeAndLongitude | undefined => {
    if (isNil(value) || isEmpty(value)) {
        return;
    }

    const latLng = latLngExpressionToLatLng(value);

    if (isNil(latLng)) {
        return;
    }

    return {
        latitude: latLng.lat,
        longitude: latLng.lng,
    };
};
