import { ReactNode } from 'react';

import { ClassValue, useClassNames } from '../basic';

import './build-version-wrapper.less';

interface BuildVersionWrapperProps {
    className?: ClassValue;
    children: ReactNode;
}

export function BuildVersionWrapper(props: BuildVersionWrapperProps) {
    const {
        children,
        className,
    } = props;

    const classNames = useClassNames('common-build-version');

    return (
        <>
            {children}
            {localStorage?.ARG_SHOW_VERSION && window.argonodeConfig?.version?.build &&
                <div className={classNames('&', className)}>
                    {window.argonodeConfig.version.build}
                </div>
            }
        </>
    );
}
