import { defineMessages, useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';

import { ArgCombo, useClassNames } from 'src/components/basic';
import { FormRuleEffect, FormRuleProcessed } from '../../../../../models/form-policy';
import { ROW_HEIGHT } from 'src/settings/universes/common/policy-utils';
import { DeleteFilterButton } from 'src/settings/universes/common/effects/delete-filter-button';
import { DropdownTooltipField } from 'src/settings/universes/common/dropdown-tooltip-field/dropdown-tooltip-field';
import { FormDisplayTemplate, FormDisplayTemplates } from 'src/exploration/model/form-display-template';
import { DEFAULT_FORM_TEMPLATE_KEY } from 'src/settings/universes/form/views/form-customisation';
import { ReadOnlyFilterInput } from 'src/settings/universes/common/rules/read-only-filter-input/read-only-filter-input';

import './form-rule-effect-block.less';

export const messages = defineMessages({
    display: {
        id: 'settings.form-rule-effect.display',
        defaultMessage: 'Display',
    },
    select: {
        id: 'settings.form-rule-effect.select',
        defaultMessage: 'Select a form',
    },
    defaultForm: {
        id: 'settings.form-rule-effect.default-form',
        defaultMessage: 'Default form',
    },
});

interface FormRuleEffectBlockProps {
    effect: FormRuleEffect;
    index: number;
    onRuleChange: React.Dispatch<React.SetStateAction<FormRuleProcessed>>;
    editable: boolean;
    forms?: FormDisplayTemplates;
}

export const FormRuleEffectBlock = ({ effect, index, onRuleChange, editable, forms }: FormRuleEffectBlockProps) => {
    const intl = useIntl();
    const classNames = useClassNames('settings-form-rule-effect-block');

    const deleteEffectFilter = useCallback(() => {
        onRuleChange((currentRule: FormRuleProcessed) => {
            return {
                ...currentRule,
                Effects: currentRule.Effects.filter((effect, idx) => idx !== index),
            };
        });
    }, [index, onRuleChange]);

    const items = useMemo(() => {
        if (!forms) {
            return [];
        }

        return Object.values(forms.displayTemplates);
    }, [forms]);

    const value = useMemo(() => {
        return items?.find((form) => form.key === effect.template?.key);
    }, [items, effect.template?.key]);

    const handleFormChange = useCallback((newForm: FormDisplayTemplate) => {
        onRuleChange((currentRule) => {
            if (newForm.key === value?.key) {
                return currentRule;
            }

            return {
                ...currentRule,
                Effects: currentRule.Effects.map((effect, idx) => {
                    if (idx === index) {
                        return {
                            ...effect,
                            template: {
                                key: newForm.key,
                            },
                        };
                    }

                    return effect;
                }),
            };
        });
    }, [index, onRuleChange, value]);

    const handleGetItemLabel = useCallback((form: FormDisplayTemplate): string => {
        if (form.key === DEFAULT_FORM_TEMPLATE_KEY) {
            return intl.formatMessage(messages.defaultForm);
        }

        return form.displayName;
    }, [intl]);

    const displayedValue = useMemo(() => {
        if (value?.key === DEFAULT_FORM_TEMPLATE_KEY) {
            return intl.formatMessage(messages.defaultForm);
        }

        return value?.displayName || intl.formatMessage(messages.select);
    }, [intl, value]);

    return (
        <div className={classNames('&-row-container')} style={{ height: ROW_HEIGHT }}>
            <div className={classNames('&-display-text')}>{intl.formatMessage(messages.display)}</div>
            {editable && <ArgCombo<FormDisplayTemplate>
                    className={classNames('&-form-field')}
                    placeholder={intl.formatMessage(messages.select)}
                    items={items}
                    getItemKey={(form) => form.key}
                    getItemLabel={handleGetItemLabel}
                    value={value}
                    popoverClassName='arg-input-popover-no-max-width'
                    cardinality='one'
                    size='small'
                    onChange={handleFormChange}
                    renderInput={() => (
                        <DropdownTooltipField
                            className={classNames('&-form-field')}
                            value={displayedValue}
                        />
                    )}
                    enableFilter={true}
            />}
            {!editable && <ReadOnlyFilterInput className={classNames('&-form-field')} value={displayedValue} />}
            {editable && <DeleteFilterButton onDeleteRule={deleteEffectFilter} />}
        </div>
    );
};
