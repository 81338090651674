
export interface KnowledgeBasePermissions {
    'kb.entity.read': true,
    'kb.entity.details.read': true,
    'kb.statistics.read': true,
    'kb.entity.write': true,
    'kb.entity.physical-delete': true,
}

export const KB_PERMISSIONS_KEYS: (keyof KnowledgeBasePermissions)[] = [
    'kb.entity.read',
];

export const KB_PERMISSIONS_REQUIRED = KB_PERMISSIONS_KEYS;
