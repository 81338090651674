import React, { CSSProperties } from 'react';

import { ClassValue, useClassNames } from 'src/components/basic';
import { FormElement, HorizontalAlignmentElement, SizeElement } from '../model';

import './form-floating-component.less';

interface FormFloatingComponentProps {
    element: FormElement;
    children: React.ReactNode;
}

export function FormFloatingComponent(props: FormFloatingComponentProps) {
    const { element, children } = props;

    const classNames = useClassNames('form-floating-component');

    const cls = (element as HorizontalAlignmentElement).horizontalAlignment
        ? horizontalAlignment(element as HorizontalAlignmentElement, classNames('&-align'))
        : {};

    const style = ((element as SizeElement).width || (element as SizeElement).height)
        ? styleFromSizeElement(element as SizeElement)
        : {};

    return (
        <div key={element.id} className={classNames('&', cls)}>
            <div className={classNames('&-size')} style={style}>
                {children}
            </div>
        </div>
    );
}

function styleFromSizeElement(size: SizeElement): CSSProperties | undefined {
    const isNumberRegex = /^\d+$/;
    const width = size.width?.match(isNumberRegex) ? `${size.width}px` : size.width;
    const height = size.height?.match(isNumberRegex) ? `${size.height}px` : size.height;

    const ret: CSSProperties = { width, height };

    return ret;
}

function horizontalAlignment(element: HorizontalAlignmentElement, className: ClassValue) {
    switch (element.horizontalAlignment) {
        case 'left':
            return { [`${className}-left`]: true };
        case 'center':
            return { [`${className}-center`]: true };
        case 'right':
            return { [`${className}-right`]: true };
        default:
            throw new Error(`Unexpected horizontalAlignment: ${element.horizontalAlignment}`);
    }
}
