import { isEmpty, isString } from 'lodash';
import { defineMessages } from 'react-intl';
import React, { ReactNode, useCallback, useMemo } from 'react';

import {
    ArgButton,
    ArgIcon,
    ArgMessageValues,
    ArgModal,
    ArgModalSize,
    ArgRenderedText,
    ClassValue,
    ProgressMonitor,
    ProgressMonitorName,
    ProgressMonitorOptions,
    renderText,
    useCallbackAsync,
    useClassNames,
    ArgAlertCard, ArgButtonProps,
} from '../../../basic';

import './confirm-modal.less';

export const messages = defineMessages({
    cancel: {
        id: 'common.confirm-modal.CancelButton',
        defaultMessage: 'Cancel',
    },
    confirm: {
        id: 'common.confirm-modal.ConfirmButton',
        defaultMessage: 'Confirm',
    },
    create: {
        id: 'common.confirm-modal.CreateButton',
        defaultMessage: 'Create',
    },
    save: {
        id: 'common.confirm-modal.SaveButton',
        defaultMessage: 'Save',
    },
    delete: {
        id: 'common.confirm-modal.DeleteButton',
        defaultMessage: 'Delete',
    },
    validate: {
        id: 'common.confirm-modal.ValidateButton',
        defaultMessage: 'Validate',
    },
    reset: {
        id: 'common.confirm-modal.ResetButton',
        defaultMessage: 'Reset',
    },
    edit: {
        id: 'common.confirm-modal.EditButton',
        defaultMessage: 'Edit',
    },
});

const MODAL_FORM_ID = 'MODAL_FORM';

export type ConfirmModalType = 'confirm' | 'create' | 'save' | 'delete' | 'validate' | 'reset' | 'edit';

export interface ConfirmModalProps {
    className?: ClassValue;
    bodyClassName?: ClassValue;
    onClose: (confirmed: boolean) => void;
    onConfirm: (progressMonitor: ProgressMonitor, e?: React.MouseEvent | React.FormEvent) => Promise<boolean | undefined | void>;
    confirmButtonTooltip?: ArgRenderedText;
    alertMessage?: ArgRenderedText;
    alertDescription?: ArgRenderedText;
    messageValues?: ArgMessageValues;
    icon?: ReactNode | string | false;
    title?: ArgRenderedText;
    confirmDisabled?: boolean;
    cancelText?: ArgRenderedText;
    wrapChildrenInAForm?: boolean;
    isConfirmButtonVisible?: boolean;
    size?: ArgModalSize;
    centered?: boolean;
    children?: ReactNode;
    type?: ConfirmModalType;
    confirmText?: ArgRenderedText;
    confirmLoading?: boolean;
    footerButtons?: ArgButtonProps[];

    progressMonitorName?: ProgressMonitorName;
    progressMonitorOptions?: ProgressMonitorOptions;
}

export function ConfirmModal(props: ConfirmModalProps) {
    const {
        className,
        bodyClassName,
        alertMessage,
        messageValues,
        title,
        alertDescription,
        icon,
        onClose,
        onConfirm,
        footerButtons,
        cancelText,
        children,
        wrapChildrenInAForm,
        isConfirmButtonVisible = true,
        confirmDisabled,
        size = 'medium',
        centered,
        type,
        confirmButtonTooltip,
        confirmText: externalConfirmText,
        progressMonitorName,
        progressMonitorOptions,
        confirmLoading,
    } = props;

    const classNames = useClassNames('arg-confirm-modal');

    // Title
    const _title = (
        <span className={classNames('&-title-container')}>
            {isString(icon)
                ? <ArgIcon className={classNames('&-title-icon')} name={icon} />
                : icon
            }

            <span className={classNames('&-title')}>
                {renderText(title, messageValues)}
            </span>
        </span>
    );

    const [callConfirm, progressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor, e?: React.MouseEvent | React.FormEvent) => {
        const ret = await onConfirm(progressMonitor, e);
        if (ret === false) {
            return;
        }

        onClose(true);
    }, [onClose, onConfirm], progressMonitorName, 1, progressMonitorOptions);

    const handleSubmit = useCallback((event: React.MouseEvent | React.FormEvent | undefined) => {
        event?.preventDefault();
        event?.stopPropagation(); // Sûr ????

        callConfirm(event).catch((error) => {
            console.error(error);
        });
    }, [callConfirm]);

    const handleCancel = useCallback(() => {
        onClose(false);
    }, [onClose]);

    let _children: ReactNode | undefined = children;
    if (_children && wrapChildrenInAForm) {
        _children = <form id={MODAL_FORM_ID} onSubmit={handleSubmit}>
            {_children}
        </form>;
    }
    const confirmText = useMemo(() => {
        if (externalConfirmText) {
            return externalConfirmText;
        }

        switch (type) {
            case 'create':
                return messages.create;
            case 'delete':
                return messages.delete;
            case 'save':
                return messages.save;
            case 'validate':
                return messages.validate;
            case 'reset':
                return messages.reset;
            case 'edit':
                return messages.edit;
            default:
                return messages.confirm;
        }
    }, [type, externalConfirmText]);

    return (
        <ArgModal
            size={size}
            title={_title}
            onOk={handleSubmit}
            onCancel={handleCancel}
            onClose={handleCancel}
            className={classNames('&', className)}
            centered={centered}
            footer={
                <div className={classNames('&-footer')} data-testid='confirm-modal-test'>
                    <ArgButton
                        className={classNames('&-footer-btn')}
                        type='secondary'
                        onClick={handleCancel}
                        label={cancelText || messages.cancel}
                        messageValues={messageValues}
                    />

                    {footerButtons && footerButtons.length > 0 && (
                        footerButtons.map((button) => {
                            return (
                                <ArgButton {...button} key={button.id} />
                            );
                        })
                    )}

                    {isConfirmButtonVisible &&
                        <ArgButton
                            className={classNames('&-footer-btn')}
                            type='primary'
                            formId={wrapChildrenInAForm ? MODAL_FORM_ID : undefined}
                            htmlType='submit'
                            onClick={handleSubmit}
                            loading={progressMonitor?.isRunning || confirmLoading}
                            disabled={progressMonitor?.isRunning || confirmDisabled}
                            label={confirmText}
                            messageValues={messageValues}
                            tooltip={confirmButtonTooltip}
                            autoFocus={isEmpty(_children)}
                            data-testid='ok-btn'
                        />
                    }
                </div>
            }
        >
            {alertMessage && (
                <ArgAlertCard
                    type='warning'
                    className={classNames('&-alert')}
                    title={alertMessage}
                    description={alertDescription}
                    messageValues={messageValues}
                />
            )}
            {_children && <div className={classNames('&-content', bodyClassName)}>
                {_children}
            </div>}
        </ArgModal>
    );
}
