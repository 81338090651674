import React, { useCallback, useContext, useState } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgButton,
    ArgMenu,
    ArgMenuItem,
    ArgModalContainerContext,
    ClassValue,
    ProgressMonitor,
    useCallbackAsync, useArgNotifications,
} from 'src/components/basic';
import { EditFormPolicyModal } from '../edit-policy-modal/edit-form-policy-modal';
import { FormPolicy } from 'src/settings/models/form-policy';
import { FormPoliciesDeletionModal } from '../form-policies-deletion-modal/form-policies-deletion-modal';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';

const messages = defineMessages({
    edit: {
        id: 'settings.form-policy.kebab-menu.edit',
        defaultMessage: 'Edit',
    },
    enable: {
        id: 'settings.form-policy.kebab-menu.activate',
        defaultMessage: 'Activate',
    },
    disable: {
        id: 'settings.form-policy.kebab-menu.deactivate',
        defaultMessage: 'Deactivate',
    },
    delete: {
        id: 'settings.form-policy.kebab-menu.delete',
        defaultMessage: 'Delete',
    },
    changeStatusErrorMessage: {
        id: 'settings.form-policy.menu.change-status-error-message',
        defaultMessage: 'Unable to {statusChange, select, activate {activate} other {deactivate}} form policy',
    },
});

export interface FormPolicyActionsProps {
    formPolicy: FormPolicy;
    onActionSuccess: () => void;
    className?: ClassValue;
}

export function FormPolicyActions(props: FormPolicyActionsProps) {
    const {
        formPolicy,
        onActionSuccess,
        className,
    } = props;

    const [visible, setVisible] = useState(false);

    const modalContainer = useContext(ArgModalContainerContext);
    const notifications = useArgNotifications();

    const handleEdition = useCallback(() => {
        modalContainer.open('edit-form-policy-modal', <EditFormPolicyModal
            closeModal={() => modalContainer.close('edit-form-policy-modal')}
            formPolicy={formPolicy}
            onSuccess={onActionSuccess}
        />);
    }, [modalContainer, formPolicy, onActionSuccess]);

    const handleDeletion = useCallback(() => {
        modalContainer.open('delete-form-policy-modal', <FormPoliciesDeletionModal
            onClose={() => modalContainer.close('delete-form-policy-modal')}
            formPolicies={[formPolicy]}
            onSuccess={onActionSuccess}
        />);
    }, [modalContainer, formPolicy, onActionSuccess]);

    const [handleToggleActivation] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            await explorationSettingsConnector.changeFormPolicyActivationStatus(!!formPolicy.enabled, formPolicy.id, progressMonitor);
            onActionSuccess();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.changeStatusErrorMessage }, error as Error, {
                statusChange: formPolicy.enabled ? 'deactivate' : 'activate',
            });
            throw error;
        }
    }, [onActionSuccess, formPolicy.enabled, formPolicy.id]);


    const actionsMenu = (
        <ArgMenu>
            <ArgMenuItem
                key='edit'
                data-testid='edit'
                onClick={handleEdition}
                label={messages.edit}
            />
            <ArgMenuItem
                key='toggle'
                data-testid='toggle'
                onClick={handleToggleActivation}
                label={formPolicy.enabled ? messages.disable : messages.enable}
            />
            <ArgMenuItem
                key='delete'
                data-testid='delete'
                onClick={handleDeletion}
                label={messages.delete}
            />
        </ArgMenu>
    );

    return (
        <ArgButton
            className={className}
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={visible}
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
        />
    );
}
