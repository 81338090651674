import { defineMessages } from 'react-intl';

import { ClassValue, useArgNotifications, useClassNames } from '../../../components/basic';
import { ContextualVariable } from '../../../exploration/model/contextual-variable';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';
import { ExplorationConnector } from '../../../exploration/utils/connector/exploration-connector';

import './delete-variable-modal.less';

export type DeleteVariableModalProps = {
    className?: ClassValue;
    closeModal: () => void;
    variable: ContextualVariable;
    setContextualVariables: React.Dispatch<React.SetStateAction<ContextualVariable[]>>;
};

export const messages = defineMessages({
    deleteVar: {
        id: 'settings.contextual-variables.delete-variable-modal.delete-var',
        defaultMessage: 'Delete a contextual variable',
    },
    deleteVarConfirmMessage: {
        id: 'settings.contextual-variables.delete-variable-modal.confirm-message',
        defaultMessage: 'Are you sure you want to delete "{variable}"?',
    },
    deleteContextualVariablesError: {
        id: 'settings.contextual-variables.delete-variable-modal.SaveContextualVariablesError',
        defaultMessage: 'Failed to delete contextual variables',
    },
    deleteDescription: {
        id: 'settings.contextual-variables.delete-variable-modal.deleteDescription',
        defaultMessage: 'Are you sure you want to delete the variable "{variable}"?',
    },
    deleteSubDescription: {
        id: 'settings.contextual-variables.delete-variable-modal.deleteSubDescription',
        defaultMessage: 'The variable can be used in rules. It will be permanently removed from the rules to which it belongs',
    },
    cancel: {
        id: 'settings.contextual-variables.delete-variable-modal.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.contextual-variables.delete-variable-modal.delete',
        defaultMessage: 'Delete',
    },
});

export const DeleteVariableModal = ({
    className,
    closeModal,
    variable,
    setContextualVariables,
}: DeleteVariableModalProps) => {
    const classNames = useClassNames('delete-variable-modal');
    const notifications = useArgNotifications();

    const deleteVariable = async () => {
        try {
            await ExplorationConnector.getInstance().deleteContextualVariable(variable.id);
            const variables = await ExplorationConnector.getInstance().getContextualVariables();
            setContextualVariables(variables);
        } catch (error) {
            notifications.snackError({ message: messages.deleteContextualVariablesError }, error as Error);
        }
    };

    const handleSubmit = async () => {
        deleteVariable();
    };

    return (
        <div className={classNames('&', className)}>
            <ConfirmModal
                size='medium'
                title={messages.deleteVar}
                type='delete'
                onConfirm={handleSubmit}
                onClose={closeModal}
                alertDescription={messages.deleteSubDescription}
                alertMessage={messages.deleteVarConfirmMessage}
                messageValues={{ variable: variable.displayName }}
                confirmText={messages.delete}
            />
        </div>
    );
};
