import { useEffect } from 'react';
import Debug from 'debug';

import { ProgressMonitor, SubProgressMonitor } from '../basic';
import { Connector } from '../../utils/connector';


const debug = Debug('common:caches:PagesAccess');


const PAGE_ACCESS_PROPERTY = 'ARG_PAGE_ACCESS';
const TRY_PAGE_ACCESS_PROPERTY = 'ARG_PAGE_ACCESS_TRY';

export interface PageToTest {
    api: string;
    testURL: string;

    gotoURL: string;
}

export function useRecordPageAccess(page?: string) {
    useEffect(() => {
        debug('useRecordPageAccess', 'Record page', page);
        window.localStorage.setItem(PAGE_ACCESS_PROPERTY, (page !== undefined) ? page : document.location.toString());
    }, [page]);
}

export function getRecordPageAccess(): string | undefined {
    const path = window.localStorage.getItem(PAGE_ACCESS_PROPERTY);
    if (path === null) {
        return undefined;
    }

    return path;
}

export function authorizedPageAccessed() {
    debug('authorizedPageAccessed');
    window.localStorage.removeItem(TRY_PAGE_ACCESS_PROPERTY);
}

export function unauthorizedPageAccessed() {
    debug('unauthorizedPageAccessed');
    // The page is no longer accessible
    window.localStorage.removeItem(PAGE_ACCESS_PROPERTY);
}

export async function computePageAccess(pages: PageToTest[], progressMonitor: ProgressMonitor): Promise<string | undefined> {
    debug('computePageAccess', 'pages=', pages);

    if (!window.localStorage.getItem(TRY_PAGE_ACCESS_PROPERTY)) {
        window.localStorage.setItem(TRY_PAGE_ACCESS_PROPERTY, 'true');

        const path = getRecordPageAccess();
        debug('computePageAccess', 'recordPageAccess=', path);
        if (path) {
            return path;
        }
    }

    window.localStorage.setItem(TRY_PAGE_ACCESS_PROPERTY, 'true');

    for (let i = 0; i < pages.length; i++) {
        progressMonitor.verifyCancelled();

        const test = pages[i];
        if (!test.api) {
            console.warn('API is not defined for test=', test);
            continue;
        }

        debug('computePageAccess', 'test URL=', test.testURL);
        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        try {
            await Connector.getInstance().request(test.testURL, {
                verifyJSONResponse: true,
                noRedirectIfUnauthorized: true,
                api: test.api,
            }, sub1);
        } catch (ex) {
            console.error(ex);
            continue;
        }

        window.localStorage.removeItem(TRY_PAGE_ACCESS_PROPERTY);

        debug('computePageAccess', 'goto URL=', test.gotoURL);

        return test.gotoURL;
    }
}
