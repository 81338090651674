import { CSSProperties, ReactNode } from 'react';
import { chain, defaultTo, find, first, isNumber, merge, partition, some } from 'lodash';
import { defineMessages, IntlShape } from 'react-intl';
import classNames from 'classnames';
import { NavigateFunction } from 'react-router-dom';
import * as H from 'history';
import Debug from 'debug';

import {
    ArgIcon,
    ArgImage,
    ClassValue,
    getGlobalNotifications,
    ProgressMonitor,
    SubProgressMonitor,
} from '../../components/basic';
import {
    InfoTitle,
    Vertex,
    VertexId,
    VertexInfo,
    VertexPropertiesOrderBy,
    VertexPropertySorting,
    VertexSorting,
    VertexStyle,
} from '../model/vertex';
import {
    SystemVertexTypeName,
    UniverseId,
    UniversePropertyType,
    UniversePropertyTypes,
    UniverseType,
    UniverseVertexType,
    UniverseVertexTypeName,
    VERTEX_EDGE_BRIEF_SECTION_PART_OF_TYPE,
    VertexSystemPropertyName,
} from '../model/universe';
import { DEFAULT_POSITION_AND_SCALE } from '../../utils/image-alignment-provider';
import { ControlType, getBasicControlType, getControlType } from '../../components/common/controls/controls';
import { DEFAULT_VERTEX_STYLE } from '../constants/default-vertex-style';
import { getTitle } from './node-utils';
import { FlaggedVertex } from '../model/flagged-object';
import { FullOntology, FullOntologyObjectType } from 'src/settings/universes/ontology/types';
import { ThumbnailSize } from '../model/thumbnail-size';
import { ExplorationObjectTypesTypeItem } from '../model/types';
import { Filter } from '../model/filter';
import { PropertyUtils } from './property-utils';
import { ExplorationId } from '../model/exploration';
import { BriefId } from '../model/brief';
import { UniverseConnector } from './connector/universe-connector';
import { CaseId } from 'src/model/folder-case-piece';
import { CasePieceType } from '../../model/case-piece-type';
import { computeURL } from './compute-url';
import { openInNewWindow } from 'src/utils/window';
import { DataViewMode } from '../model/view-mode';
import { ExplorationEnvironment } from './exploration-environment';
import { getDataExplorationApi } from '../../utils/connectors/api-url';
import { CaseFlag } from '../model/exploration-visualization';
import { SuggestVertex } from '../model/suggest';
import { Property } from 'src/components/common/controls/controls-type';
import { DataType } from 'src/components/common/data-types';
import { ExplorationConnector } from './connector/exploration-connector';
import { BriefConnector } from './connector/brief-connector';
import { FormsConnector } from './connector/forms-connector';


const REGRAPH_ICON_ZOOM = 1.2;

export const DEFAULT_MAP_CLUSTER_STYLE = {
    minClusterSize: 0.7,
    labelBackgroundColor: 'transparent',
    labelFontsize: 40,
    minLabelFontSize: 15,
    borderWidth: 2,
};

const messages = defineMessages({
    undefinedValue: {
        id: 'exploration.vertex-utils.UndefinedValue',
        defaultMessage: '#Undefined',
    },
    getVertexError: {
        id: 'exploration.vertex-utils.getVertexError',
        defaultMessage: 'Failed to retrieve vertex',
    },
});

const debug = Debug('exploration:utils:vertex-utils');

export enum VertexColorMode { Normal, Inverted, None }

interface VertexObjectId {
    type: SystemVertexTypeName;
    objectId: ExplorationId | BriefId;
}

export interface UniverseVertexTypeWithCount extends UniverseVertexType {
    count: number;
}

export class VertexUtils {
    static getIconColor = (
        universe: UniverseType | undefined,
        vertexOrType: VertexInfo | UniverseVertexType | string
    ): string => {
        let type: UniverseVertexType | undefined;
        let vertex: VertexInfo | undefined;

        if (typeof vertexOrType === 'object' && vertexOrType) {
            if ((vertexOrType as VertexInfo).type) {
                vertex = vertexOrType as Vertex;
                vertexOrType = vertex.type;
            } else {
                type = vertexOrType as UniverseVertexType;
            }
        }
        if (typeof vertexOrType === 'string' && universe) {
            type = universe.schema.vertices.find((v) => v.name === vertexOrType);
        }

        if (type) {
            const color = type.style?.iconColor;

            if (color) {
                return color;
            }
        }

        const style = VertexUtils.getStyle(universe, vertexOrType);

        if (style.iconColor) {
            return style.iconColor;
        }

        return DEFAULT_VERTEX_STYLE.iconColor!;
    };

    static getBackgroundColor = (vertex: UniverseVertexType): string => {
        const backgroundColor = vertex.style?.fillColor;

        if (backgroundColor) {
            return backgroundColor;
        }

        const style = VertexUtils.getStyle(undefined, vertex);

        if (style.fillColor) {
            return style.fillColor;
        }

        return DEFAULT_VERTEX_STYLE.fillColor!;
    };

    static getStyle = (
        universe: UniverseType | undefined,
        vertexOrType: Vertex | VertexInfo | UniverseVertexType | string
    ): VertexStyle => {
        const type: UniverseVertexType | undefined = VertexUtils.getType(universe, vertexOrType);
        const vertexStyle = (vertexOrType as Vertex | UniverseVertexType | FullOntologyObjectType).style;
        const style = merge({}, DEFAULT_VERTEX_STYLE, type?.style, vertexStyle);

        return style;
    };

    static getIconComponentFromStyle = (
        style: VertexStyle,
        title?: string,
        universe?: UniverseType,
        className?: ClassValue,
        props?: Record<string, any>,
        vertexColorMode: VertexColorMode = VertexColorMode.Normal) => {
        // Generate the position and scale style
        const scale = style.iconScale || DEFAULT_POSITION_AND_SCALE.size;
        const offsetX = style.offsetX || DEFAULT_POSITION_AND_SCALE.dx;
        const offsetY = style.offsetY || DEFAULT_POSITION_AND_SCALE.dy;


        if (style.imageUrl) {
            const positionAndScaleStyle = { transform: `scale(${scale}) translate(${offsetX}, ${offsetY})` };

            return (
                <ArgImage
                    src={VertexUtils.computeVertexImageURL(universe, style.imageUrl, ThumbnailSize.Small)}
                    fit='cover'
                    alt={title}
                    style={positionAndScaleStyle}
                    className={classNames(className)}
                    placeholder={false}
                    {...props}
                />
            );
        }

        if (style.iconName) {
            let scaleTransform = '';
            if (isNumber(scale)) {
                scaleTransform = `scale(${scale * REGRAPH_ICON_ZOOM})`;
            }

            let translateTransform = '';
            if (isNumber(offsetX) && isNumber(offsetY)) {
                translateTransform = `translate(${offsetX}, ${offsetY})`;
            }

            const positionAndScaleStyle = { transform: `${scaleTransform} ${translateTransform}` };
            const iconName = style.iconName;

            const cssStyle: CSSProperties = {
                fontFamily: style.iconFontFamily || DEFAULT_VERTEX_STYLE.iconFontFamily,
            };

            if (vertexColorMode === VertexColorMode.Normal) {
                cssStyle.color = style.iconColor || style.strokeColor;
            } else if (vertexColorMode === VertexColorMode.Inverted) {
                cssStyle.color = style.fillColor;
            }

            let icon;
            if (iconName.length > 1) {
                icon = <i className={iconName} />;
            } else {
                icon = iconName;
            }

            return (
                <span style={{ ...cssStyle, ...positionAndScaleStyle }} className={classNames(className)} {...props}>
                    {icon}
                </span>
            );
        }
    };

    static getIconComponent = (
        universe: UniverseType,
        vertexType: UniverseVertexType,
        className?: ClassValue,
        props?: Record<string, any>,
        vertexColorMode: VertexColorMode = VertexColorMode.Normal
    ): ReactNode => {
        const style = VertexUtils.getStyle(universe, vertexType);
        const title = vertexType?.displayName;

        const {
            iconColor,
            strokeColor,
            fillColor,
        } = style;

        // Generate the position and scale style
        const scale = style.iconScale || DEFAULT_POSITION_AND_SCALE.size;
        const offsetX = style.offsetX || DEFAULT_POSITION_AND_SCALE.dx;
        const offsetY = style.offsetY || DEFAULT_POSITION_AND_SCALE.dy;

        if (style.imageUrl) {
            const positionAndScaleStyle = { transform: `scale(${scale}) translate(${offsetX}, ${offsetY})` };

            return (
                <ArgImage
                    src={VertexUtils.computeVertexImageURL(universe, style.imageUrl, ThumbnailSize.Small)}
                    fit='cover'
                    alt={title}
                    style={positionAndScaleStyle}
                    className={classNames(className)}
                    {...props}
                />
            );
        }

        let scaleTransform = '';
        if (isNumber(scale)) {
            scaleTransform = `scale(${scale * REGRAPH_ICON_ZOOM})`;
        }

        let translateTransform = '';
        if (isNumber(offsetX) && isNumber(offsetY)) {
            translateTransform = `translate(${offsetX}, ${offsetY})`;
        }

        const positionAndScaleStyle = { transform: `${scaleTransform} ${translateTransform}` };

        if (style.iconName) {
            const iconName = style.iconName;

            const cssStyle: CSSProperties = {
                fontFamily: style.iconFontFamily || DEFAULT_VERTEX_STYLE.iconFontFamily,
            };

            if (vertexColorMode === VertexColorMode.Normal) {
                cssStyle.color = iconColor || strokeColor;
            }

            if (vertexColorMode === VertexColorMode.Inverted) {
                cssStyle.color = fillColor;
            }
            if (props?.size) {
                cssStyle.fontSize = props.size;
            }

            let icon;
            if (style.iconName.length > 1) {
                icon = <i className={iconName} />;
            } else {
                icon = iconName;
            }

            return (
                <span style={{ ...cssStyle, ...positionAndScaleStyle }} className={classNames(className)} {...props}>
                    {icon}
                </span>
            );
        }

        return (
            <ArgIcon
                className={classNames(className)}
                name={DEFAULT_VERTEX_STYLE.iconName!}
                color={iconColor}
                style={positionAndScaleStyle}
                {...props}
            />
        );
    };

    static getType = (
        universe: UniverseType | undefined,
        vertexOrType: VertexInfo | UniverseVertexType | string
    ): UniverseVertexType | undefined => {
        let type: UniverseVertexType | undefined;

        if (typeof vertexOrType === 'object' && vertexOrType) {
            if ((vertexOrType as VertexInfo).type) {
                vertexOrType = (vertexOrType as VertexInfo).type;
            } else {
                type = vertexOrType as UniverseVertexType;
            }
        }

        if (typeof vertexOrType === 'string' && universe) {
            type = universe.schema.vertices.find((v) => v.name === vertexOrType);
        }

        return type;
    };

    static getTitle = (universe: UniverseType | FullOntology | undefined, vertexTitle?: InfoTitle): string => {
        return getTitle(vertexTitle);
    };

    static computeVertexImageURL = (
        universe: UniverseType | FullOntology | undefined | UniverseId,
        imageUrl: string,
        thumbnailSize?: ThumbnailSize
    ): string | undefined => {
        const regexp = /^resource:(.*)$/.exec(imageUrl);
        if (!regexp) {
            let url = imageUrl;

            if (thumbnailSize) {
                url += `?thumbnailSize=${encodeURIComponent(thumbnailSize)}`;
            }

            return url;
        }

        if (!universe) {
            return undefined;
        }

        const resourceId = regexp[1];

        const id = (universe as UniverseType)?.id || (universe as string);

        let url = `${getDataExplorationApi()}/universes/${encodeURIComponent(id)}/resources/${encodeURIComponent(resourceId)}`;

        if (thumbnailSize) {
            url += `?thumbnailSize=${encodeURIComponent(thumbnailSize)}`;
        }

        return url;
    };

    static isPropertyVisible = (universePropertyType: UniversePropertyType): boolean => {
        const type = universePropertyType.type;

        switch (type) {
            case UniversePropertyTypes.Image:
            case UniversePropertyTypes.Action:
            case UniversePropertyTypes.Resources:
                return false;
        }

        return true;
    };

    static VertexPropertiesSortingCompare = (
        propertyNameA: string,
        propertyNameB: string,
        sortingSettings: VertexSorting,
        vertexSchema?: UniverseVertexType
    ): number => {
        if (!sortingSettings?.properties) {
            return -1;
        }
        const sortingA: VertexPropertySorting | undefined = sortingSettings.properties[propertyNameA];
        const sortingB: VertexPropertySorting | undefined = sortingSettings.properties[propertyNameB];

        if (
            sortingA?.position !== undefined &&
            sortingB?.position !== undefined &&
            sortingA?.position > sortingB?.position
        ) {
            return 1;
        }
        if (sortingA?.position !== undefined && !sortingB?.position) {
            return -1;
        }
        if (!sortingA?.position && sortingB?.position !== undefined) {
            return 1;
        }
        if (!sortingA?.position && !sortingB?.position && vertexSchema) {
            switch (sortingSettings.orderBy) {
                case VertexPropertiesOrderBy.AlphaAsc: {
                    const propertiesByName = PropertyUtils.getPropertiesByName(vertexSchema.properties);

                    return defaultTo(propertiesByName[propertyNameA]?.displayName, propertyNameA)
                        .localeCompare(defaultTo(propertiesByName[propertyNameB]?.displayName, propertyNameB));
                }
                case VertexPropertiesOrderBy.AlphaDesc: {
                    const propertiesByName = PropertyUtils.getPropertiesByName(vertexSchema.properties);

                    return defaultTo(propertiesByName[propertyNameB]?.displayName, propertyNameB)
                        .localeCompare(defaultTo(propertiesByName[propertyNameA]?.displayName, propertyNameA));
                }
                default:
                    if (!vertexSchema.properties) {
                        return -1;
                    }

                    return vertexSchema.properties.findIndex((p) => p.name === propertyNameA) <=
                    vertexSchema.properties.findIndex((p) => p.name === propertyNameB)
                        ? -1
                        : 1;
            }
        }

        return -1;
    };

    static renderVertexPropertyValue(
        vertexPropertyName: string,
        vertexPropertyValue: any,
        intl: IntlShape,
        universe?: UniverseType,
        vertexType?: string
    ): ReactNode {
        if (vertexPropertyValue === '' || vertexPropertyValue === undefined || vertexPropertyValue === null) {
            return intl.formatMessage(messages.undefinedValue);
        }

        if (universe && vertexType) {
            const universeVertexType = find(universe.schema.vertices, ['name', vertexType]);

            const universeVertexProperty = find(universeVertexType?.properties, ['name', vertexPropertyName]);

            //!THIS IS NOT POSSIBLE BECAUSE OF THE LACK OF THE LINKED PROPERTIY VALUE OF THE OLD VERTEX PROPERTY
            //!TO BE DONE WHEN COORDINATES AND ADRESS ARE MANAGED
            // if (customProtocolSupport) {
            //     let linkedValues: { [x: string]: { type: UniversePropertyType | undefined; value: any; }; } = {};
            //     const universeVertexType = find(universe.schema.vertices, ['name', vertexType]);
            //     if (universeVertexType) {
            //         const linkedProperties = find(
            //             universeVertexType.properties,
            //             (p) => p.name === vertexPropertyName
            //         )?.linkedProperties;
            //         console.log({ vertexPropertyName, linkedProperties });

            //         if (linkedProperties) {
            //             linkedValues = mapValues(linkedProperties, (propertyName) => {
            //                 return {
            //                     type: find(universeVertexType.properties, ['name', propertyName]),
            //                     value: vertexPropertyValue,
            //                 };
            //             });
            //         }
            //     }
            //     if (universeVertexProperty) {
            //         switch (universeVertexProperty?.type) {
            //             case UniversePropertyTypes.Address: {
            //                 const coordinates = linkedValues.Coordinates;
            //                 if (coordinates?.type && coordinates?.value) {
            //                     const ret = VertexUtils.renderCustomProtocol(
            //                         coordinates.type,
            //                         coordinates.value,
            //                         intl,
            //                         universeVertexProperty,
            //                         vertexPropertyValue
            //                     );
            //                     if (ret !== undefined) {
            //                         return ret;
            //                     }
            //                 }
            //                 break;
            //             }

            //             default:
            //                 const ret = VertexUtils.renderCustomProtocol(
            //                     universeVertexProperty,
            //                     vertexPropertyValue,
            //                     intl,
            //                     undefined,
            //                     undefined
            //                 );
            //                 if (ret !== undefined) {
            //                     return ret;
            //                 }
            //                 break;
            //         }
            //     }
            // }

            if (universeVertexProperty) {
                let text = getControlType(universeVertexProperty)?.toText(vertexPropertyValue, intl);

                if (text) {
                    switch (universeVertexProperty.type) {
                        case UniversePropertyTypes.Text:
                        case UniversePropertyTypes.String:
                        case UniversePropertyTypes.MultiString:
                        case UniversePropertyTypes.Address:
                            text = text.replace(/([,.;])/g, (x: string) => `${x}\u200B`);
                            break;
                    }

                    return text;
                }
            }
        }

        return intl.formatMessage(messages.undefinedValue);
    }

    static getGeoVertices = (universe: UniverseType) => {
        return chain(universe.schema.vertices)
            .keyBy((vertex) => vertex.name)
            .filter((vertex) => vertex.defaultGeographyProperty !== undefined)
            .value();
    };

    static getVertexSchema = (
        universe: UniverseType,
        vertexType: UniverseVertexTypeName
    ): UniverseVertexType | undefined => {
        return universe.schema.vertices.find((v) => v.name == vertexType);
    };

    static getExplorationVerticesInformations = (
        universe: UniverseType,
        explorationObjects?: Readonly<ExplorationObjectTypesTypeItem[]>
    ): UniverseVertexTypeWithCount[] => {
        if (!explorationObjects) {
            return [];
        }

        const informations: UniverseVertexTypeWithCount[] = [];
        universe.schema.vertices.forEach((vertexType: UniverseVertexType) => {
            const label = explorationObjects.find((c) => c.label === vertexType.name);
            if (label?.verticesCount) {
                informations.push({
                    ...vertexType,
                    count: label.verticesCount,
                });
            }
        });

        return informations;
    };

    static mapFlaggedVertexToVertex = (flaggedVertex?: FlaggedVertex): Vertex | undefined => {
        if (!flaggedVertex) {
            return;
        }

        const vertex: Vertex = {
            id: flaggedVertex.id,
            type: flaggedVertex.type,
            title: flaggedVertex.title,
            style: flaggedVertex.style,
            properties: flaggedVertex.properties,
        };

        return vertex;
    };

    // Returns the list of properties of vertexType, with the title property always first and the rest sorted according to vertexType.sorting.
    static getProperties = (
        vertexType: UniverseVertexType,
        opts: { includeSecondaries?: boolean, ignoreEnvDisplayLimit?: boolean } = {}
    ): UniversePropertyType[] => {
        let ret = chain(vertexType.properties)
            .filter((prop) => VertexUtils.isPropertyVisible(prop))
            .filter((prop) => !PropertyUtils.isSystemTypedPropertyType(prop.name))
            .thru((array) => {
                const [[titleProperty], others] = partition(array, property => property.isTitle);

                return titleProperty ? [titleProperty, ...others] : others;
            })
            .tap((array) => {
                const sorting = vertexType?.sorting;
                if (sorting) {
                    array.sort((a, b) => {
                        return VertexUtils.VertexPropertiesSortingCompare(
                            a.name, b.name, sorting, vertexType
                        );
                    });
                }
            })
            .value();

        if (!opts.includeSecondaries) {
            const isAnySummaryProperty = some(
                vertexType?.sorting?.properties,
                (settingsProperty) => settingsProperty.showOnSummary === true
            );
            if (!isAnySummaryProperty) {
                return ret;
            }

            ret = ret.filter(entry => {
                if (!vertexType?.sorting?.properties || !vertexType?.sorting?.properties[entry.name]) {
                    return false;
                }

                return vertexType?.sorting?.properties[entry.name].showOnSummary ?? false;
            });

            if (!opts.ignoreEnvDisplayLimit) {
                ret = ret.slice(0, ExplorationEnvironment.property_list_display_limit);
            }
        }

        return ret;
    };

    // Returns vertex primary properties
    static getPrimaryProperties = (
        vertexType: UniverseVertexType
    ): UniversePropertyType[] => {
        return VertexUtils.getProperties(vertexType, { ignoreEnvDisplayLimit: true });
    };

    static getSuggestVertexFlag = (suggestVertex: SuggestVertex) => {
        if (!suggestVertex.flags) {
            return;
        }

        const firstFlagEntry = first(Object.entries(suggestVertex.flags));
        if (!firstFlagEntry) {
            return;
        }

        const [, flagLabel] = firstFlagEntry;
        const ret: CaseFlag = {
            label: flagLabel,
        };

        return ret;
    };
}

export function getSingleVertex(filter: Filter): VertexId | undefined {
    if (filter?.filterGroups?.length !== 1) {
        return undefined;
    }

    if (filter.filterGroups[0].type?.included?.length) {
        return undefined;
    }

    if (filter.filterGroups[0].id?.included?.length !== 1) {
        return undefined;
    }

    const vertexId = filter.filterGroups[0].id.included[0]; // CONVERT TO STRING
    if (vertexId === undefined) {
        return undefined;
    }

    return String(vertexId);
}

async function getSystemVertexId(vertexInfo: VertexInfo, universeId: UniverseId, progressMonitor: ProgressMonitor): Promise<VertexObjectId | undefined> {
    const notifications = getGlobalNotifications();

    let objectIdPropertyName: VertexSystemPropertyName | undefined;
    switch (vertexInfo.type) {
        case SystemVertexTypeName.Exploration:
            objectIdPropertyName = VertexSystemPropertyName.ExplorationIdentifier;
            break;
        case SystemVertexTypeName.Brief:
        case SystemVertexTypeName.BriefSection:
            objectIdPropertyName = VertexSystemPropertyName.BriefIdentifier;
            break;

        default:
            return undefined;
    }

    let objectId;

    try {
        const visualizedVertex = await UniverseConnector.getInstance().visualizeVertex(
            universeId,
            vertexInfo.id,
            undefined,
            { listRelatedVertices: true },
            undefined,
            progressMonitor
        );
        objectId = visualizedVertex.properties?.[objectIdPropertyName]?.[0]?.value as string || vertexInfo.id;

        if (vertexInfo.type === SystemVertexTypeName.BriefSection) {
            const briefSectionPartOfNeighbor = visualizedVertex.neighbors?.find((neighbor) => neighbor.edge.type === VERTEX_EDGE_BRIEF_SECTION_PART_OF_TYPE);

            if (!briefSectionPartOfNeighbor) {
                return;
            }
            objectId = briefSectionPartOfNeighbor.edge.to;
        }

        const ret = {
            type: vertexInfo.type,
            objectId,
        };

        return ret;
    } catch (error) {
        if (progressMonitor.isCancelled) {
            throw error;
        }

        notifications.snackError({ message: messages.getVertexError }, error as Error);
        throw error;
    }
}

export async function openVertex(
    navigate: NavigateFunction,
    location: H.Location<unknown>,
    caseId: CaseId,
    universeId: UniverseId,
    vertexInfo: VertexInfo,
    newWindow: boolean,
    progressMonitor: ProgressMonitor
) {
    const sub1 = new SubProgressMonitor(progressMonitor, 1);
    const ret = await getSystemVertexId(vertexInfo, universeId!, sub1);
    debug('openVertex', 'caseId=', caseId, 'universeId=', universeId, 'vertexInfo=', vertexInfo);
    if (ret) {
        switch (ret.type) {
            case SystemVertexTypeName.Exploration: {
                const sub2 = new SubProgressMonitor(progressMonitor, 1);
                const explorationCasePiece = await ExplorationConnector.getInstance().getExplorationCasePiece(caseId, ret.objectId, sub2);
                if (explorationCasePiece) {
                    const newURL = computeURL(location, caseId!, explorationCasePiece.id, CasePieceType.Exploration)!;
                    if (newWindow) {
                        openInNewWindow(newURL);

                        return;
                    }
                    navigate(newURL);

                    return;
                }
                break;
            }
            case SystemVertexTypeName.Brief:
            case SystemVertexTypeName.BriefSection: {
                const sub2 = new SubProgressMonitor(progressMonitor, 1);
                const briefCasePiece = await BriefConnector.getInstance().getBriefCasePiece(caseId, ret.objectId, sub2);
                if (briefCasePiece) {
                    const newURL = computeURL(location, caseId!, briefCasePiece.id, CasePieceType.Brief)!;
                    if (newWindow) {
                        openInNewWindow(newURL);

                        return;
                    }
                    navigate(newURL);

                    return;
                }
                break;
            }
        }

        return;
    }

    const sub2 = new SubProgressMonitor(progressMonitor, 1);
    const formCasePiece = await FormsConnector.getInstance().createForm(caseId, '', vertexInfo, universeId, sub2);

    const viewMode: DataViewMode = {
        form: {
            vertexId: vertexInfo.id,
            universeId,
        },
    };
    const newURL = computeURL(location, caseId!, formCasePiece.id, CasePieceType.Form, viewMode)!;
    if (newWindow) {
        openInNewWindow(newURL);

        return;
    }

    navigate(newURL);
}

export function universePropertyToControlProperty(universeProperty: UniversePropertyType): Property {
    return {
        name: universeProperty.name,
        displayName: universeProperty.displayName,
        type: universeProperty.type,
        fixedValues: universeProperty.possibleValues,
        isContinuous: universeProperty.isContinuous,
    };
}

export function getVertexControlType(universeProperty: UniversePropertyType): ControlType | undefined {
    const controlProperty = universePropertyToControlProperty(universeProperty);
    const controlType = getControlType(controlProperty);

    return controlType;
}

export function getVertexEditorControlType(universeProperty: UniversePropertyType): ControlType | undefined {
    const controlType = getVertexControlType(universeProperty);

    return controlType;
}
