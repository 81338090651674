import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ArgInputText, ClassValue, useClassNames } from '../../../components/basic';
import { ApplicationOrModuleBranding } from '../../models/visual-identity';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';
import ImageManager from './image-manager';

import ChapsIcon from '../../../assets/chapsicon.svg';
import ArgonosLogo from '../../../assets/argonos.svg';
import '../components/app-brander.less';


const messages = defineMessages({
    appName: {
        id: 'settings.visual-identity.app-brander.appName',
        defaultMessage: 'Name of the environment',
    },
    domainUrl: {
        id: 'settings.visual-identity.app-brander.domainUrl',
        defaultMessage: 'Domain URL',
    },
    nameAppInputPlaceholder: {
        id: 'settings.visual-identity.app-brander.nameAppInputPlaceholder',
        defaultMessage: 'ex: Argonos',
    },
    urlInputPlaceholder: {
        id: 'settings.visual-identity.app-brander.urlInputPlaceholder',
        defaultMessage: 'ex: argonos.com',
    },
});

interface AppBranderProps {
    className?: ClassValue;
    applicationModuleBranding: ApplicationOrModuleBranding;
    onCustomNameChange: (customName: string) => void;
    onIconChange: (icon: Blob | undefined) => void;
    onLogoChange: (logo: Blob | undefined) => void;
}

export function AppBrander(props: AppBranderProps) {
    const { className, applicationModuleBranding, onCustomNameChange, onIconChange, onLogoChange } = props;

    const classNames = useClassNames('settings-app-brander');

    const canManageVisualIdentity = useHasPermission<SettingsPermissions>('admin.visual.identity.management');

    return (
        <>
            <div className={classNames('&', className)}>
                <div className={classNames('&-textInputs', '&-cell')}>
                    <div className={classNames('&-textInputs-name')}>
                        <div className={classNames('&-textInputs-title')}>
                            <FormattedMessage {...messages.appName} />
                        </div>
                        <ArgInputText
                            value={applicationModuleBranding.brandingName}
                            onInputChange={onCustomNameChange}
                            autoFocus={true}
                            disabled={!canManageVisualIdentity}
                            placeholder={messages.nameAppInputPlaceholder}
                        />
                    </div>
                    {/* <div className={classNames('&-textInputs-url')}>
                        <div className={classNames('&-textInputs-title')}>
                            <FormattedMessage {...messages.domainUrl} />
                        </div>
                        <ArgInputText disabled={true} placeholder={messages.urlInputPlaceholder}/>
                    </div>*/}
                </div>
                <div className={classNames('&-cell')}>
                    <ImageManager
                        moduleBranding={applicationModuleBranding}
                        onImageChange={onLogoChange}
                        imageType='logo'
                        logoPlaceholder={ArgonosLogo}
                    />
                </div>
                <div className={classNames('&-cell')}>
                    <ImageManager
                        moduleBranding={applicationModuleBranding}
                        onImageChange={onIconChange}
                        imageType='icon'
                        logoPlaceholder={ChapsIcon}
                    />
                </div>
            </div>
        </>
    );
}
