import React, {
    ChangeEvent,
    FocusEventHandler,
    KeyboardEvent,
    KeyboardEventHandler,
    MouseEvent,
    ReactNode,
    Ref,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
    WheelEventHandler,
} from 'react';
import { intersection, isEmpty, isFunction, isNil } from 'lodash';
import Debug from 'debug';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';

import { DEFAULT_AUTOCOMPLETE, DEFAULT_INPUT_INPUT_TYPE, DEFAULT_INPUT_TYPE, DEFAULT_SIZE } from '../defaults';
import {
    ArgGetItemBackgroundColor,
    ArgGetItemClassName,
    ArgGetItemIcon,
    ArgGetItemKey,
    ArgGetItemLabel,
    ArgGetItemTooltip,
    computeItemBackgroundColor,
    computeItemClassName,
    computeItemIcon,
    computeItemKey,
    computeItemLabel,
    computeItemTooltip,
    getDataTestIdFromProps,
    preventDefault,
} from '../utils';
import {
    ArgChangeReason,
    ArgInputInputType,
    ArgInputState,
    ArgInputType,
    ArgMessageValues,
    ArgPlaceholderText,
    ArgRenderedText,
    ArgSize,
} from '../types';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ThreeDotsLoading } from '../arg-loading/three-dots-loading';
import { KeyBindingDescriptor } from '../keybindings/keybinding';
import { useKeyBinding } from '../keybindings/use-keybinding';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ArgTag } from '../arg-tag/arg-tag';
import { ArgIcon } from '../arg-icon/arg-icon';
import { computeText, renderText } from '../utils/message-descriptor-formatters';
import { DND_NO_SUPPORT, DndAction, Droppable } from '../arg-dnd/droppable';
import { $yield } from '../utils/yield';
import { useIsDragging } from '../utils/use-is-dragging';
import { DraggingEvent } from '../arg-dnd/drag-drop-context';
import { ArgTooltip2, TooltipPlacement } from '../arg-tooltip/arg-tooltip2';
import { ArgPopover } from '../arg-popover/arg-popover';
import { ArgComponentPopoverProps, usePopover } from '../arg-popover/use-popover';
import { ArgComponentProps } from '../arg-component/arg-component';
import { isIn } from '../utils/is-in';
import { useFilterDataProps } from '../utils/use-filter-data-props';

import './arg-input.less';

const messages = defineMessages({
    moreTagCount: {
        id: 'basic.arg-input.MoreTagsCount',
        defaultMessage: '+{count} values',
    },
});


const debug = Debug('argonode:components:common:ArgInput');


const DEFAULT_POPOVER_PLACEMENT = 'bottomRight';
const DEFAULT_POPOVER_OFFSET = { mainAxis: 12 };
/**
 *  BlUR_DELAI_MS is updated to 0 to fix the bug N° 7416
 Explanation:
 The date input value is lost when clicking on save button in the form vertex Editor (because of the delay).
 To resolve this issue this delay has been reset to 0 (It has been added to delay the blur event when clicking on clear button)
 and a state is added and set to true when the clear button is clicked to prevent the blur event and reset to false once the
 blur event handler did not get invoked.
 !NOTE: Mousedown event is used because it fires before the blur event.
 */

const BLUR_DELAY_MS = 0;
const DEBOUNCE_DELAY_MS = 800;
const CLEAR_DELAY_MS = 0;
const ENTER_DELAY_MS = 0;
const DEFAULT_TAG_TOOLTIP = true;

const MAX_TAG_COUNT = 15;

const DISABLE_UNMOUNTED_CHANGE = false;

const SWITCH_FORMAT_VALUE_ON_FOCUS = false;

export interface PendingCommit<T> {
    value: T | null;
    reason: ArgChangeReason;
    timeoutId: any;
}

export interface ArgInputKeyPressEvent {
    input: string;
    keyboardEvent: KeyboardEvent;
    selectionRange: {
        start: number,
        end: number
    };
}

export interface ArgInputDroppedItem<T = any> {
    type: string;
    data: T;
}

export interface ArgInputDndConfig<T = any, D = any> {
    allowedDndTypes: string[];
    allowDrop?: boolean | ((event: DraggingEvent) => boolean);
    renderDroppedItem?: (item: ArgInputDroppedItem<T>) => ReactNode;
    onDrop: (type: string, item: D) => void;
    droppedItem?: ArgInputDroppedItem<T> | false;
    dropPlaceholder?: ArgRenderedText;
}

export interface ArgInputCustomComponentProps<T> {
    value: T | null;
    internalInputValue: string;
    className: ClassValue;
    onBlur: FocusEventHandler;
    onFocus: FocusEventHandler;
    onChange: (value: T | null | Event | ChangeEvent, reason?: ArgChangeReason) => void;
    ref: Ref<HTMLElement>,
    onUnmount: (value: string) => void;

    id?: string;
    disabled?: boolean;
    readOnly?: boolean;
    maxLength?: number;
    placeholder?: string;
    autoComplete?: string | false;
}

export interface ArgInputProps<T, U> extends ArgComponentProps, ArgComponentPopoverProps {
    id?: string;
    className?: ClassValue;
    type?: ArgInputType;
    inputType?: ArgInputInputType;
    hidden?: boolean;
    size?: ArgSize;
    state?: ArgInputState;
    left?: ReactNode | 'magnifier';
    clearable?: boolean;
    right?: ReactNode | 'dropdown';
    loading?: boolean;
    progressMonitor?: ProgressMonitor;

    onInputChange?: (value: string) => void;
    onKeyPress?: (event: ArgInputKeyPressEvent) => void
    onFocus?: FocusEventHandler;
    onClear?: () => void;
    onBlur?: FocusEventHandler;
    onWheel?: WheelEventHandler;
    onWheelCapture?: WheelEventHandler;
    onKeyDown?: KeyboardEventHandler;
    onChange?: (value: T | null, reason: ArgChangeReason) => void;

    placeholder?: ArgPlaceholderText;
    disabled?: boolean;
    readOnly?: boolean;
    maxLength?: number;

    initialValue?: T;
    value?: T;
    formatValue: (value: T | null, focused?: boolean) => string;
    filterInput?: (input: string) => string;
    parseValue: (input: string) => T | null;
    shouldPerformChange?: (input: string) => boolean;

    messageValues?: ArgMessageValues;

    tooltip?: boolean | ArgRenderedText;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName?: ClassValue;

    popoverFitWidth?: boolean;

    tags?: U[];
    tagSize?: ArgSize;
    tagToolip?: boolean;
    tagReadOnly?: boolean;
    maxTagShown?: number;
    onTagClose?: (tag: U) => void;
    getTagKey?: ArgGetItemKey<U>;
    getTagLabel?: ArgGetItemLabel<U>;
    getTagTooltip?: ArgGetItemTooltip<U>;
    getTagIcon?: ArgGetItemIcon<U>;
    getTagClassName?: ArgGetItemClassName<U>;
    getTagBackgroundColor?: ArgGetItemBackgroundColor<U>;
    moreTagPopoverClassName?: ClassValue,
    moreTagMessage?: MessageDescriptor

    onTagClick?: (tag: U) => void;

    // HTML
    htmlAutoComplete?: string | false;

    onInputClick?: (event: MouseEvent) => void;

    //inputRef?: Ref<FocusableElement>;
    autoFocus?: boolean;
    autoSelect?: boolean;

    debounce?: boolean | number;
    catchUnmountedChange?: boolean;

    // Please take care of this props, only expert would set this property
    renderInputComponent?: (props: ArgInputCustomComponentProps<T>) => ReactNode;

    focusKeyBinding?: KeyBindingDescriptor;

    dndConfig?: ArgInputDndConfig;

    disableCopy?: boolean;
    disablePaste?: boolean;
    disableCut?: boolean;

    'data-testid'?: string;
}

export function ArgInput<T = string, U = any>(props: ArgInputProps<T, U>) {
    const {
        id,
        size = DEFAULT_SIZE,
        type = DEFAULT_INPUT_TYPE,
        inputType: externalInputType = DEFAULT_INPUT_INPUT_TYPE,
        className,
        loading,
        progressMonitor,
        hidden,
        maxLength,
        onInputChange,
        onKeyPress,
        onChange,
        onFocus,
        onClear,
        onBlur,
        onWheel,
        onWheelCapture,
        onKeyDown,
        placeholder,
        disabled,
        readOnly,
        left,
        right,
        initialValue,
        parseValue,
        filterInput,
        formatValue,
        shouldPerformChange,
        value: externalValue,
        messageValues,
        clearable,

        tooltip,
        tooltipPlacement,
        tooltipClassName,

        popover,
        popoverFitWidth = true,

        state,
        htmlAutoComplete = DEFAULT_AUTOCOMPLETE,
        tags,
        tagSize,
        tagToolip = DEFAULT_TAG_TOOLTIP,
        maxTagShown = MAX_TAG_COUNT,
        getTagKey,
        getTagLabel,
        getTagTooltip,
        getTagIcon,
        getTagClassName,
        getTagBackgroundColor,
        moreTagMessage,
        onTagClose,
        onTagClick,
        onInputClick,
        moreTagPopoverClassName,
        autoFocus,
        autoSelect,
        debounce,
        catchUnmountedChange,
        tagReadOnly,

        renderInputComponent,

        focusKeyBinding,

        dndConfig,
        //inputRef,

        disableCopy,
        disablePaste,
        disableCut,

        ...otherProps // dataProps
    } = props;

    const intl = useIntl();
    const inputContainerRef = useRef<HTMLDivElement>(null);
    const shouldPreventBlurRef = useRef(false);
    const popoverRef = useRef<HTMLElement>(null);

    const isDragging = useIsDragging();

    const [inputType, setInputType] = useState<ArgInputInputType>(externalInputType);

    const useInternalValue = !isIn(props, 'value');

    const classNames = useClassNames('arg-input');
    const dataTestId = getDataTestIdFromProps(props);

    const {
        hasPopover,
        popoverVisible,
        getPopoverProps,
        handlePopoverVisibleChange,
    } = usePopover({
        ...props,
        popoverFitWidth: props.popoverFitWidth ?? true,
        popoverClassName: classNames('&-popover-overlay', 'arg-popover-overlay', props.popoverClassName),
        popoverPlacement: props.popoverPlacement ?? DEFAULT_POPOVER_PLACEMENT,
    });

    const [internalInputValue, setInternalInputValue] = useState<string>(() => {
        const inputInitialValue = formatValue((initialValue === undefined) ? null : initialValue);

        return inputInitialValue;
    });

    const [internalValue, setInternalValue] = useState<T | null>(null);

    const pendingCommitRef = useRef<PendingCommit<T>>();
    const committedValueRef = useRef<T | null>();
    const committedInitialValueRef = useRef<T | null | undefined>();

    const [showTagPopover, setShowTagPopover] = useState<boolean>(false);

    const modifiedRef = useRef<boolean>();

    let value: T | null = useInternalValue ? internalValue : ((externalValue === undefined) ? null : externalValue);

    const [focused, setFocused] = useState<boolean>(false);
    useEffect(() => {
        if (SWITCH_FORMAT_VALUE_ON_FOCUS === false) {
            return;
        }

        const pendingCommit = pendingCommitRef.current;

        if (pendingCommit) {
            const value = pendingCommit.value;
            const inputValue = formatValue(value, false);
            setInternalInputValue(inputValue);

            return;
        }

        const inputValue = formatValue(value, false);
        setInternalInputValue(inputValue);
    }, [focused]);

    debug('render', 'value=', value, 'internalValue=', internalValue, 'externalValue=', externalValue, 'useInternalValue=', useInternalValue);

    useEffect(() => {
        const _initialValue = (initialValue === undefined) ? null : initialValue;
        setInternalValue(_initialValue);
        if (useInternalValue) {
            value = _initialValue;
        }
        debug('Initial value changed to', value);
        committedValueRef.current = value;
        committedInitialValueRef.current = _initialValue;
        if (pendingCommitRef.current) {
            const timeoutId = pendingCommitRef.current?.timeoutId;
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            pendingCommitRef.current = undefined;
        }
    }, [initialValue]);

    useEffect(() => {
        if (pendingCommitRef.current) {
            pendingCommitRef.current.value = value;
        }
    }, [value]);

    useEffect(() => {
        return () => {
            const timeoutId = pendingCommitRef.current?.timeoutId;
            if (timeoutId) {
                debug('exit', '**** CLEAR TIMEOUT');

                clearTimeout(timeoutId);
            }
        };
    }, []);

    useEffect(() => {
        const newValue = formatValue((value === undefined) ? null : value);

        debug('[value]', 'value=', value, 'formattedValue=', newValue);

        setInternalInputValue(newValue);
    }, [formatValue, value]);


    const commitValue = useCallback((newValue: T | null, reason: ArgChangeReason, waitingMs: number) => {
        debug('commitValue', 'Request committed value  newValue=', newValue, 'reason=', reason, 'waitingMs=', waitingMs, 'commited=', committedValueRef.current, 'pending=', pendingCommitRef.current);

        const pendingCommit = pendingCommitRef.current;

        if (pendingCommit) {
            if (pendingCommit.value === newValue && waitingMs) {
                debug('  => same pending value');

                return;
            }
        } else if (committedValueRef.current === newValue && reason !== 'enter') {
            debug('  => same committed value');

            return;
        }
        debug('  => process');


        const timeoutId = pendingCommit?.timeoutId;
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        if (!waitingMs) {
            pendingCommitRef.current = undefined;
            committedValueRef.current = newValue;

            if (useInternalValue) {
                setInternalValue(newValue);
            }

            debug('Commit value without waiting newValue=', newValue, 'reason=', reason);
            onChange && onChange(newValue, reason);

            return;
        }

        const newPendingCommit = {
            value: newValue,
            reason,

            timeoutId: setTimeout(() => {
                pendingCommitRef.current = undefined;
                const updatedValue = newPendingCommit.value;

                debug('Process timeout commit=', newPendingCommit);

                committedValueRef.current = updatedValue;

                if (useInternalValue) {
                    setInternalValue(updatedValue);
                }

                debug('Commit value delayed newValue=', updatedValue, 'reason=', newPendingCommit.reason);
                onChange && onChange(updatedValue, newPendingCommit.reason);
            }, waitingMs),
        };

        pendingCommitRef.current = newPendingCommit;
    }, [onChange, useInternalValue]);

    const handleOnFocus = useCallback((event: React.FocusEvent) => {
        debug('FOCUS');

        setFocused(true);

        onFocus && onFocus(event);
    }, [onFocus]);

    const handleOnBlur = useCallback((event: React.FocusEvent) => {
        if (shouldPreventBlurRef.current) {
            shouldPreventBlurRef.current = false;

            return;
        }

        const input = (event.target as HTMLInputElement).value || null;

        debug('BLUR value=', value, 'input=', input);

        if (!input) {
            commitValue(null, 'blur', BLUR_DELAY_MS);
        } else {
            const v: T | null = parseValue(input);
            commitValue(v, 'blur', BLUR_DELAY_MS);
        }

        setFocused(false);

        onBlur?.(event);
    }, [commitValue, value, parseValue, onBlur]);


    const myInputRef = useRef<HTMLInputElement | null>(null);
    const myInputValueRef = useRef<string>();
    const myInputRefCb = useCallback((element: HTMLInputElement | null) => {
        if (element) {
            myInputRef.current = element;

            if (autoFocus) {
                myInputRef.current.focus?.();
            }

            return;
        }

        if (!myInputRef.current) {
            return;
        }

        myInputValueRef.current = myInputRef.current?.value;
        myInputRef.current = null;
    }, [autoFocus]);

    const handleInputOnUnmount = useCallback((value: string) => {
        myInputValueRef.current = value;
    }, []);

    const unmountRefs = useRef<{
        onChange: ((value: T | null, reason: ArgChangeReason) => void) | undefined,
        parseValue: (input: string) => T | null
    }>();

    unmountRefs.current = {
        onChange,
        parseValue,
    };

    const handleFocusKeyBinding = useCallback(() => {
        const focusElement = myInputRef.current;

        if (typeof (focusElement?.focus) === 'function') {
            focusElement.focus();
        }
    }, []);

    useKeyBinding(focusKeyBinding, handleFocusKeyBinding, !disabled);

    useEffect(() => {
        return () => {
            const {
                onChange,
                parseValue,
            } = unmountRefs.current!;

            if (DISABLE_UNMOUNTED_CHANGE || !modifiedRef.current || catchUnmountedChange === false || !onChange) {
                return;
            }
            const input = myInputValueRef.current || '';

            const v: T | null = parseValue(input);

            if (committedValueRef.current === v) {
                return;
            }

            debug('ONCHANGE', 'input=', input, 'committed=', committedValueRef.current, 'v=', v);

            committedValueRef.current = v;

            onChange(v, 'unmount');
        };
    }, []);

    /*
    useImperativeHandle(inputRef, () => ({
        focus: () => {
            myInputRef?.current?.focus();
        },
    }));
     */

    useLayoutEffect(() => {
        if (!autoSelect) {
            return;
        }
        const timerId = setTimeout(() => {
            myInputRef.current?.select?.();
        }, 200);

        return () => {
            clearTimeout(timerId);
        };
    }, [autoSelect]);

    useEffect(() => {
        setInputType(externalInputType);
    }, [setInputType, externalInputType]);


    const handleOnOpenDropdown = useCallback((event: React.MouseEvent<any> | React.KeyboardEvent<any>) => {
        event.preventDefault();

        if (disabled) {
            return;
        }

        handlePopoverVisibleChange(!popoverVisible);
    }, [
        disabled,
        popoverVisible,
        handlePopoverVisibleChange,
    ]);

    const handleOnKeyPress = useCallback((event: KeyboardEvent) => {
        const inputElement = event.target as HTMLInputElement;

        const input = inputElement.value;

        if (onKeyPress) {
            const argEvent: ArgInputKeyPressEvent = {
                input,
                keyboardEvent: event,
                selectionRange: {
                    start: inputElement.selectionStart!,
                    end: inputElement.selectionEnd!,
                },
            };

            onKeyPress(argEvent);

            if (event.defaultPrevented) {
                return;
            }
        }

        if (event.key === 'ArrowDown' || event.key === 'ContextMenu') {
            handleOnOpenDropdown(event);

            return;
        }

        if (event.key === 'Enter') {
            if (!input) {
                commitValue(null, 'enter', ENTER_DELAY_MS);

                return;
            }

            const v: T | null = parseValue(input);
            commitValue(v, 'enter', ENTER_DELAY_MS);

            return;
        }
    }, [onKeyPress, handleOnOpenDropdown, parseValue, commitValue]);

    const handleOnChange = useCallback((event: T | null | Event | ChangeEvent) => {
        let input;
        if ((event as Event).target === undefined) {
            input = event;
        } else {
            input = ((event as Event).target! as any).value;
        }

        modifiedRef.current = true;

        const filteredInput = filterInput ? filterInput(input) : input;

        if (shouldPerformChange?.(filteredInput) === false) {
            return;
        }

        setInternalInputValue(filteredInput);
        onInputChange?.(filteredInput);

        debug('handleOnChange', 'TRY DEBOUNCE=', debounce);
        if (debounce !== false) {
            const v: T | null = parseValue(filteredInput);

            commitValue(v, 'debounce', typeof (debounce) === 'number' ? debounce : DEBOUNCE_DELAY_MS);
        }
    }, [onInputChange, setInternalInputValue, filterInput, debounce, commitValue, parseValue, shouldPerformChange]);

    const handleOnClear = useCallback((event: React.MouseEvent) => {
        debug('handleOnClear', 'event=', event);

        event.preventDefault();
        event.stopPropagation();

        modifiedRef.current = true;

        setInternalInputValue('');
        onInputChange?.('');

        onClear?.();
        commitValue(null, 'clear', CLEAR_DELAY_MS);

        handlePopoverVisibleChange(false);

        if (!readOnly) {
            $yield(() => {
                handleFocusKeyBinding();
            });
        }
    }, [
        onInputChange,
        onClear,
        commitValue,
        readOnly,
        handleFocusKeyBinding,
        handlePopoverVisibleChange,
    ]);

    const handleShowTagPopover = useCallback(() => {
        setShowTagPopover(true);
    }, [setShowTagPopover]);

    const handleTogglePassword = useCallback(() => {
        setInputType((inputType) => (inputType === 'password' ? 'text' : externalInputType));
    }, [setInputType, externalInputType]);

    const handleTagClose = useCallback((tag: U) => {
        if (disabled || !onTagClose) {
            return;
        }

        onTagClose(tag);
    }, [disabled, onTagClose]);


    const handleTagPopover = useCallback((): ReactNode => {
        if (!tags || !tags.length) {
            return;
        }

        const _tags = tags.slice(maxTagShown - 1);

        return <div className={classNames('&-tagPopover')}>
            {_tags.map((tag: U) => {
                const key = computeItemKey(tag, getTagKey);
                const label = computeItemLabel(tag, getTagLabel);
                const cls = computeItemClassName(tag, getTagClassName);
                const icon = computeItemIcon(tag, getTagIcon);
                const backgroundColor = computeItemBackgroundColor(tag, getTagBackgroundColor);
                const tooltip = (tagToolip === undefined) ? computeItemTooltip(tag, getTagTooltip) : tagToolip;

                return (
                    <ArgTag
                        key={key}
                        size={tagSize || size}
                        label={label}
                        tooltip={tooltip}
                        closable={!tagReadOnly}
                        icon={icon}
                        backgroundColor={backgroundColor}
                        className={classNames('&-tagPopover-tag', cls)}
                        onClose={() => handleTagClose(tag)}
                        disabled={disabled}
                    />
                );
            })}
        </div>;
    }, [
        tags, maxTagShown, classNames, getTagKey, getTagLabel, getTagClassName, getTagIcon, getTagBackgroundColor, tagToolip,
        getTagTooltip, tagSize, size, tagReadOnly, handleTagClose, disabled,
    ]);

    const hasDropdown = (right === 'dropdown') && (popover || popoverVisible !== undefined);
    const hasLoading = progressMonitor?.isRunning || loading;

    let leftComponent = left;
    let clearableComponent: ReactNode;
    let togglePasswordComponent: ReactNode;
    let rightComponent = right;
    let stateComponent: ReactNode;

    const handleOnClearButtonMouseDown = useCallback(() => {
        if (!focused) {
            return;
        }

        shouldPreventBlurRef.current = true;
    }, [focused]);

    if ((clearable !== false && !readOnly) || clearable === true) {
        const name = 'icon-cross';
        clearableComponent =
            <button
                key='clear'
                tabIndex={-1}
                type='button'
                data-testid='clear-input'
                className={classNames('&-clear', '&-button', '&-right')}
                disabled={disabled}
                onClick={handleOnClear}
                onMouseDown={handleOnClearButtonMouseDown}
            >
                <ArgIcon className={classNames('&-right-icon')} name={name} data-testid={name} />
            </button>;
    }

    if (externalInputType === 'password') {
        const name = inputType === 'password' ? 'icon-eye-crossed' : 'icon-eye';

        togglePasswordComponent =
            <button
                key='toggle-password'
                tabIndex={-1}
                type='button'
                className={classNames('&-toggle-password', '&-button', '&-right')}
                onClick={handleTogglePassword}
            >
                <ArgIcon className={classNames('&-right-icon')} name={name} data-testid={name} />
            </button>;
    }

    if (left === 'magnifier') {
        leftComponent = <span key='magnify' className={classNames('&-magnifier', '&-left')}>
            <ArgIcon className={classNames('&-left-icon')} name='icon-magnifier' />
        </span>;
    } else if (typeof (left) === 'string' && left.length > 1) {
        leftComponent = <span key='left-icon' className={classNames('&-left-icon', '&-left')}>
            <ArgIcon className={classNames('&-left-icon')} name={left} />
        </span>;
    }
    if (hasDropdown) {
        rightComponent =
            <button
                key='dropdown'
                type='button'
                disabled={disabled}
                className={classNames('&-right', '&-button', '&-dropdown')}
                onClick={handleOnOpenDropdown}
                data-testid='dropdown'
            >
                <ArgIcon className={classNames('&-right-icon')} name='icon-triangle-down' />
            </button>;
    }
    if (hasLoading) {
        rightComponent =
            <ThreeDotsLoading key='loading' className={classNames('&-right', '&-loader')} />;
    }
    if (state) {
        let icon;
        switch (state) {
            case 'valid':
                icon = 'icon-valid';
                break;
            case 'caution':
                icon = 'icon-exclamation-solid';
                break;
            default:
                icon = 'icon-invalid';
                break;
        }

        stateComponent = <span key='state' className={classNames('&-state', '&-right', `&-state-${state}`)}>
            <ArgIcon className={classNames('&-right-icon')} name={icon} data-testid={icon} />
        </span>;
    }

    const _placeholder = computeText(intl, placeholder, messageValues);

    const tagsComponent = useMemo<ReactNode>(() => {
        if (!tags || !tags.length) {
            return null;
        }

        let moreTags = null;
        let _tags = tags;
        if (tags.length > maxTagShown) {
            _tags = tags.slice(0, maxTagShown - 1);

            moreTags = <ArgTag
                    key='more-tags'
                    size={tagSize || size}
                    popoverVisible={showTagPopover}
                    dropdown={true}
                    onClick={handleShowTagPopover}
                    data-testid='more-tags-dropdown'
                    popover={handleTagPopover}
                    onPopoverVisibleChange={setShowTagPopover}
                    popoverClassName={classNames('&-more-tags-popover', moreTagPopoverClassName)}
                    label={moreTagMessage || messages.moreTagCount}
                    messageValues={{ count: tags.length - maxTagShown + 1 }}
                    className={classNames('&-tag', '&-tags-item', '&-tags-item-more-tags', '&-tag-popover-trigger', 'all-opacity')}
                    disabled={disabled}
            />;
        }
        const tagsComponent = <>
            {_tags.map((tag) => {
                return (
                    <ArgTag
                            key={computeItemKey(tag, getTagKey)}
                            size={tagSize || size || 'medium'}
                            closable={!tagReadOnly}
                            className={classNames('&-tag', '&-tags-item', 'all-opacity', computeItemClassName(tag, getTagClassName))}
                            label={computeItemLabel(tag, getTagLabel)}
                            icon={computeItemIcon(tag, getTagIcon)}
                            backgroundColor={computeItemBackgroundColor(tag, getTagBackgroundColor)}
                            tooltip={(tagToolip === undefined) ? computeItemTooltip(tag, getTagTooltip) : tagToolip}
                            onClick={onTagClick && (() => onTagClick(tag))}
                            onClose={() => handleTagClose(tag)}
                            disabled={disabled}
                    />
                );
            })}
            {moreTags}
        </>;

        return tagsComponent;
    }, [
        tags, maxTagShown, tagSize, size, showTagPopover, handleShowTagPopover, handleTagPopover, classNames, moreTagPopoverClassName,
        moreTagMessage, disabled, getTagKey, tagReadOnly, getTagClassName, getTagLabel, getTagIcon, getTagBackgroundColor,
        tagToolip, getTagTooltip, onTagClick, handleTagClose,
    ]
    );

    const dndAction = useMemo<DndAction | null>(() => {
        if (!dndConfig) {
            return null;
        }

        return {
            dragInfos: (event) => {
                let allowDrop = false;
                if (isFunction(dndConfig.allowDrop)) {
                    allowDrop = dndConfig.allowDrop(event);
                } else if (dndConfig.allowDrop !== false) {
                    allowDrop = !!intersection(event.types, dndConfig.allowedDndTypes).length;
                }

                if (!allowDrop) {
                    return DND_NO_SUPPORT;
                }

                return {
                    supports: true,
                    hint: dndConfig.dropPlaceholder,
                };
            },
            onDrop: (dataTransfer) => {
                if (dndConfig.allowDrop === false) {
                    return;
                }
                for (const type of dndConfig.allowedDndTypes) {
                    const data = dataTransfer!.getData(type);
                    if (data) {
                        const item = JSON.parse(data);
                        dndConfig.onDrop(type, { item, id });
                    }
                }
            },
            dropEffect: 'copy',
        };
    }, [dndConfig, id]);

    const dataProps = useFilterDataProps(otherProps);

    const hasTags = !isEmpty(tags) && !isNil(tags);

    const cls = {
        disabled,
        focused,
        'read-only': readOnly,
        empty: !internalInputValue,
        'not-empty': !!internalInputValue,
        'state-valid': (state === 'valid'),
        'state-invalid': (state === 'invalid'),
        'state-caution': (state === 'caution'),
        'no-state': !state,
        [`size-${size}`]: true,
        [`type-${type}`]: true,
        'popover-visible': popoverVisible,
        'popover-hidden': !popoverVisible,
        'has-clear': clearable,
        'has-tags': hasTags,
        'has-dropdown': hasDropdown,
        'has-input-click': !!onInputClick,
    };

    let inputComponent: ReactNode;
    if (!renderInputComponent && readOnly) {
        if (internalInputValue) {
            inputComponent = <div
                key='input-readOnly'
                data-testid={dataTestId}
                onClick={onInputClick}
                onWheel={onWheel}
                onWheelCapture={onWheelCapture}
                className={classNames('&-input')}>
                {internalInputValue}
            </div>;
        } else if (_placeholder) {
            inputComponent = <div
                key='input-readOnly'
                data-testid={dataTestId}
                onClick={onInputClick}
                onWheel={onWheel}
                onWheelCapture={onWheelCapture}
                className={classNames('&-input', '&-input-placeholder')}>
                {_placeholder}
            </div>;
        }
    }

    if (!inputComponent && renderInputComponent) {
        inputComponent = renderInputComponent({
            id,
            ref: myInputRefCb,
            value,
            internalInputValue,
            onBlur: handleOnBlur,
            onFocus: handleOnFocus,
            onChange: handleOnChange,
            className: classNames('&-input'),
            onUnmount: handleInputOnUnmount,
            disabled,
            readOnly,
            maxLength,
            placeholder: _placeholder,
            autoComplete: htmlAutoComplete,
        });
    }

    if (!inputComponent) {
        const _htmlAutoComplete = (typeof (htmlAutoComplete) !== 'string') ? 'off' : htmlAutoComplete;

        inputComponent = <input
            key='input'
            id={id}
            maxLength={maxLength}
            readOnly={readOnly}
            type={inputType}
            data-testid={dataTestId}
            ref={myInputRefCb}
            disabled={disabled}
            value={internalInputValue}
            onWheel={onWheel}
            onWheelCapture={onWheelCapture}
            onChange={handleOnChange}
            onClick={onInputClick}
            onFocus={handleOnFocus}
            onKeyPress={handleOnKeyPress}
            onKeyDown={onKeyDown}
            onBlur={handleOnBlur}
            placeholder={_placeholder}
            autoComplete={_htmlAutoComplete}
            className={classNames('&-input')}
            onCopy={disableCopy ? preventDefault : undefined}
            onCut={disableCut ? preventDefault : undefined}
            onPaste={disablePaste ? preventDefault : undefined}
        />;
    }

    let component: ReactNode;

    if (dndConfig?.droppedItem) {
        component = dndConfig.renderDroppedItem?.(dndConfig.droppedItem);
    } else {
        component = <>
            <div className={classNames('&-left-container')}>
                {leftComponent}
            </div>

            {hasTags && (
                <div className={classNames('&-tags')}>
                    {tagsComponent}
                </div>
            )}

            {inputComponent}

            <div className={classNames('&-right-container')}>
                {clearableComponent}
                {stateComponent}
                {rightComponent}
                {togglePasswordComponent}
            </div>
        </>;
    }

    const _component = component;
    if (dndAction) {
        component = (
            <Droppable
                actions={dndAction}
                className={classNames('&', className, cls)}
                containerRef={inputContainerRef}
                onClick={onInputClick}
            >
                {(provided, snapshot) => {
                    if (snapshot?.supportsDragging && snapshot?.draggingFromThisWith) {
                        const draggingPlaceholder = renderText(dndConfig?.dropPlaceholder);

                        return <div
                            className={classNames('&-drop-zone')}
                            {...provided}
                        >
                            {/*...dataProps*/}
                            {draggingPlaceholder}
                        </div>;
                    }

                    return _component;
                }}
            </Droppable>);
    } else {
        component = (
            <div
                className={classNames('&', className, cls)}
                ref={inputContainerRef}
                onClick={onInputClick}
                onFocus={handleOnFocus}
            >
                {/*...dataProps*/}
                {component}
            </div>);
    }

    if (tooltip && !popoverVisible && !isDragging) {
        component =
            <ArgTooltip2
                key='tooltip'
                className={classNames('&-tooltip', tooltipClassName)}
                title={tooltip}
                messageValues={messageValues}
                data-testid='tooltip'
                placement={tooltipPlacement}
                ref={inputContainerRef}
            >
                {component}
            </ArgTooltip2>;
    }

    // Disable popover when popover is a dropdown and the input is disabled
    const disablePopover = disabled && right === 'dropdown';

    if (hasPopover && !disablePopover) {
        component = (
            <ArgPopover
                key='popover'
                {...getPopoverProps()}
                popoverRef={popoverRef}
                offset={props.popoverOffset || DEFAULT_POPOVER_OFFSET}
            >
                {component}
            </ArgPopover>
        );
    }

    if (hidden) {
        return null;
    }

    return component;
}
