import { InfoTitle } from '../model/vertex';

export function getTitle(nodeTitle?: InfoTitle): string {
    let retTitle = '?';
    if (nodeTitle) {
        retTitle = nodeTitle.value?.toString() || retTitle;
    }

    return retTitle;
}
