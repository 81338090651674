import { defineMessages } from 'react-intl';

export const briefTemplateMessages = defineMessages({
    title: {
        defaultMessage: 'Templates',
        id: 'settings.templates.brief-template-messages.title',
    },
    missingTemplateNameErrorMessage: {
        defaultMessage: 'The template name must be at least 3 characters.',
        id: 'settings.templates.brief-template-messages.missingTemplateNameErrorMessage',
    },
    libraryTitle: {
        defaultMessage: 'Templates library',
        id: 'settings.templates.brief-template-messages.libraryTitle',
    },
    templateNamePlaceholder: {
        defaultMessage: 'Case Report, Summary, etc...',
        id: 'settings.templates.brief-template-messages.namePlaceholder',
    },
    templateName: {
        defaultMessage: 'Brief template name',
        id: 'settings.templates.brief-template-messages.name',
    },
    emptyMessage: {
        defaultMessage: 'No Templates',
        id: 'settings.templates.brief-template-messages.emptyMessage',
    },
});
