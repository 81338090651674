import { useCallback } from 'react';
import { defineMessages } from 'react-intl';

import { ArgButton, ProgressMonitor, SubProgressMonitor, getGlobalNotifications, useArgModalContext, useClassNames } from 'src/components/basic';
import { ProceoGroupsConnector } from 'src/proceo/utils/connectors/proceo-groups-connector';
import { HierarchyGroupModal } from './hierarchy-group-modal';

const HIERARCHY_CREATE_MODAL_ID = 'HIERARCHY_BTN_CREATE_MODAL_ID';

const messages = defineMessages({
    newGroup: {
        id: 'proceo.hierarchy.btn.newGroup',
        defaultMessage: 'Create a group',
    },

    //NEW SUBFOLDER ERROR
    createGroupHierarchyElementError: {
        id: 'proceo.hierarchy.btn.CreateGroupHierarchyElementError',
        defaultMessage: 'Failed to create the group',
    },

    createGroupMonitor: {
        id: 'proceo.hierarchy.btn.monitor.CreateGroupHierarchyElementMonitor',
        defaultMessage: 'Create group in hierarchy',
    },
});

export interface HeriarchyNewGroupBtnProps {
    onGroupCreated: () => void
}
export function HeriarchyNewGroupBtn(props: HeriarchyNewGroupBtnProps) {
    const {
        onGroupCreated,
    } = props;

    const modalContext = useArgModalContext();
    const notifications = getGlobalNotifications();
    const classNames = useClassNames('proceo-heriarchy-overview');

    const handleGroupCreated = useCallback(() => {
        onGroupCreated();
    }, [onGroupCreated]);


    const createGroup = useCallback(() => {
        const createGroupAsync = async (progressMonitor: ProgressMonitor, name: string) => {
            progressMonitor.setTaskName(messages.createGroupMonitor);
            try {
                const sub1 = new SubProgressMonitor(progressMonitor, 1);
                await ProceoGroupsConnector.getInstance().createFolder(name, undefined, undefined, undefined, sub1);

                return handleGroupCreated();
            } catch (error) {
                if (progressMonitor.isCancelled) {
                    throw error;
                }

                notifications.snackError({ message: messages.createGroupHierarchyElementError }, error as Error);
            }
        };
        modalContext.open(HIERARCHY_CREATE_MODAL_ID, (
            <HierarchyGroupModal
                onClose={() => modalContext.close(HIERARCHY_CREATE_MODAL_ID)}
                onSubmit={createGroupAsync}
            />
        ));
    }, [modalContext, notifications, handleGroupCreated]);

    return (
        <ArgButton
            className={classNames('&-new-group-btn')}
            icon='icon-users'
            onClick={() => createGroup()}
            label={messages.newGroup}
        />
    );
}
