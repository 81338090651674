import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
    ArgButton,
    ArgDragAndDropUploader,
    ArgInputSearch,
    ArgMessageRenderer,
    ArgModalContainerContext,
    ArgTable2,
    ArgTable2Column,
    ArgTooltip2,
    ArgUploaderButton,
    DEEP_EQUALS,
    ProgressMonitor,
    renderText,
    SelectionProvider,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
    useMemoAsync,
    useSelectionList,
} from 'src/components/basic';
import { PageHeader } from 'src/settings/common-components/page-header';
import WebhookConnector from 'src/settings/connectors/webhook-connector';
import { DetailedWebHook } from 'src/settings/models/detailed-webhooks';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { dateSorter, stringSorter } from 'src/utils/sorter';
import { DateByUser } from 'src/components/common/date-by-user';
import { CreateWebhookModal } from '../../webhooks/components/create-webhook-modal/create-webhook-modal';
import { mapDetailedWebhook } from '../../connectors/mappers';
import { ExternalComponent, ExternalComponentStatus, ExternalComponentType } from '../../models/external-component';
import { WebhookActionsMenu } from '../../webhooks/webhook-actions-menu';
import {
    WebhookActionsButtonMenu,
} from '../../webhooks/components/webhooks-actions-button-menu/webhooks-actions-button-menu';
import { WebhookInformationPanel } from '../../webhooks/webhook-information-panel';
import {
    CreateExternalComponentModal,
} from '../../external-component/components/create-external-component-modal/create-external-component-modal';
import {
    ExternalComponentActionsMenu,
} from '../../external-component/components/external-component-actions-menu/external-component-actions-menu';
import {
    ExternalComponentActionsButtonMenu,
} from '../../external-component/components/external-component-actions-button-menu/external-component-actions-button-menu';
import {
    ExternalComponentInformationPanel,
} from '../../external-component/components/external-component-information-panel/external-component-information-panel';
import { useHasAnyPermissions, useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';
import { PreparationPermissions } from '../../../preparation/permissions/permissions';
import { SettingsConnector } from '../../connectors/settings-connector';

import './extensions-view.less';

const SETTINGS_WEBHOOKS_TABLE_SELECTION_SOURCE = 'settings-webhooks-table';
const SETTINGS_EXTERNAL_COMPONENTS_TABLE_SELECTION_SOURCE = 'settings-external-components-table';

const messages = defineMessages({
    name: {
        id: 'settings.extensions-view.name',
        defaultMessage: 'Name',
    },
    key: {
        id: 'settings.extensions-view.key',
        defaultMessage: 'Key',
    },
    url: {
        id: 'settings.extensions-view.url',
        defaultMessage: 'Access URL',
    },
    category: {
        id: 'settings.extensions-view.category',
        defaultMessage: 'Category',
    },
    type: {
        id: 'settings.extensions-view.type',
        defaultMessage: 'Type',
    },
    description: {
        id: 'settings.extensions-view.description',
        defaultMessage: 'Description',
    },
    new: {
        id: 'settings.extensions-view.new',
        defaultMessage: 'New',
    },
    searchPlaceholder: {
        id: 'settings.extensions-view.searchPlaceholder',
        defaultMessage: 'Search',
    },
    active: {
        id: 'settings.extensions-view.active',
        defaultMessage: 'Active',
    },
    inactive: {
        id: 'settings.extensions-view.inactive',
        defaultMessage: 'Inactive',
    },
    error: {
        id: 'settings.extensions-view.error',
        defaultMessage: 'Error',
    },
    status: {
        id: 'settings.extensions-view.status',
        defaultMessage: 'Status',
    },
    modification: {
        id: 'settings.extensions-view.modification',
        defaultMessage: 'Modification',
    },
    webhooksTitle: {
        id: 'settings.extensions-view.webhooksTitle',
        defaultMessage: 'Webhooks',
    },
    externalComponentsTitle: {
        id: 'settings.extensions-view.externalComponentsTitle',
        defaultMessage: 'External components',
    },
    emptyWebhooks: {
        id: 'settings.extensions-view.emptyWebhooks',
        defaultMessage: 'No webhooks',
    },
    fetchWebhooks: {
        id: 'settings.extensions-view.fetchWebhooks',
        defaultMessage: 'Fetching webhooks...',
    },
    import: {
        id: 'settings.extensions-view.import',
        defaultMessage: 'Import',
    },
    importError: {
        id: 'settings.extensions-view.importError',
        defaultMessage: 'An error occurred while importing the webhook',
    },
    emptyExternalComponents: {
        id: 'settings.extensions-view.emptyExternalComponents',
        defaultMessage: 'No external components',
    },
    connector: {
        id: 'settings.extensions-view.connector',
        defaultMessage: 'Connector',
    },
    module: {
        id: 'settings.extensions-view.module',
        defaultMessage: 'Module',
    },
    injector: {
        id: 'settings.extensions-view.injector',
        defaultMessage: 'Injector',
    },
    invalid: {
        id: 'settings.extensions-view.invalid',
        defaultMessage: 'Invalid',
    },
    working: {
        id: 'settings.extensions-view.working',
        defaultMessage: 'Working',
    },
    invalidDefinitionError: {
        id: 'settings.extensions-view.invalidDefinitionError',
        defaultMessage: 'Invalid definition error',
    },
    connectionError: {
        id: 'settings.extensions-view.connectionError',
        defaultMessage: 'Connection error',
    },
});

export const REMOTE_COMPONENTS_TYPE: Record<ExternalComponentType, MessageDescriptor> = {
    [ExternalComponentType.Connector]: messages.connector,
    [ExternalComponentType.Module]: messages.module,
    [ExternalComponentType.Injector]: messages.injector,
    [ExternalComponentType.Invalid]: messages.invalid,
};

export const REMOTE_COMPONENTS_STATUS: Record<ExternalComponentStatus, MessageDescriptor> = {
    [ExternalComponentStatus.Working]: messages.working,
    [ExternalComponentStatus.InvalidDefinitionError]: messages.invalidDefinitionError,
    [ExternalComponentStatus.ConnectionError]: messages.connectionError,
};

export enum PageHeadersButtons {
    INFO = 'info',
}

export function ExtensionsView() {
    const intl = useIntl();
    const classNames = useClassNames('settings-extensions-view');
    const notifications = useArgNotifications();

    const hasWebhooksAccess = useHasAnyPermissions<SettingsPermissions>('admin.webhook.access');
    const canEditWebhooks = useHasAnyPermissions<SettingsPermissions>('admin.webhook.edition');

    const canEditExternalComponents = useHasPermission<PreparationPermissions>('preparation.remote.component.edition');
    const canExportExternalComponents = useHasPermission<PreparationPermissions>('preparation.remote.component.export');

    const [buttonSelected, setButtonSelected] = useState<string>();
    const [webhooksStateId, setWebhooksStateId] = useState<number>(0);
    const [externalComponentsStateId, setExternalComponentsStateId] = useState<number>(0);
    const [search, onSearchChange] = useState<string>();

    const modalContainer = useContext(ArgModalContainerContext);

    const { extensionsScope } = useParams<{ extensionsScope: string }>();

    const handleRefresh = useCallback(() => {
        if (extensionsScope === 'webhooks') {
            setWebhooksStateId((webhooksStateId) => webhooksStateId + 1);
        }
        if (extensionsScope === 'externalComponents') {
            setExternalComponentsStateId((externalComponentsStateId) => externalComponentsStateId + 1);
        }
    }, [extensionsScope]);

    const handleCreateExtension = useCallback(() => {
        if (extensionsScope === 'webhooks') {
            modalContainer.open('settings-webhook-create', <CreateWebhookModal
                onClose={() => modalContainer.close('settings-webhook-create')}
                onSuccess={handleRefresh}
            />);
        }
        if (extensionsScope === 'externalComponents') {
            modalContainer.open('settings-external-component-create', <CreateExternalComponentModal
                onClose={() => modalContainer.close('settings-external-component-create')}
                onSuccess={handleRefresh}
            />);
        }
    }, [handleRefresh, modalContainer, extensionsScope]);

    const handleRowDoubleClick = useCallback((webhook: DetailedWebHook) => {
        if (!canEditWebhooks) {
            return;
        }

        modalContainer.open('settings-webhook-edit', (
            <CreateWebhookModal
                webhook={webhook}
                onClose={() => modalContainer.close('settings-webhook-edit')}
                onSuccess={handleRefresh}
            />
        ));
    }, [canEditWebhooks, handleRefresh, modalContainer]);

    const [importExtension] = useCallbackAsync(async (progressMonitor: ProgressMonitor, blob: Blob) => {
        if (extensionsScope === 'webhooks') {
            let rawExtension;
            try {
                const fileData = await blob.text();
                rawExtension = JSON.parse(fileData);
            } catch (e) {
                notifications.snackError({ message: messages.importError }, e as Error);
            }
            if (!rawExtension) {
                return;
            }
            try {
                await WebhookConnector.getInstance().importWebhook(rawExtension, progressMonitor);
                handleRefresh();
            } catch (e) {
                notifications.snackError({ message: messages.importError }, e as Error);
                modalContainer.open('settings-webhook-create', <CreateWebhookModal
                    webhook={mapDetailedWebhook(rawExtension)}
                    onClose={() => modalContainer.close('settings-webhook-create')}
                    onSuccess={handleRefresh}
                />);
            }
        }
        if (extensionsScope === 'externalComponents') {
            try {
                await SettingsConnector.getInstance().importExternalComponent(blob, progressMonitor);
                handleRefresh();
            } catch (e) {
                notifications.snackError({ message: messages.importError }, e as Error);
            }
        }
    }, [handleRefresh, modalContainer, extensionsScope]);

    const [webhooks = [], webhooksProgressMonitor] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        if (!canEditWebhooks && !hasWebhooksAccess) {
            return [];
        }
        const ret = await WebhookConnector.getInstance().getWebhooks(progressMonitor);

        return ret;
    }, [canEditWebhooks, hasWebhooksAccess, webhooksStateId], messages.fetchWebhooks, 1, DEEP_EQUALS);

    const webhooksSelectionProvider = useMemo(
        () => new SelectionProvider<DetailedWebHook>('settings-webhook-table', (webhook) => webhook.id),
        []
    );

    const [externalComponents = [], externalComponentsProgressMonitor] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        const ret = await SettingsConnector.getInstance().getExternalComponents(progressMonitor);

        return ret;
    }, [externalComponentsStateId], messages.fetchWebhooks, 1, DEEP_EQUALS);

    const externalComponentsSelectionProvider = useMemo(
        () => new SelectionProvider<ExternalComponent>('settings-external-component-table', (externalComponent) => externalComponent.key),
        []
    );

    const [selectedExternalComponentsKeys] = useSelectionList(externalComponentsSelectionProvider);

    const selectedExternalComponents = useMemo(
        () => externalComponents.filter((externalComponent) => selectedExternalComponentsKeys.includes(externalComponent.key)),
        [selectedExternalComponentsKeys, externalComponents]
    );

    const [selectedWebhookIds] = useSelectionList(webhooksSelectionProvider);

    const selectedWebhooks = useMemo(
        () => webhooks.filter((webhook) => selectedWebhookIds.includes(webhook.id)),
        [selectedWebhookIds, webhooks]
    );

    useEffect(() => {
        if ((extensionsScope === 'webhooks' && selectedWebhookIds.length !== 0) || (extensionsScope === 'externalComponents' && selectedExternalComponentsKeys.length !== 0) && buttonSelected === undefined) {
            setButtonSelected(PageHeadersButtons.INFO);
        }
    }, [buttonSelected, selectedWebhookIds, extensionsScope, selectedExternalComponentsKeys]);

    const columns = useMemo<ArgTable2Column<DetailedWebHook>[]>(() => {
        const columns: ArgTable2Column<DetailedWebHook>[] = [
            {
                key: 'name',
                title: messages.name,
                dataIndex: 'name',
                render: function display(name) {
                    return <span>{name}</span>;
                },
            },
            {
                key: 'description',
                title: messages.description,
                dataIndex: 'description',
                ellipsis: true,
                render: function display(description) {
                    return (
                        <div className={classNames('&-table-description')}>
                            <ArgTooltip2 title={description}>
                                {description?.length ? description : '-'}
                            </ArgTooltip2>
                        </div>
                    );
                },
                width: '40%',
            },
            {
                key: 'status',
                title: messages.status,
                dataIndex: 'isEnabled',
                render: function display(status, webhook) {
                    if (webhook.emissionError) {
                        return (
                            <div className={classNames('&-error-tag')}>
                                {intl.formatMessage(messages.error)}
                            </div>
                        );
                    }

                    return (
                        <span>
                            {status
                                ? intl.formatMessage(messages.active)
                                : intl.formatMessage(messages.inactive)}
                        </span>
                    );
                },
            },
            {
                key: 'lastUpdatedDate',
                title: messages.modification,
                sorter: (a, b) => dateSorter<DetailedWebHook>(a, b, (item) => item.lastUpdatedDate),
                dataIndex: 'lastUpdatedDate',
                ellipsis: true,
                render: function LastUpdate(date: Date, webhook: DetailedWebHook) {
                    return (
                        <DateByUser
                            date={date}
                            relative={true}
                            user={webhook.lastUpdatedBy}
                        />
                    );
                },
                defaultSortOrder: 'descend',
            },
        ];

        if (canEditWebhooks) {
            columns.push(
                {
                    key: 'actions',
                    title: ' ',
                    dataIndex: 'actions',
                    width: 50,
                    render: function display(data: string, webhook: DetailedWebHook) {
                        return <WebhookActionsMenu
                            webhook={webhook}
                            className={classNames('&-action-button')}
                            onActionSuccess={handleRefresh}
                        />;
                    },
                }
            );
        }

        return columns;
    }, [canEditWebhooks, classNames, handleRefresh, intl]);

    const externalComponentsColumns = useMemo<ArgTable2Column<ExternalComponent>[]>(
        () => {
            const columns: ArgTable2Column<ExternalComponent>[] = [
                {
                    key: 'name',
                    title: messages.name,
                    dataIndex: 'name',
                    ellipsis: true,
                    sorter: (a, b) => {
                        return stringSorter<ExternalComponent>(a, b, item => item.name);
                    },
                },
                {
                    key: 'key',
                    title: messages.key,
                    dataIndex: 'key',
                    ellipsis: true,
                    sorter: (a, b) => {
                        return stringSorter<ExternalComponent>(a, b, item => item.key);
                    },
                },
                {
                    key: 'type',
                    title: messages.type,
                    dataIndex: 'type',
                    ellipsis: true,
                    render: (type: ExternalComponentType) => {
                        return <ArgMessageRenderer message={REMOTE_COMPONENTS_TYPE[type]} />;
                    },
                },
                {
                    key: 'category',
                    title: messages.category,
                    dataIndex: 'category',
                    ellipsis: true,
                    sorter: (a, b) => {
                        return stringSorter<ExternalComponent>(a, b, item => item.category);
                    },
                    render: (category: string) => {
                        return <ArgTooltip2 title={category}>{category}</ArgTooltip2>;
                    },
                },
                {
                    key: 'getDefinitionUrl',
                    title: messages.url,
                    dataIndex: 'getDefinitionUrl',
                    ellipsis: true,
                    sorter: (a, b) => {
                        return stringSorter<ExternalComponent>(a, b, item => item.getDefinitionUrl);
                    },
                    render: (url: string) => {
                        return <ArgTooltip2 title={url}>{url}</ArgTooltip2>;
                    },
                },
                {
                    key: 'status',
                    title: messages.status,
                    dataIndex: 'status',
                    ellipsis: true,
                    sorter: (a, b) => {
                        return stringSorter<ExternalComponent>(a, b, item => item.status);
                    },
                    render: (status: ExternalComponentStatus) => {
                        const title = REMOTE_COMPONENTS_STATUS[status];

                        return <ArgTooltip2 title={title}>{renderText(title)}</ArgTooltip2>;
                    },
                },
            ];

            if (canEditExternalComponents) {
                columns.push({
                    key: 'actions',
                    title: ' ',
                    dataIndex: 'actions',
                    width: 50,
                    render: function display(data: string, externalComponent: ExternalComponent) {
                        return (
                            <ExternalComponentActionsMenu
                                externalComponent={externalComponent}
                                className={classNames('&-action-button')}
                                onActionSuccess={handleRefresh}
                            />
                        );
                    },
                });
            }

            return columns;
        }, [canEditExternalComponents, classNames, handleRefresh]);

    const toolbar = useMemo(() => {
        return (
            <div className={classNames('&-toolbar')}>
                <div className={classNames('&-toolbar-left')}>
                    <ArgInputSearch
                        onInputChange={onSearchChange}
                        placeholder={messages.searchPlaceholder}
                        className={classNames('&-toolbar-search')}
                    />
                    {extensionsScope === 'webhooks' && canEditWebhooks && (
                        <WebhookActionsButtonMenu
                            selectedWebhooks={selectedWebhooks}
                            onActionSuccess={handleRefresh}
                        />
                    )}
                    {extensionsScope === 'externalComponents' && (canEditExternalComponents || canExportExternalComponents) &&
                        <ExternalComponentActionsButtonMenu
                            selectedExternalComponents={selectedExternalComponents}
                            onActionSuccess={handleRefresh}
                        />}
                </div>
                {(
                    (extensionsScope === 'externalComponents' && canEditExternalComponents) ||
                        (extensionsScope === 'webhooks' && canEditWebhooks)
                ) &&
                    (
                        <div className={classNames('&-toolbar-right')}>
                            <ArgUploaderButton
                                type='ghost'
                                size='medium'
                                icon='icon-download'
                                className={classNames('&-toolbar-export-button')}
                                label={messages.import}
                                acceptedFiles={extensionsScope === 'webhooks' ? '.json' : '.zip'}
                                method={importExtension}
                            />
                            <ArgButton
                                size='medium'
                                type='primary'
                                icon='icon-plus'
                                className='toolbar-item'
                                label={messages.new}
                                onClick={handleCreateExtension}
                            />
                        </div>
                    )
                }
            </div>
        );
    }, [classNames, extensionsScope, canEditWebhooks, selectedWebhooks, handleRefresh, canEditExternalComponents, canExportExternalComponents, selectedExternalComponents, importExtension, handleCreateExtension]);

    const renderWebhooks = useCallback(() => {
        let table: ReactNode = null;
        if (webhooksProgressMonitor?.isRunning) {
            table = (
                <div className='arg-layout'>
                    <LoadingPane
                        progressMonitor={webhooksProgressMonitor}
                        className={classNames('&-pane')}
                    />
                </div>
            );
        } else if (webhooks.length === 0) {
            table = (
                <EmptyPane
                    message={messages.emptyWebhooks}
                    className={classNames('&-pane')}
                    size='medium'
                />
            );
        } else {
            table = (
                <ArgTable2<DetailedWebHook>
                    className={classNames('&-table')}
                    columns={columns}
                    rowHeight={45}
                    dataSource={webhooks}
                    search={search}
                    selectionProvider={webhooksSelectionProvider}
                    onRowDoubleClick={handleRowDoubleClick}
                    selectionSource={SETTINGS_WEBHOOKS_TABLE_SELECTION_SOURCE}
                />
            );
        }

        return (
            <React.Fragment>
                {toolbar}
                <div className={classNames('&-table-container')}>
                    {table}
                </div>
            </React.Fragment>
        );
    }, [classNames, toolbar, columns, handleRowDoubleClick, search, webhooks, webhooksProgressMonitor, webhooksSelectionProvider]);

    const renderExternalComponents = useCallback(() => {
        let table: ReactNode = null;
        if (externalComponentsProgressMonitor?.isRunning) {
            table = (
                <div className='arg-layout'>
                    <LoadingPane
                        progressMonitor={externalComponentsProgressMonitor}
                        className={classNames('&-pane')}
                    />
                </div>
            );
        } else if (externalComponents.length === 0) {
            table = (
                <EmptyPane
                    message={messages.emptyExternalComponents}
                    className={classNames('&-pane')}
                    size='medium'
                />
            );
        } else {
            table = (
                <ArgTable2<ExternalComponent>
                    className={classNames('&-table')}
                    columns={externalComponentsColumns}
                    rowHeight={45}
                    dataSource={externalComponents}
                    search={search}
                    selectionProvider={externalComponentsSelectionProvider}
                    selectionSource={SETTINGS_EXTERNAL_COMPONENTS_TABLE_SELECTION_SOURCE}
                />
            );
        }

        return (
            <React.Fragment>
                {toolbar}
                <div className={classNames('&-table-container')}>
                    {table}
                </div>
            </React.Fragment>
        );
    }, [classNames, externalComponents, externalComponentsColumns, search, toolbar, externalComponentsSelectionProvider, externalComponentsProgressMonitor]);

    return (
        <ArgDragAndDropUploader
            method={importExtension}
            className={classNames('& &-wrapper')}
        >
            <PageHeader
                title={extensionsScope === 'webhooks' ? messages.webhooksTitle : messages.externalComponentsTitle}
                selectedItem={buttonSelected || ''}
                setSelectedItem={setButtonSelected}
                buttons={[{ key: PageHeadersButtons.INFO, icon: 'icon-information-outline' }]}
            />
            <div className={classNames('&-content')}>
                <div className={classNames('&-main-view')}>
                    {extensionsScope === 'externalComponents' && (
                        <div>
                            {renderExternalComponents()}
                        </div>
                    )}
                    {extensionsScope === 'webhooks' && (
                        <div>
                            {renderWebhooks()}
                        </div>
                    )}
                </div>
                {buttonSelected === PageHeadersButtons.INFO && (
                    <div className={classNames('&-webhook-panel')}>
                        {extensionsScope === 'webhooks' &&
                            <WebhookInformationPanel
                                selectedWebhooks={selectedWebhooks}
                                onActionSuccess={handleRefresh}
                            />
                        }
                        {extensionsScope === 'externalComponents' &&
                            <ExternalComponentInformationPanel
                                selectedExternalComponents={selectedExternalComponents}
                                onActionSuccess={handleRefresh}
                            />
                        }
                    </div>
                )}
            </div>
        </ArgDragAndDropUploader>
    );
}
