import { ArgCheckbox, ArgTable2, ArgUserId } from 'src/components/basic';
import { User } from 'src/model/user';

export interface UsersListProps {
    users: User[];
    search: string;
    userIdsSelected: ArgUserId[];
    handleCheckboxSelection: (checked: boolean, id: string) => void;
}

export const UsersList: React.FunctionComponent<UsersListProps> = ({
    users,
    search,
    userIdsSelected,
    handleCheckboxSelection,
}) => {
    const columns = [
        {
            dataIndex: 'id',
            key: 'check',
            width: 40,
            render: function header(id: string) {
                return (
                    <ArgCheckbox
                        size='node'
                        value={userIdsSelected.includes(id)}
                        onChange={(checked) => handleCheckboxSelection(checked, id)}
                    />
                );
            },
        },
        { key: 'Name', dataIndex: 'fullName' },
    ];

    return (
        <div>
            <ArgTable2 columns={columns} dataSource={users} search={search} />
        </div>
    );
};
