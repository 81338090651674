import { ProgressMonitor, ProgressMonitorOptions, SubProgressMonitor } from './progress-monitor';

/**
 * Web Socket progress Monitor  (controlled by server)
 */
export class WSProgressMonitor extends SubProgressMonitor {
    constructor(parent: ProgressMonitor, taskCount: number, options?: ProgressMonitorOptions) {
        super(parent, taskCount, options);
    }

    processMessage(messageKey: string, parameter: any) {
    }

    onProcessFinished() {
    }

    onProcessFailed() {
    }
}
