import { useMemo, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { PageHeader } from 'src/settings/common-components/page-header';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';
import { ArgIcon, ArgTabsSubLevel, ProgressMonitor, useClassNames, useMemoAsync } from 'src/components/basic';
import { PolicyRulesView } from './policy-rules-view';
import { PolicyApplicationConditionsView } from './policy-application-conditions-view';
import { computePoliciesUrl } from 'src/settings/utils/compute-url';

import './policy-details-view.less';

const messages = defineMessages({
    rules: {
        id: 'settings.policies.policy.rules',
        defaultMessage: 'Rules',
    },
    conditions: {
        id: 'settings.policies.policy.conditions',
        defaultMessage: 'Application Conditions',
    },
    security: {
        id: 'settings.policies.policy.security',
        defaultMessage: 'Security',
    },
});

type PolicyParams = {
    policyId: string;
    ontologyId: string;
    universeId: string;
}

export const PolicyDetailsView = () => {
    const { ontologyId, universeId, policyId } = useParams<PolicyParams>();
    const [activeTab, setActiveTab] = useState<string>('rules');

    const classNames = useClassNames('policy-entry-view');

    const [policy, loadingPolicy] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        if (!policyId) {
            return;
        }
        const ret = explorationSettingsConnector.getPolicy(policyId);

        return ret;
    }, [policyId]);

    const tabs = useMemo(() => {
        return [
            {
                key: 'rules',
                title: <FormattedMessage {...messages.rules} />,
            },
            {
                key: 'application-conditions',
                title: <FormattedMessage {...messages.conditions} />,
            },
            // TODO: bring back with security functionality {
            //     key: 'security',
            //     title: <FormattedMessage {...messages.security} />,
            // },
        ];
    }, []);

    const title = (
        <h1 className={classNames('&-title')}>
            {ontologyId && universeId && (
                <Link
                    className={classNames('&-back-link')}
                    to={computePoliciesUrl(ontologyId, universeId)}
                >
                    <ArgIcon name='icon-arrow-thin-left' size='medium' />
                </Link>
            )}
            {policy?.name}
        </h1>
    );

    return (
        <div className={classNames('&')}>
            <PageHeader
                title={title}
            />
            <div className={classNames('&-page-content')}>
                <ArgTabsSubLevel
                    tabs={tabs}
                    activeTabKey={activeTab}
                    onChange={(tabKey) => tabKey && setActiveTab(tabKey)}
                />
                {activeTab === 'rules' && <PolicyRulesView />}
                {activeTab === 'application-conditions' && <PolicyApplicationConditionsView />}
            </div>
        </div>
    );
};
