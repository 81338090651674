import { ArgIcon, ArgInputText, useClassNames } from 'src/components/basic';
import { FormBaseProperty } from 'src/components/common/forms/components/form-base-property';
import { getPropertyIconClass } from 'src/exploration/utils/universe.utils';
import { OntologyPropertyBaseType } from 'src/settings/universes/ontology/types';

import './ontology-form-property.less';

export interface OntologyFormPropertyProps {
    propertyType: OntologyPropertyBaseType;
    isMandatory?: boolean;
}

export function OntologyFormProperty(props: OntologyFormPropertyProps) {
    const { propertyType, isMandatory } = props;

    const classNames = useClassNames('ontology-form-property');

    const icon = getPropertyIconClass(propertyType);

    return (
        <FormBaseProperty isMandatory={isMandatory}>
            <ArgInputText
                className={classNames('&')}
                right={<ArgIcon name={icon} />}
            />
        </FormBaseProperty>
    );
}
