import { createDragDropSupport, createTypePlugin } from '../type-editing-plugin';

export const DASHBOARD_VIEW_DND_TYPE = 'application/arg-dashboard';

export const DashboardEditing = createTypePlugin('argonos-dashboard',
    ['dashboardId', 'caseId', 'dashboardName', 'dashboardWidth', 'dashboardHeight', 'width'],
    {
        defineClipboardInputOutput: createDragDropSupport(DASHBOARD_VIEW_DND_TYPE), // Remove it for CKEditor build
        containerElement: 'figure',
        containerElementClassName: 'image',
        isBlock: true,
        customProperty: 'dashboard',
    });
