import { defineMessages } from 'react-intl';

import { defineKeyBindings, KeyBindingScopeDescriptor } from '../../components/basic/keybindings/keybinding';
import { SCOPE as CASE_SCOPE } from './case';

const messages = defineMessages({
    vertexEditorScope: {
        id: 'exploration.keybindings.VertexEditorScope',
        defaultMessage: 'Object Editor',
    },
    relationShipsPane: {
        id: 'exploration.keybindings.RelationShipsPane',
        defaultMessage: 'Toggle relation relationships panel',
    },
    attachmentsPane: {
        id: 'exploration.keybindings.AttachmentsPane',
        defaultMessage: 'Toggle attachments panel',
    },
    historyPane: {
        id: 'exploration.keybindings.HistoryPane',
        defaultMessage: 'Toggle history panel',
    },
    uploadAttachment: {
        id: 'exploration.keybindings.UploadAttachment',
        defaultMessage: 'Upload an attachment',
    },
    deleteVertex: {
        id: 'exploration.keybindings.DeleteVertex',
        defaultMessage: 'Delete object',
    },
    editVertex: {
        id: 'exploration.keybindings.EditVertex',
        defaultMessage: 'Edit object',
    },
    searchInProperties: {
        id: 'exploration.keybindings.SearchInProperties',
        defaultMessage: 'Search in properties',
    },
    save: {
        id: 'exploration.keybindings.Save',
        defaultMessage: 'Save',
    },
});

export const SCOPE: KeyBindingScopeDescriptor = {
    id: 'exploration.VertexEditor',
    name: messages.vertexEditorScope,
    extends: CASE_SCOPE,
};

export const KEY_BINDINGS = defineKeyBindings({

    relationShipsPane: {
        id: 'exploration.vertexEditor.RelationShipsPane',
        scope: SCOPE,
        name: messages.relationShipsPane,
        defaultKeys: 'alt+R',
    },
    attachmentsPane: {
        id: 'exploration.vertexEditor.AttachmentsPane',
        scope: SCOPE,
        name: messages.attachmentsPane,
        defaultKeys: 'alt+A',
    },
    historyPane: {
        id: 'exploration.vertexEditor.HistoryPane',
        scope: SCOPE,
        name: messages.historyPane,
        defaultKeys: 'alt+H',
    },
    uploadAttachment: {
        id: 'exploration.vertexEditor.UploadAttachment',
        scope: SCOPE,
        name: messages.uploadAttachment,
        defaultKeys: 'alt+U',
    },
    deleteVertex: {
        id: 'exploration.vertexEditor.DeleteVertex',
        scope: SCOPE,
        name: messages.deleteVertex,
        defaultKeys: 'alt+Delete',
    },
    modifyVertex: {
        id: 'exploration.vertexEditor.EditVertex',
        scope: SCOPE,
        name: messages.editVertex,
        defaultKeys: 'alt+E',
    },
    searchInProperties: {
        id: 'exploration.vertexEditor.SearchInProperties',
        scope: SCOPE,
        name: messages.searchInProperties,
        defaultKeys: 'alt+F',
    },
    save: {
        id: 'exploration.vertexEditor.Save',
        scope: SCOPE,
        name: messages.save,
        defaultKeys: 'alt+S',
    },
});

