import { Menu } from 'antd';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Policy } from 'src/settings/models/dtoApi';
import { ArgButton, ArgMenu, useArgNotifications } from 'src/components/basic';
import { EditPolicyModal } from '../edit-policy-modal';
import { DeletePolicyModal } from '../single-policy-delete-modal';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';

const messages = defineMessages({
    edit: {
        id: 'settings.policy.kebab-menu.edit',
        defaultMessage: 'Edit',
    },
    enable: {
        id: 'settings.policy.kebab-menu.activate',
        defaultMessage: 'Activate',
    },
    disable: {
        id: 'settings.policy.kebab-menu.deactivate',
        defaultMessage: 'Deactivate',
    },
    delete: {
        id: 'settings.policy.kebab-menu.delete',
        defaultMessage: 'Delete',
    },
    changeStatusErrorMessage: {
        id: 'settings.policy.menu.change-status-error-message',
        defaultMessage: 'Unable to {statusChange} policy',
    },
});

export interface PoliciesListKebabMenuProps {
    policy: Policy;
    setPolicies: React.Dispatch<React.SetStateAction<Policy[]>>;
}
export const PoliciesListKebabMenu: React.FunctionComponent<PoliciesListKebabMenuProps> = ({
    policy,
    setPolicies,
}) => {
    const notifications = useArgNotifications();
    const intl = useIntl();
    const [visible, setVisible] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const changeStatus = async () => {
        setDisabled(true);
        const isEnabled = policy.enabled;

        try {
            await explorationSettingsConnector.changePolicyActivationStatus(isEnabled, policy.id);
            const newPolicies = await explorationSettingsConnector.getPolicies(policy.universeId);
            setPolicies(newPolicies);
            setVisible(false);
        } catch (error) {
            const errorMessageValues = isEnabled
                ? intl.formatMessage(messages.disable).toLowerCase()
                : intl.formatMessage(messages.enable).toLowerCase();
            notifications.snackError(
                { message: messages.changeStatusErrorMessage },
                error as Error,
                { errorMessageValues }
            );
        }

        setDisabled(false);
    };

    const actionsMenu = (
        <ArgMenu data-testid='policy-kebab-button-menu'>
            <Menu.Item
                key={messages.edit.id}
                data-testid='edit'
                onClick={() => {
                    setEditModalOpen(true);
                    setVisible(false);
                }}
                disabled={disabled}
            >
                <span>{intl.formatMessage(messages.edit)}</span>
            </Menu.Item>

            <Menu.Item
                key={messages.enable.id}
                data-testid='enable-disable'
                onClick={changeStatus}
                disabled={disabled}
            >
                <span>
                    {policy.enabled
                        ? intl.formatMessage(messages.disable)
                        : intl.formatMessage(messages.enable)}
                </span>
            </Menu.Item>

            <Menu.Item
                key={messages.delete.id}
                data-testid='delete'
                disabled={disabled}
                onClick={() => {
                    setDeleteModalOpen(true);
                    setVisible(!visible);
                }}
            >
                <span>{intl.formatMessage(messages.delete)}</span>
            </Menu.Item>
            {/* TODO: descoped for MVP
            <Menu.Item key={messages.export.id} data-testid='export' disabled={disabled}>
                <span>{intl.formatMessage(messages.export)}</span>
            </Menu.Item> */}
        </ArgMenu>
    );

    return (
        <>
            <ArgButton
                type='ghost'
                icon='icon-options'
                popover={actionsMenu}
                popoverTrigger='click'
                popoverVisible={visible}
                data-testid='actions-policies-menu'
                popoverPlacement='bottomLeft'
                onPopoverVisibleChange={setVisible}
            />
            <EditPolicyModal
                visible={editModalOpen}
                closeModal={() => setEditModalOpen(false)}
                policy={policy}
                setPolicies={setPolicies}
            />
            <DeletePolicyModal
                visible={deleteModalOpen}
                closeModal={() => setDeleteModalOpen(false)}
                policy={policy}
                setPolicies={setPolicies}
            />
        </>
    );
};
