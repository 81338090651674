import React, { useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { isEqual } from 'lodash';

import {
    ArgButton,
    ClassValue,
    computeText,
    immutableUpdate,
    ProgressMonitor,
    SubProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from '../../../components/basic';
import { PageHeader } from '../../common-components/page-header';
import { AppBrander } from '../components/app-brander';
import { ModulesBrander } from '../components/modules-brander';
import { ApplicationOrModuleBranding } from '../../models/visual-identity';
import { LoadingPane } from '../../../components/common/panes/loading-pane';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';
import { ArgonosModule, ArgonosModuleId } from '../../../components/application/modules';
import { listArgonosModules } from '../../../components/application/modules-manager';
import { SettingsConnector } from '../../connectors/settings-connector';
import {
    ArgonosApplicationId,
    useApplicationBranding,
} from '../../../components/application/argonos-application-branding';
import {
    ArgonosModulesBranding,
    getArgonosModuleBranding,
    useArgonosModulesBrandingContext,
} from '../../../components/application/argonos-modules-branding';

import './visual-identity.less';

const VALID_TYPES = ['image/png', 'image/jpeg', 'image/svg'];

const messages = defineMessages({
    visualIdentityTitle: {
        id: 'settings.visual-identity.visualIdentityTitle',
        defaultMessage: 'Visual identity',
    },
    visualIdentityDescription: {
        id: 'settings.visual-identity.visualIdentityDescription',
        defaultMessage: 'Manage the main customization of your environment here.',
    },
    publish: {
        id: 'settings.visual-identity.publish',
        defaultMessage: 'Publish',
    },
    reset: {
        id: 'settings.visual-identity.Reset',
        defaultMessage: 'Reset',
    },
    appTitle: {
        id: 'settings.visual-identity.appTitle',
        defaultMessage: 'Environment',
    },
    modulesTitle: {
        id: 'settings.visual-identity.modulesTitle',
        defaultMessage: 'Modules',
    },
    modulesUpdateErrorMessage: {
        id: 'settings.visual-identity.modulesUpdateErrorMessage',
        defaultMessage: 'Error updating',
    },
    modulesUpdateErrorDescription: {
        id: 'settings.visual-identity.modulesUpdateErrorDescription',
        defaultMessage: 'Error updating branding settings',
    },
    loading: {
        id: 'settings.visual-identity.loading',
        defaultMessage: 'Loading',
    },
    failedToFetchMessage: {
        id: 'settings.visual-identity.failedToFetchMessage',
        defaultMessage: 'Failed to fetch',
    },
    failedToFetchDescription: {
        id: 'settings.visual-identity.failedToFetchDescription',
        defaultMessage: 'Failed to fetch data : ',
    },
    errorWrongTypeMessage: {
        id: 'settings.visual-identity.errorWrongTypeMessage',
        defaultMessage: 'Not supported file type',
    },
    errorWrongTypeDescription: {
        id: 'settings.visual-identity.errorWrongTypeDescription',
        defaultMessage: 'These file types are not supported, please use : jpg, png, svg. ',
    },
    publishSucceed: {
        id: 'settings.visual-identity.publishSucceed',
        defaultMessage: 'Publish succeed',
    },
});

interface VisualIdentityProps {
    className?: ClassValue;
}

export function VisualIdentityView(props: VisualIdentityProps) {
    const {
        className,
    } = props;

    const notifications = useArgNotifications();

    const classNames = useClassNames('settings-visual-identity');
    const intl = useIntl();

    const canManageVisualIdentity = useHasPermission<SettingsPermissions>('admin.visual.identity.management');
    const { setModulesBranding } = useArgonosModulesBrandingContext();
    const applicationBranding = useApplicationBranding();

    const [modulesBrandingData, setApplicationOrModulesBrandingData] = useState<ApplicationOrModuleBranding[]>(() => {
        const result = listArgonosModules().map((argonosModule) => {
            const result: ApplicationOrModuleBranding = {
                id: argonosModule.id,
                brandingName: '',
            };

            return result;
        }).value();

        result.push({
            id: applicationBranding.applicationId,
            brandingName: computeText(intl, applicationBranding.brandingName) || '',
        });

        return result;
    });
    const initialApplicationOrModulesBrandingDataRef = useRef<ApplicationOrModuleBranding[]>();

    const updateArgonosModulesContext = useCallback(() => {
        const newModulesBranding = modulesBrandingData.reduce((acc, module) => {
            if (!module.id) {
                return acc;
            }

            const settingsKey = `${applicationBranding.applicationId}-${module.id}`;
            acc[module.id] = {
                brandingName: module.brandingName,
                ...(module.logo && { brandingLogoURL: SettingsConnector.getInstance().computeAppSettingsURL(`${settingsKey}-logo`) }),
                ...(module.icon && { brandingIconURL: SettingsConnector.getInstance().computeAppSettingsURL(`${settingsKey}-icon`) }),
            };

            return acc;
        }, {} as ArgonosModulesBranding);

        setModulesBranding((prev) => ({ ...prev, ...newModulesBranding }));
    }, [modulesBrandingData, setModulesBranding, applicationBranding]);

    const [fetchData, loadingData, errorData] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const errorFetch: string[] = [];

        try {
            const promises: Promise<ApplicationOrModuleBranding>[] = listArgonosModules().map(async (argonosModule: ArgonosModule): Promise<ApplicationOrModuleBranding> => {
                const moduleBranding: ApplicationOrModuleBranding = {
                    id: argonosModule.id,
                    brandingName: '',
                };

                const sub1 = new SubProgressMonitor(progressMonitor, 1);
                try {
                    const result = await getArgonosModuleBranding(applicationBranding.applicationId, argonosModule.id, sub1);
                    if (!result) {
                        return moduleBranding;
                    }

                    moduleBranding.brandingName = computeText(intl, result.brandingName) || '';

                    const settingsKey = `${applicationBranding.applicationId}-${argonosModule.id}`;

                    const ps: Promise<void>[] = [];
                    if (result.brandingLogoURL) {
                        const sub2 = new SubProgressMonitor(progressMonitor, 1);
                        ps.push(SettingsConnector.getInstance().getAppSettingsBlob(`${settingsKey}-logo`, sub2).then((result) => {
                            moduleBranding.logo = result;
                        }, (error) => {
                            console.error(error);
                            errorFetch.push('logo');
                        }));
                    }
                    if (result.brandingIconURL) {
                        const sub3 = new SubProgressMonitor(progressMonitor, 1);
                        ps.push(SettingsConnector.getInstance().getAppSettingsBlob(`${settingsKey}-icon`, sub3).then((result) => {
                            moduleBranding.icon = result;
                        }, (error) => {
                            console.error(error);
                            errorFetch.push('icon');
                        }));
                    }

                    await Promise.all(ps);
                } catch (error) {
                    console.error(error);
                    errorFetch.push('message');
                }

                return moduleBranding;
            }).value();

            promises.push((async (): Promise<ApplicationOrModuleBranding> => {
                const moduleBranding: ApplicationOrModuleBranding = {
                    id: applicationBranding.applicationId,

                    brandingName: '',
                };

                moduleBranding.brandingName = computeText(intl, applicationBranding.brandingName) || '';

                const settingsKey = applicationBranding.applicationId;

                const ps: Promise<void>[] = [];
                if (applicationBranding.brandingLogoURL) {
                    const sub2 = new SubProgressMonitor(progressMonitor, 1);
                    ps.push(SettingsConnector.getInstance().getAppSettingsBlob(`${settingsKey}-logo`, sub2).then((result) => {
                        moduleBranding.logo = result;
                    }, (error) => {
                        console.error(error);
                        errorFetch.push('logo');
                    }));
                }
                if (applicationBranding.brandingIconURL) {
                    const sub3 = new SubProgressMonitor(progressMonitor, 1);
                    ps.push(SettingsConnector.getInstance().getAppSettingsBlob(`${settingsKey}-icon`, sub3).then((result) => {
                        moduleBranding.icon = result;
                    }, (error) => {
                        console.error(error);
                        errorFetch.push('icon');
                    }));
                }

                return moduleBranding;
            })());

            const modules = await Promise.all(promises);

            initialApplicationOrModulesBrandingDataRef.current = modules;
            setApplicationOrModulesBrandingData(modules);
        } catch (error) {
            console.error('Error fetching module settings:', error);
            notifications.snackError({
                message: messages.failedToFetchMessage,
                description: `${intl.formatMessage(messages.failedToFetchDescription) + errorFetch.join(', ')}.`,
            });
        }
    }, [applicationBranding.applicationId, intl, notifications]);

    useEffect(() => {
        fetchData().catch((error) => {
            console.error(error);
        });
    }, [fetchData]);

    const handleReset = useCallback(() => {
        setApplicationOrModulesBrandingData(initialApplicationOrModulesBrandingDataRef.current || []);
    }, []);

    const [handlePublish, publishProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const promises: Promise<void>[] = [];

            modulesBrandingData.forEach((moduleBranding: ApplicationOrModuleBranding) => {
                let settingsKey: string;
                if (moduleBranding.id === applicationBranding.applicationId) {
                    settingsKey = applicationBranding.applicationId;
                } else {
                    settingsKey = `${applicationBranding.applicationId}-${moduleBranding.id}`;
                }

                if (moduleBranding.logoModified && moduleBranding.logo) {
                    const sub1 = new SubProgressMonitor(progressMonitor, 1);
                    const promise = SettingsConnector.getInstance().setAppSettings(`${settingsKey}-logo`, moduleBranding.logo, sub1);
                    promises.push(promise);
                }

                if (moduleBranding.iconModified && moduleBranding.icon) {
                    const sub1 = new SubProgressMonitor(progressMonitor, 1);
                    const promise = SettingsConnector.getInstance().setAppSettings(`${settingsKey}-icon`, moduleBranding.icon, sub1);
                    promises.push(promise);
                }

                if (moduleBranding.brandingNameModified || moduleBranding.logoModified || moduleBranding.iconModified) {
                    const applicationDescriptor = {
                        brandingName: moduleBranding.brandingName,
                        hasLogo: !!moduleBranding.logo,
                        hasIcon: !!moduleBranding.icon,
                    };

                    const sub1 = new SubProgressMonitor(progressMonitor, 1);
                    const promise = SettingsConnector.getInstance().setAppSettings(settingsKey, applicationDescriptor, sub1);
                    promises.push(promise);
                }
            });

            await Promise.all(promises);

            initialApplicationOrModulesBrandingDataRef.current = modulesBrandingData;
            updateArgonosModulesContext();

            notifications.snackSuccess({ message: messages.publishSucceed });
        } catch (error) {
            notifications.snackError({
                message: messages.modulesUpdateErrorMessage,
                description: messages.modulesUpdateErrorDescription,
            });
            console.error('Error updating branding settings:', error);
        }
    }, [applicationBranding.applicationId, modulesBrandingData, notifications]);

    const handleLogoChange = useCallback(async (logo: Blob | undefined, id: ArgonosModuleId | ArgonosApplicationId) => {
        if (logo && !VALID_TYPES.includes(logo.type)) {
            notifications.snackError({
                message: messages.errorWrongTypeMessage,
                description: messages.errorWrongTypeDescription,
            });

            return;
        }

        setApplicationOrModulesBrandingData((prevData) => {
            const idx = prevData.findIndex((m) => m.id === id);
            if (idx < 0) {
                return prevData;
            }

            const newList = immutableUpdate(prevData, [idx], (prev) => {
                return {
                    ...prev,
                    logo,
                    logoModified: true,
                };
            });

            return newList;
        });
    }, [notifications]);

    const handleIconChange = useCallback(async (icon: Blob | undefined, id: ArgonosModuleId | ArgonosApplicationId) => {
        if (icon && !VALID_TYPES.includes(icon.type)) {
            notifications.snackError({
                message: messages.errorWrongTypeMessage,
                description: messages.errorWrongTypeDescription,
            });

            return;
        }

        setApplicationOrModulesBrandingData((prevData) => {
            const idx = prevData.findIndex((m) => m.id === id);
            if (idx < 0) {
                return prevData;
            }

            const newList = immutableUpdate(prevData, [idx], (prev) => {
                return {
                    ...prev,
                    icon,
                    iconModified: true,
                };
            });

            return newList;
        });
    }, [notifications]);

    const handleBrandingNameChange = useCallback(async (customName: string, id: ArgonosModuleId | ArgonosApplicationId) => {
        setApplicationOrModulesBrandingData((prevData) => {
            const idx = prevData.findIndex((m: ApplicationOrModuleBranding) => m.id === id);
            if (idx < 0) {
                return prevData;
            }

            const initialModule = initialApplicationOrModulesBrandingDataRef.current?.[idx];

            const newList = immutableUpdate(prevData, [idx], (prev) => {
                return {
                    ...prev,
                    brandingName: customName,
                    brandingNameModified: (initialModule?.brandingName !== customName),
                };
            });

            return newList;
        });
    }, [setApplicationOrModulesBrandingData]);

    if (loadingData?.isRunning) {
        return (
            <div className={classNames('&', className, 'loading')}>
                <LoadingPane
                    className='fill'
                    size='large'
                    message={messages.loading}
                />
            </div>
        );
    }

    const disabledPublish = !initialApplicationOrModulesBrandingDataRef.current || isEqual(initialApplicationOrModulesBrandingDataRef.current, modulesBrandingData);

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-header')}>
                <div className={classNames('&-header-left')}>
                    <PageHeader
                        className={classNames('&-header-left-title')}
                        title={messages.visualIdentityTitle}
                    />
                    <div className={classNames('&-header-left-description')}>
                        <FormattedMessage {...messages.visualIdentityDescription} />
                    </div>
                </div>
                {canManageVisualIdentity && (
                    <div className={classNames('&-header-right-buttons')}>
                        <ArgButton
                            key='reset'
                            size='medium'
                            type='primary'
                            label={messages.reset}
                            disabled={isEqual(initialApplicationOrModulesBrandingDataRef.current, modulesBrandingData) || publishProgressMonitor?.isRunning}
                            onClick={handleReset}
                        />
                        <ArgButton
                            key='publish'
                            size='medium'
                            type='primary'
                            label={messages.publish}
                            disabled={disabledPublish || publishProgressMonitor?.isRunning}
                            onClick={handlePublish}
                            loading={publishProgressMonitor?.isRunning}
                        />
                    </div>
                )}
            </div>
            <div className={classNames('&-body')}>
                <div className={classNames('&-body-app')}>
                    <h2>
                        <FormattedMessage {...messages.appTitle} />
                    </h2>
                    <AppBrander
                        applicationModuleBranding={modulesBrandingData.find((m) => m.id === applicationBranding.applicationId)!}
                        onCustomNameChange={(name) => handleBrandingNameChange(name, applicationBranding.applicationId)}
                        onIconChange={(icon) => handleIconChange(icon, applicationBranding.applicationId)}
                        onLogoChange={(logo) => handleLogoChange(logo, applicationBranding.applicationId)}
                    />
                </div>
                <div className={classNames('&-body-modules')}>
                    <h2>
                        <FormattedMessage {...messages.modulesTitle} />
                    </h2>
                    <ModulesBrander
                        modulesBrandingList={modulesBrandingData.filter((m) => m.id !== applicationBranding.applicationId)}
                        onCustomNameChange={handleBrandingNameChange}
                        onIconChange={handleIconChange}
                        onLogoChange={handleLogoChange}
                    />
                </div>
            </div>
        </div>
    );
}
