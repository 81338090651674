import { useEffect, useState } from 'react';
import Debug from 'debug';

import { StateId } from '../utils/event-emitter';
import { ToolContext } from './tool-context';

const debug = Debug('common:basic:UseContextUpdate');

export function useContextUpdate<T>(toolContext: ToolContext<T>): StateId {
    const [stateId, setStateId] = useState(() => {
        debug('Initialize', 'stateId=', toolContext.stateId);

        return toolContext.stateId;
    });

    useEffect(() => {
        const updateToolbar = () => {
            debug('Update toolbar', toolContext.stateId);
            setStateId(toolContext.stateId);
        };

        toolContext.on('*', updateToolbar);

        return () => {
            //setStateId(UNMOUNTED_STATEID);
            toolContext.removeListener('*', updateToolbar);
        };
    }, [toolContext]);

    return stateId;
}
