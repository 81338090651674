import { ToolTreeNode } from './tool-context';
import { isToolVisible } from './tool';

export type ToolbarItemCallback<T, E> = (item: ToolTreeNode<E>) => T | undefined;

export function walkNode<T, E = undefined>(root: ToolTreeNode<E>[], callback: ToolbarItemCallback<T, E>, environmentContext: E) {
    const stack: ToolTreeNode<E>[] = [...root];

    for (; stack.length;) {
        const node = stack.shift()!;

        if (!isToolVisible<E>(node, environmentContext)) {
            continue;
        }

        const ret = callback(node);

        if (ret !== undefined) {
            return ret;
        }

        if (node.children) {
            stack.unshift(...node.children);
        }
    }
}

export function countVisibleChildren<E = undefined>(node: ToolTreeNode<E>, environmentContext: E): ToolTreeNode<E>[] {
    if (node.children) {
        const visibleChildren = node.children.reduce<ToolTreeNode<E>[]>((acc: ToolTreeNode<E>[], child: ToolTreeNode<E>) => {
            const cs = countVisibleChildren<E>(child, environmentContext);
            if (cs.length) {
                acc.push(child);
            }

            return acc;
        }, []);

        return visibleChildren;
    }

    const isVisible = isToolVisible<E>(node, environmentContext);

    return (isVisible) ? [node] : [];
}


export function hasOnlyContainer<E = undefined>(root: ToolTreeNode<E>[], environmentContext: E): boolean {
    const onlyContainer = walkNode<any, E>(root, (node: ToolTreeNode<E>) => {
        switch (node.type) {
            case 'group':
            case 'marker':
            case 'separator':
                return undefined;

            default:
                return false;
        }
    }, environmentContext);

    return onlyContainer !== false;
}
