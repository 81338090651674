import { Row } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';

import { ArgCombo, ArgFormLabel } from 'src/components/basic';
import { UserInputType } from '../../../../../model/user-metadata';
import { FormatItem, INPUT_TYPES, InputType, messages, OptionListItem, TypeItem } from './const';
import { OptionComponentList } from './fixed-values/fixed-values-options-list';

interface PropertyInputTypeProps {
    type: TypeItem | undefined;
    inputType: InputType | UserInputType | undefined;
    setInputType: Dispatch<SetStateAction<InputType | UserInputType | undefined>>;
    format: FormatItem | undefined;
    setFormat: Dispatch<SetStateAction<FormatItem | undefined>>;
    optionsList: OptionListItem[];
    inputs: InputType[] | UserInputType [];
    setOptionsList: Dispatch<SetStateAction<OptionListItem[]>>;
    isMetaProperty?: boolean;
    displayFinalOptionOnly: boolean;
    setDisplayFinalOptionOnly: Dispatch<SetStateAction<boolean>>;
}

export function PropertyInputType(props: PropertyInputTypeProps) {
    const {
        type,
        inputType,
        setInputType,
        optionsList,
        setOptionsList,
        inputs,
        isMetaProperty,
        displayFinalOptionOnly,
        setDisplayFinalOptionOnly,
    } = props;

    if (type === TypeItem.boolean) {
        return null;
    }

    return (
        <>
            {!isMetaProperty && (
                <Row>
                    <ArgFormLabel propertyName={messages.fieldInputType}>
                        <ArgCombo<InputType | UserInputType>
                            items={inputs}
                            renderItem={(type) => {
                                const value = INPUT_TYPES[type];

                                return <FormattedMessage {...value.label} />;
                            }}
                            value={inputType}
                            onChange={(type: InputType) => {
                                setInputType(type);
                            }}
                            getItemLabel={(input) => {
                                return input ? INPUT_TYPES[input]?.label : undefined;
                            }}
                        />
                    </ArgFormLabel>
                </Row>
            )}
            {inputType !== InputType.freeInput && (
                <OptionComponentList
                    optionsList={optionsList}
                    setOptionsList={setOptionsList}
                    displayFinalOptionOnly={displayFinalOptionOnly}
                    setDisplayFinalOptionOnly={setDisplayFinalOptionOnly}
                />
            )}
        </>
    );
}
