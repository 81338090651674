import { useCallback, useState } from 'react';
import { defineMessages } from 'react-intl';
import Debug from 'debug';
import { isNull } from 'lodash';

import { ArgButton, ArgMenu, ArgMenuItem, ProgressMonitor, SubProgressMonitor, getGlobalNotifications, useArgModalContext, useClassNames } from 'src/components/basic';
import { CasePieceType } from 'src/model/case-piece-type';
import { ProceoConnector } from 'src/proceo/utils/connectors/proceo-connector';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';
import { isGroupService, UIItem } from './hierarchy-overview';
import { NewService, Service } from 'src/proceo/model/service';
import { HierarchyGroupModal } from './hierarchy-group-modal';
import { HierarchyEditMembersModal } from './hierarchy-edit-members-modal';
import { ProceoGroupsConnector } from 'src/proceo/utils/connectors/proceo-groups-connector';
import { ProceoPieceConnector } from 'src/proceo/utils/connectors/proceo-piece-connector';
import { ProceoGroupCustomFieldNames, ProceoGroupCustomFields } from 'src/proceo/constants/group-custom-fields';
import { ProceoResourceConnector } from 'src/proceo/utils/connectors/proceo-resource-connector';
import { ProceoGroup } from 'src/proceo/model/group';
import { HierarchyServiceModal, HierarchyServiceModalSubmitType } from './hierarchy-service-modal';
import { getServiceJsonChanges } from '../../../utils/service-utils';
import { Address } from '../../../../model/address';

const HIERARCHY_DELETE_MODAL_ID = 'HIERARCHY_DELETE_MODAL_ID';
const HIERARCHY_CREATE_GROUP_MODAL_ID = 'HIERARCHY_CREATE_GROUP_MODAL_ID';
const HIERARCHY_EDIT_MEMBERS_MODAL_ID = 'HIERARCHY_CREATE_EDIT_MODAL_ID';
const HIERARCHY_CREATE_SERVICE_MODAL_ID = 'HIERARCHY_CREATE_SERVICE_MODAL_ID';

export enum HierarchyServiceActions {
    NewService = 'newService',
    NewGroup = 'newGroup',
    Delete = 'delete',
    Edit = 'edit',
    EditMembers = 'editMembers',
}

const messages = defineMessages({
    newService: {
        id: 'proceo.hierarchy.menu.newService',
        defaultMessage: 'Create a service',
    },
    newGroup: {
        id: 'proceo.hierarchy.menu.newGroup',
        defaultMessage: 'Create a group',
    },
    edit: {
        id: 'proceo.hierarchy.menu.edit',
        defaultMessage: 'Edit',
    },
    delete: {
        id: 'proceo.hierarchy.menu.delete',
        defaultMessage: 'Erase',
    },

    //DELETE
    deletingGroup: {
        id: 'proceo.hierarchy.menu.DeletingGroup',
        defaultMessage: 'Deleting group',
    },
    deleteGroupConfirmText: {
        id: 'proceo.hierarchy.menu.DeleteGroupConfirmText',
        defaultMessage: 'Delete',
    },
    deleteGroupConfirmTitle: {
        id: 'proceo.hierarchy.menu.DeleteGroupConfirmTitle',
        defaultMessage: 'Delete the group',
    },
    deleteGroupConfirmMessage: {
        id: 'proceo.hierarchy.menu.DeleteGroupConfirmMessage',
        defaultMessage: 'Are you sure you want to delete this group?',
    },
    deleteGroupConfirmDescription: {
        id: 'proceo.hierarchy.menu.DeleteGroupConfirmDescription',
        defaultMessage: 'The content of this group will be definitely lost',
    },
    deleteGroupError: {
        id: 'proceo.hierarchy.menu.DeleteGroupError',
        defaultMessage: 'Failed to delete the group',
    },
    //SERVICE
    deletingService: {
        id: 'proceo.hierarchy.menu.DeletingService',
        defaultMessage: 'Deleting service',
    },
    deleteServiceConfirmText: {
        id: 'proceo.hierarchy.menu.DeleteServiceConfirmText',
        defaultMessage: 'Delete',
    },
    deleteServiceConfirmTitle: {
        id: 'proceo.hierarchy.menu.DeleteServiceConfirmTitle',
        defaultMessage: 'Delete the service',
    },
    deleteServiceConfirmMessage: {
        id: 'proceo.hierarchy.menu.DeleteServiceConfirmMessage',
        defaultMessage: 'Are you sure you want to delete this service?',
    },
    deleteServiceConfirmDescription: {
        id: 'proceo.hierarchy.menu.DeleteServiceConfirmDescription',
        defaultMessage: 'The content of this service will be definitely lost',
    },
    deleteServiceError: {
        id: 'proceo.hierarchy.menu.DeleteServiceError',
        defaultMessage: 'Failed to delete the service',
    },

    //NEW SERVICE ERROR
    createServiceHierarchyError: {
        id: 'proceo.hierarchy.menu.CreateServiceHierarchyError',
        defaultMessage: 'Failed to create the service',
    },

    //NEW SUBFOLDER ERROR
    createGroupHierarchyElementError: {
        id: 'proceo.hierarchy.menu.CreateGroupHierarchyElementError',
        defaultMessage: 'Failed to create the group',
    },

    editGroupHierarchyElementError: {
        id: 'proceo.hierarchy.menu.EditGroupHierarchyElementError',
        defaultMessage: 'Failed to update the group',
    },

    editServiceHierarchyError: {
        id: 'proceo.hierarchy.menu.EditServiceHierarchyError',
        defaultMessage: 'Failed to update the service',
    },

    progressMonitorInsertArticulationItem: {
        id: 'proceo.hierarchy.menu.monitor.InsertArticulationItem',
        defaultMessage: 'Insert element in hierarchy subfolder',
    },

    editMembers: {
        id: 'proceo.hierarchy.menu.EditMembers',
        defaultMessage: 'Edit members',
    },
});

export interface HierarchyActionsMenuProps {
    element: UIItem;
    onSubmit: () => void;
    services: Service[];
}
export function HierarchyActionsMenu(props: HierarchyActionsMenuProps) {
    const {
        element,
        onSubmit,
        services,
    } = props;
    const classNames = useClassNames('proceo-hierarchy-actions-menu');
    const debug = Debug('proceo:actions-menu:HierarchyServiceionsMenu');
    const [visible, setVisible] = useState(false);
    const modalContext = useArgModalContext();
    const notifications = getGlobalNotifications();

    const handleSubmit = useCallback(() => {
        return onSubmit();
    }, [onSubmit]);

    const deleteHierarchyElement = useCallback((target: ProceoGroup) => {
        const deleteHierarchyElementAsync = async (progressMonitor: ProgressMonitor) => {
            try {
                // As created groups and services are linked to a parent folderId, delete will trigger cascade deletion.
                await ProceoGroupsConnector.getInstance().deleteFolder(target.id, progressMonitor);

                handleSubmit();
            } catch (error) {
                if (progressMonitor.isCancelled) {
                    throw error;
                }

                notifications.snackError({ message: target.type === CasePieceType.Folder ? messages.deleteGroupError : messages.deleteServiceError }, error as Error);
            }
        };

        const isService = isGroupService(target);
        modalContext.open(HIERARCHY_DELETE_MODAL_ID, (
            <ConfirmModal
                title={isService ? messages.deleteServiceConfirmTitle : messages.deleteGroupConfirmTitle}
                alertDescription={isService ? messages.deleteServiceConfirmDescription : messages.deleteGroupConfirmDescription}
                alertMessage={isService ? messages.deleteServiceConfirmMessage : messages.deleteGroupConfirmMessage}
                confirmText={isService ? messages.deleteServiceConfirmText : messages.deleteGroupConfirmText}
                onClose={() => modalContext.close(HIERARCHY_DELETE_MODAL_ID)}
                onConfirm={deleteHierarchyElementAsync}
                progressMonitorName={isService ? messages.deletingService : messages.deletingGroup}
            />
        ));
    }, [modalContext, notifications, handleSubmit]);

    const openServiceModal = useCallback(async (target: ProceoGroup, service?: Service): Promise<void> => {
        const _handleSubmit: HierarchyServiceModalSubmitType = async (
            progressMonitor,
            name,
            code,
            phoneNumber,
            address,
            newImage,
            faxNumber
        ) => {
            try {
                if (!service) {
                    progressMonitor.setTaskName(messages.progressMonitorInsertArticulationItem);
                    await createService(target, name, code, phoneNumber, address, faxNumber, newImage, progressMonitor);
                } else {
                    await updateService(target, service, name, code, phoneNumber, address, faxNumber, newImage, progressMonitor);
                }

                return handleSubmit();
            } catch (error) {
                if (progressMonitor.isCancelled) {
                    throw error;
                }

                notifications.snackError({ message: service ? messages.editServiceHierarchyError : messages.createServiceHierarchyError }, error as Error);
            }
        };
        modalContext.open(HIERARCHY_CREATE_SERVICE_MODAL_ID, (
            <HierarchyServiceModal
                onClose={() => modalContext.close(HIERARCHY_CREATE_SERVICE_MODAL_ID)}
                service={service}
                onSubmit={_handleSubmit}
            />
        ));
    }, [modalContext, notifications, handleSubmit]);

    const openGroupModal = useCallback((target: ProceoGroup, group?: ProceoGroup) => {
        const _handleSubmit = async (progressMonitor: ProgressMonitor, name: string) => {
            try {
                if (!group) {
                    progressMonitor.setTaskName(messages.progressMonitorInsertArticulationItem);
                    await createGroup(name, target, progressMonitor);
                } else {
                    await updateGroup(name, group, progressMonitor);
                }

                return handleSubmit();
            } catch (error) {
                if (progressMonitor.isCancelled) {
                    throw error;
                }

                notifications.snackError({ message: group ? messages.editGroupHierarchyElementError : messages.createGroupHierarchyElementError }, error as Error);
            }
        };
        modalContext.open(HIERARCHY_CREATE_GROUP_MODAL_ID, (
            <HierarchyGroupModal
                onClose={() => modalContext.close(HIERARCHY_CREATE_GROUP_MODAL_ID)}
                onSubmit={_handleSubmit}
                group={group}
            />
        ));
    }, [modalContext, notifications, handleSubmit]);

    const editGroupMembers = useCallback((target: ProceoGroup) => {
        modalContext.open(HIERARCHY_EDIT_MEMBERS_MODAL_ID, (
            <HierarchyEditMembersModal
                onClose={() => modalContext.close(HIERARCHY_EDIT_MEMBERS_MODAL_ID)}
                group={target}
            />
        ));
    }, [modalContext]);

    const handleActionMenuItem = useCallback((key: HierarchyServiceActions) => {
        debug('handleActionMenuItem', 'key=', key);
        switch (key) {
            case HierarchyServiceActions.NewService:
                openServiceModal(element.data);
                break;
            case HierarchyServiceActions.NewGroup:
                openGroupModal({ ...element.data });
                break;
            case HierarchyServiceActions.Delete:
                deleteHierarchyElement({ ...element.data });
                break;
            case HierarchyServiceActions.EditMembers:
                editGroupMembers({ ...element.data });
                break;
            case HierarchyServiceActions.Edit: {
                const matchedService = services.find(service => service.id === element.data.serviceId);
                if (matchedService) {
                    openServiceModal(element.data, matchedService);
                } else {
                    openGroupModal(element.data, element.data);
                }
                break;
            }
            default:
                break;
        }
        setVisible(false);
    }, [debug, element, openServiceModal, openGroupModal, deleteHierarchyElement, editGroupMembers, services]);

    const actionsMenu = useCallback(() => {
        return <ArgMenu className={classNames('&-menu')} data-testid='actions-folder-button-menu' onClick={param => handleActionMenuItem(param.key as HierarchyServiceActions)}>
            <>
                <ArgMenuItem
                    key={HierarchyServiceActions.NewService}
                    data-testid='menu-item-assign'
                    label={messages.newService}
                />
                <ArgMenuItem
                    key={HierarchyServiceActions.NewGroup}
                    data-testid='menu-item-assign'
                    label={messages.newGroup}
                />
                <ArgMenuItem
                    key={HierarchyServiceActions.Edit}
                    data-testid='menu-item-assign'
                    label={messages.edit}
                />
                <ArgMenuItem
                    key={HierarchyServiceActions.Delete}
                    data-testid='menu-item-assign'
                    label={messages.delete}
                />
                <ArgMenuItem
                    key={HierarchyServiceActions.EditMembers}
                    data-testid='menu-item-assign'
                    label={messages.editMembers}
                />
            </>
        </ArgMenu>;
    }, [classNames, handleActionMenuItem]);

    return (
        <ArgButton
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={visible}
            data-testid='actions-folders-menu'
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
        />
    );
}
async function createGroup(name: string, target: ProceoGroup, progressMonitor: ProgressMonitor) {
    const sub1 = new SubProgressMonitor(progressMonitor, 1);
    const createdFolder = await ProceoGroupsConnector.getInstance().createFolder(name, undefined, undefined, undefined, sub1);

    const parentId = target.id;

    const sub2 = new SubProgressMonitor(progressMonitor, 1);
    await ProceoPieceConnector.getInstance().addPiece(parentId, createdFolder.id, CasePieceType.Folder, sub2);
}

async function updateGroup(name: string, group: ProceoGroup, progressMonitor: ProgressMonitor) {
    if (name !== group.displayName) {
        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        await ProceoGroupsConnector.getInstance().updateFolder(group.id, name, group.description, group.customFields, sub1);
    }
}

async function createService(
    target: ProceoGroup,
    name: string,
    code: string,
    phoneNumber: string,
    address: Address,
    faxNumber: string | undefined,
    newImage: Blob | null | undefined,
    progressMonitor: ProgressMonitor
) {
    let createdResourceId: string | undefined;

    if (newImage) {
        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        const file = newImage as File;
        createdResourceId = await ProceoResourceConnector.getInstance().uploadCaseResource(undefined, file.name, undefined, newImage, undefined, sub1);
    }

    const newService: NewService = {
        name: name,
        code: code,
        phoneNumber: phoneNumber,
        headOffice: '',
        address: address,
        faxNumber: faxNumber,
        stampId: createdResourceId,
    };
    const sub1 = new SubProgressMonitor(progressMonitor, 1);
    const createdService = await ProceoConnector.getInstance().createService(newService, sub1);

    const newCustomFields: ProceoGroupCustomFields = { [ProceoGroupCustomFieldNames.ServiceId]: createdService.id };

    const sub2 = new SubProgressMonitor(progressMonitor, 1);
    const createdCase = await ProceoGroupsConnector.getInstance().createFolder(name, undefined, undefined, newCustomFields, sub2);

    if (createdResourceId) {
        const sub3 = new SubProgressMonitor(progressMonitor, 1);
        await ProceoPieceConnector.getInstance().addPiece(createdCase.id, createdResourceId, CasePieceType.Resource, sub3);
    }

    const sub4 = new SubProgressMonitor(progressMonitor, 1);
    await ProceoPieceConnector.getInstance().addPiece(createdCase.id, createdService.id, CasePieceType.Service, sub4);

    const sub5 = new SubProgressMonitor(progressMonitor, 1);
    await ProceoPieceConnector.getInstance().addPiece(target.id, createdCase.id, CasePieceType.Folder, sub5);
}

async function updateService(
    target: ProceoGroup,
    currentService: Service,
    name: string,
    code: string,
    phoneNumber: string,
    address: Address,
    faxNumber: string | undefined,
    newImage: Blob | null | undefined,
    progressMonitor: ProgressMonitor) {
    let stampId = currentService.stampId;
    if ((newImage && currentService.stampId)
        || (isNull(newImage) && currentService.stampId)) {
        // Delete previous image
        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        await ProceoResourceConnector.getInstance().deleteResource(currentService.stampId, sub1);
        stampId = undefined;
    }

    if (newImage) {
        // Upload new image
        const sub2 = new SubProgressMonitor(progressMonitor, 1);
        const file = newImage as File;
        stampId = await ProceoResourceConnector.getInstance().uploadCaseResource(target.id, file.name, undefined, newImage, undefined, sub2);
    }

    const updatedService: NewService = {
        name: name,
        code: code,
        phoneNumber: phoneNumber,
        faxNumber: faxNumber,
        stampId: stampId,
        headOffice: '',
        address: address,
    };
    const changes = getServiceJsonChanges(updatedService, currentService);
    const sub3 = new SubProgressMonitor(progressMonitor, 1);
    await ProceoConnector.getInstance().patchService(currentService.id, changes, sub3);

    if (updatedService.name !== target.displayName) {
        const sub4 = new SubProgressMonitor(progressMonitor, 1);
        await ProceoGroupsConnector.getInstance().updateFolder(target.id, updatedService.name, target.description, target.customFields, sub4);
    }
}
