import { defineMessages } from 'react-intl';

import { FolderCustomFieldDefinition } from '../../model/folder-custom-field-definition';
import { LegalFramework, LegalRegime, ProceoFolderConfidentialityLevel, ProceoFolderStatus } from '../model/folder';
import { DataType } from 'src/components/common/data-types';
import { PROCEO_BASE_FOLDER_CUSTOM_FIELDS_DEFINITION, ProceoBaseFolderCustomFieldsDefinition } from './base-folder-custom-fields';

const messages = defineMessages({
    folderNumber: {
        id: 'proceo.constants.folder-field.number',
        defaultMessage: 'Folder number',
    },
    justiceNumber: {
        id: 'proceo.constants.folder-field.justice-number',
        defaultMessage: 'Justice number',
    },
    legalFramework: {
        id: 'proceo.constants.folder-field.legal-framework',
        defaultMessage: 'Legal framework',
    },
    legalRegime: {
        id: 'proceo.constants.folder-field.legal-regime',
        defaultMessage: 'Legal regime',
    },
    confidentialityLevel: {
        id: 'proceo.constants.folder-field.confidentiality-level',
        defaultMessage: 'Confidentiality level',
    },
    responsible: {
        id: 'proceo.constants.folder-field.responsible',
        defaultMessage: 'Responsible',
    },
    lastAct: {
        id: 'proceo.constants.folder-field.last-act',
        defaultMessage: 'Last act',
    },
    persons: {
        id: 'proceo.constants.folder-field.persons',
        defaultMessage: 'Persons',
    },
    offence: {
        id: 'proceo.constants.folder-field.offence',
        defaultMessage: 'Offence',
    },
    status: {
        id: 'proceo.constants.folder-field.status',
        defaultMessage: 'Status',
    },
    picture: {
        id: 'proceo.constants.folder-field.picture',
        defaultMessage: 'Picture',
    },
    procedureArticulationData: {
        id: 'proceo.constants.folder-field.procedureArticulationData',
        defaultMessage: 'Procedure articulation',
    },
    articulationTemplate: {
        id: 'proceo.constants.folder-field.articulationTemplate',
        defaultMessage: 'Articulation template',
    },
    type: {
        id: 'proceo.constants.folder-field.type',
        defaultMessage: 'Type',
    },
});

export type ProceoFolderCustomFields = Partial<Record<ProceoFolderCustomFieldNames, any>>;
export type ProceoFolderCustomFieldsDefinition = ProceoBaseFolderCustomFieldsDefinition & Record<ProceoFolderCustomFieldNames, FolderCustomFieldDefinition>;

export enum ProceoFolderCustomFieldNames {
    FolderNumber = 'FolderNumber',
    JusticeNumber = 'JusticeNumber',
    LegalFramework = 'LegalFramework',
    LegalRegime = 'LegalRegime',
    ConfidentialityLevel = 'ConfidentialityLevel',
    Responsible = 'Responsible',
    LastAct = 'LastAct',
    MainPersons = 'MainPersons',
    MainOffence = 'MainOffence',
    Status = 'Status',
    Picture = 'Picture',
    ProcedureArticulationData = 'ProcedureArticulationData',
    ArticulationTemplate = 'ArticulationTemplate',
}


export const PROCEO_FOLDER_CUSTOM_FIELDS_DEFINITION: ProceoFolderCustomFieldsDefinition = {
    ...PROCEO_BASE_FOLDER_CUSTOM_FIELDS_DEFINITION,
    [ProceoFolderCustomFieldNames.FolderNumber]: {
        name: ProceoFolderCustomFieldNames.FolderNumber,
        displayName: messages.folderNumber,
        type: DataType.String,
        isRequired: false,
        isSortable: true,
    },
    [ProceoFolderCustomFieldNames.JusticeNumber]: {
        name: ProceoFolderCustomFieldNames.JusticeNumber,
        displayName: messages.justiceNumber,
        type: DataType.String,
        isRequired: false,
        isSortable: true,
    },
    [ProceoFolderCustomFieldNames.LegalFramework]: {
        name: ProceoFolderCustomFieldNames.LegalFramework,
        displayName: messages.legalFramework,
        type: DataType.String,
        isRequired: false,
        isSortable: true,
        possibleValues: Object.values(LegalFramework),
    },
    [ProceoFolderCustomFieldNames.LegalRegime]: {
        name: ProceoFolderCustomFieldNames.LegalRegime,
        displayName: messages.legalRegime,
        type: DataType.String,
        isRequired: false,
        isSortable: true,
        possibleValues: Object.values(LegalRegime),
    },
    [ProceoFolderCustomFieldNames.ConfidentialityLevel]: {
        name: ProceoFolderCustomFieldNames.ConfidentialityLevel,
        displayName: messages.confidentialityLevel,
        type: DataType.String,
        isRequired: false,
        isSortable: true,
        possibleValues: Object.values(ProceoFolderConfidentialityLevel),
    },
    [ProceoFolderCustomFieldNames.Responsible]: {
        name: ProceoFolderCustomFieldNames.Responsible,
        displayName: messages.responsible,
        type: DataType.String,
        isSortable: true,
        isRequired: false,
    },
    [ProceoFolderCustomFieldNames.MainPersons]: {
        name: ProceoFolderCustomFieldNames.MainPersons,
        displayName: messages.persons,
        type: DataType.String,
    },
    [ProceoFolderCustomFieldNames.MainOffence]: {
        name: ProceoFolderCustomFieldNames.MainOffence,
        displayName: messages.offence,
        type: DataType.String,
    },
    [ProceoFolderCustomFieldNames.LastAct]: {
        name: ProceoFolderCustomFieldNames.LastAct,
        displayName: messages.lastAct,
        type: DataType.DateTime,
        isSortable: true,
    },
    [ProceoFolderCustomFieldNames.Status]: {
        name: ProceoFolderCustomFieldNames.Status,
        displayName: messages.status,
        type: DataType.String,
        isRequired: false,
        isSortable: true,
        possibleValues: Object.values(ProceoFolderStatus),
    },
    [ProceoFolderCustomFieldNames.Picture]: {
        name: ProceoFolderCustomFieldNames.Picture,
        displayName: messages.picture,
        type: DataType.String,
        isSortable: false,
        isRequired: false,
    },
    [ProceoFolderCustomFieldNames.ProcedureArticulationData]: {
        name: ProceoFolderCustomFieldNames.ProcedureArticulationData,
        displayName: messages.procedureArticulationData,
        type: DataType.String,
    },
    [ProceoFolderCustomFieldNames.ArticulationTemplate]: {
        name: ProceoFolderCustomFieldNames.ArticulationTemplate,
        displayName: messages.articulationTemplate,
        isRequired: false,
        type: DataType.Object,
    },
};
