
import { useCallback, useEffect, useState } from 'react';
import { multiply } from 'lodash';
import { FormattedMessage, defineMessages } from 'react-intl';

import {
    ArgButton,
    ArgDragAndDropUploader, ArgIcon,
    ArgImage,
    ArgUploaderButton,
    ClassValue, useArgNotifications, useClassNames,
} from '../../../components/basic';
import { getMimeTypesFromExtensions } from '../../../utils/file';

import './input-image-manager.less';

const messages = defineMessages({
    add: {
        id: 'proceo.image-manager.add',
        defaultMessage: 'Add photo',
    },
    change: {
        id: 'proceo.image-manager.change',
        defaultMessage: 'Change photo',
    },
    delete: {
        id: 'proceo.image-manager.delete',
        defaultMessage: 'Delete photo',
    },
    imageMaxSizeExceed: {
        id: 'proceo.image-manager.imageMaxSizeExceed',
        defaultMessage: 'Images are limited to {fileSizeMb} Mb',
    },
});

const ACCEPTED_FILE_EXTENSIONS = '.jpg,.jpeg,.png,.svg,.tiff';
const VALID_TYPES = getMimeTypesFromExtensions(ACCEPTED_FILE_EXTENSIONS.split(','));

const DEFAULT_IMAGE_MAX_SIZE_MB = 15;

interface ImageManagerProps {
    className?: ClassValue;
    initialImageBlobUrl?: string;
    onImageChange: (image: Blob | undefined) => void;
    imageMaxSizeMb?: number;
}

export default function InputImageManager(props: ImageManagerProps) {
    const {
        className,
        initialImageBlobUrl,
        onImageChange,
        imageMaxSizeMb = DEFAULT_IMAGE_MAX_SIZE_MB,
    } = props;

    const classNames = useClassNames('proceo-folder-image-manager');
    const notifications = useArgNotifications();

    const [blob, setBlob] = useState<Blob | undefined>();
    const [_initialImageBlobUrl, setInitialimageBlobUrl] = useState(initialImageBlobUrl);
    const [blobURL, setBlobURL] = useState<string>();
    const _blobUrl = blobURL || _initialImageBlobUrl;

    const handleImageChange = useCallback(async (image: Blob | undefined) => {
        if (image && !VALID_TYPES.includes(image.type)) {
            return;
        }

        if (image){
            const file = image as File;
            const maxFileSize = multiply(imageMaxSizeMb, Math.pow(1024, 2));
            if (file.size > maxFileSize) {
                notifications.snackError({ message: <FormattedMessage {...messages.imageMaxSizeExceed} values={{ fileSizeMb: imageMaxSizeMb }} /> });

                return;
            }
        }
        setBlob(image);

        if (!image) {
            setInitialimageBlobUrl(undefined);
        }

        onImageChange(image);
    }, [imageMaxSizeMb, notifications, onImageChange]);

    useEffect(() => {
        if (!blob) {
            setBlobURL(undefined);

            return;
        }

        const url = URL.createObjectURL(blob);
        setBlobURL(url);

        return () => {
            URL.revokeObjectURL(url);
        };
    }, [blob]);

    return (
        <div className={classNames('&', className)}>
            <ArgDragAndDropUploader className={classNames('&-uploader')} method={async (image) => handleImageChange(image)}>
                {!_blobUrl ? (
                    <div className={classNames('&-uploader-dropArea')}>
                        <ArgIcon size={24} name='icon-image' color='gray' />
                        <ArgUploaderButton type='link' icon='icon-plus' label={messages.add} acceptedFiles={ACCEPTED_FILE_EXTENSIONS}
                                           method={async (image) => handleImageChange(image)} />
                    </div>
                ) : (
                    <div className={classNames('&-uploader-dropArea')}>
                        <ArgImage className={classNames('&-uploader-image')} widthForcedByParent={true} src={_blobUrl} />
                    </div>
                )}
            </ArgDragAndDropUploader>
            {_blobUrl && (
                <div className={classNames('&-menu')}>
                    <ArgUploaderButton
                        type='link'
                        size='large'
                        className={classNames('&-menu-button')}
                        label={messages.change}
                        acceptedFiles={ACCEPTED_FILE_EXTENSIONS}
                        method={async (image) => handleImageChange(image)}
                    />
                    <ArgButton
                        type='link'
                        size='large'
                        className={classNames('&-menu-button')}
                        label={messages.delete}
                        onClick={() => handleImageChange(undefined)}
                    />
                </div>
            )}
        </div>
    );
}
