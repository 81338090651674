import React, { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ListControlProps, ListControlValue } from './controls-type';
import {
    ArgChangeReason,
    ArgCombo,
    ArgGetItemKey,
    ArgInputDndConfig,
    ArgMessageRenderer,
    ThreeDotsLoading,
    useClassNames,
} from '../../basic';
import { ControlParameter } from './control-parameter';
import { DroppedParameter } from './dropped-parameter';

import './picklist-control.less';

interface Item<ItemType> {
    label: string;
    value: ItemType | null;
    numberOfVertices: number | undefined;
}

export interface PickListControlType<ItemType> extends ListControlProps<ItemType> {
    maxTagCount?: number | 'responsive';
    minTagCount?: number;
    undefinedLabel?: string;
    sortPriorities?: { [key: string]: number };
    readOnly?: boolean;
}

const messages = defineMessages({
    undefinedLabel: {
        id: 'common.controls.picklist.UndefinedLabel',
        defaultMessage: 'Undefined values',
    },
    placeholder: {
        id: 'common.controls.picklist.SearchPlaceholder',
        defaultMessage: 'Select {propertyDisplayName}',
    },
    dropParameter: {
        id: 'common.controls.picklist.DropParameter',
        defaultMessage: 'Drop the parameter here',
    },
    noData: {
        id: 'common.controls.picklist.NoData',
        defaultMessage: 'No data',
    },
});

export const PickListControl = <ItemType, >({
    propertyDisplayName,
    value,
    onChange,
    propertyInfo,
    propertyInfoLoading,
    undefinedLabel,
    sortPriorities,
    showUndefined,
    acceptsParameters,
    readOnly,
}: PickListControlType<ItemType>) => {
    const intl = useIntl();

    const classNames = useClassNames('arg-picklist-control');

    const list = useMemo<Item<ItemType>[]>(() => {
        if (!propertyInfo) {
            return [];
        }

        let list: Item<ItemType>[] = [];
        if (propertyInfo.facets) {
            list = propertyInfo.facets.map((f) => {
                const ret: Item<ItemType> = {
                    value: f.value,
                    numberOfVertices: f.numberOfVertices || 0,
                    label: String(f.value),
                };

                return ret;
            });
        }

        list.sort((f1, f2) => {
            const n = (f1.numberOfVertices || 0) - (f2.numberOfVertices || 0);
            if (n < 0) {
                return 1;
            }
            if (n > 0) {
                return -1;
            }

            const label1 = f1.label as string;
            const label2 = f2.label as string;

            if (sortPriorities) {
                const p1 = sortPriorities[label1];
                const p2 = sortPriorities[label2];

                if (p1 > p2) {
                    return -1;
                }
                if (p1 < p2) {
                    return 1;
                }
            }

            if (label1 < label2) {
                return -1;
            }
            if (label1 > label2) {
                return 1;
            }

            return 0;
        });

        if (showUndefined !== false) {
            list.push({
                value: null,
                label: undefinedLabel || intl.formatMessage(messages.undefinedLabel),
                numberOfVertices: propertyInfo.numberOfMissing || 0,
            });
        }

        return list;
    }, [intl, propertyInfo, showUndefined, sortPriorities, undefinedLabel]);

    const selectValues = useMemo<Item<ItemType>[]>(() => {
        if (!value || !value.fixed?.length) {
            return [];
        }

        const selectValues: Item<ItemType>[] = value.fixed?.map((v) => {
            if (list) {
                const f = list.find((i) => (i.value === v));
                if (f) {
                    return f;
                }
            }

            if (v === null) {
                const ret: Item<ItemType> = {
                    value: null,
                    label: undefinedLabel || intl.formatMessage(messages.undefinedLabel),
                    numberOfVertices: 0,
                };

                return ret;
            }

            const ret: Item<ItemType> = {
                value: v,
                label: String(v),
                numberOfVertices: 0,
            };

            return ret;
        });

        return selectValues;
    }, [value, list]);

    const handleSelectChange = (value: Item<ItemType> | Item<ItemType>[] | undefined, reason: ArgChangeReason) => {
        let newValues: (ItemType | null)[];
        if (Array.isArray(value)) {
            newValues = value.map((f) => {
                return f.value;
            });
        } else if (value) {
            newValues = [value.value];
        } else {
            newValues = [null];
        }

        onChange((prev) => ({ ...prev, fixed: newValues }), reason);
    };

    const handleParameterChange = useCallback((type: string, parameter: ControlParameter | undefined) => {
        onChange((prev) => {
            const newValue: ListControlValue<ItemType> = {
                ...prev,
                parameter,
            };

            return newValue;
        }, 'keypress');
    }, [onChange]);

    const getItemKey: ArgGetItemKey<Item<ItemType>> = useCallback((item: Item<ItemType>) => {
        return String(item.value);
    }, []);

    const dndType = propertyInfo?.property.type ?? '';

    const dndConfig = useMemo<ArgInputDndConfig>(() => ({
        allowedDndTypes: acceptsParameters ? acceptsParameters(dndType) : [],
        allowDrop: acceptsParameters && !value?.parameter && !readOnly,
        renderDroppedItem: ({ type, data }: { type: string; data: any }) =>
            <DroppedParameter
                item={data}
                onRemove={() => handleParameterChange(type, undefined)}
                readonly={readOnly}
            />,
        onDrop: handleParameterChange,
        droppedItem: value?.parameter && { data: value?.parameter, type: dndType },
        dropPlaceholder: messages.dropParameter,
    }), [acceptsParameters, dndType, handleParameterChange, value?.parameter, readOnly]);


    if (!propertyInfo) {
        return <div className={classNames('&', '&-waiting')} data-testid='arg-PickListControl'>
            {propertyInfoLoading?.isRunning &&
                <ThreeDotsLoading className='loading' />
            }
            {!propertyInfoLoading?.isRunning &&
                <ArgMessageRenderer message={messages.noData} />
            }
        </div>;
    }


    return (
        <ArgCombo<Item<ItemType>>
            className={classNames('&')}
            data-testid='arg-PickListControl'
            size='medium'
            cardinality='zeroMany'
            autoFocus={true}
            items={list}
            enableFilter={true}
            placeholder={messages.placeholder}
            getItemLabel='label'
            getItemKey={getItemKey}
            getItemCount='numberOfVertices'
            onChange={handleSelectChange}
            value={selectValues}
            messageValues={{
                propertyDisplayName,
            }}
            dndConfig={dndConfig}
            disabled={readOnly}
            readOnly={readOnly}
        />
    );
};
