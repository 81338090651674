import { useCallback, useContext, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
    ArgButton,
    ArgMenu,
    ArgMenuItem,
    ArgModalContainerContext,
    ClassValue,
    ProgressMonitor,
    useCallbackAsync,
    useClassNames,
    useArgNotifications,
} from 'src/components/basic';
import { downloadFile } from 'src/utils/file';
import WebhookConnector from '../connectors/webhook-connector';
import { DetailedWebHook } from '../models/detailed-webhooks';
import { CreateWebhookModal } from './components/create-webhook-modal/create-webhook-modal';
import { WebhooksActivationModal } from './components/webhooks-activation-modal/webhooks-activation-modal';
import { WebhooksDeletionModal } from './components/webhooks-deletion-modal/webhooks-deletion-modal';
import { useHasPermission } from '../../contexts/user-permission';
import { SettingsPermissions } from '../permissions/permissions';

import './webhook-actions-menu.less';

const messages = defineMessages({
    edit: {
        id: 'settings.webhooks.menu.edit',
        defaultMessage: 'Edit',
    },
    delete: {
        id: 'settings.webhooks.menu.delete',
        defaultMessage: 'Delete',
    },
    activate: {
        id: 'settings.webhooks.menu.activate',
        defaultMessage: 'Activate',
    },
    deactivate: {
        id: 'settings.webhooks.menu.deactivate',
        defaultMessage: 'Deactivate',
    },
    export: {
        id: 'settings.webhooks.menu.export',
        defaultMessage: 'Export',
    },
    exportError: {
        id: 'settings.webhooks.menu.exportError',
        defaultMessage: 'An error occurred while exporting the webhook',
    },
    webhookExportPrefix: {
        id: 'settings.webhooks.menu.webhookExportFileName',
        defaultMessage: 'webhook',
    },
});

export interface WebhookActionsMenuProps {
    webhook: DetailedWebHook;
    className?: ClassValue;
    onActionSuccess?: () => void;
}

export const WebhookActionsMenu = ({ webhook, className, onActionSuccess }: WebhookActionsMenuProps) => {
    const classNames = useClassNames('settings-webhook-actions-menu');
    const [visible, setVisible] = useState(false);

    const modalContainer = useContext(ArgModalContainerContext);
    const intl = useIntl();
    const notifications = useArgNotifications();

    const canEditWebhooks = useHasPermission<SettingsPermissions>('admin.webhook.edition');
    const canExportWebhooks = useHasPermission<SettingsPermissions>('admin.webhook.export');

    const handleEditWebhook = useCallback(() => {
        modalContainer.open('settings-webhook-create', <CreateWebhookModal
            webhook={webhook}
            onClose={() => modalContainer.close('settings-webhook-create')}
            onSuccess={onActionSuccess}
        />);
    }, [modalContainer, onActionSuccess, webhook]);

    const handleDeleteWebhook = useCallback(() => {
        modalContainer.open('settings-webhook-delete', <WebhooksDeletionModal
            webhooks={[webhook]}
            onClose={() => modalContainer.close('settings-webhook-delete')}
            onSuccess={onActionSuccess}
        />);
    }, [modalContainer, onActionSuccess, webhook]);

    const handleToggleWebhook = useCallback((targetStatus: boolean) => {
        modalContainer.open('settings-webhook-activation', <WebhooksActivationModal
            webhooks={[webhook]}
            targetStatus={targetStatus}
            onClose={() => modalContainer.close('settings-webhook-activation')}
            onSuccess={onActionSuccess}
        />);
    }, [modalContainer, onActionSuccess, webhook]);

    const handleActivateWebhook = useCallback(() => {
        handleToggleWebhook(true);
    }, [handleToggleWebhook]);

    const handleDeactivateWebhook = useCallback(() => {
        handleToggleWebhook(false);
    }, [handleToggleWebhook]);

    const [handleExportWebhook] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const rawWebhook = await WebhookConnector.getInstance().exportWebhook(webhook.id, progressMonitor);
            downloadFile(`${intl.formatMessage(messages.webhookExportPrefix)}-${rawWebhook.name}-${new Date().getTime()}.json`, JSON.stringify(rawWebhook, null, 2), 'application/json');
            onActionSuccess?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.exportError }, error as Error);
            throw error;
        }
    }, [intl, notifications, onActionSuccess, webhook.id]);

    const actions = useMemo(() => [
        {
            key: 'edit',
            label: messages.edit,
            onClick: handleEditWebhook,
            hidden: !canEditWebhooks,
        },
        {
            key: 'activate',
            label: messages.activate,
            onClick: handleActivateWebhook,
            hidden: webhook.isEnabled || !canEditWebhooks,
        },
        {
            key: 'deactivate',
            label: messages.deactivate,
            onClick: handleDeactivateWebhook,
            hidden: !webhook.isEnabled || !canEditWebhooks,
        },
        {
            key: 'export',
            label: messages.export,
            onClick: handleExportWebhook,
            hidden: !canExportWebhooks,
        },
        {
            key: 'delete',
            label: messages.delete,
            onClick: handleDeleteWebhook,
            hidden: !canEditWebhooks,
        },
    ], [canEditWebhooks, canExportWebhooks, handleActivateWebhook, handleDeactivateWebhook, handleDeleteWebhook, handleEditWebhook, handleExportWebhook, webhook.isEnabled]);

    const actionsMenu = (
        <ArgMenu>
            {actions.map((action) => {
                if (action.hidden) {
                    return null;
                }

                return (
                    <ArgMenuItem
                        key={action.key}
                        onClick={async () => {
                            await action.onClick();
                            setVisible(!visible);
                        }}
                        label={action.label}
                    />
                );
            })}
        </ArgMenu>
    );

    return (
        <ArgButton
            className={classNames(className, '&-button')}
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={visible}
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
        />
    );
};
