import React from 'react';

import { ClassValue, useClassNames } from '../../../../basic';

import './color-palette-picker-default-colors.less';

interface colorPalettePickerDefaultColorsProps {
    className?: ClassValue;
    hex?: string;
    defaultColors?: string[];
    onChange?: (color: string) => void;
}

export const ColorPalettePickerDefaultColors = ({
    className,
    hex,
    defaultColors,
    onChange,
}: colorPalettePickerDefaultColorsProps) => {
    const classNames = useClassNames('color-palette-picker-default-colors');

    const isSameHexs = (hex1?: string, hex2?: string) => {
        const formattedHex1 = hex1?.toLowerCase();
        const formattedHex2 = hex2?.toLowerCase();

        return formattedHex1 === formattedHex2;
    };

    const style = (currentHex?: string, hex?: string) => {
        const backgroundColor = hex;

        return {
            backgroundColor,
        };
    };

    if (!defaultColors || defaultColors.length === 0) {
        return null;
    }

    return (
        <div className={classNames('&', className)}>
            {defaultColors.map((defaultColor) => {
                return (
                    <span
                        className={classNames('&-item')}
                        key={defaultColor}
                        style={style(hex, defaultColor)}
                        onClick={() => onChange?.(defaultColor)}
                    >
                        {isSameHexs(hex, defaultColor) && (
                            <span className={classNames('&-item-selected')} />
                        )}
                    </span>
                );
            })}
        </div>
    );
};
