import { useCallback, useMemo } from 'react';

import { ArgTable2Column } from 'src/components/basic/arg-table/arg-table2';
import { ConfigurationPath } from 'src/components/basic/utils/configurations';
import { useUserConfiguration } from './use-user-configuration';

export interface UserConfigTable {
    favoriteSortedColumn?: UserConfigColumnOrder;
}

interface UserConfigColumnOrder {
    key?: string;
    defaultSortOrder?: 'ascend' | 'descend';
}

interface UserArgTable2ConfigurationReturnType<T> {
    handleSortColumnChange: (columnKey?: string, direction?: 'ascend' | 'descend') => void;
    userColumns: ArgTable2Column<T>[];
}

export function useArgTable2Configuration<T>(configurationPath: ConfigurationPath, columns: ArgTable2Column<T>[]) {
    const defaultTableUserConfig = useMemo<UserConfigTable>(() => {
        const ret: UserConfigTable = {
            favoriteSortedColumn: undefined,
        };

        return ret;
    }, []);

    const [userConfigTable, setUserConfigTable] = useUserConfiguration<UserConfigTable>(`${configurationPath}.table`, defaultTableUserConfig);

    const userColumns = useMemo<ArgTable2Column<T>[]>(() => {
        if (userConfigTable.favoriteSortedColumn?.key !== defaultTableUserConfig.favoriteSortedColumn?.key) {
            const matchedSortedColumn = columns.find(column => column.key && column.key === userConfigTable.favoriteSortedColumn?.key);
            if (matchedSortedColumn) {
                return columns.map(column => {
                    const mappedColumn: ArgTable2Column<T> = {
                        ...column,
                        defaultSortOrder: column.key === matchedSortedColumn.key ? userConfigTable.favoriteSortedColumn?.defaultSortOrder : undefined,
                    };

                    return mappedColumn;
                });
            }
        }

        return columns;
    }, [
        columns,
        defaultTableUserConfig.favoriteSortedColumn?.key,
        userConfigTable.favoriteSortedColumn?.defaultSortOrder,
        userConfigTable.favoriteSortedColumn?.key,
    ]);


    const handleSortColumnChange = useCallback((columnKey?: string, direction?: 'ascend' | 'descend') => {
        if (!configurationPath) {
            return;
        }
        setUserConfigTable((prev) => {
            if (prev.favoriteSortedColumn?.key !== columnKey || prev.favoriteSortedColumn?.defaultSortOrder !== direction) {
                return {
                    ...prev,
                    favoriteSortedColumn: columnKey ? {
                        key: columnKey,
                        defaultSortOrder: direction,
                    } : undefined,
                };
            }

            return prev;
        });
    }, [configurationPath, setUserConfigTable]);

    const ret: UserArgTable2ConfigurationReturnType<T> = {
        handleSortColumnChange: handleSortColumnChange,
        userColumns: userColumns,
    };

    return ret;
}
