import { useCallback, useContext } from 'react';
import { defineMessages } from 'react-intl';

import { ApplicationConfigurations, ApplicationConfigurationsContext } from 'src/contexts/application-configurations';
import { ConfigurationPath, ProgressMonitor, useArgNotifications } from '../components/basic';
import { useUserSettingsState } from '../utils/rt-states/users/use-user-settings';
import { useConfiguration, UseConfigurationReturnType, useConfigurations } from './use-configuration';
import { SettingsConnector } from '../settings/connectors/settings-connector';

const messages = defineMessages({
    loadAppConfigurationsError: {
        id: 'common.app-settings.LoadAppConfigurationsError',
        defaultMessage: 'Failed to load application configurations',
    },
    updateAppConfigurationsError: {
        id: 'common.app-settings.updateAppConfigurationsError',
        defaultMessage: 'Failed to update user app configurations',
    },
});

export function useApplicationConfiguration<T>(
    path: ConfigurationPath,
    initialConfiguration: T | (() => T)
): UseConfigurationReturnType<T> {
    // Whole configurations object
    const configurations = useContext(ApplicationConfigurationsContext);
    if (!configurations) {
        throw new Error('ApplicationConfigurationContext is not defined');
    }

    return useConfiguration(configurations, path, initialConfiguration);
}

export function useApplicationConfigurations():
    readonly [ApplicationConfigurations | undefined, ProgressMonitor | undefined, Error | undefined] {
    const notifications = useArgNotifications();
    const stateObject = useUserSettingsState();

    const onFetchError = useCallback((error: Error) => {
        notifications.snackError({ message: messages.loadAppConfigurationsError }, error);
    }, [notifications]);

    const onSaveError = useCallback((error: Error) => {
        notifications.snackError({ message: messages.updateAppConfigurationsError }, error);
    }, [notifications]);

    const newApplicationConfigurations = useCallback(() => {
        const configurations = new ApplicationConfigurations(SettingsConnector.getInstance(), onFetchError, onSaveError);

        return configurations;
    }, [onFetchError, onSaveError]);

    const ret = useConfigurations(newApplicationConfigurations, stateObject);

    return ret;
}
