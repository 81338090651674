import { defineMessages } from 'react-intl';

import { defineKeyBindings, KeyBindingScopeDescriptor } from '../../components/basic';

const messages = defineMessages({
    structuredSearchPanel: {
        id: 'exploration.keybindings.StructuredSearchPanel',
        defaultMessage: 'Search Panel',
    },

    addObjectType: {
        id: 'exploration.keybindings.AddObjectType',
        defaultMessage: 'Add object type',
    },
    exploreResults: {
        id: 'exploration.keybindings.ExploreResults',
        defaultMessage: 'Explore results',
    },
    clearCriteria: {
        id: 'exploration.keybindings.ClearCriteria',
        defaultMessage: 'Clear criteria',
    },
    structuredSearchClosePopup: {
        id: 'exploration.keybindings.ClosePopup',
        defaultMessage: 'Close popup',
    },
});

export const SCOPE: KeyBindingScopeDescriptor = {
    id: 'exploration.StructuredSearchPanel',
    name: messages.structuredSearchPanel,
};

export const KEY_BINDINGS = defineKeyBindings({

    // Structured Search
    addObjectType: {
        id: 'exploration.structuredSearch.AddObjectType',
        scope: SCOPE,
        name: messages.addObjectType,
        defaultKeys: 'alt+A',
    },
    exploreResults: {
        id: 'exploration.structuredSearch.ExploreResults',
        scope: SCOPE,
        name: messages.exploreResults,
        defaultKeys: 'alt+E',
    },
    clearCriteria: {
        id: 'exploration.structuredSearch.ClearCriteria',
        scope: SCOPE,
        name: messages.clearCriteria,
        defaultKeys: 'alt+D',
    },
    closePopup: {
        id: 'exploration.structuredSearch.ClosePopup',
        scope: SCOPE,
        name: messages.structuredSearchClosePopup,
        defaultKeys: 'Escape',
        locked: true,
    },

});

