import { defineMessages } from 'react-intl';

import { ArgCreateModal, ProgressMonitor, useCallbackAsync, useClassNames, useArgNotifications } from 'src/components/basic';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';
import { ValuationPolicy } from 'src/settings/models/valuation-policy';

import './edit-valuation-policy-modal.less';

export const messages = defineMessages({
    modalTitle: {
        id: 'settings.edit-valuation-policy-modal.title',
        defaultMessage: 'Edit valuation policy',
    },
    nameField: {
        id: 'settings.edit-valuation-policy-modal.name',
        defaultMessage: 'Name',
    },
    descriptionField: {
        id: 'settings.edit-valuation-policy-modal.field.description',
        defaultMessage: 'Description',
    },
    creationError: {
        id: 'settings.edit-valuation-policy-modal.error',
        defaultMessage: 'An error occurred while updating the valuation policy',
    },
});

export interface EditValuationPolicyModalProps {
    closeModal: () => void;
    onSuccess: () => void;
    valuationPolicy: ValuationPolicy;
}

export function EditValuationPolicyModal(props: EditValuationPolicyModalProps) {
    const {
        closeModal,
        onSuccess,
        valuationPolicy,
    } = props;

    const classNames = useClassNames('settings-edit-valuation-policy-modal');
    const notifications = useArgNotifications();

    const [handleConfirm] = useCallbackAsync(async (progressMonitor: ProgressMonitor, name: string, description: string) => {
        try {
            await explorationSettingsConnector.putValuationPolicy(valuationPolicy.id, {
                ...valuationPolicy,
                name,
                description,
            }, progressMonitor);

            onSuccess();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.creationError }, error as Error);

            throw error;
        }
    }, [onSuccess, valuationPolicy]);

    return (
        <ArgCreateModal
            className={classNames('&')}
            onClose={closeModal}
            onConfirm={handleConfirm}
            title={messages.modalTitle}
            nameFieldLabel={messages.nameField}
            descriptionFieldLabel={messages.descriptionField}
            initialName={valuationPolicy.name}
            initialDescription={valuationPolicy.description}
        />
    );
}
