import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { first } from 'lodash';

import { Action } from '../../../../utils/actions/action';
import { HistoryManager } from '../../../../utils/history-manager';
import { ActionsEngine } from '../../../../utils/actions/actions-engine';
import { ProgressMonitor, SelectionProvider } from '../../../../components/basic';
import { FormContainer, FormDocument, FormElement, FormTab } from '../../../../components/common/forms/model';
import { FormRepository } from './form-actions-engine';

export const REMOVE_FORM_PAGE_ELEMENTS_ACTION = Symbol('Form:RemoveFormPageElements');

export const messages = defineMessages({
    removeFormPageElements: {
        id: 'exploration.forms.actions.remove-form-page-elements.TaskName',
        defaultMessage: '{count, plural, =1 {Remove a page} other {Remove {count} pages}}',
    },
    undoRemoveFormPageElements: {
        id: 'exploration.forms.actions.remove-form-page-elements.Undo',
        defaultMessage: 'Cancel {count, plural, =1 {remove a page} other {remove {count} pages}}',
    },
});

export function createFormRemovePageElements(
    container: FormContainer,
    formElements: FormTab[],
    selectionProvider: SelectionProvider<FormElement> | undefined
): Action<FormRepository, void> {
    let previousValue: FormDocument;

    return {
        type: REMOVE_FORM_PAGE_ELEMENTS_ACTION,

        renderEntry: function ChangeStepActionTitle(undo: boolean) {
            return (
                <FormattedMessage
                    {...(undo) ? messages.undoRemoveFormPageElements : messages.removeFormPageElements}
                    values={{
                        count: formElements.length,
                    }}
                />
            );
        },
        action: async (
            historyManager: HistoryManager<ActionsEngine<FormRepository>>,
            actionsEngine: ActionsEngine<FormRepository>,
            progressMonitor: ProgressMonitor
        ) => {
            progressMonitor.beginTask(messages.removeFormPageElements, 1, {
                count: formElements.length,
            });

            if (!formElements.length) {
                return;
            }

            previousValue = actionsEngine.repository.formDocument;

            actionsEngine.repository.removePages(container, formElements);
            selectionProvider?.set(first(formElements)!, 'form-remove-page-elements-action');
        },
        reverseAction: async (
            historyManager: HistoryManager<ActionsEngine<FormRepository>>,
            actionsEngine: ActionsEngine<FormRepository>,
            progressMonitor: ProgressMonitor
        ) => {
            progressMonitor.beginTask(messages.undoRemoveFormPageElements, 1, {
                count: formElements.length,
            });

            actionsEngine.repository.setDocument(previousValue);

            selectionProvider?.clear('form-remove-page-elements-action');
        },
    };
}
