import { defineMessages } from 'react-intl';

import { ArgButton, ArgMenu, ArgMenuItem, ClassValue } from 'src/components/basic';
import { FAKE_TAB_ID } from '../../utils';
import { FormTab } from 'src/components/common/forms/model';

const messages = defineMessages({
    rename: {
        id: 'exploration.forms.form-pages-panel.Rename',
        defaultMessage: 'Rename',
    },
    addAbove: {
        id: 'exploration.forms.form-pages-panel.AddAbove',
        defaultMessage: 'Add a page above',
    },
    addBelow: {
        id: 'exploration.forms.form-pages-panel.AddBelow',
        defaultMessage: 'Add a page below',
    },
    delete: {
        id: 'exploration.forms.form-pages-panel.Delete',
        defaultMessage: 'Delete',
    },
});

export interface FormPagesPanelItemMenuProps {
    className: ClassValue;
    tab: FormTab;
    index: number;
    setEditMode: (tab: FormTab) => void;
    handleRemovePage: (id: string) => void;
}

export function FormPagesPanelItemMenu(props: FormPagesPanelItemMenuProps) {
    const {
        className,
        tab,
        index,
        setEditMode,
        handleRemovePage,
    } = props;

    const isFirstPage = index === 0;

    return (
        <ArgButton
            className={className}
            type='ghost'
            icon='icon-options'
            popover={
                <ArgMenu>
                    {(tab.id !== FAKE_TAB_ID) && (
                        <ArgMenuItem
                            key='rename'
                            onClick={() => setEditMode(tab)}
                            label={messages.rename}
                        />
                    )}
                    {!isFirstPage && (
                        <ArgMenuItem
                            key='delete'
                            onClick={() => handleRemovePage(tab.id)}
                            label={messages.delete}
                        />
                    )}
                </ArgMenu>
            }
            popoverTrigger='click'
            popoverPlacement='bottomLeft'
        />
    );
}
