import React from 'react';
import classNames from 'classnames';
import { FormatDateOptions, IntlShape } from 'react-intl';

import { EditorProps } from '../controls/controls-type';
import { ArgInputDateTime, ClassValue, DateTimeMode, dayjs } from '../../basic';
import { DataType } from '../data-types';

const DATE_DEFAULT_FORMAT: FormatDateOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
};
const TIME_DEFAULT_FORMAT: FormatDateOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
};
const DATE_TIME_DEFAULT_FORMAT: FormatDateOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
};

interface InputDateEditorProps extends EditorProps<any> {
    className?: ClassValue;
}

export function InputDateEditor(props: InputDateEditorProps) {
    const {
        className,
        readOnly,
        value,
        onChange,
        autoFocus,
        state,
        propertyDisplayName,
        propertyType,
        size = 'medium',
        placeholder,
        clearable,
    } = props;

    const momentValue = value ? dayjs(value) : undefined;

    let _placeholder: typeof placeholder = undefined;
    if (placeholder === null) {
        _placeholder = null;
    } else if (placeholder === undefined) {
        _placeholder = propertyDisplayName;
    } else {
        _placeholder = placeholder;
    }

    let dateTimeMode: DateTimeMode;
    if (propertyType === DataType.Date) {
        dateTimeMode = DateTimeMode.DateOnly;
    } else {
        dateTimeMode = DateTimeMode.DateAndTime;
    }

    return <ArgInputDateTime
        readOnly={readOnly}
        className={classNames(className)}
        value={momentValue}
        placeholder={_placeholder}
        type='ghost'
        autoFocus={autoFocus}
        clearable={!readOnly && clearable}
        onChange={onChange}
        state={state}
        size={size}
        dateTimeMode={dateTimeMode}
    />;
}

export function DateToText(value: any, intl: IntlShape) {
    if (!value) {
        return undefined;
    }

    const text = intl.formatDate(value, DATE_DEFAULT_FORMAT);

    return text;
}

export function DateTimeToText(value: any, intl: IntlShape) {
    if (!value) {
        return undefined;
    }

    const text = intl.formatDate(value, DATE_TIME_DEFAULT_FORMAT);

    return text;
}

export function TimeToText(value: any, intl: IntlShape) {
    if (!value) {
        return undefined;
    }

    const text = intl.formatDate(value, TIME_DEFAULT_FORMAT);

    return text;
}

