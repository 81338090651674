import { omit, without } from 'lodash';

import { ConnectorRequestInit } from '../../utils/connector';
import { AddPolicyDTO, GetPolicyResponse, Policy, PolicyId } from '../models/dtoApi';
import { immutableSet, ProgressMonitor } from 'src/components/basic';
import { ContextualVariable } from 'src/exploration/model/contextual-variable';
import { UniverseId } from 'src/exploration/model/universe';
import { PropertyObject } from '../models/policy';
import { BaseConnector } from '../../utils/connectors/base-connector';
import { getDataExplorationApi } from 'src/utils/connectors/api-url';
import { ValuationPolicy, ValuationPolicyId, ValuationPolicyPostRequest } from '../models/valuation-policy';
import { FormPolicy, FormPolicyId, FormPolicyPostRequest } from '../models/form-policy';
import { mapFormPolicy, mapPolicy, mapUserProfileField, mapValuationPolicy } from './mappers';
import { UserProfileField } from 'src/model/user-metadata';

const SUPPORT_LEGACY = false;
const REMOVE_ID = true;

class DataExplorationSettingsConnector extends BaseConnector {
    private static instance: DataExplorationSettingsConnector;

    static getInstance(): DataExplorationSettingsConnector {
        if (!DataExplorationSettingsConnector.instance) {
            DataExplorationSettingsConnector.instance = new DataExplorationSettingsConnector('settings', getDataExplorationApi());
        }

        return DataExplorationSettingsConnector.instance;
    }

    async getPolicies(
        universeId: UniverseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Policy[]> {
        const url = '/data-access-policies';
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const response: GetPolicyResponse = await this.request(
            url,
            options,
            progressMonitor
        );

        const policies = response[universeId]?.vectorPolicies || [];

        const ret = policies.map(mapPolicy);

        return ret;
    }

    async getPolicy(
        policyId: PolicyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Policy> {
        const url = `/data-access-policies/${encodeURIComponent(policyId)}`;
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const response = await this.request(url, options, progressMonitor);

        const policy = mapPolicy(response);

        let patchedPolicy = policy;
        if (SUPPORT_LEGACY) {
            /*           policy.statement.Actions[0] = {
                InferenceDetection: false,
                Targets: [{ object: { 'Mission': 'profile:<Missions', '_kind': 'Vertex' } }],
                Effects: [],
            };
*/
            policy.statement.Actions.forEach((action, actionIndex) => {
                action.Targets.forEach((target, targetIndex) => {
                    if (!target?.object?._kind && target?.and && target?.or) {
                        return;
                    }

                    const keys = without(Object.keys(target.object || {}), '_kind', '_type');
                    if (keys.length !== 1) {
                        return;
                    }

                    patchedPolicy = immutableSet(patchedPolicy, `statement.Actions[${actionIndex}].Targets[${targetIndex}]`, {
                        and: [
                            {
                                object: omit(target.object!, keys[0]),
                            },
                            {
                                object: {
                                    [keys[0]]: (target.object as PropertyObject)[keys[0]],
                                },
                            },
                        ],
                    });
                });
            });
        }

        return patchedPolicy;
    }


    async editPolicy(
        policyId: string,
        newPolicy: Policy,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        let patchedPolicy = newPolicy;
        if (SUPPORT_LEGACY) {
            patchedPolicy.statement.Actions.forEach((action, actionIndex) => {
                action.Targets.forEach((target, targetIndex) => {
                    if (!target?.and && target?.or && target.and?.length !== 2) {
                        return;
                    }
                    const keys = Object.keys(target?.and?.[1]?.object || {});
                    if (keys.length !== 1 || keys[0] === '_kind' || keys[0] === '_type') {
                        return;
                    }

                    patchedPolicy = immutableSet(patchedPolicy, `statement.Actions[${actionIndex}].Targets[${targetIndex}]`, {
                        object: {
                            ...target.and![0].object,
                            [keys[0]]: (target.and![1].object as PropertyObject)[keys[0]],
                        },
                    });
                });
            });
        }
        if (REMOVE_ID) {
            patchedPolicy.scopes.forEach((scope, scopeIndex) => {
                if (!(scope as any).id) {
                    return;
                }
                patchedPolicy = immutableSet(patchedPolicy, ['scopes', scopeIndex], omit(scope, 'id'));
            });
        }
        const url = `/data-access-policies/${encodeURIComponent(policyId)}`;
        const options = {
            method: 'PUT',
            json: patchedPolicy,
        };

        await this.request(url, options, progressMonitor);
    }

    async addPolicy(
        addPolicyPayload: AddPolicyDTO,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/data-access-policies';
        const options = {
            method: 'POST',
            json: addPolicyPayload,
        };

        await this.request(url, options, progressMonitor);
    }

    async deletePolicy(
        policyId: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/data-access-policies/${encodeURIComponent(policyId)}`;
        const options = {
            method: 'DELETE',
        };
        await this.request(url, options, progressMonitor);
    }

    async changePolicyActivationStatus(
        policyEnabled: boolean,
        policyId: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/data-access-policies/${encodeURIComponent(policyId)}`;
        const options = {
            method: 'PATCH',
            json: {
                comment: 'enable or disable the policy',
                changes: [
                    {
                        op: 'replace',
                        path: '/enabled',
                        value: !policyEnabled,
                    },
                ],
            },
            headers: { 'Content-Type': 'application/json-patch+json' },
            verifyJSONResponse: true,
        };

        await this.request(url, options, progressMonitor);
    }

    async patchPolicyNameAndDescription(
        policyId: string,
        name: string,
        description: string | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/data-access-policies/${encodeURIComponent(policyId)}`;
        const jsonPayload = {
            comment: 'Edit the policy\'s name and description',
            changes: [
                {
                    op: 'replace',
                    path: '/name',
                    value: name,
                },
                {
                    op: 'replace',
                    path: '/description',
                    value: description || null,
                },
            ],
        };
        const options = {
            method: 'PATCH',
            json: jsonPayload,
            headers: { 'Content-Type': 'application/json-patch+json' },
            verifyJSONResponse: true,
        };
        await this.request(url, options, progressMonitor);
    }

    async publishPolicy(
        policyId: string,
        operation: 'Publish' | 'CancelChanges',
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/data-access-policies/${encodeURIComponent(policyId)}`;
        const options = {
            method: 'POST',
            params: {
                operation,
            },
        };
        await this.request(url, options, progressMonitor);
    }

    async reorderPolicies(
        orderedPolicyIds: string[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/data-access-policies/order';
        const options = {
            method: 'POST',
            json: {
                policyIds: orderedPolicyIds,
                selectedPolicyId: null,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async getContextualVariables(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ContextualVariable[]> {
        const url = '/contextual-variables';
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const result = await this.request(url, options, progressMonitor);

        return result.variables || [];
    }

    async getUserProfileFields(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<UserProfileField[]> {
        const url = '/user-profiles/fields';
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };


        const result = await this.request(url, options, progressMonitor);

        const ret = (result.fields as (any[] | undefined) || []).map((raw) => {
            const ret = mapUserProfileField(raw);

            return ret;
        });

        return ret;
    }

    async putValuationPolicy(
        id: ValuationPolicyId,
        valuationPolicy: ValuationPolicy,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/data-valuation-policies/${encodeURIComponent(id)}`;

        const options = {
            method: 'PUT',
            json: valuationPolicy,
        };

        await this.request(url, options, progressMonitor);
    }

    async createValuationPolicy(
        valuationPolicyPostRequest: ValuationPolicyPostRequest,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/data-valuation-policies';

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: valuationPolicyPostRequest,
            verifyJSONResponse: true,
        };

        await this.request(url, options, progressMonitor);
    }

    async getValuationPolicies(
        universeId: UniverseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ValuationPolicy[]> {
        const url = '/data-valuation-policies';

        const result = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const rawValuationPolicies = result[universeId]?.vectorPolicies || [];

        const ret: ValuationPolicy[] = rawValuationPolicies.map(mapValuationPolicy);

        return ret;
    }

    async getValuationPolicy(
        valuationPolicyId: ValuationPolicyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ValuationPolicy> {
        const url = `/data-valuation-policies/${encodeURIComponent(valuationPolicyId)}`;

        const rawValuationPolicy = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const ret: ValuationPolicy = mapValuationPolicy(rawValuationPolicy);

        return ret;
    }

    async deleteValuationPolicy(
        valuationPolicyId: ValuationPolicyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/data-valuation-policies/${encodeURIComponent(valuationPolicyId)}`;

        const options: ConnectorRequestInit = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    async editValuationPolicy(
        valuationPolicyId: ValuationPolicyId,
        newPolicy: ValuationPolicy,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        let patchedPolicy = newPolicy;
        if (REMOVE_ID) {
            patchedPolicy.scopes?.forEach((scope, scopeIndex) => {
                if (!(scope as any).id) {
                    return;
                }
                patchedPolicy = immutableSet(patchedPolicy, ['scopes', scopeIndex], omit(scope, 'id'));
            });
        }
        const url = `/data-valuation-policies/${encodeURIComponent(valuationPolicyId)}`;
        const options = {
            method: 'PUT',
            json: patchedPolicy,
        };

        await this.request(url, options, progressMonitor);
    }

    async publishValuationPolicy(
        valuationPolicyId: ValuationPolicyId,
        operation: 'Publish' | 'CancelChanges',
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/data-valuation-policies/${encodeURIComponent(valuationPolicyId)}`;
        const options = {
            method: 'POST',
            params: {
                operation,
            },
        };
        await this.request(url, options, progressMonitor);
    }

    async changeValuationPolicyActivationStatus(
        policyEnabled: boolean,
        policyId: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/data-valuation-policies/${encodeURIComponent(policyId)}`;
        const options = {
            method: 'PATCH',
            json: {
                comment: 'enable or disable the valuation policy',
                changes: [
                    {
                        op: 'replace',
                        path: '/enabled',
                        value: !policyEnabled,
                    },
                ],
            },
            headers: { 'Content-Type': 'application/json-patch+json' },
            verifyJSONResponse: true,
        };

        await this.request(url, options, progressMonitor);
    }

    async putFormPolicy(
        id: FormPolicyId,
        valuationPolicy: FormPolicy,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/display-template-policies/${encodeURIComponent(id)}`;

        const options = {
            method: 'PUT',
            json: valuationPolicy,
        };

        await this.request(url, options, progressMonitor);
    }

    async createFormPolicy(
        valuationPolicyPostRequest: FormPolicyPostRequest,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/display-template-policies';

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: valuationPolicyPostRequest,
            verifyJSONResponse: true,
        };

        await this.request(url, options, progressMonitor);
    }

    async getFormPolicies(
        universeId: UniverseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<FormPolicy[]> {
        const url = '/display-template-policies';

        const result = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const rawFormPolicies = result[universeId]?.vectorPolicies || [];

        const ret: FormPolicy[] = rawFormPolicies.map(mapFormPolicy);

        return ret;
    }

    async getFormPolicy(
        valuationPolicyId: FormPolicyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<FormPolicy> {
        const url = `/display-template-policies/${encodeURIComponent(valuationPolicyId)}`;

        const rawFormPolicy = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const ret: FormPolicy = mapFormPolicy(rawFormPolicy);

        return ret;
    }

    async deleteFormPolicy(
        valuationPolicyId: FormPolicyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/display-template-policies/${encodeURIComponent(valuationPolicyId)}`;

        const options: ConnectorRequestInit = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    async editFormPolicy(
        valuationPolicyId: FormPolicyId,
        newPolicy: FormPolicy,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        let patchedPolicy = newPolicy;
        if (REMOVE_ID) {
            patchedPolicy.scopes?.forEach((scope, scopeIndex) => {
                if (!(scope as any).id) {
                    return;
                }
                patchedPolicy = immutableSet(patchedPolicy, ['scopes', scopeIndex], omit(scope, 'id'));
            });
        }
        const url = `/display-template-policies/${encodeURIComponent(valuationPolicyId)}`;
        const options = {
            method: 'PUT',
            json: patchedPolicy,
        };

        await this.request(url, options, progressMonitor);
    }

    async publishFormPolicy(
        valuationPolicyId: FormPolicyId,
        operation: 'Publish' | 'CancelChanges',
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/display-template-policies/${encodeURIComponent(valuationPolicyId)}`;
        const options = {
            method: 'POST',
            params: {
                operation,
            },
        };
        await this.request(url, options, progressMonitor);
    }

    async changeFormPolicyActivationStatus(
        policyEnabled: boolean,
        policyId: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/display-template-policies/${encodeURIComponent(policyId)}`;
        const options = {
            method: 'PATCH',
            json: {
                comment: 'enable or disable the display template policy',
                changes: [
                    {
                        op: 'replace',
                        path: '/enabled',
                        value: !policyEnabled,
                    },
                ],
            },
            headers: { 'Content-Type': 'application/json-patch+json' },
            verifyJSONResponse: true,
        };

        await this.request(url, options, progressMonitor);
    }
}

export default DataExplorationSettingsConnector.getInstance();
