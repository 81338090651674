import React, { ReactNode, useContext, useMemo } from 'react';

import { ProgressMonitor, SelectionProvider, useClassNames, useDataProvider } from 'src/components/basic';
import { User } from 'src/model/user';
import { DeletedUsersTable } from '../components/deleted-users-table/deleted-users-table';
import { UsersDataProvider } from '../providers/users-data-provider';
import { UsersAndGroupsStateContext } from '../providers/usersState';

import './users-view.less';

export interface UsersViewProps {
    getUsersMonitor: ProgressMonitor | undefined;
    pageTabs: ReactNode;
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

export const UsersDeletedView: React.FunctionComponent<UsersViewProps> = ({
    pageTabs,
    setActiveTab,
}) => {
    const { deletedUsers } = useContext(UsersAndGroupsStateContext);
    const classNames = useClassNames('settings-users-view');
    const usersDataProvider = useMemo(
        () => new UsersDataProvider(deletedUsers, false),
        [deletedUsers]
    );
    useDataProvider(usersDataProvider);

    const usersSelectionProvider = useMemo(
        () => new SelectionProvider<User>('settings-deleted-user-table', (user) => user.id.toString()),
        []
    );

    return (
        <div className={classNames('&')}>
            {pageTabs}
            <div className={classNames('&-body')}>
                <DeletedUsersTable
                    users={usersDataProvider}
                    selectionProvider={usersSelectionProvider}
                    setActiveTab={setActiveTab}
                />
            </div>
        </div>
    );
};
