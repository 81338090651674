import { ArgIcon, ArgMessageValues, ArgRenderedText, renderText, useClassNames } from 'src/components/basic';

import './notify-box.less';

interface NotifyBoxProps {
    icon: string;
    color: string;
    text: ArgRenderedText;
    subtext: ArgRenderedText;
    messageValues?: ArgMessageValues;
}

export function NotifyBox(props: NotifyBoxProps) {
    const {
        icon, color, text, subtext, messageValues,
    } = props;

    const classNames = useClassNames('notify-box');

    return (
        <div className={classNames('&')} style={{ borderLeftColor: color }}>
            <div className={classNames('&-icon')}>
                <ArgIcon size={30} name={icon} color={color} />
            </div>
            <div>
                <div className={classNames('&-text')}> {renderText(text, messageValues)}</div>
                <div className={classNames('&-subtext')}> {renderText(subtext, messageValues)}</div>
            </div>
        </div>
    );
}
