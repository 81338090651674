import { WebHook } from '../../settings/models/webhook';

export type ContextualVariable = {
    id: string,
    displayName: string,
    path?: string,
    description?: string,
    value?: ContextualVariableValueType,
    type: ContextualVariableType,
    auto?: ContextualVariableAutoConfiguration,
};

export enum ContextualVariableType {
    string = 'string',
    bool = 'bool',
    int = 'int',
    decimal = 'decimal',
    hour = 'hour',
}

export type ContextualVariableValueType = string | number | boolean;

type ContextualVariableAutoConfiguration = {
    refreshDelay: number,
    webHook?: WebHook,
};
