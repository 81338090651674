import { cloneDeep, isEmpty, unset } from 'lodash';

import { DropDownOption, RuleTarget, SchemaObject } from '../../../../models/policy';
import {
    getObjectProperties, getObjectsTypes, isEmptyObject, isUniverseItemObject,
    isUniversePropertyFilter, isUniverseTypeAndPropertyFilter,
} from '../../policy-utils';
import { UniverseItemFilterButton } from '../universe-item-filter/universe-item-filter-button';
import { TargetsAddFilterButtons } from '../targets-add-filter-buttons/targets-add-filter-buttons';
import { DeleteFilterButton } from '../../effects/delete-filter-button';
import { useClassNames } from 'src/components/basic';
import {
    OntologyBaseSchema,
    OntologyLinkType,
    OntologyObjectType,
    OntologyProperty,
} from 'src/settings/universes/ontology/types';
import { UserProfileField } from 'src/model/user-metadata';
import { UniverseItemFilter } from '../universe-item-filter/universe-item-filter';
import { UniversePropertyFilter } from '../universe-property-filter/universe-property-filter';
import { TreeBlocks } from '../../tree/tree-blocks';

import './policy-rule-target.less';

interface PolicyRuleTargetProps<T> {
    ruleTarget: RuleTarget;
    onChange: React.Dispatch<React.SetStateAction<T>>;
    currentPath: string;
    schema: OntologyBaseSchema;
    editable: boolean;
    firstRecursiveCall?: boolean;
    userProfileFields: UserProfileField[];
    externalSchemaObject?: SchemaObject;
    onChangeItemFilter?: (schema: SchemaObject) => void;
}

export default function PolicyRuleTarget<T extends { Targets: RuleTarget[] }>(props: PolicyRuleTargetProps<T>) {
    const {
        ruleTarget,
        currentPath,
        schema,
        editable,
        firstRecursiveCall,
        onChange,
        userProfileFields,
        externalSchemaObject,
        onChangeItemFilter,
    } = props;
    const classNames = useClassNames('policy-rule-target');


    if ((isUniverseItemObject(ruleTarget) || isEmptyObject(ruleTarget)) && !ruleTarget.and && (!isUniversePropertyFilter(ruleTarget) || isEmpty(isUniversePropertyFilter(ruleTarget)))) {
        const object = ruleTarget.object as SchemaObject;
        const objectTypes = getObjectsTypes(object._kind, schema);
        const dropdownOpts = getDropdownOptions(objectTypes);

        return (
            <div key={currentPath} className={classNames('&-universe-item-filter')}>
                <UniverseItemFilter
                    object={object}
                    currentPath={currentPath}
                    dropdownOpts={dropdownOpts}
                    onChange={onChange}
                    editable={editable}
                    onChangeItemFilter={onChangeItemFilter}
                    externalSchemaObject={externalSchemaObject}
                />
                {editable && (
                    <UniverseItemFilterButton currentPath={currentPath} filterObject={object} onChange={onChange} />
                )}

                {editable && !firstRecursiveCall && (
                    <DeleteFilterButton
                        onDeleteRule={() => onChange((currentRule) => deleteTargetRow(currentPath, currentRule))}
                    />
                )}
            </div>
        );
    }

    const propertyObject = isUniversePropertyFilter(ruleTarget);
    if (propertyObject) {
        const object = ruleTarget.object as SchemaObject;

        const objectTypes = getObjectsTypes(object._kind, schema);
        const dropdownOpts = getDropdownOptions(objectTypes);
        const properties = getObjectProperties(object, schema);

        return (
            <div key={currentPath} className={classNames('&-schema-and-property-object')}>
                <UniverseItemFilter
                    object={object}
                    currentPath={currentPath}
                    dropdownOpts={dropdownOpts}
                    pathOfParentFilter={currentPath}
                    onChange={onChange}
                    editable={editable}
                    onChangeItemFilter={onChangeItemFilter}
                    externalSchemaObject={externalSchemaObject}
                />
                <UniversePropertyFilter
                    objKey={propertyObject?.[0].key}
                    objVal={propertyObject?.[0].value}
                    currentPath={currentPath}
                    schema={schema}
                    editable={editable}
                    onChange={onChange}
                    userProfileFields={userProfileFields}
                    properties={properties}
                />

                {editable && !firstRecursiveCall && (
                    <DeleteFilterButton
                        onDeleteRule={() => onChange((currentRule) => deleteTargetRow(currentPath, currentRule))}
                    />
                )}
            </div>
        );
    }

    const optSchemaAndPropertyObj = isUniverseTypeAndPropertyFilter(ruleTarget);
    if (optSchemaAndPropertyObj) {
        const { property, schema: objSchema } = optSchemaAndPropertyObj;

        const objectTypes = getObjectsTypes(objSchema.val._kind, schema);
        const schemaDropdownOpts = getDropdownOptions(objectTypes);
        const properties = getObjectProperties(objSchema.val, schema);

        const [propertyKey, propertyVal] = Object.entries(property.val)[0] || [
            undefined,
            undefined,
        ];

        return (
            <div key={currentPath} className={classNames('&-schema-and-property-object')}>
                <UniverseItemFilter
                    object={objSchema.val}
                    currentPath={`${currentPath}.and[${objSchema.idx}]`}
                    dropdownOpts={schemaDropdownOpts}
                    pathOfParentFilter={currentPath}
                    onChange={onChange}
                    editable={editable}
                    onChangeItemFilter={onChangeItemFilter}
                    externalSchemaObject={externalSchemaObject}
                />

                <UniversePropertyFilter
                    objKey={propertyKey}
                    objVal={propertyVal}
                    currentPath={`${currentPath}.and[${property.idx}]`}
                    editable={editable}
                    onChange={onChange}
                    userProfileFields={userProfileFields}
                    schema={props.schema}
                    properties={properties}
                />

                {editable && !firstRecursiveCall && (
                    <DeleteFilterButton
                        onDeleteRule={() => onChange((currentRule) => deleteTargetRow(currentPath, currentRule))}
                    />
                )}
            </div>
        );
    }
    const borderClassName = firstRecursiveCall
        ? undefined
        : `${editable ? classNames('&-block-container-editable') : classNames('&-block-container')}`;

    if (ruleTarget.or) {
        return (
            <div className={borderClassName}>
                <div className={classNames('&-or-block')}>
                    <TreeBlocks targets={ruleTarget.or} block='or' currentPath={currentPath} onChange={onChange} editable={editable} />
                    <div className={classNames('&-target-sub-block')}>
                        {ruleTarget.or.map((target, index) =>
                            <PolicyRuleTarget
                                key={`${currentPath}.or[${index}]`}
                                ruleTarget={target}
                                currentPath={`${currentPath}.or[${index}]`}
                                schema={schema}
                                editable={editable}
                                onChange={onChange}
                                userProfileFields={userProfileFields}
                                externalSchemaObject={externalSchemaObject}
                                onChangeItemFilter={onChangeItemFilter}
                            />
                        )}
                    </div>
                </div>
                {editable && (
                    <TargetsAddFilterButtons
                        currentPath={`${currentPath}.or`}
                        blockTargets={ruleTarget.or}
                        operator='or'
                        onChange={onChange}
                        schemaObject={externalSchemaObject}
                    />
                )}
            </div>
        );
    }

    if (ruleTarget.and) {
        return (
            <div className={borderClassName}>
                <div className={classNames('&-and-block')}>
                    <TreeBlocks targets={ruleTarget.and} block='and' currentPath={currentPath} onChange={onChange} editable={editable} />
                    <div className={classNames('&-target-sub-block')}>
                        {ruleTarget.and.map((target, index) =>
                            <PolicyRuleTarget
                                key={`${currentPath}.or[${index}]`}
                                ruleTarget={target}
                                currentPath={`${currentPath}.and[${index}]`}
                                schema={schema}
                                editable={editable}
                                onChange={onChange}
                                userProfileFields={userProfileFields}
                                externalSchemaObject={externalSchemaObject}
                                onChangeItemFilter={onChangeItemFilter}
                            />
                        )}
                    </div>
                </div>
                {editable && (
                    <TargetsAddFilterButtons
                        currentPath={`${currentPath}.and`}
                        blockTargets={ruleTarget.and}
                        operator='and'
                        onChange={onChange}
                        schemaObject={externalSchemaObject}
                    />
                )}
            </div>
        );
    }

    return <>?</>;
}

function getDropdownOptions(list: (OntologyObjectType | OntologyLinkType | OntologyProperty)[]) {
    return list.map((element) => {
        const mappedElement: DropDownOption = {
            name: element.name,
            displayName: element.displayName,
        };

        return mappedElement;
    });
}

function isInvalidTarget(target: RuleTarget): boolean {
    if (!target) return true;
    if (!target.and && !target.or) return false;

    if (target.and) {
        if (target.and.length === 0 || target.and.every((t) => !t)) {
            return true;
        } else {
            return target.and.every((t) => isInvalidTarget(t));
        }
    }
    if (target.or) {
        if (target.or.length === 0 || target.or.every((t) => !t)) {
            return true;
        } else {
            return target.or.every((t) => isInvalidTarget(t));
        }
    }

    return true;
}

function cleanUpTarget(target: RuleTarget): RuleTarget {
    if (target.and) {
        return {
            and: target.and.filter((el) => !isInvalidTarget(el)).map(cleanUpTarget),
        };
    }
    if (target.or) {
        return {
            or: target.or.filter((el) => !isInvalidTarget(el)).map(cleanUpTarget),
        };
    }

    return target;
}

function deleteTargetRow<T extends { Targets: RuleTarget[] }>(currentPath: string, currentRule: T): T {
    const newRule = cloneDeep(currentRule);
    unset(newRule, currentPath);

    return {
        ...newRule,
        Targets: newRule.Targets.map((target) => {
            const newTarget = cleanUpTarget(target);
            if (newTarget.and && newTarget.and.length === 1) {
                return newTarget.and[0];
            }
            if (newTarget.or && newTarget.or.length === 1) {
                return newTarget.or[0];
            }

            return newTarget;
        }),
    };
}
