import { Dispatch, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';

import {
    ArgCheckbox,
    ArgFormLabel,
    ArgInputText,
    ArgModal,
    ArgSliderInput,
    ArgSwitch,
    useClassNames, useArgNotifications,
} from 'src/components/basic';
import { ConstraintItem, FormatItem, InputType, ONTOLOGY_PROPERTY_INPUT_TYPES, messages, TypeItem } from '../const';
import { PropertyInputType } from '../property-input-type';
import { FullOntology, FullOntologyLinkType, FullOntologyObjectType, OntologyProperty } from '../../../types';
import { getBaseType } from '../utils';
import { VertexOrEdge } from 'src/exploration/hooks/use-graph-style-customisation';
import { Environment } from 'src/utils/environment';
import { PropertyPreview } from '../property-preview';
import { PropertyTypeAndConstraint } from '../property-type-and-constraints';
import { AddEditOntology, AddEditOntologyProperty } from '../../../../../models/dtoApi';
import ontologiesConnector from '../../../../../connectors/ontologies-connector';
import { PropertyFormModal } from '../property-form-modal/property-form-modal';
import { AddModalFooter } from '../../../../../common-components/add-modal-footer';
import { UserInputType } from '../../../../../../model/user-metadata';

import './property-modal.less';

export interface CreatePropertyModalProps {
    closeModal: () => void;
    propertyList: OntologyProperty[];
    edgeOrVertex: FullOntologyLinkType | FullOntologyObjectType;
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
    propertyOf: VertexOrEdge;
}

export function CreatePropertyModal(props: CreatePropertyModalProps) {
    const {
        closeModal,
        propertyList,
        edgeOrVertex,
        ontology,
        setOntology,
        propertyOf,
    } = props;

    const intl = useIntl();
    const classNames = useClassNames('property-modal');
    const notifications = useArgNotifications();

    const [type, setType] = useState<TypeItem | undefined>(TypeItem.boolean);
    const [description, setDescription] = useState<string>('');
    const [format, setFormat] = useState<FormatItem>();
    const [constraint, setConstraint] = useState<ConstraintItem>(ConstraintItem.none);
    const [characters, setCharacters] = useState<[number, number]>([10, 40]);
    const [continuousNumber, setContinuousNumber] = useState(false);
    const [isHeadProperty, setIsHeadProperty] = useState(false);
    const [inputType, setInputType] = useState<InputType | UserInputType | undefined>(InputType.freeInput);
    const [displayPropertyName, setDisplayPropertyName] = useState<string>('');
    const [isTitle, setIsTitle] = useState(false);
    const [isRequired, setIsRequired] = useState(false);
    const [isMultivalued, setIsMultivalued] = useState(false);
    const [loading, setLoading] = useState(false);
    const [displayFinalOptionOnly, setDisplayFinalOptionOnly] = useState(false);
    const [optionsList, setOptionsList] = useState([
        { value: '', id: 1 },
        { value: '', id: 2 },
    ]);
    const [searchWeight, setSearchWeight] = useState<number>(100);

    const handleSubmit = async () => {
        const baseType = getBaseType(type);
        if (!baseType) return;

        const newProperty: AddEditOntologyProperty = {
            displayName: displayPropertyName,
            baseType: baseType,
            constraint:
                inputType === InputType.singleSelect
                    ? {
                        fixedValues: optionsList.map(({ value }) => ({ value })),
                        hideFullPath: displayFinalOptionOnly,
                    }
                    : undefined,
            pathDefinition: {
                separator: '/',
            },
            isTitle: isTitle,
            isContinuous:
                continuousNumber || type === TypeItem.date || type === TypeItem.dateAndTime,
            isMandatory: isRequired,
            clientMetadata: { [Environment.appId]: { description } },
            isHeadProperty: isHeadProperty,
            isMultivalued: isMultivalued,
            weight: searchWeight / 100,
        };

        const properties = isTitle ? edgeOrVertex.properties.map(property => {
            const ret: OntologyProperty = {
                ...property,
                isTitle: false,
            };

            return ret;
        }) : edgeOrVertex.properties;

        const editOntologyProps: AddEditOntology = {
            ontologyId: ontology.id,
            name: edgeOrVertex.name,
            newDisplayName: edgeOrVertex.displayName,
            newProperties: [...properties, newProperty],
        };
        setLoading(true);
        try {
            if (propertyOf === VertexOrEdge.Vertex) {
                await ontologiesConnector.editOntologyObject(editOntologyProps);
            } else {
                await ontologiesConnector.editOntologyEdge(editOntologyProps);
            }
            const newOntology = await ontologiesConnector.getFullOntology(ontology.id);
            setOntology(newOntology);
            setLoading(false);
        } catch (error) {
            notifications.snackError({ message: messages.addPropertyErrorMsg }, error as Error);
            setLoading(false);
        }
    };

    const resetState = () => {
        setDisplayPropertyName('');
        setType(TypeItem.boolean);
        setFormat(undefined);
        setConstraint(ConstraintItem.none);
        setIsHeadProperty(false);
        setIsMultivalued(false);
        setContinuousNumber(false);
        setInputType(InputType.freeInput);
        setIsTitle(false);
        setIsRequired(false);
        setOptionsList([
            { value: '', id: 1 },
            { value: '', id: 2 },
        ]);
        setDescription('');
        setDisplayFinalOptionOnly(false);
        setSearchWeight(100);
    };

    const disabled =
        !displayPropertyName ||
        !type ||
        propertyList.filter((prop) => prop.displayName === displayPropertyName).length > 0 ||
        loading;

    return (
        <ArgModal
            size='large'
            title={messages.title}
            onClose={closeModal}
            className={classNames('&-modal')}
            footer={(
                <AddModalFooter
                    okButtonDisabled={disabled}
                    onClose={closeModal}
                    onSave={() => {
                        handleSubmit();
                        resetState();
                        closeModal();
                    }}
                    onSaveAndAddAnother={() => {
                        handleSubmit();
                        resetState();
                        document.getElementById('propertyName')?.focus();
                    }}
                />
            )}
        >
            <PropertyFormModal>
                <div className={classNames('&-config')}>
                    <ArgFormLabel
                        className={classNames('&-config-name')}
                        propertyName={messages.fieldName}
                        description={messages.fieldNameSubtitle}
                    >
                        <ArgInputText
                            value={displayPropertyName}
                            onChange={(input) => setDisplayPropertyName(input ?? '')}
                            autoFocus={true}
                            id='propertyName'
                        />
                    </ArgFormLabel>
                </div>
                <div>
                    <ArgCheckbox
                        value={isTitle}
                        onChange={(value) => setIsTitle(value)}
                        size='small'
                        label={messages.isTitle}
                        messageValues={{ fieldName: <strong>{edgeOrVertex.displayName}</strong> }}
                        className={classNames('&-is-title')}
                    />
                    <p className={classNames('&-is-title-description')}>
                        {intl.formatMessage(messages.isTitleDescription)}
                    </p>
                </div>
                <PropertyTypeAndConstraint
                    type={type}
                    setType={setType}
                    setFormat={setFormat}
                    constraint={constraint}
                    setConstraint={setConstraint}
                    characters={characters}
                    setCharacters={setCharacters}
                    continuousNumber={continuousNumber}
                    setContinuousNumber={setContinuousNumber}
                    inEditModal={false}
                />
                <ArgSwitch
                    checked={isRequired}
                    label={intl.formatMessage(messages.fieldRequired)}
                    onChange={(value) => setIsRequired(value)}
                    size='small'
                    className={classNames('&-switch-isRequired')}
                />
                <ArgSwitch
                    value={isMultivalued}
                    onChange={(value) => setIsMultivalued(value)}
                    label={intl.formatMessage(messages.isMultivalue)}
                    size='small'
                    className={classNames('&-switch-isMultivalue')}
                />
                <PropertyInputType
                    type={type}
                    inputType={inputType}
                    setInputType={setInputType}
                    inputs={Object.keys(ONTOLOGY_PROPERTY_INPUT_TYPES) as InputType[]}
                    format={format}
                    setFormat={setFormat}
                    optionsList={optionsList}
                    setOptionsList={setOptionsList}
                    displayFinalOptionOnly={displayFinalOptionOnly}
                    setDisplayFinalOptionOnly={setDisplayFinalOptionOnly}
                />
                <ArgFormLabel propertyName={messages.fieldSearchWeight}>
                    <ArgSliderInput
                        min={0}
                        max={400}
                        onChange={(value) => setSearchWeight(value)}
                        step={1}
                        value={searchWeight}
                        unitSymbol='%'
                        className={classNames('&-weight-slider')}
                    />
                </ArgFormLabel>
                <ArgFormLabel propertyName={messages.fieldMessage} description={messages.fieldMessageDescription}>
                    <ArgInputText
                        value={description}
                        onChange={(input) => setDescription(input ?? '')}
                    />
                </ArgFormLabel>
                <ArgSwitch
                    checked={isHeadProperty}
                    onChange={() => {
                        setIsHeadProperty(!isHeadProperty);
                    }}
                    label={intl.formatMessage(messages.fieldPriority)}
                    className={classNames('&-switch')}
                />
                <PropertyPreview
                    inputType={inputType}
                    propertyName={displayPropertyName}
                    optionsList={optionsList}
                    displayFinalOptionOnly={displayFinalOptionOnly}
                    type={type}
                />
            </PropertyFormModal>
        </ArgModal>
    );
}
