import { BaseFormElement, FormContainer, FormElement } from './model';

export function isFormContainer(element: BaseFormElement): element is FormContainer {
    if ('children' in element) {
        return true;
    }

    return false;
}

export function walk(element: FormElement, callback: (element: FormElement) => any): any {
    const nodes: FormElement[] = [element];

    while (nodes.length > 0) {
        const node = nodes.shift()!;

        const ret = callback(node);
        if (ret !== undefined) {
            return ret;
        }

        if (isFormContainer(node)) {
            nodes.push(...node.children);
        }
    }

    return undefined;
}
