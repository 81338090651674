import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Action } from '../../../../utils/actions/action';
import { HistoryManager } from '../../../../utils/history-manager';
import { ActionsEngine } from '../../../../utils/actions/actions-engine';
import { ProgressMonitor } from '../../../../components/basic';
import { FormContainer, FormDocument, FormElement } from '../../../../components/common/forms/model';
import { FormRepository } from './form-actions-engine';

export const MOVE_FORM_ELEMENTS_ACTION = Symbol('Form:MoveFormElements');

export const messages = defineMessages({
    moveFormElements: {
        id: 'exploration.forms.actions.move-form-elements.TaskName',
        defaultMessage: '{count, plural, =1 {Move an element} other {Move {count} elements}}',
    },
    undoMoveFormElements: {
        id: 'exploration.forms.actions.move-form-elements.Undo',
        defaultMessage: 'Cancel {count, plural, =1 {move an element} other {move {count} elements}}',
    },
});

export function createFormMoveElements(
    container: FormContainer,
    formElements: FormElement[],
    index: number | undefined
): Action<FormRepository, void> {
    let previousValue: FormDocument;

    return {
        type: MOVE_FORM_ELEMENTS_ACTION,

        renderEntry: function ChangeStepActionTitle(undo: boolean) {
            return (
                <FormattedMessage
                    {...(undo) ? messages.undoMoveFormElements : messages.moveFormElements}
                    values={{ count: formElements.length }} />
            );
        },
        action: async (
            historyManager: HistoryManager<ActionsEngine<FormRepository>>,
            actionsEngine: ActionsEngine<FormRepository>,
            progressMonitor: ProgressMonitor
        ) => {
            progressMonitor.beginTask(messages.moveFormElements, 1, {
                count: formElements.length,
            });

            if (!formElements.length) {
                return;
            }

            previousValue = actionsEngine.repository.formDocument;

            actionsEngine.repository.add(container, formElements, index);
        },
        reverseAction: async (
            historyManager: HistoryManager<ActionsEngine<FormRepository>>,
            actionsEngine: ActionsEngine<FormRepository>,
            progressMonitor: ProgressMonitor
        ) => {
            progressMonitor.beginTask(messages.undoMoveFormElements, 1, {
                count: formElements.length,
            });

            actionsEngine.repository.setDocument(previousValue);
        },
    };
}
