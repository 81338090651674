import React from 'react';
import { defineMessages } from 'react-intl';

import { ArgTooltip2, useClassNames } from 'src/components/basic';
import { FormComposite, FormCompositeItem } from '../model';
import { FormElementWithLabel } from './form-element-with-label';

import './form-composite-component.less';

const messages = defineMessages({
    compositeName: {
        id: 'common.forms.CompositeName',
        defaultMessage: 'Composite field',
    },
});

export interface FormCompositeProp<T> {
    formComposite: FormComposite;
    vertexPropertiesByName: Record<string, T>;
    renderProperty: (item: FormCompositeItem, type: T) => React.ReactNode;
    readonly?: boolean;
}

export function FormCompositeComponent<T>(props: FormCompositeProp<T>) {
    const {
        formComposite,
        readonly,
        vertexPropertiesByName,
        renderProperty,
    } = props;

    const classNames = useClassNames('form-composite-component');

    const items = formComposite.children.filter((child) => child.type === 'composite-item') as FormCompositeItem[];

    const composites = items.flatMap((compositeItem, index) => {
        const propertyType = vertexPropertiesByName[compositeItem.propertyName];

        if (!propertyType) {
            console.error('Ignore unknown ontology property', compositeItem.propertyName);

            return null;
        }

        const property = renderProperty(compositeItem, propertyType);

        const separator = (
            <div key={`${compositeItem.id}-separator`} className={classNames('&-separator')}>
                <div className={classNames('&-separator-container')}>
                    {compositeItem.separator}
                </div>
            </div>
        );

        return [
            index !== 0 && separator,
            property,
        ];
    });

    let component = (
        <FormElementWithLabel name={formComposite.name || messages.compositeName}>
            <div className={classNames('&', { readonly })}>
                {composites}
            </div>
        </FormElementWithLabel>
    );

    if (formComposite.description && readonly) {
        component = (
            <ArgTooltip2 title={formComposite.description}>
                { /* Use an intermediate div to hold the tooltip reference */}
                <div>
                    {component}
                </div>
            </ArgTooltip2>
        );
    }

    return component;
}
