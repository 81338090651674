import { defineMessages } from 'react-intl';

import { ClassValue, useArgNotifications, useCallbackAsync, useClassNames } from 'src/components/basic';
import { ConfirmModal } from '../../../../components/common/modal2/confirm-modal/confirm-modal';
import { ExternalComponent } from '../../../models/external-component';
import { SettingsConnector } from '../../../connectors/settings-connector';

import './external-component-deletion-modal.less';

const CLASSNAME = 'settings-external-component-deletion-modal';
const messages = defineMessages({
    delete: {
        id: 'settings.external-component.deletion-modal.delete',
        defaultMessage: 'Delete',
    },
    deleteTitle: {
        id: 'settings.external-component.deletion-modal.deleteTitle',
        defaultMessage: 'Delete this external component',
    },
    deleteMessage: {
        id: 'settings.external-component.deletion-modal.deleteMessage',
        defaultMessage: 'Are you sure you want to delete the external component "{name}"?',
    },
    deleteMultipleTitle: {
        id: 'settings.external-component.deletion-modal.deleteMultipleTitle',
        defaultMessage: 'Delete {count} external components',
    },
    deleteMultipleMessage: {
        id: 'settings.external-component.deletion-modal.deleteMultipleMessage',
        defaultMessage: 'Are you sure you want to delete these {count} external components?',
    },
    deleteError: {
        id: 'settings.external-component.deletion-modal.deleteError',
        defaultMessage: 'An error occurred while deleting the {count, plural, one {externalComponent} other {externalComponents}}',
    },
});

export interface ExternalComponentDeletionModalProps {
    className?: ClassValue;
    externalComponents: ExternalComponent[];
    onSuccess?: () => void;
    onClose: () => void;
}

export function ExternalComponentDeletionModal(props: ExternalComponentDeletionModalProps) {
    const {
        className,
        externalComponents,
        onSuccess,
        onClose,
    } = props;

    const classNames = useClassNames(CLASSNAME);
    const notifications = useArgNotifications();

    const [onDelete] = useCallbackAsync(async (progressMonitor) => {
        try {
            const externalComponentsKeys = externalComponents.map((externalComponent) => externalComponent.key);
            if (externalComponentsKeys.length === 0) {
                return;
            }

            if (externalComponentsKeys.length === 1) {
                const externalComponentKey = externalComponentsKeys[0];
                await SettingsConnector.getInstance().deleteExternalComponent(externalComponentKey, progressMonitor);
            } else {
                await SettingsConnector.getInstance().deleteExternalComponents(externalComponentsKeys, progressMonitor);
            }

            onSuccess?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.deleteError }, error as Error, { count: externalComponents.length });
            throw error;
        }
    }, [externalComponents, onSuccess, notifications]);

    const title = props.externalComponents.length > 1 ? messages.deleteMultipleTitle : messages.deleteTitle;
    const message = props.externalComponents.length > 1 ? messages.deleteMultipleMessage : messages.deleteMessage;

    const messageValues = {
        count: props.externalComponents.length,
        name: props.externalComponents.length === 1 ? props.externalComponents[0].name : undefined,
    };

    return (
        <ConfirmModal
            className={classNames('&')}
            type='delete'
            title={title}
            alertMessage={message}
            messageValues={messageValues}
            onConfirm={onDelete}
            onClose={onClose}
            confirmText={messages.delete}
        />
    );
}
