import { useMemo } from 'react';
import { chain, isEmpty } from 'lodash';

const USE_MEMO = false;

const NO_DATA_PROPS: Record<string, any> = {};

function filterDataProps(props: Record<string, any>) {
    if (isEmpty(props)) {
        return NO_DATA_PROPS;
    }

    const dataProps = chain(props).reduce((acc, value, key) => {
        if (value === undefined) {
            return acc;
        }

        if (!key.startsWith('data-')) {
            return acc;
        }

        if (key === 'data-testid') {
            return acc; // the component must define the data-testid itself
        }

        if (!acc) {
            acc = {};
        }

        acc[key] = value;

        return acc;
    }, undefined as Record<string, any> | undefined).value();

    if (!dataProps) {
        return NO_DATA_PROPS;
    }

    return dataProps;
}

function useFilterDataPropsWithMemo(props: Record<string, any>) {
    const dataProps = useMemo<Record<string, any>>(() => {
        const dataProps = filterDataProps(props);

        return dataProps;
    }, [props]);

    return dataProps;
}

export const useFilterDataProps = (USE_MEMO) ? useFilterDataPropsWithMemo : filterDataProps;
