import { defineMessages, useIntl } from 'react-intl';
import { useCallback, useEffect } from 'react';

import { useClassNames } from 'src/components/basic';
import { FormRuleEffect, FormRuleProcessed } from 'src/settings/models/form-policy';
import { FormRuleEffectBlock } from './form-rule-effect-block';
import { FormDisplayTemplates } from 'src/exploration/model/form-display-template';
import { DEFAULT_FORM_TEMPLATE_KEY } from 'src/settings/universes/form/views/form-customisation';

import './form-policy-rule-block-effect.less';

const messages = defineMessages({
    effect: {
        id: 'settings.form-policy-rule-block-effect.effect',
        defaultMessage: 'Effect',
    },
});

interface FormPolicyRuleBlockEffectProps {
  effects: FormRuleEffect[];
  editable: boolean;
  onRuleChange: React.Dispatch<React.SetStateAction<FormRuleProcessed>>;
  forms: FormDisplayTemplates | undefined;
}

export const FormPolicyRuleBlockEffect = ({
    effects,
    editable,
    onRuleChange,
    forms,
}: FormPolicyRuleBlockEffectProps) => {
    const classNames = useClassNames('settings-form-policy-rule-block-effect');
    const intl = useIntl();

    const addEffect = useCallback(() => {
        onRuleChange((currentRule) => {
            return {
                ...currentRule,
                Effects: [...effects, { template: { key: DEFAULT_FORM_TEMPLATE_KEY } }],
            };
        });
    }, [effects, onRuleChange]);

    useEffect(() => {
        if (effects.length === 0) {
            addEffect();
        }
    }, [effects, addEffect]);

    return (
        <>
            <div className={classNames(editable ? '&-title-editable' : '&-title')}>
                {intl.formatMessage(messages.effect)}
            </div>
            {effects.map((effect, index) => {
                return <FormRuleEffectBlock key={index} effect={effect} index={index} onRuleChange={onRuleChange} editable={editable} forms={forms} />;
            })}
        </>
    );
};
