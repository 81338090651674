export const COLORS = [
    '#7BD0E3',
    '#5DA6F0',
    '#3079BD',
    '#6568D6',
    '#9A30EE',
    '#D74289',
    '#C63E5A',
    '#DE841A',
    '#EDB522',
    '#138721',
];

export const WARNING_ORANGE = '#df9112';
