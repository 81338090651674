import { useCallback } from 'react';
import { defineMessages } from 'react-intl';

import { ArgButton, ArgIcon, ProgressMonitor, useArgModalContext, useClassNames } from 'src/components/basic';
import { Group, User } from 'src/model/user';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';
import userGroupsConnector from 'src/settings/connectors/user-groups-connector';

const messages = defineMessages({
    title: {
        id: 'settings.remove-user-from-group-modal.title',
        defaultMessage: 'Remove from group',
    },
    description: {
        id: 'settings.remove-user-from-group.description',
        defaultMessage: 'Are you sure you want to remove "{userName}" from group "{groupName}" ?',
    },
    cancel: {
        id: 'settings.remove-user-from-group.button.cancel',
        defaultMessage: 'Cancel',
    },
    remove: {
        id: 'settings.remove-user-from-group.button.remove',
        defaultMessage: 'Remove',
    },
    close: {
        id: 'settings.remove-user-from-group.close',
        defaultMessage: 'Close',
    },
});

const DELETE_USER_MODAL = 'settings-delete-user-from-group-modal';

export interface UserGroupsRowProps {
    userGroups: Group[];
    user: User;
    onGroupDeleted: () => void;
}

export const UserGroupsRow = ({ userGroups, user, onGroupDeleted }: UserGroupsRowProps) => {
    const classNames = useClassNames('settings-user-panel');
    const modalContainer = useArgModalContext();

    const handleOnDeleteFromGroup = useCallback((groupId: string) => {
        const group = userGroups.find((g) => g.id === groupId);

        modalContainer.open(DELETE_USER_MODAL,
            <ConfirmModal
                title={messages.title}
                alertMessage={messages.description}
                messageValues={{
                    userName: user.displayName,
                    groupName: group?.name,
                }}
                confirmText={messages.remove}
                onClose={() => modalContainer.close(DELETE_USER_MODAL)}
                onConfirm={async (progressMonitor: ProgressMonitor) => {
                    await userGroupsConnector.deleteUserFromGroup(groupId, user.id, progressMonitor);
                    onGroupDeleted();
                }}
            />
        );
    }, [modalContainer, onGroupDeleted, user.id, user.userName, userGroups]);

    return (
        <>
            {userGroups?.map((group) => (
                <div key={group.id} className={classNames('&-group-row')}>
                    <ArgIcon name='icon-users' className={classNames('&-users-icon')} />
                    <div className={classNames('&-group-name')}>
                        {group.name}
                    </div>
                    <ArgButton className={classNames('&-group-delete')} onClick={() => {
                        handleOnDeleteFromGroup(group.id);
                    }} icon='icon-trash' type='secondary' size='small' />
                </div>
            ))}
        </>
    );
};
