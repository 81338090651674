import { Dispatch, SetStateAction, useState } from 'react';
import { defineMessages } from 'react-intl';

import {
    FullOntology,
    FullOntologyLinkType,
    FullOntologyObjectType,
    OntologyProperty,
} from 'src/settings/universes/ontology/types';
import { useArgNotifications } from 'src/components/basic';
import { VertexOrEdge } from 'src/exploration/hooks/use-graph-style-customisation';
import { AddEditOntology } from '../../../../models/dtoApi';
import ontologiesConnector from '../../../../connectors/ontologies-connector';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';

const messages = defineMessages({
    title: {
        id: 'settings.delete-property-modal.title',
        defaultMessage: 'Delete a property',
    },
    check: {
        id: 'settings.delete-property-modal.check',
        defaultMessage: 'Are you sure you want to delete this property?',
    },
    warning: {
        id: 'settings.delete-property-modal.warning',
        defaultMessage: 'The property "{propertyDisplayName}" will be deleted.',
    },
    cancel: {
        id: 'settings.delete-property-modal.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.delete-property-modal.delete',
        defaultMessage: 'Delete',
    },
    error: {
        id: 'settings.delete-property-modal.error',
        defaultMessage: 'Something went wrong while deleting the property',
    },
});

interface DeletePropertyModalProps {
    property: OntologyProperty;
    closeModal: () => void;
    edgeOrVertex: FullOntologyLinkType | FullOntologyObjectType;
    propertyOf: VertexOrEdge;
    ontologyId: string;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
}

export function DeletePropertyModal(props: DeletePropertyModalProps) {
    const {
        property,
        closeModal,
        edgeOrVertex,
        propertyOf,
        ontologyId,
        setOntology,
    } = props;

    const notifications = useArgNotifications();

    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        const remainingProperties = edgeOrVertex.properties
            .filter(({ name }) => name !== property.name);

        const editOntologyProps: AddEditOntology = {
            ontologyId: ontologyId,
            name: edgeOrVertex.name,
            newDisplayName: edgeOrVertex.displayName,
            newProperties: remainingProperties,
        };
        setLoading(true);
        try {
            if (propertyOf === VertexOrEdge.Vertex) {
                await ontologiesConnector.editOntologyObject(editOntologyProps);
            } else {
                await ontologiesConnector.editOntologyEdge(editOntologyProps);
            }
            const newOntology = await ontologiesConnector.getFullOntology(ontologyId);
            setOntology(newOntology);
        } catch (e) {
            notifications.snackError({ message: messages.error }, e as Error);
        }
    };

    return (
        <ConfirmModal
            size='medium'
            title={messages.title}
            type='delete'
            onClose={closeModal}
            messageValues={{
                propertyDisplayName: property.displayName,
            }}
            onConfirm={handleSubmit}
            confirmLoading={loading}
            alertMessage={messages.check}
            alertDescription={messages.warning}
        />
    );
}
