import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
    ArgButton,
    ArgDragAndDropUploader,
    ArgIcon,
    ArgImage,
    ArgUploaderButton,
    ClassValue,
    useClassNames,
} from '../../../components/basic';
import { ApplicationOrModuleBranding } from '../../models/visual-identity';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';

import './image-manager.less';

const messages = defineMessages({
    changeImage: {
        id: 'settings.image-manager.changeImage',
        defaultMessage: 'Change image',
    },
    delete: {
        id: 'settings.image-manager.delete',
        defaultMessage: 'Delete',
    },
    iconInstructions: {
        id: 'settings.image-manager.iconInstructions',
        defaultMessage: 'Place your icon here',
    },
    logoInstructions: {
        id: 'settings.image-manager.logoInstructions',
        defaultMessage: 'Place your logo here',
    },
    iconSizeRecommendations: {
        id: 'settings.image-manager.iconSizeRecommendations',
        defaultMessage: 'Recommended size 600x600 px',
    },
    iconFormats: {
        id: 'settings.image-manager.iconFormats',
        defaultMessage: 'Supported formats: .jpg, .png, .svg',
    },
    browseFile: {
        id: 'settings.image-manager.browseFile',
        defaultMessage: 'or browse the disk',
    },
    iconTitle: {
        id: 'settings.image-manager.iconTitle',
        defaultMessage: 'Icon',
    },
    logoSizeRecommandations: {
        id: 'settings.image-manager.logoSizeRecommandations',
        defaultMessage: 'Recommended size 600x600 px',
    },
    faviconSizeRecommandations: {
        id: 'settings.image-manager.faviconSizeRecommandations',
        defaultMessage: 'Recommended size 36x36 px',
    },
    logoTitle: {
        id: 'settings.image-manager.logoTitle',
        defaultMessage: 'Logo',
    },
    faviconTitle: {
        id: 'settings.image-manager.faviconTitle',
        defaultMessage: 'Favicon',
    },
});

interface ImageManagerProps {
    className?: ClassValue;
    moduleBranding: ApplicationOrModuleBranding;
    onImageChange: (icon: Blob | undefined) => void;
    imageType: 'icon' | 'logo';
    logoPlaceholder?: string;
}

export function ImageManager(props: ImageManagerProps) {
    const {
        className,
        moduleBranding,
        onImageChange,
        imageType,
        logoPlaceholder,
    } = props;

    const classNames = useClassNames('image-manager');
    const canManageVisualIdentity = useHasPermission<SettingsPermissions>('admin.visual.identity.management');

    const [blobURL, setBlobURL] = useState<string>();

    const isIcon = !logoPlaceholder?.includes('/');
    const blob = moduleBranding[imageType];

    useEffect(() => {
        if (!blob) {
            setBlobURL(undefined);

            return;
        }

        const url = URL.createObjectURL(blob);
        setBlobURL(url);

        return () => {
            URL.revokeObjectURL(url);
        };
    }, [blob]);

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-dnd')}>
                <FormattedMessage {...((imageType === 'icon' && !moduleBranding.id)
                    ? messages.faviconTitle
                    : (imageType === 'icon'
                        ? messages.iconTitle
                        : messages.logoTitle
                    )
                )} />
                <ArgDragAndDropUploader
                    disabled={!canManageVisualIdentity}
                    className={classNames('&-dnd-uploader')}
                    method={async (icon) => onImageChange(icon)}
                >
                    {!blob ? (
                        <div className={classNames('&-dnd-uploader-dropArea')}>
                            {logoPlaceholder && (
                                <div className={classNames('&-dnd-uploader-dropArea-icon')}>
                                    {isIcon ? <ArgIcon name={logoPlaceholder} size='25px' /> :
                                        <ArgImage src={logoPlaceholder} />}
                                </div>)}
                            {canManageVisualIdentity && (
                                <div>
                                    <FormattedMessage {...(imageType === 'logo' ? messages.logoInstructions : messages.iconInstructions)} />
                                </div>
                            )}
                            <div className={classNames('&-dnd-uploader-dropArea-smallText')}>
                                <div>
                                    <FormattedMessage {...((imageType === 'icon' && !moduleBranding.id)
                                        ? messages.faviconSizeRecommandations
                                        : messages.logoSizeRecommandations
                                    )} />
                                </div>
                                <div>
                                    <FormattedMessage {...messages.iconFormats} />
                                </div>
                            </div>
                            {canManageVisualIdentity && (
                                <div>
                                    <ArgUploaderButton
                                        type='link'
                                        size='small'
                                        className={classNames('&-dnd-uploader-dropArea-browser')}
                                        label={messages.browseFile}
                                        acceptedFiles='.jpg,.png,.svg'
                                        method={async (icon) => onImageChange(icon)}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={classNames('&-dnd-uploader-dropArea')}>
                            <img className={classNames('&-dnd-uploader-image')} src={blobURL} />
                        </div>
                    )}
                </ArgDragAndDropUploader>
            </div>
            <div className={classNames('&-menu')}>
                {blob && (
                    <>
                        <ArgUploaderButton
                            type='link'
                            size='large'
                            className={classNames('&-menu-button')}
                            label={messages.changeImage}
                            acceptedFiles='.jpg,.png,.svg'
                            method={async (icon) => onImageChange(icon)}
                        />
                        <ArgButton
                            type='link'
                            size='large'
                            className={classNames('&-menu-button')}
                            label={messages.delete}
                            onClick={() => onImageChange(undefined)}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default ImageManager;
