import { ArgIcon, ArgRenderedText, ClassValue, renderText, useClassNames } from 'src/components/basic';

import './page-header.less';

interface PageHeaderProps {
    title: ArgRenderedText;
    selectedItem?: string;
    setSelectedItem?: (key: string) => void;
    buttons?: { key: string; icon: string }[];
    className?: ClassValue;
}

export function PageHeader(props: PageHeaderProps) {
    const {
        title,
        selectedItem,
        setSelectedItem,
        buttons,
        className,
    } = props;

    const classNames = useClassNames('settings-page-header');

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-title')}>
                {renderText(title)}
            </div>
            <div className={classNames('&-buttons-container')}>
                {buttons &&
                    buttons.map((button) => {
                        return (
                            <button
                                key={button.key}
                                type='button'
                                className={
                                    selectedItem === button.key
                                        ? classNames('&-button &-button-active')
                                        : classNames('&-button &-button-inactive')
                                }
                                onClick={() =>
                                    setSelectedItem?.(selectedItem === button.key ? '' : button.key)
                                }
                            >
                                <ArgIcon name={button.icon} size={20} />
                            </button>
                        );
                    })}
            </div>
        </div>
    );
}
