import React, { ReactNode, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { isString } from 'lodash';

import {
    ArgButton,
    ArgImage,
    ArgRenderedText,
    ArgTooltip2,
    ClassValue,
    DEFAULT_TOOLTIP_BUTTON_DELAY,
    renderText,
    useClassNames,
} from 'src/components/basic';
import { hasAnyPermissions } from '../../../contexts/user-permission';
import { useGetMyPermissions } from 'src/contexts/user-permissions-context';
import { ApplicationButton } from './application-button';
import { ArgonosModule, ArgonosModuleId } from 'src/components/application/modules';
import { listArgonosModules } from '../../application/modules-manager';
import { Environment } from '../../../utils/environment';
import { useApplicationBranding } from '../../application/argonos-application-branding';
import { useArgonosModuleBranding } from 'src/components/application/argonos-modules-branding';
import { useCurrentArgonosModule } from 'src/components/application/argonos-current-module';

import ChapsIcon from '../../../assets/chapsicon.svg';
import './applications.less';

interface ApplicationsProps {
    className?: ClassValue;
    currentModuleId: ArgonosModuleId,
    title?: ArgRenderedText | false;
    backUrl?: string;
    backTooltip?: ArgRenderedText;
}

export function Applications(props: ApplicationsProps) {
    const { className, currentModuleId, title, backUrl, backTooltip } = props;

    const [popoverVisibility, setPopoverVisibility] = useState(false);

    const classNames = useClassNames('common-applications');
    const module = useCurrentArgonosModule();
    const [moduleBranding] = useArgonosModuleBranding(module.id);
    const applicationBranding = useApplicationBranding();

    const { permissions: userPermissions } = useGetMyPermissions();

    const isApplicationAvailable = useCallback((application: ArgonosModule) => {
        const isApplicationPermitted = !application.requiredPermissions || hasAnyPermissions(userPermissions, ...application.requiredPermissions);

        const isApplicationEnabled = application.enabled !== false;

        return isApplicationEnabled && isApplicationPermitted;
    }, [userPermissions]);

    const handlePopover = useCallback(() => {
        return (
            <div className={classNames('&-popup')}>
                {listArgonosModules().reduce((acc, argonosModule) => {
                    const applicationAvailable = isApplicationAvailable(argonosModule);

                    if (!applicationAvailable) {
                        return acc;
                    }

                    acc.push(
                        <ApplicationButton
                            key={argonosModule.id}
                            selected={argonosModule.id === currentModuleId}
                            argonosModule={argonosModule}
                        />
                    );

                    return acc;
                }, [] as React.ReactElement[]).value()}
            </div>
        );
    }, [
        currentModuleId,
        isApplicationAvailable,
        classNames,
    ]);

    const _title = (title !== false)
        ? renderText(title
            || moduleBranding?.brandingName
            || module.name
            || Environment.appName
        )
        : undefined;

    const brandingLogoURL = moduleBranding?.brandingLogoURL
        || applicationBranding.brandingLogoURL
    ;

    let logo: ReactNode = <Link to={backUrl || '/'} className={classNames('&-logo')} replace={true}>
        {isString(brandingLogoURL) ? <ArgImage
            className={classNames('&-logo-image')}
            src={brandingLogoURL}
        /> : <img
            className={classNames('&-logo-image')}
            src={ChapsIcon}
        />}
    </Link>;

    if (backTooltip) {
        logo = <ArgTooltip2
            placement='bottomLeft'
            mouseEnterDelay={DEFAULT_TOOLTIP_BUTTON_DELAY}
            title={backTooltip}>
            {logo}
        </ArgTooltip2>;
    }

    return (
        <div className={classNames('&', className)}>
            <ArgButton
                className={classNames('&-dots')}
                icon='icon-menu-9dots'
                size='large'
                type='ghost'
                popover={handlePopover}
                popoverVisible={popoverVisibility}
                onPopoverVisibleChange={setPopoverVisibility}
                popoverPlacement='bottomLeft'
            />
            {logo}
            {_title && (
                <div className={classNames('&-branding')}>
                    {renderText(_title)}
                </div>
            )}
        </div>
    );
}
