import { Dispatch, SetStateAction, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ArgTab, ArgTabsSubLevel, useClassNames } from 'src/components/basic';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { FullOntology } from '../../types';
import { MetapropertiesPanelContent } from './metaproperties-panel-content';
import { ObjectsPanelContent } from './objects-panel-content';
import { RelationsPanelContent } from './relations-panel-content';

import './universe-library-panel.less';

const messages = defineMessages({
    panelTitle: {
        id: 'settings.universe-library-panel.panelTitle',
        defaultMessage: 'Ontology',
    },
    objects: {
        id: 'settings.universe-library-panel.objects',
        defaultMessage: 'Objects ({count})',
    },
    relations: {
        id: 'settings.universe-library-panel.relations',
        defaultMessage: 'Relations ({count})',
    },
    metaproperties: {
        id: 'settings.universe-library-panel.metaproperties',
        defaultMessage: 'Metaproperties ({count})',
    },
    noOntology: {
        id: 'settings.universe-library-panel.NoOntology',
        defaultMessage: 'No ontology',
    },
});

interface UniverseLibraryPanelProps {
    ontology?: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
}

enum LibraryPanelTabs {
    OBJECTS = 'objects',
    RELATIONS = 'relations',
    METAPROPERTIES = 'metaproperties',
}

export const UniverseLibraryPanel: React.FC<UniverseLibraryPanelProps> = ({
    ontology,
    setOntology,
}) => {
    const intl = useIntl();
    const classNames = useClassNames('universe-library-panel');
    const [libraryTabSelected, setLibraryTabSelected] = useState<LibraryPanelTabs>(
        LibraryPanelTabs.OBJECTS
    );

    const tabs: ArgTab[] = !ontology ? [] : [
        {
            key: LibraryPanelTabs.OBJECTS,
            title: intl.formatMessage(messages.objects, { count: ontology.objectTypes.length }),
        },
        {
            key: LibraryPanelTabs.RELATIONS,
            title: intl.formatMessage(messages.relations, { count: ontology.linkTypes.length }),
        },
        {
            key: LibraryPanelTabs.METAPROPERTIES,
            title: intl.formatMessage(messages.metaproperties, {
                count: ontology.metaProperties.length,
            }),
        },
    ];

    if (!ontology) {
        return (
            <div className={classNames('&', 'empty')}>
                <EmptyPane
                    message={messages.noOntology}
                    icon='icon-info'
                    size='medium'
                />
            </div>
        );
    }

    return (
        <div className={classNames('&')}>
            <div className={classNames('&-title')}>{intl.formatMessage(messages.panelTitle)}</div>
            <div className={classNames('&-body')}>
                <ArgTabsSubLevel
                    tabs={tabs}
                    className={classNames('&-tabs')}
                    activeTabKey={libraryTabSelected}
                    onChange={(tabKey) => {
                        tabKey && setLibraryTabSelected(tabKey as LibraryPanelTabs);
                    }}
                />
                {libraryTabSelected === LibraryPanelTabs.OBJECTS && (
                    <ObjectsPanelContent vertices={ontology.objectTypes} />
                )}
                {libraryTabSelected === LibraryPanelTabs.RELATIONS && (
                    <RelationsPanelContent edges={ontology.linkTypes} />
                )}
                {libraryTabSelected === LibraryPanelTabs.METAPROPERTIES && (
                    <MetapropertiesPanelContent
                        metaproperties={ontology.metaProperties}
                        setOntology={setOntology}
                        ontologyId={ontology.id}
                    />
                )}
            </div>
        </div>
    );
};
