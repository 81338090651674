import React, { CSSProperties, DragEvent, DragEventHandler, ReactNode } from 'react';
import { get, isBoolean, isFunction, isString } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { KeyAndLine } from '../../../utils/scroll-display-manager';
import { ArgTable3OnDragStartHandler, ArgTable3RowState, ArgTableColumn3, RowState } from './arg-table3';
import { ArgTooltip2 } from '../arg-tooltip/arg-tooltip2';

import './virtual-column.less';

export const STYLE_DISPLAY_NONE = {
    display: 'none',
};

interface VirtualColumnProps<T> {
    column: ArgTableColumn3<T>;
    first?: boolean;
    last?: boolean;
    totalHeight: number;
    elements: KeyAndLine[];
    left: number;
    columnWidth: number;
    scrollTop?: number;
    rowsCache: Map<number, RowState<T>>;
    itemsCount: number;
    className?: ClassValue;
    renderLoadingCell?: (column: ArgTableColumn3<T>, index?: number) => ReactNode;
    renderErrorCell?: (column: ArgTableColumn3<T>, index?: number, error?: Error) => ReactNode;
    dragTransform?: string;
    dragged?: boolean;
    onDragStart?: ArgTable3OnDragStartHandler<T>;
    onDragEnd?: (event: DragEvent) => void;
}

export function VirtualColumn<T>(props: VirtualColumnProps<T>) {
    const {
        elements,
        first,
        last,
        totalHeight,
        left,
        columnWidth,
        scrollTop,
        rowsCache,
        column,
        itemsCount,
        className,
        renderLoadingCell,
        renderErrorCell,
        dragTransform,
        dragged,
        onDragStart,
        onDragEnd,
    } = props;

    const classNames = useClassNames('arg-table3-virtual-column');
    const visibleChildren = elements.map((element) => {
        if (element.row < 0) {
            return (
                <div
                    key={element.key}
                    data-rowindex=''
                    style={STYLE_DISPLAY_NONE}
                />
            );
        }
        if (element.row >= itemsCount) {
            return (
                <div className='outside' key={element.key} style={element.style} data-rowindex={element.row} />
            );
        }

        const rowCache = rowsCache.get(element.row);
        if (!rowCache) {
            return null;
        }

        const { data: rowData, className: rowClassName, draggable: draggableRow } = rowCache;
        const isDraggable = !!draggableRow;

        let cellComponent;

        const cellProps: Record<string, any> = {};
        if (isDraggable && rowData !== ArgTable3RowState.Error && rowData !== ArgTable3RowState.Loading && onDragStart) {
            const handleOnDragStart: DragEventHandler = (event) => {
                //event.stopPropagation();
                onDragStart(event, rowData as T);
            };

            cellProps.draggable = true;
            cellProps.onDragStart = handleOnDragStart;
            cellProps.onDragEnd = onDragEnd;
        }

        let cls = rowClassName;
        let cellData;
        let pureRowData: T | undefined;
        if (rowData === ArgTable3RowState.Loading) {
            cls = 'loading';
            if (renderLoadingCell) {
                cellComponent = renderLoadingCell(column, element.row);
            } else {
                cellComponent = <div className='default-render arg-skeleton' />;
            }
        } else if (rowData === ArgTable3RowState.Error) {
            cls = 'error';
            if (renderErrorCell) {
                cellComponent = renderErrorCell(column, element.row);
            } else {
                cellComponent = <div className='default-render' />;
            }
        } else if (rowData) {
            pureRowData = rowData;

            if (column.dataIndex) {
                cellData = get(rowData, column.dataIndex);
            }

            if (column.render) {
                cellComponent = column.render(cellData, rowData, element.row);
                if (isString(cellComponent) || column.cellTooltip) {
                    cellComponent = <div className='string-render'>{cellComponent}</div>;
                    const tooltipTitle = isBoolean(column.cellTooltip) ? cellComponent : column.cellTooltip?.(cellData);
                    const tooltipClassname = column.cellTooltipClassName ? column.cellTooltipClassName : 'string-render-container';
                    cellComponent = <ArgTooltip2
                        className={tooltipClassname}
                        title={tooltipTitle}
                        placement='bottomLeft'
                    >
                        <div className={classNames('clamp-2', 'string-render')}>
                            {cellComponent}
                        </div>
                    </ArgTooltip2>
                    ;
                }
            } else {
                cellComponent = <div className='cell-render'>{cellData}</div>;
            }
        }

        let cellClassName = column.cellClassName;
        if (isFunction(cellClassName)) {
            cellClassName = cellClassName(cellData, pureRowData, element.row);
        }

        return (
            <div {...cellProps}
                 className={classNames(cls, cellClassName)}
                 key={element.key}
                 style={element.style}
                 data-rowindex={element.row}
            >
                {cellComponent}
            </div>
        );
    });

    const bodyStyle: CSSProperties = {
        height: `${totalHeight}px`,
        left: `${left}px`,
        width: columnWidth ? `${columnWidth}px` : '100%', // To handling list
        transform: dragTransform,
    };
    if (scrollTop) {
        bodyStyle.top = `${-scrollTop}px`;
    }

    const cls = {
        first,
        last,
        dragged,
        'drag-transform': dragTransform,
    };

    return (
        <div className={classNames('&', cls, column.className, className)} style={bodyStyle}
             data-column={column.key}>
            {visibleChildren}
        </div>
    );
}
