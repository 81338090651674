import { merge } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { DEFAULT_VERTEX_STYLE } from 'src/exploration/constants/default-vertex-style';
import {
    GraphCustomizerState,
    UseGraphStyleCustomisationReturnType,
} from 'src/exploration/hooks/use-graph-style-customisation';
import { FullOntology, FullOntologyObjectType } from '../../types';
import { GetOntologyStyleDTO } from 'src/settings/models/dtoApi';
import { addProperty, getDefaultValueFromControlType, updateStyleFromState } from './utils';
import {
    AdvancedStyleType,
    DiscreteValue,
    Interval,
    StyleControlType,
    UserDefinedContent,
} from 'src/components/common/graph/customisation/graph-style';
import { graphStyleCustomisationReducer, TYPES } from 'src/exploration/reducers/graph-style-customisation-reducer';
import { Side } from 'src/components/common/graph/customisation/advanced-style';
import { dayjs, useChainedActions, useArgNotifications } from '../../../../../components/basic';
import ontologiesConnector from '../../../../connectors/ontologies-connector';

const messages = defineMessages({
    styleChangesError: {
        id: 'settings.useVertexStyleCustomisation.message',
        defaultMessage: 'An error occurred while trying to edit the object style',
    },
});

export const useVertexStyleCustomisation = (
    ontology: FullOntology,
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>,
    vertexSelected: FullOntologyObjectType | undefined,
    setVertexSelected: Dispatch<SetStateAction<FullOntologyObjectType | undefined>>
): UseGraphStyleCustomisationReturnType => {
    const intl = useIntl();
    const notifications = useArgNotifications();

    const [colorAndIconEditorVisible, setColorAndIconEditorVisible] = useState(false);
    const [defaultPropertiesExpanded, setDefaultPropertiesExpanded] = useState(true);

    const defaultVertexStyle = merge({}, DEFAULT_VERTEX_STYLE, vertexSelected?.style);

    const objectType = vertexSelected?.name
        ? ontology.fullStyle.objectTypes[vertexSelected.name]
        : undefined;
    const convertedObject = {
        ...objectType,
        userDefinedContent: objectType?.userDefinedContent
            ? (objectType.userDefinedContent as UserDefinedContent)
            : {},
        ruleSets: objectType?.ruleSets || {},
    };

    const graphCustomiserState: GraphCustomizerState = {
        ...convertedObject,
        colorAndIconEditorVisible,
        defaultPropertiesExpanded,
    };

    const modifyOntologyStyle = useCallback(async (newStyle: GetOntologyStyleDTO) => {
        try {
            await ontologiesConnector.editOntologyStyle(ontology.id, { style: newStyle });
            const newOntology = await ontologiesConnector.getFullOntology(ontology.id);
            setOntology(newOntology);
        } catch (e) {
            notifications.snackError({ message: messages.styleChangesError }, e as Error);
        }
    }, [notifications, ontology.id, setOntology]);

    const [modifyOntologyStyleProcessor] = useChainedActions('settings-vertex-styles', modifyOntologyStyle);

    const submitNewStyle = useCallback(async (newStyle?: GetOntologyStyleDTO) => {
        if (!newStyle) {
            return;
        }

        await modifyOntologyStyleProcessor(newStyle);
    }, [modifyOntologyStyleProcessor]);

    const objectTypeList = useMemo(() => {
        return ontology.objectTypes.map((vertex) => vertex.name);
    }, [ontology?.objectTypes]);

    return {
        initialStyles: {
            vertexStyles: {},
            edgeStyles: {},
        },
        currentStyles: {
            vertexStyles: {},
            edgeStyles: {},
        },
        refreshInitialStyles: () => {
        },
        loadStyles: async () => {
        },
        resetStyles: async () => {
        },
        defaultVertexStyle: undefined,
        state: {
            label: vertexSelected?.name,
            titleProperty: vertexSelected?.properties?.filter((property) => property.isTitle)[0]
                ?.displayName,
            clusteringProperty: undefined,
            groupbyProperty: undefined,
            timelineProperty: undefined,
            userDefinedContent: {
                size: defaultVertexStyle?.size,
                gradientSize: undefined,
                fillColor: defaultVertexStyle?.fillColor,
                strokeColor: defaultVertexStyle?.strokeColor,
                iconName: defaultVertexStyle?.iconName,
                iconFontFamily: defaultVertexStyle?.iconFontFamily,
                iconScale: defaultVertexStyle?.iconScale,
                iconColor: defaultVertexStyle?.iconColor,
                offsetX: undefined,
                offsetY: undefined,
                gradientBounds: undefined,
                badgeBlink: undefined,
                lineStyle: undefined,
                lineAnimation: undefined,
                id: undefined,
            },
            ruleSets: convertedObject.ruleSets,
            defaultPropertiesExpanded: defaultPropertiesExpanded,
            colorAndIconEditorVisible: colorAndIconEditorVisible,
        },
        objectTypeList,
        clusterableProperties: [],
        geographyProperties: [],
        canBeDefinedAsTitleProperties: vertexSelected?.properties.map(({ name }) => name) || [],
        properties: vertexSelected?.properties.map(({ name }) => name) || [],
        setFillColor: async (input: string) => {
            const newProperties = { fillColor: input };
            const newStyle = addProperty(
                ontology.fullStyle,
                vertexSelected,
                newProperties,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        setStrokeColor: async (input: string) => {
            const newProperties = { strokeColor: input };
            const newStyle = addProperty(
                ontology.fullStyle,
                vertexSelected,
                newProperties,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        setIconColor: async (input: string) => {
            const newProperties = { iconColor: input };
            const newStyle = addProperty(
                ontology.fullStyle,
                vertexSelected,
                newProperties,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        setIcon: async (input: string) => {
            const newProperties = { iconName: input };
            const newStyle = addProperty(
                ontology.fullStyle,
                vertexSelected,
                newProperties,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        setIsStrokeTransparent: async (input: boolean) => {
            const newProperties = { strokeColor: 'none' };
            const newStyle = addProperty(
                ontology.fullStyle,
                vertexSelected,
                newProperties,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        setIconAndColorVisibility: (visible: boolean) => setColorAndIconEditorVisible(visible),
        setClusteringProperty: async () => {
        },
        setChronogramGroupingProperty: async () => {
        },
        setChronogramTimelineProperty: async () => {
        },
        setGeographicalProperty: async () => {
        },
        setAdvancedStyleProperty: async (
            advancedStyleType: AdvancedStyleType,
            property: string | undefined,
            controlType?: StyleControlType
        ) => {
            const value = getDefaultValueFromControlType(property, controlType);
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.SET_ADVANCED_STYLE_PROPERTY,
                undoRedo: () => {
                },
                payload: { advancedStyleType, property, value, controlType },
            });
            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                vertexSelected,
                newState,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        setAdvancedStyleDiscretePropertyValue: async (
            advancedStyleType: AdvancedStyleType,
            value: string | number | boolean | null,
            index: number
        ) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.SET_ADVANCED_DISCRETE_PROPERTY_VALUE,
                undoRedo: () => {
                },
                payload: { advancedStyleType, value, index, state: graphCustomiserState },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                vertexSelected,
                newState,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        setAdvancedStyleRangeValue: async (
            advancedStyleType: AdvancedStyleType,
            side: Side,
            value: number | dayjs.Dayjs | null,
            index: number
        ) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.SET_RANGE_VALUE,
                undoRedo: () => {
                },
                payload: { advancedStyleType, side, value, index },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                vertexSelected,
                newState,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        setAdvancedStyleRangeIntervalType: async (
            advancedStyleType: AdvancedStyleType,
            side: Side,
            index: number
        ) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: advancedStyleType,
                undoRedo: () => {
                },
                payload: { advancedStyleType, clickSide: side, position: index },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                vertexSelected,
                newState,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        setAdvancedStyle: async (
            advancedStyleType: AdvancedStyleType,
            style: Record<string, any>,
            index: number
        ) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.SET_ADVANCED_STYLE,
                undoRedo: () => {
                },
                payload: { advancedStyleType, style, index },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                vertexSelected,
                newState,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        addNewAdvancedStyleRule: async (
            advancedStyleType: AdvancedStyleType,
            value: DiscreteValue | Interval | null
        ) => {
            let payloadValue = value;
            if (value && 'interval' in value) {
                payloadValue = null;
            }
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.ADD_NEW_ADVANCED_STYLE_RULE,
                undoRedo: () => {
                },
                payload: { advancedStyleType, value: payloadValue },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                vertexSelected,
                newState,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        removeAdvancedStyleRule: async (advancedStyleType: AdvancedStyleType, index: number) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.REMOVE_ADVANCED_STYLE_RULE,
                undoRedo: () => {
                },
                payload: { advancedStyleType, position: index },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                vertexSelected,
                newState,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        onVertexLabelChange: async (input: string) => {
            const newVertexSelected = ontology.objectTypes.find((vertex) => vertex.name === input);
            setVertexSelected(newVertexSelected);
        },
        isSomeProgressMonitorsRunning: false,
        onToggleDefaultPropClick: async () => setDefaultPropertiesExpanded((current) => !current),
        onDisplayPropertyChange: async () => {
        },
        onSizeChange: async (input: number | null) => {
            const newProperties = { size: typeof (input) === 'number' ? input : undefined };
            const newStyle = addProperty(
                ontology.fullStyle,
                vertexSelected,
                newProperties,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        toggleGradientInterval: async () => {
        },
        setGradientPropertyRangeValue: async () => {
        },
        intl: intl,
        onResetStyles: async () => {
            const newProperties = {
                strokeColor: DEFAULT_VERTEX_STYLE.strokeColor,
                iconName: DEFAULT_VERTEX_STYLE.iconName,
                backgroundColor: DEFAULT_VERTEX_STYLE.fillColor,
            };
            const newStyle = addProperty(
                ontology.fullStyle,
                vertexSelected,
                newProperties,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        addUndefinedValueStylesRule: async (advancedStyleType: AdvancedStyleType) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.ADD_UNDEFINED_VALUE_STYLES_RULE,
                undoRedo: () => {
                },
                payload: { advancedStyleType },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                vertexSelected,
                newState,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        removeUndefinedValueStylesRule: async (advancedStyleType: AdvancedStyleType) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.REMOVE_UNDEFINED_VALUE_STYLES_RULE,
                undoRedo: () => {
                },
                payload: { advancedStyleType },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                vertexSelected,
                newState,
                'objectTypes'
            );
            submitNewStyle(newStyle);
        },
        setLineStyle: async () => {
        },
        setLineAnimation: async () => {
        },
    };
};
