import { defineMessages } from 'react-intl';

import { defineKeyBindings, KeyBindingScopeDescriptor } from '../../components/basic';
import { GLOBAL_SCOPE } from './global';

const messages = defineMessages({
    schedulerScope: {
        id: 'preparation.keybindings.SchedulerScope',
        defaultMessage: 'Scheduler Page',
    },
    triggerProcess: {
        id: 'preparation.keybindings.TriggerProcess',
        defaultMessage: 'Trigger process',
    },
    byDetection: {
        id: 'preparation.keybindings.ByDetection',
        defaultMessage: 'By detection',
    },
    byPlanification: {
        id: 'preparation.keybindings.ByPlanification',
        defaultMessage: 'By planification',
    },
});

export const SCOPE: KeyBindingScopeDescriptor = {
    id: 'preparation.Scheduler',
    name: messages.schedulerScope,
    extends: GLOBAL_SCOPE,
};

export const KEY_BINDINGS = defineKeyBindings({
    triggerProcess: {
        id: 'preparation.scheduler.TriggerProcess',
        scope: SCOPE,
        name: messages.triggerProcess,
        defaultKeys: 'alt+shift+T',
    },
    byDetection: {
        id: 'preparation.scheduler.ByDetection',
        scope: SCOPE,
        name: messages.byDetection,
        defaultKeys: 'alt+shift+D',
    },
    byPlanification: {
        id: 'preparation.scheduler.ByPlanification',
        scope: SCOPE,
        name: messages.byPlanification,
        defaultKeys: 'alt+shift+P',
    },
});

