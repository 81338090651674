import { ReactNode, useCallback, useMemo } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ArgTable2, ArgTable2Column, ArgTooltip2, ClassValue, SelectionProvider, useClassNames } from 'src/components/basic';
import { DateByUser } from 'src/components/common/date-by-user';
import { FormPolicy } from 'src/settings/models/form-policy';
import { dateSorter } from 'src/utils/sorter';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { FormPolicyActions } from '../form-policies-actions/form-policies-actions';
import { computeFormPolicyDetailsUrl } from 'src/settings/utils/compute-url';
import { OntologyId } from 'src/settings/universes/ontology/types';
import { UniverseId } from 'src/exploration/model/universe';

import './form-policies-table.less';

const SETTINGS_VALUATION_TABLE_SELECTION_SOURCE = 'settings-form-policy-table';

const messages = defineMessages({
    name: {
        id: 'settings.form-policies.table.name',
        defaultMessage: 'Name',
    },
    description: {
        id: 'settings.form-policies.table.description',
        defaultMessage: 'Description',
    },
    status: {
        id: 'settings.form-policies.table.status',
        defaultMessage: 'Status',
    },
    enabled: {
        id: 'settings.form-policies.table.enabled',
        defaultMessage: 'Enabled',
    },
    disabled: {
        id: 'settings.form-policies.table.disabled',
        defaultMessage: 'Disabled',
    },
    lastPublished: {
        id: 'settings.form-policies.table.last-published',
        defaultMessage: 'Last published',
    },
    emptyFormPolicies: {
        id: 'settings.form-policies.table.empty',
        defaultMessage: 'No form policies',
    },
});

interface FormPoliciesTableProps {
    className?: ClassValue;
    formPolicies: FormPolicy[];
    search?: string;
    handleRefresh: () => void;
    ontologyId: OntologyId;
    universeId: UniverseId;
}

export function FormPoliciesTable(props: FormPoliciesTableProps) {
    const { className, formPolicies, search, handleRefresh, ontologyId, universeId } = props;

    const classNames = useClassNames('settings-form-policies-table');

    const navigate = useNavigate();

    const formPoliciesSelectionProvider = useMemo(() => {
        const selectionProvider = new SelectionProvider<FormPolicy>('settings-form-policy-table', (formPolicy) => formPolicy.id);

        return selectionProvider;
    }, []);

    const handleRowDoubleClick = useCallback((formPolicy: FormPolicy) => {
        const formPolicyUrl = computeFormPolicyDetailsUrl(ontologyId, universeId, formPolicy.id);
        navigate(formPolicyUrl);
    }, [navigate, ontologyId, universeId]);

    const columns = useMemo<ArgTable2Column<FormPolicy>[]>(() => {
        return [
            {
                key: 'name',
                title: messages.name,
                dataIndex: 'name',
                ellipsis: true,
                render: function Name(value: string) {
                    return (
                        <ArgTooltip2 title={value}>
                            <span className={classNames('&-name')}>
                                {value}
                            </span>
                        </ArgTooltip2>
                    );
                },
            },
            {
                key: 'description',
                title: messages.description,
                dataIndex: 'description',
                width: '40%',
            },
            {
                key: 'status',
                title: messages.status,
                dataIndex: 'enabled',
                render: function renderStatus(enabled: boolean) {
                    return <FormattedMessage {...(enabled ? messages.enabled : messages.disabled)} />;
                },
            },
            {
                key: 'lastPublished',
                title: messages.lastPublished,
                dataIndex: 'lastPublished',
                sorter: (a, b) => dateSorter<FormPolicy>(a, b, (item) => item.lastUpdatedDate),
                ellipsis: true,
                render: function LastPublished(date: Date, formPolicy: FormPolicy) {
                    if (!formPolicy.lastPublishedBy) {
                        return '-';
                    }

                    return (
                        <DateByUser
                            date={date}
                            relative={true}
                            user={formPolicy.lastPublishedBy}
                        />
                    );
                },
            },
            {
                key: 'actions',
                columnName: ' ',
                title: ' ',
                dataIndex: 'actions',
                sortable: false,
                width: 50,
                render: function display(data: string, formPolicy: FormPolicy) {
                    return <FormPolicyActions formPolicy={formPolicy} className={classNames('&-action-button')} onActionSuccess={handleRefresh} />;
                },
            },
        ];
    }, [classNames, handleRefresh]);

    let table: ReactNode = null;
    if (formPolicies.length === 0) {
        table = (
            <EmptyPane
                message={messages.emptyFormPolicies}
                className={classNames('&-pane')}
            />
        );
    } else {
        table = (
            <ArgTable2<FormPolicy>
                className={classNames('&-table')}
                columns={columns}
                rowHeight={45}
                dataSource={formPolicies}
                search={search}
                selectionProvider={formPoliciesSelectionProvider}
                onRowDoubleClick={handleRowDoubleClick}
                selectionSource={SETTINGS_VALUATION_TABLE_SELECTION_SOURCE}
            />
        );
    }

    return (
        <div className={classNames('&', className)}>
            {table}
        </div>
    );
}
