import { ReactNode, useCallback } from 'react';
import { Modal, ModalProps } from 'antd';

import { messages } from '../../common/modal2/naming-modal/naming-modal';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { renderText } from '../utils/message-descriptor-formatters';

import './arg-modal.less';

export type ArgModalSize = 'small' | 'medium' | 'large' | 'xlarge' | '2xlarge' | 'fullsize';

const SIZES_VALUES = {
    small: {
        width: 500,
    },
    medium: {
        width: 600,
    },
    large: { width: 870 },
    xlarge: { width: 1300 },
    '2xlarge': { width: 1700 },
    fullsize: {
        width: '100%',
        height: '100%',
    },

};

export interface ArgModalProps extends Omit<ModalProps, 'title' | 'okText' | 'cancelText' | 'className' | 'okButtonProps' | 'cancelButtonProps' | 'onOk' | 'onCancel' | 'destroyOnClose'> {
    className?: ClassValue;
    title?: ArgRenderedText;
    messageValues?: ArgMessageValues;

    okText?: ArgRenderedText | false;
    cancelText?: ArgRenderedText | false;

    okButtonTooltip?: ArgRenderedText;
    okDataTestId?: string;

    cancelButtonTooltip?: ArgRenderedText;
    cancelDataTestId?: string;

    children?: ReactNode;

    size?: ArgModalSize;

    loading?: boolean;
    progressMonitor?: ProgressMonitor;

    okDisabled?: boolean;
    cancelDisabled?: boolean;

    onClose?: () => void;

    visible?: boolean;

    onOk?: (event: ButtonClickEvent | undefined) => void;

    onCancel?: (event: ButtonClickEvent | undefined) => void;
}

export function ArgModal(props: ArgModalProps) {
    const {
        className,
        wrapClassName,
        title,
        children,
        messageValues,
        cancelText,
        progressMonitor,
        okDisabled,
        okText,
        okButtonTooltip,
        cancelButtonTooltip,
        onCancel,
        onClose,
        onOk,
        loading,
        footer,
        okDataTestId,
        cancelDataTestId,
        visible = true,
        cancelDisabled,
        size,
        ...otherProps
    } = props;

    const classNames = useClassNames('arg-modal');

    const handleOk = useCallback((event?: ButtonClickEvent) => {
        onOk?.(event);
    }, [onOk]);

    const handleCancel = useCallback((event: ButtonClickEvent) => {
        if (onCancel) {
            onCancel?.(event);

            return;
        }
        onClose?.();
    }, [onCancel, onClose]);

    const _title: ReactNode = renderText(title, messageValues);

    const _loading = loading || progressMonitor?.isRunning;

    const wrapCls = {
        loading: _loading,
    };

    let _footer = footer;
    if (_footer !== false && !_footer) {
        _footer = <div className={classNames('&-footer')}>
            {cancelText !== false && <ArgButton
                className={classNames('&-footer-btn')}
                type='secondary'
                onClick={handleCancel}
                label={cancelText || messages.cancel}
                messageValues={messageValues}
                tooltip={cancelButtonTooltip}
                data-testid={cancelDataTestId}
                disabled={cancelDisabled}
            />}
            {okText !== false && <ArgButton
                className={classNames('&-footer-btn')}
                type='primary'
                htmlType='submit'
                onClick={handleOk}
                label={okText || messages.ok}
                loading={_loading}
                disabled={okDisabled || _loading}
                messageValues={messageValues}
                tooltip={okButtonTooltip}
                data-testid={okDataTestId}
            />}
        </div>;
    }

    return (
        <Modal
            className={classNames('&', className)}
            wrapClassName={classNames('&-wrap', wrapClassName, wrapCls)}
            {...SIZES_VALUES[size || 'small']}
            maskClosable={false}
            destroyOnClose={true}
            {...otherProps}
            open={visible}
            centered={true}
            closeIcon={<ArgIcon name='icon-cross' />}
            title={<span className={classNames('&-header-title')}> {_title}</span>}
            onCancel={onClose}
            footer={_footer}
        >
            {children}
        </Modal>
    );
}
