import { defineMessages } from 'react-intl';

import { defineKeyBindings, KeyBindingScopeDescriptor } from '../../components/basic';
import { messages as homePageMessages } from './home-page';

const messages = defineMessages({
    caseScope: {
        id: 'exploration.keybindings.CaseScope',
        defaultMessage: 'Case Page',
    },
    backToHome: {
        id: 'exploration.keybindings.BackToHome',
        defaultMessage: 'Back to home page',
    },
    openGraphQuickView: {
        id: 'exploration.keybindings.OpenGraphQuickView',
        defaultMessage: 'Open graph quick view',
    },
    openMapQuickView: {
        id: 'exploration.keybindings.OpenMapQuickView',
        defaultMessage: 'Open map quick view',
    },
    openTableQuickView: {
        id: 'exploration.keybindings.OpenTableQuickView',
        defaultMessage: 'Open table quick view',
    },
    openOverview: {
        id: 'exploration.keybindings.OpenOverview',
        defaultMessage: 'Open overview',
    },
});

export const SCOPE: KeyBindingScopeDescriptor = {
    id: 'exploration.Case',
    name: messages.caseScope,
    hidden: true,
};


export const KEY_BINDINGS_SEARCH = defineKeyBindings({
    universeStructuredSearch: {
        id: 'exploration.case.OpenStructuredSearch',
        scope: SCOPE,
        name: homePageMessages.openStructuredSearch,
        defaultKeys: 'alt+shift+S',
    },
});

export const KEY_BINDINGS = defineKeyBindings({
    universeSearch: {
        id: 'exploration.case.SearchInUniverse',
        scope: SCOPE,
        name: homePageMessages.searchInUniverse,
        defaultKeys: 'alt+shift+F',
    },
    backToHome: {
        id: 'exploration.case.BackToHome',
        scope: SCOPE,
        name: messages.backToHome,
        defaultKeys: 'alt+ArrowLeft',
    },
    openGraphQuickView: {
        id: 'exploration.case.OpenGraphQuickView',
        scope: SCOPE,
        name: messages.openGraphQuickView,
        defaultKeys: 'alt+ctrl+shift+G',
    },
    openMapQuickView: {
        id: 'exploration.case.OpenMapQuickView',
        scope: SCOPE,
        name: messages.openMapQuickView,
        defaultKeys: 'alt+ctrl+shift+M',
    },
    openTableQuickView: {
        id: 'exploration.case.OpenTableQuickView',
        scope: SCOPE,
        name: messages.openTableQuickView,
        defaultKeys: 'alt+ctrl+shift+T',
    },
    openOverview: {
        id: 'exploration.case.OpenOverview',
        scope: SCOPE,
        name: messages.openOverview,
        defaultKeys: 'alt+ctrl+shift+O',
    },
});

