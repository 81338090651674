export type ContinuousValueIntervaleType = 'Closed' | 'Open' | 'LeftOpen' | 'RightOpen';
export interface ContinousValueCondition {
    type: ContinuousValueIntervaleType,
    left: number | null,
    right: number |null
}

export type LineStyle = 'solid' | 'dotted' | 'dashed' | 'hidden';

export enum LineAnimation {
    Slow = 'Slow',
    Fast = 'Fast',
    NoAnimation = 'No Animation'
}
export interface UserDefinedContent {
    size?: number;
    gradientSize?: [number | undefined, number | undefined],

    fillColor?: string;
    //transparentStrokeColor?: boolean;
    strokeColor?: string;
    iconColor?: string;

    iconName?: string;
    iconFontFamily?: string;

    iconScale?: number;
    offsetX?: number;
    offsetY?: number;

    gradientBounds?: [number | undefined, number | undefined]

    badgeBlink?: boolean;

    lineStyle?: LineStyle;
    lineAnimation?: LineAnimation;
    id?: string; //this help to add react key to the list of items
}

export interface Interval {
    interval: ContinousValueCondition
}

export interface Gradient {
    gradient: ContinousValueCondition
}

export interface DiscreteValue {
    value: any
}
export interface RuleSet {
    isUndefinedRuleSet?: boolean;
    property: string;
    userDefinedContent: UserDefinedContent;
    condition?: Interval | Gradient | DiscreteValue;
}

export type StyleControlType = 'gradient' | 'interval' | 'discrete'

export interface IntervalSymbols {
    lowerBoundIntervalType: JSX.Element;
    upperBoundIntervalType: JSX.Element;
}

export type AdvancedStyleType = 'color' | 'size' | 'icon' | 'badges' | 'style';
export type AdvancedStyleLabel = 'advancedColor' | 'advancedIcon' | 'advancedSize' | 'advancedBadge' | 'advancedStyle';
