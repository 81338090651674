import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { isEmpty } from 'lodash';

import {
    ArgButton,
    ArgCheckboxMinus,
    ArgDivider,
    ArgInputSearch,
    ArgUploaderButton,
    ClassValue,
    DndAction,
    Droppable,
    DroppingZone,
    ProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { ExplorationStyleTemplate, TemplateType } from '../../../../exploration/model/template';
import { ConfigurationOption, ImportAction, ImportExportOptions } from '../../../../model/configuration';
import { downloadBlob } from '../../../../utils/file';
import {
    ExplorationSettingsStylesTemplates,
} from './exploration-settings-styles-templates/exploration-settings-styles-templates';
import { useExplorationStylesTemplates } from './hooks/use-exploration-styles-templates';
import { TemplateId } from '../../../../model/template';
import { useHasPermission } from '../../../../contexts/user-permission';
import { ExplorationPermissions } from '../../../../exploration/model/permissions';
import configurationConnector from 'src/utils/connectors/configuration-connector';
import {
    ConfigurationErrorNotificationDescription,
} from '../configuration-error-notification-description/configuration-error-notification-description';
import { ConfigurationsScope } from '../../configuration-type';

import './library-configuration.less';


const CLASSNAME = 'settings-library-configuration';

const SUPPORTED_IMPORT_MIME_TYPES = [
    'application/x-zip-compressed', 'application/zip',
];

const FILE_TYPE = 'Files';

const CONFIGURATION_SCOPE:ConfigurationsScope = 'data_exploration';

const messages = defineMessages({
    searchPlaceholder: {
        id: 'settings.library-configuration.searchPlaceholder',
        defaultMessage: 'Search',
    },
    importLabel: {
        id: 'settings.library-configuration.importLabel',
        defaultMessage: 'Import',
    },
    stylesTemplateTitle: {
        id: 'settings.library-configuration.stylesTemplatesTitle',
        defaultMessage: 'Styles templates',
    },
    dropConfigurationtMessage: {
        id: 'settings.library-configuration.dropConfigurationtMessage',
        defaultMessage: 'Drop here',
    },
    invalidType: {
        id: 'settings.library-configuration.InvalidType',
        defaultMessage: 'Invalid file',
    },
    invalidConfigurationType: {
        id: 'settings.library-configuration.invalidConfigurationType',
        defaultMessage: 'Invalid configuration type (you can only import style template)',
    },
    importSucceed: {
        id: 'settings.library-configuration.ImportSucceed',
        defaultMessage: 'Configurations have successfully been imported',
    },
    importConfigurationsError: {
        id: 'settings.library-configuration.importConfigurationsError',
        defaultMessage: 'An error occurred while importing the configurations',
    },
    importConfigurationDescriptionError: {
        id: 'settings.library-configuration.importConfigurationDescriptionError',
        defaultMessage: '{count, plural, =1 {1 error detected } other {{count} errors detected}}',
    },
    export: {
        id: 'settings.library-configuration.export',
        defaultMessage: 'Export',
    },
    exportSucceed: {
        id: 'settings.library-configuration.exportSucceed',
        defaultMessage: 'Configurations have successfully been exported',
    },
    exportConfigurationsError: {
        id: 'settings.library-configuration.exportConfigurationsError',
        defaultMessage: 'An error occurred while exporting the configurations',
    },
    configurationsFilename: {
        id: 'settings.library-configuration.configurationsFilename',
        defaultMessage: 'configurations',
    },
});

interface LibraryConfigurationProps {
    className?:ClassValue;
    universeId?:string;
}

interface LibrarySection {
    id:SectionId;
    title:MessageDescriptor;
    content:ReactNode;
    handleAllCheckboxValue?:boolean | 'minus';
    onChangeAllCheckbox?:() => void;
}

enum SectionId {
    ExplorationStylesSection = 'ExplorationStylesSection'
}

export function LibraryConfiguration(props:LibraryConfigurationProps) {
    const { className, universeId } = props;
    const classNames = useClassNames(CLASSNAME);
    const notifications = useArgNotifications();

    const intl = useIntl();

    const canImportExport = useHasPermission<ExplorationPermissions>('exploration.import.export.settings');

    const [searchedToken, setSearchedToken] = useState<string>('');

    const [stylesTemplateConfigurationsKeys, setStylesTemplateConfigurationsKeys] = useState<TemplateId[]>([]);

    const onImportSucceed = useCallback(() => {
        notifications.snackInfo({ message: messages.importSucceed });
    }, [notifications]);

    const {
        explorationStylesTemplates,
        createExplorationStylesTemplates,
        createExplorationStylesTemplateError,
        onDeleteTemplateConfirm: onDeleteStylesTemplateConfirm,
        onRenameTemplateConfirm: onRenameStylesTemplateConfirm,
        refreshExplorationStylesTemplates,
    } = useExplorationStylesTemplates(universeId, onImportSucceed, searchedToken);

    const [loadImportedConfigurations] = useCallbackAsync(async (progressMonitor:ProgressMonitor, file:Blob) => {
        if (!SUPPORTED_IMPORT_MIME_TYPES.includes(file.type)) {
            notifications.snackError({
                message: messages.invalidType,
            });

            throw new Error('Invalid type');
        }

        try {
            const manifest = await configurationConnector.importManifest(file, CONFIGURATION_SCOPE, progressMonitor);

            const expectedTypes = [TemplateType.ExplorationStyle];
            const options = new Array<ConfigurationOption>();

            expectedTypes.forEach(expectedType => {
                const confType = manifest.configurations.find(conf => conf.type === expectedType);
                if (confType) {
                    const configurationTypeKeys = confType.configurations.map(conf => conf.id);
                    if (configurationTypeKeys.length) {
                        options.push({
                            type: expectedType,
                            configurationKeys: configurationTypeKeys,
                            options: {
                                setAsDefault: true,
                                resetDefaultConfiguration: false,
                                parentId: universeId,
                            },
                        });
                    }
                }
            });

            if (!options.length) {
                notifications.snackError({
                    message: messages.invalidConfigurationType,
                });

                throw new Error();
            }

            const importOptions:ImportExportOptions = {
                options: options,
            };

            const result = await configurationConnector.importConfigurations(
                file,
                importOptions,
                ImportAction.RenameConflicts,
                CONFIGURATION_SCOPE,
                progressMonitor
            );

            if (isEmpty(result) || !result.errors) {
                onImportSucceed();
                refreshExplorationStylesTemplates();

                return;
            }

            const nbImportErrors = result.errors.length;

            notifications.notifError(
                {
                    message: messages.importConfigurationsError,
                    description: messages.importConfigurationDescriptionError,
                    details: (
                        <ConfigurationErrorNotificationDescription
                            errors={ result.errors }
                        />
                    ),
                },
                undefined,
                {
                    count: nbImportErrors,
                });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.importConfigurationsError }, error as Error);
            throw error;
        }
    }, [onImportSucceed, refreshExplorationStylesTemplates, universeId, notifications]);

    useEffect(() => {
        if (!createExplorationStylesTemplateError) {
            return;
        }

        notifications.snackError({ message: messages.importConfigurationsError }, createExplorationStylesTemplateError);
    }, [createExplorationStylesTemplateError, notifications]);

    const getDndAction = useCallback((sectionId:SectionId) => {
        const ret:DndAction = {
            dragInfos: (event) => {
                if (!event.types.includes(FILE_TYPE)) {
                    return {
                        supports: false,
                    };
                }

                if (!canImportExport) {
                    return {
                        supports: false,
                    };
                }

                return {
                    supports: true,
                };
            },
            onDrop: (dataTransfer) => {
                const files = [...dataTransfer.files];
                const file = files[0];
                if (SUPPORTED_IMPORT_MIME_TYPES.includes(file.type)) {
                    loadImportedConfigurations(file);
                } else {
                    switch (sectionId) {
                        case SectionId.ExplorationStylesSection: {
                            createExplorationStylesTemplates(files);
                            break;
                        }
                        default: {
                            notifications.snackError({
                                message: messages.invalidType,
                            });
                            break;
                        }
                    }
                }
            },
            dropEffect: 'copy',
        };

        return ret;
    }, [createExplorationStylesTemplates, loadImportedConfigurations]);

    const [handleExportConfigurations] = useCallbackAsync(async (progressMonitor:ProgressMonitor) => {
        try {
            const options = new Array<ConfigurationOption>();

            if (stylesTemplateConfigurationsKeys.length) {
                options.push({
                    type: TemplateType.ExplorationStyle,
                    configurationKeys: stylesTemplateConfigurationsKeys,
                    options: {},
                });
            }
            if (!options.length) {
                return;
            }

            const configurationListToExport:ImportExportOptions = {
                options: options,
            };
            const ret:Blob = await configurationConnector.exportConfigurations(configurationListToExport, CONFIGURATION_SCOPE, progressMonitor);

            downloadBlob(`${intl.formatMessage(messages.configurationsFilename)}-${new Date().getTime()}.zip`, ret);

            notifications.snackInfo({ message: messages.exportSucceed });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.exportConfigurationsError }, error as Error);
            throw error;
        }
    }, [intl, stylesTemplateConfigurationsKeys]);

    const handleStylesTemplateCheck = useCallback((value:boolean, stylesTemplate:ExplorationStyleTemplate) => {
        if (value) {
            setStylesTemplateConfigurationsKeys((prev) => {
                return [...prev, stylesTemplate.id];
            });
        } else {
            setStylesTemplateConfigurationsKeys((prev) => {
                return prev.filter((briefTemplateId) => briefTemplateId !== stylesTemplate.id);
            });
        }
    }, []);

    const isStylesTemplateSelected = useCallback((stylesTemplate:ExplorationStyleTemplate) => {
        return stylesTemplateConfigurationsKeys.includes(stylesTemplate.id);
    }, [stylesTemplateConfigurationsKeys]);

    const allStylesTemplatesCheckboxValue = useMemo(() => {
        if (stylesTemplateConfigurationsKeys.length === 0) {
            return false;
        }
        if (stylesTemplateConfigurationsKeys.length === explorationStylesTemplates.length) {
            return true;
        }

        return 'minus';
    }, [explorationStylesTemplates.length, stylesTemplateConfigurationsKeys.length]);

    const handleAllStylesTemplatesSelection = useCallback(() => {
        if (stylesTemplateConfigurationsKeys.length > 0) {
            setStylesTemplateConfigurationsKeys([]);
        } else {
            const stylesTemplateConfigurationsKeys = explorationStylesTemplates.map((stylesTemplate) => stylesTemplate.id);
            setStylesTemplateConfigurationsKeys(stylesTemplateConfigurationsKeys);
        }
    }, [explorationStylesTemplates, stylesTemplateConfigurationsKeys.length]);

    const _onDeleteStylesTemplateConfirm = useCallback(async (progressMonitor:ProgressMonitor, template:ExplorationStyleTemplate) => {
        await onDeleteStylesTemplateConfirm(progressMonitor, template);
        handleStylesTemplateCheck(false, template);
    }, [handleStylesTemplateCheck, onDeleteStylesTemplateConfirm]);

    const handleSearchedTokenChange = useCallback((searchedToken:string) => {
        setSearchedToken(searchedToken);
    }, []);

    const sections:LibrarySection[] = [{
        id: SectionId.ExplorationStylesSection,
        title: messages.stylesTemplateTitle,
        content: <ExplorationSettingsStylesTemplates className={ classNames('&-styles-templates') }
                                                     stylesTemplates={ explorationStylesTemplates }
                                                     searchedToken={ searchedToken }
                                                     onDeleteTemplateConfirm={ _onDeleteStylesTemplateConfirm }
                                                     onRenameTemplateConfirm={ onRenameStylesTemplateConfirm }
                                                     onCheckAction={ handleStylesTemplateCheck }
                                                     isTemplateSelected={ isStylesTemplateSelected }
        />,
        handleAllCheckboxValue: allStylesTemplatesCheckboxValue,
        onChangeAllCheckbox: handleAllStylesTemplatesSelection,
    }];

    return (
        <DroppingZone>
            <div className={ classNames('&', className) } data-testid='library-configuration'>
                <div className={ classNames('&-header') }>
                    <ArgInputSearch className={ classNames('&-header-search') }
                                    autoFocus={ true }
                                    onInputChange={ handleSearchedTokenChange }
                                    placeholder={ messages.searchPlaceholder } />
                    { canImportExport && <ArgUploaderButton type='ghost'
                                                            size='large'
                                                            className={ classNames('&-header-import') }
                                                            icon='icon-download'
                                                            label={ messages.importLabel }
                                                            acceptedFiles='.zip'
                                                            method={ (file:Blob) => loadImportedConfigurations(file) }
                                                            disabled={ stylesTemplateConfigurationsKeys.length > 0 }

                    /> }
                    { canImportExport && <ArgButton
                        icon='icon-upload'
                        className={ classNames('&-header-export') }
                        label={ messages.export }
                        type='ghost'
                        size='large'
                        disabled={ !stylesTemplateConfigurationsKeys.length }
                        onClick={ handleExportConfigurations }
                    /> }
                </div>
                <div className={ classNames('&-body') }>
                    { sections.map((section, index) => {
                        return (
                            <React.Fragment key={ section.id }>
                                <div className={ classNames('&-section') }>
                                    <ArgCheckboxMinus className={ classNames('&-checkbox') } size='node'
                                                      label={ section.title } value={ section.handleAllCheckboxValue }
                                                      onChange={ section.onChangeAllCheckbox } />
                                    <Droppable actions={ getDndAction(section.id) }
                                               className={ classNames('&-droppable') }
                                    >
                                        { (provided, snapshot) => {
                                            const dropMode = snapshot?.supportsDragging && snapshot?.draggingFromThisWith;
                                            const cls = {
                                                '&-drop-zone': true,
                                                '&-drop-mode': dropMode,
                                            };

                                            return <div className={ classNames(cls) }
                                                        data-label={ intl.formatMessage(messages.dropConfigurationtMessage) }>
                                                { section.content }
                                            </div>;
                                        } }
                                    </Droppable>
                                </div>
                                { index < sections.length - 1 && <ArgDivider /> }
                            </React.Fragment>
                        );
                    }) }
                </div>
            </div>
        </DroppingZone>
    );
}
