import { defineMessages, useIntl } from 'react-intl';

import { ArgCheckbox, ArgTable2, useClassNames } from 'src/components/basic';
import { Group } from 'src/model/user';

import './groups-list.less';

const messages = defineMessages({
    self: {
        id: 'settings.groups-list.self',
        defaultMessage: 'You cannot select this group because you cannot add a group to itself.',
    },
    ancestor: {
        id: 'settings.groups-list.ancestor',
        defaultMessage:
            'This group cannot be a member of group {groupName}, because {groupName} belongs to this group.',
    },
    noGroups: {
        id: 'settings.groups-list.noGroups',
        defaultMessage: 'No groups',
    },
});

export interface GroupsListProps {
    groups: Group[];
    search: string;
    groupIdsSelected: string[];
    handleCheckboxSelection: (checked: boolean, id: string) => void;
    groupIdsDisabled: string[];
    selectedGroupId?: string;
    selectedGroupName?: string;
}

export const GroupsList: React.FunctionComponent<GroupsListProps> = ({
    groups,
    search,
    groupIdsSelected,
    handleCheckboxSelection,
    groupIdsDisabled,
    selectedGroupId,
    selectedGroupName,
}) => {
    const intl = useIntl();
    const classNames = useClassNames('groups-list');
    const columns = [
        {
            dataIndex: 'id',
            key: 'check',
            width: 40,
            render: function header(id: string) {
                let tooltip: string | undefined;
                if (selectedGroupId === id) {
                    tooltip = intl.formatMessage(messages.self);
                } else if (groupIdsDisabled.includes(id)) {
                    tooltip = intl.formatMessage(messages.ancestor, {
                        groupName: selectedGroupName,
                    });
                }

                return (
                    <ArgCheckbox
                        size='node'
                        value={groupIdsSelected.includes(id)}
                        onChange={(checked) => handleCheckboxSelection(checked, id)}
                        disabled={groupIdsDisabled.includes(id)}
                        tooltip={tooltip}
                    />
                );
            },
        },
        { key: 'Name', dataIndex: 'name' },
    ];

    return (
        <>
            {groups.length !== 0 ? (
                <div>
                    <ArgTable2 columns={columns} dataSource={groups} search={search} />
                </div>
            ) : (
                <div className={classNames('&-empty')}>{intl.formatMessage(messages.noGroups)}</div>
            )}
        </>
    );
};
