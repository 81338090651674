import { isEqual, isNumber } from 'lodash';
import * as UUID from 'uuid';

import { ActionsEngine } from '../../../../utils/actions/actions-engine';
import { EventEmitter, immutableSet, immutableUpdate, ProgressMonitorsFactoryType } from '../../../../components/basic';
import { FormContainer, FormDocument, FormElement, FormTab, FormTabs } from '../../../../components/common/forms/model';
import { computeElementPath, computeParentMap, findFormElement, removeFormElement } from '../utils';
import { JobQuitControl } from 'src/contexts/job-quit-control-context';

export class FormActionsEngine extends ActionsEngine<FormRepository> {
    constructor(progressMonitorsFactory: ProgressMonitorsFactoryType, formDocument: FormDocument, jobQuitControl?: JobQuitControl) {
        super(progressMonitorsFactory, new FormRepository(formDocument), undefined, undefined, jobQuitControl);
    }

    ignoreHistory(): boolean {
        return false;
    }
}

export interface RepositoryEventTypes {
    '*': (stateId: number) => void;
    Updated: (formDocument: FormDocument) => void;
    Added: (formDocument: FormDocument, addFormElement: FormElement[]) => void;
    Removed: (formDocument: FormDocument, removedFormElement: FormElement[]) => void;
    Set: (formDocument: FormDocument) => void;
    MovePage: (formDocument: FormDocument, movedFormElement: FormElement) => void;
}

export class FormRepository extends EventEmitter<RepositoryEventTypes> {
    #formDocument: FormDocument;

    constructor(formDocument: FormDocument) {
        super();
        this.#formDocument = formDocument;
    }

    get formDocument(): FormDocument {
        return this.#formDocument;
    }

    change(formElement: FormElement, propertyName: string, value: any) {
        const parentMap = computeParentMap(this.formDocument);

        const path = computeElementPath(formElement, parentMap, propertyName);

        const newFormDocument = immutableSet(this.#formDocument, path, value);

        if (isEqual(newFormDocument, this.formDocument)) {
            return;
        }

        this.#formDocument = newFormDocument;
        this.updateStateId();

        this.emit('Updated', newFormDocument);
    }

    private doAdd(container: FormContainer, newElements: FormElement[], index?: number): FormDocument {
        let newFormDocument = this.formDocument;

        for (const newElement of newElements) {
            newFormDocument = removeFormElement(newFormDocument, newElement.id);
            const newParentMap = computeParentMap(newFormDocument);
            const newContainer = findFormElement(newFormDocument, container.id);
            const path = computeElementPath(newContainer as FormElement, newParentMap);

            if (!container.children) {
                newFormDocument = immutableSet(newFormDocument, [...path, 'children'], [newElement]);
                continue;
            }
            newFormDocument = immutableUpdate(newFormDocument, [...path, 'children'], (prev: FormElement[]) => {
                const newList = [...(prev ?? [])];

                if (isNumber(index)) {
                    newList.splice(index, 0, newElement);

                    return newList;
                }

                newList.push(newElement);

                return newList;
            });
        }

        return newFormDocument;
    }

    add(container: FormContainer, newElements: FormElement[], index?: number) {
        const newFormDocument = this.doAdd(container, newElements, index);

        if (isEqual(newFormDocument, this.formDocument)) {
            return;
        }

        this.#formDocument = newFormDocument;
        this.updateStateId();

        this.emit('Added', newFormDocument, newElements);
    }

    remove(removedFormElements: FormElement[]): boolean {
        let newFormDocument = this.formDocument;

        for (const removedFormElement of removedFormElements) {
            newFormDocument = removeFormElement(newFormDocument, removedFormElement.id);
        }

        if (isEqual(newFormDocument, this.formDocument)) {
            return false;
        }

        this.#formDocument = newFormDocument;
        this.updateStateId();

        this.emit('Removed', newFormDocument, removedFormElements);

        return true;
    }


    setDocument(newFormDocument: FormDocument, chekEquality = true): boolean {
        if (chekEquality && isEqual(newFormDocument, this.formDocument)) {
            return false;
        }

        this.#formDocument = newFormDocument;
        this.updateStateId();

        this.emit('Set', newFormDocument);

        return true;
    }

    addPages(container: FormContainer, newElements: FormTab[], index: number | undefined, defaultPageName: string) {
        let newContainer: FormContainer | undefined = container.children.find((child) => child.type === 'tabs') as FormTabs | undefined;

        if (!newContainer) {
            const tabs: FormTabs = {
                type: 'tabs',
                id: UUID.v4(),
                children: [
                    {
                        type: 'tab',
                        id: UUID.v4(),
                        children: container.children,
                        name: defaultPageName,
                    },
                ],
            };
            const parentMap = computeParentMap(this.formDocument);

            const path = computeElementPath(container as FormElement, parentMap);

            const newFormDocument = immutableSet(this.#formDocument, [...path, 'children'], [tabs]);
            this.#formDocument = newFormDocument;
            newContainer = tabs;
        }
        const newFormDocument = this.doAdd(newContainer, newElements, index);

        if (isEqual(newFormDocument, this.formDocument)) {
            return;
        }

        this.#formDocument = newFormDocument;
        this.updateStateId();

        this.emit('Added', newFormDocument, newElements);
    }

    removePages(container: FormContainer, removedFormElements: FormTab[]): boolean {
        let newFormDocument = this.formDocument;

        for (const removedFormElement of removedFormElements) {
            newFormDocument = removeFormElement(newFormDocument, removedFormElement.id);
        }

        if (isEqual(newFormDocument, this.formDocument)) {
            return false;
        }

        const tabs = container.children.find((child) => child.type === 'tabs') as FormTabs | undefined;
        const newTabItems = tabs?.children.filter((child) => !removedFormElements.find((removedFormElement) => removedFormElement.id === child.id));
        if (newTabItems && newTabItems.length === 1 && newTabItems[0].type === 'tab') {
            const parentMap = computeParentMap(this.formDocument);

            const path = computeElementPath(container as FormElement, parentMap);

            const newContainer = {
                ...container,
                children: newTabItems[0].children,
            };

            newFormDocument = immutableSet(newFormDocument, path, newContainer);
        }

        this.#formDocument = newFormDocument;
        this.updateStateId();

        this.emit('Removed', newFormDocument, removedFormElements);

        return true;
    }

    movePage(container: FormContainer, element: FormTab, index: number) {
        const newFormDocument = this.doAdd(container, [element], index);

        if (isEqual(newFormDocument, this.formDocument)) {
            return;
        }

        this.#formDocument = newFormDocument;
        this.updateStateId();

        this.emit('MovePage', newFormDocument, element);
    }

    changeElement(formElement: FormElement, value: FormElement) {
        const parentMap = computeParentMap(this.formDocument);

        const path = computeElementPath(formElement, parentMap);

        const newFormDocument = immutableSet(this.#formDocument, path, value);

        if (isEqual(newFormDocument, this.formDocument)) {
            return;
        }

        this.#formDocument = newFormDocument;
        this.updateStateId();

        this.emit('Updated', newFormDocument);
    }
}
