import { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ArgModalContainer, useClassNames } from 'src/components/basic';
import { PageHeader } from 'src/settings/common-components/page-header';
import { Role, RolesScope } from 'src/settings/models/dtoApi';
import { RoleInformationsPanel } from './role-informations-panel';
import { RolesManager } from './roles-manager';

import './roles-view.less';

const messages = defineMessages({
    title: {
        id: 'settings.roles.title',
        defaultMessage: 'Roles',
    },
});

export enum PageHeadersButtons {
    INFO = 'info',
    HISTORY = 'history',
}

export function RolesView() {
    const { rolesScope: rolesScopeParam } = useParams<{ rolesScope: RolesScope }>();

    // A FAIRE avec les TOOLBARs ! Surtout pas comme ça !
    const [headerButtonSelected, setHeaderButtonSelected] = useState<string>('');
    const [selectedRole, setSelectedRole] = useState<Role>();
    const [rolesScope, setRolesScope] = useState<RolesScope>('admin');

    const classNames = useClassNames('settings-roles-view');

    useEffect(() => {
        if (rolesScopeParam) {
            setRolesScope(rolesScopeParam);
        }
    }, [rolesScopeParam]);

    return <div className={classNames('&-wrapper')}>
        <PageHeader
            title={messages.title}
        />
        <ArgModalContainer>
            <div className={classNames('&-container')}>
                <div className={classNames('&-container-body')}>
                    <RolesManager
                        rolesScope={rolesScope}
                        selectedRole={selectedRole}
                        setSelectedRole={setSelectedRole}
                    />
                </div>
                {headerButtonSelected === PageHeadersButtons.INFO && (
                    <div className={classNames('&-aside')}>
                        <RoleInformationsPanel selectedRole={selectedRole} />
                    </div>
                )}
            </div>
        </ArgModalContainer>
    </div>;
}
