import { EdgeStyle } from '../model/edge';

export const DEFAULT_EDGE_STYLE = {
    color: '#8d9db1',
    size: 1,
    lineStyle: 'solid',
    lineAnimation: 'No Animation',
} as Required<EdgeStyle>;


export const RELATIONSHIP_LINE_DRAGGER_STYLE = {
    color: '#39939E',
    width: 3,
};
