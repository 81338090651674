import { useEffect, useRef, useState } from 'react';

import { BlobImagesProvider } from '../utils/blob-images-provider';

export function useBlobImagesProvider(): BlobImagesProvider {
    const [, setStateId] = useState<number>(0);

    const blobImagesProviderRef = useRef<BlobImagesProvider>();
    if (!blobImagesProviderRef.current) {
        blobImagesProviderRef.current = new BlobImagesProvider();
    }

    useEffect(() => {
        const blobImagesProvider = blobImagesProviderRef.current!;

        function updateBlobImages() {
            setStateId((prev) => prev + 1);
        }

        blobImagesProvider.on('Change', updateBlobImages);

        return () => {
            blobImagesProvider.off('Change', updateBlobImages);
            blobImagesProvider.destroy(); //releases the existing object URL which was previously created by calling URL.createObjectURL()
        };
    }, []);

    return blobImagesProviderRef.current;
}
