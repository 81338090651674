import { Plugin } from '@ckeditor/ckeditor5-core';
import { modelToViewStyleAttribute, viewToModelStyleAttribute } from './converters';
import { getDefaultStylesConfiguration, normalizeStyles } from './utils';
import { ScreenshotEditing } from '../screenshot-editing';

export default class ScreenshotStyle extends Plugin {
    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'argonos-resource-screenshot:style';
    }

    /**
     * @inheritDoc
     */
    static get requires() {
        return [ScreenshotEditing];
    }

    /**
     * @inheritDoc
     */
    init() {
        /**
         * It contains a list of the normalized and validated style options.
         *
         * * Each option contains a complete icon markup.
         * * The style options not supported by any of the loaded image editing plugins (
         * {@link module:image/image/imageinlineediting~ImageInlineEditing `ImageInlineEditing`} or
         * {@link module:image/image/imageblockediting~ImageBlockEditing `ImageBlockEditing`}) are filtered out.
         *
         * @protected
         * @readonly
         * @type {module:image/imagestyle~ImageStyleConfig}
         */
        const editor = this.editor;

        editor.config.define('screenshot.styles', getDefaultStylesConfiguration());

        this.normalizedStyles = normalizeStyles(editor.config.get('screenshot.styles'));

        this._setupConversion();
    }

    /**
     * Sets the editor conversion taking the presence of
     * {@link module:image/image/imageinlineediting~ImageInlineEditing `ImageInlineEditing`}
     * and {@link module:image/image/imageblockediting~ImageBlockEditing `ImageBlockEditing`} plugins into consideration.
     *
     * @private
     * @param {Boolean} isBlockPluginLoaded
     * @param {Boolean} isInlinePluginLoaded
     */
    _setupConversion() {
        const editor = this.editor;
        const schema = editor.model.schema;

        const modelToViewConverter = modelToViewStyleAttribute(this.normalizedStyles);
        const viewToModelConverter = viewToModelStyleAttribute(this.normalizedStyles);

        editor.editing.downcastDispatcher.on('attribute:screenshotStyle', modelToViewConverter);
        editor.data.downcastDispatcher.on('attribute:screenshotStyle', modelToViewConverter);

        schema.extend('argonos-resource-screenshot', { allowAttributes: 'screenshotStyle' });

        // Converter for figure element from view to model.
        editor.data.upcastDispatcher.on('element:figure', viewToModelConverter, { priority: 'low' });
    }
}

export function isScreenshot(modelElement) {
    return !!modelElement && modelElement.is('element', 'screenshot');
}
