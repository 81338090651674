import React, { KeyboardEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';

import { ArgInputText, ArgInputTextProps } from './arg-input-text';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgButton } from '../arg-button/arg-button';
import { ArgChangeReason, ArgInputSearchType } from '../types';

import './arg-input-search.less';

const messages = defineMessages({
    nextHit: {
        id: 'basic.arg-input-search.NextHit',
        defaultMessage: 'Next hit',
    },
    previousHit: {
        id: 'basic.arg-input-search.PreviousHit',
        defaultMessage: 'Previous hit',
    },
    filter: {
        id: 'basic.arg-input-search.Filter',
        defaultMessage: 'Filter',
    },
    placeholder: {
        id: 'basic.arg-input-search.Placeholder',
        defaultMessage: 'Search',
    },
});

export interface ArgInputSearchProps extends ArgInputTextProps {
    initialIndex?: number;
    index?: number;
    count?: number;
    searchType?: ArgInputSearchType;
    onSearchIndexChange?: (index: number) => void;
}


export function ArgInputSearch(props: ArgInputSearchProps): ReactElement {
    const {
        initialIndex,
        index: externalIndex,
        count,
        onChange,
        onSearchIndexChange,
        searchType = 'search',
        left,
        className,
        value,
        clearable,
        placeholder,
    } = props;
    const classNames = useClassNames('arg-input-search');

    const [internalIndex, setInternalIndex] = useState<number | undefined>(initialIndex);

    const useInternalIndex = !('index' in props);

    const index: number | undefined = ((useInternalIndex) ? internalIndex : externalIndex);

    const handlePreviousClick = useCallback(() => {
        let newIndex = index! - 1;
        if (newIndex < 0) {
            newIndex = count! - 1;
        }

        if (useInternalIndex) {
            setInternalIndex(newIndex);
        }

        onSearchIndexChange && onSearchIndexChange(newIndex);
    }, [index, useInternalIndex, onSearchIndexChange, count]);

    const handleNextClick = useCallback(() => {
        let newIndex = index! + 1;
        if (newIndex >= count!) {
            newIndex = 0;
        }

        if (useInternalIndex) {
            setInternalIndex(newIndex);
        }

        onSearchIndexChange && onSearchIndexChange(newIndex);
    }, [index, count, useInternalIndex, onSearchIndexChange]);

    useEffect(() => {
        if (count === undefined || !!index) {
            return;
        }

        if (useInternalIndex) {
            setInternalIndex(0);
        }

        onSearchIndexChange && onSearchIndexChange(0);
    }, [count, index, value]);

    const handleKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (!count) {
            return;
        }
        if (event.key === 'ArrowUp') {
            event.preventDefault();
            event.stopPropagation();
            handlePreviousClick();

            return;
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            event.stopPropagation();
            handleNextClick();

            return;
        }
    }, [count, handleNextClick, handlePreviousClick]);

    const handleOnChange = useCallback((value: string | null, reason: ArgChangeReason) => {
        if (useInternalIndex) {
            setInternalIndex((index !== undefined) ? 0 : undefined);
        }

        onChange?.(value, reason);
    }, [useInternalIndex, onChange, index]);

    const _left = useMemo(() => {
        if (left) {
            return left;
        }

        if (searchType === 'filter') {
            return 'icon-filter';
        }

        return 'magnifier';
    }, [left, searchType]);

    const right = count !== undefined && <>
        {index !== undefined && <span className={classNames('&-index')}>{(count) ? (index + 1) : 0}</span>}
        <span className={classNames('&-count')}>{count}</span>
        {index !== undefined && <ArgButton
            key='previous'
            type='ghost'
            disabled={!count}
            className={classNames('&-previous')}
            icon='icon-triangle-up'
            tooltip={messages.previousHit}
            tooltipPlacement='top'
            onClick={handlePreviousClick}
            data-testid='input-search-previous'
        />}
        {index !== undefined && <ArgButton
            key='next'
            type='ghost'
            className={classNames('&-next')}
            icon='icon-triangle-down'
            disabled={!count}
            tooltip={messages.nextHit}
            tooltipPlacement='top'
            onClick={handleNextClick}
            data-testid='input-search-next'
        />}
    </>;

    let _placeholder = placeholder;
    if (!_placeholder && searchType === 'filter') {
        _placeholder = messages.filter;
    }
    if (!_placeholder) {
        _placeholder = messages.placeholder;
    }

    return (
        <ArgInputText
            {...props}
            placeholder={_placeholder}
            className={classNames('&', className)}
            left={_left}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            clearable={(!right) ? clearable : false}
            right={right}
        />
    );
}
