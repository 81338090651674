import { Dispatch, SetStateAction, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ArgButton, ArgModal, useClassNames, useArgNotifications } from 'src/components/basic';
import { FullOntology, FullOntologyObjectType } from '../../types';
import { NotifyBox } from 'src/settings/common-modals/notify-box/notify-box';
import { WARNING_ORANGE } from 'src/settings/models/colors';
import ontologiesConnector from '../../../../connectors/ontologies-connector';

import './delete-object-modal.less';

interface DeleteObjectModalProps {
    closeModal: () => void;
    vertex: FullOntologyObjectType;
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
}

export const messages = defineMessages({
    title: {
        id: 'settings.delete-object-modal.title',
        defaultMessage: 'Delete object',
    },
    description: {
        id: 'settings.delete-object-modal.description',
        defaultMessage: 'Are you sure you want to delete the object?',
    },
    warningMessage: {
        id: 'settings.delete-object-modal.warningMessage',
        defaultMessage:
            'The object, along with its relationships and associated forms, will no longer be considered in the Universe.',
    },
    cancel: {
        id: 'settings.delete-object-modal.button.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.delete-object-modal.button.delete',
        defaultMessage: 'Delete',
    },
    deleteObjectErrorMessage: {
        id: 'settings.delete-object-modal.error-message',
        defaultMessage: 'Something went wrong while deleting the object',
    },
});

export const DeleteObjectModal: React.FC<DeleteObjectModalProps> = ({
    closeModal,
    vertex,
    ontology,
    setOntology,
}) => {
    const intl = useIntl();
    const classNames = useClassNames('delete-object-modal');
    const notifications = useArgNotifications();

    const [loading, setLoading] = useState(false);

    const deleteObject = async () => {
        setLoading(true);
        try {
            await ontologiesConnector.deleteObject(ontology.id, vertex.name);
            setOntology(await ontologiesConnector.getFullOntology(ontology.id));
            setLoading(false);
            closeModal();
        } catch (error) {
            notifications.snackError({ message: messages.deleteObjectErrorMessage }, error as Error);
            setLoading(false);
        }
    };

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={closeModal}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={closeModal}
                        label={messages.cancel}
                        disabled={loading}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        label={messages.delete}
                        data-testid='delete'
                        onClick={deleteObject}
                        disabled={loading}
                    />
                </div>
            }
        >
            <NotifyBox
                icon='icon-exclamation-point'
                color={WARNING_ORANGE}
                text={intl.formatMessage(messages.description)}
                subtext={intl.formatMessage(messages.warningMessage)}
            />
        </ArgModal>
    );
};
