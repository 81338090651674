import React, { useCallback, useMemo, useState } from 'react';

import {
    ArgButton,
    ArgToolMenu,
    ClassValue,
    OffsetValue,
    SelectionProvider,
    ToolContext,
    useClassNames,
} from 'src/components/basic/index';

import './arg-ellipsis-menu-button.less';

const CLASSNAME = 'arg-ellipsis-menu-button';

const POPOVER_OFFSET:OffsetValue = { mainAxis: 4, crossAxis: 2 };


export interface TargetEnvironmentContext<T, K> {
    parentContext: T;

    first(): Promise<K|undefined>;
    listKeys(): string[]|undefined;
    getItem(key: string): Promise<K|undefined>;
    count(): number;
}

interface ArgEllipsisMenuButtonProps<T, K> {
    className?: ClassValue;

    toolContext: ToolContext<TargetEnvironmentContext<T, K>>;
    environmentContext: T;

    selectionProvider?: SelectionProvider<K>;
    getTarget?: (key: string) => Promise<K|undefined>;

    selectedKey?: string;
    selectedObject?: K;
}

export function ArgEllipsisMenuButton<T=undefined, K=any>(props: ArgEllipsisMenuButtonProps<T, K>) {
    const {
        className,
        toolContext,
        environmentContext,
        selectionProvider,
        selectedKey,
        selectedObject,
        getTarget,
    } = props;

    const [visible, setVisible] = useState<boolean>(false);

    const classNames = useClassNames(CLASSNAME);

    const targetEnvironmentContext = useMemo<TargetEnvironmentContext<T, K>>(() => {
        const result:TargetEnvironmentContext<T, K> = {
            parentContext: environmentContext,
            async first(): Promise<K|undefined> {
                if (selectedObject !== undefined) {
                    return selectedObject;
                }
                if (selectedKey !== undefined && getTarget) {
                    const selectedObject = await getTarget(selectedKey);

                    return selectedObject;
                }
                if (selectionProvider && getTarget) {
                    const firstKey = selectionProvider.first();
                    if (firstKey === undefined) {
                        return undefined;
                    }

                    const firstObject = await getTarget(firstKey);

                    return firstObject;
                }

                return undefined;
            },
            async getItem(key: string): Promise<K | undefined> {
                if (!getTarget) {
                    throw new Error('getItem is not defined !');
                }

                const result = await getTarget(key);

                return result;
            },
            listKeys(): string[]|undefined {
                if (selectedKey !== undefined) {
                    return [selectedKey];
                }
                if (selectionProvider) {
                    return selectionProvider.list();
                }

                return undefined;
            },
            count(): number {
                if (selectedKey !== undefined) {
                    return 1;
                }
                if (selectionProvider) {
                    return selectionProvider.count;
                }

                return 0;
            },
        };

        return result;
    }, [environmentContext, selectionProvider, selectedKey]);

    const handleCloseMenu = useCallback(() => {
        setVisible(false);
    }, []);

    const actionsMenu = <ArgToolMenu<TargetEnvironmentContext<T, K>>
        menuContext={toolContext}
        onCloseMenu={handleCloseMenu}
        environmentContext={targetEnvironmentContext}
    />;

    return (
        <ArgButton
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={visible}
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
            className={classNames('&', className)}
            popoverClassName={classNames('&-popover')}
            popoverOffset={POPOVER_OFFSET}
        />
    );
}
