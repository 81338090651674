import React, { useCallback, useState } from 'react';

import { Template } from '../../../../../model/template';
import { ArgButton, ArgSize, ClassValue, ProgressMonitor, useClassNames } from '../../../../../components/basic';
import { TemplateActionsMenuPopup } from './template-action-menu-popup';

const CLASSNAME = 'template-actions-menu';

interface TemplateActionsMenuProps<TContent> {
    className?: ClassValue;
    template: Template<TContent>;
    size?: ArgSize | 'node';
    onDeleteTemplateConfirm?: (progressMonitor: ProgressMonitor, template: Template<TContent>) => Promise<void>;
    onRenameTemplateConfirm?: (progressMonitor: ProgressMonitor, template: Template<TContent>, newName: string) => Promise<void>;
}

export function TemplateActionsMenu<TContent>(props: TemplateActionsMenuProps<TContent>) {
    const {
        className,
        template,
        size = 'small',
        onDeleteTemplateConfirm,
        onRenameTemplateConfirm,
    } = props;

    const [visible, setVisible] = useState<boolean>(false);

    const classNames = useClassNames(CLASSNAME);

    const renderPopup = useCallback(() => {
        if (!visible) {
            return;
        }

        return <TemplateActionsMenuPopup
            template={template}
            setVisible={setVisible}
            onDeleteTemplateConfirm={onDeleteTemplateConfirm}
            onRenameTemplateConfirm={onRenameTemplateConfirm}
        />;
    }, [onDeleteTemplateConfirm, onRenameTemplateConfirm, template, visible]);

    return (
        <ArgButton
            className={classNames('&', className)}
            type='ghost'
            icon='icon-options'
            size={size}
            popover={() => renderPopup()}
            popoverTrigger='click'
            popoverVisible={visible}
            data-testid='actions-menu'
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
            popoverClassName={classNames('&-popover')}
        />
    );
}
