import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { GetOntologySchemaDTO } from 'src/settings/models/dtoApi';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import {
    ArgButton,
    ProgressMonitor,
    SubProgressMonitor,
    useCallbackAsync,
    useClassNames,
    useEffectAsync,
    useArgNotifications,
} from 'src/components/basic';
import {
    ValuationRulesPageToolbar,
} from '../components/rules/valuation-rules-page-toolbar/valuation-rules-page-toolbar';
import { ValuationPolicyRuleBlock } from '../components/rules/valuation-policy-rule-block/valuation-policy-rule-block';
import { getValuationPolicyWithActionsIds } from './utils';
import { ValuationPolicyId, ValuationPolicyProcessed } from 'src/settings/models/valuation-policy';
import { OntologyContext } from '../../common/providers/policy-rules-provider';
import { UserProfileField } from '../../../../model/user-metadata';
import ontologiesConnector from '../../../connectors/ontologies-connector';
import { ErrorPane } from '../../../../components/common/panes/error-pane';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';

import './valuation-policy-rules-view.less';

const messages = defineMessages({
    publish: {
        id: 'settings.valuation-policy-rules.publish-button',
        defaultMessage: 'Publish',
    },
    fetchData: {
        id: 'settings.valuation-policy-rules.fetching-policy',
        defaultMessage: 'Loading policy',
    },
    fetchDataError: {
        id: 'settings.valuation-policy-rules.fetching-policy.error',
        defaultMessage: 'An error occurred while fetching the policy',
    },
    publishPolicyError: {
        id: 'settings.valuation-policy-rules.fetching-policy.publishingPolicy',
        defaultMessage: 'An error occurred while publishing the policy',
    },
    publishPolicySuccess: {
        id: 'settings.valuation-policy-rules.fetching-policy.publishingPolicy.success',
        defaultMessage: 'Valuation policy published',
    },
    publishPolicySuccessDescription: {
        id: 'settings.valuation-policy-rules.fetching-policy.publishingPolicy.success.description',
        defaultMessage: 'The valuation policy has successfully been published',
    },
    emptyRules: {
        id: 'settings.valuation-policy-rules.empty',
        defaultMessage: 'No rules',
    },
});

export const ValuationPolicyRulesView = () => {
    const classNames = useClassNames('settings-valuation-policy-rules-view');
    const [search, setSearch] = useState<string | null>(null);
    const [ontologySchema, setOntologySchema] = useState<GetOntologySchemaDTO>();
    const [policy, setPolicy] = useState<ValuationPolicyProcessed>();
    const [editableRuleIds, setEditableRuleIds] = useState<Set<string>>(new Set());
    const [userProfileFields, setUserProfileFields] = useState<UserProfileField[]>([]);
    const { policyId, ontologyId, universeId } =
        useParams<{ ontologyId: string; universeId: string; policyId: string }>();

    const intl = useIntl();
    const notifications = useArgNotifications();

    const [fetchPolicyAndUniverseMonitor, error] = useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        if (!universeId || !ontologyId || !policyId) {
            return;
        }

        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        const sub2 = new SubProgressMonitor(progressMonitor, 1);
        const sub3 = new SubProgressMonitor(progressMonitor, 1);

        try {
            const [ontologySchema, policy, profileFields] = await Promise.all([
                ontologiesConnector.getOntologySchema(ontologyId, sub1),
                explorationSettingsConnector.getValuationPolicy(policyId, sub2),
                explorationSettingsConnector.getUserProfileFields(sub3),
            ]);

            setOntologySchema(ontologySchema);
            setPolicy(getValuationPolicyWithActionsIds(policy));
            setUserProfileFields(profileFields);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.fetchDataError }, error as Error);
            throw error;
        }
    }, [ontologyId, policyId, universeId, notifications], messages.fetchData);


    const unpublishable = policy?.statement.Actions.some((action) =>
        editableRuleIds.has(action.id)
    );

    const editPolicyEditableState = (editable: boolean, id: string) => {
        const newSet = new Set(editableRuleIds);
        if (editable) {
            newSet.add(id);
            setEditableRuleIds(newSet);

            return;
        }
        newSet.delete(id);
        setEditableRuleIds(newSet);
    };

    const [publishPolicy, publishProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor, id: ValuationPolicyId) => {
        try {
            await explorationSettingsConnector.publishValuationPolicy(id, 'Publish');
            notifications.snackSuccess({
                message: messages.publishPolicySuccess,
                description: messages.publishPolicySuccessDescription,
            });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.publishPolicyError }, error as Error);
            throw error;
        }
    }, [notifications]);


    if (error) {
        return (
            <div className={classNames('&', 'arg-layout')}>
                <ErrorPane error={error} />
            </div>
        );
    }

    if (fetchPolicyAndUniverseMonitor?.isRunning || !ontologySchema || !policy) {
        return (
            <div className={classNames('&', 'arg-layout')}>
                <LoadingPane progressMonitor={fetchPolicyAndUniverseMonitor} />
            </div>
        );
    }

    return (
        <OntologyContext.Provider
            value={{
                ontologySchema: ontologySchema,
                userProfileFields: userProfileFields,
            }}
        >
            <div className={classNames('&')}>
                <ArgButton
                    size='medium'
                    type='primary'
                    className={classNames('&-publish-button')}
                    label={messages.publish}
                    onClick={() => publishPolicy(policy.id)}
                    disabled={unpublishable || publishProgressMonitor?.isRunning}
                    loading={publishProgressMonitor?.isRunning}
                />
                <ValuationRulesPageToolbar
                    search={search}
                    setSearch={setSearch}
                    policy={policy}
                    setPolicy={setPolicy}
                    editableRuleIds={editableRuleIds}
                />
                <div className={classNames('&-rules-container')}>
                    {!policy?.statement.Actions.length && (
                        <div className={classNames('&-empty')}>
                            <EmptyPane
                                message={intl.formatMessage(messages.emptyRules)}
                            />
                        </div>
                    )}
                    {policy?.statement.Actions.map((action, idx) => {
                        return (
                            <ValuationPolicyRuleBlock
                                key={action.id}
                                rule={action}
                                index={idx}
                                setPolicy={setPolicy}
                                policy={policy}
                                editable={editableRuleIds.has(action.id)}
                                setEditable={(editable) =>
                                    editPolicyEditableState(editable, action.id)
                                }
                            />
                        );
                    })}
                </div>
            </div>
        </OntologyContext.Provider>
    );
};
