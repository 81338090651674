export const SVG = `<!-- Generated by IcoMoon.io -->
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
<title></title>
<g id="icomoon-ignore">
    <line stroke-width="1" x1="16" y1="0" x2="16" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="32" y1="0" x2="32" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="48" y1="0" x2="48" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="64" y1="0" x2="64" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="80" y1="0" x2="80" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="96" y1="0" x2="96" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="112" y1="0" x2="112" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="128" y1="0" x2="128" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="144" y1="0" x2="144" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="160" y1="0" x2="160" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="176" y1="0" x2="176" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="192" y1="0" x2="192" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="208" y1="0" x2="208" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="224" y1="0" x2="224" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="240" y1="0" x2="240" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="256" y1="0" x2="256" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="272" y1="0" x2="272" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="288" y1="0" x2="288" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="304" y1="0" x2="304" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="320" y1="0" x2="320" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="336" y1="0" x2="336" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="352" y1="0" x2="352" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="368" y1="0" x2="368" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="384" y1="0" x2="384" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="400" y1="0" x2="400" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="416" y1="0" x2="416" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="432" y1="0" x2="432" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="448" y1="0" x2="448" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="464" y1="0" x2="464" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="480" y1="0" x2="480" y2="512" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="496" y1="0" x2="496" y2="512" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="16" x2="512" y2="16" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="32" x2="512" y2="32" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="48" x2="512" y2="48" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="64" x2="512" y2="64" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="80" x2="512" y2="80" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="96" x2="512" y2="96" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="112" x2="512" y2="112" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="128" x2="512" y2="128" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="144" x2="512" y2="144" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="160" x2="512" y2="160" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="176" x2="512" y2="176" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="192" x2="512" y2="192" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="208" x2="512" y2="208" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="224" x2="512" y2="224" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="240" x2="512" y2="240" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="256" x2="512" y2="256" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="272" x2="512" y2="272" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="288" x2="512" y2="288" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="304" x2="512" y2="304" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="320" x2="512" y2="320" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="336" x2="512" y2="336" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="352" x2="512" y2="352" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="368" x2="512" y2="368" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="384" x2="512" y2="384" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="400" x2="512" y2="400" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="416" x2="512" y2="416" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="432" x2="512" y2="432" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="448" x2="512" y2="448" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="464" x2="512" y2="464" stroke="#449FDB" opacity="0.3"></line>
    <line stroke-width="1" x1="0" y1="480" x2="512" y2="480" stroke="#449FDB" opacity="1"></line>
    <line stroke-width="1" x1="0" y1="496" x2="512" y2="496" stroke="#449FDB" opacity="0.3"></line>
</g>
<path d="M178.646 166.699c-7.917 2.434-16.328 3.733-25.048 3.733-47.119 0-85.329-38.21-85.329-85.329s38.21-85.329 85.329-85.329c47.143 0 85.329 38.211 85.329 85.329 0 29.987-15.478 56.382-38.872 71.6 0 0 26.183 56.099 26.183 56.099 14.603-5.316 30.389-8.223 46.836-8.223 33.437 0 64.085 12.004 87.834 31.972 0 0 56.312-56.311 56.312-56.311-4.82-7.798-7.609-17.014-7.609-26.868 0-28.286 22.921-51.208 51.183-51.208 28.286 0 51.207 22.922 51.207 51.208 0 28.262-22.921 51.183-51.207 51.183-9.854 0-19.046-2.764-26.868-7.609 0 0-56.311 56.335-56.311 56.335 19.967 23.725 31.995 54.374 31.995 87.811 0 75.405-61.132 136.536-136.536 136.536-47.757 0-89.772-24.505-114.182-61.628 0 0-58.202 31.334-58.202 31.334 1.111 4.23 1.725 8.649 1.725 13.233 0 28.262-22.922 51.183-51.207 51.183s-51.207-22.921-51.207-51.183c0-28.286 22.922-51.207 51.207-51.207 15.218 0 28.877 6.64 38.258 17.18 0 0 58.202-31.334 58.202-31.334-7.16-16.589-11.13-34.879-11.13-54.114 0-50.545 27.482-94.663 68.292-118.27 0 0-26.183-56.122-26.183-56.122s0 0 0 0"></path>
</svg>
`;
