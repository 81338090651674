import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isFunction } from 'lodash';
import Debug from 'debug';
import { defineMessages } from 'react-intl';

import {
    $yield,
    AbstractConfigurations,
    ArgGlobalNotificationType,
    ConfigurationChangedReason,
    ConfigurationPath,
    ProgressMonitor,
    useArgNotifications,
    useDebounce,
    useEffectAsync,
} from '../components/basic';
import { BasicState, StateId } from '../utils/rt-states/basic-state';
import { useInitialValue } from './use-initial-value';

const messages = defineMessages({
    loadingConfiguration: {
        id: 'common.useConfiguration.LoadingConfiguration',
        defaultMessage: 'Loading configuration {threeDotsLoading}',
    },
});

type ChangeActionValue<T> = ((currentValue: T) => T) | T;
export type ChangeAction<T> = (value: SetStateAction<T>, isReadonly?: boolean) => void;

const debug = Debug('common:hooks:use-configuration');

const DISABLE_STATE_ID_ON_CHANGE = false;

const UNSETTED_VALUE = Symbol('***unsetted***');

const USE_CONFIGURATION_UPDATE_MS = 500;

export interface ConfigurationOption {
    updateState?: boolean;
    realtimeUpdate?: boolean;
    ignoreSet?: boolean;
}

export const NO_REALTIME_UPDATE: ConfigurationOption = {
    realtimeUpdate: false,
};

export const NO_REALTIME_UPDATE__NO_STATE_UPDATE: ConfigurationOption = {
    realtimeUpdate: false,
    updateState: false,
};

export type UseConfigurationReturnType<T> = readonly [T, ChangeAction<T>, boolean, StateId];

export function useConfiguration<T>(
    configurations: AbstractConfigurations,
    path: ConfigurationPath,
    initialConfiguration: T | (() => T),
    options?: ConfigurationOption
): UseConfigurationReturnType<T> {
    const [stateId, setStateId] = useState<number>(0);

    const stateIdObject = useMemo<StateId>(() => {
        const ret: StateId = {
            id: stateId,
            url: `useConfiguration:${configurations.name}/${path}`,
        };

        return ret;
    }, [stateId, path, configurations]);

    const localValueRef = useRef<T | symbol | undefined>(UNSETTED_VALUE);
    const unmountedRef = useRef<boolean>(false);
    const initialConfigurationValue = useInitialValue(initialConfiguration);

    useEffect(() => {
        if (!configurations) {
            return;
        }

        function handleChanged(changedPath: string, value: any, reason: ConfigurationChangedReason) {
            if (!changedPath.startsWith(path)) {
                return;
            }

            if (unmountedRef.current) {
                return;
            }

            // If realtime is disabled, keep only SET change reason
            if (options?.realtimeUpdate === false && reason !== ConfigurationChangedReason.Set) {
                debug('handleChanged', 'IGNORE change event', 'path=', changedPath, 'value=', value, 'reason=', reason);

                return;
            }

            debug('handleChanged', 'Get change event', 'path=', changedPath, 'value=', value, 'reason=', reason);

            $yield(() => {
                if (DISABLE_STATE_ID_ON_CHANGE) {
                    console.warn('ChangeConfig (disabled)', 'changedPath=', changedPath, 'value=', value);

                    return;
                }

                debug('ChangeConfig', 'changedPath=', changedPath, 'value=', value);
                if (options?.updateState === false) {
                    return;
                }
                setStateId((prev) => {
                    return prev + 1;
                });
            });
        }

        function handleLoaded() {
            if (unmountedRef.current) {
                return;
            }

            $yield(() => {
                if (options?.realtimeUpdate === false) {
                    let configuration: T = configurations?.get(path);
                    if (configuration === undefined) {
                        configuration = initialConfigurationValue;
                    }
                    localValueRef.current = configuration;
                }
                debug('LOADED', 'path=', path, 'localValue=', configuration, 'localValue=', localValueRef.current);

                setStateId((prev) => {
                    const ret = prev + 1;

                    return ret;
                });
            });
        }

        configurations.on('Changed', handleChanged);
        configurations.on('Loaded', handleLoaded);

        return () => {
            configurations.off('Changed', handleChanged);
            configurations.off('Loaded', handleLoaded);
        };
    }, [configurations, path]);

    useEffect(() => {
        return () => {
            unmountedRef.current = true;
        };
    }, []);

    // Set the configuration value in the whole configurations object
    const setConfiguration = useCallback<ChangeAction<T>>(async (value: ChangeActionValue<T>, isReadonly?: boolean) => {
        if (!configurations) {
            throw new Error('Configurations is not setted !');
        }

        debug('SET CONFIGURATION', 'path=', path, 'value=', value);

        try {
            await configurations.set(path, (prevConfiguration: T | undefined) => {
                if (prevConfiguration === undefined) {
                    prevConfiguration = initialConfigurationValue;
                }
                const newConfiguration = isFunction(value) ? value(prevConfiguration!) : value;


                debug('SET CONFIGURATION', 'newConfiguration=', newConfiguration);

                localValueRef.current = newConfiguration;

                return newConfiguration;
            }, isReadonly || options?.ignoreSet);

            if (options?.realtimeUpdate === false) {
                if (options?.updateState !== false) {
                    setStateId((prev) => {
                        return prev + 1;
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, [configurations, initialConfigurationValue, options?.updateState, options?.realtimeUpdate, options?.ignoreSet, path]);

    // Current configuration value
    let configuration: T | undefined;
    if (options?.realtimeUpdate === false && localValueRef.current !== UNSETTED_VALUE) {
        configuration = localValueRef.current as T;
    } else {
        configuration = configurations?.get(path);
        localValueRef.current = configuration;
    }

    // Return the initial value if gathered configuration was undefined
    if (configuration === undefined) {
        configuration = initialConfigurationValue;
    }

    debug('useConfiguration', 'Return configuration path=', path, 'value=', configuration, 'stateId=', stateId, 'localValue=', localValueRef.current, 'options=', options);

    return [configuration, setConfiguration, configurations.isLoaded, stateIdObject];
}

export function useConfigurations<T extends AbstractConfigurations>(
    newConfiguration: () => T,
    stateObject?: BasicState
): [(T | undefined), (ProgressMonitor | undefined), (Error | undefined)] {
    const initialStateIdRef = useRef<StateId | undefined>();
    const needFetchRef = useRef<boolean>(false);

    const notifications = useArgNotifications();

    // Whole configurations object
    const configurations = useMemo<T>(() => {
        const configurations = newConfiguration();

        debug('configurations', 'Create configuration=', configurations);

        initialStateIdRef.current = stateObject?.stateId;
        needFetchRef.current = true;

        return configurations;
    }, [newConfiguration]);

    const [debounceEngine] = useDebounce(USE_CONFIGURATION_UPDATE_MS);

    useEffect(() => {
        function handleToBeStored() {
            debug('handleToBeStored', 'configuration=', configurations.name);
            debounceEngine(() => {
                saveConfiguration(configurations, notifications, stateObject).catch((error) => {
                    console.error(error);
                });
            });
        }

        configurations.on('ToBeStored', handleToBeStored);

        return () => {
            saveConfiguration(configurations, notifications, stateObject).catch((error) => {
                console.error(error);
            });
            configurations.off('ToBeStored', handleToBeStored);
        };
    }, [configurations]);

    const [progressMonitor, error] = useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        debug('configurationsEffect', 'Update configuration=', configurations, 'needFetch=', needFetchRef.current);

        if (!configurations) {
            return;
        }

        if (needFetchRef.current) {
            needFetchRef.current = false;
            await configurations.fetch(notifications, progressMonitor);

            return;
        }

        await configurations.sync(notifications, progressMonitor);
    }, [stateObject?.stateId, configurations], messages.loadingConfiguration);

    return [configurations, progressMonitor, error];
}

async function saveConfiguration(configurations: AbstractConfigurations, notifications: ArgGlobalNotificationType, stateObject?: BasicState) {
    const myProgressMonitor = ProgressMonitor.empty();
    try {
        debug('storeConfiguration', 'configuration=', configurations.name);
        await configurations.store(notifications, myProgressMonitor);

        stateObject?.change();
    } catch (error) {
        console.error('Can not store configuration', error);
    }
}
