import { useCallback, useContext, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import {
    ArgButton,
    ArgRelativeTime,
    ArgSwitch,
    ArgTabAction,
    ArgTabsSubLevel,
    ArgUser,
    ClassValue,
    ProgressMonitor,
    useArgModalContext,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { Group, User } from 'src/model/user';
import { UserGroupsRow } from './user-groups-row';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { UsersAndGroupsStateContext } from '../../providers/usersState';
import { useUsers } from 'src/settings/hooks/use-users';
import { useGetMe } from 'src/contexts/user-context';
import { ResetPasswordModal } from '../reset-user-password-modal/reset-user-password-modal';
import { Environment } from 'src/utils/environment';
import { DeleteUserModal } from '../single-user-delete-modal/single-user-delete-modal';
import { EditUserModal } from '../edit-user-modal/edit-user-modal';
import { AddUserToGroupModal } from '../add-user-group-modal/add-user-group-modal';
import { UserProfileField } from '../../../../model/user-metadata';
import { TagsRoles } from 'src/settings/roles/components/tags-roles';
import { Role } from 'src/settings/models/dtoApi';
import { useHasAnyPermissions, useHasPermission } from '../../../../contexts/user-permission';
import { SettingsPermissions } from '../../../permissions/permissions';
import { SettingsConnector } from '../../../connectors/settings-connector';

import './user-panel.less';

const messages = defineMessages({
    headerTitle: {
        id: 'settings.users.active.panel.title',
        defaultMessage: 'User information',
    },
    active: { id: 'settings.users.active.panel.active', defaultMessage: 'Active' },
    yes: { id: 'settings.users.active.panel.yes', defaultMessage: 'Yes' },
    no: { id: 'settings.users.active.panel.no', defaultMessage: 'No' },
    created: { id: 'settings.users.active.panel.created', defaultMessage: 'Created' },
    modified: { id: 'settings.users.active.panel.modified', defaultMessage: 'Modified' },
    by: { id: 'settings.users.active.panel.by', defaultMessage: 'by' },
    groups: { id: 'settings.users.active.panel.groups', defaultMessage: 'Groups ({totalNumber})' },
    modules: { id: 'settings.users.active.panel.modules', defaultMessage: 'Modules' },
    dataAccess: { id: 'settings.users.active.panel.dataAccess', defaultMessage: 'Data access' },
    noUserSelected: {
        id: 'settings.users.active.panel.noUserSelected',
        defaultMessage: 'No user selected',
    },
    addGroup: {
        id: 'settings.users.active.panel.addGroup',
        defaultMessage: 'Group',
    },
    loadGroupsError: {
        id: 'settings.users.active.panel.loadGroupsError',
        defaultMessage: 'An error has occured while loading groups',
    },
    loadRolesError: {
        id: 'settings.users.active.panel.loadRolesError',
        defaultMessage: 'An error has occured while loading roles',
    },
});

export interface UserPanelProps {
    user?: User;
    className?: ClassValue;
    userProfilesFields?: UserProfileField[];
}

export function UserPanel(props: UserPanelProps) {
    const {
        className,
        user,
        userProfilesFields,
    } = props;

    const intl = useIntl();
    const classNames = useClassNames('settings-user-panel');
    const notifications = useArgNotifications();

    const canEditUser = useHasAnyPermissions<SettingsPermissions>('admin.user.edition', 'admin.user.management');

    const hasGroupsAccess = useHasPermission<SettingsPermissions>('admin.user.group.access');
    const canEditGroups = useHasPermission<SettingsPermissions>('admin.user.group.edition');

    const { groups } = useContext(UsersAndGroupsStateContext);
    const changeStatus = useUsers();
    const { me: currentUser } = useGetMe();
    const modalContext = useArgModalContext();

    const [userGroups, setUserGroups] = useState<Group[]>();
    const [userRoles, setUserRoles] = useState<Role[]>();

    const [loadGroups] = useCallbackAsync(async (progressMonitor?: ProgressMonitor) => {
        if (!user) {
            return;
        }

        try {
            const groups = await SettingsConnector.getInstance().getUserGroups(user.id, progressMonitor);
            setUserGroups(groups);
        } catch (error) {
            if (progressMonitor?.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.loadGroupsError }, error as Error);
        }
    }, [user]);

    const [loadRoles] = useCallbackAsync(async (progressMonitor?: ProgressMonitor) => {
        if (!user) {
            return;
        }

        try {
            const roles = await SettingsConnector.getInstance().getAllUserRoles(user.id, progressMonitor);
            setUserRoles(roles);
        } catch (error) {
            if (progressMonitor?.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.loadRolesError }, error as Error);
        }
    }, [user]);

    useEffect(() => {
        if (!user) {
            return;
        }
        loadGroups().catch((error) => {
            console.error(error);
        });
        loadRoles().catch((error) => {
            console.error(error);
        });
    }, [user]);

    const handleActiveTab = useCallback((tabKey: string | undefined, action: ArgTabAction) => {
        setActiveTab(tabKey!);
    }, []);

    enum Tabs {
        Groups = 'groups',
        Modules = 'modules',
        DataAccess = 'dataAccess',
    }

    const tabs = [
        {
            key: Tabs.Groups,
            title: <FormattedMessage {...messages.groups} values={{ totalNumber: userGroups?.length ?? 0 }} />,
        },
        // TODO: Temporarily descoped because API endpoints were not ready
        // {
        //     key: Tabs.Modules,
        //     title: messages.modules,
        // },
        // {
        //     key: Tabs.DataAccess,
        //     title: messages.dataAccess,
        // },
    ];

    const [activeTab, setActiveTab] = useState<string>(Tabs.Groups);

    if (!user) {
        return (
            <div className={classNames('&', className)}>
                <div className={classNames('&-header')}>
                    <h3 className={classNames('&-header-title')}>
                        <FormattedMessage {...messages.headerTitle} />
                    </h3>
                </div>
                <div className={classNames('&-no-user-pane')}>
                    <EmptyPane key='loading' message={messages.noUserSelected} backgroundAnimation='wave' />
                </div>
            </div>
        );
    }

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-header')}>
                <h3 className={classNames('&-header-title')}>
                    <FormattedMessage {...messages.headerTitle} />
                </h3>
            </div>
            <div className={classNames('&-name-and-photo')}>
                <ArgUser user={user} size='large' />
                <div className={classNames('&-full-name-and-username')}>
                    <div className={classNames('&-name-line')}>
                        <div className={classNames('&-name-line-full-name')}>{user.displayName}</div>
                    </div>
                    <TagsRoles roles={userRoles} tagMaxWidth={undefined} maxTagShown={6} />
                    <div>{user.userName}</div>
                </div>
            </div>
            <div className={classNames('&-details-row')}>
                <div className={classNames('&-row-title')}>
                    <FormattedMessage {...messages.active} />
                </div>
                <div className={classNames('&-active')}>
                    {user.id != currentUser?.id &&
                        <ArgSwitch
                            checked={user.isActive}
                            onClick={() => {
                                changeStatus(user);
                            }}
                            size='large'
                            label={user.isActive ? messages.yes : messages.no}
                        />
                    }
                    {user.id == currentUser?.id && (
                        <FormattedMessage {...(user.isActive ? messages.yes : messages.no)} />
                    )}
                </div>
                {canEditUser && (
                    <ArgButton
                        className={classNames('&-row-button')}
                        onClick={() => {
                            modalContext.open('settings-user-edit-modal',
                                <EditUserModal
                                    closeModal={() => {
                                        modalContext.close('settings-user-edit-modal');
                                    }}
                                    user={user}
                                    isCurrentUser={user.id === currentUser?.id}
                                    userProfilesFields={userProfilesFields}
                                />
                            );
                        }}
                        icon='icon-pencil'
                        type='secondary-black'
                    />
                )}
            </div>
            <div className={classNames('&-details-row')}>
                <div className={classNames('&-row-title')}>{intl.formatMessage(messages.created)}</div>
                <div className={classNames('&-date-and-by')}>
                    <ArgRelativeTime date={new Date(user.createdDate!)} numeric='auto' />
                    <span className={classNames('&-by')}>
                        {intl.formatMessage(messages.by)} {user.createdBy?.displayName}
                    </span>
                </div>
                {user.id != currentUser?.id && canEditUser && (
                    <ArgButton
                        className={classNames('&-row-button')}
                        onClick={() => {
                            modalContext.open('settings-user-delete-modal',
                                <DeleteUserModal
                                    closeModal={() => {
                                        modalContext.close('settings-user-delete-modal');
                                    }}
                                    user={user}
                                />
                            );
                        }}
                        icon='icon-trash'
                        type='secondary-black'
                    />
                )}
            </div>
            <div className={classNames('&-details-row')}>
                <div className={classNames('&-row-title')}>{intl.formatMessage(messages.modified)}</div>
                <div className={classNames('&-date-and-by')}>
                    <ArgRelativeTime date={new Date(user.lastUpdatedDate!)} numeric='auto' />
                    <span className={classNames('&-by')}>
                        {intl.formatMessage(messages.by)} {user.lastUpdatedBy?.displayName}
                    </span>
                </div>
                {Environment.apiOAuth && !user.identityIssuer && canEditUser && (
                    <ArgButton
                        className={classNames('&-row-button')}
                        onClick={() => {
                            modalContext.open('settings-user-reset-password-modal',
                                <ResetPasswordModal
                                    closeModal={() => {
                                        modalContext.close('settings-user-reset-password-modal');
                                    }}
                                    user={user}
                                />
                            );
                        }}
                        icon='icon-key'
                        type='secondary-black'
                    />
                )}
            </div>

            {hasGroupsAccess && (
                <ArgTabsSubLevel
                    className={classNames('&-tabs')}
                    tabs={tabs}
                    activeTabKey={activeTab}
                    onChange={handleActiveTab}
                />
            )}
            {canEditGroups && (
                <ArgButton
                    type='ghost'
                    icon='icon-add-outline'
                    size='large'
                    label={messages.addGroup}
                    className={classNames('&-user-add-group')}
                    onClick={() => {
                        modalContext.open('settings-user-add-group-modal',
                            <AddUserToGroupModal
                                onClose={() => {
                                    modalContext.close('settings-user-add-group-modal');
                                }}
                                onAdd={loadGroups}
                                userId={user.id}
                                groups={groups}
                            />
                        );
                    }}
                />
            )}

            {activeTab === Tabs.Groups && userGroups && user && hasGroupsAccess && (
                <UserGroupsRow
                    key={user.id}
                    userGroups={userGroups}
                    user={user}
                    onGroupDeleted={loadGroups}
                />
            )}
        </div>
    );
}
