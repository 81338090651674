import { defineMessages } from 'react-intl';

import {
    ArgButton,
    ArgModal,
    ProgressMonitor,
    SubProgressMonitor,
    useCallbackAsync,
    useClassNames, useArgNotifications,
} from 'src/components/basic';
import { NotifyBox } from 'src/settings/common-modals/notify-box/notify-box';
import { WARNING_ORANGE } from 'src/settings/models/colors';
import ontologiesConnector from 'src/settings/connectors/ontologies-connector';
import { UniverseId } from 'src/exploration/model/universe';

import './reset-universe-modal.less';

export const messages = defineMessages({
    title: {
        id: 'settings.reset-universe-modal.title',
        defaultMessage: 'Reset the universe',
    },
    description: {
        id: 'settings.reset-universe-modal.description',
        defaultMessage: 'Are you sure you want to reset the "{universeName}" universe ?',
    },
    subDescription: {
        id: 'settings.reset-universe-modal.subDescription',
        defaultMessage: 'All objects, relationships and associated work items will be permanently deleted.',
    },
    cancel: {
        id: 'settings.reset-universe-modal.button.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.reset-universe-modal.button.reset',
        defaultMessage: 'Reset',
    },
    deleteUniverseErrorMsg: {
        id: 'settings.reset-universe-modal.error-message',
        defaultMessage: 'Something went wrong while resetting the universe',
    },
    universeResetSuccess: {
        id: 'settings.reset-universe-modal.UniverseResetSuccess',
        defaultMessage: 'The {universeName} universe has been successfully reset',
    },
});


interface ResetUniverseModalProps {
    onClose: () => void;
    ontologyName: string;
    onUniverseReset?: (progressMonitor: ProgressMonitor) => Promise<void>;
    universeId: UniverseId;
}

export function ResetUniverseModal(props: ResetUniverseModalProps) {
    const {
        onClose,
        universeId,
        ontologyName,
        onUniverseReset,
    } = props;

    const notifications = useArgNotifications();
    const classNames = useClassNames('settings-reset-universe-modal');

    const [handleSubmit, submitting] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            await ontologiesConnector.resetUniverse(universeId, sub1);

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            await onUniverseReset?.(sub2);

            notifications.snackSuccess({ message: messages.universeResetSuccess }, { universeName: ontologyName });

            onClose();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.deleteUniverseErrorMsg }, error as Error);
            throw error;
        }
    }, [universeId, onUniverseReset, notifications, ontologyName, onClose]);

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={onClose}
            footer={
                <>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={onClose}
                        label={messages.cancel}
                        disabled={submitting?.isRunning}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        onClick={handleSubmit}
                        label={messages.delete}
                        loading={submitting?.isRunning}
                        disabled={submitting?.isRunning}
                        data-testid='delete'
                    />
                </>
            }
        >
            <NotifyBox
                icon='icon-exclamation-point'
                text={messages.description}
                subtext={messages.subDescription}
                messageValues={{ universeName: ontologyName }}
                color={WARNING_ORANGE}
            />
        </ArgModal>
    );
}
