import { createJsonChangesBuilder, updateJsonChanges } from '../../utils/connector';
import { NewService, Service } from '../model/service';

export function getServiceJsonChanges(editedService: NewService, service: Service) {
    let changeBuilder = createJsonChangesBuilder();
    changeBuilder = updateJsonChanges('name', editedService.name, service.name, changeBuilder);
    changeBuilder = updateJsonChanges('stampId', editedService.stampId, service.stampId, changeBuilder);
    changeBuilder = updateJsonChanges('code', editedService.code, service.code, changeBuilder);
    changeBuilder = updateJsonChanges('headOffice', editedService.headOffice, service.headOffice, changeBuilder);
    changeBuilder = updateJsonChanges('phoneNumber', editedService.phoneNumber, service.phoneNumber, changeBuilder);
    changeBuilder = updateJsonChanges('faxNumber', editedService.faxNumber, service.faxNumber, changeBuilder);
    changeBuilder = updateJsonChanges('address/streetNumber', editedService.address.streetNumber, service.address?.streetNumber, changeBuilder);
    changeBuilder = updateJsonChanges('address/streetType', editedService.address.streetType, service.address?.streetType, changeBuilder);
    changeBuilder = updateJsonChanges('address/streetName', editedService.address.streetName, service.address?.streetName, changeBuilder);
    changeBuilder = updateJsonChanges('address/additionalAddress', editedService.address.additionalAddress, service.address?.additionalAddress, changeBuilder);
    changeBuilder = updateJsonChanges('address/zipCode', editedService.address.zipCode, service.address?.zipCode, changeBuilder);
    changeBuilder = updateJsonChanges('address/city', editedService.address.city, service.address?.city, changeBuilder);
    changeBuilder = updateJsonChanges('address/country', editedService.address.country, service.address?.country, changeBuilder);

    return changeBuilder.build();
}
