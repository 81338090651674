import { Dispatch, SetStateAction, useState } from 'react';

import { ArgFormLabel, ArgInputText, useClassNames, useArgNotifications } from 'src/components/basic';
import { ConstraintItem, FormatItem, getFormatItem, InputType, messages, TypeItem } from '../const';
import { PropertyInputType } from '../property-input-type';
import { getBaseType, getTypeItem } from '../utils';
import { Environment } from 'src/utils/environment';
import { FullOntology, OntologyId, OntologyMetaProperty } from 'src/settings/universes/ontology/types';
import { PropertyTypeAndConstraint } from '../property-type-and-constraints';
import { PropertyPreview } from '../property-preview';
import ontologiesConnector from '../../../../../connectors/ontologies-connector';
import { PropertyFormModal } from '../property-form-modal/property-form-modal';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';

export interface EditMetapropertyModalProps {
    closeModal: () => void;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
    metaproperty: OntologyMetaProperty;
    ontologyId: OntologyId,
}

export const EditMetapropertyModal = ({
    closeModal,
    setOntology,
    metaproperty,
    ontologyId,
}: EditMetapropertyModalProps) => {
    const classNames = useClassNames('edit-metaproperty-modal');
    const notifications = useArgNotifications();

    const [loading, setLoading] = useState(false);
    const [displayFinalOptionOnly, setDisplayFinalOptionOnly] = useState(
        metaproperty.constraint?.hideFullPath || false
    );

    const [propertyDisplayName, setPropertyDisplayName] = useState<string>(
        metaproperty.displayName
    );

    const [description, setDescription] = useState<string>(
        metaproperty.clientMetadata?.[Environment.appId]?.description || ''
    );
    const [type, setType] = useState<TypeItem | undefined>(
        getTypeItem(metaproperty.baseType) || undefined
    );
    const [format, setFormat] = useState<FormatItem | undefined>(
        getFormatItem(metaproperty.constraint?.inputFormat)
    );
    const [constraint, setConstraint] = useState<ConstraintItem>(ConstraintItem.none);
    const [characters, setCharacters] = useState<[number, number]>([10, 40]);
    const [optionsList, setOptionsList] = useState(
        metaproperty.constraint?.fixedValues?.map(({ value }, id) => {
            return { value, id };
        }) || [
            { value: '', id: 1 },
            { value: '', id: 2 },
        ]
    );

    const handleSubmit = async () => {
        const baseType = getBaseType(type);
        if (!baseType) return;
        setLoading(true);
        try {
            await ontologiesConnector.editMetaProperty(ontologyId, metaproperty.name, {
                displayName: propertyDisplayName,
                description: description,
                clientMetadata: {
                    ...metaproperty.clientMetadata,
                    [Environment.appId]: {
                        ...metaproperty.clientMetadata?.[Environment.appId],
                        description,
                    },
                },
                constraint: optionsList.length === 0 ? undefined : {
                    fixedValues: optionsList.map((fixedValue) => ({ value: fixedValue.value })),
                    hideFullPath: displayFinalOptionOnly,
                },
                pathDefinition: {
                    separator: '/',
                },
            });
            const ontology = await ontologiesConnector.getFullOntology(ontologyId);
            setOntology(ontology);
            setLoading(false);
        } catch (e) {
            notifications.snackError({ message: messages.errorCreatingMetaproperty }, e as Error);
            setLoading(false);
        }
    };


    return (
        <ConfirmModal
            size='large'
            type='edit'
            title={messages.editMetapropertyTitle}
            onClose={closeModal}
            className={classNames('&-modal')}
            onConfirm={handleSubmit}
            confirmText={messages.edit}
            confirmDisabled={!type || loading || propertyDisplayName === ''}
            confirmLoading={loading}
        >
            <PropertyFormModal>
                <ArgFormLabel
                    propertyName={messages.metapropertyFieldName}
                    description={messages.metapropertySubtitle}
                >
                    <ArgInputText
                        value={propertyDisplayName}
                        onChange={(input) => setPropertyDisplayName(input ?? '')}
                    />
                </ArgFormLabel>
                <PropertyTypeAndConstraint
                    type={type}
                    setType={setType}
                    setFormat={setFormat}
                    constraint={constraint}
                    setConstraint={setConstraint}
                    characters={characters}
                    setCharacters={setCharacters}
                    continuousNumber={false}
                    setContinuousNumber={() => {
                    }}
                    isMetaProperty={true}
                    inEditModal={true}
                />
                <PropertyInputType
                    type={type}
                    inputType={InputType.singleSelect}
                    setInputType={() => {
                    }}
                    format={format}
                    setFormat={setFormat}
                    optionsList={optionsList}
                    setOptionsList={setOptionsList}
                    isMetaProperty={true}
                    inputs={[InputType.singleSelect]}
                    displayFinalOptionOnly={displayFinalOptionOnly}
                    setDisplayFinalOptionOnly={setDisplayFinalOptionOnly}
                />

                <ArgFormLabel
                    propertyName={messages.fieldMessage}
                    description={messages.fieldMessageDescription}
                >
                    <ArgInputText
                        value={description}
                        onChange={(input) => setDescription(input ?? '')}
                    />
                </ArgFormLabel>
                <PropertyPreview
                    inputType={InputType.singleSelect}
                    propertyName={propertyDisplayName}
                    optionsList={optionsList}
                    isMetaproperty={true}
                    displayFinalOptionOnly={displayFinalOptionOnly}
                    type={type}
                />
            </PropertyFormModal>
        </ConfirmModal>
    );
};
