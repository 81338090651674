import React, { useEffect } from 'react';
import { defineMessages } from 'react-intl';

import { useClassNames } from '../../basic';
import { LoadingPane } from '../../common/panes/loading-pane';
import { Connector } from '../../../utils/connector';

import './logout.less';

const messages = defineMessages({
    gotoLogout: {
        id: 'common.logout.GotoLogout',
        defaultMessage: 'Processing logout',
    },
});

export function Logout() {
    const classNames = useClassNames('logout-page');

    useEffect(() => {
        setTimeout(() => {
            Connector.getInstance().logout();
        }, 1000);
    }, []);

    return (
        <div className={classNames('&')}>
            <LoadingPane className='fill'
                         size='large'
                         message={messages.gotoLogout}
            />
        </div>
    );
}
