import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { chain, isArray, isEmpty, pull, size, uniq, without } from 'lodash';
import Debug from 'debug';

import {
    ArgChangeReason,
    ArgExpandState,
    ArgGetItemCheckedState,
    ArgGetItemExpandState,
    ButtonClickEvent,
    normalizeText,
} from '../components/basic';
import { ArgIconCheckboxStates } from '../components/basic/arg-checkbox/arg-icon-checkbox';

const debug = Debug('argonode:hooks:UseExpandableItems');

export interface ExpandableItemInfo<T> {
    id: string;
    parent?: ExpandableItemInfo<T>;
    children?: ExpandableItemInfo<T>[];

    data: T;
}

interface UseExpandableItemsReturn<T> {
    checkedItems: T[];
    expandedItems: T[];
    displayedItems: ExpandableItemInfo<T>[];
    allCheckButtonStates?: ArgIconCheckboxStates;

    getItemCheckedState: ArgGetItemCheckedState<ExpandableItemInfo<T>>,
    getItemExpandState: ArgGetItemExpandState<ExpandableItemInfo<T>>,
    onItemExpand: (item: ExpandableItemInfo<T>, isExpanded: boolean, event?: ButtonClickEvent) => void,
    onItemCheck: (item: ExpandableItemInfo<T>, checked: boolean, event?: ButtonClickEvent) => void,
    onItemSelect: (value: ExpandableItemInfo<T> | ExpandableItemInfo<T>[] | undefined, reason: ArgChangeReason, item?: ExpandableItemInfo<T>, forceSelection?: boolean) => void,
    onSelectAll: (check: boolean, event: ButtonClickEvent) => void;

    setCheckedItems: (items: ExpandableItemInfo<T>[]) => void;
}

export function useExpandableItems<T>(
    roots: ExpandableItemInfo<T>[],
    initialExpandedItems?: T[],
    initialCheckedItems?: T[],
    search?: string,
    getSearchLabelFromItem?: (item: ExpandableItemInfo<T>) => string | undefined,
    supportMinusCheckedState = true // parent will have access to folders in checkedItems, by default, this hooks return only elements that represents files in checkedItems
): UseExpandableItemsReturn<T> {
    // Sometimes initial values are initially undefined and get computed after receiving a server response.
    // So this way, make it possible to initialize the state when the data is ready
    const [expandedItems, setExpandedItems] = useState<T[]>(() => []);
    useEffect(() => {
        if (initialExpandedItems) {
            setExpandedItems(initialExpandedItems);
        }
    }, [initialExpandedItems]);

    const [checkedItems, setCheckedItems] = useState<T[]>(() => {
        return initialCheckedItems || [];
    });

    const prevSearch = useRef<string|undefined>(undefined);
    useEffect(() => {
        if (!search && prevSearch.current) {
            expandOnlyCheckedItems();
        }
        prevSearch.current = search;

        if (!search) {
            return;
        }

        if (!getSearchLabelFromItem) {
            throw new Error('getSearchLabelFromItem must be defined if search is setted');
        }

        const normalizedSearch = normalizeText(search);

        function walk(node: ExpandableItemInfo<T>): void {
            const itemLabel = getSearchLabelFromItem!(node);

            if (itemLabel) {
                const normalizedItemLabel = normalizeText(itemLabel);

                if (normalizedItemLabel.indexOf(normalizedSearch) >= 0) {
                    let parent = node.parent;

                    setExpandedItems((prev) => {
                        let list = prev;

                        for (; parent; parent = parent!.parent) {
                            if (!prev.includes(parent!.data)) {
                                list = [...prev, parent!.data];
                            }
                        }

                        return list;
                    });
                }
            }

            node.children?.forEach((child) => {
                walk(child);
            });
        }

        roots.forEach((root) => {
            walk(root);
        });
    }, [getSearchLabelFromItem, roots, search]);

    const getItemCheckedState = useCallback((item: ExpandableItemInfo<T>): ArgIconCheckboxStates => {
        function walk(node: ExpandableItemInfo<T>): ArgIconCheckboxStates {
            if (checkedItems.includes(node.data)) {
                return true;
            }
            if (isEmpty(node.children)) {
                return false;
            }

            const count = node.children!.reduce((acc, f) => {
                const w = walk(f);
                if (w === true) {
                    return acc + 1;
                }

                return acc;
            }, 0);

            if (count === size(node.children)) {
                return true;
            }

            if (count > 0) {
                return 'minus';
            }

            return false;
        }

        const ret = walk(item);

        //console.log('getItemCheckedState', 'item=', item, 'checked=', ret);

        return ret;
    }, [checkedItems]);

    const getItemExpandState = useCallback((item: ExpandableItemInfo<T>): ArgExpandState => {
        const isExpanded = expandedItems.includes(item.data);
        let level = 0;
        for (let node: ExpandableItemInfo<T> | undefined = item.parent; node; node = node.parent) {
            level++;
        }

        const ret: ArgExpandState = {
            isExpanded,
            hasAnyChild: !isEmpty(item.children),
            level,
        };

        return ret;
    }, [expandedItems]);


    const onItemExpand = useCallback((item: ExpandableItemInfo<T>, isExpand: boolean, event?: ButtonClickEvent) => {
        debug('onItemExpand', 'item=', item, 'isExpand=', isExpand);
        setExpandedItems((prev) => {
            if (isExpand) {
                prev = [...prev, item.data];
            } else {
                prev = without(prev, item.data);
            }

            const ret = uniq(prev);

            return ret;
        });
    }, []);

    const onItemCheck = useCallback((item: ExpandableItemInfo<T>, isChecked: boolean, event?: ButtonClickEvent) => {
        debug('onItemCheck', 'item=', item, 'isChecked=', isChecked, 'prevented=', event?.isDefaultPrevented());

        if (!supportMinusCheckedState) {
            if (isChecked) {
                setCheckedItems((prev) => {
                    const newList = [...prev];

                    newList.push(item.data);

                    return newList;
                });

                return;
            }

            setCheckedItems((prev) => {
                const newList = [...prev];

                pull(newList, item.data);

                return newList;
            });

            return;
        }

        if (isChecked) {
            setCheckedItems((prev) => {
                const newList = [...prev];

                function walk(node: ExpandableItemInfo<T>) {
                    if (isEmpty(node.children)) {
                        newList.push(node.data);

                        return;
                    }
                    pull(newList, node.data);

                    node.children!.forEach((child) => {
                        walk(child);
                    });
                }

                walk(item);

                const ret = uniq(newList);

                return ret;
            });

            return;
        }

        // Uncheck

        setCheckedItems((prev) => {
            const newList = [...prev];

            function walk(node: ExpandableItemInfo<T>) {
                pull(newList, node.data);

                node.children?.forEach((child) => {
                    walk(child);
                });
            }

            walk(item);

            return newList;
        });
    }, [supportMinusCheckedState]);

    const onItemSelect = useCallback((value: ExpandableItemInfo<T> | ExpandableItemInfo<T>[] | undefined, reason: ArgChangeReason, item?: ExpandableItemInfo<T>, forceSelection?: boolean) => {
        debug('onItemSelect', 'value=', value, 'item=', item, 'forceSelection=', forceSelection);
        if (value === undefined) {
            return;
        }

        let checked = !!forceSelection;
        if (item) {
            checked = checkedItems.includes(item.data);
        }

        if (isArray(value)) {
            value.forEach((v) => {
                onItemCheck(v, checked);
            });

            return;
        }
        onItemCheck(value, checked);
    }, [checkedItems, onItemCheck]);

    const displayedItems = useMemo<ExpandableItemInfo<T>[]>(() => {
        const list: ExpandableItemInfo<T>[] = [];

        function walk(node: ExpandableItemInfo<T>): void {
            list.push(node);

            if (!expandedItems.includes(node.data) || isEmpty(node.children)) {
                return;
            }

            node.children?.forEach((child) => {
                walk(child);
            });
        }

        roots.forEach((root) => {
            walk(root);
        });

        debug('displayedItems', 'DisplayedItems=', list);

        return list;
    }, [expandedItems, roots]);


    const onSelectAll = useCallback((check: boolean, event: ButtonClickEvent) => {
        if (!check) {
            setCheckedItems([]);

            return;
        }

        const list: T[] = [];

        function walk(node: ExpandableItemInfo<T>): void {
            if (isEmpty(node.children)) {
                list.push(node.data);

                return;
            }

            node.children!.forEach((child) => {
                walk(child);
            });
        }

        roots.forEach((root) => {
            walk(root);
        });

        setCheckedItems(list);
    }, [roots]);

    const leafCount = useMemo(() => {
        let count = 0;

        function walk(node: ExpandableItemInfo<T>): void {
            if (isEmpty(node.children)) {
                count++;

                return;
            }

            node.children!.forEach((child) => {
                walk(child);
            });
        }

        roots.forEach((root) => {
            walk(root);
        });

        return count;
    }, [roots]);

    const allCheckButtonStates = useMemo<ArgIconCheckboxStates>(() => {
        if (!checkedItems.length) {
            return false;
        }
        if (leafCount === checkedItems.length) {
            return true;
        }

        return 'minus';
    }, [checkedItems, leafCount]);

    const expandOnlyCheckedItems = useCallback(() => {
        const items = chain(checkedItems)
            .map((item) => findInTree(roots, item))
            .compact()
            .map((item) => listParents(item))
            .flatten()
            .map((item) => item.data)
            .compact()
            .uniq()
            .value();
        setExpandedItems(items);
    }, [checkedItems, roots]);

    const externalSetCheckedItems = useCallback((items: ExpandableItemInfo<T>[]): void => {
        //
        setCheckedItems(items.map((t) => t.data));

        const expandedItems = chain(items)
            .map((item) => listParents(item))
            .flatten()
            .map((item) => item.data)
            .compact()
            .uniq()
            .value();
        setExpandedItems(expandedItems);
    }, []);

    return {
        expandedItems,
        checkedItems,
        displayedItems,
        getItemCheckedState,
        getItemExpandState,
        onItemCheck,
        onItemExpand,
        onItemSelect,
        onSelectAll,
        allCheckButtonStates,
        setCheckedItems: externalSetCheckedItems,
    };
}

function listParents<T>(item: ExpandableItemInfo<T>) {
    const parents = [];

    for (let i: ExpandableItemInfo<T> | undefined = item.parent; i; i = i.parent) {
        parents.push(i);
    }

    return parents;
}

function findInTree<T>(tree: ExpandableItemInfo<T>[], item: T): ExpandableItemInfo<T> | undefined {
    const stack = [...tree];

    while (stack.length) {
        const node = stack.pop()!;
        if (node.data === item) {
            return node;
        }
        if (node.children) {
            stack.push(...node.children);
        }
    }

    return undefined;
}
