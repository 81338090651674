import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    labelName: {
        id: 'common.forms.LabelName',
        defaultMessage: 'Label',
    },
    uuid: {
        id: 'common.forms.UUID',
        defaultMessage: 'GUID',
    },
});
