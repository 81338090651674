import React from 'react';
import { defineMessages } from 'react-intl';

import { ArgCollapse, ArgMessageRenderer, useClassNames } from 'src/components/basic';
import { FormSection } from '../model';
import { CLASSNAME } from '../editable-form';

import './form-section-collapse.less';

const messages = defineMessages({
    sectionName: {
        id: 'common.forms.SectionName',
        defaultMessage: 'Section name',
    },
});

export interface FormSectionCollapseProps {
    formSection: FormSection;
    children: React.ReactNode;
}

export function FormSectionCollapse(props: FormSectionCollapseProps) {
    const { formSection, children } = props;
    const { name, description, hideDescription } = formSection;

    const classNames = useClassNames('form-section-collapse');

    const sectionTitle = (formSection.hideName !== true) && (
        <label className={classNames('&-label')}>
            <ArgMessageRenderer message={name || messages.sectionName} />
        </label>
    );

    return (
        <ArgCollapse title={sectionTitle} className={classNames('&')}>
            {(description && hideDescription !== true) && (
                <label className={classNames('&-description')}>
                    {description}
                </label>
            )}
            <div className={classNames('&-body', `${CLASSNAME}-nodes`)}>
                {children}
            </div>
        </ArgCollapse>
    );
}
