import { defineMessages, FormattedMessage } from 'react-intl';

import { useClassNames } from 'src/components/basic';
import { RuleTarget, SchemaObject } from '../../../../models/policy';
import PolicyRule from './policy-rule';
import { TargetsAddFilterButtons } from '../targets-add-filter-buttons/targets-add-filter-buttons';
import { isFilterRow } from '../../policy-utils';
import { OntologyBaseSchema } from 'src/settings/universes/ontology/types';
import { UserProfileField } from 'src/model/user-metadata';

import './policy-rule-block-target.less';


interface PolicyRuleBlockTargetProps<T> {
    editable: boolean;
    policyTargetsPath: string;
    target: RuleTarget;
    policyIndex: number;
    onRuleChange: React.Dispatch<React.SetStateAction<T>>;
    schema: OntologyBaseSchema;
    userProfileFields: UserProfileField[];
    externalSchemaObject?: SchemaObject;
    onChangeItemFilter?: (schema: SchemaObject) => void;
}

const messages = defineMessages({
    target: {
        id: 'settings.policy-rule-block-target.target',
        defaultMessage: 'Targets',
    },
});

export const PolicyRuleBlockTarget = <T extends { Targets: RuleTarget[] }>({
    editable,
    policyTargetsPath,
    target,
    policyIndex,
    onRuleChange,
    schema,
    userProfileFields,
    externalSchemaObject,
    onChangeItemFilter,
}: PolicyRuleBlockTargetProps<T>) => {
    const classNames = useClassNames('settings-policy-rule-block-target');

    return (
        <>
            <div className={classNames(editable ? '&-title-editable' : '&-title')}>
                <FormattedMessage {...messages.target} />
            </div>
            <PolicyRule
                policyTargetsPath={policyTargetsPath}
                target={target}
                policyIndex={policyIndex}
                onChange={onRuleChange}
                editable={editable}
                schema={schema}
                userProfileFields={userProfileFields}
                externalSchemaObject={externalSchemaObject}
                onChangeItemFilter={onChangeItemFilter}
            />
            {isFilterRow(target) && editable && (
                <TargetsAddFilterButtons currentPath={`${policyTargetsPath}[${policyIndex}]`} target={target} onChange={onRuleChange} schemaObject={externalSchemaObject} />
            )}
        </>
    );
};
