import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import { defineMessages, MessageDescriptor } from 'react-intl';

import { ArgInput } from 'src/components/basic/arg-input/arg-input';
import { getDataTestIdFromProps, highlightSplit, normalizeText } from 'src/components/basic/utils';
import { ArgChangeReason, ArgInputState, ArgSize } from 'src/components/basic/types';
import { DEFAULT_SIZE } from 'src/components/basic/defaults';
import { ClassValue, useClassNames } from 'src/components/basic/arg-hooks/use-classNames';
import { Role, RolesScope } from 'src/settings/models/dtoApi';
import { getScopeDisplayName, getTagBackgroundColor, getTagIcon } from '../utils';
import { ArgCheckbox, ArgCheckboxMinus, ArgDivider, ArgIcon, ArgInputSearch, ArgTooltip2, ButtonClickEvent, renderText } from 'src/components/basic';

import './assign-roles.less';

const MAX_TAG_COUNT = 6;

const messages = defineMessages({
    moreRolesCount: {
        id: 'settings.assign-roles.moreRolesCount',
        defaultMessage: '+{count}',
    },
    selectRole: {
        id: 'settings.assign-roles.selectRole',
        defaultMessage: 'Select role',
    },
});

interface Scope {
    key: RolesScope;
    displayName: string | MessageDescriptor;
}

export interface AssignRolesProps {
    id?: string;
    className?: ClassValue;
    size?: ArgSize;
    state?: ArgInputState;
    allRoles: Role[];
    value: Role[];
    onChange: (value: Role[], reason: ArgChangeReason) => void;
}

export function AssignRoles(props: AssignRolesProps) {
    const {
        id,
        className,
        size = DEFAULT_SIZE,
        state,
        allRoles,
        value,
        onChange,
    } = props;

    const dataTestId = getDataTestIdFromProps(props);

    const classNames = useClassNames('settings-assign-roles');

    const [selectedRoles, setSelectedRoles] = useState<Role[]>(value);
    const [popoverVisible, setPopoverVisible] = useState<boolean>();
    const [searchedToken, setSearchedToken] = useState<string>();
    const [selectedScope, setSelectedScope] = useState<Scope>();

    const filteredRoles = useMemo(() => {
        return allRoles.filter((role) => {
            return !(searchedToken && normalizeText(role.displayName).indexOf(normalizeText(searchedToken)) === -1);
        });
    }, [allRoles, searchedToken]);

    const displayedRoles: Role[] = useMemo(() => {
        const roles = filteredRoles.filter((role) => {
            return role.scope == selectedScope?.key;
        });

        return roles;
    }, [filteredRoles, selectedScope]);

    const scopes: Scope[] = useMemo(() => {
        return uniqBy(filteredRoles.map((role) => {
            return { key: role.scope as RolesScope, displayName: getScopeDisplayName(role.scope as RolesScope) };
        }), 'key');
    }, [filteredRoles]);

    useEffect(() => {
        if (!popoverVisible) {
            setSearchedToken(undefined);
        }

        if (scopes.length > 0 && (!selectedScope || scopes.indexOf(selectedScope) === -1)) {
            setSelectedScope(scopes[0]);
        }
    }, [popoverVisible, scopes, selectedScope]);

    const handleOpenPopover = useCallback((event: React.MouseEvent<Element, MouseEvent>) => {
        event.preventDefault();
        setPopoverVisible(true);
    }, [setPopoverVisible]);

    const handleScopeClick = useCallback((scope: Scope, value: string | boolean) => {
        const childrenRoles = allRoles.filter((role) => role.scope === scope.key);
        if (value === true) {
            const newSelection = uniqBy([
                ...selectedRoles,
                ...childrenRoles,
            ], 'id');
            setSelectedRoles(newSelection);
            onChange(newSelection, 'selection');

            return;
        }

        const newSelection = selectedRoles.filter((role) => role.scope !== scope.key);

        setSelectedRoles(newSelection);
        onChange(newSelection, 'selection');
    }, [allRoles, onChange, selectedRoles]);

    const handleRoleClick = useCallback((role: Role) => {
        const idx = selectedRoles.findIndex((i) => role.id === i.id);
        if (idx < 0) {
            const newSelection = [...selectedRoles, role];
            setSelectedRoles(newSelection);
            onChange(newSelection, 'selection');

            return;
        }

        const newSelection = selectedRoles.filter((i) => (role.id !== i.id));

        setSelectedRoles(newSelection);
        onChange && onChange(newSelection, 'selection');
    }, [onChange, selectedRoles]);

    const checkScopeValue = useCallback((key: string) => {
        const selected = selectedRoles.filter((role) => role.scope === key).length;
        if (selected === 0) {
            return false;
        }
        const all = allRoles.filter((role) => role.scope === key).length;
        if (all > selected) {
            return 'minus';
        }

        return true;
    }, [allRoles, selectedRoles]);

    const handleScopeSelection = useCallback((event: ButtonClickEvent, scope: Scope) => {
        // Check if event is prevented to avoid checkbox click to change scope
        if (!event.defaultPrevented) {
            setSelectedScope(scope);
        }
    }, []);

    const renderScopes = useCallback(() => {
        return scopes.map((scope: Scope) => {
            const cls = {
                'selected': scope.key === selectedScope?.key,
            };

            return (
                <div
                    key={scope.key}
                    className={classNames('&-dropDown-item', cls)}
                    onClick={(e) => handleScopeSelection(e, scope)}
                >
                    <ArgCheckboxMinus
                        key={scope.key}
                        className={classNames('&-dropDown-item-checkbox')}
                        value={checkScopeValue(scope.key)}
                        size='small'
                        onChange={(value) => handleScopeClick(scope, value)}
                    />
                    <div className={classNames('&-dropDown-item-label')}>
                        <ArgTooltip2 key={scope.key} title={scope.displayName} placement='right'>
                            <span className={classNames('&-item-label-name', 'ellipsis')}>
                                {renderText(scope.displayName)}
                            </span>
                        </ArgTooltip2>
                        <span className={classNames('&-dropDown-item-label-icon')}>
                            <ArgIcon name='icon-next' />
                        </span>
                    </div>
                </div>
            );
        });
    }, [checkScopeValue, classNames, handleScopeClick, handleScopeSelection, scopes, selectedScope]);

    const renderRoles = useCallback(() => {
        return displayedRoles.map((role: Role) => {
            return (
                <div
                    className={classNames('&-dropDown-item')}
                    onClick={() => handleRoleClick(role)}
                    key={role.id}
                >
                    <ArgCheckbox
                        key={role.id}
                        className={classNames('&-dropDown-item-checkbox')}
                        value={!!selectedRoles.find((r) => r.id === role.id)}
                        size='small'
                    />
                    <div className={classNames('&-dropDown-item-label')}>
                        <ArgTooltip2 key={role.id} title={role.displayName} placement='right'>
                            <span className={classNames('&-dropDown-item-label-name', 'ellipsis')}>
                                {highlightSplit(role.displayName, searchedToken)}
                            </span>
                        </ArgTooltip2>
                    </div>
                </div>
            );
        });
    }, [classNames, displayedRoles, handleRoleClick, searchedToken, selectedRoles]);

    const handleTagClick = useCallback(() => {
        setPopoverVisible(true);
    }, [setPopoverVisible]);

    const handleTagClose = useCallback((tag: Role) => {
        if (!selectedRoles.length) {
            return;
        }

        const newSelection = selectedRoles.filter((t) => t.id !== tag.id);

        setSelectedRoles(newSelection);
        onChange(newSelection, 'clear');
    }, [selectedRoles, onChange]);

    const handleClear = useCallback(() => {
        setSelectedRoles([]);
        onChange([], 'clear');
    }, [onChange]);

    const renderAllButtons = useCallback((): ReactNode => {
        return <div className={classNames('&-dropDown-body')}>
            <div className={classNames('&-dropDown-search')}>
                <ArgInputSearch autoFocus={true} onChange={(value) => setSearchedToken(value ?? '')} />
            </div>
            <div className={classNames('&-dropDown-values')}>
                <div className={classNames('&-dropDown-values-left')}>
                    {renderScopes()}
                </div>
                <ArgDivider type='vertical' className={classNames('&-dropDown-divider-line', 'arg-divider')} />
                <div className={classNames('&-dropDown-values-right')}>
                    {renderRoles()}
                </div>
            </div>
        </div>;
    }, [classNames, renderScopes, renderRoles]);

    const computeMenu = useCallback(() => {
        return renderAllButtons();
    }, [renderAllButtons]);

    return <div className={classNames('&', className)}>
        <ArgInput<Role, Role>
            id={id}
            size={size}
            data-testid={dataTestId}
            readOnly={true}
            clearable={true}
            right='dropdown'
            placeholder={!selectedRoles?.length ? messages.selectRole : undefined}
            parseValue={() => null}
            formatValue={(value: Role | null) => (value ? value.displayName : '')}
            popover={computeMenu}
            popoverPlacement='bottomRight'
            popoverTrigger={undefined}
            popoverVisible={popoverVisible}
            popoverClassName={classNames('&-popover')}
            onPopoverVisibleChange={(visible) => setPopoverVisible(visible)}
            onInputClick={handleOpenPopover}
            tags={selectedRoles}
            tagSize='medium'
            maxTagShown={MAX_TAG_COUNT}
            moreTagMessage={messages.moreRolesCount}
            getTagLabel={(role: Role) => role.displayName}
            getTagKey={(role: Role) => role.id}
            getTagIcon={(role: Role) => getTagIcon(role.scope as RolesScope)}
            getTagBackgroundColor={(role: Role) => getTagBackgroundColor(role.scope as RolesScope)}
            tagToolip={false}
            onClear={handleClear}
            onTagClick={handleTagClick}
            onTagClose={handleTagClose}
            className={classNames('arg-combo')}
            state={state}
        />
    </div>;
}
