import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { Role } from 'src/settings/models/dtoApi';

export interface RoleInformationsPanelProps {
    selectedRole: Role | undefined;
}

export function RoleInformationsPanel(props: RoleInformationsPanelProps) {
    const { selectedRole } = props;

    return selectedRole ? (
        <span>{selectedRole.name}</span>
    ) : (<EmptyPane />);
}
