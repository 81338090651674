import { RuleTarget, SchemaObject } from '../../../../models/policy';
import { useClassNames } from 'src/components/basic';
import {
    OntologyBaseSchema,
} from 'src/settings/universes/ontology/types';
import { UserProfileField } from 'src/model/user-metadata';
import PolicyRuleTarget from './policy-rule-target';


import './policy-rule.less';

interface PolicyRuleProps<T> {
    policyTargetsPath: string;
    target: RuleTarget;
    policyIndex: number;
    onChange: React.Dispatch<React.SetStateAction<T>>;
    editable: boolean;
    schema: OntologyBaseSchema;
    userProfileFields: UserProfileField[];
    externalSchemaObject?: SchemaObject;
    onChangeItemFilter?: (schema: SchemaObject) => void;
}

export default function PolicyRule<T extends { Targets: RuleTarget[] }>(props: PolicyRuleProps<T>) {
    const {
        policyTargetsPath,
        policyIndex,
        target,
        onChange,
        editable,
        schema,
        userProfileFields,
        externalSchemaObject,
        onChangeItemFilter,
    } = props;
    const classNames = useClassNames('settings-policy-rule');

    const currentPath = `${policyTargetsPath}[${policyIndex}]`;

    return (
        <div className={classNames('&-container')}>
            <PolicyRuleTarget
                ruleTarget={target}
                currentPath={currentPath}
                schema={schema}
                editable={editable}
                firstRecursiveCall={true}
                onChange={onChange}
                userProfileFields={userProfileFields}
                externalSchemaObject={externalSchemaObject}
                onChangeItemFilter={onChangeItemFilter}
            />
        </div>
    );
}
