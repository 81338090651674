import React, { ReactNode, useEffect, useState } from 'react';
import { isFunction, isNil, isNumber, isString } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { getDataTestIdFromProps } from '../utils';
import { ArgBadges } from '../arg-badge/arg-badge';
import { ArgRenderedIcon, ArgRenderFunction } from '../types';
import { isPromise, PromiseComponent } from '../utils/promise';
import { IconNode, useIconRepository } from './arg-icon-repository';

import './arg-icon.less';

export interface ArgIconProps {
    badge?: number | boolean;
    badges?: ArgBadges;
    name: string;
    color?: string;
    size?: string | number;
    style?: React.CSSProperties;
    className?: ClassValue;
}

export function ArgIcon(props: ArgIconProps) {
    const {
        className: additionalClassName,
        name,
        size,
        badge,
        color,
        style,
        badges,
    } = props;

    const dataTestId = getDataTestIdFromProps(props);

    const classNames = useClassNames('arg-icon');

    const [iconNode, setIconNode] = useState<IconNode>();

    const iconRepository = useIconRepository();

    const cls = {
        'has-badge': isNumber(badge) ? badge > 0 : badge,
    };

    const iconStyle: Record<string, string> = {};
    if (color !== undefined) {
        iconStyle['--arg-icon-color'] = color || 'inherit';
    }
    if (size !== undefined) {
        iconStyle['--arg-icon-font-size'] = !isNil(size) ? isNumber(size) ? `${size}px` : size : 'inherit';
    }

    let iconRepositoryMode = false;
    let className: string;
    if (name.startsWith('data:') || name.charAt(0) === '<') {
        iconRepositoryMode = true;
        className = classNames('&', additionalClassName, cls);
    } else {
        className = classNames(name, '&', additionalClassName, cls);
    }

    useEffect(() => {
        if (!iconRepositoryMode || !iconRepository) {
            return;
        }

        const iconNode = iconRepository.link(name);

        setIconNode(iconNode);

        return () => {
            iconRepository.unlink(name);
        };
    }, [iconRepositoryMode, iconRepository, name]);

    const renderProps: Record<string, any> = {
        style: {
            ...style,
            ...iconStyle,
        },
        'data-color': color,
        'data-testid': dataTestId,
        className,
    };

    if (isNumber(badge)) {
        renderProps['data-count'] = badge;
    }

    if (iconRepositoryMode) {
        if (!iconNode) {
            return null;
        }

        return <>
            {iconNode.render(className)}
        </>;
    }

    return (
        <i {...renderProps}>
            {badges}
        </i>
    );
}


export function renderIcon(icon: ArgRenderedIcon, iconClassName?: ClassValue, iconColor?: string, iconSize?: string | number): ReactNode {
    if (isPromise(icon)) {
        const ret = <PromiseComponent promise={icon.then(val => renderIcon(val, iconClassName, iconColor, iconSize))} />;

        return ret;
    }

    if (isFunction(icon)) {
        const ret = (icon as ArgRenderFunction)();

        return renderIcon(ret, iconClassName, iconColor, iconSize);
    }


    if (isString(icon)) {
        const ret = <ArgIcon name={icon} color={iconColor} className={iconClassName} size={iconSize} />;

        return ret;
    }

    return icon;
}
