import { defineMessages, useIntl } from 'react-intl';
import { Dispatch, SetStateAction, useState } from 'react';

import { ArgButton, ArgModal, useClassNames, useArgNotifications } from 'src/components/basic';
import { FullOntology, FullOntologyLinkType } from '../../types';
import { WARNING_ORANGE } from 'src/settings/models/colors';
import { NotifyBox } from 'src/settings/common-modals/notify-box/notify-box';
import ontologiesConnector from '../../../../connectors/ontologies-connector';

import './delete-edge-modal.less';


interface DeleteRelationModalProps {
    closeModal: () => void;
    edge: FullOntologyLinkType;
    ontologyId: string;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
}

export const messages = defineMessages({
    title: {
        id: 'settings.delete-relation-modal.title',
        defaultMessage: 'Delete relation',
    },
    description: {
        id: 'settings.delete-relation-modal.description',
        defaultMessage: 'Are you sure you want to delete the relation?',
    },
    warningMessage: {
        id: 'settings.delete-relation-modal.warningMessage',
        defaultMessage: 'The relation will no longer be considered in the Universe.',
    },
    cancel: {
        id: 'settings.delete-relation-modal.button.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.delete-relation-modal.button.delete',
        defaultMessage: 'Delete',
    },
    error: {
        id: 'settings.delete-relation-modal.error',
        defaultMessage: 'An error occurred while trying to delete the new edge.',
    },
});

export function DeleteRelationModal(props: DeleteRelationModalProps) {
    const {
        closeModal,
        edge,
        ontologyId,
        setOntology,
    } = props;

    const intl = useIntl();
    const classNames = useClassNames('delete-relation-modal');
    const notifications = useArgNotifications();

    const [buttonDisabled, setButtonDisabled] = useState(false);

    const deleteEdge = async (): Promise<void> => {
        try {
            await ontologiesConnector.deleteEdge(edge.name, ontologyId);
            setOntology(await ontologiesConnector.getFullOntology(ontologyId));
        } catch (e) {
            notifications.snackError({ message: messages.error });
        }
    };

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={closeModal}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={closeModal}
                        label={messages.cancel}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        label={messages.delete}
                        disabled={buttonDisabled}
                        data-testid='delete'
                        onClick={() => {
                            setButtonDisabled(true);
                            deleteEdge();
                            setButtonDisabled(false);
                            closeModal();
                        }}
                    />
                </div>
            }
        >
            <NotifyBox
                text={messages.description}
                subtext={messages.warningMessage}
                color={WARNING_ORANGE}
                icon='icon-exclamation-point'
            />
        </ArgModal>
    );
}
