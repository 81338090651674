import { useCallback, useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgFormLabel,
    ArgInputText,
    ArgModal,
    ClassValue,
    ProgressMonitor,
    SubProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { UserInputType, UserProfileField } from '../../../model/user-metadata';
import { AddEditUserProfileField } from '../../models/dtoApi';
import {
    ConstraintItem,
    FormatItem,
    INPUT_TYPES,
    InputType,
    TypeItem,
} from '../../universes/ontology/components/property-and-metaproperty-modals/const';
import {
    PropertyInputType,
} from '../../universes/ontology/components/property-and-metaproperty-modals/property-input-type';
import {
    PropertyTypeAndConstraint,
} from '../../universes/ontology/components/property-and-metaproperty-modals/property-type-and-constraints';
import { getBaseType, getCustomDataType } from './utils';
import { AddModalFooter } from '../../common-components/add-modal-footer';
import { UsersConnector } from '../../../utils/connectors/users-connector';

import './create-property-modal.less';

const CLASSNAME = 'settings-create-property-modal';
const messages = defineMessages({
    title: {
        id: 'settings.properties.create-property-modal.title',
        defaultMessage: 'Create a property',
    },
    fieldName: {
        id: 'settings.properties.create-property-modal.fieldName',
        defaultMessage: 'Name of the property',
    },
    fieldNameSubtitle: {
        id: 'settings.properties.create-property-modal.fieldNameSubtitle',
        defaultMessage: 'The name property must be unique',
    },
    createPropertyError: {
        id: 'settings.properties.create-property-modal.createPropertyError',
        defaultMessage: 'An error occurred while creating the property',
    },
    nameMustBeUnique: {
        id: 'settings.properties.create-property-modal.nameMustBeUnique',
        defaultMessage: 'Name is already used',
    },
    submitTooltip: {
        id: 'settings.properties.create-property-modal.submitTooltip',
        defaultMessage: 'Enter the property name',
    },
});

export interface CreatePropertyModalProps {
    className?: ClassValue;
    closeModal: () => void;
    properties?: UserProfileField[];
    setProperties: React.Dispatch<React.SetStateAction<UserProfileField[]>>;
}

export function CreatePropertyModal(props: CreatePropertyModalProps) {
    const { className, closeModal, properties, setProperties } = props;
    const classNames = useClassNames(CLASSNAME);

    const [inputType, setInputType] = useState<InputType | UserInputType | undefined>(UserInputType.freeInput);
    const [type, setType] = useState<TypeItem | undefined>(TypeItem.boolean);
    const [displayPropertyName, setDisplayPropertyName] = useState('');
    const [format, setFormat] = useState<FormatItem>();
    const [constraint, setConstraint] = useState<ConstraintItem>(ConstraintItem.none);
    const [characters, setCharacters] = useState<[number, number]>([10, 40]);
    const [continuousNumber, setContinuousNumber] = useState(false);
    const [optionsList, setOptionsList] = useState([
        { value: '', id: 1 },
        { value: '', id: 2 },
    ]);
    const [displayFinalOptionOnly, setDisplayFinalOptionOnly] = useState(false);

    const notifications = useArgNotifications();

    const isPropertyNameInvalid = useMemo(() => {
        const sameName = properties?.find((p) => p.displayName === displayPropertyName);

        return !!sameName;
    }, [properties, displayPropertyName]);

    const [handleSubmit, submitProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const baseType = getBaseType(type);
        const customDataType = getCustomDataType(type);
        if (!baseType && !customDataType) {
            return;
        }

        const newProperty: AddEditUserProfileField = {
            displayName: displayPropertyName,
            baseType,
            customDataType,
            possibleValues: inputType === UserInputType.freeInput ? undefined : optionsList.map((option) => option.value),
            isMultivalued: inputType === UserInputType.multiSelect,
        };

        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            await UsersConnector.getInstance().createUserProfileField(newProperty, sub1);

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            const newProperties = await UsersConnector.getInstance().getUserProfileFields(sub2);
            setProperties(newProperties);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.createPropertyError }, error as Error);
        }
    }, [optionsList, displayPropertyName, inputType, type, notifications, setProperties]);

    const resetState = useCallback(() => {
        setDisplayPropertyName('');
        setType(TypeItem.boolean);
        setFormat(undefined);
        setConstraint(ConstraintItem.none);
        setContinuousNumber(false);
        setInputType(UserInputType.freeInput);
        setOptionsList([
            { value: '', id: 1 },
            { value: '', id: 2 },
        ]);
        setDisplayFinalOptionOnly(false);
    }, []);

    const okButtonDisabled = !displayPropertyName || isPropertyNameInvalid;

    const propertyNameError = isPropertyNameInvalid
        ? messages.nameMustBeUnique
        : undefined;

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={closeModal}
            loading={submitProgressMonitor?.isRunning}
            className={classNames('&')}
            footer={(
                <AddModalFooter
                    okButtonDisabled={okButtonDisabled}
                    tooltip={!displayPropertyName ? messages.submitTooltip : ''}
                    onClose={closeModal}
                    onSave={() => {
                        handleSubmit();
                        resetState();
                        closeModal();
                    }}
                    onSaveAndAddAnother={() => {
                        handleSubmit();
                        resetState();
                        document.getElementById('propertyName')?.focus();
                    }}
                />
            )}
        >
            <form autoComplete='off' className={classNames('&', className)}>
                <ArgFormLabel
                    className={classNames('&-property-name')}
                    propertyName={messages.fieldName}
                    description={propertyNameError ? '' : messages.fieldNameSubtitle}
                    errorMessage={propertyNameError}
                >
                    <ArgInputText
                        value={displayPropertyName}
                        onChange={(input) => setDisplayPropertyName(input ?? '')}
                        autoFocus={true}
                        id='propertyName'
                    />
                </ArgFormLabel>
                <PropertyTypeAndConstraint
                    type={type}
                    setType={setType}
                    setFormat={setFormat}
                    constraint={constraint}
                    setConstraint={setConstraint}
                    characters={characters}
                    setCharacters={setCharacters}
                    continuousNumber={continuousNumber}
                    setContinuousNumber={setContinuousNumber}
                    inEditModal={false}
                />
                <PropertyInputType
                    type={type}
                    inputType={inputType}
                    setInputType={setInputType}
                    inputs={Object.keys(INPUT_TYPES) as UserInputType[]}
                    format={format}
                    setFormat={setFormat}
                    optionsList={optionsList}
                    setOptionsList={setOptionsList}
                    displayFinalOptionOnly={displayFinalOptionOnly}
                    setDisplayFinalOptionOnly={setDisplayFinalOptionOnly}
                />

            </form>
        </ArgModal>
    );
}
