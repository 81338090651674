import { defineMessages } from 'react-intl';

import { ArgButton, ArgMessageValues, ArgRenderedText, useClassNames } from 'src/components/basic';

import './add-modal-footer.less';

const CLASSNAME = 'settings-add-modal-footer';
const messages = defineMessages({
    submit: {
        id: 'settings-add-modal-footer.submit',
        defaultMessage: 'Add',
    },
    cancel: {
        id: 'settings-add-modal-footer.cancel',
        defaultMessage: 'Cancel',
    },
    submitAndAddAnother: {
        id: 'settings-add-modal-footer.submitAndAddAnother',
        defaultMessage: 'Create and add another',
    },
});

export interface AddModalFooterProps {
    okButtonDisabled: boolean;
    tooltip?: ArgRenderedText;
    cancelText?: ArgRenderedText;
    saveText?: ArgRenderedText;
    saveAndAddAnotherText?: ArgRenderedText;
    messageValues?: ArgMessageValues;
    onClose: () => void;
    onSave: () => void;
    onSaveAndAddAnother: () => void;
}

export function AddModalFooter(props: AddModalFooterProps) {
    const {
        okButtonDisabled,
        cancelText,
        saveText,
        saveAndAddAnotherText,
        messageValues,
        tooltip,
        onClose,
        onSave,
        onSaveAndAddAnother,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    return (
        <>
            <ArgButton
                className={classNames('arg-modal-footer-btn')}
                type='secondary'
                onClick={onClose}
                label={cancelText || messages.cancel}
                messageValues={messageValues}
            />
            <ArgButton
                className={classNames('arg-modal-footer-btn')}
                type='primary'
                label={saveText || messages.submit}
                messageValues={messageValues}
                data-testid='create'
                disabled={okButtonDisabled}
                tooltip={tooltip}
                onClick={onSave}
            />
            <ArgButton
                className={classNames('arg-modal-footer-btn')}
                type='primary'
                label={saveAndAddAnotherText || messages.submitAndAddAnother}
                messageValues={messageValues}
                data-testid='create'
                disabled={okButtonDisabled}
                tooltip={tooltip}
                onClick={onSaveAndAddAnother}
            />
        </>
    );
}
