import { UniverseId } from 'src/exploration/model/universe';
import { OntologyId } from '../universes/ontology/types';
import { ValuationPolicyId } from '../models/valuation-policy';
import { FormPolicyId } from '../models/form-policy';
import { PolicyId } from '../models/dtoApi';

export const computeValuationPolicyUrl = (ontologyId: OntologyId, universeId: UniverseId) => {
    return `/settings/data_exploration/ontology/${encodeURIComponent(ontologyId)}/universe/${encodeURIComponent(universeId)}/valuation-policies`;
};

export const computeValuationPolicyDetailsUrl = (ontologyId: OntologyId, universeId: UniverseId, valuationPolicyId: ValuationPolicyId) => {
    return `/settings/data_exploration/ontology/${encodeURIComponent(ontologyId)}/universe/${encodeURIComponent(universeId)}/valuation-policies/${encodeURIComponent(valuationPolicyId)}`;
};

export const computeFormCustomisationUrl = (ontologyId: OntologyId, objectOrEdgeName: string) => {
    return `/settings/data_exploration/ontology/${encodeURIComponent(ontologyId)}/forms/${encodeURIComponent(objectOrEdgeName)}`;
};

export const computeFormsUrl = (ontologyId: OntologyId) => {
    return `/settings/data_exploration/ontology/${encodeURIComponent(ontologyId)}/forms`;
};

export const computePoliciesUrl = (ontologyId: OntologyId, universeId: UniverseId) => {
    return `/settings/data_exploration/ontology/${encodeURIComponent(ontologyId)}/universe/${encodeURIComponent(universeId)}/policies`;
};

export const computePolicyDetailsUrl = (ontologyId: OntologyId, universeId: UniverseId, policyId: PolicyId) => {
    return `/settings/data_exploration/ontology/${encodeURIComponent(ontologyId)}/universe/${encodeURIComponent(universeId)}/policies/${encodeURIComponent(policyId)}`;
};

export const computeFormPoliciesUrl = (ontologyId: OntologyId, universeId: UniverseId) => {
    return `/settings/data_exploration/ontology/${encodeURIComponent(ontologyId)}/universe/${encodeURIComponent(universeId)}/forms-policies`;
};

export const computeFormPolicyDetailsUrl = (ontologyId: OntologyId, universeId: UniverseId, formPolicyId: FormPolicyId) => {
    return `/settings/data_exploration/ontology/${encodeURIComponent(ontologyId)}/universe/${encodeURIComponent(universeId)}/forms-policies/${encodeURIComponent(formPolicyId)}`;
};

export const computeOntologyUrl = (ontologyId: OntologyId) => {
    return `/settings/data_exploration/ontology/${encodeURIComponent(ontologyId)}/schema`;
};
