import { includes } from 'lodash';

import { VertexOrEdgeProperties } from 'src/exploration/model/universe';
import { EdgeId } from './edge';
import { PropertyValueInfos, SystemVertexTypeName, UniversePropertyTypes, UniverseVertexTypeName } from './universe';
import { VertexId, VertexInfo } from './vertex';
import { ClusterId, ClusterInfoTitle } from './cluster';
import {
    Visualization,
    VisualizationCluster,
    VisualizationClusterEdge,
    VisualizationEdge,
    VisualizationVertex,
} from './exploration-visualization';

export type GraphVisualizationVertex = VisualizationVertex;
export type GraphVisualizationEdge = VisualizationEdge;

export interface GraphVisualization extends Omit<Visualization, 'vertices' | 'edges' | 'clusters' | 'clusterEdges'> {
    vertices: Record<VertexId, GraphVisualizationVertex>;
    edges: Record<EdgeId, GraphVisualizationEdge>;
    clusters: Record<ClusterId, GraphVisualizationCluster>;
    clusterEdges: Record<EdgeId, GraphVisualizationClusterEdge>;
}

export interface GraphVisualizationCluster extends VisualizationCluster {
    title: ClusterInfoTitle;
}

//export interface ExplorationGraphClusterItem extends GraphClusterItem {
//    filter?: GraphClusterFilter,
//}

export type GraphVisualizationClusterEdge = VisualizationClusterEdge

export function isSystemTypedVertex(vertex: VertexInfo) {
    return isSystemTypedVertexType(vertex.type);
}

export function isSystemTypedVertexType(vertexType: UniverseVertexTypeName) {
    return includes(SystemVertexTypeName, vertexType);
}

// export interface

export interface TableVisualizationVertex extends VisualizationVertex {
    values: PropertyValueInfos[][];
    subTables: TableVisualization[];
}

export interface TableVisualization extends Visualization {
    // count only the main vertices, as opposed to verticesCount that includes also vertices in sub tables.
    mainVerticesCount: number;
    searchCount?: number;
    skip?: number;

    // includesColumns (in request body) must be setted to get this field
    columns?: TableVisualizationColumn[];

    sorting?: TableVisualizationSorting[];

    verticesArray: TableVisualizationVertex[];
    vertices: Record<VertexId, TableVisualizationVertex>;
}

export type SubTableVisualization = Omit<TableVisualization, 'verticesCount'>;

export interface TableVisualizationColumn {
    name: string;
    type: UniversePropertyTypes;
    isPinned: boolean;
    isSortable: boolean;
}

export interface TableVisualizationSorting {
    order: TableVisualizationSortingOrder;
    column: string;
}

export enum TableVisualizationSortingOrder {
    Ascending = 'Ascending',
    Descending = 'Descending',
}

export interface ListVisualization extends Omit<Visualization, 'vertices'> {
    skip: number;
    searchCount?: number;

    vertices: Record<VertexId, ListVisualizationVertex>;
    verticesArray: ListVisualizationVertex[];
}

export interface ListVisualizationVertex extends VisualizationVertex {
    summary?: string;
    properties?: VertexOrEdgeProperties;
}

export type ShortestPath = Record<string, any>;
