import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { addListToDropdown, createDropdown, ListDropdownItemDefinition } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import EditorWithUI from '@ckeditor/ckeditor5-core/src/editor/editorwithui';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import { Collection } from '@ckeditor/ckeditor5-utils';
import { add } from '@ckeditor/ckeditor5-utils/src/translation-service';

export const OBJECT_CLICK_EVENT_NAME = 'create-object-click';
export const RELATIONSHIP_CLICK_EVENT_NAME = 'create-relationship-click';
export const BUTTON_ICON_NAME = 'icon-add-outline';

const FR_TRANSLATION = {
    'Create object or relationship': 'Créer un objet ou une relation',
    'Create object': 'Créer un objet',
    'Create relationship': 'Créer une relation',
};

export class CreateObject extends Plugin {
    init() {
        const editor = this.editor as EditorWithUI.EditorWithUI;

        editor.ui.componentFactory.add('createObject', (locale: any) => {
            const view = createDropdown(locale);

            // The translation function.
            const { t } = editor.locale;

            // The localized label.
            add('fr', FR_TRANSLATION);

            view.buttonView.set({
                label: t('Create object or relationship'),
                tooltip: true,
                class: `${BUTTON_ICON_NAME} create-object`,
            });
            const items = new Collection<ListDropdownItemDefinition>();

            items.add({
                type: 'button',
                model: new Model({
                    withText: true,
                    label: t('Create object'),
                    id: 'object',
                }),
            });

            items.add({
                type: 'button',
                model: new Model({
                    withText: true,
                    label: t('Create relationship'),
                    id: 'relationship',
                }),
            });

            addListToDropdown(view, items);

            // Callback executed once the create object button is clicked.
            view.on('execute', (eventInfo) => {
                const selection = editor.model.document.selection;

                const content = editor.data.toView(editor.model.getSelectedContent(selection));

                let selectedText = '';
                const children = Array.from(content.getChildren());
                for (; children.length;) {
                    const child = children.shift()!;
                    if (child.is('attributeElement') || child.is('containerElement')) {
                        children.unshift(...Array.from(child.getChildren()));
                        continue;
                    }
                    if (child.is('text')) {
                        selectedText += child.data;
                        continue;
                    }
                }

                if (eventInfo.source.id === 'object') {
                    view.fire(OBJECT_CLICK_EVENT_NAME, selectedText);
                }
                if (eventInfo.source.id === 'relationship') {
                    view.fire(RELATIONSHIP_CLICK_EVENT_NAME, selectedText);
                }
            });

            view.delegate(OBJECT_CLICK_EVENT_NAME, RELATIONSHIP_CLICK_EVENT_NAME).to((editor.ui as any).view.toolbar);

            return view;
        });
    }
}
