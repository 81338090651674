import { defineMessages } from 'react-intl';

import { useCallbackAsync, useClassNames, useArgNotifications } from 'src/components/basic';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';
import WebhookConnector from 'src/settings/connectors/webhook-connector';
import { DetailedWebHook } from 'src/settings/models/detailed-webhooks';

import './webhooks-activation-modal.less';

const messages = defineMessages({
    activateTitle: {
        id: 'settings.webhooks.activation-modal.activateTitle',
        defaultMessage: '{targetStatus, select, true {Activate} other {Deactivate}} this webhook',
    },
    activateMessage: {
        id: 'settings.webhooks.activation-modal.activateMessage',
        defaultMessage: 'Are you sure you want to {targetStatus, select, true {activate} other {deactivate}} the webhook "{name}"?',
    },
    activateMultipleTitle: {
        id: 'settings.webhooks.activation-modal.activateMultipleTitle',
        defaultMessage: '{targetStatus, select, true {Activate} other {Deactivate}} {count} webhooks',
    },
    activateMultipleMessage: {
        id: 'settings.webhooks.activation-modal.activateMultipleMessage',
        defaultMessage: 'Are you sure you want to {targetStatus, select, true {activate} other {deactivate}} these {count} webhooks?',
    },
    activateError: {
        id: 'settings.webhooks.activation-modal.activateError',
        defaultMessage: 'An error occurred while {targetStatus, select, true {activating} other {deactivating}} the {count, plural, one {webhook} other {webhooks}}',
    },
    activate: {
        id: 'settings.webhooks.activation-modal.activate',
        defaultMessage: '{targetStatus, select, true {Activate} other {Deactivate}}',
    },
});

interface WebhooksActivationModalProps {
    webhooks: DetailedWebHook[];
    targetStatus: boolean;
    onSuccess?: () => void;
    onClose: () => void;
}

export function WebhooksActivationModal(props: WebhooksActivationModalProps) {
    const { webhooks, targetStatus, onClose, onSuccess } = props;

    const classNames = useClassNames('settings-webhooks-activation-modal');
    const notifications = useArgNotifications();

    const [onDelete] = useCallbackAsync(async (progressMonitor) => {
        try {
            const webhookIds = webhooks.map((webhook) => webhook.id);
            await WebhookConnector.getInstance().toggleWebhooks(webhookIds, targetStatus, progressMonitor);
            onSuccess?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.activateError }, error as Error, { count: webhooks.length, targetStatus });
            throw error;
        }
    }, [onSuccess, targetStatus, webhooks]);

    const title = props.webhooks.length > 1 ? messages.activateMultipleTitle : messages.activateTitle;
    const message = props.webhooks.length > 1 ? messages.activateMultipleMessage : messages.activateMessage;

    const messageValues = {
        count: props.webhooks.length,
        name: props.webhooks.length === 1 ? props.webhooks[0].name : undefined,
        targetStatus,
    };

    return (
        <ConfirmModal
            className={classNames('&')}
            type='edit'
            title={title}
            alertMessage={message}
            messageValues={messageValues}
            onConfirm={onDelete}
            onClose={onClose}
            confirmText={messages.activate}
        />
    );
}
