import React, { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Policy } from 'src/settings/models/dtoApi';
import { ArgButton, ArgInputSearch, useArgModalContext, useClassNames } from 'src/components/basic';
import { CreatePolicyModal } from '../create-policy-modal';

import './table-toolbar.less';

const messages = defineMessages({
    newUser: {
        id: 'settings.policies-page.toolbar.new',
        defaultMessage: 'New',
    },
    import: {
        id: 'settings.policies-page.toolbar.import',
        defaultMessage: 'Import',
    },
    searchPlaceHolder: {
        id: 'settings.policies-page.toolbar.searchPlaceHolder',
        defaultMessage: 'Search',
    },
});

const CREATE_POLICY_MODAL_KEY = 'CREATE_POLICY_MODAL_KEY';

interface TableToolbarProps {
    setPolicies: React.Dispatch<React.SetStateAction<Policy[]>>;
    setSearch: React.Dispatch<React.SetStateAction<string | null>>;
}

export const TableToolbar: React.FC<TableToolbarProps> = ({ setPolicies, setSearch }) => {
    const { universeId } = useParams<{ ontologyId: string; universeId: string }>();
    const classNames = useClassNames('table-toolbar');
    const modalContext = useArgModalContext();

    const openCreatePolicyModal = useCallback(() => {
        modalContext.open(
            CREATE_POLICY_MODAL_KEY,
            <CreatePolicyModal
                closeModal={() => modalContext.close(CREATE_POLICY_MODAL_KEY)}
                setPolicies={setPolicies}
                universeId={universeId}
            />
        );
    }, [modalContext, setPolicies, universeId]);

    return (
        <>
            <div className={classNames('&-toolbar')}>
                <ArgInputSearch
                    onChange={(newSearchText) => {
                        setSearch(newSearchText);
                    }}
                    placeholder={messages.searchPlaceHolder}
                    className={classNames('&-search')}
                />
                <ArgButton
                    size='medium'
                    type='primary'
                    icon='icon-plus'
                    label={messages.newUser}
                    onClick={openCreatePolicyModal}
                />
            </div>
        </>
    );
};
