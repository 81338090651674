import { useEffect, useMemo, useRef } from 'react';
import { forEach } from 'lodash';
import Debug from 'debug';

import { ToolContext, ToolTreeContext, ToolTreeNode } from './tool-context';
import { useContextUpdate } from './use-context-update';
import { StateId } from '../utils/event-emitter';

const debug = Debug('basic:arg-toolbar:use-tool-nodes');

export function useToolNodes(toolContext: ToolContext<undefined>, environmentContext?: undefined, prefix?: string, removeEditors?: boolean): [ToolTreeNode<undefined>[], ToolTreeContext<undefined>, StateId];
export function useToolNodes<T = undefined>(toolContext: ToolContext<T>, environmentContext: T, prefix?: string, removeEditors?: boolean): [ToolTreeNode<T>[], ToolTreeContext<T>, StateId];

export function useToolNodes<T = undefined>(toolContext: ToolContext<T>, environmentContext: T, prefix?: string, removeEditors?: boolean): [ToolTreeNode<T>[], ToolTreeContext<T>, StateId] {
    useContextUpdate(toolContext);

    const treeContextRef = useRef<ToolTreeContext<T>>();
    if (!treeContextRef.current) {
        treeContextRef.current = {
            fetchedTools: {},
        };
    }

    toolContext.updateEnvironment(environmentContext);

    const [tree, stateId] = useMemo<[ToolTreeNode<T>[], StateId]>(() => {
        const tree = toolContext.computeTree(treeContextRef.current, prefix, removeEditors);

        debug('memoToolTreeNode', 'stateId=', toolContext.stateId, 'prefix=', prefix, 'tree=', tree);

        return [tree, toolContext.stateId];
    }, [toolContext.stateId, prefix, removeEditors]);

    useEffect(() => {
        debug('mount', 'toolContext=', toolContext);

        toolContext.mount();

        return () => {
            debug('unmount', 'toolContext=', toolContext);

            const pm = treeContextRef.current?.fetchedTools;

            forEach(pm, (p) => {
                p.progressMonitor?.cancel();
            });

            toolContext.onUnmount();
        };
    }, [toolContext]);

    return [tree, treeContextRef.current, stateId];
}
