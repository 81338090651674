import { Location } from 'history';

export const MINIMAL_HASH = 'minimal';

export function hasMinimalHash(currentLocation: Location) {
    const ret = hasHashToken(currentLocation, MINIMAL_HASH);

    return ret;
}

export function hasHashToken(currentLocation: Location, token: string): boolean {
    const hash = currentLocation.hash;
    if (!hash) {
        return false;
    }

    const hs = hash.substring(1).split(',');

    if (hs.includes(token)) {
        return true;
    }

    return false;
}
