import { KEY_BINDINGS as GLOBAL_KEY_BINDINGS } from './global';
import { KEY_BINDINGS as HOME_PAGE_KEY_BINDINGS } from './home-page';
import { KEY_BINDINGS as FOLDER_KEY_BINDINGS } from './folder';
import { KEY_BINDINGS as PROCESS_KEY_BINDINGS } from './process';
import { KEY_BINDINGS as SCHEDULER_KEY_BINDINGS } from './scheduler';
import { KEY_BINDINGS as WRANGLER_KEY_BINDINGS } from './wrangler';

export const KEY_BINDINGS = {
    ...GLOBAL_KEY_BINDINGS,

    ...HOME_PAGE_KEY_BINDINGS,
    ...FOLDER_KEY_BINDINGS,

    ...PROCESS_KEY_BINDINGS,
    ...SCHEDULER_KEY_BINDINGS,
    ...WRANGLER_KEY_BINDINGS,
};

