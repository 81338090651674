import { defineMessages } from 'react-intl';
import { FormikHelpers, useFormik } from 'formik';

import {
    ArgButton,
    ArgFormLabel,
    ArgInputText,
    ArgModal,
    ProgressMonitor,
    SubProgressMonitor,
    useCallbackAsync,
    useClassNames, useArgNotifications,
} from 'src/components/basic';
import ontologiesConnector from '../../../../connectors/ontologies-connector';

import './edit-universe-modal.less';

interface FormFields {
    name?: string;
}

type FormFieldsErrors = Partial<Record<keyof FormFields, boolean>>;

export const messages = defineMessages({
    modalTitle: {
        id: 'settings.rename-universe-modal.title',
        defaultMessage: 'Rename universe',
    },
    modalDescription: {
        id: 'settings.rename-universe-modal.description',
        defaultMessage: 'Name this universe',
    },
    nameField: {
        id: 'settings.rename-universe-modal.field.name',
        defaultMessage: 'Name',
    },
    required: {
        id: 'settings.rename-universe-modal.required',
        defaultMessage: 'Required',
    },
    submit: {
        id: 'settings.rename-universe-modal.submitButton',
        defaultMessage: 'Modify',
    },
    cancel: {
        id: 'settings.rename-universe-modal.cancelButton',
        defaultMessage: 'Cancel',
    },
    editUniverseErrorMsg: {
        id: 'settings.rename-universe-modal.editUniverseErrorMsg',
        defaultMessage: 'Something went wrong while editing the universe',
    },
});

export interface EditUniverseModalProps {
    onClose: () => void;
    ontologyId: string;
    ontologyName: string;
    onUniverseEdition: (progressMonitor: ProgressMonitor) => Promise<void>;
}

export function EditUniverseModal(props: EditUniverseModalProps) {
    const {
        onClose,
        ontologyId,
        ontologyName,
        onUniverseEdition,
    } = props;

    const classNames = useClassNames('settings-edit-universe-modal');
    const notifications = useArgNotifications();

    const [submitForm, submitting] = useCallbackAsync(async (progressMonitor: ProgressMonitor, values: FormFields, { resetForm }: FormikHelpers<FormFields>) => {
        if (!values.name) {
            return;
        }

        const editOntologyPayload = {
            name: values.name,
        };

        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            await ontologiesConnector.editOntology(editOntologyPayload, ontologyId, sub1);

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            await onUniverseEdition(sub2);

            onClose();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.editUniverseErrorMsg }, error as Error);
            throw error;
        }
    }, [ontologyId, onUniverseEdition, onClose, notifications]);

    const validateFrom = (values: FormFields) => {
        const errors: FormFieldsErrors = {};
        if (!values.name) {
            errors.name = true;
        }

        return errors;
    };

    const {
        handleSubmit,
        handleChange,
        values: formValues,
        errors: formErrors,
    } = useFormik<FormFields>({
        initialValues: { name: ontologyName },
        validateOnChange: false,
        enableReinitialize: true,
        validate: validateFrom,
        onSubmit: submitForm,
    });

    return (
        <ArgModal
            size='medium'
            title={messages.modalTitle}
            onClose={onClose}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={onClose}
                        label={messages.cancel}
                        disabled={submitting?.isRunning}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        onClick={() => handleSubmit()}
                        label={messages.submit}
                        loading={submitting?.isRunning}
                        disabled={submitting?.isRunning}
                        data-testid='edit'
                    />
                </div>
            }
        >
            <form onSubmit={() => handleSubmit()} autoComplete='off'>
                <ArgFormLabel
                    propertyName={messages.nameField}
                    required={messages.required}
                >
                    <ArgInputText
                        value={formValues.name}
                        onInputChange={handleChange('name')}
                        data-testid='name'
                        state={formErrors.name ? 'invalid' : undefined}
                    />
                </ArgFormLabel>
            </form>
        </ArgModal>
    );
}

