import { useState } from 'react';

import {
    ArgCombo,
    ArgFormLabel,
    ArgInputDate,
    ArgInputDateTime,
    ArgInputText,
    ArgMessageRenderer,
    ArgSwitch,
    useClassNames,
} from 'src/components/basic';
import { UserInputType } from '../../../../../model/user-metadata';
import { InputType, messages, NUMERIC_ITEM_TYPES, OptionListItem, TypeItem } from './const';

import './property-preview.less';

interface PropertyPreviewProps {
    inputType: InputType | UserInputType | undefined;
    propertyName: string | undefined;
    optionsList: OptionListItem[];
    isMetaproperty?: boolean;
    displayFinalOptionOnly: boolean;
    type: TypeItem | undefined;
}

export function PropertyPreview(props: PropertyPreviewProps) {
    const {
        inputType,
        propertyName,
        optionsList,
        isMetaproperty,
        displayFinalOptionOnly,
        type,
    } = props;

    const classNames = useClassNames('property-preview');
    const [booleanValue, setBooleanValue] = useState(false);

    const getPreviewComponent = (inputType: InputType | UserInputType, type: TypeItem) => {
        if (inputType === InputType.singleSelect) {
            return (
                <ArgCombo
                    items={optionsList.map((object) => {
                        return displayFinalOptionOnly
                            ? object.value.split('/').pop()
                            : object.value;
                    })}
                    className={classNames('&-dropdown')}
                    cardinality='one'
                    enableFilter={true}
                />
            );
        } else if (type === TypeItem.date) {
            return <ArgInputDate />;
        } else if (type === TypeItem.dateAndTime) {
            return <ArgInputDateTime />;
        } else if (NUMERIC_ITEM_TYPES.includes(type)) {
            return <ArgInputText inputType='number' />;
        } else if (type === TypeItem.boolean) {
            return (
                <ArgSwitch value={booleanValue} onChange={() => setBooleanValue(!booleanValue)} />
            );
        } else {
            return <ArgInputText />;
        }
    };

    const titleMessage = isMetaproperty ? messages.metapropertyPreview : messages.preview;
    const _propertyName = propertyName
        || (isMetaproperty ? messages.metapropertyFieldName : messages.fieldName);

    return (
        <div className={classNames('&')}>
            <div className={classNames('&-subtitle')}>
                <ArgMessageRenderer message={titleMessage} />
            </div>
            <ArgFormLabel propertyName={_propertyName} addedRow={true}>
                {inputType && type && getPreviewComponent(inputType, type)}
            </ArgFormLabel>
        </div>
    );
}

