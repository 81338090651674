import { ArgIcon, useClassNames } from 'src/components/basic';
import { FormImage } from '../model';

import './form-image-component.less';

export interface FormImageComponentProps {
    formImage: FormImage;
}

export function FormImageComponent({ formImage } : FormImageComponentProps) {
    const { src } = formImage;
    const classNames = useClassNames('form-image-component');

    if (!src) {
        return (
            <div className={classNames('&-placeholder')}>
                <ArgIcon name='icon-image' />
            </div>
        );
    }

    return (
        <img src={src} className={classNames('&')} />
    );
}
