import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { ArgInputText, ArgPlaceholderText, ClassValue, renderText, useClassNames } from '../../../components/basic';
import { ApplicationOrModuleBranding } from '../../models/visual-identity';
import { ImageManager } from './image-manager';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';
import { ArgonosModuleId } from '../../../components/application/modules';
import { getArgonosModuleById } from '../../../components/application/modules-manager';

import '../components/sub-modules-brander.less';


const messages = defineMessages({
    appName: {
        id: 'settings.visual-identity.sub-modules-brander.appName',
        defaultMessage: 'Application',
    },
    adminName: {
        id: 'settings.visual-identity.sub-modules-brander.adminName',
        defaultMessage: 'Admin',
    },
    settingsName: {
        id: 'settings.visual-identity.sub-modules-brander.settingsName',
        defaultMessage: 'Settings',
    },
    dataCollectName: {
        id: 'settings.visual-identity.sub-modules-brander.dataCollectName',
        defaultMessage: 'Data collect',
    },
    dataPrepName: {
        id: 'settings.visual-identity.sub-modules-brander.dataPrepName',
        defaultMessage: 'Data preparation',
    },
    dataExploName: {
        id: 'settings.visual-identity.sub-modules-brander.dataExploName',
        defaultMessage: 'Data exploration',
    },
    proceoName: {
        id: 'settings.visual-identity.sub-modules-brander.proceoName',
        defaultMessage: 'Proceo',
    },
    moduleName: {
        id: 'settings.visual-identity.sub-modules-brander.moduleName',
        defaultMessage: 'Module name',
    },
});

type MessageMappingType = {
    [key in ArgonosModuleId]: {
        id: string;
        defaultMessage: string;
    };
};

const messageMapping: MessageMappingType = {
    'Application': messages.appName,
    'Administration': messages.adminName,
    'Settings': messages.settingsName,
    'DataCollect': messages.dataCollectName,
    'DataPreparation': messages.dataPrepName,
    'DataExploration': messages.dataExploName,
    'Proceo': messages.proceoName,
};

interface SubModulesBranderProps {
    className?: ClassValue;
    moduleBranding: ApplicationOrModuleBranding;
    onCustomNameChange: (customName: string) => void;
    onIconChange: (icon: Blob | undefined) => void;
    onLogoChange: (logo: Blob | undefined) => void;
    logoPlaceholder?: string;
}

export function SubModulesBrander(props: SubModulesBranderProps) {
    const {
        className,
        moduleBranding,
        onCustomNameChange,
        onLogoChange,
        logoPlaceholder,
    } = props;

    // TODO CEDRIC changer le nom du CSS
    const classNames = useClassNames('sub-modules-brander');
    const canManageVisualIdentity = useHasPermission<SettingsPermissions>('admin.visual.identity.management');
    const intl = useIntl();

    const argonosModule = getArgonosModuleById(moduleBranding.id!)!;

    let placeholder:ArgPlaceholderText = argonosModule.name;
    if (argonosModule.subType && messageMapping[argonosModule.subType]) {
        placeholder = intl.formatMessage(messageMapping[argonosModule.subType]);
    }

    return (
        <>
            <div className={classNames('&', className)}>
                <h3 className={classNames('&-title')}>
                    {renderText(argonosModule.name)}
                </h3>
                <div className={classNames('&-name-input')}>
                    <FormattedMessage {...messages.moduleName} />
                    <ArgInputText
                        value={moduleBranding.brandingName}
                        disabled={!canManageVisualIdentity}
                        placeholder={placeholder}
                        onInputChange={(value) => onCustomNameChange(value)} />
                </div>
                <ImageManager
                    className={classNames('&-image')}
                    moduleBranding={moduleBranding}
                    onImageChange={onLogoChange}
                    imageType='logo'
                    logoPlaceholder={logoPlaceholder}
                />
            </div>
        </>
    );
}
