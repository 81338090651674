import React, { useCallback, useMemo } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { isNil } from 'lodash';

import { ArgChangeReason } from '../../basic';
import { EditorProps } from '../controls/controls-type';
import { PickListEditor } from './picklist-editor';

const messages = defineMessages({
    trueMessage: {
        id: 'common.editors.boolean.True',
        defaultMessage: 'True',
    },
    falseMessage: {
        id: 'common.editors.boolean.False',
        defaultMessage: 'False',
    },
    undefinedMessage: {
        id: 'common.editors.boolean.Undefined',
        defaultMessage: 'Undefined',
    },
});


type BooleanEditorType = EditorProps<boolean>;

export const BooleanEditor = ({
    className,
    propertyName,
    value,
    state,
    onChange,
    propertyDisplayName,
    size = 'medium',
    placeholder,
}: BooleanEditorType) => {
    const intl = useIntl();

    const falseLabel = intl.formatMessage(messages.falseMessage);
    const trueLabel = intl.formatMessage(messages.trueMessage);

    const transformStringToBoolean = useCallback((str: string): boolean => {
        if (str === trueLabel) {
            return true;
        }

        return false;
    }, [intl]);

    const transformBooleanToString = useCallback((bool: boolean): string => {
        return bool === true ? trueLabel : falseLabel;
    }, [intl]);

    const stringValue = useMemo<string | null>(() => {
        if (isNil(value)) {
            return null;
        }

        return transformBooleanToString(value);
    }, [value, transformBooleanToString]);

    const handleSelectChange = (value: string | string[] | null, reason?: ArgChangeReason) => {
        if (Array.isArray(value)) {
            return;
        }

        const newValue = value ? transformStringToBoolean(value) : null;

        onChange(newValue, reason);
    };

    return (
        <PickListEditor
            cardinality='optional'
            className={className}
            value={stringValue}
            propertyName={propertyName}
            propertyDisplayName={propertyDisplayName}
            onChange={handleSelectChange}
            state={state}
            size={size}
            placeholder={placeholder}
            pickerOptions={{
                fixedValues: [falseLabel, trueLabel],
            }}
        />
    );
};

export function BooleanToText(value: any, intl: IntlShape) {
    switch (value) {
        case true:
            return intl.formatMessage(messages.trueMessage);

        case false:
            return intl.formatMessage(messages.falseMessage);

        case undefined:
            return intl.formatMessage(messages.undefinedMessage);
    }
}

