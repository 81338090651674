import React, { ReactNode } from 'react';
import { Resizable, ResizeCallback } from 're-resizable';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgButton } from '../arg-button/arg-button';
import { LEFT_ENABLE, RIGHT_ENABLE } from '../utils/resizable';

import './arg-drawer.less';


type ArgDrawerSize = 'small' | 'medium' | 'large' | 'xlarge';
type ArgDrawerPlacement = 'left' | 'right'

const DEFAULT_PANEL_WIDTH = 256;
const DEFAULT_PLACEMENT = 'right';

interface ArgDrawerProps {
    children: ReactNode;

    title: ArgRenderedText;
    description?: ArgRenderedText;
    messageValues?: ArgMessageValues;

    placement?: ArgDrawerPlacement;
    className?: ClassValue;
    bodyClassName?: ClassValue;

    size?: ArgDrawerSize;

    closable?: boolean;
    onClose?: () => void;

    resizable?: boolean;
    defaultPanelWidth?: number;
    panelMinWidth?: number|string;
    panelMaxWidth?: number|string;
    onPanelResized?: ResizeCallback;
}

export function ArgDrawer(props: ArgDrawerProps) {
    const {
        size = 'medium',
        title,
        description,
        children,
        className,
        messageValues,
        placement = DEFAULT_PLACEMENT,
        bodyClassName,
        closable,
        onClose,
        resizable,
        panelMaxWidth,
        panelMinWidth,
        defaultPanelWidth,
        onPanelResized,
    } = props;

    const classNames = useClassNames('arg-drawer');

    const content = <>
        <div className={classNames('&-title')}>
            <div className={classNames('&-title-label')}>
                <div className={classNames('&-title-label-name')}>
                    {renderText(title, messageValues)}
                </div>
                {description && <div className={classNames('&-title-label-description')}>
                    {renderText(description, messageValues)}
                </div>}
            </div>
            {closable && <ArgButton
                className={classNames('&-title-close')}
                icon='icon-cross'
                onClick={onClose}
                type='ghost'
            />}
        </div>
        <div className={classNames('&-content', bodyClassName)}>
            {children}
        </div>
    </>;

    if (resizable && onPanelResized) {
        return (
            <Resizable
                        key='resizable-panel'
                        className={classNames('&')}
                        defaultSize={{
                            width: defaultPanelWidth || DEFAULT_PANEL_WIDTH,
                            height: 'auto',
                        }}
                        onResizeStop={onPanelResized!}
                        minWidth={panelMinWidth}
                        maxWidth={panelMaxWidth}
                        enable={(placement) === 'right' ? LEFT_ENABLE : RIGHT_ENABLE}
            >
                {content}
            </Resizable>
        );
    }

    const cls = {
        left: placement === 'left',
        right: placement === 'right',
        'size-small': size === 'small',
        'size-medium': size === 'medium',
        'size-large': size === 'large',
        'size-xlarge': size === 'xlarge',
    };

    return (
        <div className={classNames('&', className, cls)}>
            {content}
        </div>
    );
}
