import { useCallback, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
    ArgButton,
    ArgInputSearch,
    ArgModal,
    ArgUserId,
    ProgressMonitor,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { GroupsList } from 'src/settings/groups/components/groups-list';
import { Group } from 'src/model/user';
import userGroupsConnector from 'src/settings/connectors/user-groups-connector';
import { SettingsConnector } from '../../../connectors/settings-connector';

import './add-user-group-modal.less';

export const messages = defineMessages({
    title: {
        id: 'settings.add-user-group-modal.title',
        defaultMessage: 'Add to group',
    },
    subtitle: {
        id: 'settings.add-user-group-modal.subtitle',
        defaultMessage: 'Select groups to join',
    },
    cancel: {
        id: 'settings.add-user-group-modal.cancel',
        defaultMessage: 'Cancel',
    },
    add: {
        id: 'settings.add-user-group-modal.add',
        defaultMessage: 'Add',
    },
});

export interface AddUserToGroupModalProps {
    userId: ArgUserId;
    groups: Group[];
    onClose: () => void;
    onAdd: () => void;
}

export const AddUserToGroupModal = (props: AddUserToGroupModalProps) => {
    const {
        userId,
        groups,
        onClose,
        onAdd,
    } = props;

    const [userGroupsIds, SetUserGroupsIds] = useState<string[]>([]);
    const [selectedIds, SetSelectedIds] = useState<string[]>([]);
    const [search, setSearch] = useState<string>('');
    const classNames = useClassNames('settings-add-user-group-modal');

    useEffect(() => {
        SettingsConnector.getInstance().getUserGroups(userId).then((res) => {
            const groupsIds = res.map((group) => {
                return group.id;
            });
            SetUserGroupsIds(groupsIds);
            SetSelectedIds(groupsIds);
        }).catch((error) => {
            console.error(error);
        });
    }, [userId]);

    const [handleAddToGroup, addGroupProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const groupApiCalls = [];
        const groupIdsToAdd = selectedIds.filter((id) => {
            return !userGroupsIds.includes(id);
        });

        if (groupIdsToAdd.length > 0) {
            groupApiCalls.push(
                ...groupIdsToAdd.map((groupId) => {
                    const result = userGroupsConnector.addMembersToGroup(
                        { groupIds: [], userIds: [userId] },
                        groupId,
                        progressMonitor
                    );

                    return result;
                })
            );
        }

        await Promise.all(groupApiCalls).catch((error) => {
            console.error(error);
        });

        onAdd();
        onClose();
    }, [selectedIds, userGroupsIds, userId]);

    const handleSelection = useCallback((checked: boolean, id: string) => {
        if (checked) {
            SetSelectedIds([...selectedIds, id]);
        } else {
            SetSelectedIds(selectedIds.filter((groupId) => groupId !== id));
        }
    }, [selectedIds]);

    const handleSearch = useCallback((value: string) => {
        setSearch(value);
    }, []);

    return <ArgModal
        title={messages.title}
        onClose={onClose}
        onCancel={onClose}
        onOk={handleAddToGroup}
        footer={
            <div>
                <ArgButton
                    className={classNames('&-footer-button')}
                    type='secondary'
                    onClick={onClose}
                    label={messages.cancel}
                    disabled={addGroupProgressMonitor?.isRunning}
                />
                <ArgButton
                    className={classNames('&-footer-button')}
                    type='primary'
                    label={messages.add}
                    data-testid='edit'
                    onClick={handleAddToGroup}
                    disabled={addGroupProgressMonitor?.isRunning}
                    loading={addGroupProgressMonitor?.isRunning}
                    tooltip={messages.add}
                />
            </div>}
    >
        <div className={classNames('&-subtitle')}>
            <FormattedMessage {...messages.subtitle} />
        </div>
        <ArgInputSearch onInputChange={handleSearch} className={classNames('&-search-bar')} />
        <GroupsList
            groups={groups}
            groupIdsSelected={selectedIds}
            groupIdsDisabled={userGroupsIds}
            handleCheckboxSelection={handleSelection}
            search={search}
        />
    </ArgModal>;
};
