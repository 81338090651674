import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { defineMessages } from 'react-intl';
import { NumberSize, Resizable, ResizeDirection } from 're-resizable';

import {
    ArgPageConfig,
    ClassValue,
    KeyBindingsEngine,
    LEFT_ENABLE,
    ProgressMonitor,
    useArgModalContext,
    useArgNotifications,
    useClassNames,
    useEffectAsync,
    useToolContext,
} from 'src/components/basic';
import { TopBar } from 'src/components/features/top-bar/top-bar';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { SettingsNavMenu } from './nav-menu/settings-nav-menu';
import { USERS_AND_GROUPS_ROUTES } from './users';
import { GetOntologiesDTO } from './models/dtoApi';
import { SETTINGS_ROUTE_PATH } from './settings-router';
import { SettingsPermissions } from './permissions/permissions';
import { useHasAnyPermissions } from 'src/contexts/user-permission';
import { GLOBAL_SCOPE } from '../exploration/keybindings/global';
import { KEY_BINDINGS } from '../components/common/keybindings/global';
import { ErrorPane } from '../components/common/panes/error-pane';
import ontologiesConnector from './connectors/ontologies-connector';
import { useUserConfiguration } from '../hooks/use-user-configuration';
import { isResponse403, isResponse404 } from 'src/components/basic/utils/response-error';
import { UserAndGroupsPanel } from './users/views/users-and-groups-panel';
import { ContextualVariablesView } from './contextual-variables/views/contextual-variables';
import { VisualIdentityView } from './visual-identity/view/visual-identity';
import { ExtensionsView } from './extensions/extensions-view/extensions-view';
import { RolesView } from './roles/views/roles-view';
import { ConfigurationType } from './configuration/configuration-type';
import { ConfigurationView } from './configuration/views/configuration-view';
import { OntologyRouter } from './universes/ontology/ontology-router';
import { getArgonosModuleById, listArgonosModules } from '../components/application/modules-manager';
import { ApplicationsVersion } from '../components/common/applications/applications-version';
import { SettingsConnector } from './connectors/settings-connector';
import { SettingsEnvironmentContext } from './envrionment-contexts/settings-environment-context';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME, SETTINGS_TOOLBAR_CONTEXT_NAME } from './envrionment-contexts/context-names';
import { useGetMyPermissions } from '../contexts/user-permissions-context';
import { registerToolItem } from '../components/basic/arg-toolbar/tools-registry';

import './home-page.less';


const DEFAULT_PANEL_WIDTH = '250px';
const LEFT_MAX_WIDTH = '50%';
const LEFT_MIN_WIDTH = '64px';

const USER_CONFIGURATION_KEY = 'settings.ui.KEY_BINDINGS';

const FORCE_LOADING = false;
const FORCE_ERROR = false;

const messages = defineMessages({
    activity: {
        id: 'settings.home-page.activity',
        defaultMessage: 'Latest Activity',
    },
    topBarTitle: {
        id: 'settings.home-page.PageTitle',
        defaultMessage: 'Settings',
    },
    pageTitle: {
        id: 'settings.home-page.PageTitle',
        defaultMessage: 'Settings',
    },
    fetchingUniverses: {
        id: 'settings.home-page.fetch-universe.message',
        defaultMessage: 'Loading universes',
    },
    fetchingUniversesError: {
        id: 'settings.home-page.fetch-universe.error',
        defaultMessage: 'An error occurred while fetching universes',
    },
});

const CLASSNAME = 'settings-home-page';

export interface HomePageProps {
    className?: ClassValue;
}

export function HomePage(props: HomePageProps) {
    const { className } = props;

    const classNames = useClassNames(CLASSNAME);
    const { permissions: userPermissions } = useGetMyPermissions();
    const notifications = useArgNotifications();
    const modalContext = useArgModalContext();
    const navigate = useNavigate();


    const settingsEnvironmentContext = useMemo<SettingsEnvironmentContext>(() => {
        const result: SettingsEnvironmentContext = {
            getArgonosModuleById,
            navigate,
            modalContext,
            hasAnyPermissions<T>(...permissionNames: (keyof T)[]): boolean {
                for (const p of permissionNames) {
                    if (userPermissions[p as string]) {
                        return true;
                    }
                }

                return false;
            },
        };

        return result;
    }, [navigate, userPermissions, modalContext]);

    // Use null as loading state, undefined means ontologies must not be fetch
    const [ontologies, setOntologies] = useState<GetOntologiesDTO[] | null | undefined>(null);

    const [leftPanelWidth, setLeftPanelWidth] = useUserConfiguration<string | number>(
        'settings.left-panel.width',
        DEFAULT_PANEL_WIDTH
    );

    const handleLeftPanelResized = useCallback((event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLElement, delta: NumberSize) => {
        const bounds = elementRef.getBoundingClientRect();
        setLeftPanelWidth(bounds.width);
    }, [setLeftPanelWidth]);

    const toolbarToolContext = useToolContext<SettingsEnvironmentContext>(SETTINGS_TOOLBAR_CONTEXT_NAME);

    const settingsToolContext = useToolContext<SettingsEnvironmentContext>(SETTINGS_LEFT_MENU_CONTEXT_NAME);

    const hasUsersAndGroupsAccess = useHasAnyPermissions<SettingsPermissions>('admin.user.access', 'admin.user.group.access');

    const fetchOntologies = useCallback(async (progressMonitor: ProgressMonitor) => {
        /* @TODO  Socle v1.1 */
        const explorationModule = getArgonosModuleById('chapsVision.DataExploration');
        if (!explorationModule?.enabled) {
            setOntologies(undefined);

            return;
        }

        try {
            const ontologies = await ontologiesConnector.getOntologies(progressMonitor);

            setOntologies(ontologies);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            if (isResponse404(error) || isResponse403(error)) {
                setOntologies([]);

                return;
            }

            setOntologies(undefined);
            notifications.snackError({ message: messages.fetchingUniversesError }, error as Error);
            throw error;
        }
    }, [notifications]);

    const updateOntologies = useCallback(async (progressMonitor: ProgressMonitor) => {
        await fetchOntologies(progressMonitor);
    }, [fetchOntologies]);

    const [fetchUniversesMonitor, fetchError] = useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        await fetchOntologies(progressMonitor);
    }, []);

    const routes = useMemo(() => {
        const ret: ReactNode[] = [];
        for (const module of listArgonosModules().value()) {
            for (const { path, element } of (module.settings || [])) {
                ret.push(
                    <Route key={path} path={path} element={element} />
                );
            }
        }

        return ret;
    }, []);

    if (FORCE_ERROR || fetchError) {
        return (
            <div className={classNames('&', 'error')}>
                <ErrorPane error={fetchError} />
            </div>
        );
    }

    if (FORCE_LOADING || ontologies === null) {
        return (
            <div className={classNames('&', 'loading')}>
                <LoadingPane progressMonitor={fetchUniversesMonitor} />
            </div>
        );
    }

    return (
        <ArgPageConfig pageSubTitle={messages.pageTitle}>
            <KeyBindingsEngine
                defs={KEY_BINDINGS}
                userConfigurationKey={USER_CONFIGURATION_KEY}
                globalScope={GLOBAL_SCOPE}
            >
                <div className={classNames('&', className)} data-testid='settings-home-page'>
                    <TopBar<SettingsEnvironmentContext>
                        className={classNames('&-header')}
                        homePage={SETTINGS_ROUTE_PATH}
                        toolContext={toolbarToolContext}
                        environmentContext={settingsEnvironmentContext}
                    />
                    <section className={classNames('&-body')}>
                        <Resizable
                            key='left-panel'
                            className={classNames('&-body-left-panel')}
                            defaultSize={{
                                width: leftPanelWidth,
                                height: 'auto',
                            }}
                            onResizeStop={handleLeftPanelResized}
                            minWidth={LEFT_MIN_WIDTH}
                            maxWidth={LEFT_MAX_WIDTH}
                            enable={LEFT_ENABLE}
                        >
                            <SettingsNavMenu
                                ontologies={ontologies}
                                className={classNames('&-body-nav')}
                                settingsToolContext={settingsToolContext}
                                settingsEnvironmentContext={settingsEnvironmentContext}
                            />
                        </Resizable>
                        <main className={classNames('&-body-main')}>
                            <Routes>
                                <Route
                                    path='users-and-groups'
                                    element={<UserAndGroupsPanel />}
                                />
                                <Route
                                    path='contextual-variables'
                                    element={<ContextualVariablesView />}
                                />
                                <Route
                                    path='visual-identity'
                                    element={<VisualIdentityView />}
                                />
                                <Route
                                    path='extensions/:extensionsScope'
                                    element={<ExtensionsView />}
                                />
                                <Route
                                    path=':rolesScope/roles'
                                    element={<RolesView />}
                                />
                                {routes}
                                <Route
                                    path=':configurationScope/preferences'
                                    element={<ConfigurationView type={ConfigurationType.Preferences} />}
                                />
                                {/* @TODO  Socle v1.1 */}
                                <Route
                                    path='data_exploration/ontology/*'
                                    element={<OntologyRouter
                                        ontologies={ontologies}
                                        updateOntologies={updateOntologies}
                                    />}
                                />
                                {hasUsersAndGroupsAccess && (
                                    <Route
                                        path='*'
                                        element={<Navigate to={USERS_AND_GROUPS_ROUTES} />}
                                    />
                                )}
                            </Routes>
                        </main>
                    </section>
                </div>
            </KeyBindingsEngine>
        </ArgPageConfig>
    );
}

export function setupApplicationVersion() {
    registerToolItem(SETTINGS_TOOLBAR_CONTEXT_NAME, {
        path: 'right/user/begin/version',
        type: 'custom',
        order: 200,
        label: (
            <ApplicationsVersion
                getApplicationVersion={(progressMonitor) => {
                    return SettingsConnector.getInstance().applicationVersion(progressMonitor);
                }}
            />
        ),
    });
}
