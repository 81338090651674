import { defineMessages, useIntl } from 'react-intl';

import { ArgIcon, useClassNames } from 'src/components/basic';
import { RuleEffect as TypeRuleEffect } from 'src/settings/models/policy';
import { useAccessRuleStateContext } from '../../../../common/providers/policy-rules-provider';
import { RuleEffect } from '../rule-effect/rule-effect';

import './policy-rule-block-effect.less';

interface PolicyRuleBlockEffectProps {
    effects: TypeRuleEffect[];
    editable: boolean;
}

const messages = defineMessages({
    effects: {
        id: 'settings.policy-rule-block-effects.effects',
        defaultMessage: 'Effects',
    },
    effect: {
        id: 'settings.policy-rule-block-effect.effect',
        defaultMessage: 'Effect',
    },
});

export const PolicyRuleBlockEffect: React.FC<PolicyRuleBlockEffectProps> = ({
    effects,
    editable,
}) => {
    const classNames = useClassNames('policy-rule-block-effect');
    const { setRule } = useAccessRuleStateContext();
    const intl = useIntl();

    const addEffect = () => {
        setRule((currentRule) => {
            return {
                ...currentRule,
                Effects: [...effects, { object: { match: [], Permissions: [] } }],
            };
        });
    };

    return (
        <>
            <div className={classNames(editable ? '&-title-editable' : '&-title')}>
                {intl.formatMessage(messages.effects)}
            </div>
            {/* Temporarily use index as key cause it's not possible to delete effect */}
            {effects.map((effect, index) => (
                <RuleEffect key={index} effect={effect} index={index} />
            ))}
            {editable && (
                <div className={classNames('&-icon')} onClick={() => addEffect()}>
                    <ArgIcon name='icon-add-outline' />
                    <div>{intl.formatMessage(messages.effect)}</div>
                </div>
            )}
        </>
    );
};
