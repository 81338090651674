import { defineMessages } from 'react-intl';

import { defineKeyBindings, KeyBindingScopeDescriptor } from '../../components/basic';

export const messages = defineMessages({
    casesScope: {
        id: 'exploration.keybindings.CasesScope',
        defaultMessage: 'Cases Page',
    },
    newCase: {
        id: 'exploration.keybindings.NewCase',
        defaultMessage: 'New Case',
    },
    sandboxSearch: {
        id: 'exploration.keybindings.SandboxSearch',
        defaultMessage: 'Search in the universe',
    },
    openStructuredSearch: {
        id: 'exploration.keybindings.OpenStructuredSearch',
        defaultMessage: 'Open advanced search',
    },
    openFastSearch: {
        id: 'exploration.keybindings.OpenFastSearch',
        defaultMessage: 'Open fast search',
    },
    openMassiveSearch: {
        id: 'exploration.keybindings.OpenMassiveSearch',
        defaultMessage: 'Open massive search',
    },
    searchInCases: {
        id: 'exploration.keybindings.SearchInCases',
        defaultMessage: 'Search in cases',
    },
    openMySandboxCase: {
        id: 'exploration.keybindings.OpenMySandboxCase',
        defaultMessage: 'Open my sandbox case',
    },
    searchInUniverse: {
        id: 'exploration.keybindings.SearchInUniverse',
        defaultMessage: 'Search in universe',
    },
});

export const SCOPE: KeyBindingScopeDescriptor = {
    id: 'exploration.Cases',
    name: messages.casesScope,
};

export const KEY_BINDINGS_HOME_SEARCH = defineKeyBindings({
    openStructuredSearch: {
        id: 'exploration.cases.OpenStructuredSearch',
        scope: SCOPE,
        name: messages.openStructuredSearch,
        defaultKeys: 'alt+S',
    },
    openFastSearch: {
        id: 'exploration.cases.OpenFastSearch',
        scope: SCOPE,
        name: messages.openFastSearch,
    },
    openMassiveSearch: {
        id: 'exploration.cases.OpenMassiveSearch',
        scope: SCOPE,
        name: messages.openMassiveSearch,
    },
});

export const KEY_BINDINGS = defineKeyBindings({
    // Cases (home-page)
    newCase: {
        id: 'exploration.cases.NewCase',
        scope: SCOPE,
        name: messages.newCase,
        defaultKeys: 'alt+N',
    },
    sandboxSearch: {
        id: 'exploration.cases.SandboxSearch',
        scope: SCOPE,
        name: messages.sandboxSearch,
        defaultKeys: 'alt+D',
    },
    casesSearch: {
        id: 'exploration.cases.SearchInCases',
        scope: SCOPE,
        name: messages.searchInCases,
        defaultKeys: 'alt+F',
    },
    openMySandboxCase: {
        id: 'exploration.cases.OpenMySandboxCase',
        scope: SCOPE,
        name: messages.openMySandboxCase,
        defaultKeys: 'alt+M',
    },
});

