import { getProceoApi } from '../../../utils/connectors/api-url';
import { ResourceConnector } from '../../../utils/connectors/resource-connector';
import { ProceoPieceConnector } from './proceo-piece-connector';

export class ProceoResourceConnector extends ResourceConnector {
    private static instance: ProceoResourceConnector;

    static getInstance(): ProceoResourceConnector {
        if (!ProceoResourceConnector.instance) {
            ProceoResourceConnector.instance = new ProceoResourceConnector('proceo.resources', getProceoApi(), ProceoPieceConnector.getInstance());
        }

        return ProceoResourceConnector.instance;
    }
}
