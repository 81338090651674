import { ReactNode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { useClassNames } from '../arg-hooks/use-classNames';

import './arg-context-menu.less';

export interface ArgContextMenuPosition {
    x: number;
    y: number;
}

export interface ArgContextMenuProps {
    overlay: (getPopupContainer?: (container: HTMLElement) => HTMLElement) => ReactNode;
    visible: boolean;
    onHide: () => void;
    x?: number;
    y?: number;
}

export function ArgContextMenu(props: ArgContextMenuProps) {
    const {
        visible,
        onHide,
        overlay,
        x,
        y,
    } = props;

    const glass = useRef<HTMLDivElement>();
    const classNames = useClassNames('arg-context-menu');

    const animationIdRef = useRef<any>();
    const captureEvent = useRef<any>();

    const clearListeners = () => {
        if (!glass.current) {
            return;
        }

        glass.current.removeEventListener('arg-menu-update', captureEvent.current);

        if (glass.current.parentElement) {
            glass.current.parentElement.removeChild(glass.current);
        }
        glass.current = undefined;
        window.removeEventListener('mousedown', captureEvent.current);
        window.removeEventListener('keydown', captureEvent.current);
        window.removeEventListener('contextMenu', captureEvent.current);
        window.removeEventListener('focus', captureEvent.current);
    };

    if (!captureEvent.current) {
        captureEvent.current = (event: Event) => {
            if (!glass.current) {
                return;
            }

            // differed update from the menu component : request an adjustment
            if (event.type === 'arg-menu-update') {
                if (event.target === glass.current) {
                    if (!animationIdRef.current) {
                        function f() {
                            animationIdRef.current = undefined;
                            if (glass.current && glass.current.firstElementChild) {
                                updatePosition(glass.current.firstElementChild as HTMLElement);
                            }
                        }

                        animationIdRef.current = requestAnimationFrame(f);
                    }
                }

                return;
            }

            const target = event.target as Element | null;

            if (target?.closest && !target.closest(`.${classNames('&-glass')}`)) {
                onHide();
            }
        };
    }

    useEffect(() => {
        return () => {
            if (animationIdRef.current) {
                cancelAnimationFrame(animationIdRef.current);
            }

            clearListeners();
        };
    }, []);

    useEffect(() => {
        if (visible) {
            return;
        }

        clearListeners();
    }, [visible]);

    if (!visible) {
        return null;
    }

    if (!glass.current) {
        const g = document.createElement('div');
        glass.current = g;

        g.className = classNames('&-glass');

        window.addEventListener('mousedown', captureEvent.current);
        window.addEventListener('keydown', captureEvent.current);
        window.addEventListener('contextMenu', captureEvent.current);
        window.addEventListener('focus', captureEvent.current);
        g.addEventListener('arg-menu-update', captureEvent.current);

        const p = document.createElement('div');
        p.className = classNames('&-popup');
        p.setAttribute('data-contextmenu', 'true');
        g.appendChild(p);


        if (x !== undefined && y !== undefined) {
            p.style.left = `${x}px`;
            p.style.top = `${y}px`;
        }
        p.style.visibility = 'hidden';

        document.body.appendChild(g);
    }

    if (glass.current && glass.current.firstElementChild) {
        const cb = (() => glass.current) as (() => HTMLDivElement);
        const popup = overlay(cb);

        return ReactDOM.createPortal(
            popup,
            glass.current.firstElementChild
        );
    }

    return null;
}

function updatePosition(popup: HTMLElement) {
    const newClientRect = popup.getBoundingClientRect();

    if (!newClientRect.width) {
        return false;
    }

    const measures: DOMRect = document.body.getBoundingClientRect();

    if (newClientRect.bottom > measures.bottom) {
        popup.style.top = `${measures.bottom - newClientRect.height}px`;
    }
    if (newClientRect.right > measures.right) {
        popup.style.left = `${measures.right - newClientRect.width}px`;
    }
    popup.style.visibility = '';

    return true;
}
