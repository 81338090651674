import { Row } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';

import { ArgCheckbox, ArgCombo, ArgIcon, useClassNames } from 'src/components/basic';
import { ConstraintItem, FormatItem, messages, NUMERIC_ITEM_TYPES, TYPE_ITEMS, TypeItem } from './const';
import { ArgFormLabel } from '../../../../../components/basic';

import './property-type-and-constraints.less';

interface PropertyTypeAndConstraintProps {
    type?: TypeItem;
    setType: Dispatch<SetStateAction<TypeItem | undefined>>;
    setFormat: Dispatch<SetStateAction<FormatItem | undefined>>;
    constraint: ConstraintItem;
    setConstraint: Dispatch<SetStateAction<ConstraintItem>>;
    characters: [number, number];
    setCharacters: Dispatch<SetStateAction<[number, number]>>;
    continuousNumber: boolean;
    setContinuousNumber: Dispatch<SetStateAction<boolean>>;
    isMetaProperty?: boolean;
    inEditModal: boolean;
}

export function PropertyTypeAndConstraint(props: PropertyTypeAndConstraintProps) {
    const {
        type,
        setType,
        setConstraint,
        setFormat,
        continuousNumber,
        setContinuousNumber,
        isMetaProperty,
        inEditModal,
    } = props;

    const className = useClassNames('property-type-and-constraints');

    const types = Object.keys(TYPE_ITEMS) as TypeItem[];
    const propertyName = isMetaProperty ? messages.metapropertyFieldType : messages.fieldType;

    return (
        <>
            <Row>
                <ArgFormLabel propertyName={propertyName}>
                    <ArgCombo<TypeItem>
                        disabled={inEditModal}
                        items={types}
                        renderItem={(type) => {
                            const value = TYPE_ITEMS[type];

                            return (
                                <div className={className('&-item')} key={type}>
                                    <ArgIcon name={value.icon} size='large' />
                                    <span>
                                        <FormattedMessage {...value.name} />
                                    </span>
                                </div>
                            );
                        }}
                        onChange={(selected: TypeItem) => {
                            setType(selected);
                            setConstraint(ConstraintItem.none);
                            setFormat(undefined);
                            setContinuousNumber(false);
                        }}
                        value={type}
                        left={type ? TYPE_ITEMS[type]?.icon : undefined}
                        getItemLabel={(type) => {
                            return type ? TYPE_ITEMS[type]?.name : undefined;
                        }}
                    />
                </ArgFormLabel>
            </Row>
            {type && NUMERIC_ITEM_TYPES.includes(type) && !isMetaProperty && (
                <ArgCheckbox
                    value={continuousNumber}
                    onChange={(value) => setContinuousNumber(value)}
                    size='small'
                    label={messages.continuousNumber}
                />
            )}
        </>
    );
}
