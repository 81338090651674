import { useContext, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ArgButton, ArgModal, useArgNotifications, useClassNames } from 'src/components/basic';
import { User } from 'src/model/user';
import { UsersAndGroupsStateContext } from '../../providers/usersState';
import { NotifyBox } from 'src/settings/common-modals/notify-box/notify-box';
import { WARNING_ORANGE } from 'src/settings/models/colors';
import { UsersAdminConnector } from '../../../../utils/connectors/users-admin-connector';

import './single-user-delete-modal.less';

export const messages = defineMessages({
    title: {
        id: 'settings.delete-user-modal.title',
        defaultMessage: 'Delete a user',
    },
    description: {
        id: 'settings.delete-user-modal.description',
        defaultMessage: 'Are you sure you want to delete the user?',
    },
    cancel: {
        id: 'settings.delete-user-modal.button.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.delete-user-modal.button.delete',
        defaultMessage: 'Delete',
    },
    deleteUserErrorMsg: {
        id: 'settings.delete-user-modal.error-message',
        defaultMessage: 'Something went wrong while deleting the user',
    },
    warning: {
        id: 'settings.delete-user-modal.warning',
        defaultMessage:
            'This account "{username}" will lose its attributes and can be restored for 30 days',
    },
});

interface DeleteUserModalProps {
    closeModal: () => void;
    user: User;
}

export function DeleteUserModal(props: DeleteUserModalProps) {
    const {
        closeModal,
        user,
    } = props;

    const intl = useIntl();
    const notifications = useArgNotifications();
    const classNames = useClassNames('settings-delete-user-modal');
    const [loading, setLoading] = useState(false);
    const { setUsers, setDeletedUsers } = useContext(UsersAndGroupsStateContext);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await UsersAdminConnector.getInstance().deleteUser(user.id, false);
            const deletedUsers = await UsersAdminConnector.getInstance().getUsers(true);
            setUsers((currentUsers) =>
                currentUsers.filter((existingUser) => existingUser.id !== user.id)
            );
            setDeletedUsers(deletedUsers);
            setLoading(false);
            closeModal();
        } catch (error) {
            notifications.snackError({ message: messages.deleteUserErrorMsg }, error as Error);
            setLoading(false);
        }
    };

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={closeModal}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={closeModal}
                        label={messages.cancel}
                        disabled={loading}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        onClick={() => handleSubmit()}
                        label={messages.delete}
                        loading={loading}
                        disabled={loading}
                        data-testid='delete'
                    />
                </div>
            }
        >
            <div>
                <NotifyBox
                    icon='icon-exclamation-point'
                    color={WARNING_ORANGE}
                    text={intl.formatMessage(messages.description)}
                    subtext={intl.formatMessage(messages.warning, { username: user.userName })}
                />
            </div>
        </ArgModal>
    );
}
