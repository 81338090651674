import React, { useCallback, useMemo } from 'react';

import { ArgButtonGroup, ArgButtonProps, ArgSize, ClassValue, useClassNames } from '../../../components/basic';
import {
    DEFAULT_EXPRESSION_LANGUAGE,
    EXPRESSION_LANGUAGES,
    ExpressionLanguage,
} from './constants';

export interface ProgrammingLanguageChoiceProps {
    value: ExpressionLanguage;
    languages: ExpressionLanguage[];
    onChange: (language: ExpressionLanguage) => void;
    size?: ArgSize;
    initialValue?: ExpressionLanguage,
    className?: ClassValue;
}

export function ExpressionLanguageChoice(props: ProgrammingLanguageChoiceProps) {
    const {
        size = 'medium',
        languages: _languages,
        value = DEFAULT_EXPRESSION_LANGUAGE,
        className,
        onChange,
    } = props;

    const classNames = useClassNames('preparation-expression-language-choice');

    const handleLanguageChange = useCallback((language: ExpressionLanguage) => {
        onChange(language);
    }, [onChange]);

    const languages = useMemo<ArgButtonProps[]>(() => {
        const ret: ArgButtonProps[] = _languages.map((language) => {
            const ret: ArgButtonProps = {
                label: EXPRESSION_LANGUAGES[language].label,
                size: size,
                type: language === value ? 'primary' : 'secondary',
                onClick: () => handleLanguageChange(language),
            };

            return ret;
        });

        return ret;
    }, [_languages, size, value, handleLanguageChange]);


    return (
        <ArgButtonGroup
            buttons={languages}
            className={classNames('&', className)}
        />
    );
}
