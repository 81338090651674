import { defineMessages, FormattedMessage } from 'react-intl';
import { useCallback } from 'react';

import { useClassNames } from 'src/components/basic';
import { ValuationRuleEffect, ValuationRuleProcessed } from '../../../../../models/valuation-policy';
import { ROW_HEIGHT } from 'src/settings/universes/common/policy-utils';
import { DeleteFilterButton } from 'src/settings/universes/common/effects/delete-filter-button';
import { MetaOrPropertyDropdown } from './meta-or-property-dropdown';
import { MetapropertyDropdowns } from './metaproperty-dropdowns';
import { UniverseItemDropdown } from './universe-item-dropdown';
import { EventDropdown } from './event-dropdown';
import { ValuationDropdown } from './valuation-dropdown';
import { useValuationRuleStateContext } from 'src/settings/universes/common/providers/policy-rules-provider';

import './valuation-rule-effect-block.less';

export const messages = defineMessages({
    for: {
        id: 'settings.valuation-rule-effect.for',
        defaultMessage: 'For',
    },
});

interface ValuationRuleEffectBlockProps {
    effect: ValuationRuleEffect;
    index: number;
}

export const ValuationRuleEffectBlock = ({ effect, index }: ValuationRuleEffectBlockProps) => {
    const classNames = useClassNames('settings-valuation-rule-effect-block');
    const { setRule, editable } = useValuationRuleStateContext();

    const targetType = effect.object ? 'object' : effect.property ? 'property' : undefined;
    const effectValue = effect.object || effect.property || undefined;

    const deleteEffectFilter = useCallback(() => {
        setRule((currentRule: ValuationRuleProcessed) => {
            return {
                ...currentRule,
                Effects: currentRule.Effects.filter((effect, idx) => idx !== index),
            };
        });
    }, [index, setRule]);

    const shouldDisplayPropertyDropdown = (targetType === 'property' && !!effectValue?.setMetadata?.name) || targetType === 'object' && !!effectValue;
    const shouldDisplayEventDropdown = shouldDisplayPropertyDropdown && (!!effectValue?.setProperty?.name || !!effectValue?.match?.length);
    const shouldDisplayValuationDropdown = shouldDisplayEventDropdown && (!!effectValue?.setProperty?.onEvent || !!effectValue?.setMetadata?.onEvent);

    return (
        <>
            <div className={classNames('&-row-container')} style={{ height: ROW_HEIGHT }}>
                <div className={classNames('&-sentence-text')}>
                    <FormattedMessage {...messages.for} />
                </div>
                <MetaOrPropertyDropdown
                    targetType={targetType}
                    index={index}
                />
                {targetType === 'property' && effectValue && (
                    <MetapropertyDropdowns
                        effectValue={effectValue}
                        targetType={targetType}
                        index={index}
                    />
                )}
                {shouldDisplayPropertyDropdown && (
                    <UniverseItemDropdown
                        effectValue={effectValue}
                        targetType={targetType}
                        index={index}
                    />
                )}
                {shouldDisplayEventDropdown && (
                    <EventDropdown
                        effectValue={effectValue}
                        targetType={targetType}
                        index={index}
                    />
                )}
                {shouldDisplayValuationDropdown && (
                    <ValuationDropdown
                        effectValue={effectValue}
                        targetType={targetType}
                        index={index}
                    />
                )}
                {editable && <DeleteFilterButton onDeleteRule={deleteEffectFilter} />}
            </div>
        </>
    );
};
