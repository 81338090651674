import { defineMessages } from 'react-intl';
import React, { lazy, Suspense } from 'react';
import { RouteProps } from 'react-router';
import { Navigate, Route } from 'react-router-dom';

import { registerArgonosModule } from '../components/application/modules-manager';
import { PROCEO_ROOT_URL } from '../components/containers/routes-urls';
import { Environment } from '../utils/environment';
import { ArgonosModuleRouterProps } from '../components/containers/authenticated-apps-router';
import { LoadingSuspense } from '../components/containers/loading-suspense';
import { ArgonosModule } from '../components/application/modules';
import { getProceoApi } from '../utils/connectors/api-url';
import { HierarchyOverview } from './module-settings/services-groups/features/hierarchy-overview';
import { PROCEO_PERMISSIONS_REQUIRED } from './model/permissions';

const LazyProceoRouter = lazy(() => import('./proceo-router'));

const FORCE_SUSPENSE_LOADING = false;

const messages = defineMessages({
    proceoApplication: {
        id: 'common.applications.Proceo',
        defaultMessage: 'Proceo',
    },
    hierarchy: {
        id: 'proceo.services-groups.menu.title',
        defaultMessage: 'Services and groups',
    },
});

function ProceoApp(props: ArgonosModuleRouterProps) {
    const { className } = props;

    if (FORCE_SUSPENSE_LOADING) {
        return <LoadingSuspense />;
    }

    return (
        <Suspense fallback={<LoadingSuspense />}>
            <LazyProceoRouter
                className={className}
            />
        </Suspense>
    );
}

const PROCEO_MODULE: ArgonosModule = {
    id: 'chapsVision.Proceo',
    subType: 'Proceo',

    routeURL: PROCEO_ROOT_URL,
    enabled: Environment.isProceoEnabled(),
    requiredPermissions: PROCEO_PERMISSIONS_REQUIRED,
    apiURL: getProceoApi(),
    testURL: '/cases',

    iconURL: Environment.proceoIcon,
    name: messages.proceoApplication,
    color: '#000000',

    // proceo and settings docs are the same (and we're missing doc url in the config hence this solution)
    documentationURL: Environment.docServerUrlSettings,

    getRoutes(): React.ReactElement<RouteProps>[] {
        return [
            <Route
                key='proceo'
                path='proceo'
                element={<Navigate to='./folders' replace={true} />}
            />,
            <Route
                key='proceo/*'
                path='proceo/*'
                element={(
                    <ProceoApp argonosModule={PROCEO_MODULE} />
                )}
            />,
        ];
    },

    hasRoles: true,
    hasUserRoles: true,
    hasGroupRoles: true,
    hasUserPermissions: true,
    scope: 'proceo',
    hasEnvironments: true,
    settings: [
        {
            path: 'proceo/hierarchy',
            label: messages.hierarchy,
            icon: 'icon-user-badge',
            element: <HierarchyOverview />,
        },
    ],
};

registerArgonosModule(PROCEO_MODULE);
