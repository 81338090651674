
import { defineMessages } from 'react-intl';

import { ArgFormLabel, ArgInputText, ClassValue, useClassNames } from '../basic';

import './address-form-label.less';

const CLASSNAME = 'common-address-form-label';

const messages = defineMessages({
    streetNumberTitle: {
        id: 'common.address.street-number.title',
        defaultMessage: 'Street number',
    },
    streetTypeTitle: {
        id: 'common.address.street-type.title',
        defaultMessage: 'Street type',
    },
    streetNameTitle: {
        id: 'common.address.street-name.title',
        defaultMessage: 'Street name',
    },
    additionalAddressTitle: {
        id: 'common.address.additional-address.title',
        defaultMessage: 'Additional address',
    },
    zipCodeTitle: {
        id: 'common.address.zip-code.title',
        defaultMessage: 'Zip code',
    },
    cityTitle: {
        id: 'common.address.city.title',
        defaultMessage: 'City',
    },
    countryTitle: {
        id: 'common.address.country.title',
        defaultMessage: 'Country',
    },
});

interface AddressFormLabelProps {
    className?: ClassValue;
    streetNumber?: string;
    streetName?: string;
    streetType?: string;
    additionalAddress?: string;
    zipCode?: string;
    city?: string;
    country?: string;
    setStreetNumber?: (val: string) => void;
    setStreetType?: (val: string) => void;
    setStreetName?: (val: string) => void;
    setAdditionalAddress?: (val: string) => void;
    setZipCode?: (val: string) => void;
    setCity?: (val: string) => void;
    setCountry?: (val: string) => void;
}
export function AddressFormLabel({
    className,
    streetNumber,
    streetName,
    streetType,
    additionalAddress,
    zipCode,
    city,
    country,
    setStreetNumber,
    setStreetType,
    setStreetName,
    setAdditionalAddress,
    setZipCode,
    setCity,
    setCountry,
}: AddressFormLabelProps) {
    const classNames = useClassNames(CLASSNAME);

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-row-1')}>
                <ArgFormLabel
                    key='street-number'
                    className={classNames('&-street-number')}
                    size='medium'
                    propertyName={messages.streetNumberTitle}
                >
                    <ArgInputText
                        value={streetNumber}
                        onInputChange={setStreetNumber}
                        placeholder={messages.streetNumberTitle}
                        className={classNames('&-street-number-input')}
                    />
                </ArgFormLabel>
                <ArgFormLabel
                    key='street-type'
                    className={classNames('&-street-type')}
                    size='medium'
                    propertyName={messages.streetTypeTitle}
                >
                    <ArgInputText
                        value={streetType}
                        onInputChange={setStreetType}
                        placeholder={messages.streetTypeTitle}
                        className={classNames('&-street-type-input')}
                    />
                </ArgFormLabel>
                <ArgFormLabel
                    key='street-name'
                    className={classNames('&-street-name')}
                    size='medium'
                    propertyName={messages.streetNameTitle}
                    required={true}
                >
                    <ArgInputText
                        value={streetName}
                        onInputChange={setStreetName}
                        placeholder={messages.streetNameTitle}
                        className={classNames('&-street-name-input')}
                    />
                </ArgFormLabel>
            </div>
            <div className={classNames('&-row-2')}>
                <ArgFormLabel
                    key='additional-address'
                    className={classNames('&-additionnal-address')}
                    size='medium'
                    propertyName={messages.additionalAddressTitle}
                >
                    <ArgInputText
                        value={additionalAddress}
                        onInputChange={setAdditionalAddress}
                        placeholder={messages.additionalAddressTitle}
                        className={classNames('&-additionnal-address-input')}
                    />
                </ArgFormLabel>
            </div>
            <div className={classNames('&-row-3')}>
                <ArgFormLabel
                    key='zip-code'
                    className={classNames('&-zip-code')}
                    size='medium'
                    propertyName={messages.zipCodeTitle}
                    required={true}
                >
                    <ArgInputText
                        value={zipCode}
                        onInputChange={setZipCode}
                        placeholder={messages.zipCodeTitle}
                        className={classNames('&-zip-code-input')}
                    />
                </ArgFormLabel>
                <ArgFormLabel
                    key='city'
                    className={classNames('&-city')}
                    size='medium'
                    propertyName={messages.cityTitle}
                    required={true}
                >
                    <ArgInputText
                        value={city}
                        onInputChange={setCity}
                        placeholder={messages.cityTitle}
                        className={classNames('&-city-input')}
                    />
                </ArgFormLabel>
                <ArgFormLabel
                    key='country'
                    className={classNames('&-country')}
                    size='medium'
                    propertyName={messages.countryTitle}
                    required={true}
                >
                    <ArgInputText
                        value={country}
                        onInputChange={setCountry}
                        placeholder={messages.countryTitle}
                        className={classNames('&-country-input')}
                    />
                </ArgFormLabel>
            </div>
        </div>
    );
}
