import { chain, first, last } from 'lodash';
import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
    ArgInputSearch,
    ArgNodeKey,
    ArgNodePath,
    ArgTree,
    ClassValue,
    GetNodeKey,
    highlightSplit,
    normalizeText,
    useClassNames,
} from 'src/components/basic';
import { TemplateType } from '../../../exploration/model/template';
import { Configuration } from '../../basic/utils/configurations';
import { Environment } from '../../../utils/environment';
import { ConfigurationManifest, ConfigurationOption } from '../../../model/configuration';
import { ConfigurationType, CONFIGURATION_POLICIES_OPTIONS, USER_PROFILES_CONF_KEY } from '../../../settings/configuration/configuration-type';

import './applications-parameters-configuration.less';

const CLASSNAME = 'common-applications-parameters-configuration';
const DEFAULT_OPEN_NODES: string[] = [
    ConfigurationType.Administration,
    ConfigurationType.Preparation,
    ConfigurationType.Exploration,
];

const messages = defineMessages({
    dataPreparation: {
        id: 'common.applications-parameters-configuration.dataPreparation',
        defaultMessage: 'Preparation Data',
    },
    dataExploration: {
        id: 'common.applications-parameters-configuration.dataExploration',
        defaultMessage: 'Exploration Data',
    },
    universe: {
        id: 'common.applications-parameters-configuration.universe',
        defaultMessage: 'Universe',
    },
    ontology: {
        id: 'common.applications-parameters-configuration.ontology',
        defaultMessage: 'Ontology',
    },
    miscellaneous: {
        id: 'common.applications-parameters-configuration.miscellaneous',
        defaultMessage: 'Policies & other configurations',
    },
    briefTemplates: {
        id: 'common.applications-parameters-configuration.briefTemplates',
        defaultMessage: 'Brief templates',
    },
    styleTemplates: {
        id: 'common.applications-parameters-configuration.styleTemplates',
        defaultMessage: 'Styles templates',
    },
    processes: {
        id: 'common.applications-parameters-configuration.processes',
        defaultMessage: 'Processes',
    },
    composites: {
        id: 'common.applications-parameters-configuration.composites',
        defaultMessage: 'Components',
    },
    remoteComponents: {
        id: 'common.applications-parameters-configuration.remoteComponents',
        defaultMessage: 'Additional components',
    },
    secrets: {
        id: 'common.applications-parameters-configuration.secrets',
        defaultMessage: 'Secrets',
    },
    keyBindings: {
        id: 'common.applications-parameters-configuration.keyBindings',
        defaultMessage: 'Key bindings',
    },
    centralAdministration: {
        id: 'common.applications-parameters-configuration.centralAdministration',
        defaultMessage: 'Central administration',
    },
    users: {
        id: 'common.applications-parameters-configuration.users',
        defaultMessage: 'Users',
    },
    groups: {
        id: 'common.applications-parameters-configuration.groups',
        defaultMessage: 'Groups',
    },
    roles: {
        id: 'common.applications-parameters-configuration.roles',
        defaultMessage: 'Roles',
    },
    applicationSettings: {
        id: 'common.applications-parameters-configuration.applicationSettings',
        defaultMessage: 'Branding',
    },
    contextualVariables: {
        id: 'common.applications-parameters-configuration.contextualVariables',
        defaultMessage: 'Contextual variables',
    },
    userProfilesFieldsBundle: {
        id: 'common.applications-parameters-configuration.userProfilesFieldsBundle',
        defaultMessage: 'User profiles',
    },
});

enum TreeNodeType {
    Universe = 'Universe',
    BriefTemplate = 'BriefTemplate',
    Processes = 'Processes',
    Composites = 'Composites',
    RemoteComponents = 'RemoteComponents',
    Secrets = 'Secrets',
    AppSettings = 'AppSettings',
    Ontology = 'Ontology',
    Group = 'Group',
    AdminRole = 'AdminRole',
    ExplorationRole = 'ExplorationRole',
    PreparationRole = 'PreparationRole',
    ExplorationStyle = 'ExplorationStyle',
    User = 'User',
}

enum ConfigurationScope {
    Exploration = 'data_exploration',
    Preparation = 'data_preparation',
}

interface TreeData {
    key: string;
    label: string;
    type?: TreeNodeType;
    children?: TreeData[];
    count?: string;
}

export interface ApplicationsParametersConfigurationProps {
    className?: ClassValue;
    defaultSelectedList?: ArgNodeKey[];
    currentConfigurations: ConfigurationManifest[];
    onSelectionChange: (selectedOptions: ConfigurationOption[]) => void;
}

export function ApplicationsParametersConfiguration(props: ApplicationsParametersConfigurationProps) {
    const { defaultSelectedList, currentConfigurations, onSelectionChange } = props;
    const classNames = useClassNames(CLASSNAME);

    const intl = useIntl();

    const [openedKeys, setOpenedKeys] = useState<string[]>(DEFAULT_OPEN_NODES);
    const [selectedList, setSelectedList] = useState<ArgNodeKey[] | undefined>(defaultSelectedList);
    const [searchedToken, setSearchedToken] = useState<string>();
    const [filteredBriefConfigurations, setFilteredBriefConfigurations] = useState<Configuration[]>();
    const [filteredProcessConfigurations, setFilteredProcessConfigurations] = useState<Configuration[]>();
    const [filteredCompositeConfigurations, setFilteredCompositeConfigurations] = useState<Configuration[]>();
    const [filteredRemoteComponentConfigurations, setFilteredRemoteComponentConfigurations] = useState<Configuration[]>();
    const [filteredSecretConfigurations, setFilteredSecretConfigurations] = useState<Configuration[]>();
    const [filteredGroupConfigurations, setFilteredGroupConfigurations] = useState<Configuration[]>();
    const [filteredRoleConfigurations, setFilteredRoleConfigurations] = useState<Configuration[]>();
    const [filteredStyleConfigurations, setFilteredStyleConfigurations] = useState<Configuration[]>();
    const [filteredUsersConfigurations, setFilteredUsersConfigurations] = useState<Configuration[]>();

    const handleGetNodeLabel = useCallback((item: ArgNodePath<TreeData>) => {
        const currentItem = item[item.length - 1];
        const label = highlightSplit(currentItem.label, searchedToken);

        if (!currentItem?.count) {
            return label;
        }

        return (
            <div className={classNames('&-label-container')}>
                <span className={classNames('&-label-container-title')}>
                    {label}
                </span>

                <div className={classNames('&-label-container-count')}>
                    {currentItem.count}
                </div>
            </div>
        );
    }, [classNames, searchedToken]);

    const handleGetNodeKey: GetNodeKey<TreeData> = useCallback((item: ArgNodePath<TreeData>) => {
        switch (item.length) {
            case 1:
                return item[0].key;
            case 2:
            case 3:
            case 4: {
                const currentItem = item[item.length - 1];

                const itemKeySplitted = currentItem.key.split('/');
                const key = last(itemKeySplitted)!;

                return key;
            }
            default:
                return 'key';
        }
    }, []);

    const handleOnOpenNodes = useCallback((nodeKeys: SetStateAction<ArgNodeKey[]>) => {
        setOpenedKeys(nodeKeys);
    }, []);

    const filterNodeChildrenWithToken = useCallback((node: TreeData, token?: string): TreeData[] => {
        if (!node.children) {
            return [];
        }

        if (!token || normalizeText(node.label).includes(normalizeText(token))) {
            return node.children;
        }


        const searchedChildren: TreeData[] = node.children.filter((child) => {
            if (child.children && child.children.length > 0) {
                const subChild = filterNodeChildrenWithToken(child, token);

                return subChild.length > 0;
            }

            return normalizeText(child.label).includes(normalizeText(token));
        });

        return searchedChildren;
    }, []);

    const configurationsTree = useMemo<TreeData[]>(() => {
        const ret: TreeData[] = [];

        const briefConf = findConfByType(currentConfigurations, TemplateType.Brief);
        const ontologyConf = findConfByType(currentConfigurations, ConfigurationType.Ontologies);
        const appSettingsConf = findConfByType(currentConfigurations, ConfigurationType.ApplicationSettings);
        const styleConf = findConfByType(currentConfigurations, TemplateType.ExplorationStyle);
        const groupsConf = findConfByType(currentConfigurations, ConfigurationType.Groups);
        const processesConf = findConfByType(currentConfigurations, ConfigurationType.Processes);
        const compositesConf = findConfByType(currentConfigurations, ConfigurationType.Composites);
        const remoteComponentsConf = findConfByType(currentConfigurations, ConfigurationType.RemoteComponents);
        const secretsConf = findConfByType(currentConfigurations, ConfigurationType.Secrets);
        const usersConf = findConfByType(currentConfigurations, ConfigurationType.Users);

        const exploKeyBindingsConf = appSettingsConf?.configurations.find(conf => conf.id === ConfigurationType.Exploration);
        const prepaKeyBindingsConf = appSettingsConf?.configurations.find(conf => conf.id === ConfigurationType.Preparation);

        const exploRoles = filteredRoleConfigurations?.filter((conf) => conf.metadata.scope === ConfigurationScope.Exploration);
        const exploRolesSelectedCount = getSelectedItemsCount(selectedList, exploRoles);
        const exploRolesCount = `${exploRolesSelectedCount}/${exploRoles?.length}`;

        const prepaRoles = filteredRoleConfigurations?.filter((conf) => conf.metadata.scope === ConfigurationScope.Preparation);
        const prepaRolesSelectedCount = getSelectedItemsCount(selectedList, prepaRoles);
        const prepaRolesCount = `${prepaRolesSelectedCount}/${prepaRoles?.length}`;

        // Processes
        const processesSelectedCount = getSelectedItemsCount(selectedList, processesConf?.configurations);
        const processesCount = `${processesSelectedCount}/${processesConf?.configurations?.length}`;

        // Composites
        const compositesSelectedCount = getSelectedItemsCount(selectedList, compositesConf?.configurations);
        const compositesCount = `${compositesSelectedCount}/${compositesConf?.configurations?.length}`;

        // Remote component
        const remoteComponentsSelectedCount = getSelectedItemsCount(selectedList, remoteComponentsConf?.configurations);
        const remoteComponentsCount = `${remoteComponentsSelectedCount}/${remoteComponentsConf?.configurations?.length}`;

        // Secrets
        const secretsSelectedCount = getSelectedItemsCount(selectedList, secretsConf?.configurations);
        const secretsCount = `${secretsSelectedCount}/${secretsConf?.configurations?.length}`;

        const briefTemplatesSelectedCount = getSelectedItemsCount(selectedList, briefConf?.configurations);
        const briefTemplatesCount = `${briefTemplatesSelectedCount}/${briefConf?.configurations?.length}`;

        const groupsSelectedCount = getSelectedItemsCount(selectedList, groupsConf?.configurations);
        const groupesCount = `${groupsSelectedCount}/${groupsConf?.configurations?.length}`;

        const usersSelectedCount = getSelectedItemsCount(selectedList, usersConf?.configurations);
        const usersCount = `${usersSelectedCount}/${usersConf?.configurations?.length}`;

        const styleTemplatesSelectedCount = getSelectedItemsCount(selectedList, styleConf?.configurations);
        const styleTemplatesCount = `${styleTemplatesSelectedCount}/${styleConf?.configurations?.length}`;

        const dataExploChildren: TreeData[] = [];

        const universesNodes: { [universeId: string]: { children: TreeData[], name: string } } = {};
        const getUniverseNode = (universeId: string, name: string) => {
            universesNodes[universeId] = universesNodes[universeId] || { children: [], name };

            return universesNodes[universeId];
        };

        ontologyConf?.configurations.forEach((ontology) => {
            // very surprising but ontologies do not reference universe by id (??) hence falling bach to using the metadata.name
            const universeId = ontology.id;
            const universeName = ontology.metadata.name;
            const children = getUniverseNode(universeId, ontology.metadata.name).children;

            dataExploChildren.push({
                key: `${ConfigurationType.Exploration}/Universe/${universeId}`,
                label: universeName,
                children: [
                    {
                        key: `${ConfigurationType.Exploration}/Universe/${universeId}.ontology`,
                        label: intl.formatMessage(messages.ontology),
                        children: [],
                    },
                    {
                        key: `${ConfigurationType.Exploration}/Universe/${universeId}.misc`,
                        label: intl.formatMessage(messages.miscellaneous),
                        children: [],
                    },
                ],
            });

            const styleUniverseIds = styleConf?.configurations.map((conf: Configuration) => conf.metadata.parentId);
            const hasStyleTemplate = styleUniverseIds?.includes(universeId);

            if (styleConf && hasStyleTemplate) {
                children.push({
                    key: `${ConfigurationType.Exploration}/Universe/${universeId}/styles`,
                    label: intl.formatMessage(messages.styleTemplates),
                    children: filteredStyleConfigurations?.map((conf: Configuration) => {
                        return {
                            key: `${ConfigurationType.Exploration}/${ontology.id}/${TreeNodeType.ExplorationStyle}/${conf.id}`,
                            type: TreeNodeType.ExplorationStyle,
                            label: conf.metadata.templateName || conf.metadata.name,
                        };
                    }),
                    count: styleTemplatesCount,
                });
            }
        });


        if (exploRoles && exploRoles.length > 0) {
            dataExploChildren.push({
                key: `${ConfigurationType.Exploration}/${TreeNodeType.ExplorationRole}`,
                label: intl.formatMessage(messages.roles),
                children: exploRoles.map((conf: Configuration) => {
                    return {
                        key: `${ConfigurationType.Exploration}/${TreeNodeType.ExplorationRole}/${conf.id}`,
                        type: TreeNodeType.ExplorationRole,
                        label: conf.metadata.displayName,
                    };
                }),
                count: exploRolesCount,
            });
        }

        if (briefConf) {
            dataExploChildren.push({
                key: `${ConfigurationType.Exploration}/${ConfigurationType.BriefTemplate}`,
                label: intl.formatMessage(messages.briefTemplates),
                children: filteredBriefConfigurations?.map((conf: Configuration) => {
                    return {
                        key: `${ConfigurationType.Exploration}/${TreeNodeType.BriefTemplate}/${conf.id}`,
                        type: TreeNodeType.BriefTemplate,
                        label: conf.metadata.templateName || conf.metadata.name,
                    };
                }),
                count: briefTemplatesCount,
            });
        }

        if (exploKeyBindingsConf) {
            dataExploChildren.push({
                key: `${ConfigurationType.Exploration}/${ConfigurationType.ExplorationKeyBinding}`,
                label: intl.formatMessage(messages.keyBindings),
                children: [],
            });
        }

        const dataPrepaChildren: TreeData[] = [];

        if (prepaKeyBindingsConf) {
            dataPrepaChildren.push({
                key: `${ConfigurationType.Preparation}/${ConfigurationType.PreparationKeyBinding}`,
                label: intl.formatMessage(messages.keyBindings),
                children: [],
            });
        }

        if (prepaRoles && prepaRoles.length > 0) {
            dataPrepaChildren.push(
                {
                    key: `${ConfigurationType.Preparation}/${TreeNodeType.PreparationRole}`,
                    label: intl.formatMessage(messages.roles),
                    children: prepaRoles?.map((conf: Configuration) => {
                        return {
                            key: `${ConfigurationType.Preparation}/${TreeNodeType.PreparationRole}/${conf.id}`,
                            type: TreeNodeType.PreparationRole,
                            label: conf.metadata.displayName,
                        };
                    }),
                    count: prepaRolesCount,
                }
            );
        }

        if (processesConf) {
            dataPrepaChildren.push({
                key: `${ConfigurationType.Preparation}/${ConfigurationType.Processes}`,
                label: intl.formatMessage(messages.processes),
                children: filteredProcessConfigurations?.map((conf: Configuration) => {
                    return {
                        key: `${ConfigurationType.Preparation}/${TreeNodeType.Processes}/${conf.id}`,
                        type: TreeNodeType.Processes,
                        label: conf?.metadata?.templateName || conf?.metadata?.name || conf?.id,
                    };
                }),
                count: processesCount,
            });
        }

        if (compositesConf) {
            dataPrepaChildren.push({
                key: `${ConfigurationType.Preparation}/${ConfigurationType.Composites}`,
                label: intl.formatMessage(messages.composites),
                children: filteredCompositeConfigurations?.map((conf: Configuration) => {
                    return {
                        key: `${ConfigurationType.Preparation}/${TreeNodeType.Composites}/${conf.id}`,
                        type: TreeNodeType.Composites,
                        label: conf?.metadata?.templateName || conf?.metadata?.name || conf?.id,
                    };
                }),
                count: compositesCount,
            });
        }

        if (remoteComponentsConf) {
            dataPrepaChildren.push({
                key: `${ConfigurationType.Preparation}/${ConfigurationType.RemoteComponents}`,
                label: intl.formatMessage(messages.remoteComponents),
                children: filteredRemoteComponentConfigurations?.map((conf: Configuration) => {
                    return {
                        key: `${ConfigurationType.Preparation}/${TreeNodeType.RemoteComponents}/${conf.id}`,
                        type: TreeNodeType.RemoteComponents,
                        label: conf?.metadata?.templateName || conf?.metadata?.name || conf?.id,
                    };
                }),
                count: remoteComponentsCount,
            });
        }

        if (secretsConf) {
            dataPrepaChildren.push({
                key: `${ConfigurationType.Preparation}/${ConfigurationType.Secrets}`,
                label: intl.formatMessage(messages.secrets),
                children: filteredSecretConfigurations?.map((conf: Configuration) => {
                    return {
                        key: `${ConfigurationType.Preparation}/${TreeNodeType.Secrets}/${conf.id}`,
                        type: TreeNodeType.Secrets,
                        label: conf?.metadata?.templateName || conf?.metadata?.name || conf?.id,
                    };
                }),
                count: secretsCount,
            });
        }

        const centralAdminChildren: TreeData[] = [];

        if (usersConf) {
            centralAdminChildren.push(
                {
                    key: `admin/${ConfigurationType.Users}`,
                    label: intl.formatMessage(messages.users),
                    children: filteredUsersConfigurations?.map((conf: Configuration) => {
                        return {
                            key: `admin/${TreeNodeType.User}/${conf.id}`,
                            type: TreeNodeType.User,
                            label: conf.metadata.fullName,
                        };
                    }),
                    count: usersCount,
                }
            );
        }

        if (groupsConf) {
            centralAdminChildren.push(
                {
                    key: `admin/${ConfigurationType.Groups}`,
                    label: intl.formatMessage(messages.groups),
                    children: filteredGroupConfigurations?.map((conf: Configuration) => {
                        return {
                            key: `admin/${TreeNodeType.Group}/${conf.id}`,
                            type: TreeNodeType.Group,
                            label: conf.metadata.name,
                        };
                    }),
                    count: groupesCount,
                }
            );
        }

        const applicationConf = currentConfigurations.find(conf => conf.type === ConfigurationType.ApplicationSettings);
        if (applicationConf) {
            centralAdminChildren.push({
                key: `admin/${ConfigurationType.ApplicationSettings}`,
                label: intl.formatMessage(messages.applicationSettings),
            });
        }

        const adminRoles = filteredRoleConfigurations?.filter((conf) => conf.metadata.scope === 'admin');
        const adminRolesSelectedCount = getSelectedItemsCount(selectedList, adminRoles);
        const adminRolesCount = `${adminRolesSelectedCount}/${adminRoles?.length}`;

        if (adminRoles) {
            centralAdminChildren.push(
                {
                    key: `admin/${ConfigurationType.EnvironmentRole}`,
                    label: intl.formatMessage(messages.roles),
                    children: adminRoles.map((conf: Configuration) => {
                        return {
                            key: `admin/${ConfigurationType.EnvironmentRole}/${conf.id}`,
                            type: TreeNodeType.AdminRole,
                            label: conf.metadata.displayName,
                        };
                    }),
                    count: adminRolesCount,
                }
            );
        }

        // a helper that adds a node for a configuration type that has several child configurations.
        // TODO: use for roles (amdin, dp, explo)
        const addConfigurationTypeWithChildren = (array: TreeData[], configurationType: ConfigurationType, label: string, opts: {
            childrenLabel?: (conf: Configuration) => string,
        } = {}) => {
            const configurationManifest = findConfByType(currentConfigurations, configurationType);
            const childrenLabel = opts.childrenLabel || (conf => conf.metadata.displayName);

            if (configurationManifest) {
                const filteredConfigurations = chain(configurationManifest.configurations)
                    .filter((conf) => {
                        return !(searchedToken && normalizeText(childrenLabel(conf) as string).indexOf(searchedToken) === -1);
                    })
                    .uniqBy((conf) => conf.id)
                    .value();
                const selectedCount = getSelectedItemsCount(selectedList, filteredConfigurations);
                const count = `${selectedCount}/${filteredConfigurations.length}`;

                array.push(
                    {
                        key: `admin/${configurationType}`,
                        label: label,
                        children: filteredConfigurations.map((conf: Configuration) => {
                            return {
                                key: `admin/${configurationType}/${conf.id}`,
                                label: childrenLabel(conf),
                            };
                        }),
                        count,
                    }
                );
            }
        };

        addConfigurationTypeWithChildren(centralAdminChildren, ConfigurationType.ContextualVariable, intl.formatMessage(messages.contextualVariables));

        const userProfilesConf = currentConfigurations.find(conf => conf.type === ConfigurationType.UserProfiles);
        if (userProfilesConf) {
            centralAdminChildren.push(
                {
                    key: `admin/${ConfigurationType.UserProfiles}`,
                    label: intl.formatMessage(messages.userProfilesFieldsBundle),
                }
            );
        }

        const centralAdminNode: TreeData = {
            key: ConfigurationType.Administration,
            label: intl.formatMessage(messages.centralAdministration),
            children: centralAdminChildren,
        };

        const dataExploNode: TreeData = {
            key: ConfigurationType.Exploration,
            label: intl.formatMessage(messages.dataExploration),
            children: dataExploChildren,
        };

        const dataPrepNode: TreeData = {
            key: ConfigurationType.Preparation,
            label: intl.formatMessage(messages.dataPreparation),
            children: dataPrepaChildren,
        };

        const searchedCentralAdminChildren = filterNodeChildrenWithToken(centralAdminNode, searchedToken);

        if (searchedCentralAdminChildren.length > 0) {
            ret.push(centralAdminNode);
        }

        const searchedDataExploChildren = filterNodeChildrenWithToken(dataExploNode, searchedToken);

        if (searchedDataExploChildren.length > 0) {
            ret.push(dataExploNode);
        }

        const searchedDataPrepaChildren = filterNodeChildrenWithToken(dataPrepNode, searchedToken);

        if (searchedDataPrepaChildren.length > 0) {
            ret.push(dataPrepNode);
        }

        return ret;
    }, [
        currentConfigurations,
        filteredRoleConfigurations,
        selectedList,
        intl,
        filteredStyleConfigurations,
        filteredBriefConfigurations,
        filteredProcessConfigurations,
        filteredCompositeConfigurations,
        filteredRemoteComponentConfigurations,
        filteredSecretConfigurations,
        filteredGroupConfigurations,
        searchedToken,
        filterNodeChildrenWithToken,
        filteredUsersConfigurations,
    ]);

    const handleSelection = useCallback(async (selection: ArgNodeKey[]) => {
        const selectionDiff = selection?.filter(item => !openedKeys?.includes(item));
        const openedDiff = openedKeys?.filter(item => !selection?.includes(item));

        if (selectionDiff && selectionDiff.length > 0 && !openedDiff || openedDiff.length === 0) {
            setOpenedKeys([...selection]);
        }

        setSelectedList(selection);

        const newSelectedOptions: ConfigurationOption[] = [
            ...buildNewSelection(currentConfigurations, TemplateType.Brief, selection),
            ...buildNewSelectionForOntologies(currentConfigurations, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.Groups, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.GlobalAdminRole, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.EnvironmentRole, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.PreparationRole, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.ExplorationRole, selection),
            ...buildNewSelection(currentConfigurations, TemplateType.ExplorationStyle, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.ContextualVariable, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.Processes, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.RemoteComponents, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.Composites, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.Secrets, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.Users, selection),
        ];

        if (selection.includes(ConfigurationType.ExplorationKeyBinding)) {
            newSelectedOptions.push(
                {
                    type: ConfigurationType.ApplicationSettings,
                    configurationKeys: [ConfigurationType.ExplorationKeyBinding],
                    options: {
                        applicationId: Environment.appId,
                    },
                }
            );
        }

        if (selection.includes(ConfigurationType.PreparationKeyBinding)) {
            newSelectedOptions.push(
                {
                    type: ConfigurationType.ApplicationSettings,
                    configurationKeys: [ConfigurationType.PreparationKeyBinding],
                    options: {
                        applicationId: Environment.appId,
                    },
                }
            );
        }

        if (selection.includes(ConfigurationType.ApplicationSettings)) {
            newSelectedOptions.push(
                {
                    type: ConfigurationType.ApplicationSettings,
                    configurationKeys: [ConfigurationType.ApplicationSettings],
                    options: {},
                }
            );
        }

        if (selection.includes(ConfigurationType.UserProfiles)) {
            newSelectedOptions.push(
                {
                    type: ConfigurationType.UserProfiles,
                    configurationKeys: [USER_PROFILES_CONF_KEY],
                    options: {},
                }
            );
        }

        onSelectionChange(newSelectedOptions);
    }, [currentConfigurations, onSelectionChange, openedKeys]);

    const handleSearch = useCallback((search: string) => {
        setSearchedToken(normalizeText(search));
    }, []);

    useEffect(() => {
        const briefConf = currentConfigurations.find(conf => conf.type === TemplateType.Brief);
        const filteredBriefConfigurations = briefConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata.templateName as string || conf.metadata.name as string)?.indexOf(searchedToken) === -1);
        });

        const processesConf = currentConfigurations.find(conf => conf.type === ConfigurationType.Processes);
        const filteredProcessConfigurations = processesConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata?.templateName as string || conf.metadata?.name as string || conf?.id)?.indexOf(searchedToken) === -1);
        });

        const compositesConf = currentConfigurations.find(conf => conf.type === ConfigurationType.Composites);
        const filteredCompositeConfigurations = compositesConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata?.templateName as string || conf.metadata?.name as string || conf?.id)?.indexOf(searchedToken) === -1);
        });

        const remoteComponentsConf = currentConfigurations.find(conf => conf.type === ConfigurationType.RemoteComponents);
        const filteredRemoteComponentConfigurations = remoteComponentsConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata?.templateName as string || conf.metadata?.name as string || conf?.id)?.indexOf(searchedToken) === -1);
        });

        const secretsConf = currentConfigurations.find(conf => conf.type === ConfigurationType.Secrets);
        const filteredSecretConfigurations = secretsConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata?.templateName as string || conf.metadata?.name as string || conf?.id)?.indexOf(searchedToken) === -1);
        });

        const groupConf = currentConfigurations.find(conf => conf.type === ConfigurationType.Groups);
        const filteredGroupConfigurations = groupConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata.name as string).indexOf(searchedToken) === -1);
        });

        const usersConf = currentConfigurations.find(conf => conf.type === ConfigurationType.Users);
        const filteredUsersConfigurations = usersConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata.fullName as string).indexOf(searchedToken) === -1);
        });

        const roleConf = currentConfigurations.find(conf => conf.type === ConfigurationType.EnvironmentRole);
        const filteredRoleConfigurations = roleConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata.displayName as string).indexOf(searchedToken) === -1);
        });

        const styleConf = currentConfigurations.find(conf => conf.type === TemplateType.ExplorationStyle);
        const filteredStyleConfigurations = styleConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata.templateName as string || conf.metadata.name as string).indexOf(searchedToken) === -1);
        });

        setFilteredBriefConfigurations(filteredBriefConfigurations);
        setFilteredProcessConfigurations(filteredProcessConfigurations);
        setFilteredCompositeConfigurations(filteredCompositeConfigurations);
        setFilteredRemoteComponentConfigurations(filteredRemoteComponentConfigurations);
        setFilteredSecretConfigurations(filteredSecretConfigurations);
        setFilteredGroupConfigurations(filteredGroupConfigurations);
        setFilteredRoleConfigurations(filteredRoleConfigurations);
        setFilteredStyleConfigurations(filteredStyleConfigurations);
        setFilteredUsersConfigurations(filteredUsersConfigurations);
    }, [searchedToken, currentConfigurations]);

    return (
        <React.Fragment>
            <ArgInputSearch
                onInputChange={handleSearch}
                className={classNames('&-search-bar')}
            />
            <div className={classNames('&-configurations-list-body')}>
                <ArgTree<TreeData>
                    checkable={true}
                    getNodeLabel={handleGetNodeLabel}
                    onOpen={handleOnOpenNodes}
                    getNodeKey={handleGetNodeKey}
                    openedNodes={openedKeys}
                    root={configurationsTree}
                    openAllNodes={!!searchedToken}
                    value={selectedList}
                    hasNodeSearchedToken={(item: ArgNodePath<TreeData>) => {
                        const currentItem = item[item.length - 1];
                        const childNode = filterNodeChildrenWithToken(currentItem, searchedToken);

                        return childNode.length > 0;
                    }}
                    initialValue={defaultSelectedList}
                    getNodeChildren={(item) => {
                        const currentItem = item[item.length - 1];
                        const childNode = filterNodeChildrenWithToken(currentItem, searchedToken);

                        return childNode;
                    }}
                    className={classNames('&-configurations-list-tree')}
                    onChange={async (selection: ArgNodeKey[]) => {
                        await handleSelection(selection);
                    }}
                />
            </div>
        </React.Fragment>
    );
}

function buildNewSelection(configurations: ConfigurationManifest[], type: string, selection: ArgNodeKey[]) {
    const newSelectedOptions: ConfigurationOption[] = [];
    const newSelectedConfs: string[] = [];
    const conf = configurations.find(conf => conf.type === type);

    selection.forEach((key) => {
        const configurationKey = last(key.split('/'));
        if (configurationKey && !newSelectedConfs.includes(configurationKey) && conf?.configurations.find(conf => conf.id === configurationKey)) {
            newSelectedConfs?.push(configurationKey);
        }
    });

    if (newSelectedConfs.length > 0) {
        let options = {};
        if (type === TemplateType.Brief || type === TemplateType.ExplorationStyle) {
            options = {
                setAsDefault: true,
                resetDefaultConfiguration: false,
            };
        }
        newSelectedOptions.push({
            type,
            configurationKeys: newSelectedConfs,
            options,
        });
    }

    return newSelectedOptions;
}

function findConfByType(configurations: ConfigurationManifest[], type: string) {
    return configurations.find(conf => conf.type === type);
}

function getSelectedItemsCount(selectedList: string[] | undefined, configurations: Configuration[] | undefined) {
    if (!configurations || !selectedList) {
        return 0;
    }

    const selectedConfigurations = configurations.filter((conf) => {
        return selectedList?.includes(conf.id);
    });

    return selectedConfigurations.length;
}

function buildNewSelectionForOntologies(configurations: ConfigurationManifest[], selection: ArgNodeKey[]) {
    const newSelectedOptions: ConfigurationOption[] = [];
    const newSelectedConfs: string[] = [];
    const conf = configurations.find(conf => conf.type === ConfigurationType.Ontologies);

    selection.forEach((key) => {
        const confKey = first(key.split('.'));
        const confOptions = last(key.split('.'));

        if (!confKey) {
            return;
        }

        const hasConfKey = conf?.configurations.some(conf => conf.id === confKey);
        const hasConfKeyInSelected = newSelectedConfs.includes(confKey);

        if (hasConfKeyInSelected || !hasConfKey) {
            return;
        }

        newSelectedConfs.push(confKey);
        const withPolicies = !!confOptions && (confOptions === confKey || confOptions === CONFIGURATION_POLICIES_OPTIONS);

        newSelectedOptions.push({
            type: ConfigurationType.Ontologies,
            configurationKeys: [confKey],
            options: {
                withPolicies,
            },
        });
    });

    return newSelectedOptions;
}
