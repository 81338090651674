import { useCallback, useContext, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import {
    ArgButton,
    ArgMenu,
    ArgMenuItem,
    ArgModalContainerContext,
    ClassValue,
    ProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { downloadBlob } from '../../../../utils/file';
import { ExternalComponent } from '../../../models/external-component';
import { ExternalComponentDeletionModal } from '../external-component-deletion-modal/external-component-deletion-modal';
import { SettingsConnector } from '../../../connectors/settings-connector';

import './external-component-actions-button-menu.less';

const CLASSNAME = 'settings-external-component-actions-button-menu';
const messages = defineMessages({
    actions: {
        id: 'settings.external-component-actions-button.menu.actions',
        defaultMessage: 'Actions',
    },
    delete: {
        id: 'settings.external-component-actions-button.menu.delete',
        defaultMessage: 'Delete',
    },
    export: {
        id: 'settings.external-component-actions-button.menu.export',
        defaultMessage: 'Export',
    },
    exportError: {
        id: 'settings.external-component-actions-button.menu.exportError',
        defaultMessage: 'An error occurred while exporting the external component',
    },
    externalComponentExportPrefix: {
        id: 'settings.external-component-actions-button.menu.externalComponentExportPrefix',
        defaultMessage: 'external-component',
    },
});

export interface ExternalComponentActionsButtonMenuProps {
    className?: ClassValue;
    selectedExternalComponents: ExternalComponent[];
    onActionSuccess?: () => void;
}

export function ExternalComponentActionsButtonMenu(props: ExternalComponentActionsButtonMenuProps) {
    const { className, selectedExternalComponents, onActionSuccess } = props;
    const classNames = useClassNames(CLASSNAME);
    const notifications = useArgNotifications();

    const intl = useIntl();

    const [visible, setVisible] = useState<boolean>(false);

    const modalContainer = useContext(ArgModalContainerContext);

    const handleDeleteExternalComponent = useCallback(() => {
        modalContainer.open('settings-external-component-delete', <ExternalComponentDeletionModal
            externalComponents={selectedExternalComponents}
            onClose={() => modalContainer.close('settings-external-component-delete')}
            onSuccess={onActionSuccess}
        />);
    }, [modalContainer, onActionSuccess, selectedExternalComponents]);

    const [handleExportExternalComponent] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const externalComponentsKeys = selectedExternalComponents.map((externalComponent) => externalComponent.key);
            const rawExternalComponent: Blob = await SettingsConnector.getInstance().exportExternalComponent(externalComponentsKeys, progressMonitor);

            downloadBlob(`${intl.formatMessage(messages.externalComponentExportPrefix)}-${new Date().getTime()}.zip`, rawExternalComponent);
            onActionSuccess?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }
            notifications.snackError({ message: messages.exportError }, error as Error);
        }
    }, [intl, onActionSuccess, selectedExternalComponents]);

    const actions = useMemo(() => [
        {
            key: 'delete',
            label: messages.delete,
            onClick: handleDeleteExternalComponent,
        },
        {
            key: 'export',
            label: messages.export,
            onClick: handleExportExternalComponent,
        },
    ], [handleDeleteExternalComponent, handleExportExternalComponent]);

    const actionsMenu = (
        <ArgMenu>
            {actions.map((action) => (
                <ArgMenuItem
                    key={action.key}
                    onClick={() => {
                        action.onClick();
                        setVisible(!visible);
                    }}
                >
                    <FormattedMessage {...action.label} />
                </ArgMenuItem>
            ))}
        </ArgMenu>
    );

    return (
        <ArgButton
            size='medium'
            type='secondary'
            right='dropdown'
            label={messages.actions}
            disabled={selectedExternalComponents.length === 0}
            popover={visible && (
                actionsMenu
            )}
            popoverTrigger='click'
            popoverVisible={visible}
            className={classNames('&')}
            data-testid='actions-menu'
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
            popoverClassName={classNames('&-popover')}
        />
    );
}
