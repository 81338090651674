import { ProgressMonitor } from 'src/components/basic';
import { ConnectorRequestInit } from 'src/utils/connector';
import { getSettingsApi } from 'src/utils/connectors/api-url';
import { BaseConnector } from 'src/utils/connectors/base-connector';
import { DetailedWebHook, DetailedWebHookId, DetailedWebHookRequest, WebhookEvent } from '../models/detailed-webhooks';
import { DetailedWebHookDTO } from '../models/dtoApi';
import { mapDetailedWebhook } from './mappers';

export default class WebhookConnector extends BaseConnector {
    private static instance: WebhookConnector;

    static getInstance(): WebhookConnector {
        if (!WebhookConnector.instance) {
            WebhookConnector.instance = new WebhookConnector('webhook', getSettingsApi());
        }

        return WebhookConnector.instance;
    }


    async getWebhooks(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<DetailedWebHook[]> {
        const url = '/webhooks';

        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const response = await this.request(url, options, progressMonitor);

        return response.webHooks.map((rawWebhook: DetailedWebHookDTO) => mapDetailedWebhook(rawWebhook));
    }

    async putWebhook(
        id: DetailedWebHookId,
        webhook: DetailedWebHookRequest,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/webhooks/${encodeURIComponent(id)}`;

        const options = {
            method: 'PUT',
            json: webhook,
        };

        await this.request(url, options, progressMonitor);
    }

    async createWebhook(
        webhook: DetailedWebHookRequest,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/webhooks';

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: webhook,
            verifyJSONResponse: true,
        };

        await this.request(url, options, progressMonitor);
    }

    async getWebhookEvents(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<WebhookEvent[]> {
        const url = '/webhook-events';

        const result = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const ret = result.webHookEvents.map((webhookEvent: WebhookEvent) => {
            return {
                id: webhookEvent,
                isEnabled: webhookEvent.isEnabled,
            };
        });

        return ret;
    }

    async deleteWebhook(
        webhookId: DetailedWebHookId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/webhooks/${encodeURIComponent(webhookId)}`;

        const options: ConnectorRequestInit = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    async deleteWebhooks(
        webhookIds: DetailedWebHookId[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/webhooks';

        const options: ConnectorRequestInit = {
            method: 'DELETE',
            json: {
                webHookIds: webhookIds,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async toggleWebhooks(
        webhookIds: DetailedWebHookId[],
        isEnabled: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/webhooks/toggle';

        const options: ConnectorRequestInit = {
            method: 'PATCH',
            json: {
                webHookIds: webhookIds,
                state: isEnabled,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async exportWebhook(
        webhookId: DetailedWebHookId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<DetailedWebHookDTO> {
        const url = `/webhooks/${encodeURIComponent(webhookId)}/export`;

        const options: ConnectorRequestInit = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }

    async importWebhook(
        webhook: DetailedWebHookDTO,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<DetailedWebHookDTO> {
        const url = '/webhooks/import';

        const options: ConnectorRequestInit = {
            method: 'POST',
            verifyJSONResponse: true,
            json: webhook,
        };

        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }
}
