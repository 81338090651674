import { useCallback, useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';

import {
    GLOBAL_PM,
    normalizeText,
    ProgressMonitor,
    SubProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useMemoAsync,
} from '../../../../../components/basic';
import {
    ExplorationStyleTemplate,
    ExplorationStyleTemplateContent,
    TemplateType,
} from '../../../../../exploration/model/template';
import {
    ExplorationTemplateConnector,
} from '../../../../../exploration/utils/connector/exploration-template-connector';
import { UniverseId } from '../../../../../exploration/model/universe';
import { CreationTemplate, TemplateExported } from '../../../../../model/template';
import { dateSorter } from '../../../../../utils/sorter';
import { mapExplorationStyleTemplateExported } from '../../../../../exploration/utils/connector/mappers';


interface UseExplorationStylesTemplatesReturn {
    explorationStylesTemplates: ExplorationStyleTemplate[];
    createExplorationStylesTemplates: (files: File[]) => Promise<void>;
    createExplorationStylesTemplateError?: Error;
    onDeleteTemplateConfirm: (progressMonitor: ProgressMonitor, template: ExplorationStyleTemplate) => Promise<void>;
    onRenameTemplateConfirm: (progressMonitor: ProgressMonitor, template: ExplorationStyleTemplate, newName: string) => Promise<void>;
    refreshExplorationStylesTemplates: () => void;
}

const messages = defineMessages({
    fetchingStylesTemplates: {
        id: 'settings.library-configuration.hooks.useStylesTemplates.fetchingTemplates',
        defaultMessage: 'Fetching styles templates...',
    },
    creatingStylesTemplates: {
        id: 'settings.library-configuration.hooks.useStylesTemplates.creatingTemplates',
        defaultMessage: 'Creating styles templates...',
    },
    fetchingStylesTemplatesError: {
        id: 'settings.library-configuration.hooks.useStylesTemplates.fetchingTemplateError',
        defaultMessage: 'Error while fetching templates',
    },
    deleteTemplateError: {
        id: 'settings.library-configuration.hooks.useStylesTemplates.deleteTemplateError',
        defaultMessage: 'Error while deleting template',
    },
    renameTemplateError: {
        id: 'settings.library-configuration.hooks.useStylesTemplates.renameTemplateError',
        defaultMessage: 'Error while renaming template',
    },
});

export const useExplorationStylesTemplates = (universeId?: UniverseId, onCreateSucceed?: () => void, searchedToken?: string) => {
    const notifications = useArgNotifications();

    const [stylesTemplatesStateId, setStylesTemplatesStateId] = useState<number>(0);
    const [stylesTemplates, setStylesTemplates] = useState<ExplorationStyleTemplate[]>([]);

    const [memoStylesTemplates] = useMemoAsync(async (progressMonitor) => {
        try {
            const _stylesTemplates: ExplorationStyleTemplate[] = await ExplorationTemplateConnector.getInstance()
                .listPublicTemplates<ExplorationStyleTemplateContent>(TemplateType.ExplorationStyle, universeId, progressMonitor);

            return [..._stylesTemplates].sort((a, b) => -dateSorter<ExplorationStyleTemplate>(a, b, (item) => item.createdDate));
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }
            notifications.snackError({ message: messages.fetchingStylesTemplatesError }, error as Error);
            throw error;
        }
    }, [universeId, stylesTemplatesStateId], messages.fetchingStylesTemplates, 1);

    useEffect(() => {
        if (!searchedToken) {
            setStylesTemplates(memoStylesTemplates || []);
        } else {
            const normalizedToken = normalizeText(searchedToken);
            setStylesTemplates(memoStylesTemplates?.filter(memoStyleTemplate => {
                return normalizeText(memoStyleTemplate.name).indexOf(normalizedToken) > -1;
            }) || []);
        }
    }, [memoStylesTemplates, searchedToken]);

    const refreshExplorationStylesTemplates = useCallback(() => {
        setStylesTemplatesStateId(prev => prev + 1);
    }, []);

    const [createStylesTemplates, , createStylesTemplateError] = useCallbackAsync(async (progressMonitor, files: File[]) => {
        try {
            const fileContents = await Promise.all(files.map(file => (file as Blob).text()));
            const exportedStyleTemplates = fileContents.map(fileContent => JSON.parse(fileContent) as TemplateExported);
            const promises = exportedStyleTemplates.map(exportedStylesTemplate => {
                const p1 = new SubProgressMonitor(progressMonitor, 1);

                return ExplorationTemplateConnector.getInstance().createTemplate<ExplorationStyleTemplateContent>(
                    mapStyleTemplateToCreationExplorationStyleTemplate(exportedStylesTemplate, universeId), true, p1);
            });

            await Promise.all(promises);
            onCreateSucceed?.();
            refreshExplorationStylesTemplates();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }
            throw error;
        }
    }, [onCreateSucceed, refreshExplorationStylesTemplates, universeId], messages.creatingStylesTemplates, 1, GLOBAL_PM);

    const onDeleteTemplateConfirm = useCallback(async (progressMonitor: ProgressMonitor, template: ExplorationStyleTemplate) => {
        try {
            await ExplorationTemplateConnector.getInstance().deleteTemplate(template.id, progressMonitor);
            refreshExplorationStylesTemplates();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }
            notifications.snackError({ message: messages.deleteTemplateError }, error as Error);
            throw error;
        }
    }, [refreshExplorationStylesTemplates]);

    const onRenameTemplateConfirm = useCallback(async (progressMonitor: ProgressMonitor, template: ExplorationStyleTemplate, newName: string) => {
        try {
            await ExplorationTemplateConnector.getInstance().patchTemplate(template.id, [{
                path: '/name',
                value: newName,
                op: 'replace',
            }], undefined, progressMonitor);
            refreshExplorationStylesTemplates();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }
            notifications.snackError({ message: messages.renameTemplateError }, error as Error);
            throw error;
        }
    }, [refreshExplorationStylesTemplates]);

    const ret: UseExplorationStylesTemplatesReturn = {
        explorationStylesTemplates: stylesTemplates,
        createExplorationStylesTemplates: createStylesTemplates,
        createExplorationStylesTemplateError: createStylesTemplateError,
        onDeleteTemplateConfirm: onDeleteTemplateConfirm,
        onRenameTemplateConfirm: onRenameTemplateConfirm,
        refreshExplorationStylesTemplates: refreshExplorationStylesTemplates,
    };

    return ret;
};

function mapStyleTemplateToCreationExplorationStyleTemplate(exportedStyleTemplate: TemplateExported, universeId?: UniverseId) {
    const styleTemplate = mapExplorationStyleTemplateExported(exportedStyleTemplate, true);
    const template: CreationTemplate<ExplorationStyleTemplateContent> = {
        name: styleTemplate.name,
        content: {
            vertexStyles: styleTemplate.vertexStyles,
            edgeStyles: styleTemplate.edgeStyles,
        },
        type: TemplateType.ExplorationStyle,
        parentId: universeId,
    };

    return template;
}
