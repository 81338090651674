import { isNil } from 'lodash';
import { IntlShape, MessageDescriptor } from 'react-intl';

import { ClusterInfoTitle, FilterValueType, PropertyValueRange, PropertyValueRangeType } from '../model/cluster';

//
export function getClusterLabel(title: ClusterInfoTitle | undefined, intl: IntlShape, undefinedLabel: MessageDescriptor, unknownLabel: MessageDescriptor) {
    const propvertyValue = title?.value;
    if (!isNil(propvertyValue)) {
        const valueType = getPropertyValueType(propvertyValue);
        switch (valueType) {
            case FilterValueType.Range: {
                const rangeValue = propvertyValue as PropertyValueRange;

                return getRangeLabel(rangeValue);
            }
        }

        return propvertyValue.toString();
    }

    return intl.formatMessage(undefinedLabel);
}

function getRangeLabel(rangeValue: PropertyValueRange) {
    const leftValue = rangeValue.left.toString() as string;
    const rightValue = rangeValue.right.toString() as string;
    let leftInterval = '';
    let rightInterval = '';

    switch (rangeValue.type) {
        case PropertyValueRangeType.Open:
            leftInterval = ']';
            rightInterval = '[';
            break;
        case PropertyValueRangeType.LeftOpen:
            leftInterval = ']';
            rightInterval = ']';
            break;
        case PropertyValueRangeType.RightOpen:
            leftInterval = '[';
            rightInterval = '[';
            break;
        case PropertyValueRangeType.Closed:
            leftInterval = '[';
            rightInterval = ']';
            break;
        default:
            break;
    }

    return `${leftInterval}${leftValue} - ${rightValue}${rightInterval}`;
}

function getPropertyValueType(propertyValue: any) {
    let ret = FilterValueType.Value;

    if ((propertyValue as PropertyValueRange).type) {
        ret = FilterValueType.Range;
    }

    return ret;
}
