import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import RealTimeCollaborativeComments
    from '@ckeditor/ckeditor5-real-time-collaboration/src/realtimecollaborativecomments';
import RealTimeCollaborativeTrackChanges
    from '@ckeditor/ckeditor5-real-time-collaboration/src/realtimecollaborativetrackchanges';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js';
import Comments from '@ckeditor/ckeditor5-comments/src/comments';
import TrackChanges from '@ckeditor/ckeditor5-track-changes/src/trackchanges';
import { EditorConfig } from '@ckeditor/ckeditor5-core/src/editor/editorconfig';
import ImportWord from '@ckeditor/ckeditor5-import-word/src/importword';

import { VertexEditing } from './ckeditor-plugins/vertex-plugin/vertex-editing';
import { ReactGlueMentions } from './ckeditor-plugins/vertex-mention-plugin/react-glue-mentions';
import { CreateObject } from './ckeditor-plugins/create-object-plugin/create-object-plugin';
import Exploration2ResizeButtons from './ckeditor-plugins/exploration2-plugin/resize/exploration2-resizebuttons';
import Exploration2ResizeEditing from './ckeditor-plugins/exploration2-plugin/resize/exploration2-resizeediting';
import Exploration2Toolbar from './ckeditor-plugins/exploration2-plugin/exploration2-toolbar';
import Exploration2StyleUi from './ckeditor-plugins/exploration2-plugin/style/exploration2-style-ui';
import { ExplorationEditing } from './ckeditor-plugins/exploration2-plugin/exploration-editing';
import Exploration2ViewMode from './ckeditor-plugins/exploration2-plugin/viewmode/exploration2-viewmode';
import Exploration2ModeUi from './ckeditor-plugins/exploration2-plugin/mode/exploration2-mode-ui';
import Exploration2Mode from './ckeditor-plugins/exploration2-plugin/mode/exploration2-mode';
import Exploration2ViewModeUi from './ckeditor-plugins/exploration2-plugin/viewmode/exploration2-viewmodeUi';
import Exploration2Resize from './ckeditor-plugins/exploration2-plugin/resize/exploration2-resize';
import Exploration2Style from './ckeditor-plugins/exploration2-plugin/style/exploration2-style';
import { ScreenshotEditing } from './ckeditor-plugins/screenshot-plugin/screenshot-editing';
import ScreenshotResizeButtons from './ckeditor-plugins/screenshot-plugin/resize/screenshot-resizebuttons';
import ScreenshotResizeUi from './ckeditor-plugins/screenshot-plugin/resize/screenshot-resizeui';
import ScreenshotResizeHandles from './ckeditor-plugins/screenshot-plugin/resize/screenshot-resizehandles';
import ScreenshotToolbar from './ckeditor-plugins/screenshot-plugin/screenshot-toolbar';
import ScreenshotStyleUi from './ckeditor-plugins/screenshot-plugin/style/screenshot-style-ui';
import ScreenshotResize from './ckeditor-plugins/screenshot-plugin/resize/screenshot-resize';
import ScreenshotStyle from './ckeditor-plugins/screenshot-plugin/style/screenshot-style';
import { DashboardEditing } from './ckeditor-plugins/dashboard-plugin/dashboard-editing';
import DashboardResizeButtons from './ckeditor-plugins/dashboard-plugin/resize/dashboard-resizebuttons';
import DashboardResizeUi from './ckeditor-plugins/dashboard-plugin/resize/dashboard-resizeui';
import DashboardResizeHandles from './ckeditor-plugins/dashboard-plugin/resize/dashboard-resizehandles';
import DashboardToolbar from './ckeditor-plugins/dashboard-plugin/dashboard-toolbar';
import DashboardStyleUi from './ckeditor-plugins/dashboard-plugin/style/dashboard-style-ui';
import DashboardResize from './ckeditor-plugins/dashboard-plugin/resize/dashboard-resize';
import DashboardStyle from './ckeditor-plugins/dashboard-plugin/style/dashboard-style';

const MENTION_MARKER = '#';

export function getGlobalEditorConfig(): EditorConfig {
    const ret: any = {
        plugins: [
            Essentials,
            UploadAdapter,
            Autoformat,
            Bold,
            Italic,
            BlockQuote,
            CKFinder,
            CloudServices,
            EasyImage,
            Heading,
            Image,
            ImageCaption,
            ImageStyle,
            ImageToolbar,
            ImageUpload,
            Indent,
            Link,
            List,
            MediaEmbed,
            Paragraph,
            PasteFromOffice,
            Table,
            TableToolbar,
            TextTransformation,
            Alignment,
            FontFamily,
            FontSize,
            Highlight,
            ImageResize,
            RealTimeCollaborativeComments,
            RealTimeCollaborativeTrackChanges,
            RemoveFormat,
            Strikethrough,
            Underline,
            FontBackgroundColor,
            FontColor,
            ImportWord,
            //
            ReactGlueMentions,
            VertexEditing,
            ExplorationEditing,
            ScreenshotEditing,
            DashboardEditing,
            CreateObject,
            //
            ScreenshotResize,
            ScreenshotResizeUi,
            ScreenshotResizeButtons,
            ScreenshotResizeHandles,
            ScreenshotToolbar,
            ScreenshotStyle,
            ScreenshotStyleUi,
            //
            DashboardResize,
            DashboardResizeUi,
            DashboardResizeButtons,
            DashboardResizeHandles,
            DashboardToolbar,
            DashboardStyle,
            DashboardStyleUi,
            //
            Exploration2Resize,
            Exploration2ResizeButtons,
            Exploration2ResizeEditing,
            Exploration2Toolbar,
            Exploration2Style,
            Exploration2StyleUi,
            Exploration2ViewMode,
            Exploration2ViewModeUi,
            Exploration2Mode,
            Exploration2ModeUi,
            Comments,
            TrackChanges,
        ],
        /*paginationXX: {
            // Page width and height correspond to A4 format
            pageWidth: '21cm',
            pageHeight: '29.7cm',

            pageMargins: {
                top: '20mm',
                bottom: '20mm',
                right: '12mm',
                left: '12mm',
            },
        },*/
        /*
        htmlSupport: {
            allow: [
                {
                    name: /.* /,
                    attributes: true,
                    classes: true,
                    styles: true,
                },
            ],
        },
*/
        // Configuration of the mention feature
        mention: {
            feeds: [
                {
                    marker: MENTION_MARKER,
                    minimumCharacters: 3,
                    feed: [],
                },
            ],
        },
        toolbar: [
            'undo',
            'redo',
            //            'exportPdf',
            '|',
            'heading',
            '|',
            'fontsize',
            'fontfamily',
            'fontColor',
            'fontBackgroundColor',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'highlight',
            '|',
            'alignment',
            '|',
            'numberedList',
            'bulletedList',
            '|',
            'ImportWord',
            /*            '|',
            'pageNavigation',
            'previousPage',
            'nextPage',
            'pageBreak',
 */
            '|',
            'createObject',
            '|',
            'link',
            'blockquote',
            'imageUpload',
            'insertTable',
            'mediaEmbed',
            '|',
            'comment',
            '|',
            'trackChanges',
        ],
        importWord: {
            tokenUrl: false,
            defaultStyles: true,
        },
        image: {
            styles: {
                options: ['alignLeft', 'alignCenter', 'alignRight'],
            },
            resizeOptions: [
                {
                    name: 'resizeImage:original',
                    label: 'Original',
                    value: null,
                },
                {
                    name: 'resizeImage:50',
                    label: '50%',
                    value: '50',
                },
                {
                    name: 'resizeImage:25',
                    label: '25%',
                    value: '25',
                },
                {
                    name: 'resizeImage:10',
                    label: '10%',
                    value: '10',
                },
            ],
            toolbar: [
                'imageStyle:alignLeft',
                'imageStyle:alignCenter',
                'imageStyle:alignRight',
                '|',
                'resizeImage',
                '|',
                'imageTextAlternative',
                '|',
                'comment',
            ],
        },
        exploration2: {
            styles: ['alignLeft', 'alignCenter', 'alignRight'],
            modes: ['chart', 'map', 'heatmap'],
            resizeOptions: [
                {
                    name: 'resizeExploration2:original',
                    label: '100%',
                    value: null,
                },
                {
                    name: 'resizeExploration2:50',
                    label: '50%',
                    value: '50',
                },
                {
                    name: 'resizeExploration2:25',
                    label: '25%',
                    value: '25',
                },
                {
                    name: 'resizeExploration2:10',
                    label: '10%',
                    value: '10',
                },
            ],
            toolbar: [
                'exploration2Mode:chart',
                'exploration2Mode:map',
                'exploration2Mode:heatmap',
                '|',
                'exploration2Style:alignLeft',
                'exploration2Style:alignCenter',
                'exploration2Style:alignRight',
                '|',
                'resizeExploration2',
                '|',
                'comment',
            ],
        },
        screenshot: {
            styles: ['alignLeft', 'alignCenter', 'alignRight'],
            resizeOptions: [
                {
                    name: 'resizeScreenshot:original',
                    label: 'Original',
                    value: null,
                },
                {
                    name: 'resizeScreenshot:50',
                    label: '50%',
                    value: '50',
                },
                {
                    name: 'resizeScreenshot:25',
                    label: '25%',
                    value: '25',
                },
                {
                    name: 'resizeScreenshot:10',
                    label: '10%',
                    value: '10',
                },
            ],
            toolbar: [
                'screenshotStyle:alignLeft',
                'screenshotStyle:alignCenter',
                'screenshotStyle:alignRight',
                '|',
                'resizeScreenshot',
                '|',
                'comment',
            ],
        },
        dashboard: {
            styles: ['alignLeft', 'alignCenter', 'alignRight'],
            resizeOptions: [
                {
                    name: 'resizeDashboard:original',
                    label: 'Original',
                    value: null,
                },
                {
                    name: 'resizeDashboard:50',
                    label: '50%',
                    value: '50',
                },
                {
                    name: 'resizeDashboard:25',
                    label: '25%',
                    value: '25',
                },
                {
                    name: 'resizeDashboard:10',
                    label: '10%',
                    value: '10',
                },
            ],
            toolbar: [
                'dashboardStyle:alignLeft',
                'dashboardStyle:alignCenter',
                'dashboardStyle:alignRight',
                '|',
                'resizeDashboard',
                '|',
                'comment',
            ],
        },
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
            tableToolbar: ['comment'],
        },
        mediaEmbed: {
            toolbar: ['comment'],
        },
    };

    return ret as EditorConfig;
}
