import React from 'react';

import {
    ArgBadge,
    ArgBadgeDeltaPosition,
    ArgBadgeLiteralPosition,
    ArgSize,
    ClassValue,
    useClassNames,
} from 'src/components/basic';
import { DEFAULT_FLAGGED_OBJECT_BADGE_STYLE } from 'src/exploration/constants/default-vertex-style';

//import './vertex-badge.less';

const DEFAULT_POSITION: ArgBadgeDeltaPosition = {
    // deltaX: 3,
    // deltaY: 3,
    literalPosition: 'topRight',
};

interface VertexBadgeProps {
    className?: ClassValue;

    name?: string;
    color?: string;
    backgroundColor?: string;

    position?: ArgBadgeLiteralPosition;

    size?: ArgSize;
}


export function VertexBadge(props: VertexBadgeProps) {
    const {
        className,
        name = DEFAULT_FLAGGED_OBJECT_BADGE_STYLE.icon,
        color = DEFAULT_FLAGGED_OBJECT_BADGE_STYLE.fontColor,
        backgroundColor = DEFAULT_FLAGGED_OBJECT_BADGE_STYLE.backGroundColor,
        position,
        size = 'large',
    } = props;

    const classNames = useClassNames('vertex-badge');

    const _position: ArgBadgeDeltaPosition = {
        ...DEFAULT_POSITION,
        literalPosition: position || DEFAULT_POSITION.literalPosition,
    };

    return <ArgBadge className={classNames('&', className, 'no-overflow')}
                     size={size}
                     position={_position}
                     icon={name}
                     style={{
                         backgroundColor,
                         color,
                     }}
    />;
}

