import React from 'react';
import classNames from 'classnames';
import { IntlShape } from 'react-intl';

import { EditorProps } from '../controls/controls-type';
import { ArgInputNumber, ClassValue } from '../../basic';
import { usePropertyPicker } from '../controls/use-property-picker';

interface InputNumberEditorProps extends EditorProps<any> {
    className?: ClassValue;
}

export function InputNumberEditor(props: InputNumberEditorProps) {
    const {
        className,
        readOnly,
        value,
        onChange,
        autoFocus,
        state,
        propertyDisplayName,
        size = 'medium',
        placeholder,
        clearable,
        pickerOptions,
        popoverProps,
    } = props;

    const pickerOptionsWithItemKey = {
        getItemKey: (number: number) => number.toString(),
        ...pickerOptions,
    };
    const popoverPickerProps = usePropertyPicker(pickerOptionsWithItemKey, onChange);

    return (
        <ArgInputNumber
            readOnly={readOnly}
            className={classNames(className)}
            value={value}
            placeholder={placeholder || propertyDisplayName}
            type='ghost'
            autoFocus={autoFocus}
            clearable={!readOnly && clearable}
            onChange={onChange}
            state={state}
            size={size}
            {...popoverPickerProps}
            {...popoverProps}
        />
    );
}

export function NumberToText(value: any, intl: IntlShape) {
    if (typeof value !== 'number') {
        return undefined;
    }

    return intl.formatNumber(value);
}

