import { Plugin } from '@ckeditor/ckeditor5-core';
import { ExplorationEditing } from '../exploration-editing';

export default class Exploration2Mode extends Plugin {
    /**
     * @inheritDoc
     */
    static get requires() {
        return [ExplorationEditing];
    }

    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'argonos-exploration:mode';
    }

    constructor(props) {
        super(props);
        this._onKeypress = this._onKeypress.bind(this);
    }

    /**
     * @inheritDoc
     */
    init() {
        this._registerSchema();
        this._registerConverters();
        this._addCustomSpaceEvent();
    }

    destroy() {
        this.__removeCustomSpaceEvent();
    }

    _registerSchema() {
        this.editor.model.schema.extend('argonos-exploration', { allowAttributes: ['mode'] });
    }

    _registerConverters() {
        const editor = this.editor;

        // Dedicated converter to propagate image's attribute to the img tag.
        editor.conversion.for('downcast').add(dispatcher => {
            dispatcher.on('attribute:mode', (evt, data, conversionApi) => {
                if (!conversionApi.consumable.consume(data.item, evt.name)) {
                    return;
                }

                const viewWriter = conversionApi.writer;
                const figure = conversionApi.mapper.toViewElement(data.item);

                viewWriter.setAttribute('data-mode', data.attributeNewValue, figure);
            });
        });

        editor.conversion.for('upcast')
            .attributeToAttribute({
                view: {
                    name: 'div',
                    attributes: {
                        'data-mode': /.+/,
                    },
                },
                model: {
                    key: 'mode',
                    value: viewElement => viewElement.getAttribute('data-mode'),
                },
            });
    }

    // Custom event handler to allow navigation with space key in exploration
    _addCustomSpaceEvent() {
        this.editor.on('ready', () => {
            const rootDom = Array.from(this.editor.editing.view.domRoots.values())[0];
            if (!rootDom) {
                return;
            }

            rootDom.addEventListener('keypress', this._onKeypress);
        });
    }

    _removeCustomSpaceEvent() {
        const rootDom = Array.from(this.editor.editing.view.domRoots.values())[0];
        if (!rootDom) {
            return;
        }

        rootDom.removeEventListener('keypress', this._onKeypress);
    }

    _onKeypress(event) {
        if (event.code !== 'Space') {
            return;
        }

        const selectedElement = this.editor.model.document.selection?.getSelectedElement();

        if (selectedElement && selectedElement.is('element', 'argonos-exploration')) {
            event.stopPropagation();
            event.preventDefault();
        }
    }
}
