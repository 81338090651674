import { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { isEmpty } from 'lodash';

import {
    ArgModal,
    ClassValue,
    ProgressMonitor,
    SubProgressMonitor,
    Tool,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
    useMemoAsync,
} from 'src/components/basic';
import { ConfigurationManifest, ConfigurationOption } from '../../../../model/configuration';
import {
    ApplicationsParametersConfiguration,
} from '../../../../components/common/applications/applications-parameters-configuration';
import { downloadBlob } from '../../../../utils/file';
import configurationConnector from 'src/utils/connectors/configuration-connector';
import { LoadingPane } from '../../../../components/common/panes/loading-pane';
import { ConfigurationsScope } from '../../configuration-type';
import { analyseConfigurationExport } from '../../../../utils/configuration-validator';
import {
    ConfigurationErrorNotificationDescription,
} from '../configuration-error-notification-description/configuration-error-notification-description';
import { registerToolItem } from '../../../../components/basic/arg-toolbar/tools-registry';
import { SETTINGS_TOOLBAR_CONTEXT_NAME } from '../../../envrionment-contexts/context-names';
import { SettingsEnvironmentContext } from '../../../envrionment-contexts/settings-environment-context';

import './global-export-modal.less';


const FORCE_LOADING = false;

const CLASSNAME = 'settings-global-export-modal';

const CONFIGURATION_SCOPE: ConfigurationsScope = 'settings';

const messages = defineMessages({
    exportError: {
        id: 'settings.global-export-modal.exportError',
        defaultMessage: 'An error occurred while getting the configurations',
    },
    exportGlobalPopupTitle: {
        id: 'settings.global-export-modal.exportGlobalPopupTitle',
        defaultMessage: 'Global export',
    },
    export: {
        id: 'settings.global-export-modal.export',
        defaultMessage: 'Export',
    },
    cancel: {
        id: 'settings.global-export-modal.cancel',
        defaultMessage: 'Cancel',
    },
    exportSucceed: {
        id: 'settings.global-export-modal.exportSucceed',
        defaultMessage: 'Configurations have successfully been exported',
    },
    exportInProgress: {
        id: 'settings.global-export-modal.exportInProgress',
        defaultMessage: 'Export in progress',
    },
    configurationsFilename: {
        id: 'settings.global-export-modal.configurationsFilename',
        defaultMessage: 'configurations',
    },
    loadingConfiguration: {
        id: 'settings.global-export-modal.LoadingConfiguration',
        defaultMessage: 'Loading Configurations',
    },
    exportConfigurationError: {
        id: 'settings.global-export-modal.exportConfigurationError',
        defaultMessage: 'Cannot export configurations',
    },
    exportConfigurationDescriptionError: {
        id: 'settings.global-export-modal.exportConfigurationDescriptionError',
        defaultMessage: '{count, plural, =1 {1 error detected } other {{count} errors detected}}',
    },
    globalExport: {
        id: 'settings.home-page.fetch-universe.globalExport',
        defaultMessage: 'Global export',
    },
});

interface GlobalExportModalProps {
    className?: ClassValue;
    onClose: () => void;
}

function GlobalExportModal(props: GlobalExportModalProps) {
    const { className, onClose } = props;
    const classNames = useClassNames(CLASSNAME);

    const intl = useIntl();
    const notifications = useArgNotifications();

    const [selectedOptions, setSelectedOptions] = useState<ConfigurationOption[]>();

    const [currentConfigurations, loadingCurrentConfigurations] = useMemoAsync<ConfigurationManifest[]>(async (progressMonitor: ProgressMonitor): Promise<ConfigurationManifest[]> => {
        try {
            const manifest = await configurationConnector.getConfigurationsExportManifest(CONFIGURATION_SCOPE, progressMonitor);

            return manifest.configurations;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.exportError }, error as Error);
            throw error;
        }
    }, []);

    const [onExport, exportPM] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            if (!selectedOptions) {
                return;
            }

            notifications.snackInfo({ message: messages.exportInProgress });

            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            const ret: Blob = await configurationConnector.exportConfigurations({ options: selectedOptions }, CONFIGURATION_SCOPE, sub1);

            downloadBlob(`${intl.formatMessage(messages.configurationsFilename)}-${new Date().getTime()}.zip`, ret);

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            const result = await analyseConfigurationExport(ret, sub2);

            if (isEmpty(result) || !result.errors) {
                notifications.snackInfo({ message: messages.exportSucceed });
                onClose();

                return;
            }
            const nbExportErrors = result.errors.length;

            notifications.notifError({
                message: messages.exportConfigurationError,
                description: messages.exportConfigurationDescriptionError,
                details: <ConfigurationErrorNotificationDescription errors={result.errors} />,
            }, undefined, { count: nbExportErrors });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.exportError }, error as Error);
            throw error;
        }
    }, [selectedOptions, intl, notifications, onClose]);

    const handleSelection = useCallback((selectedOptions: ConfigurationOption[]) => {
        setSelectedOptions(selectedOptions);
    }, []);

    const isExportButtonDisabled = !selectedOptions || selectedOptions.length === 0;

    const loading = loadingCurrentConfigurations?.isRunning || FORCE_LOADING;

    return (
        <ArgModal
            size='medium'
            title={messages.exportGlobalPopupTitle}
            onClose={onClose}
            okText={messages.export}
            cancelText={messages.cancel}
            okDisabled={isExportButtonDisabled}
            onOk={onExport}
            progressMonitor={exportPM}
            className={classNames('&-modal', className)}
        >
            <div className={classNames('&-body')}>
                {loading &&
                    <div className={classNames('&-loading')}>
                        <LoadingPane
                            className={classNames('&-loading-pane')}
                            message={messages.loadingConfiguration}
                        />
                    </div>
                }
                {currentConfigurations && !loading &&
                    <ApplicationsParametersConfiguration
                        className={classNames('&-body-configuration')}
                        currentConfigurations={currentConfigurations}
                        onSelectionChange={handleSelection}
                    />
                }
            </div>
        </ArgModal>
    );
}

export function setupGlobalExport() {
    registerToolItem(SETTINGS_TOOLBAR_CONTEXT_NAME, {
        path: 'right/global-export',
        order: 150,
        label: messages.globalExport,
        type: 'button',
        icon: 'icon-upload',
        className: `${CLASSNAME}-button-export`,
        visible: (settingsEnvironmentContext: SettingsEnvironmentContext) => {
            return settingsEnvironmentContext.hasAnyPermissions('admin.import.export.settings');
        },
        onClick: (tool: Tool<SettingsEnvironmentContext>, settingsEnvironmentContext: SettingsEnvironmentContext) => {
            settingsEnvironmentContext.modalContext.open('global-export',
                <GlobalExportModal onClose={() => settingsEnvironmentContext.modalContext.close('global-export')} />
            );
        },
    });
}
