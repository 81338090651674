import { LatitudeAndLongitude } from 'src/components/basic';
import { DataType } from 'src/components/common/data-types';
import { VertexPropertiesOrderBy, VertexPropertySorting, VertexSorting, VertexStyle } from './vertex';
import { RuleSet, UserDefinedContent } from 'src/components/common/graph/customisation/graph-style';

export const CASEFLAG_PROPERTYNAME_PREFIX = '_caseflag_';

export enum VertexSystemPropertyName {
    Label = '_label_',
    Title = '_title_',
    BusinessId = '_business_id_',
    ExplorationIdentifier = '_exploration_identifier_',
    ResourceIdentifier = '_resource_identifier_',
    BriefIdentifier = '_brief_identifier_',
    FavoriteImage = '_favorite_image_'
}

export const SystemPropertyTypeNames: Record<string, true> =
    Object.values(VertexSystemPropertyName).reduce((acc: Record<string, true>, value) => {
        acc[value] = true;

        return acc;
    }, {} as Record<string, true>);

export enum SystemVertexTypeName {
    Brief = '_brief_',
    BriefSection = '_brief_section_',
    Exploration = '_exploration_',
    Resource = '_resource_',
    Dashboard = '_dashboard_',
    Chart = '_chart_',
}

export const VERTEX_EDGE_BRIEF_SECTION_PART_OF_TYPE = '__brief_section__part_of_';

export const SystemVertexTypeNames: Record<string, true> =
    Object.values(SystemVertexTypeName).reduce((acc: Record<string, true>, value) => {
        acc[value] = true;

        return acc;
    }, {} as Record<string, true>);

export type UniverseId = string;

export type UniverseVertexTypeName = string;
export type UniverseVertexDisplayName = string;
export type UniverseEdgeTypeName = string;
export type UniverseEdgeDisplayName = string;

// Export both type and values to extend CommonDataTypes enum
export type UniversePropertyTypes = DataType;
export const UniversePropertyTypes = { ...DataType };

export type UniverseLinkedProperties = Record<string, string>;

export type UniversePropertyName = string;

export interface UniversePropertyType {
    name: UniversePropertyName;
    displayName: string;
    type: UniversePropertyTypes;
    count?: number;
    cardinality: 'Single';
    linkedProperties?: UniverseLinkedProperties;
    isContinuous: boolean;
    possibleValues?: Array<any>;
    isTitle?: boolean;
    typeCompatibilityCode?: string;
    isSystem?: boolean;
}

export interface UniverseVertexType {
    name: UniverseVertexTypeName;
    displayName: UniverseVertexDisplayName;
    properties: UniversePropertyType[];
    //count: number;
    defaultGeographyProperty?: string;
    defaultTimelineProperty?: string;
    style?: VertexStyle;
    sorting?: VertexSorting;
}

export interface UniverseEdgeType extends Omit<UniverseVertexType, 'name' | 'displayName'> {
    name: UniverseEdgeTypeName;
    displayName: UniverseEdgeDisplayName;
    fromLabel: UniverseVertexTypeName;
    toLabel: UniverseVertexTypeName;
    multiplicity: string;
}

export interface UniverseSchemaType {
    vertices: UniverseVertexType[];
    edges: UniverseEdgeType[];
    metaProperties?: MetaProperty[];
}

export interface MetaProperty {
    name: string;
    displayName: string;
    type: UniversePropertyTypes;
    possibleValues?: string[];
    typeCompatibilityCode?: string;
}

export interface VertexSettingsSorting {
    orderBy?: VertexPropertiesOrderBy;
    properties?: VertexPropertySorting[];
}

export interface VertexSettingsStyle {
    titleProperty?: string;
    userDefinedContent?: UserDefinedContent;
    ruleSets?: Record<string, RuleSet[]>;
}

export interface VertexSettings {
    mandatoryPropertyNames?: UniversePropertyName[];
    readOnlyPropertyNames?: UniversePropertyName[];
    multivaluedPropertyNames?: UniversePropertyName[];
    sorting?: VertexSettingsSorting;
    style?: VertexSettingsStyle;
}

export interface UniverseHeatmapSettings {
    gradients: string[][];
    radius: number;
    opacity: number;
}

export interface UniverseSettingsType {
    vertices: Record<string, VertexSettings>;
    edges: Record<string, VertexSettings>;
    heatmap?: UniverseHeatmapSettings;
}

export interface UniverseType extends UniverseDescriptor {
    schema: UniverseSchemaType;
    settings: UniverseSettingsType;
}

export interface UniverseDescriptor {
    id: UniverseId;
    name: string;
}

export interface UniverseStatistics {
    objects: {
        name: UniverseVertexTypeName,
        count: number
    }[];
    objectsTotalCount: number;
    relations: {
        name: UniverseEdgeTypeName,
        count: number
    }[];
    relationsTotalCount: number;
}

export type MetaDataPropertyName = string;
export type PropertyValueMetaData = Record<MetaDataPropertyName, any>;
export type PropertyValue = boolean | number | string | LatitudeAndLongitude | Date | null;
export type PropertyType = UniversePropertyTypes;
export type PropertyName = string;

export interface PropertyDetails {
    highlights: string[];
    length: number;
}

export interface PropertyValueInfos {
    value: PropertyValue;
    details?: PropertyDetails;
    type: PropertyType;
    meta: PropertyValueMetaData;
}

export type VertexOrEdgeProperties = Record<PropertyName, PropertyValueInfos[]>;
