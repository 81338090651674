import React, {
    CSSProperties,
    KeyboardEvent,
    MouseEvent,
    MouseEventHandler,
    ReactNode,
    Ref,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { isEmpty, isFunction, isNil } from 'lodash';
import { isElement } from 'react-is';

import { ArgIcon, renderIcon } from '../arg-icon/arg-icon';
import { ThreeDotsLoading } from '../arg-loading/three-dots-loading';
import {
    DEFAULT_BUTTON_TYPE,
    DEFAULT_POPOVER_DELAY,
    DEFAULT_POPOVER_PLACEMENT,
    DEFAULT_SIZE,
    DEFAULT_TOOLTIP_DELAY,
    DEFAULT_TOOLTIP_PLACEMENT,
} from '../defaults';
import {
    ArgButtonFormId,
    ArgButtonHTMLType,
    ArgButtonType,
    ArgRenderedIcon,
    ArgRenderedText,
    ArgRenderFunction,
    FocusableElement,
} from '../types';
import { getDataTestIdFromProps } from '../utils';
import { useClassNames } from '../arg-hooks/use-classNames';
import { KeyBindingDescriptor } from '../keybindings/keybinding';
import { useKeyBinding } from '../keybindings/use-keybinding';
import { KeyBindingTooltipContent } from '../keybindings/keybinding-tooltip-content';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ArgComponentProps } from '../arg-component/arg-component';
import { ArgBadges } from '../arg-badge/arg-badge';
import { getMessageValuesWithFormatters, renderText } from '../utils/message-descriptor-formatters';
import { useIsDragging } from '../utils/use-is-dragging';
import { ArgTooltip2, TooltipPlacement } from '../arg-tooltip/arg-tooltip2';
import { ArgComponentPopoverProps, getPopoverTriggerAsArray, usePopover } from '../arg-popover/use-popover';
import { ArgPopover } from '../arg-popover/arg-popover';
import { useFilterDataProps } from '../utils/use-filter-data-props';
import { isPromise } from '../utils/promise';

import './arg-button.less';

export type ButtonClickEvent = KeyboardEvent | MouseEvent;

const DEFAULT_HTML_TYPE = 'button';

export interface ArgButtonProps extends ArgComponentProps, ArgComponentPopoverProps {
    id?: string;
    htmlTitle?: string;
    label?: ArgRenderedText;
    htmlType?: ArgButtonHTMLType;
    type?: ArgButtonType;
    formId?: ArgButtonFormId;
    icon?: ArgRenderedIcon;
    loading?: boolean;
    progressMonitor?: ProgressMonitor;

    onClick?: (event: ButtonClickEvent) => any | Promise<void>;
    onLongClick?: (event: MouseEvent) => any | Promise<void>;
    onMouseEnter?: MouseEventHandler<HTMLElement>;
    onMouseLeave?: MouseEventHandler<HTMLElement>;

    tabIndex?: number;

    buttonRef?: Ref<FocusableElement | null>;
    autoFocus?: boolean;
    right?: 'dropdown' | ReactNode | ArgRenderFunction;
    left?: ReactNode | ArgRenderFunction;

    /**
     * @deprecated
     */
    style?: CSSProperties;

    keyBinding?: KeyBindingDescriptor;

    // Sometime we need to have a Click on mousedown event (before the blur event of the other input)
    // clickOnMouseDown is incompatible with longClick
    clickOnMouseDown?: boolean;

    onShiftClick?: (event: ButtonClickEvent) => any | Promise<void>;
    onAltClick?: (event: ButtonClickEvent) => any | Promise<void>;
    onCtrlClick?: (event: ButtonClickEvent) => any | Promise<void>;
    shiftKeyBinding?: KeyBindingDescriptor;
    altKeyBinding?: KeyBindingDescriptor;
    ctrlKeyBinding?: KeyBindingDescriptor;

    iconBadges?: ArgBadges;
}

function _ArgButton(props: ArgButtonProps) {
    const {
        id,
        htmlTitle,
        size = DEFAULT_SIZE,
        className,
        type = DEFAULT_BUTTON_TYPE,
        htmlType = DEFAULT_HTML_TYPE,
        formId,
        icon,
        children,
        progressMonitor,
        hidden,
        loading,
        disabled: externalDisabled,
        label,
        messageValues,
        onClick,
        onLongClick,
        onMouseEnter,
        onMouseLeave,
        tooltip,
        tooltipPlacement = DEFAULT_TOOLTIP_PLACEMENT,
        tooltipEnterDelay = DEFAULT_TOOLTIP_DELAY,
        tooltipClassName,
        onTooltipVisibleChange,
        popover,
        popoverPlacement = DEFAULT_POPOVER_PLACEMENT,
        popoverEnterDelay = DEFAULT_POPOVER_DELAY,
        popoverClassName,
        buttonRef,
        autoFocus,
        right,
        left,
        tabIndex,
        style,
        keyBinding,
        clickOnMouseDown,
        onShiftClick,
        onAltClick,
        onCtrlClick,
        shiftKeyBinding,
        ctrlKeyBinding,
        altKeyBinding,
        iconBadges,

        ...otherProps // Keep data-xxx properties
    } = props;

    const unmountedRef = useRef<boolean>();

    const [internalProcessing, setInternalProcessing] = useState<boolean>();

    const hasLabel = (label !== undefined) || children;
    const hasLoading = progressMonitor?.isRunning || loading || internalProcessing;
    const hasIcon = !isNil(icon);
    const disabled = externalDisabled || internalProcessing || hasLoading; // ????

    const classNames = useClassNames('arg-button');
    const dataTestId = getDataTestIdFromProps(props);

    const myButtonRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(buttonRef, () => (myButtonRef?.current as FocusableElement | null));

    const handleKeyBinding = useCallback((event: KeyboardEvent) => {
        if (myButtonRef.current) {
            // TODO longclick + clickOnMouseDown
            myButtonRef.current.click();

            return;
        }
        onClick?.(event);
    }, [onClick]);

    useKeyBinding(keyBinding, handleKeyBinding, !disabled);

    useKeyBinding(shiftKeyBinding, onShiftClick, !disabled);

    useKeyBinding(altKeyBinding, onShiftClick, !disabled);

    useKeyBinding(ctrlKeyBinding, onShiftClick, !disabled);

    const isDragging = useIsDragging();

    useEffect(() => {
        return () => {
            unmountedRef.current = true;
        };
    }, []);

    useLayoutEffect(() => {
        if (!autoFocus) {
            return;
        }
        const timerId = setTimeout(() => {
            myButtonRef.current?.focus();
        }, 200);

        return () => {
            clearTimeout(timerId);
        };
    }, [autoFocus]);

    const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>, forceEvent?: boolean) => {
        if (event.defaultPrevented && !forceEvent) {
            return;
        }

        function processReturn(ret: any) {
            if (!isPromise(ret)) {
                return;
            }

            setInternalProcessing(true);
            ret.catch((error) => {
                console.error('Button Click error', error);
            }).finally(() => {
                if (unmountedRef.current) {
                    return;
                }
                setInternalProcessing(false);
            });
        }

        try {
            if (onAltClick && event.altKey) {
                const ret = onAltClick(event);
                processReturn(ret);

                return;
            }

            if (onCtrlClick && event.ctrlKey) {
                const ret = onCtrlClick(event);
                processReturn(ret);

                return;
            }

            if (onShiftClick && event.shiftKey) {
                const ret = onShiftClick(event);
                processReturn(ret);

                return;
            }

            const ret = onClick?.(event);
            processReturn(ret);
        } finally {
            if (htmlType !== 'submit') {
                event.preventDefault();
            }
        }
    }, [onClick, onShiftClick, onAltClick, onCtrlClick, htmlType]);

    const handleMouseDownLongClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        const button = event.target;

        function removeHandlers() {
            clearTimeout(timeoutId);
            button.removeEventListener('mouseup', mouseUp, true);
            button.removeEventListener('mouseleave', mouseLeave, true);
        }

        const timeoutId = setTimeout(() => {
            if (unmountedRef.current) {
                return;
            }
            removeHandlers();

            function processReturn(ret: any) {
                if (!isPromise(ret)) {
                    return;
                }

                setInternalProcessing(true);
                ret.catch((error) => {
                    console.error('Button Click error', error);
                }).finally(() => {
                    if (unmountedRef.current) {
                        return;
                    }
                    setInternalProcessing(false);
                });
            }

            const ret = onLongClick?.(event);
            processReturn(ret);
        }, 500);

        const mouseUp = () => {
            removeHandlers();

            handleClick(event, true);
        };

        const mouseLeave = () => {
            removeHandlers();
        };

        button.addEventListener('mouseup', mouseUp, true);
        button.addEventListener('mouseleave', mouseLeave, true);
    }, [handleClick, onLongClick]);

    const dataProps = useFilterDataProps(otherProps);

    const popoverTrigger = getPopoverTriggerAsArray(props);
    const {
        hasPopover,
        popoverVisible,
        getPopoverProps,
    } = usePopover({
        ...props,
        popoverClassName: classNames('&-popover', popoverClassName),
    });

    if (label === undefined && icon === undefined && !children && !keyBinding && !loading) {
        throw new Error('Label or icon must be specified');
    }

    if (hidden) {
        return null;
    }

    const cls = {
        'has-label': hasLabel,
        'has-icon': hasIcon,
        'has-loading': hasLoading,
        'disabled': disabled,
        'has-dropdown': right === 'dropdown',
        [`type-${type}`]: true,
        [`size-${size}`]: true,
    };

    let _labelNode = label || children;

    if (_labelNode === undefined && !hasIcon && keyBinding) {
        _labelNode = keyBinding.name;
    }

    const _messageValues = getMessageValuesWithFormatters(messageValues);
    const labelNode: ReactNode = renderText(_labelNode, _messageValues);

    const buttonHandlers: Record<string, any> = {};
    if (onLongClick) {
        if (clickOnMouseDown) {
            throw new Error('onLongClick and clickOnMouseDown can not be enabled in same time');
        }
        buttonHandlers.onMouseDown = handleMouseDownLongClick;
    } else if (onClick || onShiftClick || onAltClick || onCtrlClick) {
        if (clickOnMouseDown) {
            buttonHandlers.onMouseDown = handleClick;
        } else {
            buttonHandlers.onClick = handleClick;
        }
    }

    if (onMouseEnter) {
        buttonHandlers.onMouseEnter = onMouseEnter;
    }

    if (onMouseLeave) {
        buttonHandlers.onMouseLeave = onMouseLeave;
    }

    let _left = left;
    if (isFunction(left)) {
        _left = left();
    }

    let _right = right;
    if (isFunction(right)) {
        _right = right();
    }

    const labelClass = classNames('&-label');

    let button;
    const content =
        <>
            {_left}

            {/* Icon */}
            {hasIcon && !hasLoading && <span key='icon' className={classNames('&-icon-container')}>
                {renderIcon(icon, classNames('&-icon'))}
                {iconBadges}
            </span>}

            {/* Loading depending on progress monitor */}
            {hasLoading &&
                <span
                    key='loading'
                    className={classNames('&-loading-container')}
                    data-testid='arg-button-loading-state'
                >
                    <ThreeDotsLoading className={classNames('&-loading')} />
                </span>}

            {/* Label */}
            {hasLabel && <span key='label' className={labelClass}>
                {labelNode}
            </span>}

            {_right === 'dropdown' && <span key='dropdown' className={classNames('&-dropdown')}>
                <ArgIcon className={classNames('&-dropdown-icon')} name='icon-triangle-down' />
            </span>}
            {isElement(_right) && _right}
        </>;

    button =
        <button
            ref={myButtonRef}
            key='button'
            style={style}
            id={id}
            title={htmlTitle}
            disabled={disabled}
            className={classNames('&', cls, className)}
            {...buttonHandlers}
            tabIndex={tabIndex}
            {...dataProps}
            data-testid={dataTestId}
            type={htmlType}
            form={formId}
        >
            {content}
        </button>;

    const tooltipContent = (tooltip === true) ? (labelNode) : tooltip;
    if (tooltipContent && !isDragging && (!popoverVisible || !hasSameSide(tooltipPlacement, popoverPlacement))) {
        button =
            <ArgTooltip2
                key='tooltip'
                className={classNames('&-tooltip', 'arg-tooltip', tooltipClassName)}
                title={(
                    <KeyBindingTooltipContent
                        tooltip={tooltipContent}
                        messageValues={_messageValues}
                        keyBinding={!disabled ? keyBinding : undefined}
                    />
                )}
                placement={tooltipPlacement}
                onOpenChange={onTooltipVisibleChange}
                ref={myButtonRef}
                mouseEnterDelay={tooltipEnterDelay}
            >
                {button}
            </ArgTooltip2>;
    }

    if (hasPopover && disabled !== true && (!isEmpty(popoverTrigger) || popoverVisible)) {
        button = (
            <ArgPopover {...getPopoverProps()} mouseEnterDelay={popoverEnterDelay}>
                {button}
            </ArgPopover>
        );
    }

    return button;
}

function getVerticalPosition(placement: TooltipPlacement): string | undefined {
    switch (placement) {
        case 'top':
        case 'topLeft':
        case 'topRight':
        case 'leftTop':
        case 'rightTop':
            return 'top';
        case 'bottom':
        case 'bottomLeft':
        case 'bottomRight':
        case 'leftBottom':
        case 'rightBottom':
            return 'top';
        case 'left':
        case 'right':
            return 'center';
    }

    return undefined;
}

function getHorizontalPosition(placement: TooltipPlacement): string | undefined {
    switch (placement) {
        case 'left':
        case 'leftTop':
        case 'leftBottom':
        case 'topLeft':
        case 'bottomLeft':
            return 'left';

        case 'right':
        case 'rightTop':
        case 'rightBottom':
        case 'topRight':
        case 'bottomRight':
            return 'top';

        case 'bottom':
        case 'top':
            return 'center';
    }

    return undefined;
}

function hasSameSide(placement1: TooltipPlacement, placement2: TooltipPlacement) {
    if (getVerticalPosition(placement1) === getVerticalPosition(placement2)) {
        return true;
    }

    if (getHorizontalPosition(placement1) === getHorizontalPosition(placement2)) {
        return true;
    }

    return false;
}

export const ArgButton = _ArgButton; // React.memo(_ArgButton);
