import React from 'react';

import { ArgButton, ArgInputNumber, ArgInputText, useClassNames } from '../../../../basic';
import { AdvancedStyleType, ContinuousValueIntervaleType } from '../graph-style';
import { CommonControlProps, getIntervalType, useGetStyleModifier } from './utils';
import { RangeNumberControlMessages } from '../advanced-body';

import './interval-number-control.less';

export interface IntervalNumberControlProps extends CommonControlProps {
    left?: number;
    right?: number;
    intervalType: ContinuousValueIntervaleType;

    invalidLeft?: boolean;
    invalidRight?: boolean;

    onRangeValueChange?: (
        advancedStyleType: AdvancedStyleType,
        side: 'left' | 'right',
        value: number | null,
        index: number
    ) => Promise<void>;
    onRangeIntervalTypeChange?: (
        advancedStyleType: AdvancedStyleType,
        side: 'left' | 'right',
        index: number
    ) => Promise<void>;

    messages: Pick<RangeNumberControlMessages, 'maxPlaceholder' | 'minPlaceholder' | 'undefinedStyleRulePlaceholder'>;
}
export const IntervalNumberControl = ({
    type,
    index,
    left,
    right,
    intervalType,

    invalidLeft,
    invalidRight,

    fillColor,
    iconColor,
    strokeColor,

    badgeFillColor,
    badgeIconName,
    doesBadgeBlink,

    size,

    iconName,
    lineStyle,

    onStyleChange,
    onRangeValueChange,
    onRangeIntervalTypeChange,
    canBeRemoved,
    onRemoveComponent,
    messages,
    isUndefinedStyleRule,
}: IntervalNumberControlProps) => {
    const classNames = useClassNames('interval-number-control');
    const cls = {
        size: type === 'size',
        undefined: !!isUndefinedStyleRule,
        'line-style': type === 'style',
    };

    const styleModifier = useGetStyleModifier(
        type,
        classNames,
        index,
        fillColor,
        iconColor,
        strokeColor,
        badgeFillColor,
        badgeIconName,
        size,
        iconName,
        onStyleChange,
        doesBadgeBlink,
        lineStyle
    );

    return (
        <div className={classNames('&')}>
            <div className={classNames('&-content', cls)}>
                <span className={classNames('&-content-bullet')} />
                {styleModifier}
                {isUndefinedStyleRule ? (
                    <ArgInputText
                        className={classNames('&-content-input-undefined')}
                        placeholder={messages.undefinedStyleRulePlaceholder}
                        disabled={!!isUndefinedStyleRule}
                    />
                ) : (
                    <>
                        <ArgInputNumber
                            left={
                                <button
                                    className={classNames('&-content-input-lower-button')}
                                    type='button'
                                    tabIndex={-1}
                                    onClick={() => onRangeIntervalTypeChange?.(type, 'left', index)}
                                >
                                    {/* &le; */}
                                    {getIntervalType(intervalType)?.lowerBoundIntervalType}
                                </button>
                            }
                            value={left}
                            clearable={false}
                            placeholder={messages?.minPlaceholder}
                            onChange={(value) => onRangeValueChange?.(type, 'left', value, index)}
                            state={invalidLeft ? 'invalid' : undefined}
                        />
                        <ArgInputNumber
                            left={
                                <button
                                    className={classNames('&-content-input-lower-button')}
                                    type='button'
                                    tabIndex={-1}
                                    onClick={() => onRangeIntervalTypeChange?.(type, 'right', index)}
                                >
                                    {getIntervalType(intervalType)?.upperBoundIntervalType}
                                </button>
                            }
                            value={right}
                            clearable={false}
                            placeholder={messages?.maxPlaceholder}
                            onChange={(value) => onRangeValueChange?.(type, 'right', value, index)}
                            state={invalidRight ? 'invalid' : undefined}
                        />
                    </>
                )}
            </div>

            {canBeRemoved && (
                <ArgButton
                    icon='icon-trash'
                    type='ghost'
                    className={classNames('&-trash-button')}
                    onClick={() => onRemoveComponent?.(type, index)}
                />
            )}
        </div>
    );
};
