import React, { ReactNode, useCallback, useContext, useMemo } from 'react';

import { ClassValue, SelectionProvider, useArgModalContext, useClassNames } from '../../../components/basic';
import { GroupsTable } from '../components/groups-table';
import { GroupsDataProvider } from '../providers/groups-data-provider';
import { UsersAndGroupsStateContext } from 'src/settings/users/providers/usersState';
import { Group } from 'src/model/user';
import { CreateGroupModal } from '../components/create-group-modal';

import './groups-view.less';


export interface GroupsViewProps {
    className?: ClassValue;
    pageTabs: ReactNode;
}

export const GroupsView: React.FunctionComponent<GroupsViewProps> = ({
    className,
    pageTabs,
}) => {
    const classNames = useClassNames('groups-view');
    const { users, groups, allRoles, setGroups } = useContext(UsersAndGroupsStateContext);
    const groupsDataProvider = useMemo(() => new GroupsDataProvider(groups, false), [groups]);
    const groupsSelectionProvider = useMemo(
        () => new SelectionProvider<Group>('settings-user-table', (group) => group.id.toString()),
        []
    );
    const modalContext = useArgModalContext();

    const openAddGroupModal = useCallback(() => {
        modalContext.open('settings-create-group',
            <CreateGroupModal
                closeModal={() => modalContext.close('settings-create-group')}
                users={users}
                userRoles={allRoles}
                groups={groups}
                setGroups={setGroups}
            />
        );
    }, [groups, modalContext, setGroups, allRoles, users]);

    return (
        <>
            <div className={classNames('&', className)}>
                {pageTabs}
                <GroupsTable
                    groups={groupsDataProvider}
                    selectionProvider={groupsSelectionProvider}
                    handleAddGroup={openAddGroupModal}
                />
            </div>
        </>
    );
};
