import React, { useCallback, useRef } from 'react';
import { omit } from 'lodash';
import * as uuid from 'uuid';


interface useScriptSandboxProps {
  onSuccess?: (output: any) => void;
  onError?: (error: string) => void;
  containerRef: React.RefObject<HTMLDivElement>;
}

export function useScriptSandbox(props: useScriptSandboxProps) {
    const {
        onSuccess,
        onError,
        containerRef,
    } = props;

    const iframesRef = useRef<Record<string, HTMLIFrameElement>>({});

    const runScript = useCallback((code: string) => {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.sandbox.add('allow-scripts', 'allow-same-origin', 'allow-popups'); // TODO remove same-origin
        iframe.src = 'about:blank';

        const handleMessage = (event: MessageEvent) => {
            const iframeId = event.data.iframeId;
            if (!iframeId) {
                return;
            }
            if (event.data.error) {
                onError?.(event.data.error);
            }
            if (event.data.output) {
                onSuccess?.(event.data.output);
            }
            window.removeEventListener('message', handleMessage);
            const iframe = iframesRef.current[iframeId];
            if (iframe) {
                containerRef.current?.removeChild(iframe);
                iframesRef.current = omit(iframesRef.current, iframeId);
            }
        };

        // Set up a message listener to receive the output from the iframe and clean it up
        window.addEventListener('message', handleMessage);

        // Create a script element within the iframe to execute the user input
        iframe.onload = () => {
            const script = document.createElement('script');
            const iframeId = uuid.v4();
            const innerHTML = `
                    try {
                        const _onClick = () => {
                          ${code}
                        }
                        const output = _onClick();
                        const iframeId = '${iframeId}';
                        parent.postMessage({ output, iframeId }, '*');
                    } catch (error) {
                        console.error(error);
                        parent.postMessage({ error: error.message, iframeId }, '*');
                    }
                `;
            script.innerHTML = innerHTML;
            iframe.contentWindow?.document.body.appendChild(script);
        };
        containerRef.current?.appendChild(iframe);
        iframesRef.current = {
            ...iframesRef.current,
            [iframe.id]: iframe,
        };
    }, [containerRef, onError, onSuccess]);

    return runScript;
}
