import { cloneDeep, isEmpty, set } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';

import { ArgIcon, useClassNames } from 'src/components/basic';
import { RuleTarget, SchemaObject } from '../../../../models/policy';

import './universe-item-filter-button.less';

const messages = defineMessages({
    property: {
        id: 'settings.rule-target-filter-property-button.add-group',
        defaultMessage: 'Property',
    },
});

interface UniverseItemFilterButtonProps<T> {
    currentPath: string;
    filterObject: SchemaObject;
    onChange: React.Dispatch<React.SetStateAction<T>>;
}

export const UniverseItemFilterButton = <T extends { Targets: RuleTarget[] }>({
    currentPath,
    filterObject,
    onChange,
}: UniverseItemFilterButtonProps<T>) => {
    const intl = useIntl();
    const classNames = useClassNames('settings-universe-item-filter-button');

    return (
        <div
            className={classNames('&')}
            onClick={() => {
                onChange((currentRule) => {
                    if (isEmpty(filterObject)) {
                        filterObject = {
                            _kind: undefined,
                            _type: undefined,
                        };
                    }

                    return set(cloneDeep(currentRule), currentPath, {
                        and: [{ object: filterObject }, { object: {} }],
                    });
                });
            }}
        >
            <ArgIcon name='icon-add-outline' className={classNames('&-icon')} />
            <div>{intl.formatMessage(messages.property)}</div>
        </div>
    );
};
