import React, {
    CSSProperties,
    ImgHTMLAttributes,
    ReactNode,
    SyntheticEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import Debug from 'debug';
import { omit } from 'lodash';


import { getDataTestIdFromProps } from '../utils';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ThreeDotsLoading } from '../arg-loading/three-dots-loading';
import { useImageBlobURLCache } from '../cache-repositories/use-image-cache';
import { ArgResourcesContext } from './arg-resources-context';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { useMemoAsync } from '../arg-hooks/use-memo-async';
import { Connector } from '../../../utils/connector';

import { ReactComponent as Placeholder } from './placeholder.svg';
import './arg-image.less';

const debug = Debug('basic:arg-image');

const DEFAULT_FIT = 'contain';

const FORCE_PLACEHOLDER = false;

export type ArgImageFit = 'contain' | 'cover';

export interface ImageMetadata {
    width?: number;
    height?: number;
}

export interface ArgImageProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'placeholder'> {
    fit?: ArgImageFit;
    keepBlobImage?: boolean;
    disableDrag?: boolean;
    metadata?: ImageMetadata;
    widthForcedByParent?: boolean;
    viewportRatio?: number;
    forceRatio?: boolean;
    placeholder?: boolean;
    errorRender?: () => ReactNode;
}

export function ArgImage(props: ArgImageProps) {
    const {
        className,
        fit = DEFAULT_FIT,
        keepBlobImage,
        disableDrag,
        metadata,
        widthForcedByParent,
        viewportRatio,
        forceRatio,
        placeholder,
        errorRender,
        ...otherProps
    } = props;

    const src = props.src || undefined; // to get rid of `false`
    const isMountedRef = useRef<boolean>(true);

    const [loaded, setLoaded] = useState<boolean>(false);

    const imageContainerRef = useRef<HTMLDivElement>();
    const imageRef = useRef<HTMLImageElement>(null);
    const imageBlobRef = useRef<string>();

    const [imageError, setImageError] = useState<Error>();

    const dataTestId = getDataTestIdFromProps(props);

    const [loadedMetaData, setLoadedMetadata] = useState<ImageMetadata>();

    const [imageStyle, setImageStyle] = useState<CSSProperties>();

    const classNames = useClassNames('arg-image');

    const resourcesContext = useContext(ArgResourcesContext);
    const apiServerUrl = resourcesContext.getServerApi()!;

    const shouldFetch = apiServerUrl && src?.startsWith(apiServerUrl);

    const imageBlobCache = useImageBlobURLCache(shouldFetch ? src : undefined);

    const [cacheBlobURL, cacheError, cacheProgressMonitor] = imageBlobCache ?? [];

    const [url, loadingUrl, urlError] = useMemoAsync<string | null>(async (progressMonitor: ProgressMonitor): Promise<string | null> => {
        if (imageBlobRef.current) {
            URL.revokeObjectURL(imageBlobRef.current);
        }

        setImageError(undefined);
        setLoaded(false);

        if (!src || imageBlobCache) {
            return null;
        }

        // Check if src is a remote api address that should be fetched
        if (!shouldFetch) {
            return src;
        }

        try {
            const response = await Connector.getInstance().request(
                src, {
                    api: apiServerUrl,
                },
                progressMonitor
            );

            const url = URL.createObjectURL(response);
            imageBlobRef.current = url;

            return url;
        } catch (error) {
            imageBlobRef.current = undefined;
            throw error;
        }
    }, [src, imageBlobCache, shouldFetch]);


    const _error = urlError || cacheError || imageError;
    const _progressMonitor = [loadingUrl, cacheProgressMonitor].find((p) => p?.isRunning);

    const ratioStyle = useMemo(() => {
        return viewportRatio && !isNaN(viewportRatio) && viewportRatio > 0
            ? {
                paddingBottom: `${100 / viewportRatio}%`,
                width: 'auto',
            }
            : undefined;
    }, [viewportRatio]);

    const shouldDisplayRatioFiller = ratioStyle !== undefined;


    // Manage the mounting of the component
    useEffect(() => {
        return () => {
            isMountedRef.current = false;

            const blobURL = imageBlobRef.current;
            if (blobURL) {
                imageBlobRef.current = undefined;

                URL.revokeObjectURL(blobURL);
            }
        };
    }, []);

    const containerStyle = useMemo<CSSProperties | undefined>(() => {
        const _metadata = loadedMetaData || metadata;
        if (!_metadata || !forceRatio) {
            return;
        }

        const style: CSSProperties = {};
        style.aspectRatio = `${_metadata.width} / ${_metadata.height}`;

        return style;
    }, [loadedMetaData, metadata, forceRatio]);

    const handleLoaded = useCallback(() => {
        if (!isMountedRef.current) {
            return;
        }

        if (imageBlobRef.current && !keepBlobImage) {
            URL.revokeObjectURL(imageBlobRef.current);
            imageBlobRef.current = undefined;
        }

        setLoaded(true);

        const image = imageRef.current!;

        setLoadedMetadata({
            width: image.naturalWidth,
            height: image.naturalHeight,
        });
    }, [keepBlobImage]);

    const handleError = useCallback((event: SyntheticEvent) => {
        if (!isMountedRef.current) {
            return;
        }

        const error = new Error('Image error');
        (error as any).event = event;

        setImageError(error);
    }, []);

    const updateWidthHeight = useCallback((metadata: ImageMetadata) => {
        if (shouldDisplayRatioFiller) {
            setImageStyle(() => {
                const style: CSSProperties = {
                    objectFit: fit,
                    objectPosition: '50% 50%',
                    width: '100%',
                    height: '100%',
                };

                return style;
            });

            return;
        }
        setImageStyle((prev: CSSProperties | undefined) => {
            const imageContainer = imageContainerRef.current;
            if (!imageContainer) {
                if (!metadata.width || !metadata.height) {
                    return undefined;
                }

                const style: CSSProperties = {};
                //style.aspectRatio = `${metadata.width} / ${metadata.height}`;

                return style;
            }

            // can not use getBoundingClientRect because of transform: scale() of CKEditor
            //const bounding = imageContainer.getBoundingClientRect();
            const boundingWidth = imageContainer.offsetWidth;
            const boundingHeight = imageContainer.offsetHeight;

            if (!boundingWidth || !boundingHeight || !metadata.width || !metadata.height) {
                if (!metadata.width || !metadata.height) {
                    return undefined;
                }

                const style: CSSProperties = {};
                //style.aspectRatio = `${metadata.width} / ${metadata.height}`;

                return style;
            }

            const zoomX = metadata.width / boundingWidth;
            const zoomY = metadata.height / boundingHeight;
            const zoom = (fit === 'contain') ? Math.max(zoomX, zoomY) : Math.min(zoomX, zoomY);

            const style: CSSProperties = {};
            style.width = metadata.width / zoom;
            style.height = metadata.height / zoom;
            //style.aspectRatio = `${metadata.width} / ${metadata.height}`;

            if (prev?.width === style.width && prev?.height === style.height) {
                return prev;
            }

            return style;
        });
    }, [shouldDisplayRatioFiller, fit]);

    const handleResizeObserver = useCallback(() => {
        if (!isMountedRef.current || widthForcedByParent) {
            return;
        }

        const _metadata = metadata || loadedMetaData;

        debug('handleResizeObserver', 'perform resize metadata=', _metadata);

        if (!_metadata) {
            return;
        }

        updateWidthHeight(_metadata);
    }, [loadedMetaData, metadata, updateWidthHeight, widthForcedByParent]);

    useResizeObserver(imageContainerRef.current || null, handleResizeObserver);

    const handleImageContainerRef = useCallback((ref: HTMLDivElement) => {
        debug('handleImageContainerRef', 'Set REF=', ref);
        imageContainerRef.current = ref;

        if (!isMountedRef.current) {
            return;
        }

        if (ref) {
            handleResizeObserver();
        }
    }, [handleResizeObserver]);

    const shouldDisplayPlaceholder = FORCE_PLACEHOLDER || (placeholder !== false && !_error && !loaded && shouldFetch);

    const cls = {
        'image-fit': fit,
        'has-error': _error,
        'is-loading': !_error && !loaded,
        'is-loaded': !FORCE_PLACEHOLDER && !_error && loaded,
        'has-metadata': metadata || loadedMetaData,
        'width_100': widthForcedByParent || shouldDisplayRatioFiller,
        'has-ratio': (metadata || loadedMetaData)?.width && (metadata || loadedMetaData)?.height,
    };

    const imgCls = {
        'disable-drag': disableDrag,
    };

    const _otherProps = omit(otherProps, 'data-testid');

    const imageNode = (
        <>
            {!_error && <img
                key='image'
                {..._otherProps}
                src={url || cacheBlobURL || (!shouldFetch && src) || undefined}
                ref={imageRef}
                style={imageStyle}
                onError={handleError}
                className={classNames('&-img', imgCls)}
                onLoad={handleLoaded}
            />}

            {/* Image placeholder */}
            {shouldDisplayPlaceholder && (
                <Placeholder
                    data-testid='placeholder'
                    key='placeholder'
                    className={classNames('&-placeholder', imgCls)}
                />
            )}
            {_error && (errorRender
                ? errorRender()
                : <Placeholder key='error' className={classNames('&-error', imgCls)} />
            )}
        </>
    );

    return (
        <div
            className={classNames('&', className, cls)}
            style={containerStyle}
            data-testid={dataTestId}
            ref={handleImageContainerRef}>
            {/* Loader */}
            {!loaded && _progressMonitor?.isRunning && (
                <ThreeDotsLoading
                    data-testid='image-loader'
                    key='loading'
                    className={classNames('&-loader')}
                />
            )}

            {/* Ratio */}
            {shouldDisplayRatioFiller && (
                <>
                    <div style={ratioStyle}></div>
                    <div className={classNames('&-container', imgCls)}>
                        {imageNode}
                    </div>
                </>
            )}

            {/* Image */}
            {!shouldDisplayRatioFiller && imageNode}
        </div>
    );
}
