import React, { useCallback } from 'react';

import { ArgIcon, ClassValue, useClassNames } from 'src/components/basic';
import { ControlParameter } from 'src/components/common/controls/control-parameter';

import './dropped-parameter.less';

interface DroppedParameterProps {
    className?: ClassValue;
    item: ControlParameter;
    onRemove: () => void;
    readonly?: boolean;
}

export function DroppedParameter(props: DroppedParameterProps) {
    const { className, item, onRemove, readonly } = props;

    const classNames = useClassNames('dropped-parameter');

    const onClick = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);

    return (
        <div className={classNames('&', className)} onClick={onClick}>
            {item.displayName}
            {!readonly && (
                <div
                    className={classNames('&-remove-button')}
                    onClick={() => onRemove()}
                >
                    <ArgIcon name='icon-cross' size={12} />
                </div>
            )}
        </div>
    );
}
