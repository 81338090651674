import { useCallback, useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import { EditorConfig } from '@ckeditor/ckeditor5-core/src/editor/editorconfig';
import { DecoupledEditor } from '@ckeditor/ckeditor5-editor-decoupled';

import { dayjs, normalizeText, ProgressMonitor, useArgNotifications, useCallbackAsync } from 'src/components/basic';
import { Brief } from 'src/exploration/model/brief';
import { BriefTemplate, BriefTemplateContent } from 'src/exploration/model/template';
import { Environment } from 'src/utils/environment';
import { BriefTemplateConnector } from '../../utils/connector/brief-template-connector';
import { getGlobalEditorConfig } from '../brief/global-editor-config';
import { CreationTemplate } from 'src/framework/templates/templates-types';
import { getBriefToken } from '../brief/utils';


const messages = defineMessages({
    renameTemplateError: {
        id: 'exploration.features.templates.brief-templates.renameTemplateError',
        defaultMessage: 'Error while renaming brief template',
    },
});

interface TemplateState {
    templates: BriefTemplate[];
    filteredTemplates: BriefTemplate[];
    searchString?: string;
    isLoaded: boolean;
}

export interface TemplateListReturnType {
    briefTemplateListState: TemplateState;

    searchStringChanged(searchString: string): void;

    deleteTemplate(progressMonitor: ProgressMonitor, template: BriefTemplate): Promise<void>;

    saveTemplate(template: CreationTemplate<BriefTemplateContent>, progressMonitor: ProgressMonitor): Promise<void>;

    initialize(progressMonitor?: ProgressMonitor): Promise<void>;

    renameTemplate(progressMonitor: ProgressMonitor, template: BriefTemplate, newName: string): Promise<void>;
}

export function useBriefTemplateList(usesPublicTemplates: boolean, enabled = true): TemplateListReturnType {
    const notifications = useArgNotifications();

    const [templateState, setTemplateState] = useState<TemplateState>(() => {
        return {
            templates: [],
            filteredTemplates: [],
            searchString: '',
            isLoaded: false,
        };
    });

    const [initialize] = useCallbackAsync(async (progressMonitor?: ProgressMonitor) => {
        const templates = usesPublicTemplates ?
            await BriefTemplateConnector.getInstance().listPublicBriefTemplate(progressMonitor)
            : await BriefTemplateConnector.getInstance().listBriefTemplate(progressMonitor);

        templates.sort((left, right) => {
            return (dayjs(left.lastUpdatedDate).isBefore(right.lastUpdatedDate) ? 1 : -1);
        });

        setTemplateState((prev) => {
            return {
                ...prev,
                templates,
                filteredTemplates: templates,
                isLoaded: true,
            };
        });
    }, [usesPublicTemplates]);

    useEffect(() => {
        if (templateState.isLoaded || !enabled) {
            return;
        }

        initialize().catch((error) => {
            console.error(error);
        });
    }, [templateState.isLoaded, enabled, initialize]);

    const searchStringChanged = useCallback((searchString: string) => {
        const normalizedSearchString = normalizeText(searchString);

        setTemplateState((prev) => {
            if (!prev.templates) {
                return prev;
            }

            const ret = {
                ...prev,
                filteredTemplates: prev.templates.filter((template) => {
                    const normalizedTemplateName = normalizeText(template.name);

                    if (normalizedTemplateName.indexOf(normalizedSearchString) >= 0) {
                        return true;
                    }

                    return false;
                }),
                searchString,
            };

            return ret;
        });
    }, []);

    const deleteTemplate = useCallback(async (progressMonitor: ProgressMonitor, template: BriefTemplate) => {
        await BriefTemplateConnector.getInstance().deleteBriefTemplate(template.id, progressMonitor);
        await initialize();
    }, [initialize]);

    const saveTemplate = useCallback(async (template: CreationTemplate<BriefTemplateContent>, progressMonitor: ProgressMonitor) => {
        await BriefTemplateConnector.getInstance().createBriefTemplate(template, progressMonitor);
    }, []);

    const renameTemplate = useCallback(async (progressMonitor: ProgressMonitor, template: BriefTemplate, newName: string) => {
        try {
            await BriefTemplateConnector.getInstance().patchBriefTemplate(template.id, [{
                path: '/name',
                value: newName,
                op: 'replace',
            }], undefined, progressMonitor);
            await initialize();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }
            notifications.snackError({ message: messages.renameTemplateError }, error as Error);
            throw error;
        }
    }, [initialize]);

    return {
        briefTemplateListState: templateState,
        searchStringChanged,
        deleteTemplate,
        saveTemplate,
        initialize,
        renameTemplate,
    };
}

export function useBriefTemplate() {
    const createBriefFromTemplate = useCallback(async (briefTemplateId: string, createdBrief: Brief, progressMonitor: ProgressMonitor) => {
        const templateBrief = await BriefTemplateConnector.getInstance().getBriefTemplate(briefTemplateId, progressMonitor);

        if (!templateBrief || !createdBrief.sections.length) {
            throw new Error('Cannot load Brief Template');
        }

        const section = createdBrief.sections[0];
        const editorConfig: EditorConfig = {
            cloudServices: {
                bundleVersion: localStorage.FORCE_CKEDITOR_VERSION || Environment.ckeditorBuildId || window.CKEDITOR_VERSION,
                tokenUrl: (async (): Promise<string> => {
                    const token = await getBriefToken(createdBrief, section, ProgressMonitor.empty());

                    return token;
                }) as any,
                uploadUrl: section.manifest!.cloudServices.uploadUrl,
                webSocketUrl: section.manifest!.cloudServices.webSocketUrl,
            },
            collaboration: {
                channelId: section.manifest!.collaboration.channelId.replace(/[^a-zA-Z0-9-]/g, ''),
            },
            licenseKey: Environment.ckeditorLicenseKey,
            ...getGlobalEditorConfig(),
        };

        const editor = await DecoupledEditor.create(templateBrief.content, editorConfig);
        editor.data.set(templateBrief.content, { suppressErrorInCollaboration: true } as any);
        editor.fire('collaborativeContentUpdate', { data: templateBrief.content });
    }, []);

    return {
        createBriefFromTemplate,
    };
}
