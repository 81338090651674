import { Folder } from '../../model/folder';
import { ResourceId } from '../../model/resource';
import { ArticulationStoredObject, ArticulationTemplate } from './articulation';
import { PersonId, ProceoPersonRole } from './person';
import { OffenceReferenceId } from './reference';
import { ArgUserInfo } from '../../components/basic';

export type FolderMainPersons = Map<ProceoPersonRole, FolderMainPerson>;

export interface FolderMainPerson {
    id: PersonId;
    name: string;
}

export interface FolderMainOffence {
    id: OffenceReferenceId;
    title: string;
}

export interface ProceoBaseFolder extends Folder {
    folderType: FolderType;
}

export interface ProceoFolder extends ProceoBaseFolder {
    status: ProceoFolderStatus;
    responsible?: ArgUserInfo;
    folderNumber?: string;
    justiceNumber?: string;
    picture?: ResourceId;
    legalFramework: LegalFramework;
    legalRegime: LegalRegime;
    mainPersons?: FolderMainPersons;
    mainOffence?: FolderMainOffence;
    lastActDate?: Date;
    confidentialityLevel?: ProceoFolderConfidentialityLevel;
    procedureArticulationData?: ArticulationStoredObject;
    articulationTemplate?: ArticulationTemplate;
}

export enum ProceoFolderStatus {
    Unknown = 'Unknown',
    Running = 'Running',
    Closed = 'Closed',
}

export enum ProceoFolderConfidentialityLevel {
    Normal = 'Normal',
    Level1 = 'Level1',
    Level2 = 'Level2',
}

export enum LegalFramework {
    PRELIMINARY_INVESTIGATION = 'PRELIMINARY_INVESTIGATION',
    IN_THE_ACT = 'IN_THE_ACT', //Flagrant delit
    COMMISSION = 'COMMISSION' //Commission Rogatoire
}

export enum LegalRegime {
    COMMON_RIGHT = 'COMMON_RIGHT', //Droit commun
    STUNNING = 'STUNNING', //Stups
    TERRORISM = 'TERRORISM',
    ORGANIZED_CRIME = 'ORGANIZED_CRIME',
}

export enum FolderType {
    /**
     * Classic folder (procedure)
     */
    Folder = 'Folder',
    /**
     * Group
     */
    Group = 'Group',
}

export enum ProceoFolderAccessRight {
    NONE = 'NONE',
    COLABORATOR = 'COLABORATOR',
    CONSULTANT = 'CONSULTANT',
}
