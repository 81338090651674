import { useEffect, useRef, useState } from 'react';
import { isEqual, isNumber } from 'lodash';

import { EventEmitter } from '../components/basic';

// const debug = Debug('argonode:components:features:ImageAlignmentProvider');

type PathOrName = string;

export type PositionAndScale = {
    size?: number;
    dx?: number;
    dy?: number;
};

export type ImageAlignment = Record<PathOrName, PositionAndScale>;

export const DEFAULT_POSITION_AND_SCALE: PositionAndScale = {
    dx: 0,
    dy: 0,
    size: 0.7,
};

export const DEFAULT_IMAGE_ALIGNMENT = {};

export interface ImageAlignmentProviderEvents {
    AlignmentsChanges: () => void;
}

export class ImageAlignmentProvider extends EventEmitter<ImageAlignmentProviderEvents> {
    #imageAlignment: ImageAlignment = { ...DEFAULT_IMAGE_ALIGNMENT };

    get(): ImageAlignment {
        return { ...this.#imageAlignment };
    }

    add(pathOrName: PathOrName, positionAndScale: PositionAndScale) {
        let value: PositionAndScale = {
            dx: isNumber(positionAndScale.dx) ? positionAndScale.dx : DEFAULT_POSITION_AND_SCALE.dx,
            dy: isNumber(positionAndScale.dy) ? positionAndScale.dy : DEFAULT_POSITION_AND_SCALE.dy,
            size: isNumber(positionAndScale.size) ? positionAndScale.size : DEFAULT_POSITION_AND_SCALE.size,
        };

        if (isEqual(DEFAULT_POSITION_AND_SCALE, value)) {
            value = DEFAULT_POSITION_AND_SCALE;
        } else if (isEqual(value, positionAndScale)) {
            value = positionAndScale;
        }

        const prev = this.#imageAlignment[pathOrName];

        if (isEqual(prev, value)) {
            return;
        }

        this.#imageAlignment[pathOrName] = value;

        this.updateStateId();

        this.emit('AlignmentsChanges');
    }
}

export function useImageAlignmentProvider(): ImageAlignmentProvider {
    const [stateId, setStateId] = useState<number>(0);

    const imageAlignmentProviderRef = useRef<ImageAlignmentProvider>();
    if (!imageAlignmentProviderRef.current) {
        imageAlignmentProviderRef.current = new ImageAlignmentProvider();
    }

    useEffect(() => {
        const func = () => {
            setStateId((prev) => (++prev));
        };

        imageAlignmentProviderRef.current?.addListener('AlignmentsChanges', func);

        return () => {
            imageAlignmentProviderRef.current?.removeListener('AlignmentsChanges', func);
        };
    }, [imageAlignmentProviderRef.current]);

    return imageAlignmentProviderRef.current;
}
