import React, { Fragment, ReactNode, useCallback, useState } from 'react';
import { isEmpty, map, omit } from 'lodash';

import { useToolNodes } from './use-tool-nodes';
import { ArgToolbarItem } from './arg-toolbar-item';
import { ArgToolbarCombo } from './arg-toolbar-combo';
import { ArgToolbarDivider } from './arg-toolbar-divider';
import { ArgMenu } from '../arg-menu/arg-menu';
import { ArgRenderedText } from '../types';
import { ToolContext, ToolTreeContext, ToolTreeNode } from './tool-context';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { countVisibleChildren } from './utils';
import { ButtonClickEvent } from '../arg-button/arg-button';
import { getToolIcon, getToolLabel, getToolTooltip, isToolDisabled, Tool } from './tool';
import { useRenderToolMenuFromNodes } from './arg-tool-menu';
import { preventContextMenu } from '../../../utils/prevent-context-menu';
import { ArgMessageRenderer } from '../arg-message-renderer/arg-message-renderer';
import { ArgToolbarActionDropdown } from './arg-toolbar-action-dropdown';

import './arg-toolbar.less';


export interface ArgToolbarProps<T> {
    prefix?: string;
    className?: ClassValue;
    toolbarContext: ToolContext<T>;
    disabled?: boolean;

    selectedPanel?: string;
    onPanelSelection?: (path: string) => void;

    selectedEditor?: string;
    onEditorSelection?: (path: string) => void;
    hideEditor?: boolean;

    environmentContext: T;
}

export function ArgToolbar<T = undefined>(props: ArgToolbarProps<T>) {
    const {
        prefix,
        className,
        toolbarContext,
        disabled,
        selectedPanel,
        onPanelSelection,
        selectedEditor,
        onEditorSelection,
        hideEditor,
        environmentContext,
    } = props;

    const [toolbarNodes, toolTreeContext] = useToolNodes(toolbarContext, environmentContext, prefix, hideEditor);
    const classNames = useClassNames('arg-toolbar');

    let shouldRenderDivider = false;
    const renderNodes = (nodes: ToolTreeNode<T>[]): ReactNode => {
        return map(nodes, (node: ToolTreeNode<T>) => {
            let popover: ArgRenderedText;
            if (node.type === 'group') {
                const visibleChildren = countVisibleChildren(node, environmentContext);
                if (!visibleChildren?.length) {
                    return;
                }

                const addDivider = visibleChildren?.length > 0 && shouldRenderDivider;
                shouldRenderDivider = true;

                return (
                    <Fragment key={node.path}>
                        {addDivider && (
                            <ArgToolbarDivider
                                key='divider'
                                className={classNames('&-divider')}
                                data-tool-path={node.path}
                                data-tool-order={node.order}
                            />
                        )}

                        {renderNodes(visibleChildren)}
                    </Fragment>
                );
            } else if (node.type === 'panel') {
                const oldOnClick = node.onClick;
                node = {
                    ...node,
                    onClick(tool: Tool<T>, environmentContext: T, event?: ButtonClickEvent) {
                        onPanelSelection?.(node.path);

                        oldOnClick?.(tool, environmentContext, event);
                    },
                    selected: (node.path === selectedPanel),
                };
            } else if (node.type === 'editor') {
                const oldOnClick = node.onClick;
                node = {
                    ...node,
                    onClick(tool: Tool<T>, environmentContext: T, event?: ButtonClickEvent) {
                        onEditorSelection?.(node.path);

                        oldOnClick?.(tool, environmentContext, event);
                    },

                    selected: (node.path === selectedEditor),
                };
            } else if (node.type === 'combo') {
                const tooltip = getToolTooltip(node, environmentContext);
                const toolDisabled = isToolDisabled(node, environmentContext);

                return <ArgToolbarCombo<T>
                    {...node}
                    disabled={disabled || toolDisabled}
                    tooltip={tooltip}
                    key={node.path}
                    className={classNames('&-item', node.className)}
                    environmentContext={environmentContext}
                    data-tool-path={node.path}
                    data-tool-order={node.order}
                />;
            } else if (node.type === 'actionDropdown') {
                const tooltip = getToolTooltip(node, environmentContext);
                const toolDisabled = isToolDisabled(node, environmentContext);

                return <ArgToolbarActionDropdown<T>
                    {...node}
                    disabled={disabled || toolDisabled}
                    tooltip={tooltip}
                    key={node.path}
                    className={classNames('&-item', node.className)}
                    environmentContext={environmentContext}
                    data-tool-path={node.path}
                    data-tool-order={node.order}
                />;
            } else if (node.type === 'separator') {
                shouldRenderDivider = false;

                return <ArgToolbarDivider
                    className={classNames('&-divider')}
                    key={`divider-${node.path}`}
                    data-tool-path={node.path}
                    data-tool-order={node.order}
                />;
            } else if (node.type === 'label') {
                return <ArgMessageRenderer
                    className={classNames('&-label')}
                    key={`label-${node.path}`}
                    message={getToolLabel(node, environmentContext)}
                    size='small'
                    data-tool-path={node.path}
                    data-tool-order={node.order}
                />;
            }

            if (node.type === 'button' || node.type === 'editor' || node.type === 'custom') {
                if (node.children) {
                    popover = <ArgToolbarItemMenu<T>
                        node={node}
                        toolTreeContext={toolTreeContext}
                        environmentContext={environmentContext}
                        data-tool-path={node.path}
                        data-tool-order={node.order}
                    />;
                }
            }

            shouldRenderDivider = true;
            const _restProps = omit(node, 'children');
            const tooltip = getToolTooltip(node, environmentContext);
            const toolDisabled = isToolDisabled(node, environmentContext);

            const triggerPopover = popover ? 'contextMenu' : undefined;

            const testid = node.testid && !isEmpty(node.testid) ? `arg-toolbar-item ${node.testid}` : 'arg-toolbar-item';

            return (
                <ArgToolbarItem<T>
                    {..._restProps}
                    testid={testid}
                    popover={popover}
                    popoverPlacement='bottomLeft'
                    popoverTrigger={triggerPopover}
                    tooltip={tooltip}
                    disabled={disabled || toolDisabled}
                    key={node.path}
                    icon={getToolIcon(node, environmentContext)}
                    label={getToolLabel(node, environmentContext)}
                    className={classNames('&-item', node.className)}
                    data-tool-path={node.path}
                    data-tool-order={node.order}
                    environmentContext={environmentContext}
                />
            );
        });
    };

    const renderedNodes = renderNodes(toolbarNodes);

    const cls = {
        disabled,
    };

    return (
        <div
            className={classNames('&', (prefix) ? `&-${prefix}` : undefined, className, cls)}
            data-tool-context={toolbarContext.id}
        >
            {renderedNodes}
        </div>
    );
}

interface ArgToolbarItemMenuProps<T> {
    className?: ClassValue;
    node: ToolTreeNode<T>;
    toolTreeContext: ToolTreeContext<T>;
    environmentContext: T;
    getPopupContainer?: (node: HTMLElement) => HTMLElement;
}

export function ArgToolbarItemMenu<T>(props: ArgToolbarItemMenuProps<T>) {
    const {
        className,
        node,
        toolTreeContext,
        getPopupContainer,
        environmentContext,
    } = props;

    const [activeKey, setActiveKey] = useState<string>();

    const onCloseMenu = useCallback(() => {

    }, []);

    const renderedNodes = useRenderToolMenuFromNodes<T>(
        node.children!,
        toolTreeContext,
        environmentContext,
        onCloseMenu,
        setActiveKey
    );

    return (
        <ArgMenu
            className={className}
            openKeys={activeKey ? [activeKey] : undefined}
            onContextMenu={preventContextMenu}
            getPopupContainer={getPopupContainer}
            data-tool-path={node.path}
            data-tool-order={node.order}
        >
            {renderedNodes}
        </ArgMenu>
    );
}
