import { CaseId } from '../../model/folder-case-piece';
import { CasePieceId } from 'src/model/basic-case-piece';
import { FormId } from './form';
import { VertexId } from './vertex';
import { Activity } from '../../model/activity';
import { EdgeId } from './edge';
import { CasePieceExplorationEntityProperties } from './exploration';
import { ActivityActionContentType } from '../../components/features/activities/types';

export enum ActivityType {
    Exploration = 'exploration',
    Form = 'form',
    Vertex = 'vertex',
    Edge = 'edge',
    Brief = 'brief',
    Case = 'case',
    FlaggedObject = 'flaggedObject',
    Dashboard = 'dashboard',
    Chart = 'pivot-table',
    DataSource = 'universe-projection',
    ArgonosPiece = 'argonos-piece',
    ChildCase = 'argonos-folder',
    BulkSearch = 'bulksearch',
}

export interface ExplorationActivity extends Activity {
    type: ActivityType;
    action: ActivityAction;
    effects: ActivityEffect[];
    isObsolete?: boolean;
}

export interface ActivityAction {
    comment?: string;
    content?: ActivityActionContent;
    context?: ActivityActionContext;
}

export type ActivityActionContent =
    CaseActionContent
    | OpenVertexActionContent
    | EdgeActionContent
    | ArgonosPieceActionContent
    ;

interface ActionContentBase {
    type: ActivityActionContentType;
    dataType: ActivityType;
}

export interface ArgonosPieceActionContent extends ActionContentBase {
    entityId?: CasePieceId;
    entityType?: string;
    name?: string;
}

export interface CaseActionContent extends ActionContentBase {
    caseId?: CaseId;
    caseName?: string;
}

export interface FlaggedObjectActionContent extends ActionContentBase {
    flagsCount: number;
}

export interface OpenVertexActionContent extends ActionContentBase {
    vertexId?: VertexId;
    vertexName?: string;
    universeId?: string;
    casePieceId?: FormId;
    casePieceType?: string;
}

export interface EdgeActionContent extends ActionContentBase {
    edgeId?: EdgeId;
    universeId?: string;
    fromVertexName: string;
    toVertexName: string;
    fromVertexId: VertexId;
    toVertexId: VertexId;
}

type ActivityActionContext = CaseContext | CaseBriefContext | CaseArgonosPieceContext | VertexContext | EdgeContext;

export interface CaseContext {
    case?: CaseContextContent;
}

interface ContextContentBase {
    id: string;
    name?: string;
    isObsolete?: boolean;
}

type CaseContextContent = ContextContentBase;
type CaseBriefContextContent = ContextContentBase;

interface CaseArgonosPieceContextContent extends ContextContentBase {
    entityProperties?: any | CasePieceExplorationEntityProperties;
}

export interface CaseBriefContext extends CaseContext {
    caseBrief?: CaseBriefContextContent;
}

export interface CaseArgonosPieceContext extends CaseContext {
    casePiece?: CaseArgonosPieceContextContent;
}

export interface VertexContext extends CaseContext {
    vertex?: VertexContextContent;
}

export interface EdgeContext extends CaseContext {
    edge?: EdgeContextContent;
}

interface VertexContextContent {
    id: string;
    vertexName?: string;
    universeId: string;
    vertexType: string;
}

interface EdgeContextContent {
    id: string;
    universeId: string;
    edgeType: string;
}

export enum EffectType {
    Error = 'Error',
    UpdateCase = 'UpdateCase',
    UpdateExploration = 'UpdateExploration',
    UpdateForm = 'UpdateForm',
    CreateForm = 'CreateForm',
    UpdateCaseBrief = 'UpdateCaseBrief',
}

interface EffectBase {
    type: EffectType;
    properties: EffectProperty[];
}

interface CaseEffect extends EffectBase {
    caseId: string;
}

interface ExplorationEffect extends EffectBase {
    explorationId: string;
}

interface CasePieceEffect extends EffectBase {
    casePieceId: CasePieceId;
}

interface CaseBriefEffect extends EffectBase {
    caseBriefId: string;
}

type ErrorEffect = EffectBase;
export type ActivityEffect = ErrorEffect | CaseEffect | ExplorationEffect | CasePieceEffect | CaseBriefEffect;

interface EffectProperty {
    name?: string;
    oldValue?: string;
    newValue?: string;
}
