import { defineMessages } from 'react-intl';
import React, { lazy, Suspense } from 'react';
import { RouteProps } from 'react-router';
import { Navigate, Route } from 'react-router-dom';

import { registerArgonosModule } from '../components/application/modules-manager';
import { Environment } from '../utils/environment';
import { ArgonosModuleRouterProps } from '../components/containers/authenticated-apps-router';
import { LoadingSuspense } from '../components/containers/loading-suspense';
import { ArgonosModule } from '../components/application/modules';
import { KNOWLEDGE_BASE_ROOT_URL } from '../components/containers/routes-urls';
import { getKnowledgeBaseApi } from '../utils/connectors/api-url';
import { KB_PERMISSIONS_REQUIRED } from './model/permissions';

const LazyKnowledgeBaseRouter = lazy(() => import('./knowledge-base-router'));

const FORCE_SUSPENSE_LOADING = false;

const messages = defineMessages({
    kbApplication: {
        id: 'common.applications.KnowledgeBase',
        defaultMessage: 'Knowledge Base',
    },
});

function KnowledgeBaseApp(props: ArgonosModuleRouterProps) {
    const { className } = props;

    if (FORCE_SUSPENSE_LOADING) {
        return <LoadingSuspense />;
    }

    return (
        <Suspense fallback={<LoadingSuspense />}>
            <LazyKnowledgeBaseRouter
                className={className}
            />
        </Suspense>
    );
}

const KNOWLEDGE_BASE_MODULE: ArgonosModule = {
    id: 'chapsVision.KnowledgeBase',
    subType: 'KnowledgeBase',

    routeURL: KNOWLEDGE_BASE_ROOT_URL,
    enabled: Environment.isKnowledgeBaseEnabled() || localStorage.getItem('arg-kb') === 'true',
    requiredPermissions: KB_PERMISSIONS_REQUIRED,
    apiURL: getKnowledgeBaseApi(),
    testURL: '/kb',

    iconURL: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTE4LjY4LDEyLjMyQzE2LjkyLDEwLjU2IDE0LjA3LDEwLjU3IDEyLjMyLDEyLjMzQzEwLjU2LDE0LjA5IDEwLjU2LDE2Ljk0IDEyLjMyLDE4LjY5QzEzLjgxLDIwLjE3IDE2LjExLDIwLjQzIDE3Ljg5LDE5LjMyTDIxLDIyLjM5TDIyLjM5LDIxTDE5LjMsMTcuODlDMjAuNDMsMTYuMTIgMjAuMTcsMTMuOCAxOC42OCwxMi4zMk0xNy4yNywxNy4yN0MxNi4yOSwxOC4yNSAxNC43MSwxOC4yNCAxMy43MywxNy4yN0MxMi43NiwxNi4yOSAxMi43NiwxNC43MSAxMy43NCwxMy43M0MxNC43MSwxMi43NiAxNi4yOSwxMi43NiAxNy4yNywxMy43M0MxOC4yNCwxNC43MSAxOC4yNCwxNi4yOSAxNy4yNywxNy4yN00xMC45LDIwLjFDMTAuMjUsMTkuNDQgOS43NCwxOC42NSA5LjQyLDE3Ljc4QzYuMjcsMTcuMjUgNCwxNS43NiA0LDE0VjE3QzQsMTkuMjEgNy41OCwyMSAxMiwyMVYyMUMxMS42LDIwLjc0IDExLjIzLDIwLjQ0IDEwLjksMjAuMU00LDlWMTJDNCwxMy42OCA2LjA3LDE1LjEyIDksMTUuN0M5LDE1LjYzIDksMTUuNTcgOSwxNS41QzksMTQuNTcgOS4yLDEzLjY1IDkuNTgsMTIuODFDNi4zNCwxMi4zIDQsMTAuNzkgNCw5TTEyLDNDNy41OCwzIDQsNC43OSA0LDdDNCw5IDcsMTAuNjggMTAuODUsMTFIMTAuOUMxMi4xLDkuNzQgMTMuNzYsOSAxNS41LDlDMTYuNDEsOSAxNy4zMSw5LjE5IDE4LjE0LDkuNTZDMTkuMTcsOS4wOSAxOS44Nyw4LjEyIDIwLDdDMjAsNC43OSAxNi40MiwzIDEyLDNaIiAvPjwvc3ZnPg==',
    name: messages.kbApplication,
    color: '#000000',

    // KB and settings docs are the same (and we're missing doc url in the config hence this solution)
    documentationURL: Environment.docServerUrlSettings,

    getRoutes(): React.ReactElement<RouteProps>[] {
        return [
            <Route
                key='kb'
                path='kb'
                element={<Navigate to='./home' replace={true} />}
            />,
            <Route
                key='kb/*'
                path='kb/*'
                element={(
                    <KnowledgeBaseApp argonosModule={KNOWLEDGE_BASE_MODULE} />
                )}
            />,
        ];
    },

    hasRoles: true,
    hasUserRoles: true,
    hasGroupRoles: true,
    hasUserPermissions: true,
    scope: 'kb',
    hasEnvironments: true,
};

registerArgonosModule(KNOWLEDGE_BASE_MODULE);

