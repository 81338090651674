import { defineMessages } from 'react-intl';

import { defineKeyBindings, KeyBindingScopeDescriptor } from '../../components/basic';

export const messages = defineMessages({
    homePageScope: {
        id: 'preparation.keybindings.HomePageScope',
        defaultMessage: 'Home Page',
    },
    newItem: {
        id: 'preparation.keybindings.NewItem',
        defaultMessage: 'New folder / process / component',
    },
    importItem: {
        id: 'preparation.keybindings.ImportItem',
        defaultMessage: 'Import folder / process / component',
    },
    searchInItems: {
        id: 'preparation.keybindings.SearchInItems',
        defaultMessage: 'Search in folders / processes / components',
    },
});

export const SCOPE: KeyBindingScopeDescriptor = {
    id: 'preparation.HomePage',
    name: messages.homePageScope,
};

export const KEY_BINDINGS = defineKeyBindings({
    // Cases (home-page)
    newItem: {
        id: 'preparation.cases.NewItem',
        scope: SCOPE,
        name: messages.newItem,
        defaultKeys: 'alt+N',
    },
    importItem: {
        id: 'preparation.cases.ImportItem',
        scope: SCOPE,
        name: messages.importItem,
        defaultKeys: 'alt+I',
    },
    searchItems: {
        id: 'preparation.cases.SearchInItems',
        scope: SCOPE,
        name: messages.searchInItems,
        defaultKeys: 'alt+S',
    },
});

