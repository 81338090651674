import React, { useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';

import {
    ArgButton,
    ArgChangeReason,
    ArgCombo,
    ArgRenderFunction,
    ArgSwitch,
    useClassNames,
} from '../../../basic';
import { AdvancedStyleType, RuleSet } from './graph-style';
import { getConditionAsGradient } from './controls/utils';
import { Property } from '../../controls/controls-type';

import './advanced-styles-container.less';

const messages = defineMessages({
    advancedStyle: {
        id: 'common.graph.customisation.object.advanced.style',
        defaultMessage: '{style}',
    },
    noneMessage: {
        id: 'common.graph.customisation.object.advanced.style.none-Message',
        defaultMessage: 'None',
    },
    byProperty: {
        id: 'common.graph.customisation.object.advanced.style.by-property-Message',
        defaultMessage: 'By property',
    },
    selectPropertyMessage: {
        id: 'common.graph.customisation.object.advanced.style.selecte-property',
        defaultMessage: 'Select property',
    },
    undefinedValuesRule: {
        id: 'common.graph.customisation.object.advanced.style.undefined-value-rule',
        defaultMessage: 'Undefined values rule',
    },
    colorRule: {
        id: 'common.graph.customisation.advanced.ColorRule',
        defaultMessage: 'Color rule',
    },
    sizeRule: {
        id: 'common.graph.customisation.advanced.SizeRule',
        defaultMessage: 'Size rule',
    },
    iconRule: {
        id: 'common.graph.customisation.advanced.IconRule',
        defaultMessage: 'Icon rule',
    },
    badgesRule: {
        id: 'common.graph.customisation.advanced.BadgesRule',
        defaultMessage: 'Badges rule',
    },
    styleRule: {
        id: 'common.graph.customisation.advanced.StyleRule',
        defaultMessage: 'Style rule',
    },

});

enum Tab {
    None,
    ByProperty
}

interface AdvancedStylesContainerProps {
    label: React.ReactNode | MessageDescriptor | ArgRenderFunction;
    ruleSets?: RuleSet[];
    property?: Property;
    properties: Property[];
    styleType: AdvancedStyleType;
    children: React.ReactNode;
    onPropertyChange?: (property: Property | Property[] | undefined, _: ArgChangeReason) => Promise<void>;
    onAddNewRule?: () => void;
    addNewRuleButtonDisabled?: boolean;
    handleUndefinedValuesStyleRule?: (active: boolean) => void;
}

export function AdvancedStylesContainer(props: AdvancedStylesContainerProps) {
    const {
        styleType,
        label,
        properties,
        ruleSets,
        property,
        children,
        onPropertyChange,
        onAddNewRule,
        addNewRuleButtonDisabled,
        handleUndefinedValuesStyleRule,
    } = props;

    const classNames = useClassNames('graph-advanced-style-customizer');
    const [isExpanded, setIsExpanded] = useState(false);
    const [forcePropertyTab, setForcePropertyTab] = useState(false);
    const [checked, setChecked] = useState(false);

    // Reset force property tab when a modification is sumbmitted (rulSets update)
    useEffect(() => {
        setForcePropertyTab(false);
    }, [ruleSets]);

    useEffect(() => {
        const ruleSetContainsUndefinedRule = (ruleSets ?? []).some((ruleSet) => ruleSet.isUndefinedRuleSet);
        setChecked(ruleSetContainsUndefinedRule);
    }, [ruleSets]);

    const activeTab = useMemo(() => (
        forcePropertyTab ? Tab.ByProperty : getTab(ruleSets)
    ), [ruleSets, forcePropertyTab]);

    const isGradient = !!(styleType === 'size' && ruleSets && getConditionAsGradient(ruleSets[0])?.gradient);

    const expandToggle = () => {
        setIsExpanded((prev) => !prev);
    };
    const handleNoneTabClick = () => {
        onPropertyChange?.(undefined, 'clear');
    };
    const handleByPropertyTabClick = () => setForcePropertyTab(true);

    const clsActiveElement = (tab: Tab) => ({
        active: activeTab === tab,
    });

    const onUndefinedValuesSwitchClick = () => {
        setChecked((prev) => {
            handleUndefinedValuesStyleRule?.(!prev);

            return !prev;
        });
    };

    return (
        <div className={classNames('&')}>
            {/* Advanced style title */}
            <ArgButton
                size='medium'
                icon={`icon-triangle-${isExpanded ? 'down' : 'right'}`}
                label={label}
                type='ghost'
                onClick={expandToggle}
            />
            {/* Style customization content*/}
            {isExpanded && (
                <>
                    <div className={classNames('&-header')}>
                        <div
                            className={classNames('&-header-none', clsActiveElement(Tab.None))}
                            onClick={handleNoneTabClick}
                        >
                            <FormattedMessage {...messages.noneMessage} />
                        </div>
                        <div
                            className={classNames('&-header-by-property', clsActiveElement(Tab.ByProperty))}
                            onClick={handleByPropertyTabClick}
                        >
                            <FormattedMessage {...messages.byProperty} />
                        </div>
                    </div>

                    {activeTab === Tab.ByProperty && (
                        <>
                            <div className={classNames('&-select-property')}>
                                <span className={classNames('&-select-property-label')}>
                                    <FormattedMessage {...messages.selectPropertyMessage} />
                                </span>
                                <ArgCombo<Property>
                                    getItemKey={(property) => property.name}
                                    className={classNames('&-selecte-property-values')}
                                    items={properties}
                                    placeholder={messages.selectPropertyMessage}
                                    getItemLabel={(property) => property.displayName}
                                    size='medium'
                                    cardinality='optional'
                                    clearable={true}
                                    value={property}
                                    onChange={onPropertyChange}
                                    enableFilter={true}
                                    popoverClassName={classNames('&-selecte-property-values-popover')}
                                />
                            </div>
                            {children}
                            {!!(ruleSets && ruleSets.length > 0 && !isGradient) && (
                                <div className={classNames('&-add-rule')}>
                                    <ArgButton
                                        type='link'
                                        size='medium'
                                        icon='icon-plus'
                                        className={classNames('&-button')}
                                        onClick={onAddNewRule}
                                        disabled={addNewRuleButtonDisabled}
                                    >
                                        <FormattedMessage
                                            {...messages[`${styleType}Rule`]}
                                        />
                                    </ArgButton>
                                    <ArgSwitch
                                        checked={checked}
                                        onClick={onUndefinedValuesSwitchClick}
                                        label={messages.undefinedValuesRule}
                                        className={classNames('&-add-rule-undefined-values-switch')}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
}

function getTab(ruleSets: RuleSet[] | undefined) {
    const haveRuleSets = ruleSets && ruleSets.length > 0;
    const tab = haveRuleSets ? Tab.ByProperty : Tab.None;

    return tab;
}
