import { defineMessages } from 'react-intl';

import { FolderCustomFieldDefinition } from '../../model/folder-custom-field-definition';
import { DataType } from 'src/components/common/data-types';
import { PROCEO_BASE_FOLDER_CUSTOM_FIELDS_DEFINITION, ProceoBaseFolderCustomFieldsDefinition } from './base-folder-custom-fields';

const messages = defineMessages({
    serviceId: {
        id: 'proceo.constants.group-field.serviceId',
        defaultMessage: 'Service Id',
    },
});

export type ProceoGroupCustomFields = Partial<Record<ProceoGroupCustomFieldNames, any>>;
export type ProceoGroupCustomFieldsDefinition = ProceoBaseFolderCustomFieldsDefinition & Record<ProceoGroupCustomFieldNames, FolderCustomFieldDefinition>;

export enum ProceoGroupCustomFieldNames {
    ServiceId = 'ServiceId',
}


export const PROCEO_GROUP_CUSTOM_FIELDS_DEFINITION: ProceoGroupCustomFieldsDefinition = {
    ...PROCEO_BASE_FOLDER_CUSTOM_FIELDS_DEFINITION,
    [ProceoGroupCustomFieldNames.ServiceId]: {
        name: ProceoGroupCustomFieldNames.ServiceId,
        displayName: messages.serviceId,
        type: DataType.String,
        isRequired: false,
    },
};
