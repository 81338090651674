import { defineMessages } from 'react-intl';
import { useState } from 'react';
import { isEmpty, isNil } from 'lodash';

import {
    ArgFormLabel,
    ArgInputText,
    ArgInputTextArea,
    ArgModal,
    ProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { Role, RoleId, RolesScope } from 'src/settings/models/dtoApi';
import { SettingsConnector } from '../../connectors/settings-connector';

import './edit-role-modal.less';

export const messages = defineMessages({
    title: {
        id: 'settings.edit-role-modal.title',
        defaultMessage: 'Edit Role',
    },
    name: {
        id: 'settings.edit-role-modal.name',
        defaultMessage: 'Name',
    },
    description: {
        id: 'settings.edit-role-modal.description',
        defaultMessage: 'Description',
    },
    required: {
        id: 'settings.edit-role-modal.required',
        defaultMessage: 'Required',
    },
    submit: {
        id: 'settings.edit-role-modal.submitButton',
        defaultMessage: 'Validate',
    },
    cancel: {
        id: 'settings.edit-role-modal.cancelButton',
        defaultMessage: 'Cancel',
    },
    invalidNameDescription: {
        id: 'settings.edit-role-modal.invalidName',
        defaultMessage: 'Name already exists',
    },
    editUserErrorMsg: {
        id: 'settings.edit-role-modal.error-message',
        defaultMessage: 'Something went wrong while editing the role',
    },
});

export interface EditRoleModalProps {
    closeModal: () => void;
    isRoleNameInvalid?: (value?: string) => boolean;
    handleOnRoleEdited: (progressMonitor: ProgressMonitor, roleId: RoleId) => void;
    role: Role;
    rolesScope: RolesScope;
}

export const EditRoleModal = ((props: EditRoleModalProps) => {
    const { closeModal, isRoleNameInvalid, handleOnRoleEdited, role, rolesScope } = props;

    const classNames = useClassNames('settings-edit-role-modal');
    const notifications = useArgNotifications();

    const [name, setName] = useState<string>(role?.name);
    const [description, setDescription] = useState<string>(role?.description);

    const [handleSubmit, progressMonitor] = useCallbackAsync(
        async (progressMonitor: ProgressMonitor) => {
            if (isNil(name) || !name.trim().length) {
                return;
            }

            try {
                await SettingsConnector.getInstance().editRole({
                    ...role,
                    name,
                    description,
                }, rolesScope, progressMonitor);
                handleOnRoleEdited(progressMonitor, role.id);
            } catch (error) {
                if (progressMonitor.isCancelled) {
                    throw error;
                }

                notifications.snackError({ message: messages.editUserErrorMsg }, error as Error);
            }

            closeModal();
        },
        [name, closeModal, role, description, rolesScope, handleOnRoleEdited]
    );

    const isNameInvalid = isRoleNameInvalid?.(name);

    return <ArgModal
        size='medium'
        title={messages.title}
        onClose={closeModal}
        onCancel={closeModal}
        onOk={handleSubmit}
        className={classNames('&')}
        progressMonitor={progressMonitor}
        okDisabled={progressMonitor?.isRunning || isNameInvalid ||
            (
                isNil(name) ||
                isEmpty(name) ||
                name.trim().length === 0
            )}
        okText={messages.submit}
    >
        <form onSubmit={handleSubmit} id='settings-edit-role-modal-form'>
            <ArgFormLabel
                propertyName={messages.name}
                description={isNameInvalid ? messages.invalidNameDescription : undefined}
                className={classNames('&-form-label', { 'has-error': isNameInvalid })}
                required={true}
            >
                <ArgInputText
                    value={name}
                    autoFocus={true}
                    state={isNameInvalid ? 'invalid' : undefined}
                    onInputChange={setName}
                    className={classNames('&-name-input')}
                    placeholder={messages.name}
                    data-testid='settings-edit-role-modal-name'
                />
            </ArgFormLabel>
            <ArgFormLabel
                propertyName={messages.description}
                className={classNames('&-form-label')}
            >
                <ArgInputTextArea
                    value={description}
                    resizable={false}
                    onInputChange={setDescription}
                    className={classNames('&-description-input')}
                    placeholder={messages.description}
                    data-testid='settings-edit-role-modal-description'
                />
            </ArgFormLabel>
        </form>
    </ArgModal>;
});
