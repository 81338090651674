import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';


import { ArgButton, ArgInputDate, ArgInputText, dayjs, useClassNames } from '../../../../basic';
import { CommonControlProps, useGetStyleModifier } from './utils';
import { computeLocale } from '../../../controls/locales-mapping';
import { RangeDateControlMessages } from '../advanced-body';
import { AdvancedStyleType } from '../graph-style';
import { internalisationDateFormat } from '../../../../../utils/dates/internalisation-date-format';

import './interval-date-control.less';

export interface IntervalDateControlProps extends CommonControlProps {
    left?: dayjs.Dayjs;
    right?: dayjs.Dayjs;

    invalidLeft?: boolean;
    invalidRight?: boolean;

    onRangeValueChange?: (
        advancedStyleType: AdvancedStyleType,
        side: 'left' | 'right',
        value: dayjs.Dayjs | null,
        index: number
    ) => Promise<void>;

    messages?: Pick<RangeDateControlMessages,
        'startDatePlaceholder' | 'endDatePlaceholder' | 'undefinedStyleRulePlaceholder'>;
}

export const IntervalDateControl = ({
    type,
    index,
    left,
    right,

    invalidLeft,
    invalidRight,

    fillColor,
    iconColor,
    strokeColor,

    badgeFillColor,
    badgeIconName,

    size,

    iconName,
    lineStyle,

    onStyleChange,
    onRangeValueChange,
    canBeRemoved,
    onRemoveComponent,
    doesBadgeBlink,

    messages,
    isUndefinedStyleRule,
}: IntervalDateControlProps) => {
    const classNames = useClassNames('interval-date-control');
    const intl = useIntl();
    const cls = {
        size: type === 'size',
        undefined: !!isUndefinedStyleRule,
        'line-style': type === 'style',
    };

    const styleModifier = useGetStyleModifier(
        type,
        classNames,
        index,
        fillColor,
        iconColor,
        strokeColor,
        badgeFillColor,
        badgeIconName,
        size,
        iconName,
        onStyleChange,
        doesBadgeBlink,
        lineStyle
    );
    const dateFormat = useMemo(() => {
        const dateFormat = internalisationDateFormat(intl);

        return dateFormat;
    }, [intl]);

    const locale = useMemo(() => {
        const locale = computeLocale(intl);

        return locale;
    }, [intl]);

    return (
        <div className={classNames('&')}>
            <div className={classNames('&-content', cls)}>
                <span className={classNames('&-content-bullet')} />
                {styleModifier}
                {isUndefinedStyleRule ? (
                    <ArgInputText
                        className={classNames('&-content-input-undefined')}
                        placeholder={messages?.undefinedStyleRulePlaceholder}
                        disabled={!!isUndefinedStyleRule}
                    />
                ) : (
                    <>
                        <ArgInputDate
                            value={left}
                            debounce={false}
                            format={dateFormat}
                            locale={locale}
                            onChange={(value, _) => onRangeValueChange?.(type, 'left', value, index)}
                            onClear={() => onRangeValueChange?.(type, 'left', null, index)}
                            placeholder={messages?.startDatePlaceholder}
                            className={classNames('&-start')}
                            autoFocus={true}
                            state={invalidLeft ? 'invalid' : undefined}
                        />
                        <ArgInputDate
                            value={right}
                            debounce={false}
                            format={dateFormat}
                            locale={locale}
                            onClear={() => onRangeValueChange?.(type, 'right', null, index)}
                            onChange={(value, _) => onRangeValueChange?.(type, 'right', value, index)}
                            placeholder={messages?.endDatePlaceholder}
                            className={classNames('&-end')}
                            state={invalidRight ? 'invalid' : undefined}
                        />
                    </>
                )}
            </div>

            {canBeRemoved && (
                <ArgButton
                    icon='icon-trash'
                    type='ghost'
                    className={classNames('&-trash-button')}
                    onClick={() => onRemoveComponent?.(type, index)}
                />
            )}
        </div>
    );
};
