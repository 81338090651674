import { Plugin } from '@ckeditor/ckeditor5-core';
import { ExplorationEditing } from '../exploration-editing';

export default class Exploration2ViewMode extends Plugin {
    /**
     * @inheritDoc
     */
    static get requires() {
        return [ExplorationEditing];
    }

    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'argonos-exploration:viewmode';
    }

    /**
     * @inheritDoc
     */
    init() {
        this._registerSchema();
        this._registerConverters();
    }

    /**
     * @private
     */
    _registerSchema() {
        this.editor.model.schema.extend('argonos-exploration', { allowAttributes: ['offsetX', 'offsetY', 'zoom'] });
    }

    _registerConverters() {
        const editor = this.editor;

        // Dedicated converter to propagate image's attribute to the img tag.
        editor.conversion.for('downcast').add(dispatcher => {
            dispatcher.on('attribute:offsetX', (evt, data, conversionApi) => {
                if (!conversionApi.consumable.consume(data.item, evt.name)) {
                    return;
                }

                const viewWriter = conversionApi.writer;
                const figure = conversionApi.mapper.toViewElement(data.item);

                if (data.attributeNewValue === null || data.attributeNewValue === undefined) {
                    viewWriter.removeAttribute('data-offset-x', figure);

                    return;
                }

                viewWriter.setAttribute('data-offset-x', data.attributeNewValue, figure);
            });
            dispatcher.on('attribute:offsetY', (evt, data, conversionApi) => {
                if (!conversionApi.consumable.consume(data.item, evt.name)) {
                    return;
                }

                const viewWriter = conversionApi.writer;
                const figure = conversionApi.mapper.toViewElement(data.item);
                if (data.attributeNewValue === null || data.attributeNewValue === undefined) {
                    viewWriter.removeAttribute('data-offset-y', figure);

                    return;
                }
                viewWriter.setAttribute('data-offset-y', data.attributeNewValue, figure);
            });
            dispatcher.on('attribute:zoom', (evt, data, conversionApi) => {
                if (!conversionApi.consumable.consume(data.item, evt.name)) {
                    return;
                }

                const viewWriter = conversionApi.writer;
                const figure = conversionApi.mapper.toViewElement(data.item);
                if (data.attributeNewValue === null || data.attributeNewValue === undefined) {
                    viewWriter.removeAttribute('data-zoom', figure);

                    return;
                }
                viewWriter.setAttribute('data-zoom', data.attributeNewValue, figure);
            });
        });

        editor.conversion.for('upcast')
            .attributeToAttribute({
                view: {
                    name: 'div',
                    attributes: {
                        'data-offset-x': /.+/,
                    },
                },
                model: {
                    key: 'offsetX',
                    value: viewElement => viewElement.getAttribute('data-offset-x'),
                },
            });
        editor.conversion.for('upcast')
            .attributeToAttribute({
                view: {
                    name: 'div',
                    styles: {
                        'data-offset-y': /.+/,
                    },
                },
                model: {
                    key: 'offsetY',
                    value: viewElement => viewElement.getStyle('data-offset-y'),
                },
            });
        editor.conversion.for('upcast')
            .attributeToAttribute({
                view: {
                    name: 'div',
                    styles: {
                        'data-zoom': /.+/,
                    },
                },
                model: {
                    key: 'zoom',
                    value: viewElement => viewElement.getStyle('data-zoom'),
                },
            });
    }
}
