import { defineMessages } from 'react-intl';

import { ArgCreateModal, ProgressMonitor, useCallbackAsync, useClassNames, useArgNotifications } from 'src/components/basic';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';
import { FormPolicy } from 'src/settings/models/form-policy';

import './edit-form-policy-modal.less';

export const messages = defineMessages({
    modalTitle: {
        id: 'settings.edit-form-policy-modal.title',
        defaultMessage: 'Edit form policy',
    },
    nameField: {
        id: 'settings.edit-form-policy-modal.name',
        defaultMessage: 'Name',
    },
    descriptionField: {
        id: 'settings.edit-form-policy-modal.field.description',
        defaultMessage: 'Description',
    },
    creationError: {
        id: 'settings.edit-form-policy-modal.error',
        defaultMessage: 'An error occurred while updating the form policy',
    },
});

export interface EditFormPolicyModalProps {
    closeModal: () => void;
    onSuccess: () => void;
    formPolicy: FormPolicy;
}

export const EditFormPolicyModal = ({
    closeModal,
    onSuccess,
    formPolicy,
}: EditFormPolicyModalProps) => {
    const classNames = useClassNames('settings-edit-form-policy-modal');
    const notifications = useArgNotifications();

    const [handleConfirm] = useCallbackAsync(async (progressMonitor: ProgressMonitor, name: string, description: string) => {
        try {
            await explorationSettingsConnector.putFormPolicy(formPolicy.id, {
                ...formPolicy,
                name,
                description,
            }, progressMonitor);

            onSuccess();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.creationError }, error as Error);

            throw error;
        }
    }, [onSuccess, formPolicy]);

    return (
        <ArgCreateModal
            className={classNames('&')}
            onClose={closeModal}
            onConfirm={handleConfirm}
            title={messages.modalTitle}
            nameFieldLabel={messages.nameField}
            descriptionFieldLabel={messages.descriptionField}
            initialName={formPolicy.name}
            initialDescription={formPolicy.description}
        />
    );
};
