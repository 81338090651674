import { FormikErrors } from 'formik';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';

import {
    ArgCombo,
    ArgFormLabel,
    ArgInputInteger,
    ArgInputText,
    ArgInputTextArea,
    ArgSwitch,
    ClassValue,
    useClassNames,
} from 'src/components/basic';
import { FormFields } from '../create-webhook-modal/create-webhook-modal';
import { HookBehaviorDetailLevel, HookBehaviorInteractivity } from '../../../models/detailed-webhooks';

import './webhook-identification.less';

const CLASSNAME = 'settings-webhook-identification';
const WEBHOOK_DESCRIPTION_MAXLENGTH = 1024;

const messages = defineMessages({
    identification: {
        id: 'settings.webhook-identification.identification',
        defaultMessage: 'Identification',
    },
    fieldName: {
        id: 'settings.webhook-identification.field.name',
        defaultMessage: 'Name',
    },
    fieldUrl: {
        id: 'settings.webhook-identification.field.url',
        defaultMessage: 'URL',
    },
    fieldConnectTimeout: {
        id: 'settings.webhook-identification.field.connectTimeout',
        defaultMessage: 'Connect timeout',
    },
    fieldReadTimeout: {
        id: 'settings.webhook-identification.field.readTimeout',
        defaultMessage: 'Read timeout',
    },
    fieldDescription: {
        id: 'settings.webhook-identification.field.description',
        defaultMessage: 'Description',
    },
    seconds: {
        id: 'settings.webhook-identification.seconds',
        defaultMessage: 'seconds',
    },
    optional: {
        id: 'settings.webhook-identification.field.optional',
        defaultMessage: 'optional',
    },
    webhookActive: {
        id: 'settings.webhook-identification.webhookActive',
        defaultMessage: 'This webhook is active',
    },
    interactivity: {
        id: 'settings.webhook-identification.interactivity',
        defaultMessage: 'Interactivity',
    },
    noneInteractivity: {
        id: 'settings.webhook-identification.noneInteractivity',
        defaultMessage: 'None',
    },
    noneInteractivityTooltip: {
        id: 'settings.webhook-identification.noneInteractivityTooltip',
        defaultMessage: 'Notification without waiting for a response from the server',
    },
    synchronizedInteractivity: {
        id: 'settings.webhook-identification.synchronizedInteractivity',
        defaultMessage: 'Synchronous',
    },
    synchronizedInteractivityTooltip: {
        id: 'settings.webhook-identification.synchronizedInteractivityTooltip',
        defaultMessage: 'Notification with waiting for a response from the server',
    },
    fullInteractivity: {
        id: 'settings.webhook-identification.fullInteractivity',
        defaultMessage: 'Full',
    },
    fullInteractivityTooltip: {
        id: 'settings.webhook-identification.fullInteractivityTooltip',
        defaultMessage: 'Notification with waiting for a response from the server and taking the response into account',
    },
    detailLevel: {
        id: 'settings.webhook-identification.detailLevel',
        defaultMessage: 'Detail level',
    },
    noneDetailLevel: {
        id: 'settings.webhook-identification.noneDetailLevel',
        defaultMessage: 'None',
    },
    noneDetailLevelTooltip: {
        id: 'settings.webhook-identification.noneDetailLevelTooltip',
        defaultMessage: 'Notification without sending any extra data',
    },
    minimalDetailLevel: {
        id: 'settings.webhook-identification.minimalDetailLevel',
        defaultMessage: 'Minimal',
    },
    minimalDetailLevelTooltip: {
        id: 'settings.webhook-identification.minimalDetailLevelTooltip',
        defaultMessage: 'Notification by providing essential data',
    },
    fullDetailLevel: {
        id: 'settings.webhook-identification.fullDetailLevel',
        defaultMessage: 'Full',
    },
    fullDetailLevelTooltip: {
        id: 'settings.webhook-identification.fullDetailLevelTooltip',
        defaultMessage: 'Notification by providing all available data',
    },
});

const WEBHOOK_BEHAVIOR_INTERACTIVITIES: Record<HookBehaviorInteractivity, MessageDescriptor> = {
    'None': messages.noneInteractivity,
    'Synchronized': messages.synchronizedInteractivity,
    'Full': messages.fullInteractivity,
};

const WEBHOOK_BEHAVIOR_INTERACTIVITIES_TOOLTIP: Record<HookBehaviorInteractivity, MessageDescriptor> = {
    'None': messages.noneInteractivityTooltip,
    'Synchronized': messages.synchronizedInteractivityTooltip,
    'Full': messages.fullInteractivityTooltip,
};

const WEBHOOK_BEHAVIOR_DETAIL_LEVELS: Record<HookBehaviorDetailLevel, MessageDescriptor> = {
    'None': messages.noneDetailLevel,
    'Minimal': messages.minimalDetailLevel,
    'Full': messages.fullDetailLevel,
};

const WEBHOOK_BEHAVIOR_DETAIL_LEVEL_TOOLTIPS: Record<HookBehaviorDetailLevel, MessageDescriptor> = {
    'None': messages.noneDetailLevelTooltip,
    'Minimal': messages.minimalDetailLevelTooltip,
    'Full': messages.fullDetailLevelTooltip,
};

const WEBHOOK_INTERACTIVITY_ITEMS: HookBehaviorInteractivity[] = ['None', 'Synchronized', 'Full'];
const WEBHOOK_DETAIL_LEVEL_ITEMS: HookBehaviorDetailLevel[] = ['None', 'Minimal', 'Full'];

export interface WebhookIdentificationProps {
    className?: ClassValue;
    formValues: FormFields;
    formErrors: FormikErrors<FormFields>;
    onInputChange: (fieldName: string, value: string | number | undefined) => void;
    onEnabledChange: (isEnabled: boolean) => void;
}

export function WebhookIdentification(props: WebhookIdentificationProps) {
    const { className, formValues, formErrors, onInputChange, onEnabledChange } = props;
    const classNames = useClassNames(CLASSNAME);

    return (
        <div className={classNames('&', className)} data-testid='webhook-identification'>
            <ArgSwitch
                label={messages.webhookActive}
                size='medium'
                checked={formValues.isEnabled}
                onChange={onEnabledChange}
                className={classNames('&-enabled')}
            />
            <span className={classNames('&-title')}>
                <FormattedMessage {...messages.identification} />
            </span>
            <ArgFormLabel
                propertyName={messages.fieldName}
                className={classNames('&-label')}
            >
                <ArgInputText
                    value={formValues.name}
                    onInputChange={(value) => onInputChange('name', value)}
                    data-testid='name'
                    state={formErrors.name ? 'invalid' : undefined}
                    autoFocus={true}
                />
            </ArgFormLabel>
            <ArgFormLabel
                propertyName={messages.fieldUrl}
                className={classNames('&-label')}
                addedRow={true}
            >
                <ArgInputText
                    value={formValues.url}
                    onInputChange={(value) => onInputChange('url', value)}
                    data-testid='url'
                    state={formErrors.url ? 'invalid' : undefined}
                />
            </ArgFormLabel>

            <div className={classNames('&-behaviors')}>
                <ArgFormLabel
                    propertyName={messages.fieldConnectTimeout}
                    className={classNames('&-label')}
                >
                    <div className={classNames('&-timeout')}>
                        <ArgInputInteger
                            data-testid='connectTimeout'
                            value={formValues.connectTimeout}
                            state={formErrors.connectTimeout ? 'invalid' : undefined}
                            className={classNames('&-timeout-input')}
                            onInputChange={(value) => onInputChange('connectTimeout', value)}
                        />
                        <p className={classNames('&-timeout-unit')}>
                            <FormattedMessage {...messages.seconds} />
                        </p>
                    </div>
                </ArgFormLabel>

                <ArgFormLabel
                    propertyName={messages.interactivity}
                    className={classNames('&-label')}
                >
                    <ArgCombo<HookBehaviorInteractivity>
                        className={classNames('&-interactivity')}
                        initialValue='None'
                        items={WEBHOOK_INTERACTIVITY_ITEMS}
                        value={formValues?.interactivity}
                        getItemLabel={(item) => {
                            return WEBHOOK_BEHAVIOR_INTERACTIVITIES[item];
                        }}
                        getItemTooltip={(item) => {
                            return WEBHOOK_BEHAVIOR_INTERACTIVITIES_TOOLTIP[item];
                        }}
                        onChange={(value) => onInputChange('interactivity', value)}
                    />
                </ArgFormLabel>
                <ArgFormLabel
                    propertyName={messages.detailLevel}
                    className={classNames('&-label')}
                >
                    <ArgCombo<HookBehaviorDetailLevel>
                        initialValue='None'
                        className={classNames('&-detail-level')}
                        items={WEBHOOK_DETAIL_LEVEL_ITEMS}
                        value={formValues?.detailLevel}
                        getItemLabel={(item) => {
                            return WEBHOOK_BEHAVIOR_DETAIL_LEVELS[item];
                        }}
                        getItemTooltip={(item) => {
                            return WEBHOOK_BEHAVIOR_DETAIL_LEVEL_TOOLTIPS[item];
                        }}
                        onChange={(value) => onInputChange('detailLevel', value)}
                    />
                </ArgFormLabel>
            </div>

            <ArgFormLabel
                propertyName={messages.fieldDescription}
                className={classNames('&-label')}
                addedRow={true}
                required={messages.optional}
            >
                <ArgInputTextArea
                    rows={8}
                    data-testid='description'
                    value={formValues.description}
                    maxLength={WEBHOOK_DESCRIPTION_MAXLENGTH}
                    state={formErrors.description ? 'invalid' : undefined}
                    onInputChange={(value) => onInputChange('description', value)}
                />
            </ArgFormLabel>
        </div>
    );
}
