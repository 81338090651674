import { SettingsEnvironmentContext } from '../../settings/envrionment-contexts/settings-environment-context';
import { registerRolesToolItems } from '../../settings/roles';
import { registerConfigurationToolItems } from '../../settings/configuration';
import { registerExtensionToolItems } from '../../settings/extensions';
import { registerToolItem } from '../../components/basic/arg-toolbar/tools-registry';
import { ArgonosModule } from '../../components/application/modules';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME } from '../../settings/envrionment-contexts/context-names';


export function setupSettings(preparationModule: ArgonosModule) {
    registerToolItem<SettingsEnvironmentContext>(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path: 'modules/preparation',
        order: 200,
        type: 'group',
        icon: preparationModule.iconURL,
        visible: !!preparationModule.enabled,
        label: preparationModule.name,
    });

    registerRolesToolItems('modules/preparation/roles', 100, preparationModule.scope);
    registerConfigurationToolItems('modules/preparation/configuration', 200, preparationModule.scope);
    registerExtensionToolItems('modules/preparation/extensions', 300, 'externalComponents');
}
