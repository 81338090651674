import { Avatar } from 'antd';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import retentionPolicyConnector from 'src/settings/connectors/retention-policy-connector';
import { RetentionPolicyActionTargetKind, RetentionPolicyVertexEdge } from 'src/settings/universes/retention/types';
import {
    ArgCombo,
    ArgIcon,
    ClassValue,
    ProgressMonitor,
    useArgModalContext,
    useClassNames,
    useEffectAsync,
} from 'src/components/basic';
import { CreatePropertyModal } from '../property-and-metaproperty-modals/property-modals/create-property-modal';
import { PropertiesList } from '../property-components/properties-list';
import { DEFAULT_VERTEX_STYLE } from 'src/exploration/constants/default-vertex-style';
import { RenameEdgeModal } from '../rename-edge-modal/rename-edge-modal';
import { FullOntology, FullOntologyLinkType, FullOntologyObjectType } from '../../types';
import { VertexOrEdge } from 'src/exploration/hooks/use-graph-style-customisation';
import { ObjectPropertiesPanelMenu } from '../object-properties-panel-menu/object-properties-panel-menu';

import './edge-properties-panel.less';

enum ArrowType {
    left = 'left',
    right = 'right',
}

type ArrowTypeDetails = {
    label: string;
    icon: string;
};
const ARROWS: Record<ArrowType, ArrowTypeDetails> = {
    left: { label: 'left', icon: 'icon-long-arrow-left' },
    right: { label: 'right', icon: 'icon-long-arrow-right' },
};

const arrows = Object.keys(ARROWS) as ArrowType[];

const messages = defineMessages({
    title: {
        id: 'settings.edge-properties.menu.title',
        defaultMessage: 'Edge properties',
    },
    deletionAutomatic: {
        id: 'settings.edge-properties.menu.deletionAutomatic',
        defaultMessage: 'Automatic deletion: {rule}',
    },
    connection: {
        id: 'settings.edge-properties.menu.connection',
        defaultMessage: 'Connection',
    },
    definition: {
        id: 'settings.edge-properties.menu.definition',
        defaultMessage: 'Properties',
    },
    newProperty: {
        id: 'settings.edge-properties.menu.new-property',
        defaultMessage: 'New property',
    },
    fetchingRetentionError: {
        id: 'settings.edge-properties.fetch-retention.message',
        defaultMessage: 'Loading retention',
    },
});

const MODAL_CREATE_PROPERTY_ID = 'create-property';
const MODAL_RENAME_EDGE_ID = 'rename-edge';
const DEFAULT_POLICIES: RetentionPolicyVertexEdge = {
    effects: [],
};

export interface EdgePropertiesPanelProps {
    className?: ClassValue;
    edge: FullOntologyLinkType;
    fromVertex?: FullOntologyObjectType;
    toVertex?: FullOntologyObjectType;
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
    retentionSetUp: (retention: RetentionPolicyVertexEdge, type: RetentionPolicyActionTargetKind | 'Property', targetProperty?: string) => string;
    retention: RetentionPolicyVertexEdge;
    setRetention: Dispatch<SetStateAction<RetentionPolicyVertexEdge>>;
}

export function EdgePropertiesPanel(props: EdgePropertiesPanelProps) {
    const {
        className,
        edge,
        fromVertex,
        toVertex,
        ontology,
        setOntology,
        retentionSetUp,
        retention,
        setRetention,
    } = props;

    const classNames = useClassNames('edge-properties-panel');
    const modalContext = useArgModalContext();
    const [selectedArrow, setSelectedArrow] = useState<ArrowType>(ArrowType.right);

    const handleRenameEdgeModal = useCallback(() => {
        modalContext.open(MODAL_RENAME_EDGE_ID,
            <RenameEdgeModal
                closeModal={() => modalContext.close(MODAL_RENAME_EDGE_ID)}
                edge={edge}
                ontology={ontology}
                setOntology={setOntology}
            />
        );
    }, [edge, modalContext, ontology, setOntology]);

    const handleCreatePropertyModal = useCallback(() => {
        modalContext.open(MODAL_CREATE_PROPERTY_ID,
            <CreatePropertyModal
                propertyList={edge.properties}
                closeModal={() => modalContext.close(MODAL_CREATE_PROPERTY_ID)}
                edgeOrVertex={edge}
                ontology={ontology}
                setOntology={setOntology}
                propertyOf={VertexOrEdge.Edge}
            />
        );
    }, [edge, modalContext, ontology, setOntology]);

    const _retentionSetUp = useMemo(() => retentionSetUp(retention, 'Edge'), [retention, retentionSetUp]);

    useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const edgeRetention = await retentionPolicyConnector.getRetentionPolicyByEdgeOrVertex({
                ontologyId: ontology.id,
                edgeOrVertexName: edge.name,
                retentionLinkKind: 'link-types',
            });
            setRetention(edgeRetention);
        } catch (error) {
            setRetention(DEFAULT_POLICIES);
            if (progressMonitor.isCancelled) {
                throw error;
            }
        }
    }, [ontology.id, edge.name], messages.fetchingRetentionError, 1, {
        global: true,
    });

    return (
        <div className={classNames('&-body', className)}>
            <div className={classNames('&-header')}>
                <h4 className={classNames('&-header-title')}>
                    {edge.displayName}
                </h4>
                <ObjectPropertiesPanelMenu
                    ontology={ontology}
                    edgeOrVertex={edge}
                    editVertexOrEgde={handleRenameEdgeModal}
                    retentionTarget='Edge'
                    retentionLink='link-types'
                    retention={retention}
                    setRetention={setRetention}
                />
            </div>
            <div className={classNames('&-name-row')}>
                <div className={classNames('&-name-title')}>
                    <FormattedMessage
                        {...messages.deletionAutomatic}
                        values={{ rule: _retentionSetUp }}
                    />
                </div>
            </div>
            <div className={classNames('&-connection-row')}>
                <Avatar
                    size='large'
                    icon={
                        <ArgIcon
                            name={`${fromVertex?.style?.iconName || DEFAULT_VERTEX_STYLE.iconName}`}
                            size='large'
                        />
                    }
                    style={{
                        backgroundColor:
                            fromVertex?.style?.fillColor ||
                            DEFAULT_VERTEX_STYLE.fillColor,
                    }}
                />
                <ArgCombo<ArrowType>
                    items={arrows}
                    value={selectedArrow}
                    onChange={(arrow) => {
                        setSelectedArrow(arrow);
                    }}
                    className={classNames('&-arrow-dropdown')}
                    renderItem={(item) => {
                        const arrow = ARROWS[item];

                        return <ArgIcon name={arrow.icon} size='small' />;
                    }}
                    renderInput={() => {
                        const arrow = ARROWS[selectedArrow];

                        return <ArgIcon name={arrow.icon} size='small' />;
                    }}
                    right=''
                    readOnly={true}
                />
                <Avatar
                    size='large'
                    icon={
                        <ArgIcon
                            name={`${toVertex?.style?.iconName || DEFAULT_VERTEX_STYLE.iconName}`}
                            size='large'
                        />
                    }
                    style={{
                        backgroundColor:
                            toVertex?.style?.fillColor ||
                            DEFAULT_VERTEX_STYLE.fillColor,
                    }}
                />
            </div>
            <div className={classNames('&-definition-subtitle-row')}>
                <label><FormattedMessage {...messages.definition} /></label>
                <button
                    type='button'
                    className={classNames('&-new-property-button')}
                    onClick={handleCreatePropertyModal}
                >
                    <ArgIcon name='icon-add-outline' className={classNames('&-plus-sign')} />
                    <span><FormattedMessage {...messages.newProperty} /></span>
                </button>
            </div>
            <PropertiesList
                propertyList={edge.properties}
                edgeOrVertex={edge}
                propertyOf={VertexOrEdge.Edge}
                ontology={ontology}
                setOntology={setOntology}
                retentionSetUp={retentionSetUp}
                retention={retention}
                setRetention={setRetention}
                retentionLink='link-types'
            />
        </div>
    );
}

