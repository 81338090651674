import { defineMessages } from 'react-intl';

import { useCallbackAsync, useClassNames, useArgNotifications } from 'src/components/basic';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';
import { ValuationPolicy } from 'src/settings/models/valuation-policy';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';

import './valuation-policies-deletion-modal.less';

const messages = defineMessages({
    deleteTitle: {
        id: 'settings.valuation-policies.deletion-modal.deleteTitle',
        defaultMessage: 'Delete this valuation policy',
    },
    deleteMessage: {
        id: 'settings.valuation-policies.deletion-modal.deleteMessage',
        defaultMessage: 'Are you sure you want to delete the valuation policy "{name}"?',
    },
    deleteMultipleTitle: {
        id: 'settings.valuation-policies.deletion-modal.deleteMultipleTitle',
        defaultMessage: 'Delete {count} valuation policies',
    },
    deleteMultipleMessage: {
        id: 'settings.valuation-policies.deletion-modal.deleteMultipleMessage',
        defaultMessage: 'Are you sure you want to delete these {count} valuation policies?',
    },
    deleteError: {
        id: 'settings.valuation-policies.deletion-modal.deleteError',
        defaultMessage: 'An error occurred while deleting the {count, plural, one {valuation policy} other {valuation policies}}',
    },
    delete: {
        id: 'settings.valuation-policies.deletion-modal.delete',
        defaultMessage: 'Delete',
    },
});

interface ValuationPoliciesDeletionModalProps {
    valuationPolicies: ValuationPolicy[];
    onSuccess?: () => void;
    onClose: () => void;
}

export function ValuationPoliciesDeletionModal(props: ValuationPoliciesDeletionModalProps) {
    const { valuationPolicies, onClose, onSuccess } = props;

    const classNames = useClassNames('settings-valuation-policies-deletion-modal');
    const notifications = useArgNotifications();

    const [onDelete] = useCallbackAsync(async (progressMonitor) => {
        try {
            if (valuationPolicies.length === 1) {
                const valuationPolicyId = valuationPolicies[0].id;
                await explorationSettingsConnector.deleteValuationPolicy(valuationPolicyId, progressMonitor);
            } else {
                const valuationPolicyIds = valuationPolicies.map((valuationPolicy) => valuationPolicy.id);
                // TODO: uncomment when the backend is ready
                // await settingsConnector.deleteValuationPolicies(valuationPolicyIds, progressMonitor);
            }
            onSuccess?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.deleteError }, error as Error, { count: valuationPolicies.length });
            throw error;
        }
    }, [onSuccess, valuationPolicies]);

    const title = props.valuationPolicies.length > 1 ? messages.deleteMultipleTitle : messages.deleteTitle;
    const message = props.valuationPolicies.length > 1 ? messages.deleteMultipleMessage : messages.deleteMessage;

    const messageValues = {
        count: props.valuationPolicies.length,
        name: props.valuationPolicies.length === 1 ? props.valuationPolicies[0].name : undefined,
    };

    return (
        <ConfirmModal
            className={classNames('&')}
            type='delete'
            title={title}
            alertMessage={message}
            messageValues={messageValues}
            onConfirm={onDelete}
            onClose={onClose}
            confirmText={messages.delete}
        />
    );
}
