import { ReactNode, useCallback, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useParams } from 'react-router-dom';

import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';
import { PageHeader } from 'src/settings/common-components/page-header';
import { ProgressMonitor, useClassNames, useMemoAsync, useArgNotifications } from 'src/components/basic';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { TableToolbar } from '../components/table-toolbar/table-toolbar';
import { ValuationPoliciesTable } from '../components/valuation-policies-table/valuation-policies-table';

import './valuation-policies-view.less';

const FORCE_LOADING = false;

const messages = defineMessages({
    valuationPolicies: {
        id: 'settings.valuation-policies-page.title',
        defaultMessage: 'Valuation policies',
    },
    fetchingValuationPolicies: {
        id: 'settings.valuationPolicies.fetch-valuation-policies',
        defaultMessage: 'Fetching valuation policies...',
    },
    fetchingValuationPoliciesError: {
        id: 'settings.valuationPolicies.fetch-valuation-policies.error',
        defaultMessage: 'An error occurred while fetching valuation policies',
    },
});

export function ValuationPoliciesView() {
    const classNames = useClassNames('settings-valuation-policies-view');
    const [stateId, setStateId] = useState<number>(0);
    const [search, setSearch] = useState<string | undefined>();

    const { universeId, ontologyId } = useParams<{ ontologyId: string; universeId: string }>();

    const notifications = useArgNotifications();

    const [valuationPolicies, fetchValuationPoliciesPM] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        if (!universeId) {
            return [];
        }
        try {
            const ret = await explorationSettingsConnector.getValuationPolicies(universeId, progressMonitor);

            return ret;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.fetchingValuationPoliciesError }, error as Error);
            throw error;
        }
    }, [notifications, universeId, stateId], messages.fetchingValuationPolicies);

    const handleRefresh = useCallback(() => {
        setStateId((id) => id + 1);
    }, []);

    let body: ReactNode = null;

    if (FORCE_LOADING || fetchValuationPoliciesPM?.isRunning || !valuationPolicies) {
        body = (
            <div className={classNames('&-body', 'loading')}>
                <LoadingPane className={classNames('&-pane')} progressMonitor={fetchValuationPoliciesPM} />
            </div>
        );
    } else {
        body = <div className={classNames('&-body')}>
            <TableToolbar
                className={classNames('&-body-toolbar')}
                onCreatePolicy={handleRefresh}
                setSearch={setSearch}
            />
            <div className={classNames('&-body-table-container')}>
                <ValuationPoliciesTable
                    className={classNames('&-body-table')}
                    valuationPolicies={valuationPolicies}
                    search={search}
                    handleRefresh={handleRefresh}
                    ontologyId={ontologyId}
                    universeId={universeId}
                />
            </div>
        </div>;
    }

    return (
        <div className={classNames('&')}>
            <PageHeader
                className={classNames('&-header')}
                title={messages.valuationPolicies}
            />

            {body}
        </div>
    );
}
