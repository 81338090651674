import { defineMessages } from 'react-intl';

import { NavItem } from '../models/navigation';
import { registerToolItem } from '../../components/basic/arg-toolbar/tools-registry';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME } from '../envrionment-contexts/context-names';
import { SettingsEnvironmentContext } from '../envrionment-contexts/settings-environment-context';
import { Tool } from '../../components/basic';

export const VISUAL_IDENTITY_PATH = '/settings/visual-identity';

export const messages = defineMessages({
    visualIdentity: {
        id: 'settings.visual-identity.menu.title',
        defaultMessage: 'Visual identity',
    },
});

export const visualIdentityNavItem: NavItem[] = [
    {
        path: VISUAL_IDENTITY_PATH,
        label: messages.visualIdentity,
        icon: 'icon-travel_explore',
        iconSize: 18,
        children: [],
    } as NavItem,
];

export function registerVisualIdentityToolItem(path: string, order: number): void {
    registerToolItem(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path,
        order,
        label: messages.visualIdentity,
        icon: 'icon-travel_explore',
        onClick: (tool: Tool<SettingsEnvironmentContext>, environmentContext: SettingsEnvironmentContext) => {
            environmentContext.navigate(VISUAL_IDENTITY_PATH);
        },
    });
}

export function setupVisualIdentity() {
    registerVisualIdentityToolItem('global/visual-identity', 200);
}
