import { merge } from 'lodash';
import type { Items, Link, LinkFlowOptions } from 'regraph';

import { DEFAULT_EDGE_STYLE } from 'src/exploration/constants/default-edge-style';
import { LineAnimation } from 'src/components/common/graph/customisation/graph-style';
import { BlobImagesProvider } from 'src/utils/blob-images-provider';
import { ImageAlignmentProvider } from 'src/utils/image-alignment-provider';
import { FullOntology } from '../ontology/types';
import { buildReGraphNode } from '../../../exploration/utils/universes';
import { ThumbnailSize } from 'src/exploration/model/thumbnail-size';
import { getEdgeColor } from '../ontology/components/design-properties-panel/utils';

export function getGraphItemsForOntology(
    ontology: FullOntology | undefined,
    graphItemsSelected: string[],
    blobImagesProvider: BlobImagesProvider,
    objectHoveredOver: string,
    imageAlignmentProvider: ImageAlignmentProvider
): Items {
    if (!ontology) {
        return {};
    }
    const graphItems: Items = {};
    const vertices = ontology.objectTypes;
    const edges = ontology.linkTypes;

    vertices?.forEach((vertex) => {
        const isVertexHoveredOver = vertex.name === objectHoveredOver;
        const isVertexSelected = graphItemsSelected.includes(vertex.name);
        const graphNode = buildReGraphNode(
            vertex,
            ontology,
            vertex.displayName,
            isVertexSelected,
            undefined,
            blobImagesProvider,
            imageAlignmentProvider,
            ThumbnailSize.Small,
            true
        );
        if (isVertexSelected || isVertexHoveredOver) {
            graphNode.glyphs = [
                ...(graphNode.glyphs || []),
                {
                    label: { text: '+', color: 'white' },
                    color: 'black',
                    angle: 30,
                },
            ];
        }
        graphItems[vertex.name] = graphNode;
    });

    edges.forEach((edge) => {
        const isEdgeSelected = graphItemsSelected.includes(edge.name);
        const edgeStyle = merge({}, DEFAULT_EDGE_STYLE, edge?.style);

        let flow: boolean | LinkFlowOptions | undefined;
        if (edgeStyle.lineAnimation === LineAnimation.Fast) {
            flow = { velocity: 7 };
        } else if (edgeStyle.lineAnimation === LineAnimation.Slow) {
            flow = { velocity: 3 };
        }
        const graphNode: Link = {
            id1: edge.sourceObjectName,
            id2: edge.destinationObjectName,
            end2: { arrow: true },
            color: getEdgeColor(edgeStyle),
            width: isEdgeSelected ? 2 * edgeStyle.size : edgeStyle.size,
            lineStyle: edgeStyle.lineStyle.toLowerCase() as 'solid' | 'dashed' | 'dotted',
            flow,
            label: {
                text: edge.displayName,
                backgroundColor: 'white',
                fontFamily: 'Roboto',
                fontSize: 12,
                color: 'black',
                bold: isEdgeSelected,
            },
        };
        graphItems[edge.name] = graphNode;
    });

    return graphItems;
}
