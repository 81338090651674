import { useContext, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ArgButton, ArgModal, useClassNames, useArgNotifications } from 'src/components/basic';
import { Group } from 'src/model/user';
import { UsersAndGroupsStateContext } from 'src/settings/users/providers/usersState';
import { NotifyBox } from 'src/settings/common-modals/notify-box/notify-box';
import { WARNING_ORANGE } from 'src/settings/models/colors';
import userGroupsConnector from 'src/settings/connectors/user-groups-connector';

import './single-group-delete-modal.less';

interface DeleteGroupModalProps {
    closeModal: () => void;
    group: Group;
}

export const messages = defineMessages({
    title: {
        id: 'settings.delete-group-modal.title',
        defaultMessage: 'Delete a group',
    },
    description: {
        id: 'settings.delete-group-modal.description',
        defaultMessage: 'Are you sure you want to delete the group?',
    },
    cancel: {
        id: 'settings.delete-group-modal.button.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.delete-group-modal.button.delete',
        defaultMessage: 'Delete',
    },
    deleteGroupErrorMsg: {
        id: 'settings.delete-group-modal.error-message',
        defaultMessage: 'Something went wrong while deleting the group',
    },
    warning: {
        id: 'settings.delete-group-modal.warning',
        defaultMessage: 'The group "{groupname}" will be permanently deleted.',
    },
});

export const DeleteGroupModal: React.FC<DeleteGroupModalProps> = ({
    closeModal,
    group,
}) => {
    const intl = useIntl();
    const classNames = useClassNames('delete-group-modal');
    const [loading, setLoading] = useState(false);
    const { setGroups } = useContext(UsersAndGroupsStateContext);
    const notifications = useArgNotifications();

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await userGroupsConnector.deleteGroup(group.id);
            setGroups((currentGroups) =>
                currentGroups.filter((existingGroup) => existingGroup.id !== group.id)
            );
            setLoading(false);
            closeModal();
        } catch (error) {
            notifications.snackError({ message: messages.deleteGroupErrorMsg }, error as Error);
            setLoading(false);
        }
    };

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={closeModal}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={closeModal}
                        label={messages.cancel}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        onClick={() => handleSubmit()}
                        label={messages.delete}
                        data-testid='delete'
                        loading={loading}
                    />
                </div>
            }
        >
            <NotifyBox
                icon='icon-exclamation-point'
                color={WARNING_ORANGE}
                text={messages.description}
                subtext={messages.warning}
                messageValues={{ groupname: group.name }}
            />
        </ArgModal>
    );
};
