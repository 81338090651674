import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import { defineMessages } from 'react-intl';

import { ArgModal, useCallbackAsync, useClassNames, useArgNotifications } from 'src/components/basic';
import { WebhookIdentification } from '../webhook-identification/webhook-identification';
import { WebhookEvents } from '../webhook-events/webhook-events';
import {
    DetailedWebHook,
    DetailedWebHookRequest,
    HookBehaviorDetailLevel,
    HookBehaviorInteractivity,
    WebhookEvent,
    WebHookHeaders,
} from 'src/settings/models/detailed-webhooks';
import WebhookConnector from 'src/settings/connectors/webhook-connector';
import { isResponseError } from '../../../../components/basic/utils/response-error';

import './create-webhook-modal.less';

const INCORRECT_URL_ERROR = 'https://problemdetails.chapsvisioncloud.com/argonos/framework/problemwebhookincorrecttargeturl';
const DETAIL_LEVEL_ERROR = 'https://problemdetails.chapsvisioncloud.com/argonos/framework/problemunacceptabledetaillevel';
const INTERACTIVITY_ERROR = 'https://problemdetails.chapsvisioncloud.com/argonos/framework/problemunacceptableinteractivity';
const CLASSNAME = 'settings-create-webhook-modal';

const messages = defineMessages({
    createTitle: {
        id: 'settings.create-webhook-modal.title',
        defaultMessage: 'Create a webhook',
    },
    editTitle: {
        id: 'settings.create-webhook-modal.editTitle',
        defaultMessage: 'Edit a webhook',
    },
    cancel: {
        id: 'settings.create-webhook-modal.cancel',
        defaultMessage: 'Cancel',
    },
    next: {
        id: 'settings.create-webhook-modal.next',
        defaultMessage: 'Next',
    },
    previous: {
        id: 'settings.create-webhook-modal.previous',
        defaultMessage: 'Previous',
    },
    submit: {
        id: 'settings.create-webhook-modal.submit',
        defaultMessage: 'Submit',
    },
    createWebhookError: {
        id: 'settings.create-webhook-modal.createWebhookError',
        defaultMessage: 'An error occurred while creating the webhook',
    },
    updateWebhookError: {
        id: 'settings.create-webhook-modal.updateWebhookError',
        defaultMessage: 'An error occurred while updating the webhook',
    },
    incorrectUrlError: {
        id: 'settings.create-webhook-modal.incorrectUrlError',
        defaultMessage: 'Webhook in error, please check the URL of the webhook and try again',
    },
    unacceptableDetailLevelErrorTitle: { // fix here and below
        id: 'settings.create-webhook-modal.unacceptableDetailLevelErrorTitle',
        defaultMessage: 'The level of detail specified is incompatible with the associated {eventsCount, plural, =1 {event} other {events}}, please select a valid level of detail and try again',
    },
    unacceptableInteractivityErrorTitle: {
        id: 'settings.create-webhook-modal.unacceptableInteractivityErrorTitle',
        defaultMessage: 'The specified interactivity is incompatible with the associated {eventsCount, plural, =1 {event} other {events}}, please select a valid interactivity',
    },
});

export interface FormFields {
    name?: string;
    url?: string;
    isEnabled: boolean;
    connectTimeout?: number;
    readTimeout?: number;
    description?: string;
    detailLevel?: HookBehaviorDetailLevel;
    interactivity?: HookBehaviorInteractivity;
    events?: WebhookEvent[];
    headers?: WebHookHeaders;
}

type FormFieldsErrors = Partial<Record<keyof FormFields, boolean>>;

export interface CreateWebhookModalProps {
    webhook?: Partial<DetailedWebHook>;
    onClose: () => void;
    onSuccess?: () => void;
}

export function CreateWebhookModal(props: CreateWebhookModalProps) {
    const { webhook, onClose, onSuccess } = props;
    const classNames = useClassNames(CLASSNAME);

    const notifications = useArgNotifications();

    const [submitForm, submitProgressMonitor] = useCallbackAsync(async (progressMonitor, values: FormFields) => {
        if (!values.name || !values.url || values.connectTimeout === undefined) {
            return;
        }

        try {
            if (!webhook?.id) {
                const createWebhookPayload: DetailedWebHookRequest = {
                    name: values.name,
                    target: {
                        url: values.url,
                        connectTimeout: values.connectTimeout * 1000,
                        headers: values.headers,
                    },
                    behavior: {
                        detailLevel: values?.detailLevel,
                        interactivity: values?.interactivity,
                        ignoreError: undefined,
                    },
                    isEnabled: values.isEnabled,
                    description: values.description,
                    events: values.events ?? [],
                };
                await WebhookConnector.getInstance().createWebhook(createWebhookPayload, progressMonitor);
            } else {
                const updateWebhookPayload: DetailedWebHookRequest = {
                    ...webhook,
                    name: values.name,
                    isEnabled: values.isEnabled,
                    description: values.description,
                    events: values.events ?? [],
                    target: {
                        ...webhook.target,
                        url: values.url,
                        connectTimeout: values.connectTimeout * 1000,
                        headers: values.headers,
                    },
                    behavior: {
                        ...webhook.behavior,
                        detailLevel: values?.detailLevel,
                        interactivity: values?.interactivity,
                    },
                };
                await WebhookConnector.getInstance().putWebhook(webhook.id, updateWebhookPayload, progressMonitor);
            }
            onClose();
            onSuccess?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            if (isResponseError(error)) {
                const problemDetails = error.problemDetails;
                const eventsCount = values.events?.length ?? 0;
                switch (problemDetails?.type) {
                    case INCORRECT_URL_ERROR:
                        notifications.snackError({ message: messages.incorrectUrlError });
                        throw error;
                    case DETAIL_LEVEL_ERROR:
                        notifications.snackError({ message: messages.unacceptableDetailLevelErrorTitle }, undefined, { eventsCount });
                        throw error;
                    case INTERACTIVITY_ERROR:
                        notifications.snackError({ message: messages.unacceptableInteractivityErrorTitle }, undefined, { eventsCount });
                        throw error;
                }
            }
            notifications.snackError({ message: webhook ? messages.updateWebhookError : messages.createWebhookError }, error as Error);
            throw error;
        }
    }, [notifications, onClose, onSuccess, webhook]);

    const validateForm = useCallback((values: FormFields) => {
        const errors: FormFieldsErrors = {};
        if (!values.name) {
            errors.name = true;
        }
        if (!values.url) {
            errors.url = true;
        }
        if (values.connectTimeout === undefined) {
            errors.connectTimeout = true;
        }
        if (!values.readTimeout === undefined) {
            errors.readTimeout = true;
        }

        return errors;
    }, []);

    const formInitialValues = useMemo<FormFields>(() => {
        if (!webhook) {
            return {
                isEnabled: true,
                detailLevel: 'None',
                interactivity: 'None',
            };
        }

        return {
            ...webhook,
            url: webhook.target?.url,
            connectTimeout: (webhook.target?.connectTimeout ?? 0) / 1000,
            readTimeout: (webhook.target?.readTimeout ?? 0) / 1000,
            isEnabled: webhook.isEnabled ?? false,
            headers: webhook.target?.headers,
            detailLevel: webhook.behavior?.detailLevel,
            interactivity: webhook.behavior?.interactivity,
        };
    }, [webhook]);

    const {
        handleSubmit,
        handleChange,
        setFieldValue,
        values: formValues,
        errors: formErrors,
        resetForm,
    } = useFormik<FormFields>({
        initialValues: formInitialValues,
        validateOnChange: false,
        validate: validateForm,
        onSubmit: submitForm,
    });

    const handleEnabledChange = useCallback((isEnabled: boolean) => {
        handleChange('isEnabled');
        setFieldValue('isEnabled', isEnabled);
    }, [handleChange, setFieldValue]);

    const handleInputChange = useCallback((fieldName: string, value: string | number | undefined) => {
        handleChange(fieldName);
        setFieldValue(fieldName, value);
    }, [handleChange, setFieldValue]);

    const handleEventsChange = useCallback((events: WebhookEvent[]) => {
        setFieldValue(('events'), events);
        handleChange('events');
    }, [handleChange, setFieldValue]);

    const handleCancel = useCallback(() => {
        resetForm();
        onClose();
    }, [resetForm, onClose]);

    const handleHeaderChange = useCallback((headers: WebHookHeaders) => {
        setFieldValue('headers', headers);
    }, [setFieldValue]);

    const submitDisabled = !formValues.name || !formValues.url || formValues.connectTimeout === undefined || !formValues.readTimeout === undefined;

    return (
        <ArgModal
            size='xlarge'
            title={webhook ? messages.editTitle : messages.createTitle}
            visible={true}
            onClose={handleCancel}
            onCancel={handleCancel}
            cancelText={messages.cancel}
            okText={messages.submit}
            onOk={() => handleSubmit()}
            okDisabled={submitDisabled}
            progressMonitor={submitProgressMonitor}
            className={classNames('&')}
        >
            <form
                autoComplete='off'
                className={classNames('&-form-container')}
                onSubmit={() => handleSubmit()}
            >
                <WebhookIdentification
                    formValues={formValues}
                    formErrors={formErrors}
                    onInputChange={handleInputChange}
                    onEnabledChange={handleEnabledChange}
                />
                <WebhookEvents
                    events={formValues.events}
                    onEventsChange={handleEventsChange}
                    onHeadersChange={handleHeaderChange}
                    headers={formValues.headers}
                />
            </form>

        </ArgModal>
    );
}
