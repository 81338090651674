import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { PageHeader } from 'src/settings/common-components/page-header';
import { Policy } from 'src/settings/models/dtoApi';
import { useClassNames, useArgNotifications, useProgressMonitor } from 'src/components/basic';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { PoliciesList } from '../components/policies-list';
import { TableToolbar } from '../components/table-toolbar';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';

import './policies-view.less';

const messages = defineMessages({
    policies: {
        id: 'settings.policies-page.title',
        defaultMessage: 'Policies',
    },
    fetchingPolicies: {
        id: 'settings.policy.fetch-policies.message',
        defaultMessage: 'Loading policies',
    },
    fetchingPoliciesError: {
        id: 'settings.policy.fetch-policies.error',
        defaultMessage: 'An error occurred while fetching the policies',
    },
    defaultAccess: {
        id: 'settings.policies-page.defaultAccess',
        defaultMessage:
            'Access to the data is restricted by default, please define the policies to access it.',
    },
});

export const PoliciesView = () => {
    const notifications = useArgNotifications();
    const classNames = useClassNames('policies-view');

    const [buttonSelected, setButtonSelected] = useState<string>('');
    const [fetchPolicyMonitor, createFetchPolicyMonitor] = useProgressMonitor();
    const [policies, setPolicies] = useState<Policy[]>([]);
    const [search, setSearch] = useState<string | null>('');

    const { universeId } = useParams<{ ontologyId: string; universeId: string }>();

    useEffect(() => {
        if (!universeId) {
            return;
        }
        const progressMonitor = createFetchPolicyMonitor(messages.fetchingPolicies, 1, {
            global: true,
        });
        explorationSettingsConnector
            .getPolicies(universeId)
            .then((policies) => {
                setPolicies(policies);
            })
            .catch((error) => {
                if (progressMonitor.isCancelled) return;
                notifications.snackError({ message: messages.fetchingPoliciesError }, error as Error);
            })
            .finally(() => {
                progressMonitor.done();
            });
    }, [createFetchPolicyMonitor, universeId]);

    if (fetchPolicyMonitor?.isRunning) {
        return (
            <div className='arg-layout'>
                <LoadingPane progressMonitor={fetchPolicyMonitor} />
            </div>
        );
    }

    return (
        <div className={classNames('&')}>
            <PageHeader
                title={messages.policies}
                selectedItem={buttonSelected}
                setSelectedItem={setButtonSelected}
            />
            <div className={classNames('&-page-content')}>
                <TableToolbar
                    setPolicies={setPolicies}
                    setSearch={setSearch}
                />
                <div className={classNames('&-default-access-message')}>
                    <FormattedMessage {...messages.defaultAccess} />
                </div>
                <div className={classNames('&-table-wrapper')}>
                    <PoliciesList
                        policies={policies}
                        setPolicies={setPolicies}
                        searchInputText={search}
                    />
                </div>
            </div>
        </div>
    );
};
