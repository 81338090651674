import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgInputSearch,
    ArgModal,
    ArgTable2,
    ArgTable2Column,
    ClassValue,
    ProgressMonitor, SelectionProvider,
    useClassNames,
    useMemoAsync, useSelection,
    useSharedSelectionProvider,
} from 'src/components/basic';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import WebhookConnector from 'src/settings/connectors/webhook-connector';
import { WebhookEvent } from 'src/settings/models/detailed-webhooks';

import './webhook-add-events-modal.less';

const CLASSNAME = 'settings-webhook-add-events-modal';
const SELECTION_SOURCE = 'webhook-add-events';

const messages = defineMessages({
    title: {
        id: 'settings.webhook-add-events-modal.title',
        defaultMessage: 'Select events',
    },
    cancel: {
        id: 'settings.webhook-add-events-modal.cancel',
        defaultMessage: 'Cancel',
    },
    select: {
        id: 'settings.webhook-add-events-modal.select',
        defaultMessage: 'Select',
    },
    nameColumnTitle: {
        id: 'settings.webhook-add-events-modal.nameColumnTitle',
        defaultMessage: 'Name',
    },
    emptyEventsList: {
        id: 'settings.webhook-add-events-modal.emptyEventsList',
        defaultMessage: 'No events found',
    },
});

export interface WebhookAddEventsModalProps {
    className?: ClassValue;
    initialEvents?: WebhookEvent[];
    onClose: () => void;
    onSelect: (events: WebhookEvent[]) => void;
}

export function WebhookAddEventsModal(props: WebhookAddEventsModalProps) {
    const { className, initialEvents, onClose, onSelect } = props;
    const classNames = useClassNames(CLASSNAME);
    const [searchValue, onSearchValueChange] = useState<string>();

    const selectionProvider = useSharedSelectionProvider<WebhookEvent>(() => {
        return new SelectionProvider<WebhookEvent>('Webhook', (webhook: WebhookEvent) => {
            if (webhook.id !== undefined) {
                return webhook.id;
            }

            throw new Error('Invalid object');
        });
    });

    useSelection(selectionProvider);

    const [events = [], eventsProgressMonitor] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        const webhookEvents = await WebhookConnector.getInstance().getWebhookEvents(progressMonitor);

        return webhookEvents;
    }, []);

    const handleSelect = useCallback(() => {
        const selectedEvents = events?.filter((event) => {
            return selectionProvider.has(event);
        });

        onSelect(selectedEvents);
        onClose();
    }, [events, onClose, onSelect, selectionProvider]);

    const columns = useMemo<ArgTable2Column<WebhookEvent>[]>(() => {
        return [
            {
                key: 'id',
                title: messages.nameColumnTitle,
                ellipsis: true,
                dataIndex: 'id',
                defaultSortOrder: 'ascend',
            },
        ];
    }, []);

    useEffect(() => {
        if (!initialEvents) {
            return;
        }

        selectionProvider.set(initialEvents, SELECTION_SOURCE);
    }, []);

    let body: ReactNode;

    if (eventsProgressMonitor?.isRunning) {
        body = (
            <div className='arg-layout'>
                <LoadingPane progressMonitor={eventsProgressMonitor} className={classNames('&-pane')} />
            </div>
        );
    } else if (events.length === 0) {
        body = (
            <EmptyPane
                message={messages.emptyEventsList}
                className={classNames('&-pane')}
            />
        );
    } else {
        body = (
            <ArgTable2
                rowKey='id'
                columns={columns}
                dataSource={events}
                search={searchValue}
                selectionSource={SELECTION_SOURCE}
                selectionProvider={selectionProvider}
                className={classNames('&-body-table')}
                scrollableBodyClassName={classNames('&-body-table-scrollable-body')}
            />
        );
    }

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            visible={true}
            onClose={onClose}
            onCancel={onClose}
            onOk={handleSelect}
            okText={messages.select}
        >
            <ArgInputSearch
              onInputChange={onSearchValueChange}
              className={classNames('&-search')}
            />
            <div className={classNames('&-body', className)}>
                {body}
            </div>
        </ArgModal>
    );
}
