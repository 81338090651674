export const SVG = `<svg version='1.1' id='Calque_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
\t viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'>
<path id='Vector' d='M7.2,1.9c-0.1,0-0.2,0-0.2,0l0,0c0,0,0,0,0,0l0,0l0,0l-5,2.5C1.6,4.6,1.4,4.9,1.4,5.2v12.5
\tc0,0.4,0.3,0.8,0.8,0.8c0.1,0,0.2,0,0.4-0.1l4.6-2.3l3.4,2.2l0,0l0,0l0,0c0,0,0.1,0,0.1,0.1l0.1,0c0,0,0.1,0,0.1,0h0
\tc0.1,0,0.1,0,0.2,0h0c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0,0l2.5-1.2c0.4-0.2,0.5-0.7,0.3-1c-0.2-0.4-0.7-0.5-1-0.3l0,0l-1.4,0.7V5.7
\tl3.4-1.7v3.1c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8V2.7c0-0.3-0.1-0.5-0.4-0.7C16.2,2,16.1,1.9,16,1.9c-0.1,0-0.3,0-0.4,0.1
\tL11,4.3L7.6,2l0,0L7.6,2h0c0,0,0,0,0,0s0,0-0.1,0l0,0h0h0C7.3,1.9,7.3,1.9,7.2,1.9L7.2,1.9z M6.4,3.9v10.7L3,16.4V5.7L6.4,3.9z
\t M8,4.1l2.2,1.5v10.6L8,14.8V4.1L8,4.1z M21.1,15.5V9.7c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2v5.8c-1.6,1.2-1.9,3.5-0.7,5.1
\tc1.2,1.6,3.5,1.9,5.1,0.7s1.9-3.5,0.7-5.1C21.6,16,21.4,15.8,21.1,15.5 M18.9,9c0.4,0,0.7,0.3,0.7,0.7v2.2h-1.5V9.7
\tC18.2,9.3,18.5,9,18.9,9z'/>
</svg>`;
