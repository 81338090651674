import React from 'react';
import { defineMessages } from 'react-intl';

import { ArgCheckbox, ClassValue, useClassNames } from '../../basic';

const messages = defineMessages({
    labelWithCount: {
        id: 'common.controls.undefinedButton.LabelWithCount',
        defaultMessage: 'Undefined values ({count})',
    },
    label: {
        id: 'common.controls.undefinedButton.Label',
        defaultMessage: 'Undefined values',
    },
});

interface UndefinedButtonProps {
    className?: ClassValue;
    value: boolean;
    onChange: (newValue: boolean) => void;
    disabled?: boolean;
    count?: number;
}

export const UndefinedButton: React.FunctionComponent<UndefinedButtonProps> = (props) => {
    const { className, value, count, onChange, disabled } = props;

    const classNames = useClassNames('undefinedButton');

    return (
        <ArgCheckbox
            size='node'
            value={value}
            onChange={onChange}
            disabled={disabled}
            label={count === undefined
                ? messages.label
                : messages.labelWithCount
            }
            messageValues={{ count }}
            className={classNames('&', className)}
        />
    );
};
