import { ReactNode, useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { ArgButton, ArgInputSearch, ProgressMonitor, useClassNames } from 'src/components/basic';
import { BriefTemplateTile } from './brief-template-tile';
import { BriefTemplateListItem } from './brief-template-list-item';
import { briefTemplateMessages } from './brief-template-messages';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { BriefTemplate } from '../../model/template';

import './brief-template-list.less';

export enum DisplayMode {
    List,
    Tiles,
}

export interface BriefTemplateState {
    displayMode: DisplayMode;
}

const defaultState: BriefTemplateState = {
    displayMode: DisplayMode.Tiles,
};

export interface BriefTemplatesProps {
    templates: BriefTemplate[] | undefined;
    searchString?: string;
    initialState?: BriefTemplateState;
    isActionMenuVisible?: (template: BriefTemplate) => boolean;
    selectableTemplates?: boolean;
    isTemplateSelected?: boolean;
    onSearchStringChanged?: (searchString: string) => void;
    onTemplateSelected?: (template: BriefTemplate) => void;
    onDeleteTemplateConfirm?: (progressMonitor: ProgressMonitor, template: BriefTemplate) => Promise<void>;
    onRenameTemplateConfirm?: (progressMonitor: ProgressMonitor, template: BriefTemplate, newName: string) => Promise<void>;
    onCheckAction?: (value: boolean, template: BriefTemplate) => void;
    isBriefTemplateSelected?: (template: BriefTemplate) => boolean;
}

export function BriefTemplates(props: BriefTemplatesProps) {
    const {
        templates,
        searchString,
        initialState,
        isActionMenuVisible,
        selectableTemplates,
        isTemplateSelected,
        onSearchStringChanged,
        onTemplateSelected,
        onDeleteTemplateConfirm,
        onRenameTemplateConfirm,
        onCheckAction,
        isBriefTemplateSelected,
    }: BriefTemplatesProps = props;

    const classNames = useClassNames('exploration-brief-templates');
    const [briefTemplateState, setBriefTemplateState] = useState<BriefTemplateState>(initialState ?? defaultState);
    const [selectedTemplate, setSelectedTemplate] = useState<BriefTemplate>();

    const handleTemplateSelected = useCallback((template: BriefTemplate) => {
        if (selectableTemplates) {
            setSelectedTemplate(template);
            onTemplateSelected!(template);
        }
    }, [onTemplateSelected, selectableTemplates]);

    useEffect(() => {
        if (isTemplateSelected == false) {
            setSelectedTemplate(undefined);
        }
    }, [isTemplateSelected]);

    const renderTemplates = useCallback(() => {
        let items: ReactNode[] = [];
        if (templates) {
            if (briefTemplateState.displayMode == DisplayMode.Tiles) {
                items = templates.map((template) => {
                    return <BriefTemplateTile
                        onClick={() => handleTemplateSelected(template)}
                        onDeleteTemplateConfirm={onDeleteTemplateConfirm}
                        onRenameTemplateConfirm={onRenameTemplateConfirm}
                        onCheckAction={onCheckAction}
                        isBriefTemplateSelected={isBriefTemplateSelected}
                        key={template.id}
                        template={template}
                        searchString={searchString}
                        selected={selectedTemplate && selectedTemplate.id == template.id}
                        displayContextualMenuButton={isActionMenuVisible?.(template)}
                    />;
                });
            }
            if (briefTemplateState.displayMode == DisplayMode.List) {
                items = templates.map((template) => {
                    return <BriefTemplateListItem
                        onClick={() => handleTemplateSelected(template)}
                        onDeleteTemplateConfirm={onDeleteTemplateConfirm}
                        onRenameTemplateConfirm={onRenameTemplateConfirm}
                        key={template.id}
                        template={template}
                        selected={selectedTemplate && selectedTemplate.id == template.id}
                        displayContextualMenuButton={isActionMenuVisible?.(template)}
                    />;
                });
            }
        }

        return !isEmpty(items) ? (
            <div className={classNames('templates')}>{items}</div>
        ) : (
            <div className={classNames('&-body-empty')}>
                <EmptyPane
                    message={briefTemplateMessages.emptyMessage}
                    size='medium'
                />
            </div>
        );
    }, [
        templates,
        classNames,
        briefTemplateState.displayMode,
        onDeleteTemplateConfirm,
        onRenameTemplateConfirm,
        onCheckAction,
        isBriefTemplateSelected,
        selectedTemplate,
        isActionMenuVisible,
        handleTemplateSelected,
        searchString,
    ]);

    return (
        <div
            className={classNames('&', {
                'display-mode-list': briefTemplateState.displayMode == DisplayMode.List,
                'display-mode-tiles': briefTemplateState.displayMode == DisplayMode.Tiles,
            })}
        >
            <div className={classNames('controls')}>
                {onSearchStringChanged && <ArgInputSearch
                    value={searchString}
                    debounce={250}
                    onInputChange={(value) => {
                        onSearchStringChanged(value);
                    }}
                    className={classNames('search-input')}
                />}
                <div className={classNames('display-modes')}>
                    <ArgButton
                        className={{
                            active: briefTemplateState.displayMode == DisplayMode.List,
                        }}
                        type='ghost'
                        icon='icon-list2'
                        onClick={() => {
                            setBriefTemplateState({ ...briefTemplateState, displayMode: DisplayMode.List });
                        }}
                    />
                    <ArgButton
                        className={{
                            active: briefTemplateState.displayMode == DisplayMode.Tiles,
                        }}
                        type='ghost'
                        icon='icon-view-tile'
                        onClick={() => {
                            setBriefTemplateState({ ...briefTemplateState, displayMode: DisplayMode.Tiles });
                        }}
                    />
                </div>
            </div>
            {renderTemplates()}
        </div>
    );
}
