import React, { useCallback, useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import { defineMessages, FormattedMessage } from 'react-intl';
import Debug from 'debug';

import {
    ArgButton,
    ArgImage,
    ArgPageConfig,
    GLOBAL_PM,
    isMessageDescriptor,
    ProgressMonitor,
    ThreeDotsLoading,
    useCallbackAsync,
    useClassNames,
    useSetTimeout,
} from '../../basic';
import { Environment } from '../../../utils/environment';
import { LoginForm } from './login-form';
import { computePageAccess, PageToTest } from '../../caches/pages-access';
import { computeOAuthLoginURL } from '../../../utils/connector';
import { listArgonosModules } from '../../application/modules-manager';
import { useApplicationBranding } from '../../application/argonos-application-branding';

import './login.less';

const debug = Debug('common:login:Login');

const messages = defineMessages({
    continueButton: {
        id: 'common.login.ContinueButton',
        defaultMessage: 'Continue',
    },
    welcomeLabel: {
        id: 'common.login.welcomeLabel',
        defaultMessage: 'Welcome',
    },
    pageWelcomeTitle: {
        id: 'common.login.pageWelcomeTitle',
        defaultMessage: 'Welcome',
    },
});

export function Login() {
    const classNames = useClassNames('login-page');

    const handleContinue = useCallback(async (progressMonitor: ProgressMonitor) => {
        const pagesToTest = listPageAccess();

        const pageAccess = await computePageAccess(pagesToTest, progressMonitor);
        if (pageAccess) {
            document.location.replace(pageAccess);

            return;
        }

        let redirectTo: string | undefined;
        if (Environment.redirectTo) {
            redirectTo = Environment.redirectTo;
        } else {
            const argonosModule = listArgonosModules()
                .find((m) => !!m.enabled && !!m.routeURL)
                .value();

            redirectTo = argonosModule?.routeURL;
        }

        if (!redirectTo) {
            return;
        }

        debug('handleContinue', 'Replace to', redirectTo);

        document.location.replace(redirectTo);
    }, []);

    const [handleContinueOAuth] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        await handleContinue(progressMonitor);
    }, [handleContinue], undefined, undefined, GLOBAL_PM);

    const [handleTimerOAuth] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const pageAccess = await computePageAccess(listPageAccess(), progressMonitor);
        if (pageAccess) {
            debug('handleTimerOAuth:pageAccess', 'Replace to', pageAccess);

            document.location.replace(pageAccess);

            return;
        }

        if (Environment.redirectTo) {
            debug('handleTimerOAuth:RedirectTo', 'Replace to', Environment.redirectTo);

            document.location.replace(Environment.redirectTo);

            return;
        }

        const loginUrl = computeOAuthLoginURL(document.location.origin);

        debug('handleTimerOAuth:OAuthLogin', 'Replace to', loginUrl);
        document.location.replace(loginUrl);
    }, [], undefined, undefined, GLOBAL_PM);

    // Should the user be redirected to folders or cases
    const shouldRedirectToFolders = Environment.redirectTo === '/folders';
    const shouldRedirectToAdmin = Environment.redirectTo === '/admin';

    const timerCallback = useSetTimeout(1000);

    useEffect(() => {
        if (!Environment.apiOAuth) {
            return;
        }

        timerCallback(handleTimerOAuth);
    }, []);

    let body;
    if (Environment.apiOAuth) {
        body = <>
            {/* Api login with OAuth */}
            <div className={classNames('&-oauth')}>
                <FormattedMessage {...messages.welcomeLabel} />

                <ThreeDotsLoading />
            </div>
            <ArgButton
                hidden={true}
                data-testid='login-button'
                size='large'
                htmlType='submit'
                className={classNames('&-form-item', '&-form-submit-button')}
                label={messages.continueButton}
                onClick={handleContinueOAuth}
                autoFocus={true}
            />
        </>;
    } else {
        body = <LoginForm onContinue={handleContinue} />;
    }

    const applicationBranding = useApplicationBranding();
    const applicationName: string = isMessageDescriptor(applicationBranding?.brandingName)
        ? String(applicationBranding?.brandingName.defaultMessage)
        : (applicationBranding?.brandingName as string)
        ;

    return <ArgPageConfig pageSubTitle={messages.pageWelcomeTitle}>
        <div className={classNames('&')}>
            <div className={classNames('&-left')}>
                <div className={classNames('&-left-content')}>
                    {!localStorage.ANONYMIZE && <ArgImage
                        src={applicationBranding?.brandingLogoURL}
                        alt={applicationName}
                        placeholder={false}
                        className={classNames('&-left-logo')}
                    />}
                    {body}
                </div>
            </div>

            <div className={classNames('&-right')}>
                <div className={classNames('&-right-background')}>
                    <div className={classNames('&-right-background-light-diamond')} />
                    <div className={classNames('&-right-background-dark-diamond')} />
                    <div className={classNames('&-right-background-cut-diamond')} />
                </div>
                <Row className={classNames('&-right-content')} justify='center' align='middle'>
                    <Col span={13} className={classNames('&-right-content-container')}>
                        <Typography.Title className={classNames('&-right-content-title')}>
                            {shouldRedirectToFolders
                                ? <FormattedMessage
                                    id='preparation.login.Title'
                                    defaultMessage='Data Preparation Module'
                                />

                                : shouldRedirectToAdmin
                                    ? <FormattedMessage
                                        id='settings.login.Title'
                                        defaultMessage='Your admin platform'
                                    />
                                    : <FormattedMessage
                                        id='exploration.login.Title'
                                        defaultMessage='Data Exploration Module'
                                    />
                            }
                        </Typography.Title>
                        <Typography.Text className={classNames('&-right-content-text')}>
                            {shouldRedirectToFolders
                                ? <FormattedMessage
                                    id='preparation.login.Explanation'
                                    defaultMessage='Log into the platform to define how your data will be extracted, transformed and enriched'
                                />
                                : shouldRedirectToAdmin
                                    ? <FormattedMessage
                                        id='settings.login.Explanation'
                                        defaultMessage='Log into the platform'
                                    />
                                    : <FormattedMessage
                                        id='exploration.login.Explanation'
                                        defaultMessage='Log into the platform in order to get insight on
                                        your data and create value out of it, through relevant searches and appealing
                                        visualizations.'
                                    />
                            }
                        </Typography.Text>
                    </Col>
                </Row>
            </div>
        </div>
    </ArgPageConfig>;
}

function listPageAccess(): PageToTest[] {
    const result = listArgonosModules().map((argonosModule) => {
        if (!argonosModule.enabled) {
            return undefined;
        }

        const testUrl = argonosModule.testURL;
        if (!testUrl || !argonosModule.apiURL) {
            return undefined;
        }

        const result: PageToTest = {
            api: argonosModule.apiURL,
            testURL: testUrl,
            gotoURL: argonosModule.routeURL,
        };

        return result;
    }).compact().value();

    return result;
}
