import { BasicCasePiece, CasePieceId } from './basic-case-piece';
import { CasePieceType } from './case-piece-type';

export type ResourceId = CasePieceId;
export type TagId = string;
export const PDF_PREVIEW_METADATA = 'pdf-preview';

export interface ResourceCasePiece extends Omit<BasicCasePiece, 'id'> {
    type: CasePieceType.Resource;

    id: ResourceId;

    contentType: string;
    contentLength: number;
    metadata?: ResourceMetadata;
    previews?: ResourcePreviews;
}

export type ResourceMetadata = Record<string, string>;

export interface ResourcePreviews {
    big?: string;
    medium?: string;
    small?: string;
    pdf?: ResourceId;
}
export type ResourceOperationId = string;

export interface AvailableResourceOperation {
  id: ResourceOperationId;
  name?: string;
}

export interface InProgressResourceOperation {
  id: ResourceOperationId;
  name?: string;
  progression?: number;
}
export interface CompletedResourceOperation {
  id: ResourceOperationId;
  name?: string;
  result?: any;
  errors?: string[];
}

export interface ListResourceOperationsResponse {
  available?: AvailableResourceOperation[];
  inProgress?: InProgressResourceOperation[];
  completed?: CompletedResourceOperation[];
}
