import { useMemo } from 'react';

import {
    ArgModalContainer,
    ArgNotificationsProvider,
    ArgPageConfig,
    ArgResourcesConfiguration,
    ArgResourcesContext,
    ClassValue,
    useClassNames,
} from '../components/basic';
import { HomePage } from './home-page';
import { useRecordPageAccess } from '../components/caches/pages-access';
import { ArgDrawerContainer } from '../components/basic/arg-drawer/arg-drawer-container';
import { LoadingPane } from '../components/common/panes/loading-pane';
import { ErrorPane } from '../components/common/panes/error-pane';
import { getSettingsApi } from 'src/utils/connectors/api-url';
import { UserConfigurationsContext } from 'src/contexts/user-configurations';
import { useUserConfigurations } from '../hooks/use-user-configuration';
import { UsersSettingsConnector } from '../utils/connectors/users-settings-connector';
import { useApplicationBranding } from '../components/application/argonos-application-branding';
import { useCurrentArgonosModuleBranding } from 'src/components/application/argonos-modules-branding';

export const SETTINGS_ROUTE_PATH = '/settings';

interface SettingsRouterProps {
    className?: ClassValue;
}

export function SettingsRouter(props: SettingsRouterProps) {
    const {
        className,
    } = props;

    const classNames = useClassNames('settings-router');

    useRecordPageAccess(SETTINGS_ROUTE_PATH);

    const [moduleBranding] = useCurrentArgonosModuleBranding();
    const applicationBranding = useApplicationBranding();

    const apiConfiguration = useMemo<ArgResourcesConfiguration>(() => {
        return {
            getServerApi: () => getSettingsApi(),
        };
    }, []);

    const [userConfigurations, loadingUserConfigurations, errorUserConfigurations] = useUserConfigurations(UsersSettingsConnector.getInstance());

    if (errorUserConfigurations) {
        return (
            <div className={classNames('&', className, 'error')}>
                <ErrorPane
                    error={errorUserConfigurations}
                    className='fill'
                />
            </div>
        );
    }

    if (!userConfigurations) {
        return (
            <div className={classNames('&', className, 'loading')}>
                <LoadingPane
                    progressMonitor={loadingUserConfigurations}
                    className='fill'
                />
            </div>
        );
    }

    return (
        <ArgNotificationsProvider>
            <ArgPageConfig
                pageTitle={moduleBranding.brandingName}
                iconURL={moduleBranding.brandingIconURL || applicationBranding.brandingIconURL}
            >
                <UserConfigurationsContext.Provider value={userConfigurations}>
                    <ArgResourcesContext.Provider value={apiConfiguration}>
                        <ArgModalContainer>
                            <ArgDrawerContainer>
                                <HomePage
                                    key='homepage'
                                    className={classNames(className)}
                                />
                            </ArgDrawerContainer>
                        </ArgModalContainer>
                    </ArgResourcesContext.Provider>
                </UserConfigurationsContext.Provider>
            </ArgPageConfig>
        </ArgNotificationsProvider>
    );
}

export default SettingsRouter;
