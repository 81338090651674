import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { KEY_BINDINGS as EXPLO_KEY_BINDINGS } from '../../../exploration/keybindings/keybindings';
import { GLOBAL_SCOPE as EXPLO_GLOBAL_SCOPE } from '../../../exploration/keybindings/global';
import { SCOPE as EXPLO_CASE_SCOPE } from '../../../exploration/keybindings/case';
import { KEY_BINDINGS as PREPA_KEY_BINDINGS } from '../../../preparation/keybindings/keybindings';
import { GLOBAL_SCOPE as PREPA_GLOBAL_SCOPE } from '../../../preparation/keybindings/global';
import { SCOPE as PREPA_FOLDER_SCOPE } from '../../../preparation/keybindings/folder';
import {
    ArgIcon,
    ClassValue,
    KeyBindingsEngine,
    KeyBindingsScope,
    ProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { UserConfigurationsContext } from '../../../contexts/user-configurations';
import { PageHeader } from '../../common-components/page-header';
import { LibraryConfiguration } from '../components/library-configuration/library-configuration';
import { SystemConfiguration } from '../components/system-configuration/system-configuration';
import { LoadingPane } from '../../../components/common/panes/loading-pane';
import { useUserConfigurations } from '../../../hooks/use-user-configuration';
import { ConfigurationsScope, ConfigurationType } from '../configuration-type';
import usersSettingsConnector from 'src/utils/connectors/users-settings-connector';

import './configuration-view.less';

const CLASSNAME = 'settings-configuration-view';
const USER_CONFIGURATION_KEY = 'exploration.ui.KEY_BINDINGS';
const messages = defineMessages({
    title: {
        id: 'settings.configuration.title',
        defaultMessage: 'Default configuration',
    },
    preferencesTitle: {
        id: 'settings.configuration.preferencesTitle',
        defaultMessage: 'Default preferences',
    },
});

export interface ConfigurationViewProps {
    className?:ClassValue;
    type:ConfigurationType;
}

export function ConfigurationView(props:ConfigurationViewProps) {
    const { className, type } = props;
    const classNames = useClassNames(CLASSNAME);
    const notifications = useArgNotifications();

    const params = useParams<{configurationScope:ConfigurationsScope}>();

    const { universeId } = useParams<{ontologyId?:string; universeId?:string}>();
    const [configurationScope, setConfigurationScope] = useState<ConfigurationsScope>('data_exploration');

    const [userConfigurations, loadingUserConfiguration, errorUserConfiguration] = useUserConfigurations(usersSettingsConnector);

    const [getConfigurations, fetchConfigurationsProgressMonitor] = useCallbackAsync(async (progressMonitor:ProgressMonitor) => {
        try {
            await userConfigurations?.fetch(notifications, progressMonitor);
        } catch (error) {
            console.error('Something went wrong while fetching user configurations', error);
            throw error;
        }
    }, [userConfigurations, notifications]);

    useEffect(() => {
        if (params?.configurationScope) {
            setConfigurationScope(params?.configurationScope);
        }
    }, [params]);

    if (fetchConfigurationsProgressMonitor?.isRunning || !userConfigurations || loadingUserConfiguration?.isRunning) {
        return (
            <div className={ classNames('&', className, 'loading') }>
                <LoadingPane
                    progressMonitor={ fetchConfigurationsProgressMonitor || loadingUserConfiguration }
                    className='fill'
                />
            </div>
        );
    }

    let content;
    let viewTitle = messages.preferencesTitle;

    if (type === ConfigurationType.Universe) {
        content = <LibraryConfiguration universeId={ universeId } />;
        viewTitle = messages.title;
    }

    if (type === ConfigurationType.Preferences) {
        content =
            <SystemConfiguration onImportUserConfig={ getConfigurations } configurationScope={ configurationScope } />;
    }

    return (
        <UserConfigurationsContext.Provider value={ userConfigurations }>
            <KeyBindingsEngine
                defs={ configurationScope === 'data_exploration' ? EXPLO_KEY_BINDINGS : PREPA_KEY_BINDINGS }
                userConfigurationKey={ USER_CONFIGURATION_KEY }
                globalScope={ configurationScope === 'data_exploration' ? EXPLO_GLOBAL_SCOPE : PREPA_GLOBAL_SCOPE }
            >
                <KeyBindingsScope
                    scope={ configurationScope === 'data_exploration' ? EXPLO_CASE_SCOPE : PREPA_FOLDER_SCOPE }
                >
                    <div className={ classNames('&', className) } data-testid='configuration-view'>
                        <PageHeader
                            title={
                                <div className={ classNames('&-title') }>
                                    <ArgIcon name='icon-file-table-box-outline'
                                             className={ classNames('&-title-icon') } />
                                    <FormattedMessage { ...viewTitle } />
                                </div>
                            }
                        />
                        <div className={ classNames('&-page-content') }>
                            { content }
                        </div>
                    </div>
                </KeyBindingsScope>
            </KeyBindingsEngine>
        </UserConfigurationsContext.Provider>
    );
}
