import { includes, isNil } from 'lodash';

import { ArgUserId, dayjs } from 'src/components/basic';
import { FolderCasePiece } from 'src/model/folder-case-piece';
import { CasePieceType } from '../../model/case-piece-type';
import { CasePiece } from '../model/case-piece';
import { SCREENSHOT_METADATA } from '../model/resource';
import { ScreenshotCasePiece } from '../model/resource';
import { ExplorationCasePiece, ExplorationOrigin } from '../model/exploration';
import { BasicCasePiece } from '../../model/basic-case-piece';
import { DashboardCasePiece } from '../features/dashboards/dashboards';
import { BriefCasePiece } from '../model/brief';
import { ChartCasePiece } from '../features/charts/charts';
import { FormCasePiece } from '../model/form';
import { Folder } from '../../model/folder';
import { DataSourceCasePiece } from '../features/datasources/datasources';
import { FlaggedObjectCasePiece } from '../model/flagged-object';
import { BulkSearchCasePiece } from '../model/bulk-search';
import { FilterCasePieceCondition } from 'src/utils/connectors/argonos-folders-connector';
import { ResourceCasePiece } from '../../model/resource';

export const isMyCase = (caseInfo: FolderCasePiece) => !!caseInfo.permissions?.hasOwnership;
export const isWritableCase = (caseInfo: FolderCasePiece) => caseInfo.permissions?.allowWrite;

export const SYSTEM_EXPLORATION_ORIGINS: ExplorationOrigin[] = ['ExplorationDraftGraphView', 'ExplorationDraftMapView', 'ExplorationDraftTableView', 'SearchQuickView', 'StructuredSearch'];

export function shouldDisplayBadge(casePiece: CasePiece, userId: ArgUserId): boolean {
    if (casePiece.lastUpdatedBy?.id === userId) {
        return false;
    }

    const lastChangeDate = casePiece.lastUpdatedDate || casePiece.createdDate;

    const ret = (lastChangeDate !== undefined)
        && (isNil(casePiece?.lastVisitedDate)
            || (lastChangeDate && (dayjs(lastChangeDate).isAfter(casePiece?.lastVisitedDate)))
        );

    return ret;
}

export function isResourceCasePiece(casePiece: CasePiece): casePiece is ResourceCasePiece {
    if (casePiece.type !== CasePieceType.Resource) {
        return false;
    }

    return true;
}

export function isScreenshotCasePiece(casePiece: CasePiece): casePiece is ScreenshotCasePiece {
    if (!isResourceCasePiece(casePiece)) {
        return false;
    }

    if (!casePiece.metadata?.[SCREENSHOT_METADATA]) {
        return false;
    }

    return true;
}

export function isDashboardCasePiece(piece: BasicCasePiece): piece is DashboardCasePiece {
    if (piece.type === CasePieceType.Dashboard) {
        return true;
    }

    return false;
}

export function isBriefCasePiece(piece: BasicCasePiece): piece is BriefCasePiece {
    if (piece.type === CasePieceType.Brief) {
        return true;
    }

    return false;
}

export function isExplorationCasePiece(casePiece: CasePiece): casePiece is ExplorationCasePiece {
    if (casePiece.type !== CasePieceType.Exploration) {
        return false;
    }

    return true;
}

export function isChartCasePiece(piece: BasicCasePiece): piece is ChartCasePiece {
    if (piece.type === CasePieceType.Chart) {
        return true;
    }

    return false;
}

export function isFormCasePiece(casePiece: CasePiece): casePiece is FormCasePiece {
    if (casePiece.type !== CasePieceType.Form) {
        return false;
    }

    return true;
}


export function isDataSourceCasePiece(casePiece: CasePiece): casePiece is DataSourceCasePiece {
    if (casePiece.type !== CasePieceType.DataSource) {
        return false;
    }

    return true;
}

export function isFolderCasePiece(casePiece: CasePiece): casePiece is FolderCasePiece {
    if (casePiece.type !== CasePieceType.Folder) {
        return false;
    }

    return true;
}


export function isFlaggedObjectCasePiece(casePiece: CasePiece): casePiece is FlaggedObjectCasePiece {
    if (casePiece.type !== CasePieceType.FlaggedObject) {
        return false;
    }

    return true;
}

export function removeAdvancedSearchExplorationFromFolder(folder: Folder): Folder {
    const filteredPieces = folder.pieces.filter(casePiece => casePiece.type !== CasePieceType.Search);

    const filteredFolder: Folder = {
        ...folder,
        pieces: filteredPieces,
    };

    return filteredFolder;
}

export function removeAdvancedSearchExplorationFromFolders(folders: Folder[]): Folder[] {
    const cleanFolders = folders.map(removeAdvancedSearchExplorationFromFolder);

    return cleanFolders;
}

export function shouldRemoveAdvancedSearchExploration(includingChildrenTypes?: CasePieceType[]) {
    return includingChildrenTypes && !includingChildrenTypes.includes(CasePieceType.Search);
}

export const systemCasePieceCondition: FilterCasePieceCondition = (casePiece: CasePiece) => {
    const isExplorationCasePieceAndSystemOrigin = casePiece.type === CasePieceType.Exploration && includes(SYSTEM_EXPLORATION_ORIGINS, (casePiece as ExplorationCasePiece).explorationOrigin);
    const isMassiveSearchCasePieceAndSystemOrigin = casePiece.type === CasePieceType.MassiveSearch && includes(SYSTEM_EXPLORATION_ORIGINS, (casePiece as BulkSearchCasePiece).origin);

    return isExplorationCasePieceAndSystemOrigin || isMassiveSearchCasePieceAndSystemOrigin;
};

export const notSystemCasePieceCondition: FilterCasePieceCondition = (casePiece: CasePiece) => {
    return !systemCasePieceCondition(casePiece);
};

export function filterSystemCasePieces(casePieces: CasePiece[]): CasePiece[];
export function filterSystemCasePieces(casePieces?: CasePiece[]): CasePiece[] | undefined;
export function filterSystemCasePieces(casePieces?: CasePiece[]) {
    return casePieces?.filter(systemCasePieceCondition);
}

export function excludesSystemCasePieces(casePieces: CasePiece[]): CasePiece[];
export function excludesSystemCasePieces(casePieces?: CasePiece[]): CasePiece[] | undefined;
export function excludesSystemCasePieces(casePieces?: CasePiece[]) {
    return casePieces?.filter(notSystemCasePieceCondition);
}
