import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';

import { ArgIcon, ArgTooltip2, ClassValue, useClassNames } from 'src/components/basic';
import { EmptyPane } from '../../../../components/common/panes/empty-pane';
import { ExternalComponent } from '../../../models/external-component';
import { ExternalComponentActionsMenu } from '../external-component-actions-menu/external-component-actions-menu';
import { useHasPermission } from '../../../../contexts/user-permission';
import { PreparationPermissions } from '../../../../preparation/permissions/permissions';

import './external-component-information-panel.less';

const CLASSNAME = 'settings-external-component-information-panel';
const messages = defineMessages({
    headerTitle: {
        id: 'settings.external-component.information-panel.title',
        defaultMessage: 'Information',
    },
    active: {
        id: 'settings.external-component.information-panel.active',
        defaultMessage: 'Active',
    },
    noExternalComponentSelected: {
        id: 'settings.external-component.information-panel.noExternalComponentSelected',
        defaultMessage: 'No external component selected',
    },
    key: {
        id: 'settings.external-component.information-panel.key',
        defaultMessage: 'Key',
    },
    category: {
        id: 'settings.external-component.information-panel.category',
        defaultMessage: 'Category',
    },
    type: {
        id: 'settings.external-component.information-panel.type',
        defaultMessage: 'Type',
    },
    url: {
        id: 'settings.external-component.information-panel.url',
        defaultMessage: 'Access URL',
    },
    description: {
        id: 'settings.external-component.information-panel.description',
        defaultMessage: 'Description',
    },
    yes: {
        id: 'settings.external-component.information-panel.yes',
        defaultMessage: 'Yes',
    },
    no: {
        id: 'settings.external-component.information-panel.no',
        defaultMessage: 'No',
    },
});

interface PropertyInformation {
    label: MessageDescriptor;
    content: ReactNode;
    key: string;
}

export interface ExternalComponentInformationPanelProps {
    className?: ClassValue;
    selectedExternalComponents: ExternalComponent[];
    onActionSuccess: () => void;
}

export function ExternalComponentInformationPanel(props: ExternalComponentInformationPanelProps) {
    const { className, selectedExternalComponents, onActionSuccess } = props;

    const classNames = useClassNames(CLASSNAME);

    const canEditExternalComponent = useHasPermission<PreparationPermissions>('preparation.remote.component.edition');
    const canExportExternalComponent = useHasPermission<PreparationPermissions>('preparation.remote.component.export');

    const [selectedExternalComponentIndex, setSelectedExternalComponentIndex] = useState(0);

    const externalComponent: ExternalComponent | undefined = selectedExternalComponents[selectedExternalComponentIndex];

    useEffect(() => {
        if (selectedExternalComponentIndex === selectedExternalComponents.length && selectedExternalComponentIndex !== 0) {
            setSelectedExternalComponentIndex((selectedExternalComponentIndex) => selectedExternalComponentIndex - 1);
        }
    }, [selectedExternalComponentIndex, selectedExternalComponents]);

    const renderContentWithTooltip = useCallback((content: ReactNode) => {
        return (
            <ArgTooltip2 title={content}>
                <div>{content}</div>
            </ArgTooltip2>
        );
    }, []);

    const propertiesData = useMemo<PropertyInformation[]>(() => {
        if (!externalComponent) {
            return [];
        }

        return [
            {
                key: 'active',
                label: messages.active,
                content: <FormattedMessage {...externalComponent.isEnabled ? messages.yes : messages.no} />,
            },
            {
                key: 'key',
                label: messages.key,
                content: renderContentWithTooltip(externalComponent.key),
            },
            {
                key: 'getDefinitionUrl',
                label: messages.url,
                content: renderContentWithTooltip(externalComponent.getDefinitionUrl),
            },
            {
                key: 'category',
                label: messages.category,
                content: renderContentWithTooltip(externalComponent.category),
            },
            {
                key: 'type',
                label: messages.type,
                content: renderContentWithTooltip(externalComponent.type),
            },
            {
                key: 'description',
                label: messages.description,
                content: externalComponent.description,
            },
        ];
    }, [externalComponent, renderContentWithTooltip]);

    if (!externalComponent) {
        return (
            <div className={classNames('&', className)}>
                <div className={classNames('&-header')}>
                    <h3 className={classNames('&-header-title')}>
                        <FormattedMessage {...messages.headerTitle} />
                    </h3>
                </div>
                <div className={classNames('&-no-external-component-pane')}>
                    <EmptyPane
                        key='loading'
                        message={messages.noExternalComponentSelected}
                        backgroundAnimation='wave'
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-header')}>
                <h3 className={classNames('&-header-title')}>
                    <FormattedMessage {...messages.headerTitle} />
                </h3>
                {selectedExternalComponents.length > 1 && (
                    <div className={classNames('&-toggle')}>
                        <div
                            onClick={() => {
                                setSelectedExternalComponentIndex((currentSelectedExternalComponentIndex) => {
                                    return Math.max(currentSelectedExternalComponentIndex - 1, 0);
                                });
                            }}
                            className={classNames(
                                selectedExternalComponentIndex === 0
                                    ? '&-toggle-disabled'
                                    : '&-toggle-enabled'
                            )}
                        >
                            <ArgIcon name='icon-previous' />
                        </div>
                        {selectedExternalComponentIndex + 1}
                        {'/'}
                        {selectedExternalComponents.length}
                        <div
                            onClick={() => {
                                setSelectedExternalComponentIndex((currentSelectedExternalComponentIndex) => {
                                    return Math.min(currentSelectedExternalComponentIndex + 1, selectedExternalComponents.length - 1);
                                });
                            }}
                            className={classNames(
                                selectedExternalComponentIndex === selectedExternalComponents.length - 1
                                    ? '&-toggle-disabled'
                                    : '&-toggle-enabled'
                            )}
                        >
                            <ArgIcon name='icon-next' />
                        </div>
                    </div>
                )}
            </div>
            <div className={classNames('&-scrollable-body')}>
                <div className={classNames('&-name-container')}>
                    <div className={classNames('&-name-label')}>
                        <div className={classNames('&-name')}>{externalComponent.name}</div>
                        {(canEditExternalComponent || canExportExternalComponent) && (
                            <ExternalComponentActionsMenu externalComponent={externalComponent} onActionSuccess={onActionSuccess} />
                        )}
                    </div>
                </div>
                {propertiesData.map((property) => (
                    <div key={property.key} className={classNames('&-property-row')}>
                        <div className={classNames('&-property-row-title')}>
                            <FormattedMessage {...property.label} />
                        </div>
                        <div className={classNames('&-property-row-content')}>
                            {property.content}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
