import { normalizeText } from '../components/basic';

export function filterAndSortArrayByKeyword(array: string[], keyword?: string) {
    if (!keyword) {
        return array;
    }

    const normalizedKeyword = normalizeText(keyword);

    const searchResults = array
        .filter((item) => {
            const normalizedItem = normalizeText(item);

            // Filter results by doing case insensitive match on name here
            return normalizedItem.includes(normalizedKeyword);
        })
        .sort((a, b) => {
            // Sort results by matching name with keyword position
            const a1 = normalizeText(a).indexOf(normalizedKeyword);
            const b1 = normalizeText(b).indexOf(normalizedKeyword);

            if (a1 > b1) {
                return 1;
            }
            if (a1 < b1) {
                return -1;
            }
            if (a > b) {
                return 1;
            }

            return -1;
        });

    return searchResults;
}
