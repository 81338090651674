import {
    UniverseId,
    UniversePropertyName,
    UniversePropertyTypes,
    UniverseVertexTypeName,
    VertexOrEdgeProperties,
} from './universe';
import { UserDefinedContent } from '../../components/common/graph/customisation/graph-style';
import { EdgeBasicInfo } from './edge';
import { CaseFlag } from './exploration-visualization';
import { ResourceCasePiece } from 'src/model/resource';
import { ResourceId } from 'src/model/resource';

export type VertexId = string;
export type BusinessId = string;

export interface VertexStyle extends UserDefinedContent {
//    strokeColor?: string;
//    transparentStrokeColor?: boolean;

    imageUrl?: string;

//    offsetX?: number;
//    offsetY?: number;
//    iconScale?: number;

//    size?: number;

    iconColor?: string;
//    icon?: string;
//    iconFontFamily?: string;

//    badgeBlink?: boolean;
    badgeSize?: number;
    badgeColor?: string;
    badgeIcon?: string;
    badgeFontColor?: string;

    fontFamily?: string;
}

export interface VertexPropertySorting {
    name: string;
    position: number;
    showOnSummary: boolean;
}

export enum VertexPropertiesOrderBy {
    Schema = 'Schema',
    AlphaAsc = 'AlphaAsc',
    AlphaDesc = 'AlphaDesc',
}

export interface VertexSorting {
    orderBy?: VertexPropertiesOrderBy;
    properties?: Record<string, VertexPropertySorting>;
}

export interface VertexInfo2 {
    id: VertexId;
    type: UniverseVertexTypeName;
//    flag?: CaseFlag;
//    title?: InfoTitle;
}

export interface VertexInfo {
    id: VertexId;
    type: UniverseVertexTypeName;
    title?: InfoTitle;
    flag?: CaseFlag;
}

export interface VertexInfoWithProperties extends VertexInfo {
    properties: VertexOrEdgeProperties;
}

export interface InfoTitle {
    value?: string;
    type: UniversePropertyTypes;
}

export interface Vertex extends VertexInfoWithProperties {
    style?: VertexStyle;
    createdDate?: Date;
    lastUpdatedDate?: Date;
    //groups?: ExplorationGroupId[];
    attachments?: VertexAttachment[];
    linkedEdges?: EdgeBasicInfo[];
}

export interface VertexAttachmentMetadata {
    'X-thumbnail-big': string;
    'X-thumbnail-medium': string;
    'X-thumbnail-small': string;
}

export interface VertexAttachment extends UniverseResource {
    vertexId: VertexId;

    url?: string;
    uploadDate?: Date;
    status?: 'done' | 'cancelled' | 'failed' | 'uploading' | 'waiting';
}

interface UniverseResource extends ResourceCasePiece {
    universeId: UniverseId;
}

export interface VertexResource {
    vertexId: VertexId;
    resourceId: ResourceId;
}

export type VertexElementPermission = 'create' | 'delete' | 'read';
export type VertexElementPermissions = VertexElementPermission[];
export type VertexPropertyPermission = 'read' | 'append' | 'clear' | 'write';
export type VertexPropertyPermissions = VertexPropertyPermission[];

export interface VertexCapabilities {
    elementPermissions: VertexElementPermissions;
    propertiesPermissions: Record<UniversePropertyName, VertexPropertyPermissions[]>;
}

interface CreatedVertexResult {
    id: VertexId;
    businessId: BusinessId;
}

export interface CreatedVerticesResult {
    createdIds: CreatedVertexResult[];
}
