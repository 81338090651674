import { Plugin } from '@ckeditor/ckeditor5-core';
import ViewModeExploration2Command from './viewmode-exploration2-command';
import Exploration2ViewMode from './exploration2-viewmode';


export default class Exploration2ViewModeUi extends Plugin {
    /**
     * @inheritDoc
     */
    static get requires() {
        return [Exploration2ViewMode];
    }

    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'argonos-exploration:viewmode-ui';
    }

    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;

        const viewModeExploration2Command = new ViewModeExploration2Command(editor);
        editor.commands.add('viewModeExploration2', viewModeExploration2Command);

        this.bind('isEnabled').to(viewModeExploration2Command);

        this._installListener();
    }

    /**
     * Attaches the listeners responsible for creating a resizer for each image, except for images inside the HTML embed preview.
     *
     * @private
     */
    _installListener() {
        const editor = this.editor;

        document.addEventListener('arg-viewChanged', (event) => {
            const detail = event.detail;

            //console.log('Catch change', detail);

            editor.execute('viewModeExploration2', {
                offsetX: detail.offsetX, offsetY: detail.offsetY, zoom: detail.zoom, uuid: detail.uuid,
            });
        });
    }
}
