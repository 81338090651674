import { defineMessages } from 'react-intl';

import { defineKeyBindings, GLOBAL_SCOPE_ID, KeyBindingScopeDescriptor } from '../../components/basic';

const messages = defineMessages({
    globalScope: {
        id: 'exploration.keybindings.GlobalScope',
        defaultMessage: 'Global',
    },
    multipleSelection: {
        id: 'exploration.keybindings.MultipleSelection',
        defaultMessage: 'Multiple selection',
    },
    delete: {
        id: 'exploration.keybindings.Delete',
        defaultMessage: 'Delete',
    },
    undo: {
        id: 'exploration.keybindings.Undo',
        defaultMessage: 'Undo',
    },
    redo: {
        id: 'exploration.keybindings.Redo',
        defaultMessage: 'Redo',
    },
    logout: {
        id: 'exploration.keybindings.global.Logout',
        defaultMessage: 'Logout',
    },
    messaging: {
        id: 'exploration.keybindings.Messaging',
        defaultMessage: 'Open messaging window',
    },
    notifications: {
        id: 'exploration.keybindings.Notifications',
        defaultMessage: 'Open notifications popup',
    },
});

export const GLOBAL_SCOPE: KeyBindingScopeDescriptor = {
    id: GLOBAL_SCOPE_ID,
    name: messages.globalScope,
};

export const KEY_BINDINGS = defineKeyBindings({
    multipleSelection: {
        id: 'exploration.global.MultipleSelection',
        scope: GLOBAL_SCOPE,
        name: messages.multipleSelection,
        defaultKeys: 'ctrl+🖰',
        locked: true,
    },
    delete: {
        id: 'exploration.global.Delete',
        scope: GLOBAL_SCOPE,
        name: messages.delete,
        defaultKeys: 'Delete',
        locked: true,
    },
    undo: {
        id: 'exploration.global.Undo',
        scope: GLOBAL_SCOPE,
        name: messages.undo,
        defaultKeys: 'ctrl+Z',
        locked: true,
    },
    redo: {
        id: 'exploration.global.Redo',
        scope: GLOBAL_SCOPE,
        name: messages.redo,
        defaultKeys: 'ctrl+Y',
        locked: true,
    },
    logout: {
        id: 'exploration.global.Logout',
        scope: GLOBAL_SCOPE,
        name: messages.logout,
        defaultKeys: 'alt+ctrl+shift+L',
    },
    messaging: {
        id: 'exploration.case.Messaging',
        scope: GLOBAL_SCOPE,
        name: messages.messaging,
        defaultKeys: 'alt+ctrl+shift+M',
    },
    notifications: {
        id: 'exploration.case.Notifications',
        scope: GLOBAL_SCOPE,
        name: messages.notifications,
        defaultKeys: 'alt+ctrl+shift+N',
    },
});

