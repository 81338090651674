import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgFormLabel,
    ArgInputText,
    ArgModal,
    ClassValue,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { ExternalComponent } from '../../../models/external-component';
import { isResponseError } from '../../../../components/basic/utils/response-error';
import { SettingsConnector } from '../../../connectors/settings-connector';

import './create-external-component-modal.less';

const CLASSNAME = 'settings-create-external-component-modal';

const messages = defineMessages({
    createTitle: {
        id: 'settings.create-external-component-modal.title',
        defaultMessage: 'Create an external component',
    },
    editTitle: {
        id: 'settings.create-external-component-modal.editTitle',
        defaultMessage: 'Edit an external component',
    },
    cancel: {
        id: 'settings.create-external-component-modal.cancel',
        defaultMessage: 'Cancel',
    },
    submit: {
        id: 'settings.create-external-component-modal.submit',
        defaultMessage: 'Submit',
    },
    createExternalComponentError: {
        id: 'settings.create-external-component-modal.createExternalComponentError',
        defaultMessage: 'An error occurred while creating the external component',
    },
    externalComponentAlreadyExists: {
        id: 'settings.create-external-component-modal.externalComponentAlreadyExists',
        defaultMessage: 'The external component already exists',
    },
    incorrectURL: {
        id: 'settings.create-external-component-modal.incorrectURL',
        defaultMessage: 'Incorrect URL',
    },
    fieldKey: {
        id: 'settings.external-component-modal.field.key',
        defaultMessage: 'Key',
    },
    fieldUrl: {
        id: 'settings.external-component-modal.field.url',
        defaultMessage: 'URL',
    },
});

export interface ExternalComponentFormFields {
    name?: string;
    key?: string;
    isEnabled: boolean;
    getDefinitionUrl?: string;
    category?: string;
    description?: string;
}

type FormFieldsErrors = Partial<Record<keyof ExternalComponentFormFields, boolean>>;

export interface CreateExternalComponentModalProps {
    className?: ClassValue;
    externalComponent?: Partial<ExternalComponent>;
    onClose: () => void;
    onSuccess?: () => void;
}

export function CreateExternalComponentModal(props: CreateExternalComponentModalProps) {
    const { className, externalComponent, onClose, onSuccess } = props;
    const classNames = useClassNames(CLASSNAME);
    const notifications = useArgNotifications();

    const [submitForm, submitProgressMonitor] = useCallbackAsync(async (progressMonitor, values: ExternalComponentFormFields) => {
        if (!values.getDefinitionUrl || !values.key) {
            return;
        }

        try {
            if (!externalComponent?.key) {
                const createExternalComponentPayload = {
                    key: values.key,
                    getDefinitionUrl: values.getDefinitionUrl,
                };
                await SettingsConnector.getInstance().createExternalComponent(createExternalComponentPayload, progressMonitor);
            } else {
                const updateExternalComponentPayload = {
                    ...externalComponent,
                    getDefinitionUrl: values.getDefinitionUrl,
                    key: values.key,
                };
                await SettingsConnector.getInstance().putExternalComponent(externalComponent.key, updateExternalComponentPayload, progressMonitor);
            }
            onClose();
            onSuccess?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            if (isResponseError(error)) {
                if (error.problemDetails?.status === 409) {
                    notifications.snackError({ message: messages.externalComponentAlreadyExists }, error as Error);

                    return;
                }

                if (error.problemDetails?.status === 422) {
                    notifications.snackError({ message: messages.incorrectURL }, error as Error);

                    return;
                }
            }

            notifications.snackError({ message: messages.createExternalComponentError }, error as Error);
            throw error;
        }
    }, [externalComponent, onClose, onSuccess, notifications]);

    const validateForm = useCallback((values: ExternalComponentFormFields) => {
        const errors: FormFieldsErrors = {};
        if (!values.key) {
            errors.key = true;
        }
        if (!values.getDefinitionUrl) {
            errors.getDefinitionUrl = true;
        }

        return errors;
    }, []);

    const formInitialValues = useMemo<ExternalComponentFormFields>(() => {
        if (!externalComponent) {
            return { isEnabled: true };
        }

        return {
            ...externalComponent,
            isEnabled: externalComponent.isEnabled ?? false,
        };
    }, [externalComponent]);

    const {
        handleSubmit,
        handleChange,
        setFieldValue,
        values: formValues,
        errors: formErrors,
        resetForm,
    } = useFormik<ExternalComponentFormFields>({
        initialValues: formInitialValues,
        validateOnChange: false,
        validate: validateForm,
        onSubmit: submitForm,
    });

    const handleCancel = useCallback(() => {
        resetForm();
        onClose();
    }, [resetForm, onClose]);

    const handleInputChange = useCallback((fieldName: string, value: string | number | undefined) => {
        handleChange(fieldName);
        setFieldValue(fieldName, value);
    }, [handleChange, setFieldValue]);

    const submitDisabled = !formValues.getDefinitionUrl || !formValues.key;

    return (
        <ArgModal
            size='medium'
            title={externalComponent ? messages.editTitle : messages.createTitle}
            visible={true}
            onClose={handleCancel}
            onCancel={handleCancel}
            cancelText={messages.cancel}
            okText={messages.submit}
            onOk={() => handleSubmit()}
            okDisabled={submitDisabled}
            progressMonitor={submitProgressMonitor}
        >
            <form onSubmit={handleSubmit} autoComplete='off'>
                <div className={classNames('&-form-container')}>
                    <ArgFormLabel
                        propertyName={messages.fieldKey}
                        className={classNames('&-label', '&-label-key')}
                    >
                        <ArgInputText
                            value={formValues.key}
                            onInputChange={(value) => handleInputChange('key', value)}
                            data-testid='key'
                            state={formErrors.key ? 'invalid' : undefined}
                            autoFocus={true}
                        />
                    </ArgFormLabel>

                    <ArgFormLabel
                        propertyName={messages.fieldUrl}
                        className={classNames('&-label')}
                        addedRow={true}
                    >
                        <ArgInputText
                            value={formValues.getDefinitionUrl}
                            onInputChange={(value) => handleInputChange('getDefinitionUrl', value)}
                            data-testid='url'
                            state={formErrors.getDefinitionUrl ? 'invalid' : undefined}
                        />
                    </ArgFormLabel>
                </div>
            </form>

        </ArgModal>
    );
}
