import React, { useCallback, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { ArgButton, ArgInputText, ClassValue, useClassNames } from '../../../../basic';
import { IconDisplayer } from './icon-displayer';
import { IconsModal } from './icons-modal';
import { filterAndSortArrayByKeyword } from '../../../../../utils/filter-string-array';

import './icon-picker.less';

const messages = defineMessages({
    searchIcon: {
        id: 'exploration.icon.picker.search.input.placeholder',
        defaultMessage: 'Search',
    },
    selectIcon: {
        id: 'exploration.icon.picker.search.input.label',
        defaultMessage: 'Select icon',
    },
    viewAllIcons: {
        id: 'exploration.icon.picker.view.all.label',
        defaultMessage: 'View all',
    },
});

export const PAGE_COUNT = 21;

interface IconPickerProps {
    className?: ClassValue;
    icons: string[];
    selectedIcon?: string;

    onIconChange?: (iconName: string) => void;
}

export function IconPicker(props: IconPickerProps) {
    const {
        icons,
        selectedIcon,
        onIconChange,
        className,
    } = props;

    const classNames = useClassNames('graph-icon-picker');
    const intl = useIntl();

    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [filterdIcons, setFilterdIcons] = useState(icons);
    const [isIconsModalVisible, setIsIconsModalVisible] = useState(false);

    const numberOfPages = Math.ceil(filterdIcons.length / PAGE_COUNT);

    const handleNextPageClick = useCallback(() => {
        setPage((prevPage: number) => {
            if (prevPage < numberOfPages) {
                return prevPage + 1;
            }

            return prevPage;
        });
    }, [numberOfPages]);

    const handlePreviousPageClick = useCallback(() => {
        setPage((prevPage: number) => {
            if (prevPage > 1) {
                return prevPage - 1;
            }

            return prevPage;
        });
    }, []);

    const handleSearchTextChange = (value: string | null) => {
        setPage(1);
        if (value === null) {
            setFilterdIcons(icons);

            return;
        }

        const filteredAndSortedIcons = filterAndSortArrayByKeyword(icons, value);
        setFilterdIcons(filteredAndSortedIcons);
    };

    const handleAllModalSelection = (selectedIcon: string) => {
        const index = icons.indexOf(selectedIcon);

        if (index !== -1) {
            const page = Math.ceil(index / PAGE_COUNT) || 1;
            setPage(page);
        }

        // Clear filter as it's no longer revelant
        setSearchText('');
        setFilterdIcons(icons);

        onIconChange?.(selectedIcon);
    };

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-search')}>
                <span className={classNames('&-search-label')}>
                    <FormattedMessage {...messages.selectIcon} />
                </span>
                <ArgInputText
                    className={classNames('&-search-bar')}
                    left='magnifier'
                    value={searchText}
                    onInputChange={setSearchText}
                    placeholder={messages.searchIcon}
                    onChange={handleSearchTextChange}
                />
                {filterdIcons.length > 0 && (
                    <div className={classNames('&-pages-count-and-view-all-row')}>
                        <span className={classNames('&-pages-count')}>
                            {`${intl.formatNumber(page)}/${intl.formatNumber(numberOfPages)}`}
                        </span>
                        <ArgButton
                            className={classNames('&-view-all')}
                            label={intl.formatMessage(messages.viewAllIcons)}
                            type='link'
                            onClick={() => setIsIconsModalVisible(true)}
                        />
                    </div>
                )}
            </div>
            <div className={classNames('&-icon-displayer')}>
                <IconDisplayer
                    icons={filterdIcons}
                    selectedIcon={selectedIcon}
                    page={page}
                    pagesCount={numberOfPages}
                    onNextPageClick={handleNextPageClick}
                    onPreviousPageClick={handlePreviousPageClick}
                    onIconChange={onIconChange}
                />
            </div>
            {/* Do not use modalcontext, as the modal must be a child of the popover to prevent it from being closed when clicked */}
            <IconsModal
                visible={isIconsModalVisible}
                icons={icons}
                onIconChange={handleAllModalSelection}
                selectedIcon={selectedIcon}
                onClose={() => setIsIconsModalVisible(false)}
            />
        </div>
    );
}
