import { normalizeText } from 'src/components/basic';
import { COUNTRIES_BY_CODE, COUNTRIES_BY_CODE3, COUNTRIES_BY_FRENCH_NAME, COUNTRIES_BY_NAME } from 'src/constants/countries';
import { Country } from 'src/model/country';

export function getCountryByCodeOrName(codeOrName?: string): Country | null {
    if (!codeOrName) {
        return null;
    }

    const compareValue = normalizeText(codeOrName.toLowerCase());
    const country = COUNTRIES_BY_CODE[compareValue]
        ?? COUNTRIES_BY_CODE3[compareValue]
        ?? COUNTRIES_BY_NAME[compareValue]
        ?? COUNTRIES_BY_FRENCH_NAME[compareValue];

    return country ?? null;
}
