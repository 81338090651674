import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GetOntologiesDTO } from 'src/settings/models/dtoApi';
import { ArgTable2, ClassValue, useClassNames } from 'src/components/basic';
import { useTableColumns } from './columns';
import { useOntologiesState } from '../../providers/universes';
import { computeOntologyUrl } from 'src/settings/utils/compute-url';

interface UniverseTableProps {
    search?: string;
    className?: ClassValue;
}

export function UniverseTable(props: UniverseTableProps) {
    const { search, className } = props;
    const { ontologies } = useOntologiesState();
    const navigate = useNavigate();
    const classNames = useClassNames('settings-universe-table');

    const [universesIdSelected, setUniversesIdSelected] = useState<Set<string>>(() => new Set());

    const columns = useTableColumns(ontologies, universesIdSelected, setUniversesIdSelected, classNames);

    const onTableRowDoubleClick = useCallback((ontology: GetOntologiesDTO) => {
        const url = computeOntologyUrl(ontology.id);
        navigate(url);
    }, [navigate]);

    return (
        <ArgTable2<GetOntologiesDTO>
            className={classNames('&', className)}
            rowKey={(item) => item.id}
            onRowDoubleClick={onTableRowDoubleClick}
            columns={columns}
            dataSource={ontologies}
            bordered={true}
            highlightedRowCondition={(policy) => universesIdSelected.has(policy.id)}
            search={search}
        />
    );
}
