import { isEmpty } from 'lodash';

import {
    ArgButton,
    ArgIcon,
    ArgRenderedIcon,
    ArgRenderedText,
    BasicTag,
    ButtonClickEvent,
    ClassValue,
    useClassNames,
} from '../basic';
import { ExpandableItemInfo } from '../../hooks/use-expandable-items';

import './expandable-item.less';

const CLASSNAME = 'common-expandable-item';
const DEFAULT_ITEM_PADDING_LEFT = 20;
const CHILD_ITEM_PADDING_LEFT = `var(--arg-expand-item-padding, ${DEFAULT_ITEM_PADDING_LEFT}px)`;

interface ExpandableItemProps<T> {
    className?: ClassValue;
    expandableItem: ExpandableItemInfo<T>;
    search?: string;
    isExpanded?: boolean;
    onExpandClick?: (expandableItem: ExpandableItemInfo<T>, isExpanded: boolean, event: ButtonClickEvent) => void;
    allowOffset?: boolean;
    label?: ArgRenderedText;
    tagIcon: ArgRenderedIcon;
}

export function ExpandableItem<T>(props: ExpandableItemProps<T>) {
    const {
        className,
        expandableItem,
        search,
        isExpanded,
        onExpandClick,
        allowOffset = true,
        label,
        tagIcon,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const hasAnyChild = !isEmpty(expandableItem.children);
    let level = 0;
    for (let p: ExpandableItemInfo<T> | undefined = expandableItem.parent; p; p = p.parent) {
        level++;
    }

    const paddingLeftValue = computePaddingLeft({ allowOffset, level, hasAnyChild });

    return (
        <div
            className={classNames('&', className)}
            style={{ paddingLeft: !paddingLeftValue ? undefined : `${paddingLeftValue}` }}
        >
            {hasAnyChild &&
                <ArgButton
                    className={classNames('&-expand-item')}
                    type='ghost'
                    size='small'
                    icon={<ArgIcon size='medium' name={`icon-cheveron-${isExpanded ? 'up' : 'down'}`} />}
                    onClick={onExpandClick ? (event) => onExpandClick(expandableItem, !isExpanded, event) : undefined}
                />
            }
            <BasicTag
                icon={tagIcon}
                label={label}
                search={search}
                className={classNames('&-tag')}
                iconClassName={classNames('&-tag-icon')}
                iconColor='primary'
            />
        </div>
    );
}

function computePaddingLeft({ allowOffset, level, hasAnyChild }: { allowOffset: boolean, level: number, hasAnyChild: boolean }): string | undefined {
    let paddingLeft: string | undefined = undefined;
    if (!allowOffset) {
        return paddingLeft;
    }

    paddingLeft = `calc((${(level) + (!hasAnyChild ? 1 : 0)}) * ${CHILD_ITEM_PADDING_LEFT})`;

    return paddingLeft;
}
