import EventEmitter from 'eventemitter3';
import Semaphore from 'semaphore-async-await';

import { DelegatedActionRepository } from './delegated-action-repository';
import { Action } from './action';
import { HistoryManager } from '../history-manager';
import { HistoryType } from '../../model/history-entry';
import { GLOBAL_PM, ProgressMonitor, ProgressMonitorsFactoryType } from '../../components/basic';
import { JobQuitControl } from 'src/contexts/job-quit-control-context';

const SEMAPHORE_TIMEOUT_MS = 1000;

export class ActionsEngine<T = void> extends EventEmitter {
    readonly #historyManager: HistoryManager<ActionsEngine<T>>;
    readonly #repository: T;
    readonly #progressMonitorsFactory: ProgressMonitorsFactoryType;

    readonly #semaphore = new Semaphore(1);
    readonly #delegatedActionsRepository: DelegatedActionRepository<T>;
    readonly #jobQuitControl?: JobQuitControl;

    #actionId = 1;

    constructor(
        progressMonitorsFactory: ProgressMonitorsFactoryType,
        repository: T,
        historyManager?: HistoryManager,
        delegatedActionsRepository?: DelegatedActionRepository<T>,
        jobQuitControl?: JobQuitControl
    ) {
        super();

        this.#historyManager = historyManager || new HistoryManager<ActionsEngine<T>>();

        this.#repository = repository;

        this.#delegatedActionsRepository = delegatedActionsRepository || new DelegatedActionRepository();

        this.#progressMonitorsFactory = progressMonitorsFactory;

        this.#jobQuitControl = jobQuitControl;
    }

    ignoreHistory<K>(action: Action<T, K>): boolean {
        return false;
    }

    do<K>(action: Action<T, K>): Promise<K> {
        let promise = this._do(action);

        if (this.#jobQuitControl) {
            promise = this.#jobQuitControl.add(promise);
        }

        return promise;
    }

    async _do<K>(action: Action<T, K>): Promise<K> {
        if (this.ignoreHistory(action)) {
            let ret;
            const progressMonitorId = `do #${this.#actionId++}`;
            const progressMonitor = this.#progressMonitorsFactory(
                progressMonitorId,
                undefined,
                undefined,
                GLOBAL_PM
            );
            try {
                ret = await action.action(this.#historyManager, this, progressMonitor);
            } catch (error) {
                console.error(error);
            } finally {
                progressMonitor.done();
            }

            return ret as K;
        }

        if (action.lock !== false) {
            if (!await this.#semaphore.waitFor(SEMAPHORE_TIMEOUT_MS)) {
                throw new Error('Timeout');
            }
        }
        let k;
        try {
            const progressMonitorId = `do #${this.#actionId++}`;
            const progressMonitor = this.#progressMonitorsFactory(
                progressMonitorId,
                undefined,
                undefined,
                GLOBAL_PM
            );

            try {
                k = await this.#historyManager.trackAndExecute<K>(action, this, progressMonitor);
            } catch (x) {
                console.error(x);

                throw x;
            } finally {
                progressMonitor.done();
            }
        } finally {
            if (action.lock !== false) {
                this.#semaphore.release();
            }
        }

        return k;
    }

    insert(action: Action<T>) {
        this.#historyManager.track(action);
    }

    clear() {
        this.#historyManager.clear();
    }

    navigate(action: Action<T>, onlyType?: HistoryType): Promise<any> {
        let promise = this.__navigate(action, onlyType);

        if (this.#jobQuitControl) {
            promise = this.#jobQuitControl.add(promise);
        }

        return promise;
    }

    async __navigate(action: Action<T>, onlyType?: HistoryType) {
        if (action.lock !== false && !await this.#semaphore.waitFor(SEMAPHORE_TIMEOUT_MS)) {
            throw new Error('Timeout');
        }
        try {
            const progressMonitorId = `navigate #${this.#actionId++}`;
            const progressMonitor = this.#progressMonitorsFactory(progressMonitorId);

            try {
                await this.#historyManager.navigateAndExecute(action, this, false, progressMonitor);
            } catch (x) {
                console.error(x);
            } finally {
                progressMonitor.done();
            }
        } finally {
            if (action.lock !== false) {
                this.#semaphore.release();
            }
        }
    }


    undo(): Promise<any> {
        let promise = this._undo();

        if (this.#jobQuitControl) {
            promise = this.#jobQuitControl.add(promise);
        }

        return promise;
    }

    async _undo() {
        const action = this.#historyManager.getBefore() as Action<T>;
        if (!action) {
            return;
        }

        if (action.lock !== false && !await this.#semaphore.waitFor(SEMAPHORE_TIMEOUT_MS)) {
            throw new Error('Timeout');
        }
        try {
            const progressMonitorId = `undo #${this.#actionId++}`;
            const progressMonitor = this.#progressMonitorsFactory(
                progressMonitorId,
                undefined,
                undefined,
                { global: true }
            );

            try {
                await this.#historyManager.goBackwards(this, progressMonitor);
            } catch (x) {
                console.error(x);
                throw x;
            } finally {
                progressMonitor.done();
            }
        } finally {
            if (action.lock !== false) {
                this.#semaphore.release();
            }
        }
    }

    redo(): Promise<any> {
        let promise = this._redo();

        if (this.#jobQuitControl) {
            promise = this.#jobQuitControl.add(promise);
        }

        return promise;
    }

    async _redo() {
        const action = this.#historyManager.getAfter() as Action<T>;
        if (!action) {
            return;
        }

        if (action.lock !== false && !await this.#semaphore.waitFor(SEMAPHORE_TIMEOUT_MS)) {
            throw new Error('Timeout');
        }
        try {
            const progressMonitorId = `redo #${this.#actionId++}`;
            const progressMonitor = this.#progressMonitorsFactory(
                progressMonitorId,
                undefined,
                undefined,
                { global: true }
            );

            try {
                await this.#historyManager.goForwards(this, progressMonitor, true);
            } catch (x) {
                console.error(x);
            } finally {
                progressMonitor.done();
            }
        } finally {
            if (action.lock !== false) {
                this.#semaphore.release();
            }
        }
    }

    get historyManager(): HistoryManager<ActionsEngine<T>> {
        return this.#historyManager;
    }

    get repository(): T {
        return this.#repository;
    }

    get delegatedActionsRepository() {
        return this.#delegatedActionsRepository;
    }

    get jobQuitControl(): JobQuitControl | undefined {
        return this.#jobQuitControl;
    }

    protected get progressMonitorsFactory() {
        return this.#progressMonitorsFactory;
    }

    delegateAction(action: Action<T, any>, progressMonitor: ProgressMonitor) {
        this.#delegatedActionsRepository.executeDo(action, this, progressMonitor);
    }

    delegateReverseAction(action: Action<T, any>, progressMonitor: ProgressMonitor) {
        this.#delegatedActionsRepository.executeUndo(action, this, progressMonitor);
    }
}
