import { defineMessages } from 'react-intl';

import { defineKeyBindings, KeyBindingScopeDescriptor } from '../../components/basic';
import { GLOBAL_SCOPE } from './global';

const messages = defineMessages({
    folderScope: {
        id: 'preparation.keybindings.FolderScope',
        defaultMessage: 'Folder Page',
    },
    backToHome: {
        id: 'preparation.keybindings.BackToHomeFromFolder',
        defaultMessage: 'Back to home page',
    },
});

export const SCOPE: KeyBindingScopeDescriptor = {
    id: 'preparation.Folder',
    name: messages.folderScope,
    hidden: true,
    extends: GLOBAL_SCOPE,
};

export const KEY_BINDINGS = defineKeyBindings({
    backToHome: {
        id: 'preparation.folder.BackToHome',
        scope: SCOPE,
        name: messages.backToHome,
        defaultKeys: 'alt+ArrowLeft',
    },
});

