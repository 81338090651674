import { ReactNode, useCallback, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useParams } from 'react-router-dom';

import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';
import { PageHeader } from 'src/settings/common-components/page-header';
import { ProgressMonitor, useClassNames, useMemoAsync, useArgNotifications } from 'src/components/basic';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { TableToolbar } from '../components/table-toolbar/table-toolbar';
import { FormPoliciesTable } from '../components/form-policies-table/form-policies-table';

import './form-policies-view.less';

const FORCE_LOADING = false;

const messages = defineMessages({
    formPolicies: {
        id: 'settings.form-policies-page.title',
        defaultMessage: 'Form policies',
    },
    fetchingFormPolicies: {
        id: 'settings.formPolicies.fetch-form-policies',
        defaultMessage: 'Fetching form policies...',
    },
    fetchingFormPoliciesError: {
        id: 'settings.formPolicies.fetch-form-policies.error',
        defaultMessage: 'An error occurred while fetching form policies',
    },
});

export const FormPoliciesView = () => {
    const classNames = useClassNames('settings-form-policies-view');
    const [stateId, setStateId] = useState<number>(0);
    const [search, setSearch] = useState<string | undefined>();
    const notifications = useArgNotifications();
    const { ontologyId, universeId } = useParams<{ ontologyId: string; universeId: string }>();

    const [formPolicies, fetchFormPoliciesPM] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        if (!universeId) {
            return [];
        }
        try {
            const ret = await explorationSettingsConnector.getFormPolicies(universeId, progressMonitor);

            return ret;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.fetchingFormPoliciesError }, error as Error);
            throw error;
        }
    }, [stateId, universeId], messages.fetchingFormPolicies);

    const handleRefresh = useCallback(() => {
        setStateId((id) => id + 1);
    }, []);

    let body: ReactNode = null;

    if (FORCE_LOADING || fetchFormPoliciesPM?.isRunning || !formPolicies) {
        body = (
            <div className={classNames('&-body', 'loading')}>
                <LoadingPane className={classNames('&-pane')} progressMonitor={fetchFormPoliciesPM} />
            </div>
        );
    } else {
        body = <div className={classNames('&-body')}>
            <TableToolbar
                className={classNames('&-body-toolbar')}
                onCreatePolicy={handleRefresh}
                setSearch={setSearch}
            />
            <div className={classNames('&-body-table-container')}>
                {ontologyId && universeId && (
                    <FormPoliciesTable
                        className={classNames('&-body-table')}
                        formPolicies={formPolicies}
                        search={search}
                        handleRefresh={handleRefresh}
                        ontologyId={ontologyId}
                        universeId={universeId}
                    />
                )}
            </div>
        </div>;
    }

    return (
        <div className={classNames('&')}>
            <PageHeader
                className={classNames('&-header')}
                title={messages.formPolicies}
            />

            {body}
        </div>
    );
};
