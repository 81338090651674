import React from 'react';

import {
    ArgButton,
    ArgIcon,
    ArgRenderFunction,
    BasicTagIcon,
    ClassValue,
    TooltipPlacement,
    useClassNames,
} from '../../../../basic';
import { VertexBadge } from '../../../../../exploration/common/badges/vertex-badge';

import './button-with-badge.less';

interface ButtonWithBadgeProps {
    iconName?: string;
    iconColor?: string;
    badgeIconName?: string;
    className?: ClassValue;

    popover?: React.ReactNode | ArgRenderFunction;
    popoverVisible?: boolean;
    onPopoverVisibleChange?: (visible: boolean) => void;
    popoverPlacement?: TooltipPlacement;
    popoverClassName?: ClassValue;

    backgroundColor?: string;
    borderColor?: string;
}

export function ButtonWithBadge(props: ButtonWithBadgeProps) {
    const {
        className,
        popover,
        popoverVisible,
        onPopoverVisibleChange,
        popoverPlacement,
        popoverClassName,
        iconName,
        iconColor,
        backgroundColor,
        borderColor,
    } = props;

    const classNames = useClassNames('button-with-badge');

    const badges = <VertexBadge
        key='badge'
        position='topRight'
        name='icon-pencil'
        backgroundColor='white'
        color='black'
        size='large'
    />;

    const iconComponent = iconName && <ArgIcon
        name={iconName}
        size='medium'
        color={iconColor}
    />;

    const avatar = <BasicTagIcon
        className={classNames('&-icon')}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        icon={iconComponent}
        badges={badges}
    />;

    return (
        <ArgButton
            type='ghost'
            className={classNames('&', className)}
            popover={popover}
            popoverVisible={popoverVisible}
            onPopoverVisibleChange={onPopoverVisibleChange}
            popoverPlacement={popoverPlacement}
            popoverClassName={classNames('&-popover', popoverClassName)}
            icon={avatar}
            size='medium'
        />
    );
}
