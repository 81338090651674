import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { defineMessages } from 'react-intl';

import { $yield, ArgMenu, ArgMenuItem, ClassValue, useArgModalContext, useClassNames } from 'src/components/basic';
import { FullOntology, FullOntologyObjectType } from '../../types';
import { DeleteObjectModal } from '../delete-object-modal/delete-object-modal';
import { FeedSourcesModal } from '../feed-sources-modal/feed-sources-modal';
import { RenameObjectModal } from '../rename-object-modal/rename-object-modal';

import './object-menu.less';

const messages = defineMessages({
    rename: { id: 'settings.universes.object.rename', defaultMessage: 'Rename' },
    delete: { id: 'settings.universes.object.delete', defaultMessage: 'Delete' },
    feedSources: { id: 'settings.universes.object.feedSources', defaultMessage: 'Feed sources' },
});

export interface ObjectMenuProps {
    className?: ClassValue;

    vertex: FullOntologyObjectType;
    x: number;
    y: number;
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
    closeMenu: () => void;
}

export function ObjectMenu(props: ObjectMenuProps) {
    const {
        className,
        vertex,
        x,
        y,
        ontology,
        setOntology,
        closeMenu,
    } = props;

    const classNames = useClassNames('settings-object-menu');

    const modalContext = useArgModalContext();

    const handleOpenFeedSourcesModal = useCallback(() => {
        modalContext.open('feed-sources',
            <FeedSourcesModal
                closeModal={() => {
                    modalContext.close('feed-sources');
                }}
                vertexOrEdge={vertex}
                ontology={ontology}
                ontologyType='object'
            />
        );
        closeMenu();
    }, [modalContext, ontology, vertex, closeMenu]);

    const handlesRenameObjectModal = useCallback(() => {
        modalContext.open('rename-object',
            <RenameObjectModal
                closeModal={() => {
                    modalContext.close('rename-object');
                    closeMenu();
                }}
                vertex={vertex}
                ontology={ontology}
                setOntology={setOntology}
            />
        );
    }, [closeMenu, modalContext, ontology, setOntology, vertex]);

    const handleDeleteObjectModal = useCallback(() => {
        modalContext.open('delete-object',
            <DeleteObjectModal
                closeModal={() => {
                    modalContext.close('delete-object');
                    closeMenu();
                }}
                vertex={vertex}
                ontology={ontology}
                setOntology={setOntology}
            />
        );
    }, [closeMenu, modalContext, ontology, setOntology, vertex]);

    useEffect(() => {
        const captureEvent = (event: Event) => {
            const target = event.target as Element | null;
            if (target?.closest && !target.closest(`.${classNames('&-div')}`)) {
                closeMenu();
            }
        };

        $yield(() => {
            // Must wait for the OPEN event to be processed
            window.addEventListener('mousedown', captureEvent);
            window.addEventListener('keydown', captureEvent);
            window.addEventListener('focus', captureEvent);
        });

        return () => {
            window.removeEventListener('mousedown', captureEvent);
            window.removeEventListener('keydown', captureEvent);
            window.removeEventListener('focus', captureEvent);
        };
    }, [classNames, closeMenu]);

    return (
        <div
            className={classNames('&-div', className)}
            style={{
                left: `${x}px`,
                top: `${y}px`,
            }}
        >
            <ArgMenu className={classNames('&-menu')}>
                <ArgMenuItem
                    data-testid='rename'
                    onClick={handlesRenameObjectModal}
                    label={messages.rename}
                />
                <ArgMenuItem
                    data-testid='delete'
                    onClick={handleDeleteObjectModal}
                    label={messages.delete}
                />
                <ArgMenuItem
                    data-testid='Feed sources'
                    onClick={handleOpenFeedSourcesModal}
                    label={messages.feedSources}
                />
            </ArgMenu>
        </div>
    );
}
