import React, { ComponentType, ReactNode, useCallback } from 'react';
import { isValidElementType } from 'react-is';
import { isFunction } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgButton, ArgButtonProps, ButtonClickEvent } from '../arg-button/arg-button';
import { getToolTooltip, isToolDisabled, isToolSelected, isToolVisible, Tool, ToolType } from './tool';
import { ArgButtonType, ArgSize } from '../types';
import { ArgPopoverRenderer } from '../arg-popover/types';

import './arg-toolbar-item.less';

export interface ArgToolItemRenderContext {
    onCloseMenu?(): void;

    menuItemClassName?: ClassValue;
}

export type ArgToolItemRenderFunction<T = undefined> = (props: Tool<T>, environmentContext: T) => ReactNode;
export type ArgToolItemRenderWithContextFunction<T = undefined> = (props: Tool<T>, environmentContext: T, context: ArgToolItemRenderContext, popoverRender?: ArgPopoverRenderer) => ReactNode;

export interface ArgToolItemProps<T> extends Omit<ArgButtonProps, 'type' | 'size' | 'disabled' | 'onClick' | 'onShiftClick' | 'onAltClick' | 'onCtrlClick'> {
    path: string;
    type?: ToolType;
    size?: ArgSize;
    buttonType?: ArgButtonType;
    selected?: boolean | ((environmentContext: T) => boolean);
    visible?: boolean | ((environmentContext: T) => boolean);
    disabled?: boolean | ((environmentContext: T) => boolean);
    testid?: string;
    componentType?: ComponentType;
    customRender?: ArgToolItemRenderWithContextFunction<T>;
    menuItemCustomRender?: boolean;
    onClick?: (props: Tool<T>, environmentContext: T, event: ButtonClickEvent) => void;
    onShiftClick?: (props: Tool<T>, environmentContext: T, event: ButtonClickEvent) => void;
    onAltClick?: (props: Tool<T>, environmentContext: T, event: ButtonClickEvent) => void;
    onCtrlClick?: (props: Tool<T>, environmentContext: T, event: ButtonClickEvent) => void;
    environmentContext: T;
}

export function ArgToolbarItem<T = undefined>(props: ArgToolItemProps<T>) {
    const {
        type,
        hidden,
        size = 'medium',
        testid,
        className,
        customRender,
        componentType,
        keyBinding,
        buttonType = 'ghost',
        popover,
        environmentContext,
        ...restProps
    } = props;

    const classNames = useClassNames('arg-toolbar-item');

    const toolProps = props as Tool<T>; // @TODO improve perf ?

    const handleClick = useCallback((event: ButtonClickEvent) => {
        toolProps.onClick?.(toolProps, environmentContext, event);
    }, [environmentContext, toolProps]);

    const handleShiftClick = useCallback((event: ButtonClickEvent) => {
        toolProps.onShiftClick?.(toolProps, environmentContext, event);
    }, [environmentContext, toolProps]);

    const handleAltClick = useCallback((event: ButtonClickEvent) => {
        toolProps.onAltClick?.(toolProps, environmentContext, event);
    }, [environmentContext, toolProps]);

    const handleCtrlClick = useCallback((event: ButtonClickEvent) => {
        toolProps.onCtrlClick?.(toolProps, environmentContext, event);
    }, [environmentContext, toolProps]);

    if (hidden) {
        return null;
    }

    if (!isToolVisible(toolProps, environmentContext)) {
        return null;
    }

    if (isFunction(customRender)) {
        const context = {};

        return <>
            {customRender(toolProps, environmentContext, context, popover)}
        </>;
    }

    const selected = isToolSelected(toolProps, environmentContext);
    const disabled = isToolDisabled(toolProps, environmentContext);

    const cls = {
        selected,
    };

    let Component: ComponentType<ArgButtonProps> = ArgButton;

    if (isValidElementType(componentType)) {
        Component = componentType as ComponentType<ArgButtonProps>;
    }

    let tooltip = getToolTooltip(toolProps, environmentContext);
    if (tooltip === undefined && keyBinding) {
        tooltip = keyBinding.name;
    }

    return (
        <Component
            className={classNames('&', `&-${type}`, className, cls)}
            {...restProps}
            onClick={toolProps.onClick ? handleClick : undefined}
            onShiftClick={toolProps.onShiftClick ? handleShiftClick : undefined}
            onAltClick={toolProps.onAltClick ? handleAltClick : undefined}
            onCtrlClick={toolProps.onCtrlClick ? handleCtrlClick : undefined}
            disabled={disabled}
            type={type === 'menu' ? 'primary-menu' : buttonType}
            size={size}
            tooltipClassName={classNames('&-tooltip')}
            tooltip={tooltip}
            keyBinding={keyBinding}
            data-testid={testid}
            data-tool-path={toolProps.path}
            data-tool-order={toolProps.order}
            popover={popover}
        />
    );
}
