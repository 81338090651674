import { ResourceCasePiece } from 'src/model/resource';
import { ImageMetadata } from '../../components/basic';
import { ExplorationId } from './exploration';
import { UniverseId } from './universe';

export const SCREENSHOT_METADATA = 'screenshot';

export interface ScreenshotImageMetadata extends ImageMetadata {
  explorationId?: ExplorationId;
  universeId?: UniverseId;
  explorationLayout?: Record<string, any>;
}

export interface ScreenshotCasePiece extends ResourceCasePiece {
  imageMetadata?: ScreenshotImageMetadata;
}
