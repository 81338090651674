import { ArgUserInfo } from 'src/components/basic';
import { RuleTarget, ScopeProcessed } from './policy';
import { Scope } from './policy';

export type ValuationPolicyId = string;
export enum ValuationEffectEvent {
  Create = 'Create',
  Update = 'Update',
  All = 'All',
}

export interface ValuationEffectSetter {
  onEvent?: ValuationEffectEvent;
  name?: string;
  value?: string;
}

export interface ValuationEffectFilter {
    match?: string[];
    setProperty?: ValuationEffectSetter;
    setMetadata?: ValuationEffectSetter;
}

export interface ValuationRuleEffect {
    object?: ValuationEffectFilter;
    property?: ValuationEffectFilter;
}

export interface ValuationRule {
    InferenceDetection: boolean;
    Targets: RuleTarget[];
    Effects: ValuationRuleEffect[];
}
export interface ValuationRuleProcessed {
    InferenceDetection: boolean;
    Targets: RuleTarget[];
    Effects: ValuationRuleEffect[];
    id: string;
}

export interface ValuationPolicyProcessed extends ValuationPolicy {
  statement: { Actions: ValuationRuleProcessed[] };
  scopes: ScopeProcessed[];
}

export interface PolicyVersion {
  /**
   * Indicates the version currently in use.
   *
   * - `published` cannot be defined if `required` is not defined;
   * - when `required` and `published` do not match - all necessary changes will be applied to
   * the universe in way that `published` match `required`;
   * @format int32
   * @example 41
   */
  published?: number;

  /**
   * Indicates the desired version to use.
   *
   * - When `required` and `published` do not match - all necessary changes will be applied to
   * the universe in way that `published` match `required`.
   * - `required` takes the value of `latest` each time the policy is published.
   * @format int32
   * @example 42
   */
  required?: number;

  /**
   * The latest available version of that policy.
   * @format int32
   * @example 42
   */
  latest: number;
}

export interface ValuationPolicy {
  /**
   * A unique identifier for the universe where the policy apply.
   * @format uuid
   * @example e24900a4-502a-487d-a730-f59ca39f4779
   */
  universeId: string;

  /**
   * A name for that policy.
   * @example Name of the policy
   */
  name: string;

  /**
   * A description for that policy.
   * @example Description of the policy
   */
  description?: string;

  /**
   * A list of scopes that defines how and when that policy applies.
   *
   * Valid types are:
   *   - ```scope.always``` : match any context
   *   - ```scope.user``` : applies only for specific users
   *   - ```scope.group``` : applies only for specific groups
   *   - ```scope.variable``` : applies depending on values of administration variables
   * @example [{"type":"scope.always"}]
   */
  scopes?: Scope[];

  /**
   * *true* if that policy must be evaluated, otherwise false.
   * @example true
   */
  enabled?: boolean;

  /**
   * A statement to enforce when that policy applies.
   * @example {"actions":[{"targets":[{"or":[{"object":{"_kind":"Vertex","_type":"investment"}},{"and":[{"object":{"_kind":"Vertex","_type":"organization"}},{"or":[{"object":{"employees":">300"}},{"object":{"revenue":">1000000000"}}]}]}]}],"effects":[{"object":{"setProperty":{"onEvent":"all","name":"source","value":"manual"}}},{"property":{"match":["*phone"],"setMetadata":{"onEvent":"create","name":"classification","value":"profile:habilitation"}}}]},{"targets":[{"object":{"_kind":"Edge","_type":"*invested*"}}],"effects":[{"property":{"match":["Amount"],"setMetadata":{"onEvent":"update","name":"classification","value":"profile:habilitation"}}}]}]}
   */
  statement?: { Actions: ValuationRule[] };

  /**
   * A value defining the structure of *statement*.
   * *type* may be:
   * - "data.valuation.vector" - object valuation rules based on object properties.
   * @example data.valuation.vector
   */
  type: string;

  /**
   * A unique identifier for that policy.
   * @example e24900a4-502a-487d-a730-f59ca39f4779
   */
  id: ValuationPolicyId;

  /**
   * If the policy has effects that need to be applied to the universe, the status indicates the progress of the operation.
   *
   * Possible values are:
   * - *editing*: The policy has modifications that were not published. The update in the universe will not start.
   * - *pending*: The policy need to be applied to the universe and is waiting.
   * - *inProgress*: The policy effects are progressively applied to the universe. The policy will be activated once the operation completed.
   * - *completed*: The universe is up to date with this policy
   */
  updateStatus: 'Editing' | 'Pending' | 'InProgress' | 'Completed';

  /** Versioning information of that policy. */
  version: PolicyVersion;

  /**
   * A ratio between *0* and *1* that indicates the progress of the update. If the policy is not in update, value is *null*.
   * @format float
   */
  updateProgress?: number;
  createdBy?: ArgUserInfo;

  /**
   * Date of creation of that policy.
   * @example 2026-04-05T09:06:11+00:00
   */
  createdDate?: Date;
  lastUpdatedBy?: ArgUserInfo;

  /**
   * Date of Last update of that policy.
   * @example 2026-04-05T09:09:11+00:00
   */
  lastUpdatedDate?: Date;
  lastPublishedBy?: ArgUserInfo;

  /**
   * Date of Last publish of that policy.
   * @example 2026-04-05T09:09:11+00:00
   */
  lastPublishedDate?: Date;
}

export type ValuationPolicyPostRequest = Omit<ValuationPolicy, 'id' | 'version' | 'updateStatus' | 'updateProgress' | 'createdBy' | 'createdDate' | 'lastUpdatedBy' | 'lastUpdatedDate' | 'lastPublishedBy' | 'lastPublishedDate'>;
