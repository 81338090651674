import { Role } from '../models/dtoApi';

export const getRoleIdsFromRoleNames = (listOfRoles: Role[], roleNames: string[]) => {
    return listOfRoles.filter((role) => roleNames.includes(role.name)).map((role) => role.id);
};
export const getRoleNamesFromRoleIds = (listOfRoles: Role[], roleIds?: string[]) => {
    return listOfRoles.filter((role) => (roleIds || []).includes(role.id)).map((role) => role.name);
};

export const getRolesFromRoleIds = (listOfRoles: Role[], roleIds?: string[]) => {
    return listOfRoles.filter((role) => (roleIds || []).includes(role.id));
};
