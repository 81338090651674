import { isEmpty, last, uniq } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgMessageRenderer,
    ArgNodeKey,
    ArgNodePath,
    ArgRenderedText,
    ArgSelectionChangeReason,
    ArgTooltip2,
    ArgTree,
    ArgTreeChangeDetails,
    ClassValue,
    highlightSplit,
    normalizeText,
    ProgressMonitor,
    renderText,
    SubProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
    useMemoAsync,
} from 'src/components/basic';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { Role, RoleId, RolePermission, RolesScope } from 'src/settings/models/dtoApi';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';
import { PreparationPermissions } from '../../../preparation/permissions/permissions';
import { AdministrationPermissions } from '../../../administration/permissions/permissions';
import { SettingsConnector } from '../../connectors/settings-connector';

import './role-permissions.less';

export const messages = defineMessages({
    noRoleSelected: {
        id: 'settings.role-permissions.no-role-selected',
        defaultMessage: 'No Role selected',
    },
    noPermissions: {
        id: 'settings.role-permissions.no-permissions',
        defaultMessage: 'No permissions available',
    },
    componentsSection: {
        id: 'settings.role-permissions.components-section',
        defaultMessage: 'Components library',
    },
    othersSection: {
        id: 'settings.role-permissions.others-section',
        defaultMessage: 'Others',
    },
    loadPermissionsError: {
        id: 'settings.roles-permissions.loadPermissionsError',
        defaultMessage: 'An error occurred while loading permission',
    },
    editPermissionsError: {
        id: 'settings.roles-permissions.editPermissionsError',
        defaultMessage: 'An error occurred while editing permission',
    },
    addLinkedPermissionsTooltip: {
        id: 'settings.roles-permissions.addLinkedPermissionsTooltip',
        defaultMessage: 'By enabling the "{ permissionName }" permission, the related permissions will also be enabled:',
    },
    removeLinkedPermissionsTooltip: {
        id: 'settings.roles-permissions.removeLinkedPermissionsTooltip',
        defaultMessage: 'By disabling the "{ permissionName }" permission, the related permissions will also be disabled:',
    },
});

type PermissionSubDependencies<T> = Partial<Record<keyof T, (keyof T)[]>>

const SETTINGS_PERMISSIONS_SUB_DEPENDENCIES: PermissionSubDependencies<SettingsPermissions> = {
    'admin.user.access': ['admin.user.role.access'],
    'admin.user.edition': ['admin.user.access'],
    'admin.user.management': ['admin.user.access'],
    'admin.user.group.edition': ['admin.user.group.access'],
    'admin.user.role.edition': ['admin.user.role.access'],
    'admin.user.fields.management': ['admin.user.fields.access'],
    'admin.webhook.edition': ['admin.webhook.access'],
    'admin.webhook.export': ['admin.webhook.access'],
};

const ADMINISTRATION_PERMISSIONS_SUB_DEPENDENCIES: PermissionSubDependencies<AdministrationPermissions> = {
    'admin.synchronization.execution': ['admin.synchronization.management'],
};

const PREPARATION_PERMISSIONS_SUB_DEPENDENCIES: PermissionSubDependencies<PreparationPermissions> = {
    'preparation.folder.export': ['preparation.create.folder'],
    'preparation.process.access': ['preparation.create.folder'],
    'preparation.process.edition': [
        'preparation.create.folder',
        'preparation.process.access',
    ],
    'preparation.process.export': [
        'preparation.create.folder',
        'preparation.process.access',
    ],
    'preparation.process.execution': [
        'preparation.create.folder',
        'preparation.process.access',
    ],
    'preparation.process.prioritization.access': [
        'preparation.create.folder',
        'preparation.process.access',
    ],
    'preparation.process.prioritization.edition': [
        'preparation.create.folder',
        'preparation.process.access',
        'preparation.process.prioritization.access',
    ],
    'preparation.process.execution.management': [
        'preparation.process.execution',
    ],
    'preparation.composite.component.edition': [
        'preparation.create.folder',
        'preparation.composite.component.access',
    ],
    'preparation.composite.component.export': [
        'preparation.create.folder',
        'preparation.composite.component.access',
    ],
    'preparation.remote.component.edition': [
        'preparation.create.folder',
        'preparation.remote.component.access',
    ],
    'preparation.remote.component.export': [
        'preparation.create.folder',
        'preparation.remote.component.access',
    ],
    'preparation.secret.edition': [
        'preparation.create.folder',
        'preparation.secret.access',
    ],
    'preparation.secret.export': [
        'preparation.create.folder',
        'preparation.secret.access',
    ],
    'preparation.code.fragment.edition': [
        'preparation.code.fragment.access',
    ],
    'preparation.code.fragment.export': [
        'preparation.code.fragment.access',
    ],
};

const PERMISSIONS_SUB_DEPENDENCIES: Record<string, string[]> = {
    ...SETTINGS_PERMISSIONS_SUB_DEPENDENCIES,
    ...ADMINISTRATION_PERMISSIONS_SUB_DEPENDENCIES,
    ...PREPARATION_PERMISSIONS_SUB_DEPENDENCIES,
};

interface TreeData {
    key: string;
    label: ArgRenderedText;
    children?: TreeData[];
    disabled: boolean;
}

export interface RolesPermissionsProps {
    filter: string | undefined;
    selectedRole: Role | undefined;
    setSelectedRole: Dispatch<SetStateAction<Role | undefined>>;
    rolesScope: RolesScope;
    onPermissionUpdate: (roleId: RoleId, progressMonitor: ProgressMonitor) => void;
    className?: ClassValue;
}

export function RolePermissions(props: RolesPermissionsProps) {
    const {
        filter,
        selectedRole,
        setSelectedRole,
        rolesScope,
        onPermissionUpdate,
        className,
    } = props;

    const [openedNodes, setOpenedNodes] = useState<ArgNodeKey[]>([]);
    const [selectedList, setSelectedList] = useState<ArgNodeKey[]>();

    const classNames = useClassNames('settings-role-manager-permissions');
    const notifications = useArgNotifications();

    const canEditRoles = useHasPermission<SettingsPermissions>('admin.user.role.edition');

    const [permissionList] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        if (!rolesScope) {
            return undefined;
        }

        try {
            const permissions = await SettingsConnector.getInstance().getRolePermissions(rolesScope, progressMonitor);

            const scopedPermissions = permissions.filter((permission) => permission.scope === rolesScope);

            return scopedPermissions;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.loadPermissionsError }, error as Error);
            throw error;
        }
    }, [notifications, rolesScope]);


    useEffect(() => {
        if (!permissionList) {
            return;
        }

        const sections: Record<string, true> = {};
        permissionList.forEach((p) => {
            const { section } = p;
            if (!section) {
                return;
            }

            let k = '';
            section.split('/').forEach((p) => {
                k += ((k) ? '/' : '') + p;
                sections[k] = true;
            });
        });

        setOpenedNodes([...Object.keys(sections)]);
    }, [permissionList]);

    const filteredPermissionList = useMemo(() => {
        return permissionList?.filter((permission) => {
            return !(filter && normalizeText(permission.name).indexOf(normalizeText(filter)) === -1);
        });
    }, [filter, permissionList]);

    useEffect(() => {
        if (!selectedRole || !permissionList) {
            setSelectedList(undefined);

            return;
        }

        if (selectedRole.readOnly) {
            setSelectedList(permissionList.map((permission) => {
                return permission.key;
            }));

            return;
        }

        setSelectedList(selectedRole.permissions.filter((permission) => {
            return permission.isAccess;
        }).map((permission) => {
            return permission.key;
        }));
    }, [permissionList, selectedRole]);

    const handleGetNodeLabel = useCallback((items: ArgNodePath<TreeData>) => {
        const item = items[items.length - 1];
        const subDependencies = PERMISSIONS_SUB_DEPENDENCIES?.[item.key];

        const isSelected = selectedList?.includes(item.key);

        if (!subDependencies) {
            return renderText(item.label);
        }

        const subDependenciesLabel = subDependencies.reduce<RolePermission[]>((acc, dependency) => {
            if (!permissionList) {
                return acc;
            }
            const permission = permissionList.find((p) => p.key === dependency);

            if (!permission) {
                return acc;
            }

            acc.push(permission);

            return acc;
        }, []);

        return (
            <ArgTooltip2
                title={(
                    <>
                        <ArgMessageRenderer
                            message={isSelected ? messages.removeLinkedPermissionsTooltip : messages.addLinkedPermissionsTooltip}
                            messageValues={{ permissionName: item.label }}
                        />

                        <ul className={classNames('&-linked-tooltip')}>
                            {subDependenciesLabel.map((dependency) => {
                                return (
                                    <li key={dependency.id}>
                                        {dependency.name}
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                )}
            >
                <div>
                    {renderText(item.label)}
                </div>
            </ArgTooltip2>
        );
    }, [classNames, permissionList, selectedList]);

    const handleOnOpenNodes = useCallback((nodeKeys: SetStateAction<ArgNodeKey[]>) => {
        setOpenedNodes(nodeKeys);
    }, []);

    function handleIsNodeUncheckable(path: ArgNodePath<TreeData>, key: string): boolean {
        if (!canEditRoles) {
            return true;
        }

        const permissionDependencies = Object.keys(PERMISSIONS_SUB_DEPENDENCIES).filter((dependenceKey) => {
            const value = PERMISSIONS_SUB_DEPENDENCIES[dependenceKey];

            return value.includes(key);
        });

        if (permissionDependencies.length > 0) {
            return !!permissionDependencies.find((dep) => selectedList?.includes(dep));
        }

        return last(path)?.disabled ?? false;
    }

    const permissionsTree = useMemo(() => {
        const root: TreeData[] = [];
        if (isEmpty(filteredPermissionList)) {
            return root;
        }

        filteredPermissionList!.forEach((permission) => {
            let parents = root;
            const section = permission.section;
            if (section) {
                let k = '';
                section.split('/').forEach((label) => {
                    k += `${(k) ? '/' : ''}${label}`;

                    let sectionNode: TreeData | undefined = parents.find((n) => (n.key === k));
                    if (sectionNode) {
                        parents = sectionNode.children!;

                        return;
                    }

                    sectionNode = {
                        key: k,
                        label,
                        children: [],
                        disabled: selectedRole?.readOnly ?? true,
                    };
                    parents.push(sectionNode);
                    parents = sectionNode.children!;
                });
            }

            const newNode: TreeData = {
                key: permission.key,
                label: highlightSplit(permission.name, filter),
                disabled: selectedRole?.readOnly ?? true,
            };
            parents.push(newNode);
        });

        return root;
    }, [filter, filteredPermissionList, selectedRole]);

    const hasChildren = useCallback((node: ArgNodePath<TreeData>) => {
        return last(node)?.children !== undefined && last(node)!.children!.length > 0;
    }, []);

    const getPermissionToUpdate = useCallback((key: string, isAccess: boolean) => {
        if (!selectedRole) {
            return;
        }

        const userRolePermission = selectedRole.permissions.find((permission: RolePermission) => permission.key === key);

        const updatedPermission: RolePermission = {
            id: userRolePermission ? userRolePermission.id : '00000000-0000-0000-0000-000000000000',
            roleId: selectedRole.id,
            key: key,
            name: userRolePermission ? userRolePermission.name : '',
            scope: rolesScope,
            isAccess: isAccess,
            section: userRolePermission ? userRolePermission.section : 'components',
            type: '',
        };

        const permissionIndex = userRolePermission ? selectedRole.permissions.indexOf(userRolePermission) : -1;

        let permissionsUpdated: RolePermission[] = [];
        if (permissionIndex < 0) {
            permissionsUpdated = [...selectedRole.permissions, updatedPermission];
        } else {
            permissionsUpdated = [...selectedRole.permissions];
            permissionsUpdated[permissionIndex].isAccess = isAccess;
        }

        setSelectedRole({ ...selectedRole, permissions: permissionsUpdated });

        return updatedPermission;
    }, [rolesScope, selectedRole, setSelectedRole]);

    const [handleSelection] = useCallbackAsync(async (progressMonitor: ProgressMonitor, selection: ArgNodeKey[], details: ArgTreeChangeDetails, reason: ArgSelectionChangeReason) => {
        const groupApiCalls: Promise<void>[] = [];

        const addedLinkedPermissions: string[] = [];
        const addedPermissionKeys = details.addedKeys.reduce<string[]>((acc, key) => {
            const permissionExist = permissionList?.find((p) => p.key === key);

            if (!permissionExist) {
                return acc;
            }

            const subDependencies = PERMISSIONS_SUB_DEPENDENCIES?.[key];

            if (!subDependencies) {
                acc.push(key);

                return acc;
            }


            const subPermissionsExist = subDependencies.filter((permissionId) => {
                return permissionList?.find((p) => p.key === permissionId);
            });

            addedLinkedPermissions.push(key);

            acc.push(...subPermissionsExist, key);

            return acc;
        }, []);

        addedPermissionKeys.forEach((key) => {
            const permissionToUpdate = getPermissionToUpdate(key, true);
            if (!permissionToUpdate) {
                return;
            }

            const sub = new SubProgressMonitor(progressMonitor, 1);
            groupApiCalls.push(SettingsConnector.getInstance().editRolePermission(permissionToUpdate, rolesScope, sub));
        });

        const removedPermissionKeys = details.removedKeys.reduce<string[]>((acc, key) => {
            const permissionExist = permissionList?.find((p) => p.key === key);

            if (!permissionExist) {
                return acc;
            }

            acc.push(key);

            return acc;
        }, []);

        removedPermissionKeys.forEach((key) => {
            const permissionToUpdate = getPermissionToUpdate(key, false);
            if (!permissionToUpdate) {
                return;
            }

            const sub = new SubProgressMonitor(progressMonitor, 1);
            groupApiCalls.push(SettingsConnector.getInstance().editRolePermission(permissionToUpdate, rolesScope, sub));
        });

        const allSelection = uniq([...selection, ...addedLinkedPermissions]);
        setSelectedList(allSelection);

        if (!selectedRole) {
            return;
        }


        try {
            await Promise.all(groupApiCalls);

            const sub = new SubProgressMonitor(progressMonitor, 1);
            onPermissionUpdate(selectedRole.id, sub);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.editPermissionsError }, error as Error);
            throw error;
        }
    }, [getPermissionToUpdate, notifications, onPermissionUpdate, permissionList, rolesScope, selectedRole]);

    if (!selectedRole) {
        return <div className={classNames('&-no-selection', className)}>
            <EmptyPane message={messages.noRoleSelected} />
        </div>;
    }

    return <ArgTree<TreeData>
        root={permissionsTree}
        checkable={true}
        getNodeLabel={handleGetNodeLabel}
        onOpen={handleOnOpenNodes}
        getNodeKey='key'
        openedNodes={openedNodes}
        getNodeChildren={item => last(item)?.children}
        className={classNames('&-tree', className)}
        hasNodeChildren={hasChildren}
        isNodeUncheckable={handleIsNodeUncheckable}
        onChange={async (selection: ArgNodeKey[], details: ArgTreeChangeDetails, reason: ArgSelectionChangeReason) => {
            await handleSelection(selection, details, reason);
        }}
        value={selectedList}
    />;
}
