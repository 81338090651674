import { mapDate } from 'src/utils/connectors/mappers';
import { RawTemplate, Template } from './templates-types';

export function mapTemplate<TContent>(result: RawTemplate<TContent>): Template<TContent> {
    const template: Template<TContent> = {
        id: result.id,
        name: result.name,
        description: result.description,
        type: result.type,
        parentId: result.parentId,
        content: result.content,
        createdDate: mapDate(result.createdDate)!,
        createdBy: result.createdBy,
        lastUpdatedDate: mapDate(result.lastUpdatedDate),
        lastUpdatedBy: result.lastUpdatedBy,
        isDefault: result.isDefault,
    };

    return template;
}
