import { ReactNode, useCallback, useContext, useMemo } from 'react';

import { ClassValue, SelectionProvider, useArgModalContext, useClassNames } from 'src/components/basic';
import { PropertiesDataProvider } from '../providers/properties-data-provider';
import { PropertiesTable } from '../components/properties-table';
import { CreatePropertyModal } from '../components/create-property-modal';
import { UserProfileField } from '../../../model/user-metadata';
import { UsersAndGroupsStateContext } from '../../users/providers/usersState';

import './properties-view.less';

const CLASSNAME = 'settings-properties-view';

const PROPERTY_CREATE_MODAL_ID = 'PROPERTY_CREATE_MODAL_ID';

export interface PropertiesViewProps {
    className?: ClassValue;
    pageTabs: ReactNode;
}

export function PropertiesView(props: PropertiesViewProps) {
    const { className, pageTabs } = props;
    const classNames = useClassNames(CLASSNAME);

    const { properties, setProperties } = useContext(UsersAndGroupsStateContext);

    const propertiesDataProvider = useMemo(() => new PropertiesDataProvider(properties, false), [properties]);
    const propertiesSelectionProvider = useMemo(
        () => new SelectionProvider<UserProfileField>('settings-properties-table', (property) => property.id.toString()),
        []
    );

    const modalContext = useArgModalContext();

    const openAddPropertyModal = useCallback(() => {
        modalContext.open(PROPERTY_CREATE_MODAL_ID,
            <CreatePropertyModal
                closeModal={() => modalContext.close(PROPERTY_CREATE_MODAL_ID)}
                properties={properties}
                setProperties={setProperties}
            />
        );
    }, [modalContext, properties, setProperties]);

    return (
        <>
            <div className={classNames('&', className)}>
                {pageTabs}
                <PropertiesTable
                    properties={propertiesDataProvider}
                    selectionProvider={propertiesSelectionProvider}
                    handleAddProperty={openAddPropertyModal}
                />
            </div>
        </>
    );
}
