import { isArray, isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgChangeReason,
    ArgCombo,
    useClassNames,
    useArgNotifications,
    useProgressMonitor,
} from '../../basic';
import { EditorProps } from '../controls/controls-type';

const messages = defineMessages({
    placeholder: {
        id: 'common.editors.picklist.SearchPlaceholder',
        defaultMessage: 'Select {propertyDisplayName}',
    },
    loadPropertyPossibleValuesError: {
        id: 'common.editors.picklist.LoadPropertyPossibleValuesError',
        defaultMessage: 'Failed to load property possible values',
    },
});

export function PickListEditor<T = string>(props: EditorProps<T | T[]>) {
    const {
        className,
        propertyDisplayName,
        value,
        onChange,
        cardinality,
        state,
        size = 'medium',
        placeholder,
        pickerOptions,
    } = props;

    const classNames = useClassNames('arg-vertex-editor-picklist-control');
    const notifications = useArgNotifications();

    const [list, setList] = useState<T[]>(pickerOptions?.fixedValues as T[] || []);
    const [progressMonitor, createProgressMonitor] = useProgressMonitor();

    useEffect(() => {
        if (pickerOptions?.getPossibleValues && !((pickerOptions?.fixedValues?.length ?? -1) > 0)) {
            const progressMonitor = createProgressMonitor('Fetch property possible values', 1);
            pickerOptions.getPossibleValues(progressMonitor)
                .then((newList) => setList(newList as T[]))
                .catch((error) => {
                    if (progressMonitor.isCancelled) {
                        return;
                    }
                    console.error(error);
                    notifications.snackError({ message: messages.loadPropertyPossibleValuesError }, error as Error);
                })
                .finally(() => {
                    progressMonitor.done();
                });
        }
    }, [createProgressMonitor, notifications, pickerOptions]);

    const selectValues = useMemo<T[]>(() => {
        if (isNil(value)) {
            return [];
        }

        return isArray(value) ? value : [value];
    }, [value]);

    const handleSelectChange = (value: T | T[] | undefined, reason: ArgChangeReason) => {
        let newValues: T | T[] | null;

        if (Array.isArray(value)) {
            if (value.length === 0) {
                newValues = null;
            } else {
                newValues = value;
            }
        } else if (value) {
            newValues = value;
        } else {
            newValues = null;
        }

        onChange(newValues, reason);
    };

    let _placeholder: typeof placeholder = undefined;
    if (placeholder === null) {
        _placeholder = undefined;
    } else if (placeholder === undefined) {
        _placeholder = messages.placeholder;
    } else {
        _placeholder = placeholder;
    }

    return (
        <ArgCombo<T>
            className={classNames('&', className)}
            data-testid='arg-Pick-list-editor-control'
            size={size}
            autoFocus={true}
            cardinality={cardinality}
            items={list}
            getItemKey={pickerOptions?.getItemKey}
            getItemLabel={pickerOptions?.getItemLabel}
            type='ghost'
            enableFilter={true}
            placeholder={_placeholder}
            onChange={handleSelectChange}
            value={selectValues}
            messageValues={{
                propertyDisplayName,
            }}
            progressMonitor={progressMonitor}
            state={state}
        />
    );
}

