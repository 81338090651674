import { useEffect, useMemo, useRef } from 'react';
import sanitizeHtml from 'sanitize-html';

import { ArgCheckbox, highlightSplit, ProgressMonitor, useClassNames } from 'src/components/basic';
import { DateByUser } from 'src/components/common/date-by-user';
import { BriefTemplate } from 'src/exploration/model/template';
import { TemplateActionsMenu } from '../../../settings/configuration/components/library-configuration/actions/template-action-menu';

import './brief-template-tile.less';

const SANITIZE_RULES = {};

export interface BriefTemplateTileProps {
    selected?: boolean;
    template: BriefTemplate;
    displayContextualMenuButton?: boolean;
    onClick: (template: BriefTemplate) => void;
    onDeleteTemplateConfirm?: (progressMonitor: ProgressMonitor, template: BriefTemplate) => Promise<void>;
    onRenameTemplateConfirm?: (progressMonitor: ProgressMonitor, template: BriefTemplate, newName: string) => Promise<void>;
    onCheckAction?: (value: boolean, template: BriefTemplate) => void;
    isBriefTemplateSelected?: (template: BriefTemplate) => boolean;
    searchString?: string;
}

export function BriefTemplateTile(props: BriefTemplateTileProps) {
    const {
        selected,
        template,
        displayContextualMenuButton,
        onClick,
        onDeleteTemplateConfirm,
        onRenameTemplateConfirm,
        onCheckAction,
        isBriefTemplateSelected,
        searchString,
    }: BriefTemplateTileProps = props;

    const classNames = useClassNames('exploration-brief-template');
    const thumbnailRef = useRef<HTMLDivElement>(null);
    const thumbnailContentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        window.requestAnimationFrame(() => {
            if (thumbnailRef.current && thumbnailContentRef.current) {
                const thumbnailRect = thumbnailRef.current.getBoundingClientRect();
                const thumbnailContentRect = thumbnailContentRef.current.getBoundingClientRect();
                const scale = thumbnailRect.width / thumbnailContentRect.width;
                thumbnailContentRef.current.style.transform = `scale(${scale})`;
            }
        });
    }, []);

    const html = useMemo(() => {
        return sanitizeHtml(template.content, SANITIZE_RULES);
    }, [template.content]);

    return (
        <div
            className={classNames('&', { selected: selected })}
            onClick={() => onClick(template)}
        >
            <div className='thumbnail' ref={thumbnailRef}>
                {onCheckAction && <ArgCheckbox className={classNames('&-checkbox')} size='node' value={isBriefTemplateSelected?.(template)} onChange={(value) => onCheckAction(value, template)} />}
                <div
                    className='content'
                    ref={thumbnailContentRef}
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            </div>
            <div className='display-name'>
                <div className='ellipsis'>{highlightSplit(template.name, searchString)}</div>
                {displayContextualMenuButton &&
                    <TemplateActionsMenu className={classNames('&-contextual-menu')}
                                         template={template}
                                         onDeleteTemplateConfirm={onDeleteTemplateConfirm}
                                         onRenameTemplateConfirm={onRenameTemplateConfirm}
                    />
                }
            </div>
            <DateByUser
                className='info'
                date={template.lastUpdatedDate ?? template.createdDate!}
                relative={true}
                user={template.lastUpdatedBy}
            />
        </div>
    );
}
