import { KEY_BINDINGS as EXPLORATION_KEY_BINDINGS } from './exploration';
import { KEY_BINDINGS as STRUCTURED_SEARCH_KEY_BINDINGS } from './structured-search';
import { KEY_BINDINGS as OVERVIEW_SEARCH_KEY_BINDINGS } from './overview';
import { KEY_BINDINGS as HOME_PAGE_KEY_BINDINGS } from './home-page';
import { KEY_BINDINGS as CASE_KEY_BINDINGS } from './case';
import { KEY_BINDINGS as GLOBAL_KEY_BINDINGS } from './global';
import { KEY_BINDINGS as VERTEX_EDITOR_KEY_BINDINGS } from './vertex-editor';
import { KEY_BINDINGS as BRIEF_KEY_BINDINGS } from './brief';

export const KEY_BINDINGS = {
    ...GLOBAL_KEY_BINDINGS,

    ...EXPLORATION_KEY_BINDINGS,
    ...STRUCTURED_SEARCH_KEY_BINDINGS,
    ...OVERVIEW_SEARCH_KEY_BINDINGS,
    ...HOME_PAGE_KEY_BINDINGS,
    ...BRIEF_KEY_BINDINGS,
    ...CASE_KEY_BINDINGS,
    ...VERTEX_EDITOR_KEY_BINDINGS,
};

export const KEY_BINDINGS_NO_EXPLORATION = {
    ...GLOBAL_KEY_BINDINGS,

    ...OVERVIEW_SEARCH_KEY_BINDINGS,
    ...HOME_PAGE_KEY_BINDINGS,
    ...BRIEF_KEY_BINDINGS,
    ...CASE_KEY_BINDINGS,
    ...VERTEX_EDITOR_KEY_BINDINGS,
};
