import { CSSProperties, useCallback, useMemo, useRef, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { chain, isArray, isEmpty, isNumber, isString, mapValues, max, size, sumBy } from 'lodash';
import { IAceEditorProps } from 'react-ace';
import useResizeObserver from '@react-hook/resize-observer';
import 'ace-builds/src-noconflict/mode-mysql';
import CopyToClipboard from 'react-copy-to-clipboard';
import classNames from 'classnames';

import {
    ArgButton,
    ArgDragAndDropUploader,
    ArgIcon,
    ArgModal,
    ArgTab,
    ArgTable3,
    ArgTableColumn3,
    ArgTooltip2,
    ArgUploaderButton,
    ProgressMonitor,
    SortableArrayDataProvider,
    SubProgressMonitor,
    useClassNames,
    useArgNotifications,
    ArgTabs,
    MESSAGE_DESCRIPTOR_FORMATTERS,
    ArgInputSearch,
    normalizeText,
    highlightSplit,
} from 'src/components/basic';
import {
    ExecuteOntologyFeedSourcesRequest,
    OntologyFeedSource,
    OntologyFeedSourceExecuteResponse,
    OntologyFeedSourceParseError,
    OntologyFeedSources,
    OntologyFeedSourceValidationResultResponse,
} from 'src/settings/models/feed-sources';
import { useCallbackAsync } from 'src/components/basic/arg-hooks/use-callback-async';
import { downloadFile } from 'src/utils/file';
import { FullOntology, FullOntologyLinkType, FullOntologyObjectType } from '../../types';
import { useEffectAsync } from 'src/components/basic/arg-hooks/use-effect-async';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { ArgAceEditorInput } from 'src/components/basic/arg-input/arg-input-expression-editor';
import ontologiesConnector from '../../../../connectors/ontologies-connector';

import './feed-sources-modal.less';

const LINE_HEIGHT = 16;
const ERROR_TAB_KEY = 'error';
const RESULT_TAB_KEY = 'result';
const QUERY_TYPE = 'Kbql';
const INITIAL_QUERIES: string[] = [];
const DEFAULT_QUERIES = [''];
const RESULTS_COLUMN_WIDTH = 150;
const EMPTY_COLUMN_KEY = 'empty-column-key';
const RESULT_LIMIT = 100;

const EMPTY_QUERY_REGEX = /^\s*$/;

const FORCE_LOADING = false;
const PANEL_ENTRIES = ['object-or-relation-properties'] as const;

export const messages = defineMessages({
    title: {
        id: 'settings.feed-sources-modal.title',
        defaultMessage: 'Define feed sources',
    },
    import: {
        id: 'settings.feed-sources-modal.import',
        defaultMessage: 'Import',
    },
    export: {
        id: 'settings.feed-sources-modal.export',
        defaultMessage: 'Export',
    },
    test: {
        id: 'settings.feed-sources-modal.test',
        defaultMessage: 'Test',
    },
    addSource: {
        id: 'settings.feed-sources-modal.addSource',
        defaultMessage: 'Add a source',
    },
    syntaxError: {
        id: 'settings.feed-sources-modal.syntaxError',
        defaultMessage: 'Syntax error',
    },
    testResult: {
        id: 'settings.feed-sources-modal.testResult',
        defaultMessage: 'Test result',
    },
    submit: {
        id: 'settings.feed-sources-modal.submitButton',
        defaultMessage: 'Define',
    },
    validateQueryError: {
        id: 'settings.feed-sources-modal.validateQueryError',
        defaultMessage: 'Failed to validate feed source',
    },
    errorLine: {
        id: 'settings.feed-sources-modal.errorLine',
        defaultMessage: 'Error line {line} :',
    },
    importError: {
        id: 'settings.feed-sources-modal.importError',
        defaultMessage: 'The imported file isn\'t compatible',
    },
    feedSources: {
        id: 'settings.feed-sources-modal.feedSources',
        defaultMessage: 'feed-sources',
    },
    fetchFeedSources: {
        id: 'settings.feed-sources-modal.fetchFeedSources',
        defaultMessage: 'Loading feed sources <ThreeDotsLoading></ThreeDotsLoading>',
    },
    fetchFeedSourcesError: {
        id: 'settings.feed-sources-modal.fetchFeedSourcesError',
        defaultMessage: 'Failed to get feed sources',
    },
    updateFeedSourcesError: {
        id: 'settings.feed-sources-modal.updateFeedSourcesError',
        defaultMessage: 'Failed to update feed sources',
    },
    resultNumber: {
        id: 'settings.feed-sources-modal.resultNumber',
        defaultMessage: '{count, plural,=0 {No object found} =1 {1 object found :} other {{count} objects found :}}',
    },
    objectTypeProperties: {
        id: 'settings.feed-sources-modal.ObjectTypeProperties',
        defaultMessage: 'Object properties list.',
    },
    propertyCopiedToClipboard: {
        id: 'settings.feed-sources-modal.PropertyCopiedToClipboard',
        defaultMessage: 'The Property <grey>"{propertyName}"</grey> is copied to the clipboard.',
    },
    searchProperties: {
        id: 'settings.feed-sources-modal.SearchProperties',
        defaultMessage: 'Search',
    },
});

export interface FeedSourcesModalProps {
    closeModal: () => void;
    vertexOrEdge: FullOntologyObjectType | FullOntologyLinkType;
    ontology: FullOntology;
    ontologyType: 'object' | 'relation'
}

export function FeedSourcesModal(props: FeedSourcesModalProps) {
    const {
        closeModal,
        vertexOrEdge,
        ontology,
        ontologyType,
    } = props;

    const intl = useIntl();
    const notifications = useArgNotifications();
    const classNames = useClassNames('settings-feed-sources-modal');

    const [queries, setQueries] = useState<string[]>(INITIAL_QUERIES);
    const [activeTab, setActiveTab] = useState<string | undefined>();
    const [focusedEditorIndex, setFocusedEditorIndex] = useState<number>(0);
    const [errors, setErrors] = useState<Record<number, OntologyFeedSourceParseError[]>>({});
    const [results, setResults] = useState<Record<number, OntologyFeedSourceExecuteResponse>>({});
    const [editorsContainerSize, setEditorsContainerSize] = useState<{ width: number, height: number } | undefined>();

    const editorsContainerRef = useRef<HTMLDivElement | null>(null);

    const setQueriesFromFeedSources = useCallback((feedSources: OntologyFeedSources) => {
        const queries = feedSources.feedSources?.map((feedSource) => feedSource.query);
        setQueries(!queries?.length ? DEFAULT_QUERIES : queries);
    }, []);

    const [feedSourcesProgressMonitor] = useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const feedSources = await ontologiesConnector.getFeedSources(ontology.id, ontologyType, vertexOrEdge.name, progressMonitor);
            setQueriesFromFeedSources(feedSources);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.fetchFeedSourcesError }, error as Error);
            throw error;
        }
    }, [ontology.id, setQueriesFromFeedSources, vertexOrEdge.name, ontologyType], messages.fetchFeedSources);

    const getEditorMinHeight = useCallback((text: string) =>
        (text.split('\n').length + 2) * LINE_HEIGHT
    , []);

    const computeFeedSources = useCallback(() => {
        const feedSources: OntologyFeedSources = {
            feedSources: queries
                .filter((query) => !(EMPTY_QUERY_REGEX.test(query)))
                .map((query) => ({
                    query,
                    type: QUERY_TYPE,
                })),
        };

        return feedSources;
    }, [queries]);

    const editorsHeight = useMemo<number>(() => {
        return sumBy(queries, (query) => getEditorMinHeight(query));
    }, [queries, getEditorMinHeight]);

    const handleOnClose = useCallback(() => {
        closeModal();
    }, [closeModal]);

    const onEditorChange = useCallback((value: string, index: number) => {
        setErrors((errors) => ({
            ...errors,
            [index]: [],
        }));
        setQueries(((queries) => {
            const newQueries = [...queries];
            newQueries[index] = value;

            return newQueries;
        }));
    }, []);

    const onFocus = useCallback((index: number) => {
        setFocusedEditorIndex(index);
    }, []);

    const onRemoveEditor = useCallback((index: number) => {
        setQueries(((queries) => queries.filter((_, i) => i !== index)));
        setFocusedEditorIndex((prev) => (prev === queries.length - 1 ? queries.length - 2 : prev));
        setErrors((errors) => {
            return chain(errors).omit([index]).mapKeys((_, key) => {
                const i = Number(key);

                return i > index ? i - 1 : i;
            }).value();
        });
    }, [queries.length]);

    const onClosePanel = useCallback(() => {
        setActiveTab(undefined);
    }, []);

    const handleTabChange = useCallback((tabKey: string | undefined) => {
        setActiveTab(tabKey);
    }, []);

    const handleAddSource = useCallback(() => {
        setQueries((queries) => [...queries, '']);
        setFocusedEditorIndex(queries.length);
    }, [queries]);

    const importFeedSources = useCallback(async (blob: Blob, progressMonitor: ProgressMonitor) => {
        try {
            const fileData = await blob.text();

            progressMonitor.verifyCancelled();

            const feedSourcesData = JSON.parse(fileData);
            if (!isOntologyFeedSources(feedSourcesData)) {
                notifications.snackError({ message: messages.importError });

                return;
            }

            setQueriesFromFeedSources(feedSourcesData);
        } catch (e) {
            if (progressMonitor.isCancelled) {
                throw e;
            }

            notifications.snackError({ message: messages.importError }, e as Error);
            throw e;
        }
    }, [notifications, setQueriesFromFeedSources]);

    const exportFeedSource = useCallback(() => {
        const feedSources = computeFeedSources();
        const fileName = `${intl.formatMessage(messages.feedSources)}-${ontology.name}-${vertexOrEdge.displayName}-${new Date().getTime()}.json`;

        downloadFile(fileName, JSON.stringify(feedSources, null, 2), 'application/json');
    }, [computeFeedSources, intl, ontology.name, vertexOrEdge.displayName]);

    const handleTestQuery = useCallback(async (queryIndex: number, progressMonitor: ProgressMonitor): Promise<OntologyFeedSourceValidationResultResponse> => {
        const feedSource: OntologyFeedSource = {
            type: QUERY_TYPE,
            query: queries[queryIndex],
        };

        const validationResult = await ontologiesConnector.validateFeedSource(ontology.id, ontologyType, vertexOrEdge.name, feedSource, progressMonitor);
        if (validationResult.parseErrors) {
            setErrors((prevAnnotations) => ({
                ...prevAnnotations,
                [queryIndex]: validationResult?.parseErrors ?? [],
            }));
        }

        return validationResult;
    }, [ontology.id, ontologyType, queries, vertexOrEdge.name]);

    const handleExecuteQuery = useCallback(async (queryIndex: number, progressMonitor?: ProgressMonitor) => {
        const feedSourceRequest: ExecuteOntologyFeedSourcesRequest = {
            feedSource: {
                type: QUERY_TYPE,
                query: queries[queryIndex],
            },
            limit: RESULT_LIMIT,
        };
        const result = await ontologiesConnector.executeFeedSources(ontology.id, ontologyType, vertexOrEdge.name, feedSourceRequest, progressMonitor);

        return result;
    }, [ontology.id, ontologyType, queries, vertexOrEdge.name]);

    const [onClickTestQuery, testProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const validationResult = await handleTestQuery(focusedEditorIndex, progressMonitor);
            if (!isEmpty(validationResult.parseErrors)) {
                setActiveTab(ERROR_TAB_KEY);

                return;
            }

            const result = await handleExecuteQuery(focusedEditorIndex);
            setResults((prev) => ({ ...prev, [focusedEditorIndex]: result }));
            setActiveTab(RESULT_TAB_KEY);
        } catch (e) {
            if (progressMonitor.isCancelled) {
                throw e;
            }
            console.error(e);
            notifications.snackError({ message: messages.validateQueryError }, e as Error);
        }
    }, [focusedEditorIndex, handleExecuteQuery, handleTestQuery, notifications]);

    const [handleSubmitFeedSources, submitFeedSourcesProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const validQueries = queries.filter((query) => !(EMPTY_QUERY_REGEX.test(query)));
            const ps = validQueries.map((_, index) => {
                const sub = new SubProgressMonitor(progressMonitor, 1);

                const ret = handleTestQuery(index, sub);

                return ret;
            });

            const validationResults = await Promise.all(ps);
            if (validationResults.some((validationResult) => {
                const ret = !isEmpty(validationResult.parseErrors);

                return ret;
            })) {
                return;
            }

            const feedSources = computeFeedSources();

            const sub = new SubProgressMonitor(progressMonitor, 1);
            await ontologiesConnector.putFeedSources(ontology.id, ontologyType, vertexOrEdge.name, feedSources, sub);

            handleOnClose();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            console.error(error);

            notifications.snackError({ message: messages.fetchFeedSourcesError }, error as Error);
            throw error;
        }
    }, [queries, computeFeedSources, ontology.id, ontologyType, vertexOrEdge.name, handleOnClose, handleTestQuery, notifications]);


    const errorBody = <div
        className={classNames('&-errors-container')}>{errors[focusedEditorIndex]?.map((error, index) => (
            <div key={index}><FormattedMessage {...messages.errorLine} values={{ line: error.line }} /> {error.message}</div>
        ))}</div>;


    const columns = useMemo<ArgTableColumn3<Record<string, string>>[]>(() => {
        const columns: ArgTableColumn3<Record<string, string>>[] = [];
        const result = results[focusedEditorIndex];
        if (!result?.rows?.length) {
            return columns;
        }
        for (const key in result.rows[0]) {
            columns.push({
                key,
                ellipsis: true,
                columnName: key,
                render: function FeedSourceColumn(value: string) {
                    return (
                        <ArgTooltip2 title={value}>
                            <span className={classNames('&-cell-name')}>
                                {value}
                            </span>
                        </ArgTooltip2>
                    );
                },
                dataIndex: [key],
                sortable: false,
            });
        }
        columns.push({
            key: EMPTY_COLUMN_KEY,
            ellipsis: true,
            columnName: ' ',
            dataIndex: [],
            sortable: false,
        });

        return columns;
    }, [classNames, focusedEditorIndex, results]);

    const resultsDataProvider = useMemo(() => new SortableArrayDataProvider(results[focusedEditorIndex]?.rows ?? [], false), [focusedEditorIndex, results]);
    const columnWidths = useMemo(() => {
        const result = results[focusedEditorIndex];
        if (!result?.rows?.length) {
            return {};
        }
        const tableSize = (editorsContainerSize?.width ?? 0) - 30;
        const columnWidths = mapValues(result.rows[0], () => RESULTS_COLUMN_WIDTH);
        const totalWidth = size(columnWidths) * RESULTS_COLUMN_WIDTH;
        const remaining = tableSize > totalWidth ? tableSize - totalWidth : 0;
        columnWidths[EMPTY_COLUMN_KEY] = remaining;

        return columnWidths;
    }, [editorsContainerSize?.width, focusedEditorIndex, results]);

    const resultBody = <>
        <span className={classNames('&-result-count')}>
            <FormattedMessage {...messages.resultNumber} values={{ count: results[focusedEditorIndex]?.count ?? 0 }} />
        </span>
        {
            columns.length > 0 && (
                <ArgTable3<Record<string, string>>
                    className={classNames('&-result-table')}
                    columns={columns}
                    dataProvider={resultsDataProvider}
                    initialItemsCount={results[focusedEditorIndex]?.rows?.length || 0}
                    rowHeight={30}
                    adjustColumnsOnFirstDraw={true}
                    columnWidths={columnWidths}
                />
            )
        }

    </>;

    const tabs: ArgTab[] = [{
        key: ERROR_TAB_KEY,
        icon: 'icon-embed2',
        title: messages.syntaxError,
        closable: false,
    },
    {
        key: RESULT_TAB_KEY,
        icon: 'icon-embed2',
        title: messages.testResult,
        closable: false,
    }];

    const isErrorPanelVisible = activeTab === ERROR_TAB_KEY;
    const isResultPanelVisible = activeTab === RESULT_TAB_KEY;
    const isPanelVisible = isErrorPanelVisible || isResultPanelVisible;

    useResizeObserver(editorsContainerRef.current, (entry: ResizeObserverEntry) => {
        setEditorsContainerSize({
            width: entry.target.clientWidth,
            height: entry.target.clientHeight,
        });
    });

    const setEditorsContainerRef = useCallback((element: HTMLDivElement | null) => {
        if (element) {
            editorsContainerRef.current = element;
            setEditorsContainerSize({
                width: element.clientWidth,
                height: element.clientHeight,
            });
        }
    }, []);


    const [selectedPanel, setSelectedPanel] = useState<typeof PANEL_ENTRIES[number]>();

    const editorsContainerCls = {
        '&-editors-container': true,
        '&-editors-container-no-scroll': editorsContainerSize && editorsHeight <= editorsContainerSize?.height,
    };

    const body = feedSourcesProgressMonitor?.isRunning || FORCE_LOADING ? (
        <LoadingPane
            progressMonitor={feedSourcesProgressMonitor}
            className={classNames('&-loading')}
            size='large'
        />
    ) : (
        <>
            <div className={classNames('&-editors-header')}>
                <div className={classNames('&-editors-header-left')}>
                    <ArgButton
                        icon='icon-add-outline'
                        className={classNames('&-editors-header-left-add-source-button')}
                        label={messages.addSource}
                        type='ghost'
                        onClick={handleAddSource}
                    />
                    <ArgUploaderButton
                        type='ghost'
                        size='medium'
                        icon='icon-download'
                        className={classNames('&-editors-header-left-import-button')}
                        label={messages.import}
                        acceptedFiles='.json'
                        method={importFeedSources}
                    />
                    <ArgButton
                        icon='icon-upload'
                        className={classNames('&-editors-header-left-export-button')}
                        label={messages.export}
                        type='ghost'
                        onClick={exportFeedSource}
                        disabled={queries.every((query) => !query)}
                    />
                </div>

                <div className={classNames('&-editors-header-right')}>
                    <ArgButton
                        onClick={onClickTestQuery}
                        label={messages.test}
                        loading={testProgressMonitor?.isRunning}
                        disabled={!queries[focusedEditorIndex] || EMPTY_QUERY_REGEX.test(queries[focusedEditorIndex])}
                    />

                    {PANEL_ENTRIES.map(panelEntry => {
                        return (
                            <ArgButton
                                key={panelEntry}
                                onClick={() => setSelectedPanel(prev => ((prev === panelEntry) ? undefined : panelEntry))}
                                type='ghost'
                                icon='icon-info'
                                className={classNames('&-editors-header-right-info', { selected: panelEntry === selectedPanel })}
                            />
                        );
                    })}
                </div>
            </div>
            <div className={classNames('&-editors-content')}>
                <div className={classNames(editorsContainerCls)} ref={setEditorsContainerRef}>
                    {queries.map(((query, index) => {
                        const height = index === queries.length - 1
                            ? max([getEditorMinHeight(query), queries
                                .slice(0, -1)
                                .reduce((acc, v) => acc - getEditorMinHeight(v) - 1, editorsContainerSize?.height ?? 0)])
                            : getEditorMinHeight(query);

                        return (
                            <FeedSourceEditor
                                key={index}
                                height={height}
                                onChange={(value) => onEditorChange(value, index)}
                                focused={focusedEditorIndex === index}
                                query={query}
                                errors={errors[index]}
                                onFocus={() => onFocus(index)}
                                showTrash={queries.length > 1}
                                onRemove={() => onRemoveEditor(index)}
                            />
                        );
                    }
                    ))}
                </div>

                {selectedPanel && (
                    <Panel
                        selectedPanel={selectedPanel}
                        classNames={classNames}
                        vertexOrEdge={vertexOrEdge}
                    />
                )}
            </div>
            {isPanelVisible && <div className={classNames('&-bottom-panel')}>
                <div
                    onClick={onClosePanel}
                    className={classNames('&-bottom-panel-close-button')}
                >
                    <ArgIcon name='icon-cross' />
                </div>
                {isErrorPanelVisible && errorBody}
                {isResultPanelVisible && resultBody}
            </div>}
            <div className={classNames('&-tabs-container')}>
                <ArgTabs
                    tabs={tabs}
                    activeTabKey={activeTab}
                    showListTabs={false}
                    onChange={handleTabChange}
                    className={classNames('&-tabs')}
                />
            </div>
        </>);

    return (
        <ArgModal
            size='xlarge'
            title={messages.title}
            visible={true}
            maskClosable={false}
            centered={true}
            onCancel={handleOnClose}
            onClose={handleOnClose}
            className={classNames('&')}
            onOk={handleSubmitFeedSources}
            okText={messages.submit}
            loading={submitFeedSourcesProgressMonitor?.isRunning}
            okDisabled={feedSourcesProgressMonitor?.isRunning}
        >
            <ArgDragAndDropUploader
                method={importFeedSources}
            >
                <div className={classNames('&-container')}>
                    {body}
                </div>
            </ArgDragAndDropUploader>
        </ArgModal>
    );
}

interface PropertiesListPanelProps {
    vertexOrEdge: FullOntologyObjectType | FullOntologyLinkType;
    classNames: (...args: classNames.ArgumentArray) => string;
}

function PropertiesListPanel(props: PropertiesListPanelProps) {
    const { vertexOrEdge, classNames } = props;

    const [searchText, setSearchText] = useState<string>('');
    const propertyList = useMemo(() => {
        const allProperties = vertexOrEdge.properties;
        const filteredProperties = allProperties.filter(property => {
            return normalizeText(property.name).includes(normalizeText(searchText)) || normalizeText(property.displayName).includes(normalizeText(searchText));
        });

        return filteredProperties;
    }, [vertexOrEdge, searchText]);
    const notifications = useArgNotifications();

    return (
        <div className={classNames('&-editors-content-panel-properties-container')}>
            <FormattedMessage {...messages.objectTypeProperties} />
            <ArgInputSearch
                placeholder={messages.searchProperties}
                onChange={value => setSearchText(value || '')}
                debounce={300}
            />
            <div className={classNames('&-editors-content-panel-properties-list')}>
                {
                    propertyList.map(property => (
                        <ArgTooltip2
                            key={property.name}
                            title={`${property.name} (${property.displayName})`}
                            placement='left'
                        >
                            <div className={classNames('&-editors-content-panel-properties-list-item')}>
                                <span>{highlightSplit(property.name, searchText)}</span>
                                <span className={classNames('&-editors-content-panel-properties-list-item-displayName')}>({highlightSplit(property.displayName, searchText)})</span>
                                <CopyToClipboard
                                    key='copy'
                                    text={property.name}
                                    onCopy={(text) => notifications.snackInfo({ message: messages.propertyCopiedToClipboard }, { propertyName: text, ...MESSAGE_DESCRIPTOR_FORMATTERS })}
                                >
                                    <ArgButton
                                        type='ghost'
                                        icon='icon-copy'
                                        className={classNames('&-editors-content-panel-properties-list-item-copy')}
                                    />
                                </CopyToClipboard>
                            </div>
                        </ArgTooltip2>
                    ))
                }
            </div>
        </div>
    );
}

interface PanelProps {
    selectedPanel: typeof PANEL_ENTRIES[number];
    classNames: (...args: classNames.ArgumentArray) => string;
    vertexOrEdge: FullOntologyObjectType | FullOntologyLinkType;
}

// INFO: Handle other panels here
function Panel(props: PanelProps) {
    const { selectedPanel, classNames, vertexOrEdge } = props;
    let body: JSX.Element | null = null;

    switch (selectedPanel) {
        case 'object-or-relation-properties':
            body = <PropertiesListPanel vertexOrEdge={vertexOrEdge} classNames={classNames} />;
            break;

        default:
            break;
    }

    return (
        <div className={classNames('&-editors-content-panel')}>
            {body}
        </div>
    );
}

interface FeedSourceEditorProps {
    query: string;
    errors: OntologyFeedSourceParseError[];
    height?: number;
    onChange: (query: string) => void;
    onRemove: () => void;
    onFocus: () => void;
    focused: boolean;
    showTrash: boolean;
}

function FeedSourceEditor(props: FeedSourceEditorProps) {
    const {
        query,
        errors,
        height,
        onChange,
        onRemove,
        focused,
        showTrash,
        onFocus,
    } = props;

    const classNames = useClassNames('settings-feed-sources-modal-editors-ace-container');

    const editorCls = { '&-editors-ace-focused': focused };

    const myAceProps = useMemo<IAceEditorProps>(() => ({
        showGutter: true,
        fontSize: 12,
        highlightActiveLine: false,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        maxLines: undefined,
        showPrintMargin: false,
    }), []);


    const style: CSSProperties = {};

    if (isNumber(height)) {
        style.height = `${height}px`;
    }

    return (
        <div
            className={classNames('&')}
            style={style}
        >
            {showTrash && <div
                onClick={() => onRemove()}
                className={classNames('&-trash-button')}
            >
                <ArgIcon name='icon-trash' />
            </div>}
            <ArgAceEditorInput
                language='mysql'
                focus={focused}
                value={query}
                onChange={(value) => onChange(value || '')}
                onFocus={onFocus}
                errors={errors}
                className={classNames('&-editor', editorCls)}
                height={height}
                aceProps={myAceProps}
            />
        </div>
    );
}

const isOntologyFeedSources = (x: any): x is OntologyFeedSources => {
    if (x?.feedSources !== undefined && !isArray(x?.feedSources)) {
        return false;
    }

    return (x?.feedSources as any[]).every((feedSource) => isString(feedSource.type) && isString(feedSource.query));
};
