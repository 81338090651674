import { createDragDropSupport, createTypePlugin } from '../type-editing-plugin';

export const SCREENSHOT_VIEW_DND_TYPE = 'application/arg-resource';

export const ScreenshotEditing = createTypePlugin('argonos-resource-screenshot',
    ['screenshotId', 'resourceId', 'resourceKey', 'caseId', 'screenshotName', 'screenshotWidth', 'screenshotHeight', 'width', 'universeId', 'explorationId', 'explorationLayout'],
    {
        defineClipboardInputOutput: createDragDropSupport(SCREENSHOT_VIEW_DND_TYPE), // Remove it for CKEditor build
        containerElement: 'figure',
        containerElementClassName: 'image',
        isBlock: true,
        customProperty: 'screenshot',
    });
