import { defineMessages, FormattedMessage } from 'react-intl';
import { useCallback } from 'react';

import { ArgIcon, useClassNames } from 'src/components/basic';
import { ValuationRuleEffectBlock } from '../valuation-rule-effect/valuation-rule-effect-block';
import { ValuationRuleEffect } from 'src/settings/models/valuation-policy';
import { useValuationRuleStateContext } from 'src/settings/universes/common/providers/policy-rules-provider';

import './valuation-policy-rule-block-effect.less';

const messages = defineMessages({
    effects: {
        id: 'settings.valuation-policy-rule-block-effects.effects',
        defaultMessage: 'Effects',
    },
    effect: {
        id: 'settings.valuation-policy-rule-block-effect.effect',
        defaultMessage: 'Effect',
    },
});

interface ValuationPolicyRuleBlockEffectProps {
    effects: ValuationRuleEffect[];
}

export const ValuationPolicyRuleBlockEffect = ({ effects }: ValuationPolicyRuleBlockEffectProps) => {
    const classNames = useClassNames('settings-valuation-policy-rule-block-effect');
    const { setRule, editable } = useValuationRuleStateContext();

    const addEffect = useCallback(() => {
        setRule((currentRule) => {
            return {
                ...currentRule,
                Effects: [...effects, { object: { match: [], Permissions: [] } }],
            };
        });
    }, [effects, setRule]);

    return (
        <>
            <div className={classNames(editable ? '&-title-editable' : '&-title')}>
                <FormattedMessage {...messages.effects} />
            </div>
            {effects.map((effect, index) => {
                return <ValuationRuleEffectBlock key={index} effect={effect} index={index} />;
            })}
            {editable && (
                <div className={classNames('&-icon')} onClick={addEffect}>
                    <ArgIcon name='icon-add-outline' />
                    <div>
                        <FormattedMessage {...messages.effects} />
                    </div>
                </div>
            )}
        </>
    );
};
