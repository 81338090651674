import { Chart } from 'regraph';

const options: Chart.Options = {
    navigation: false,
    overview: false,
    iconFontFamily: 'icomoon',
    links: {
        inlineLabels: true,
        avoidLabels: false,
    },
    backgroundColor: '#f2f5fa',
    fadeOpacity: 0.2,
    selection: {
        color: undefined,
    },

};

export const settings = {
    options,
};
