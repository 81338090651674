import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar } from 'antd';
import Debug from 'debug';

import { ArgChangeReason } from '../types';
import { getDataTestIdFromProps } from '../utils';
import { ArgCalendarHeader } from './arg-calendar-header';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgCalendarDecade } from './arg-calendar-decade';
import { dayjs } from '../utils/dayjs';
import { isIn } from '../utils/is-in';

import './arg-calendar.less';

const debug = Debug('argonode:components:ArgCalendar');

export type CalendarMode = 'year' | 'month' | 'decade';

export interface ArgCalendarProps {
    value?: dayjs.Dayjs;
    type?: CalendarMode;
    initialValue?: dayjs.Dayjs;
    className?: ClassValue;
    disabledDate?: ((date: dayjs.Dayjs) => boolean);
    onChange?: (date: dayjs.Dayjs, reason: ArgChangeReason, mode: CalendarMode) => void;
    customDateCellRender?: (date: dayjs.Dayjs) => React.ReactNode
}

export function ArgCalendar(props: ArgCalendarProps) {
    const {
        type = 'month',
        onChange,
        className,
        disabledDate,
        initialValue,
        value: externalValue,
        customDateCellRender,
    } = props;

    const [mode, setMode] = useState<CalendarMode>(type);
    const classNames = useClassNames('arg-calendar');

    const useInternalValue = !isIn(props, 'value');
    const dataTestId = getDataTestIdFromProps(props);

    const [internalValue, setInternalValue] = useState<dayjs.Dayjs | undefined>(() => {
        return initialValue;
    });

    const value: dayjs.Dayjs | undefined = useInternalValue ? internalValue : externalValue;

    useEffect(() => {
        setInternalValue(initialValue);
    }, [initialValue]);

    const handleChange = useCallback((date: dayjs.Dayjs, reason: ArgChangeReason = 'selection') => {
        if (reason !== 'keypress' && mode === 'year') {
            setMode('month');
        } else if (reason !== 'keypress' && mode === 'decade') {
            setMode('year');
        }

        const utcDate = dayjs.utc([date.year(), date.month(), date.date()]);
        if (debug.enabled) {
            debug('handleChange', 'date=', date.toJSON(), 'utcDate=', utcDate.toJSON());
        }

        setInternalValue(utcDate);

        onChange && onChange(utcDate, reason, mode);
    }, [mode, setInternalValue, onChange]);

    const dateValue = useMemo<dayjs.Dayjs | undefined>(() => {
        if (!value) {
            return undefined;
        }
        const m = dayjs(value);
        if (!m.isValid()) {
            return undefined;
        }

        const localMoment = dayjs([m.year(), m.month(), m.date()]);

        if (debug.enabled) {
            debug('dateValue', 'value=', value, 'localMoment=', localMoment.toJSON());
        }

        return localMoment;
    }, [value]);

    return (
        <div className={classNames('&-container')} data-testid={dataTestId}>
            {mode === 'decade' && (
                <ArgCalendarDecade
                    value={value}
                    mode={mode}
                    onChange={(value) => handleChange(value)}
                />
            )}
            {mode !== 'decade' && (
                <>
                    <ArgCalendarHeader
                        value={dateValue}
                        onChange={(value) => handleChange(value, 'keypress')}
                        onTypeChange={setMode}
                        mode={mode}
                    />
                    <div data-testid='arg-calendar-month-year'>
                        <Calendar
                            mode={mode}
                            fullscreen={false}
                            headerRender={() => null}
                            disabledDate={disabledDate}
                            className={classNames('&', className)}
                            onSelect={handleChange}
                            onPanelChange={(date, mode) => {
                                handleChange(date);
                                setMode(mode);
                            }}
                            value={dateValue}
                            dateCellRender={customDateCellRender}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
