import { UniversePropertyTypes } from './universe';

export type ClusterId = string;

export type PropertyValueRange = {
    left?: any,
    right?: any,
    type: PropertyValueRangeType,
}

export enum PropertyValueRangeType {
    Closed = 'Closed',
    Open = 'Open',
    LeftOpen = 'LeftOpen',
    RightOpen = 'RightOpen',
}

export enum FilterValueType {
    Value,
    Range,
}

type ClusterTitleValue = string | number | boolean | null | undefined | Date | PropertyValueRange;

export interface ClusterInfoTitle {
    value?: ClusterTitleValue;
    type: UniversePropertyTypes;
}
