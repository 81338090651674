import { defineMessages } from 'react-intl';

import { FolderCustomFieldDefinition } from '../../model/folder-custom-field-definition';
import { FolderType } from '../model/folder';
import { DataType } from 'src/components/common/data-types';

const messages = defineMessages({
    type: {
        id: 'proceo.constants.base-folder-field.type',
        defaultMessage: 'Type',
    },
});


export type ProceoBaseFolderCustomFieldsDefinition = Record<ProceoBaseFolderCustomFieldNames, FolderCustomFieldDefinition>;

export enum ProceoBaseFolderCustomFieldNames {
    /**
     * Proceo system custom field, known and consummed by API.
     */
    _Type = '_Type',
}

export const PROCEO_BASE_FOLDER_CUSTOM_FIELDS_DEFINITION: ProceoBaseFolderCustomFieldsDefinition = {
    [ProceoBaseFolderCustomFieldNames._Type]: {
        name: ProceoBaseFolderCustomFieldNames._Type,
        displayName: messages.type,
        type: DataType.String,
        isRequired: true,
        possibleValues: Object.values(FolderType),
    },
};
