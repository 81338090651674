import { compact, forEach, isArray, isEmpty, isString, isUndefined, map, uniq } from 'lodash';

import { VertexId } from '../model/vertex';
import { BriefId } from '../model/brief';
import { SystemVertexTypeName, UniversePropertyName, UniverseVertexTypeName } from '../model/universe';
import {
    Filter,
    FilterGroup,
    FilterOperation,
    GeographicAreaFilter,
    PropertySpecificValueFilter,
    TypeFilter,
    VertexFilter,
} from '../model/filter';
import { Polygon } from '../../components/basic';
import { CenterArea } from '../features/exploration/graph-map-geolocation-tool';
import { DashboardId } from '../features/dashboards/dashboards';
import { ChartId } from '../features/charts/charts';
import { filterChain, isFilterOperation } from '../model/filter-chain';
import { ExplorationId } from '../model/exploration';
import { ResourceId } from '../../model/resource';

export function createFilterFromObjectId(objectId: string | string[], explorationId?: ExplorationId): Filter {
    let includedVertexIdsConstraint: string[];

    if (isArray(objectId)) {
        includedVertexIdsConstraint = objectId;
    } else {
        includedVertexIdsConstraint = [objectId];
    }

    const filterGroup: FilterGroup = {
        id: {
            included: includedVertexIdsConstraint,
        },
    };

    if (explorationId) {
        filterGroup.exploration = [{ included: [explorationId] }];
    }

    return { filterGroups: [filterGroup] };
}

export function createConstraintsFromDashboardIds(dashboardIds: DashboardId | DashboardId[]): Filter {
    const filterGroup: FilterGroup = {
        type: {
            included: [SystemVertexTypeName.Dashboard],
        },
        id: {
            included: isArray(dashboardIds) ? dashboardIds : [dashboardIds],
        },
    };

    return { filterGroups: [filterGroup] };
}

export function createConstraintsFromChartIds(chartIds: ChartId | ChartId[]): Filter {
    const filterGroup: FilterGroup = {
        type: {
            included: [SystemVertexTypeName.Chart],
        },
        specificValues: [{
            included: isArray(chartIds) ? chartIds : [chartIds],
        }],
    };

    return { filterGroups: [filterGroup] };
}

export function createConstraintsFromResourceIds(resourceIds: ResourceId | ResourceId[]): Filter {
    const filterGroup: FilterGroup = {
        type: {
            included: [SystemVertexTypeName.Resource],
        },
        id: {
            included: isArray(resourceIds) ? resourceIds : [resourceIds],
        },
    };

    return { filterGroups: [filterGroup] };
}

export function createConstraintsFromBriefIds(briefIds: BriefId | BriefId[]): Filter {
    const filterGroup: FilterGroup = {
        type: {
            included: [SystemVertexTypeName.Brief],
        },
        id: {
            included: isArray(briefIds) ? briefIds : [briefIds],
        },
    };

    return { filterGroups: [filterGroup] };
}

export function getVertexIds(filter: Filter | FilterOperation): VertexId[] | undefined {
    if (isFilterOperation(filter)) {
        return undefined;
    }

    const ret: VertexId[] = [];

    forEach(filter.filterGroups, (filterGroup: FilterGroup) => {
        if (!filterGroup.id?.included) {
            return undefined;
        }
        if (filterGroup.id?.excluded) {
            return undefined;
        }
        if (filterGroup.type) {
            return undefined;
        }
        if (filterGroup.geographic) {
            return undefined;
        }
        if (filterGroup.textualSearch) {
            return undefined;
        }
        if (filterGroup.ranges) {
            return undefined;
        }
        if (filterGroup.texts) {
            return undefined;
        }

        if (filterGroup.id.included?.length) {
            ret.push(...filterGroup.id.included);
        }
    });

    return ret;
}

export function includeTypeToFilter(source: Filter, type: UniverseVertexTypeName | UniverseVertexTypeName[]): Filter {
    const types = isArray(type) ? type : [type];

    if (!source.filterGroups?.length) {
        return {
            ...source,
            filterGroups: [createTypeFilterGroup(types)],
        };
    }

    const ret: Filter = {
        ...source,
        filterGroups: source.filterGroups?.map((filterGroup: FilterGroup) => {
            let newType: TypeFilter | undefined = filterGroup.type;
            if (!newType) {
                newType = createTypeFilter(types);
            } else if (!newType.included) {
                newType = { ...newType, included: types };
            } else {
                newType = { ...newType, included: uniq([...newType.included, ...types]) };
            }

            return {
                ...filterGroup,
                type: newType,
            };
        }),
    };

    return ret;
}

export function includeExplorationToFilter(source: undefined, explorationId: undefined): undefined;
export function includeExplorationToFilter(source: undefined, explorationId: ExplorationId): Filter;
export function includeExplorationToFilter(source: Filter, explorationId?: ExplorationId): Filter;
export function includeExplorationToFilter(source?: Filter, explorationId?: ExplorationId): Filter | undefined;

export function includeExplorationToFilter(source: Filter | undefined, explorationId?: ExplorationId): Filter | undefined {
    if (!explorationId) {
        return source;
    }

    const explorationFilterGroup = createExplorationFilterGroup(explorationId);

    if (!source?.filterGroups?.length) {
        const ret: Filter = {
            ...source,
            filterGroups: [explorationFilterGroup],
        };

        return ret;
    }

    const ret: Filter = {
        ...source,
        filterGroups: source.filterGroups.map((filterGroup: FilterGroup) => {
            return {
                ...filterGroup,
                exploration: explorationFilterGroup.exploration,
            };
        }),
    };

    return ret;
}

export function createTypeFilter(type: UniverseVertexTypeName | UniverseVertexTypeName[]): TypeFilter {
    const types = isArray(type) ? type : [type];

    return { included: types };
}

export function createTypeFilterGroup(type: UniverseVertexTypeName | UniverseVertexTypeName[]): FilterGroup {
    return { type: createTypeFilter(type) };
}

export function createFilterForType(type: UniverseVertexTypeName | UniverseVertexTypeName[], explorationId?: ExplorationId): Filter {
    return {
        filterGroups: [{
            ...createTypeFilterGroup(type),
            ...createExplorationFilterGroup(explorationId),
        }],
    };
}

export function createExplorationFilterGroup(explorationId?: ExplorationId): FilterGroup {
    return {
        exploration: explorationId ? [{ included: [explorationId] }] : undefined,
    };
}

export function createHasPropertiesFilterGroup(properties: UniversePropertyName | UniversePropertyName[]): FilterGroup {
    const ps = isArray(properties) ? properties : [properties];

    return {
        specificValues: ps.map((p) => {
            const ret: PropertySpecificValueFilter = {
                propertyName: p,
                excluded: [null],
            };

            return ret;
        }),
    };
}

export function createFilterForTypeAndPropertyAndValue(
    type: UniverseVertexTypeName,
    propertyName: UniversePropertyName,
    value: any,
    explorationViewFilter: Filter | undefined,
    additionalFilters: Filter[] | undefined
): Filter | FilterOperation {
    const filter = {
        filterGroups: [{
            ...createTypeFilterGroup(type),

            specificValues: [{
                propertyName,
                included: [value],
            }],
        }],
    };

    if (!explorationViewFilter && isEmpty(additionalFilters)) {
        return filter;
    }

    const ret = filterChain(filter).intersect(explorationViewFilter, ...compact(additionalFilters)).toFilterOperation();

    return ret;
}

export function createFilterFromGeo(properties: Record<UniverseVertexTypeName, string>, searchedArea: Polygon | CenterArea): VertexFilter {
    //    region: RegionBox,
    //        geoProperties: Record<UniverseVertexTypeName, string>,

    let propertyConstraint: GeographicAreaFilter = {};

    if ('center' in searchedArea) {
        const { radius, center } = searchedArea as CenterArea;

        propertyConstraint = {
            center: { latitude: center.lat, longitude: center.lng },
            radius,
        };
    } else {
        const polygon = searchedArea as Polygon;

        propertyConstraint = {
            vertices: polygon.vertices,
        };
    }

    const filterGroups = map(properties, (propertyName: string, vertexTypeName: UniverseVertexTypeName) => {
        const filter: FilterGroup = {
            type: {
                included: [vertexTypeName],
            },
            geographic: [{
                propertyName: propertyName,
                included: [propertyConstraint],
            }],
        };

        return filter;
    });

    const ret: Filter = {
        filterGroups,
    };

    return ret;
}

export function createFilterFromTypesWithExcludedTypes(includedTypes: string | string[], excludedTypes?: string | string[]): Filter {
    const itemsToInclude = isString(includedTypes) ? [includedTypes] : includedTypes;
    if (!excludedTypes || (!isString(excludedTypes) && excludedTypes.length === 0)) {
        return createFilterForType(itemsToInclude);
    }
    const itemsToExclude = isString(excludedTypes) ? [excludedTypes] : excludedTypes;

    return {
        filterGroups: [
            {
                type: {
                    included: itemsToInclude,
                    excluded: itemsToExclude,
                },
            },
        ],
    };
}

export function createFilterWithExcludedId(filter: Filter): Filter {
    const itemsToExclude: string[] = [];
    forEach(filter.filterGroups, (filterGroup: FilterGroup) => {
        const itemIds = filterGroup.id?.included;
        if (isUndefined(itemIds)) {
            return;
        }

        itemsToExclude.push(...itemIds);
    });

    return {
        nothing: false,
        all: false,
        filterGroups: [
            {
                id: {
                    excluded: itemsToExclude,
                },
            },
        ],
    };
}
