import { chain, CollectionChain } from 'lodash';

import { ArgonosModule, ArgonosModuleId } from './modules';

const modulesById: Record<ArgonosModuleId, ArgonosModule> = {};

export function registerArgonosModule(module: ArgonosModule): void {
    if (modulesById[module.id]) {
        throw new Error('Module already registred');
    }

    modulesById[module.id] = module;
}


export function getArgonosModuleById(id: ArgonosModuleId): ArgonosModule | undefined {
    const result = modulesById[id];

    return result;
}

// TODO: should not return CollectionChain but ArgonosModule[]
export function listArgonosModules(): CollectionChain<ArgonosModule> {
    const list = Object.values(modulesById);

    const result = chain(list);

    return result;
}
