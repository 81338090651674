/**
 *
 * A component to show a tooltip on hover.
 *
 * Usage:
 * const trigger = (
 *   <ArgTooltip2 title='Tooltip'>
 *     <div>The Trigger</div>
 *   </ArgTooltip2>
 * )
 *
 * MISC:
 * - the trigger will receive a 'arg-tooltip-open' class when tooltip is
 *   opened, usefull if the trigger needs to the customized
 *
 * CAVEATS:
 *   - If children is not a react element, the component will add a wrapper.
 *   - If children is a react element, it must forward ref.
 *
 **/

import {
    cloneElement,
    forwardRef,
    isValidElement,
    ReactElement,
    ReactNode,
    Ref,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    arrow,
    autoUpdate,
    flip,
    FloatingArrow,
    FloatingPortal,
    offset,
    shift,
    useDismiss,
    useFloating,
    useHover,
    useInteractions,
    useMergeRefs,
} from '@floating-ui/react';
import { isFragment } from 'react-is';

import { ArgMessageValues, ArgRenderedText } from '../types';
import { DEFAULT_TOOLTIP_DELAY, DEFAULT_TOOLTIP_PLACEMENT } from '../defaults';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { toFloatingUIPlacement, TooltipPlacement } from './utils';
import { ArgMessageRenderer } from '../arg-message-renderer/arg-message-renderer';

import './arg-tooltip2.less';

export type { TooltipPlacement } from './utils';

const TOOLTIP_OFFSET = { mainAxis: 12, alignmentAxis: -10 };
const S_TO_MS = 1000;

export interface ArgTooltip2Props {
    /**
     * className for the tooltip
     */
    className?: ClassValue;

    /**
     * title is the content of the tooltip
     */
    title?: ArgRenderedText;

    /**
     * Values used to render the title with intl
     */
    messageValues?: ArgMessageValues;

    /**
     * The open state.
     */
    open?: boolean;

    /**
     * Callback for when the tooltip open or close.
     */
    onOpenChange?: (open: boolean) => void;

    /**
     * placement of the tooltip, default to bottomLeft
     */
    placement?: TooltipPlacement;

    /**
     * children
     */
    children: ReactNode;

    /**
     * a ref to the tooltip element
     */
    tooltipRef?: Ref<HTMLElement>;

    /**
     * wait before the specified time to open the tooltip (time in seconds)
     */
    mouseEnterDelay?: number;
}

export const ArgTooltip2 = forwardRef(function ArgTooltip2(props: ArgTooltip2Props, ref) {
    const {
        title,
        messageValues,
        children,
        open: externalOpen,
        onOpenChange: setExternalOpen,
        placement = DEFAULT_TOOLTIP_PLACEMENT,
        tooltipRef,
        mouseEnterDelay = DEFAULT_TOOLTIP_DELAY,
        className,
        ...otherProps
    } = props;

    const childReactElement: ReactElement | undefined = useMemo(() => (
        // skipping type due to isFragment type oddity
        (isValidElement(children) && !(isFragment(children) as any)) ? children : undefined
    ), [children]);

    const classNames = useClassNames('arg-tooltip2');

    const arrowRef = useRef<SVGSVGElement>(null);

    const [internalOpen, setInternalOpen] = useState(false);

    const open = externalOpen ?? internalOpen;

    const handleOpenChange = useCallback((value: boolean) => {
        setInternalOpen(value);
        setExternalOpen?.(value);
    }, [setExternalOpen]);

    const data = useFloating({
        placement: toFloatingUIPlacement(placement),
        open,
        onOpenChange: handleOpenChange,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(TOOLTIP_OFFSET),
            flip({
                crossAxis: placement.includes('-'),
                fallbackAxisSideDirection: 'start',
                padding: 5,
            }),
            shift({ padding: 5 }),
            arrow({
                element: arrowRef,
            }),
        ],
    });

    const hover = useHover(data.context, {
        delay: {
            open: mouseEnterDelay * S_TO_MS,
        },
    });

    const dismiss = useDismiss(data.context);

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        dismiss,
        { reference: otherProps },
    ]);

    const triggerRef = useRef<HTMLElement>(null);
    const childrenRef = (children as any)?.ref;
    const tooltipTriggerRef = useMergeRefs([
        data.refs.setReference,
        triggerRef,
        childrenRef,
        ref,
    ]);

    const tooltipContentRef = useMergeRefs([
        data.refs.setFloating,
        tooltipRef,
    ]);

    useEffect(() => {
        const triggerEl = triggerRef.current;
        if (!triggerEl && childReactElement) {
            console.error('If ArgTooltip children is a react element, it must forwardRef for tooltip to work.', 'className=', className, 'child=', childReactElement);
        }
    }, [childReactElement, className]);

    let trigger: ReactNode;
    const triggerCls = { 'tooltip-open': open };
    if (childReactElement) {
        trigger = cloneElement(
            childReactElement,
            {
                ...getReferenceProps({
                    ...childReactElement.props,
                }),
                ref: tooltipTriggerRef,
                className: classNames(
                    childReactElement.props.className,
                    triggerCls
                ),
            }
        );
    } else {
        trigger = (
            <div
                className={classNames('&-wrapper', triggerCls)}
                ref={tooltipTriggerRef}
                {...getReferenceProps()}
                // The user can style the trigger based on the state
                data-state={open ? 'open' : 'closed'}>
                {children}
            </div>
        );
    }

    return (
        <>
            {/* tooltip */}
            {open && (
                <FloatingPortal>
                    <div
                        ref={tooltipContentRef}
                        className={classNames('&-content', className)}
                        role='tooltip'
                        style={data.floatingStyles}
                        {...getFloatingProps()}>
                        <FloatingArrow
                            ref={arrowRef}
                            context={data.context}
                            className={classNames('&-arrow')} />
                        <ArgMessageRenderer
                            message={title}
                            messageValues={messageValues}
                        />
                    </div>
                </FloatingPortal>
            )}

            {/* trigger */}
            {trigger}
        </>
    );
});
