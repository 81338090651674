import { useMemo, useState } from 'react';

import { ArgInputSearch, useClassNames } from 'src/components/basic';
import { FullOntologyObjectType } from '../../types';

import './universe-library-panel.less';

interface ObjectsPanelContentProps {
    vertices: FullOntologyObjectType[];
}

export const ObjectsPanelContent: React.FC<ObjectsPanelContentProps> = ({ vertices }) => {
    const classNames = useClassNames('universe-library-panel');
    const [searchText, setSearchText] = useState<string>();

    const filteredVertices = useMemo(() => {
        return vertices.filter((vertex) =>
            vertex.displayName.toLowerCase().includes(searchText?.toLowerCase() || '')
        );
    }, [searchText, vertices]);

    return (
        <div>
            <div className={classNames('&-list-header')}>
                <ArgInputSearch
                    value={searchText}
                    onInputChange={(text) => setSearchText(text || '')}
                    className={classNames('&-search-bar')}
                />
            </div>
            {filteredVertices.map((vertex) => (
                <div key={vertex.name} className={classNames('&-list-item')}>
                    {vertex.displayName}
                </div>
            ))}
        </div>
    );
};
