// TODO: reduce code redundancy between this hook and code in components/features/keybindings-panel/keybinding-panel.tsx
// Consider moving this hook under src/hooks/
import { filter, forEach, reduce } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { GLOBAL_SCOPE_ID, KeyBindingDescriptor, KeyBindingsConfiguration, KeyBindingsContext, KeyBindingScopeDescriptor, KeyBindingsScopeIdentifier, normalizeText } from 'src/components/basic';

const messages = defineMessages({});

interface ByScope {
    scope: KeyBindingScopeDescriptor,
    keys: KeyBindingDescriptor[];
    extended?: boolean
}

export function useKeyBindings(searchedToken: string) {
    const keyBindingContext = useContext(KeyBindingsContext);
    const [userConfig, setUserConfig] = useState<KeyBindingsConfiguration | undefined>(keyBindingContext?.getUserConfig());

    const intl = useIntl();

    useEffect(() => {
        if (!keyBindingContext) {
            return;
        }

        function update(config?: KeyBindingsConfiguration) {
            setUserConfig(config);
        }

        keyBindingContext.eventEmitter.on('UserConfigChanged', update);

        return () => {
            keyBindingContext.eventEmitter.off('UserConfigChanged', update);
        };
    }, [keyBindingContext]);

    const byScopes: ByScope[] = useMemo(() => {
        const defs = keyBindingContext?.defs ?? {};

        const m: Record<KeyBindingsScopeIdentifier, ByScope> = {};

        let ret = reduce(defs, (acc, def) => {
            let scope = m[def.scope.id];
            if (!scope) {
                scope = {
                    scope: def.scope,
                    keys: [],
                };
                m[def.scope.id] = scope;
                acc.push(scope);
            }

            scope.keys.push(def);

            return acc;
        }, [] as ByScope[]);

        forEach(ret, (byScope) => {
            byScope.extended = true;
            for (let b = byScope; b;) {
                if (!b.scope.extends) {
                    break;
                }

                const parent = m[b.scope.extends.id];
                if (!parent) {
                    break;
                }

                byScope.keys.push(...parent.keys.filter((k) => !k.global));

                b = parent;

                if (b.extended) {
                    break;
                }
            }
        });

        ret = filter(ret, (byScope) => byScope.scope.hidden !== true);

        forEach(ret, (byScope) => {
            [...byScope.keys].sort((k1, k2) => {
                const label1 = intl.formatMessage(k1.name);
                const label2 = intl.formatMessage(k2.name);

                return label1.localeCompare(label2);
            });
        });

        [...ret].sort((s1, s2) => {
            if (s1.scope.id === GLOBAL_SCOPE_ID) {
                if (s2.scope.id === GLOBAL_SCOPE_ID) {
                    return 0;
                }

                return -1;
            }
            if (s2.scope.id === GLOBAL_SCOPE_ID) {
                return 1;
            }

            const label1 = intl.formatMessage(s1.scope.name);
            const label2 = intl.formatMessage(s2.scope.name);

            return label1.localeCompare(label2);
        });

        return ret;
    }, [keyBindingContext, intl]);

    const filteredByScopes: ByScope[] = useMemo(() => {
        let _byScopes;
        if (searchedToken) {
            const normalizedSearchedToken = normalizeText(searchedToken);

            const ret: ByScope[] = [];
            forEach(byScopes, (byScope) => {
                const scope = byScope.scope;
                const scopeLabel = normalizeText(intl.formatMessage(scope.name));
                if (scopeLabel.indexOf(normalizedSearchedToken) >= 0) {
                    ret.push(byScope);

                    return;
                }

                let ks: KeyBindingDescriptor[];

                forEach(byScope.keys, (keyBindingDescriptor) => {
                    const keyLabel = normalizeText(intl.formatMessage(keyBindingDescriptor.name));
                    if (keyLabel.indexOf(normalizedSearchedToken) < 0) {
                        return;
                    }

                    if (!ks) {
                        ks = [];
                        ret.push({
                            scope,
                            keys: ks,
                        });
                    }
                    ks.push(keyBindingDescriptor);
                });
            });

            _byScopes = ret;
        } else {
            _byScopes = Object.values(byScopes);
        }

        return _byScopes;
    }, [byScopes, searchedToken, intl]);

    return {
        userConfig,
        filteredByScopes,
    };
}
