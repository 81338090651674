import React, { createContext, ReactNode, useContext, useEffect, useRef } from 'react';
import Debug from 'debug';
import { isObject } from 'lodash';

import { PublicUser, User } from '../../model/user';
import {
    ArgUserId,
    ArgUserInfo,
    DataCacheRepository,
    DataCacheRepositoryResponse,
    IDataCacheRepository,
    ProgressMonitor,
    StateId,
    useDataCacheRepository,
} from '../basic';
import { useUserState } from '../../utils/rt-states/users/use-user-state';
import { isResponse404 } from '../basic/utils/response-error';
import { UsersAdminConnector } from '../../utils/connectors/users-admin-connector';

const debug = Debug('common:caches:UsersCache');

const NO_DATA: DataCacheRepositoryResponse<any> = [undefined, undefined, undefined];

const DEFAULT_TTL_MS = 0;

export class UsersCache {
    #dataCache: IDataCacheRepository<PublicUser>;

    constructor(cacheName: string, ttlMs: number = DEFAULT_TTL_MS) {
        this.#dataCache = new DataCacheRepository<PublicUser>(`users-cache:${cacheName}`, this.#dataLoader, { ttlMs });
    }

    get dataCache(): IDataCacheRepository<PublicUser> {
        return this.#dataCache;
    }

    getUser(userId: ArgUserId, stateId: StateId, progressMonitor?: ProgressMonitor): Promise<User | null> {
        const keys = { userId };
        const ret = this.#dataCache.loadPromise(undefined, keys, stateId, undefined, progressMonitor);

        return ret as Promise<User | null>;
    }

    #dataLoader = async (key: string, infos: {
        userId: ArgUserId
    }, previousValue: PublicUser | undefined, progressMonitor: ProgressMonitor): Promise<PublicUser | null> => {
        try {
            if (infos.userId) {
                const ret = await UsersAdminConnector.getInstance().getPublicUser(infos.userId, progressMonitor);

                return ret;
            }

            throw new Error('Unsupported key');
        } catch (error) {
            console.error('DataLoader error=', error);
            if (isResponse404(error)) {
                return null;
            }

            throw error;
        }
    };

    dispose() {
        this.#dataCache.dispose();
    }
}


export const UsersCacheContext = createContext<UsersCache | undefined>(undefined);

interface UserIdsCacheScopeProps {
    name: string;
    children: ReactNode;
    ttlMs?: number;
}

export function UsersCacheScope(props: UserIdsCacheScopeProps) {
    const { children, ttlMs, name } = props;

    const usersObjectCacheRef = useRef<UsersCache>();
    if (!usersObjectCacheRef.current) {
        usersObjectCacheRef.current = new UsersCache(name, ttlMs);
    }

    useEffect(() => {
        return () => {
            const usersObjectCache = usersObjectCacheRef.current;
            if (!usersObjectCache) {
                return;
            }
            usersObjectCacheRef.current = undefined;
            usersObjectCache.dispose();
        };
    }, []);

    return <UsersCacheContext.Provider value={usersObjectCacheRef.current}>
        {children}
    </UsersCacheContext.Provider>;
}

export function useUsersCache(userIdsCacheByParam?: UsersCache, ttlMs?: number): UsersCache {
    const userIdsCacheByContext = useContext(UsersCacheContext);

    const userIdsCacheByRef = useRef<UsersCache>();

    useEffect(() => {
        return () => {
            if (userIdsCacheByRef.current) {
                userIdsCacheByRef.current.dispose();
            }
        };
    }, []);

    if (userIdsCacheByParam) {
        return userIdsCacheByParam;
    }
    if (userIdsCacheByContext) {
        return userIdsCacheByContext;
    }

    if (userIdsCacheByRef.current) {
        return userIdsCacheByRef.current;
    }

    const userIdsCache = new UsersCache('useUsersCache', ttlMs);

    userIdsCacheByRef.current = userIdsCache;

    return userIdsCache;
}


function useUsersCacheRepository<K = string>(
    infos: K | string | undefined,
    stateId: StateId | undefined,
    userIdsCacheByParam?: UsersCache
): DataCacheRepositoryResponse<PublicUser> | undefined {
    const cacheContext = useUsersCache(userIdsCacheByParam);

    const dataRepository = cacheContext?.dataCache;

    const ret = useDataCacheRepository<PublicUser, K>(infos, stateId, dataRepository);

    return ret;
}


export function useUserById(userIdOrInfo: ArgUserId | ArgUserInfo | undefined, userIdsCacheByParam?: UsersCache): DataCacheRepositoryResponse<PublicUser | null> {
    const userId = userIdOrInfo ? (isObject(userIdOrInfo) ? userIdOrInfo.id : userIdOrInfo) : undefined;
    const key = userId ? { userId } : undefined;

    const userState = useUserState(userId);

    const ret = useUsersCacheRepository(key, userState?.stateId, userIdsCacheByParam);

    if (userIdOrInfo === undefined) {
        return NO_DATA;
    }

    return ret as DataCacheRepositoryResponse<PublicUser>;
}
