import { cloneDeep, set } from 'lodash';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ArgIcon, useClassNames } from 'src/components/basic';
import { useScopeStateContext } from '../../providers/policy-rules-provider';
import { Scope, ScopeProcessed } from '../../../../models/policy';

import './application-condition-expression-of-logic.less';

const messages = defineMessages({
    addExpression: {
        id: 'settings.application-condition-expression-of-logic.filter-buttons.add-expression',
        defaultMessage: 'Expression',
    },
    addGroup: {
        id: 'settings.application-condition-expression-of-logic.filter-buttons.add-group',
        defaultMessage: 'Group',
    },
});

interface FilterButtonsProps {
    currentPath: string;
    scope?: Scope | ScopeProcessed;
    blockScopes?: Scope[];
    operator?: 'and' | 'or';
}

export function FilterButtons(props: FilterButtonsProps) {
    const {
        currentPath,
        scope,
        blockScopes,
        operator,
    } = props;

    const { setScope } = useScopeStateContext();

    const classNames = useClassNames('settings-filter-buttons');

    const addExpression = () => {
        setScope((currentScope) => {
            if (blockScopes) {
                return set(cloneDeep(currentScope), currentPath, [
                    ...blockScopes,
                    {
                        Type: undefined,
                        Data: {},
                    },
                ]);
            }

            if (scope && 'id' in scope && 'Type' in scope) {
                return {
                    and: [
                        { Type: scope.Type, Data: scope.Data },
                        { Type: undefined, Data: {} },
                    ],
                    id: scope.id,
                };
            }

            return currentScope;
        });
    };

    const addGroup = () => {
        setScope((currentScope) => {
            if (!blockScopes) return currentScope;

            const newOperator = operator === 'and' ? 'or' : 'and';

            return set(cloneDeep(currentScope), currentPath, [
                ...blockScopes,
                {
                    [newOperator]: [
                        {
                            Type: undefined,
                            Data: {},
                        },
                    ],
                },
            ]);
        });
    };

    return (
        <div className={classNames('&-container')}>
            <div className={classNames('&-icon')} onClick={() => addExpression()}>
                <ArgIcon name='icon-add-outline' />
                <div>
                    <FormattedMessage {...messages.addExpression} />
                </div>
            </div>
            {blockScopes && (
                <div className={classNames('&-icon')} onClick={() => addGroup()}>
                    <ArgIcon name='icon-add-outline' />
                    <div>
                        <FormattedMessage {...messages.addGroup} />
                    </div>
                </div>
            )}
        </div>
    );
}
