import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { defineMessages } from 'react-intl';

import { VertexOrEdge } from 'src/exploration/hooks/use-graph-style-customisation';
import {
    RetentionPolicyActionTargetKind,
    RetentionPolicyLinkKind,
    RetentionPolicyVertexEdge,
} from 'src/settings/universes/retention/types';
import { FullOntology, FullOntologyLinkType, FullOntologyObjectType, OntologyProperty } from '../../types';
import { PropertyComponent } from './property-component';
import { ClassValue, useArgNotifications, useCallbackAsync, useClassNames } from '../../../../../components/basic';
import { AddEditOntology } from '../../../../models/dtoApi';
import ontologiesConnector from '../../../../connectors/ontologies-connector';

import './properties-list.less';

const messages = defineMessages({
    updatePropertyErrorMsg: {
        id: 'settings.properties-list.updatePropertyErrorMsg',
        defaultMessage: 'Failed to update properties order',
    },
});

export interface PropertiesListProps {
    className?: ClassValue;
    propertyList: OntologyProperty[];
    edgeOrVertex: FullOntologyLinkType | FullOntologyObjectType;
    propertyOf: VertexOrEdge;
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
    retentionSetUp: (retention: RetentionPolicyVertexEdge, type: RetentionPolicyActionTargetKind | 'Property', targetProperty?: string) => string;
    retention: RetentionPolicyVertexEdge;
    setRetention: Dispatch<SetStateAction<RetentionPolicyVertexEdge>>;
    retentionLink: RetentionPolicyLinkKind;
}

export function PropertiesList(props: PropertiesListProps) {
    const {
        className,
        propertyList,
        edgeOrVertex,
        propertyOf,
        ontology,
        setOntology,
        retentionSetUp,
        retention,
        setRetention,
        retentionLink,
    } = props;

    const classNames = useClassNames('settings-ontology-properties-list');
    const notifications = useArgNotifications();

    const [properties, setProperties] = useState(() => getPropertiesWithId(propertyList));

    useEffect(() => {
        const newProperties = getPropertiesWithId(propertyList);
        setProperties(newProperties);
    }, [propertyList]);

    const [updatePropertiesOrder] = useCallbackAsync(async () => {
        const editOntologyProps: AddEditOntology = {
            ontologyId: ontology.id,
            name: edgeOrVertex.name,
            newDisplayName: edgeOrVertex.displayName,
            newProperties: properties,
        };
        try {
            if (propertyOf === VertexOrEdge.Vertex) {
                await ontologiesConnector.editOntologyObject(editOntologyProps);
            } else {
                await ontologiesConnector.editOntologyEdge(editOntologyProps);
            }
            const newOntology = await ontologiesConnector.getFullOntology(ontology.id);
            setOntology(newOntology);
        } catch (e) {
            notifications.snackError({ message: messages.updatePropertyErrorMsg }, e as Error);
        }
    }, [edgeOrVertex.displayName, edgeOrVertex.name, ontology.id, propertyOf, setOntology, properties, notifications]);

    const reorderProperties = useCallback((dragIndex: number, hoverIndex: number) => {
        const swapProperties = [...properties];
        [swapProperties[dragIndex], swapProperties[hoverIndex]] = [swapProperties[hoverIndex], swapProperties[dragIndex]];
        setProperties(swapProperties);
    }, [properties]);

    return (
        <div className={classNames('&', className)}>
            <ul className={classNames('&-body')}>
                <DndProvider backend={HTML5Backend}>
                    {properties.map((property, index) => {
                        return (
                            <PropertyComponent
                                key={property.id}
                                property={property}
                                index={index}
                                reorderProperties={reorderProperties}
                                updatePropertiesOrder={updatePropertiesOrder}
                                edgeOrVertex={edgeOrVertex}
                                propertyOf={propertyOf}
                                ontology={ontology}
                                setOntology={setOntology}
                                retentionSetUp={retentionSetUp}
                                retention={retention}
                                setRetention={setRetention}
                                retentionLink={retentionLink}
                            />
                        );
                    })}
                </DndProvider>
            </ul>
        </div>
    );
}

function getPropertiesWithId(propertyList: OntologyProperty[]) {
    return propertyList.map((property) => ({
        ...property,
        id: property.name,
    }));
}
