import { omit } from 'lodash';

import { DEFAULT_BADGE_STYLE } from 'src/components/common/graph/constants';
import { VertexStyle } from '../model/vertex';

export const DEFAULT_VERTEX_STYLE: VertexStyle = {
    size: 1.5,
    fillColor: 'white',
    iconName: 'icon-argonode',
    iconFontFamily: 'icomoon',
    iconScale: 0.7,
    iconColor: '#5765B4',
    //strokeColor: '#FFFFFF',
    //transparentStrokeColor: true,
    fontFamily: 'Roboto',
    badgeColor: 'white',
    badgeFontColor: 'white',
};

export const DEFAULT_CLUSTER_STYLE: VertexStyle = omit(DEFAULT_VERTEX_STYLE, 'size');

export const DEFAULT_UNDEFINED_VALUES_STYLE = {
    iconName: 'icon-cross',
    fillColor: '#808080',
    size: 1.5,
};

export const DEFAULT_FLAGGED_OBJECT_BADGE_STYLE = {
    fontColor: '#AD1700',
    icon: 'icon-flag1',
    unFlaggedIcon: 'icon-outlined_flag',
    backGroundColor: 'white',
    size: DEFAULT_BADGE_STYLE.defaultSize,
};

export const DEFAULT_EXTRACTED_OBJECT_ICON_STYLE = {
    fontColor: '#AD1700',
    icon: 'icon-start',
    backGroundColor: 'white',
    size: DEFAULT_BADGE_STYLE.defaultSize,
};
