import { DataFilter, getPredicate, SortableArrayDataProvider } from '../../../components/basic';
import { UserProfileField } from '../../../model/user-metadata';

export interface PropertiesDataFilter extends DataFilter {
    search?: string;
}

export class PropertiesDataProvider extends SortableArrayDataProvider<UserProfileField, PropertiesDataFilter> {
    filterData(data: UserProfileField[], filter?: PropertiesDataFilter): UserProfileField[] {
        if (!filter?.search) {
            return [...data];
        }

        const filteredData = data.filter((item) =>
            Object.values(item).find(getPredicate(filter.search!))
        );

        return filteredData;
    }
}
