import { getProceoApi } from '../../../utils/connectors/api-url';
import { ArgonosPieceConnector } from '../../../utils/connectors/argonos-casepiece-connector';
import { registerCasePieceType } from '../../../utils/connectors/mappers';
import { CasePieceType } from '../../../model/case-piece-type';

export class ProceoPieceConnector extends ArgonosPieceConnector {
    private static instance: ProceoPieceConnector;

    static getInstance(): ProceoPieceConnector {
        if (!ProceoPieceConnector.instance) {
            ProceoPieceConnector.instance = new ProceoPieceConnector('proceo.pieces', getProceoApi());
        }

        return ProceoPieceConnector.instance;
    }
}

registerCasePieceType('person', CasePieceType.Person);
registerCasePieceType('act', CasePieceType.Act);
registerCasePieceType('offence', CasePieceType.Offence);
registerCasePieceType('service', CasePieceType.Service);
