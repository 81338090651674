import { ReactNode, useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
    ArgTabKey,
    ClassNamesFn,
    ClassValue,
    useClassNames,
} from '../../basic';
import { FormContainer, FormDocument, FormElement, FormElementId, FormTabs } from './model';
import { FormRenderContext, FormRenderFactory } from './render-factory';
import { EmptyPane } from '../panes/empty-pane';

import './editable-form.less';

export const CLASSNAME = 'editable-form';

const messages = defineMessages({
    property: {
        id: 'common.forms.editable-form.Property',
        defaultMessage: 'Property',
    },
    value: {
        id: 'common.forms.editable-form.Value',
        defaultMessage: 'Value',
    },
});

export interface EditableFormProps {
    className?: ClassValue;
    formRenderFactory: FormRenderFactory;
    formDocument: FormDocument;
    hideFormElement?: (element: FormElement) => boolean;
    onActiveTabKeyChange?: (formTabs: FormTabs, activeTabKey: ArgTabKey) => void;
}

export function EditableForm(props: EditableFormProps) {
    const {
        className,
        formRenderFactory,
        formDocument,
        hideFormElement,
        onActiveTabKeyChange,
    } = props;

    const classNames = useClassNames(CLASSNAME);
    const [activeTabKeys, setActiveTabKeys] = useState<Record<FormElementId, ArgTabKey>>({});
    // Internal form (use for temporary mutation like DND)
    const [internalformDocument, setInternalFormDocument] = useState<FormDocument>(formDocument);
    // Make sure to reset on parent form change
    useEffect(() => {
        setInternalFormDocument(formDocument);
    }, [formDocument]);

    const context = useMemo(() => {
        const parentMap = new Map<FormElement, FormContainer>();
        const context: FormRenderContext = {
            renderFactory: formRenderFactory,
            parentMap,
            activeTabKeys,
            setInternalFormDocument,
            formDocument: internalformDocument,
            hideFormElement,
            onActiveTabKeyChange(this: FormRenderContext, formTabs: FormTabs, activeTabKey: ArgTabKey) {
                setActiveTabKeys((prev) => ({
                    ...prev,
                    [formTabs.id]: activeTabKey,
                }));
                onActiveTabKeyChange?.(formTabs, activeTabKey);
            },
        };

        return context;
    }, [
        formRenderFactory,
        activeTabKeys,
        internalformDocument,
        hideFormElement,
        onActiveTabKeyChange,
    ]);

    const nodes = useMemo<ReactNode>(() => (
        render(internalformDocument, context, classNames)
    ), [context, classNames, internalformDocument]);

    if (nodes === null) {
        return (
            <div className={classNames('&', className, 'empty')}>
                <EmptyPane />
            </div>
        );
    }

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-header')}>
                <div className={classNames('&-header-property')}>
                    <FormattedMessage {...messages.property} />
                </div>
                <div className={classNames('&-header-value')}>
                    <FormattedMessage {...messages.value} />
                </div>
            </div>
            <div className={classNames('&-nodes')}>
                {nodes}
            </div>
        </div>
    );
}

function render(element: FormElement, context: FormRenderContext, classNames: ClassNamesFn) {
    const shouldHide = context.hideFormElement?.(element);

    if (shouldHide) {
        return null;
    }

    switch (element.type) {
        case 'property':
            return context.renderFactory.property(element, context);

        case 'image':
            return context.renderFactory.image(element, context);

        case 'label':
            return context.renderFactory.label(element, context);

        case 'iframe':
            return context.renderFactory.iframe(element, context);

        case 'button':
            return context.renderFactory.button(element, context);

        case 'uuid':
            return context.renderFactory.uuid(element, context);

        case 'section': {
            const children = element.children.reduce((acc, child) => {
                context.parentMap.set(child, element);
                const childNode = render(child, context, classNames);
                acc.push(childNode);

                return acc;
            }, [] as ReactNode[]);

            return context.renderFactory.section(element, context, children);
        }

        case 'document': {
            const children = element.children.reduce((acc, child) => {
                context.parentMap.set(child, element);
                const childNode = render(child, context, classNames);
                acc.push(childNode);

                return acc;
            }, [] as ReactNode[]);

            return context.renderFactory.document(element, context, children);
        }

        case 'tabs': {
            const childrenByTab = element.children.reduce((acc, tab) => {
                if (tab.type !== 'tab') {
                    return acc;
                }

                context.parentMap.set(tab, element);

                const children = tab.children.reduce((acc, child) => {
                    context.parentMap.set(child, tab);
                    const childNode = render(child, context, classNames);
                    acc.push(childNode);

                    return acc;
                }, [] as ReactNode[]);

                acc[tab.id] = children;

                return acc;
            }, {} as Record<string, ReactNode[]>);

            return context.renderFactory.tabs(element, context, childrenByTab);
        }

        case 'composite':
            return context.renderFactory.composite(element, context);


        default: {
            console.error('*** Unsupported type=', element.type);

            return null;
        }
    }
}
