import { isArray } from 'lodash';
import React from 'react';
import { MessageDescriptor } from 'react-intl';

import { ArgRenderFunction, useClassNames, dayjs, ProgressMonitor } from '../../../basic';
import {
    AdvancedStyleType,
    DiscreteValue,
    Interval,
    RuleSet,
    StyleControlType,
} from './graph-style';
import { AdvancedStylesContainer } from './advanced-styles-container';
import { AdvancedBody } from './advanced-body';
import { Property } from 'src/components/common/controls/controls-type';
import { GetGradientBounds } from './use-get-property-bounds';

export type GradientOrInterval = 'interval' | 'gradient';
export type Side = 'left' | 'right';

interface AdvancedStyleProps {
    ruleSets?: RuleSet[];
    label: React.ReactNode | MessageDescriptor | ArgRenderFunction;
    properties: Property[];
    property?: Property;
    styleType: AdvancedStyleType;
    getPossibleValues?: (progressMonitor: ProgressMonitor) => Promise<string[]>;
    getGradientBounds?: GetGradientBounds;
    onAdvancedStylePropertyNameChange?: (
        advancedStyleType: AdvancedStyleType,
        property: string | undefined,
        controlType?: StyleControlType
    ) => Promise<void>;
    onAdvancedStylePropertyValueChange?: (
        advancedStyleType: AdvancedStyleType,
        value: string | number | boolean | null,
        index: number,
        isBool?: boolean,
        isMultiString?: boolean,
    ) => Promise<void>;
    onAdvancedStylePropertyRangeValueChange?: (
        advancedStyleType: AdvancedStyleType,
        side: Side,
        value: number | dayjs.Dayjs | null,
        index: number
    ) => Promise<void>;
    onAdvancedStylePropertyDateRangeValueChange?: (
        side: Side,
        value: dayjs.Dayjs | null,
        index: number
    ) => Promise<void>;
    onAdvancedStylePropertyRangeIntervalTypeChange?: (
        advancedStyleType: AdvancedStyleType,
        side: Side,
        index: number
    ) => Promise<void>;
    onAdvancedStyleChange?: (
        advancedStyleType: AdvancedStyleType,
        style: Record<string, any>,
        index: number
    ) => Promise<void>;
    onAddNewStyleRule?: (advancedStyleType: AdvancedStyleType, value: DiscreteValue | Interval | null) => Promise<void>;
    onRemoveStyleRule?: (advancedStyleType: AdvancedStyleType, index: number) => Promise<void>;
    toggleGradientInterval: (
        advancedStyleType: AdvancedStyleType,
        gradientOrInterval: GradientOrInterval,
        property: string
    ) => Promise<void>;
    onGradientPropertyRangeValueChange: (
        type: 'property' | 'size',
        side: Side,
        value: number | dayjs.Dayjs | null
    ) => Promise<void>;
    addUndefinedValueStylesRule?: (advancedStyleType: AdvancedStyleType) => Promise<void>;
    removeUndefinedValueStylesRule?: (advancedStyleType: AdvancedStyleType) => Promise<void>;
}

const DISCRETE_NEW_RULE: DiscreteValue = { value: null };
const CONTINUOUS_NEW_RULE: Interval = { interval: { type: 'Open', left: null, right: null } };


export function AdvancedStyle(props: AdvancedStyleProps) {
    const {
        styleType,
        ruleSets,
        properties,
        property,
        getPossibleValues,
        getGradientBounds,
        onAdvancedStylePropertyNameChange,
        onAdvancedStylePropertyValueChange,
        onAdvancedStylePropertyRangeValueChange,
        onAdvancedStylePropertyRangeIntervalTypeChange,
        onAdvancedStyleChange,
        onAddNewStyleRule,
        onRemoveStyleRule,
        toggleGradientInterval,
        onGradientPropertyRangeValueChange,
        addUndefinedValueStylesRule,
        removeUndefinedValueStylesRule,
        label,
    } = props;

    const classNames = useClassNames('graph-advanced-style');

    const onPropertyChange = async (newProperty: Property | Property[] | undefined) => {
        const _property = isArray(newProperty) || undefined ? undefined : newProperty;

        if (_property === undefined) {
            await onAdvancedStylePropertyNameChange?.(styleType, _property, undefined);
        } else {
            const controlType = getControlType(_property.isContinuous, styleType);
            await onAdvancedStylePropertyNameChange?.(styleType, _property.name, controlType);
        }
    };

    const onAddNewRule = () => {
        const newRule = property?.isContinuous ? CONTINUOUS_NEW_RULE : DISCRETE_NEW_RULE;
        onAddNewStyleRule?.(styleType, newRule);
    };

    const handleUndefinedValuesStyleRule = (active: boolean) => {
        if (active) {
            addUndefinedValueStylesRule?.(styleType);
        } else {
            removeUndefinedValueStylesRule?.(styleType);
        }
    };

    return (
        <div className={classNames('&')}>
            <AdvancedStylesContainer
                styleType={styleType}
                label={label}
                property={property}
                ruleSets={ruleSets}
                properties={properties}
                onPropertyChange={onPropertyChange}
                onAddNewRule={onAddNewRule}
                addNewRuleButtonDisabled={false}
                handleUndefinedValuesStyleRule={handleUndefinedValuesStyleRule}
            >
                <AdvancedBody
                    ruleSets={ruleSets}
                    property={property}
                    styleType={styleType}
                    getPossibleValues={getPossibleValues}
                    getGradientBounds={getGradientBounds}
                    onAdvancedStyleDiscreteValueChange={onAdvancedStylePropertyValueChange}
                    onAdvancedStyleChange={onAdvancedStyleChange}
                    onAdvancedStyleNumberRangeValueChange={onAdvancedStylePropertyRangeValueChange}
                    onAdvancedStyleRangeIntervalTypeChange={onAdvancedStylePropertyRangeIntervalTypeChange}
                    onAdvancedStyleDateRangeValueChange={onAdvancedStylePropertyRangeValueChange}
                    onRemoveStyleRule={onRemoveStyleRule}
                    toggleGradientInterval={toggleGradientInterval}
                    onGradientPropertyRangeValueChange={onGradientPropertyRangeValueChange}
                />
            </AdvancedStylesContainer>
        </div>
    );
}

function getControlType(isPropertyTypeContinuous: boolean | undefined, styleType: AdvancedStyleType) {
    if (isPropertyTypeContinuous) {
        return styleType === 'size' ? 'gradient' : 'interval';
    }

    return 'discrete';
}
