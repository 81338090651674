import { RtApi, RtApiSubType } from '../../utils/rt-states/rt-api';
import { getAdministrationApi } from '../../utils/connectors/api-url';
import { Environment } from '../../utils/environment';
import { USER_ALL_EVENTS } from '../../utils/rt-states/events';

let administrationRtApi: RtApi | undefined = undefined;

export function getAdministrationRtApi(): RtApi {
    if (administrationRtApi) {
        return administrationRtApi;
    }

    const api = getAdministrationApi() || Environment.apiServerUrl;
    if (!api) {
        throw new Error('AdminURL is not set');
    }

    administrationRtApi = {
        argonosModuleId: 'chapsVision.Administration',
        subType: RtApiSubType.Users,
        api,
        defaultServiceUrl: '/users',
        events: USER_ALL_EVENTS,
    };

    return administrationRtApi;
}
