import { defineMessages, useIntl } from 'react-intl';

import { useClassNames } from 'src/components/basic';

import './tree.less';

const messages = defineMessages({
    and: {
        id: 'settings.tree.and',
        defaultMessage: 'AND',
    },
    or: {
        id: 'settings.tree.or',
        defaultMessage: 'OR',
    },
});

interface TreeProps {
    sizes: number[];
    block: 'and' | 'or';
    changeOperator: () => void;
}

export const Tree: React.FC<TreeProps> = ({ sizes, block, changeOperator }) => {
    const intl = useIntl();
    const classNames = useClassNames('tree-block');

    if (sizes.length == 1) {
        const parentClass = block === 'and' ? '&-only-and' : '&-only-or';
        const operatorClass =
            block === 'and' ? '&-and-or-block-only-and' : '&-and-or-block-only-or';

        return (
            <div>
                <div className={classNames(parentClass)} style={{ height: sizes[0] }}>
                    <div className={classNames(operatorClass)} onClick={() => changeOperator()}>
                        {intl.formatMessage(block === 'and' ? messages.and : messages.or)}
                    </div>
                </div>
                <div className={classNames('&')} style={{ height: sizes[sizes.length - 1] }} />
            </div>
        );
    }
    const parentClass = block === 'and' ? '&-middle-and' : '&-middle-or';
    const operatorClass =
        block === 'and' ? '&-and-or-block-middle-and' : '&-and-or-block-middle-or';

    return (
        <div className='division-blocks'>
            <div className={classNames('&')} style={{ height: sizes[0] }} />
            {sizes.slice(0, -1).map((el, idx) => (
                <div
                    className={classNames(parentClass, idx === 0 && 'top-border')}
                    style={{ height: sizes[idx] + sizes[idx + 1] }}
                    key={idx}
                >
                    <div className={classNames(operatorClass)} onClick={() => changeOperator()}>
                        {intl.formatMessage(block === 'and' ? messages.and : messages.or)}
                    </div>
                </div>
            ))}
            <div className={classNames('&')} style={{ height: sizes[sizes.length - 1] }} />
        </div>
    );
};
