import { ArgButton, ArgChangeReason, ArgInputText, useClassNames } from '../../../../basic';
import { CommonControlProps, useGetStyleModifier } from './utils';
import { DiscreteControlMessages } from '../advanced-body';
import { usePropertyPicker } from '../../../controls/use-property-picker';

import './discrete-control.less';

export interface DiscreteTextControlProps extends CommonControlProps {
    fieldValue?: string;
    onFieldValueChange?: (value: string | null, reason: ArgChangeReason) => void;
    inputDebounce?: boolean;
    messages: DiscreteControlMessages;
}

export function DiscreteTextControl(props: DiscreteTextControlProps) {
    const {
        type,
        fieldValue,
        index,
        fillColor,
        iconColor,
        strokeColor,
        badgeFillColor,
        badgeIconName,
        doesBadgeBlink,
        size,
        iconName,
        lineStyle,
        onStyleChange,
        onFieldValueChange,
        canBeRemoved,
        onRemoveComponent,
        inputDebounce,
        isUndefinedStyleRule,
        messages,
        property,
        pickerOptions,
    } = props;

    const classNames = useClassNames('discrete-control');
    const styleModifier = useGetStyleModifier(
        type,
        classNames,
        index,
        fillColor,
        iconColor,
        strokeColor,
        badgeFillColor,
        badgeIconName,
        size,
        iconName,
        onStyleChange,
        doesBadgeBlink,
        lineStyle
    );
    const cls = {
        size: type === 'size',
        'line-style': type === 'style',
    };

    const isFixedValues = !!property.fixedValues;

    const inputPlaceholder = (() => {
        if (isUndefinedStyleRule) {
            return messages.undefinedStyleRulePlaceholder;
        }

        return isFixedValues ? messages.comboPlaceholder : messages.inputPlaceholder;
    })();

    const propoverPickerProps = usePropertyPicker(
        {
            ...pickerOptions,
            pickList: pickerOptions?.pickList && !isUndefinedStyleRule,
            fixedValues: property.fixedValues,
        },
        onFieldValueChange
    );

    return (
        <div className={classNames('&')}>
            <div className={classNames('&-content', cls)}>
                {styleModifier}
                <ArgInputText
                    className={isFixedValues ? classNames('&-pop-only') : undefined}
                    value={fieldValue}
                    debounce={inputDebounce}
                    onChange={onFieldValueChange}
                    placeholder={inputPlaceholder}
                    disabled={!!isUndefinedStyleRule}
                    {...propoverPickerProps}
                    readOnly={isFixedValues}
                    onInputClick={() => isFixedValues && propoverPickerProps.onPopoverVisibleChange?.(true)}
                />
            </div>
            {canBeRemoved && (
                <ArgButton
                    icon='icon-trash'
                    type='ghost'
                    className={classNames('&-trash-button')}
                    onClick={() => onRemoveComponent?.(type, index)}
                />
            )}
        </div>
    );
}
