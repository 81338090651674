import { cloneDeep, max } from 'lodash';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useIntl } from 'react-intl';

import { ArgButton, ArgFormLabel, ArgSwitch, useClassNames } from 'src/components/basic';
import { messages } from '../const';
import { OptionComponent } from './fixed-values-option';

import './fixed-values-options-list.less';

interface OptionsComponentListProps {
    optionsList: { id: number; value: string }[];
    setOptionsList: React.Dispatch<
        React.SetStateAction<
            {
                value: string;
                id: number;
            }[]
        >
    >;
    displayFinalOptionOnly: boolean;
    setDisplayFinalOptionOnly: Dispatch<SetStateAction<boolean>>;
}

export function OptionComponentList(props: OptionsComponentListProps) {
    const {
        optionsList,
        setOptionsList,
        displayFinalOptionOnly,
        setDisplayFinalOptionOnly,
    } = props;

    const intl = useIntl();
    const classNames = useClassNames('fixed-values-options-list');

    const appendOptionsList = () => {
        const maxId = max(optionsList.map((option) => option.id));

        const newId = maxId ? maxId + 1 : 0;

        setOptionsList([...optionsList, { value: '', id: newId }]);
    };

    const moveOption = useCallback((dragIndex: number, hoverIndex: number) => {
        setOptionsList((prevOptionsList) => {
            const newOptionsList = cloneDeep(prevOptionsList);
            newOptionsList.splice(dragIndex, 1);
            newOptionsList.splice(hoverIndex, 0, prevOptionsList[dragIndex]);

            return newOptionsList;
        });
    }, []);

    return (
        <>
            <ArgFormLabel propertyName={messages.optionsList}>
                <DndProvider backend={HTML5Backend}>
                    <div>{intl.formatMessage(messages.useSlash)}</div>
                    <ul className={classNames('&-rows')}>
                        {optionsList.map((option, index) => {
                            return (
                                <OptionComponent
                                    key={option.id}
                                    option={option}
                                    optionsList={optionsList}
                                    setOptionsList={setOptionsList}
                                    moveOption={moveOption}
                                    index={index}
                                />
                            );
                        })}
                    </ul>
                    <ArgButton
                        className={classNames('&-new-option-button')}
                        onClick={appendOptionsList}
                        label={messages.option}
                        icon='icon-add-outline'
                        type='ghost'
                    />
                </DndProvider>
            </ArgFormLabel>

            <ArgSwitch
                value={displayFinalOptionOnly}
                onChange={() => setDisplayFinalOptionOnly((current) => !current)}
                label={intl.formatMessage(messages.displayLastOptionOnly)}
            />

        </>
    );
}
