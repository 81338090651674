import { ReactNode, useEffect, useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { Route, Routes } from 'react-router-dom';

import { UseGetMeReturnType, UserContext } from '../../contexts/user-context';
import {
    ClassValue,
    ProgressMonitor,
    useArgNotifications,
    useClassNames,
    useMemoAsync,
    useSetTimeout,
} from '../basic';
import { LoadingPane } from '../common/panes/loading-pane';
import { ErrorPane } from '../common/panes/error-pane';
import { UserPermissionsProvider } from 'src/contexts/user-permissions-context';
import { ApplicationConfigurationsContext } from '../../contexts/application-configurations';
import { useApplicationConfigurations } from '../../hooks/use-application-configuration';
import { ArgonosModule } from '../application/modules';
import { UsersConnector } from '../../utils/connectors/users-connector';
import { listArgonosModules } from '../application/modules-manager';
import { CurrentArgonosModuleLayout } from '../application/argonos-current-module';

import './authenticated-apps-router.less';

const FORCE_ERROR = false;
const FORCE_LOADING = false;

const RETRY_TIMER = 1000 * 30 + Math.floor(Math.random() * 10000);

const messages = defineMessages({
    errorLoadingUserProfile: {
        id: 'authenticated-apps-router.ErrorLoadingUserProfile',
        defaultMessage: '<p><b>Internal server error</b></p><p>Error while loading user profile.</p><p>Please retry in few minutes.</p>',
    },
    loadingUserError: {
        id: 'settings.settings-router.LoadingUserError',
        defaultMessage: 'Failed to load user',
    },
    verifyingAuthentication: {
        id: 'settings.settings-router.VerifyingAuthentication',
        defaultMessage: 'Verifying authentication {threeDotsLoading}',

    },
});

export function AuthenticatedAppsRouter() {
    const classNames = useClassNames('common-authenticated-apps-router');

    const notifications = useArgNotifications();
    const [applicationConfigurations] = useApplicationConfigurations();

    const [currentUser, progressMonitor, error] = useMemoAsync<UseGetMeReturnType>(async (progressMonitor: ProgressMonitor) => {
        try {
            const user = await UsersConnector.getInstance().myUserDetails(progressMonitor);

            const ret: UseGetMeReturnType = {
                me: user,
            };

            return ret;
        } catch (error: any) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.loadingUserError }, error as Error);
            throw error;
        } finally {
            progressMonitor.done();
        }
    }, [notifications], messages.verifyingAuthentication);

    const startTimer = useSetTimeout(RETRY_TIMER);

    useEffect(() => {
        if (!error) {
            return;
        }

        console.log('Waiting for', RETRY_TIMER, 'ms');

        async function test() {
            try {
                await UsersConnector.getInstance().myUserDetails(progressMonitor);
            } catch (x) {
                console.log('Retry for', RETRY_TIMER, 'ms');
                startTimer(test);

                return;
            }

            console.log('Ready !!!');

            document.location.reload();
        }

        startTimer(test);
    }, [error]);

    const routes = useMemo(() => {
        const modules = listArgonosModules();

        const routes: ReactNode[] = modules
            .map((module: ArgonosModule) => (
                <Route
                    key={module.id}
                    element={<CurrentArgonosModuleLayout module={module} />}
                >
                    {module.getRoutes?.()}
                </Route>
            ))
            .compact()
            .flatten()
            .value();

        return routes;
    }, []);

    if (FORCE_ERROR || error) {
        return (
            <div className={classNames('&', 'error')}>
                <ErrorPane
                    message={messages.errorLoadingUserProfile}
                    error={error}
                    className={classNames('&-error')}
                    size='large'
                />
            </div>
        );
    }

    if (FORCE_LOADING || currentUser === undefined || progressMonitor?.isRunning) {
        return (
            <div className={classNames('&', 'loading')}>
                <LoadingPane
                    progressMonitor={progressMonitor}
                    className={classNames('&-loading')}
                    size='large'
                />
            </div>
        );
    }

    return (
        <ApplicationConfigurationsContext.Provider value={applicationConfigurations}>
            <UserContext.Provider value={currentUser}>
                <UserPermissionsProvider>
                    <Routes>
                        {routes}
                    </Routes>
                </UserPermissionsProvider>
            </UserContext.Provider>
        </ApplicationConfigurationsContext.Provider>
    );
}

export interface ArgonosModuleRouterProps {
    className?: ClassValue;
    argonosModule: ArgonosModule;
}
