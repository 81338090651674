import { Plugin } from '@ckeditor/ckeditor5-core';
import { WidgetResize } from '@ckeditor/ckeditor5-widget';
import ImageLoadObserver from '@ckeditor/ckeditor5-image/src/image/imageloadobserver';
import ScreenshotResizeUi from './screenshot-resizeui';

const RESIZABLE_IMAGES_CSS_SELECTOR =
    '.arg-ck-screenShot.ck-widget > span > div > div > img';

const RESIZED_IMAGE_CLASS = 'screenshot_resized';


export default class ScreenshotResizeHandles extends Plugin {
    /**
     * @inheritDoc
     */
    static get requires() {
        return [WidgetResize, ScreenshotResizeUi];
    }

    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'argonos-resource-screenshot:resize-handles';
    }

    /**
     * @inheritDoc
     */
    init() {
        const command = this.editor.commands.get('resizeScreenshot');
        this.bind('isEnabled').to(command);

        this._setupResizerCreator();
    }

    /**
     * Attaches the listeners responsible for creating a resizer for each image, except for images inside the HTML embed preview.
     *
     * @private
     */
    _setupResizerCreator() {
        const editor = this.editor;
        const editingView = editor.editing.view;

        editingView.addObserver(ImageLoadObserver);

        this.listenTo(editingView.document, 'imageLoaded', (evt, domEvent) => {
            // The resizer must be attached only to images loaded by the `ImageInsert`, `ImageUpload` or `LinkImage` plugins.
            if (!domEvent.target.matches(RESIZABLE_IMAGES_CSS_SELECTOR)) {
                //console.error('RESIZE=', domEvent.target, 'FAILED');

                return;
            }

            const domConverter = editor.editing.view.domConverter;
            const imageView = domConverter.domToView(domEvent.target);
            const widgetView = imageView.findAncestor({ classes: 'arg-ck-screenShot' });
            let resizer = this.editor.plugins.get(WidgetResize).getResizerByViewElement(widgetView);

            if (resizer) {
                // There are rare cases when the image will be triggered multiple times for the same widget, e.g. when
                // the image's source was changed after upload (https://github.com/ckeditor/ckeditor5/pull/8108#issuecomment-708302992).
                resizer.redraw();

                return;
            }

            const mapper = editor.editing.mapper;
            const imageModel = mapper.toModelElement(widgetView);

            resizer = editor.plugins
                .get(WidgetResize)
                .attachTo({
                    unit: editor.config.get('image.resizeUnit'),

                    modelElement: imageModel,
                    viewElement: widgetView,
                    editor,

                    getHandleHost(domWidgetElement) {
                        const host = domWidgetElement; //.querySelector('img');

                        return host;
                    },
                    getResizeHost() {
                        // Return the model image element parent to avoid setting an inline element (<a>/<span>) as a resize host.
                        const resizeHost = domConverter.viewToDom(mapper.toViewElement(imageModel.parent));

                        return resizeHost;
                    },
                    // TODO consider other positions.
                    isCentered() {
                        const imageStyle = imageModel.getAttribute('imageStyle');

                        return !imageStyle || imageStyle == 'block' || imageStyle == 'alignCenter';
                    },

                    onCommit(newValue) {
                        // Get rid of the CSS class in case the command execution that follows is unsuccessful
                        // (e.g. Track Changes can override it and the new dimensions will not apply). Otherwise,
                        // the presence of the class and the absence of the width style will cause it to take 100%
                        // of the horizontal space.
                        editingView.change(writer => {
                            writer.removeClass(RESIZED_IMAGE_CLASS, widgetView);
                        });

                        editor.execute('resizeScreenshot', { width: newValue });
                    },
                });
            resizer.on('updateSize', () => {
                if (!widgetView.hasClass(RESIZED_IMAGE_CLASS)) {
                    editingView.change(writer => {
                        writer.addClass(RESIZED_IMAGE_CLASS, widgetView);
                    });
                }
            });

            resizer.bind('isEnabled').to(this);
        });
    }
}
