export function rgbIndexValueValidator(rgbIndexValue: number) {
    if (rgbIndexValue < 0) {
        return 0;
    }

    if (rgbIndexValue > 255) {
        return 255;
    }

    return rgbIndexValue;
}
