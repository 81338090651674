export enum ItemActions {
    Open = 'open',

    Rename = 'rename',
    Edit = 'edit',

    Remove = 'remove',
    Delete = 'delete',

    Add = 'add'
}
