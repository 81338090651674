import React, { useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import {
    ArgCombo,
    ArgFormLabel,
    ArgIcon,
    ArgInputText,
    ClassValue,
    useArgNotifications,
    useClassNames,
} from '../../../components/basic';
import { ConfirmModal } from '../../../components/common/modal2/confirm-modal/confirm-modal';
import { ValidationDetail, ValidationSummary } from '../../../components/common/validation-summary';
import { ContextualVariable, ContextualVariableType } from '../../../exploration/model/contextual-variable';
import { VariableValueControl } from './variable-value-control';
import { ExplorationConnector } from '../../../exploration/utils/connector/exploration-connector';

import './edit-variable-modal.less';

export type EditVariableModalProps = {
    className?: ClassValue;
    visible?: boolean;
    closeModal: () => void;
    variable: ContextualVariable;
    setContextualVariables: React.Dispatch<React.SetStateAction<ContextualVariable[]>>;
};

enum ContextualVariableMode {
    Auto,
    Manual,
}

export enum ContextualVariableAction {
    Create,
    Edit,
    Delete,
}

type ValueTypeDetails = {
    label: MessageDescriptor;
    icon: string;
};

export const messages = defineMessages({
    editVar: {
        id: 'settings.contextual-variables.edit-variable-modal.edit-var',
        defaultMessage: 'Edit variable',
    },
    varName: {
        id: 'settings.contextual-variables.edit-variable-modal.var-name',
        defaultMessage: 'Variable name',
    },
    varId: {
        id: 'settings.contextual-variables.edit-variable-modal.var-id',
        defaultMessage: 'Variable ID',
    },
    valueType: {
        id: 'settings.contextual-variables.edit-variable-modal.value-type',
        defaultMessage: 'Value type',
    },
    valueMode: {
        id: 'settings.contextual-variables.edit-variable-modal.value-mode',
        defaultMessage: 'Value mode',
    },
    valueTypeBoolean: {
        id: 'settings.contextual-variables.edit-variable-modal.value-type.boolean',
        defaultMessage: 'Boolean',
    },
    valueTypeHour: {
        id: 'settings.contextual-variables.edit-variable-modal.value-type.hour',
        defaultMessage: 'Hour',
    },
    valueTypeText: {
        id: 'settings.contextual-variables.edit-variable-modal.value-type.text',
        defaultMessage: 'Text',
    },
    valueTypeInt: {
        id: 'settings.contextual-variables.edit-variable-modal.value-type.int',
        defaultMessage: 'Integer',
    },
    valueTypeDecimal: {
        id: 'settings.contextual-variables.edit-variable-modal.value-type.decimal',
        defaultMessage: 'Decimal',
    },
    valueTypeDate: {
        id: 'settings.contextual-variables.edit-variable-modal.value-type.Date',
        defaultMessage: 'Date',
    },
    varValue: {
        id: 'settings.contextual-variables.edit-variable-modal.var-value',
        defaultMessage: 'Variable value',
    },
    varDescription: {
        id: 'settings.contextual-variables.edit-variable-modal.var-description',
        defaultMessage: 'Description',
    },
    selectAGroup: {
        id: 'settings.contextual-variables.edit-variable-modal.select-a-group',
        defaultMessage: 'Select a group',
    },
    variableNameErrorMessage: {
        id: 'settings.contextual-variables.edit-variable-modal.var-name-error',
        defaultMessage: '{fieldName} : value does not match to the expected pattern',
    },
    variableValueMissingError: {
        id: 'settings.contextual-variables.edit-variable-modal.var-value-missing-error',
        defaultMessage: '{fieldName} : you must enter a value',
    },
    variableEditTypeForbidden: {
        id: 'settings.contextual-variables.edit-variable-modal.var-type-edit-fobidden',
        defaultMessage: 'Variable type can not be updated after creation',
    },
    saveContextualVariablesError: {
        id: 'settings.contextual-variables.edit-variable-modal.SaveContextualVariablesError',
        defaultMessage: 'Failed to save contextual variables',
    },
    required: {
        id: 'settings.contextual-variables.edit-variable-modal.required',
        defaultMessage: '(Required)',
    },
});

const FORBIDDEN_VARIABLE_NAME_PATTERN = '[^a-zA-Z0-9_-]';

const VALUE_TYPES: Record<ContextualVariableType, ValueTypeDetails> = {
    string: {
        label: messages.valueTypeText,
        icon: 'icon-text',
    },
    int: {
        label: messages.valueTypeInt,
        icon: 'icon-numeric',
    },
    decimal: {
        label: messages.valueTypeDecimal,
        icon: 'icon-decimal',
    },
    bool: {
        label: messages.valueTypeBoolean,
        icon: 'icon-boolean',
    },
    hour: {
        label: messages.valueTypeHour,
        icon: 'icon-time',
    },
};

export const EditVariableModal = ({
    className,
    closeModal,
    variable,
    setContextualVariables,
}: EditVariableModalProps) => {
    const classNames = useClassNames('edit-variable-modal');
    const intl = useIntl();
    const notifications = useArgNotifications();

    const variableRef = useRef<ContextualVariable>(variable);
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(
        !variableRef.current.displayName
    );
    const [groupAffected, setGroupAffected] = useState<string>(variableRef.current.path || '');
    const [validationDetails, setValidationDetails] = useState<ValidationDetail[]>([]);
    const [renderId, setRenderId] = useState(0);

    useEffect(() => {
        // Update mode value : auto is not yet implemented
        variableRef.current.auto = undefined;

        // Update path
        variableRef.current.path = groupAffected;
    }, [groupAffected]);

    const validateForm = () => {
        const errorDetails = new Array<ValidationDetail>();
        const { id: variableName, value: variableValue } = variableRef.current;

        if (variableName) {
            if (variableName.match(new RegExp(FORBIDDEN_VARIABLE_NAME_PATTERN))) {
                errorDetails.push({
                    fieldName: intl.formatMessage(messages.varName),
                    message: messages.variableNameErrorMessage,
                });
            }
        }
        if (
            variableValue === undefined ||
            (variableRef.current.type === ContextualVariableType.string &&
                !(variableValue as string))
        ) {
            errorDetails.push({
                fieldName: intl.formatMessage(messages.varValue),
                message: messages.variableValueMissingError,
            });
        }

        setValidationDetails(errorDetails);

        return errorDetails.length < 1;
    };

    const editVariable = async (variable: ContextualVariable) => {
        const isValid = validateForm();
        if (isValid) {
            try {
                await ExplorationConnector.getInstance().saveContextualVariable(variable, true);
                const variables = await ExplorationConnector.getInstance().getContextualVariables();
                setContextualVariables(variables);
            } catch (error) {
                notifications.snackError({ message: messages.saveContextualVariablesError }, error as Error);
            }
        }
    };

    return (
        <div className={classNames('&', className)}>
            <ConfirmModal
                size='medium'
                title={messages.editVar}
                messageValues={{ name: variableRef.current.displayName }}
                type='save'
                confirmDisabled={isConfirmButtonDisabled}
                onConfirm={() => editVariable(variableRef.current)}
                onClose={closeModal}
            >
                {validationDetails.length > 0 && (
                    <div className={classNames('&-validation-summary')}>
                        <ValidationSummary type='error' details={validationDetails} />
                    </div>
                )}
                <div className={classNames('&-form')}>
                    <ArgFormLabel
                        className={classNames('&-form-row')}
                        propertyName={messages.varName}
                        required={messages.required}
                    >
                        <ArgInputText
                            className={classNames('&-form-variable-name')}
                            size='large'
                            maxLength={128}
                            value={variableRef.current.displayName}
                            autoFocus={true}
                            onInputChange={(value) => {
                                const trimmedValue = value?.trim();
                                setIsConfirmButtonDisabled(!trimmedValue);
                                variableRef.current.displayName = trimmedValue;
                            }}
                        />
                    </ArgFormLabel>
                    <div className={classNames('&-form-row', 'two-columns')}>
                        <ArgFormLabel
                            propertyName={messages.varId}
                        >
                            <ArgInputText
                                className={classNames('&-form-variable-id')}
                                size='large'
                                maxLength={64}
                                type='ghost'
                                readOnly={true}
                                value={variableRef.current.id}
                                onInputChange={(value) => {
                                    variableRef.current.id = value;
                                }}
                            />
                        </ArgFormLabel>
                        <div />
                    </div>
                    <div className={classNames('&-form-row', 'two-columns')}>
                        <ArgFormLabel
                            propertyName={<FormattedMessage {...messages.valueType} />}
                            infoTooltipMessage={messages.variableEditTypeForbidden}
                        >
                            <ArgCombo<ContextualVariableType>
                                size='large'
                                className={classNames('&-combo-value-type')}
                                items={Object.keys(VALUE_TYPES) as ContextualVariableType[]}
                                disabled={true}
                                getItemKey={(type) => type}
                                getItemLabel={(type) => {
                                    return type ? VALUE_TYPES[type]?.label : undefined;
                                }}
                                left={VALUE_TYPES[variableRef.current.type]?.icon}
                                renderItem={(type) => {
                                    const value = VALUE_TYPES[type];

                                    return (
                                        <div
                                            className={classNames('&-combo-value-type-item')}
                                            key={type}
                                        >
                                            <ArgIcon name={value.icon} />
                                            <span>
                                                <FormattedMessage {...value.label} />
                                            </span>
                                        </div>
                                    );
                                }}
                                value={variableRef.current.type}
                            />
                        </ArgFormLabel>
                    </div>
                    <ArgFormLabel
                        className={classNames('&-form-row')}
                        propertyName={<FormattedMessage {...messages.varValue} />}
                    >
                        <VariableValueControl
                            variableRef={variableRef}
                            refreshRef={() => setRenderId((x) => x + 1)}
                        />
                    </ArgFormLabel>
                    <ArgFormLabel
                        className={classNames('&-form-row')}
                        propertyName={<FormattedMessage {...messages.varDescription} />}
                    >
                        <ArgInputText
                            className={classNames('&-form-variable-description')}
                            size='large'
                            maxLength={512}
                            value={variableRef.current.description}
                            onInputChange={(value) => {
                                variableRef.current.description = value;
                            }}
                        />
                    </ArgFormLabel>
                </div>
            </ConfirmModal>
        </div>
    );
};
