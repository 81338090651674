import { compact, includes, isString } from 'lodash';
import Debug from 'debug';

import { BaseConnector } from './base-connector';
import { Folder, FolderId } from '../../model/folder';
import { convertCasePieceTypeToBack, mapCollaborators, mapFolder, mapFolderCasePiece } from './mappers';
import { CasePieceType } from 'src/model/case-piece-type';
import { ConnectorRequestInit, ETaggedObject, isNotChangedEtagError } from '../connector';
import { CaseId, CasePermissions, FolderCasePiece, FolderCustomFields } from '../../model/folder-case-piece';
import { Collaborator, CollaboratorRole } from '../../model/collaborator';
import { FolderCustomFieldDefinition, FolderCustomFieldName } from '../../model/folder-custom-field-definition';
import { RtApi } from '../rt-states/rt-api';
import { BasicCasePiece, MoveCopyCasePieceResponse } from '../../model/basic-case-piece';
import { ProgressMonitor, SubProgressMonitor } from '../../components/basic';

const debug = Debug('argonode:utils:CasesConnector');

const INCLUDING_CHILDRENTYPES_NONE_VALUE = 'None';

export type FilterCasePieceCondition = (casePiece: BasicCasePiece) => boolean;

/**
 * Case filters, based on custom field values.
 * When multiples values are provided : OR operator
 * @example { "Color": ['red'], "Size": [20] }
 */
export type CaseFilter = Record<string, any[]>;

export interface UpdatedCaseResult {
    id: FolderId;
    name: string;
    path: string;
}

export class ArgonosFoldersConnector extends BaseConnector {
    #folderCustomFieldsDefinition?: Record<FolderCustomFieldName, FolderCustomFieldDefinition>;

    constructor(name: string, api: string | undefined, progressMonitorRtApi?: RtApi, folderCustomFieldsDefinition?: Record<FolderCustomFieldName, FolderCustomFieldDefinition>) {
        super(name, api, progressMonitorRtApi);
        this.#folderCustomFieldsDefinition = folderCustomFieldsDefinition;
    }

    async getFolderCasePiece(id: FolderId, listPermissions: boolean, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<FolderCasePiece> {
        const result = await this.request(`/cases/${encodeURIComponent(id)}`, {
            verifyJSONResponse: true,
            params: {
                includingChildrenTypes: INCLUDING_CHILDRENTYPES_NONE_VALUE,
                listPermissions,
            },
        }, progressMonitor);

        const ret = mapFolderCasePiece(result, this.#folderCustomFieldsDefinition);

        return ret;
    }

    async getFolder(
        caseId: FolderId,
        markVisit?: boolean,
        includingChildrenTypes?: CasePieceType[],
        listPermissions?: boolean,
        casePieceFilterCondition?: FilterCasePieceCondition,
        previousCase?: Folder,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Folder> {
        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        let result: Folder;
        try {
            const convertedIncludingChildrenTypesToBackFormat = includingChildrenTypes
                ? includingChildrenTypes.length
                    ? compact(includingChildrenTypes.map(convertCasePieceTypeToBack))
                    : INCLUDING_CHILDRENTYPES_NONE_VALUE
                : undefined;

            const options: ConnectorRequestInit = {
                verifyJSONResponse: true,
                params: {
                    includingChildrenTypes: convertedIncludingChildrenTypesToBackFormat,
                    listPermissions: listPermissions,
                },
                etag: (previousCase as ETaggedObject)?.etag,
            };
            const response = await this.request(`/cases/${encodeURIComponent(caseId)}`, options, sub1);

            result = this.internalMapFolder(response, casePieceFilterCondition);
        } catch (error) {
            if (isNotChangedEtagError(error)) {
                return previousCase!;
            }

            throw error;
        }

        //
        debug('getCase', 'Case received from the server: ', result);

        // Mark as read on server side
        if (markVisit && result.id === caseId) {
            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            this.visitFolder(caseId, sub2).catch((error) => {
                console.error(error);
            });
        }

        return result;
    }

    protected internalMapFolder(raw: any, filterCasePieceCondition?: FilterCasePieceCondition): Folder {
        const ret = mapFolder(raw, filterCasePieceCondition, this.#folderCustomFieldsDefinition);

        return ret;
    }

    async visitFolder(caseId: FolderId, progressMonitor: ProgressMonitor): Promise<void> {
        await this.request(`/cases/${encodeURIComponent(caseId)}/visit`, { method: 'POST' }, progressMonitor);
    }

    async getPrivateFolder(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Folder> {
        const privateCaseResponse = await this.request('/cases/private', {
            verifyJSONResponse: true,
        }, progressMonitor);

        const privateCase = this.internalMapFolder(privateCaseResponse);

        debug('getPrivateCase', 'Private case received from the server: ', privateCase);

        return privateCase;
    }

    async getPrivateCaseId(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<FolderId> {
        const privateCase = await this.getPrivateFolder(progressMonitor);

        return privateCase.id;
    }

    async createFolder(
        newCaseName: string | null,
        newCasePath?: string,
        description?: string,
        customFields?: FolderCustomFields,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Folder> {
        const url = '/cases';
        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                caseName: newCaseName,
                path: newCasePath,
                description: description,
                extendedData: customFields ? {
                    customFields,
                } : undefined,
            },
            verifyJSONResponse: true,
        };

        const createdCase = await this.request(url, options, progressMonitor);

        const ret = this.internalMapFolder(createdCase);

        return ret;
    }

    async deleteFolder(caseId: FolderId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        const url = `/cases/${encodeURIComponent(caseId)}`;
        const options = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    async updateFolder(
        caseId: FolderId,
        newCaseName: string,
        description?: string,
        customFields?: FolderCustomFields,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<FolderCasePiece> {
        const url = `/cases/${encodeURIComponent(caseId)}`;
        const options: ConnectorRequestInit = {
            method: 'PUT',
            json: {
                caseName: newCaseName,
                description: description,
                extendedData: customFields ? {
                    customFields,
                } : undefined,
            },
            verifyJSONResponse: true,
        };

        const updatedCase = await this.request(url, options, progressMonitor);

        const ret = mapFolderCasePiece(updatedCase, this.#folderCustomFieldsDefinition);

        return ret;
    }

    async renameFolder(
        caseId: FolderId,
        newCaseName: string,
        newCasePath?: string,
        description?: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<UpdatedCaseResult> {
        const url = `/cases/${encodeURIComponent(caseId)}`;
        const json = {
            caseName: newCaseName,
            path: newCasePath,
            description: description,
        };

        const updatedCase: UpdatedCaseResult = await this.request(
            url,
            {
                method: 'PUT',
                json,
                verifyJSONResponse: true,
            },
            progressMonitor
        );

        return updatedCase;
    }

    async getFolders(
        includingChildrenTypes: undefined,
        includePrivateCase: boolean,
        listPermissions: boolean,
        casePieceFilterCondition: FilterCasePieceCondition | undefined,
        progressMonitor: ProgressMonitor
    ): Promise<Folder[]>;

    async getFolders(
        includingChildrenTypes: CasePieceType[] | undefined,
        includePrivateCase: boolean,
        listPermissions: boolean,
        casePieceFilterCondition: FilterCasePieceCondition | undefined,
        progressMonitor: ProgressMonitor
    ): Promise<Folder[]>;

    async getFolders(
        includingChildrenTypes: CasePieceType[] | undefined,
        includePrivateCase: boolean,
        listPermissions: boolean,
        casePieceFilterCondition: FilterCasePieceCondition | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Folder[]> {
        const ret = this._getFolders(includingChildrenTypes, includePrivateCase, listPermissions, casePieceFilterCondition, undefined, progressMonitor);

        return ret;
    }

    async getFilteredFolders(
        includingChildrenTypes: CasePieceType[] | undefined,
        includePrivateCase: boolean,
        listPermissions: boolean,
        casePieceFilterCondition: FilterCasePieceCondition | undefined,
        filters?: CaseFilter,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const ret = this._getFolders(includingChildrenTypes, includePrivateCase, listPermissions, casePieceFilterCondition, filters, progressMonitor);

        return ret;
    }

    private async _getFolders(
        includingChildrenTypes: CasePieceType[] | undefined,
        includePrivateCase: boolean,
        listPermissions: boolean,
        casePieceFilterCondition: FilterCasePieceCondition | undefined,
        filters?: CaseFilter,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const _includingChildrenTypes = includingChildrenTypes ? compact(includingChildrenTypes.map(convertCasePieceTypeToBack)) : undefined;

        const results: [] = await this.request('/cases', {
            verifyJSONResponse: true,
            params: {
                includingChildrenTypes: _includingChildrenTypes,
                listPermissions: listPermissions,
                filters: filters && JSON.stringify(filters) /* Query parameter => must be serialized */,
            },
        }, progressMonitor);

        debug('getCases', 'Cases received from the server: ', results);

        let ret = results.map(result => this.internalMapFolder(result, casePieceFilterCondition));

        if (!includePrivateCase) {
            ret = ret.filter(caseInfo => !caseInfo.isPrivate);
        }

        return ret;
    }


    async getCaseCollaborators(
        caseId: FolderId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Collaborator[]> {
        const caseInfo = await this.getFolder(caseId, false, [], true, undefined, undefined, progressMonitor);

        const ret = mapCollaborators(caseInfo?.userPermissions);

        return ret;
    }

    async saveCaseCollaborators(
        caseId: FolderId,
        collaborators: Collaborator[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/cases/${encodeURIComponent(caseId)}/permissions`;

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                permissions: collaborators.map((collaborator) => {
                    const casePermissions: CasePermissions = {
                        hasOwnership: collaborator.role === CollaboratorRole.Owner,
                        allowWrite: includes([CollaboratorRole.Owner, CollaboratorRole.Writer], collaborator.role),
                        allowRead: includes([CollaboratorRole.Owner, CollaboratorRole.Writer, CollaboratorRole.Reader], collaborator.role),
                    };

                    return {
                        identityId: collaborator.identityId,
                        permissions: casePermissions,
                    };
                }),
            },
        };
        await this.request(url, options, progressMonitor);
    }


    async moveCasePiece(
        casePieces: BasicCasePiece[],
        targetCaseId: CaseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<MoveCopyCasePieceResponse> {
        const url = `/cases/${encodeURIComponent(targetCaseId)}/move`;

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                piecesId: casePieces.map((casePiece) => ({
                    entityId: casePiece.id,
                })),
            },
        };

        const ret = await this.request(url, options, progressMonitor);

        return {
            argonosPieceErrors: ret.errors,
            argonosPieceSuccesses: ret.successes,
        };
    }

    async copyCasePiece(
        casePieces: (BasicCasePiece | string)[],
        targetCaseId: CaseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<MoveCopyCasePieceResponse> {
        const url = `/cases/${encodeURIComponent(targetCaseId)}/copy`;

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                piecesId: casePieces.map((casePiece) => ({
                    entityId: isString(casePiece) ? casePiece : casePiece.id,
                })),
            },
        };

        const ret = await this.request(url, options, progressMonitor);

        return {
            argonosPieceErrors: ret.errors,
            argonosPieceSuccesses: ret.successes,
        };
    }

    async importFolder(
        blob: Blob,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<FolderCasePiece> {
        const result = await this.request(
            '/cases/import',
            {
                headers: {
                    'Content-Type': 'application/zip',
                },
                body: blob,
                method: 'POST',
                verifyJSONResponse: true,
            },
            progressMonitor
        );

        const folder: FolderCasePiece = mapFolderCasePiece(result, this.#folderCustomFieldsDefinition);

        return folder;
    }

    async exportFolder(
        folder: FolderCasePiece,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        await this.downloadRequest(
            `${folder.displayName}.zip`,
            `/cases/${folder.id}/export`,
            {
                method: 'GET',
            },
            progressMonitor
        );
    }
}
