import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty, mapValues, omitBy, set } from 'lodash';
import { v4 } from 'uuid';
import Debug from 'debug';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import {
    ArgButton,
    ArgTab,
    ArgTabKey,
    ArgTabs,
    ArgToolbarLayout,
    ArgToolbarLayoutProps,
    ClassValue,
    GLOBAL_PM,
    immutableSet,
    KeyBindingScopeDescriptor,
    KeyBindingsScope,
    ProgressMonitor,
    renderText,
    SelectionProvider,
    SubProgressMonitor,
    useArgModalContext,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
    useEffectAsync,
    useMemoAsync,
    useProgressMonitors,
    useToolContext,
    useToolItem,
} from 'src/components/basic';
import { computeInitialDocument, renderItemTag } from '../utils';
import { EditableForm } from 'src/components/common/forms/editable-form';
import { FormDocument, FormElement, FormElementId, FormTabs } from 'src/components/common/forms/model';
import { createRenderFactory } from '../ontology-form-render';
import { FormItemsPanel } from 'src/settings/universes/form/views/items-panel/form-items-panel';
import { computeOntologyUrl } from 'src/settings/utils/compute-url';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { NamingModal } from 'src/components/common/modal2/naming-modal/naming-modal';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';
import { computeDisplayName } from 'src/utils/case-utils';
import {
    FormDisplayTemplate,
    FormDisplayTemplateKey,
    FormDisplayTemplates,
} from 'src/exploration/model/form-display-template';
import { FormPropertiesPanel } from 'src/settings/universes/form/views/form-properties-panel';
import { FormActionsEngine } from '../actions/form-actions-engine';
import { GLOBAL_SCOPE } from '../../../../components/common/keybindings/global';
import { KEY_BINDINGS } from '../../../../preparation/keybindings/global';
import { FormPagesPanel } from 'src/settings/universes/form/views/pages-panel/form-pages-panel';
import ontologiesConnector from '../../../connectors/ontologies-connector';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { FormPolicy, FormRuleEffectTemplate } from '../../../models/form-policy';
import { useJobQuitControl } from 'src/contexts/job-quit-control-context';
import { useFormRepository } from 'src/settings/universes/form/use-form-repository';
import { OntologyFormContext } from '../types';
import { DraggingContextProvider } from '../components/dragging-context';

import './form-customisation.less';

const messages = defineMessages({
    loadingOntologyError: {
        id: 'settings.form-customisation.LoadingOntologyError',
        defaultMessage: 'Failed to load the ontology',
    },
    loadingUniverseError: {
        id: 'settings.form-customisation.LoadingUniverseError',
        defaultMessage: 'Failed to load the universe',
    },
    publish: {
        id: 'settings.form-customisation.Publish',
        defaultMessage: 'Publish',
    },
    fetchDataError: {
        id: 'settings.form-customisation.FetchDataError',
        defaultMessage: 'An error occurred while fetching the data',
    },
    defaultFormTemplate: {
        id: 'settings.form-customisation.DefaultFormTemplate',
        defaultMessage: 'Default form',
    },
    addForm: {
        id: 'settings.form-customisation.AddForm',
        defaultMessage: 'Add form',
    },
    nameModalTitle: {
        id: 'settings.form-customisation.NameModalTitle',
        defaultMessage: 'New form',
    },
    nameModalLabel: {
        id: 'settings.form-customisation.NameModalLabel',
        defaultMessage: 'Name',
    },
    renameModalTitle: {
        id: 'settings.form-customisation.RenameModalTitle',
        defaultMessage: 'Rename form',
    },
    renameModalLabel: {
        id: 'settings.form-customisation.RenameModalLabel',
        defaultMessage: 'Name',
    },
    deleteConfirmTitle: {
        id: 'settings.form-customisation.DeleteConfirmTitle',
        defaultMessage: 'Delete form',
    },
    deleteConfirmMessage: {
        id: 'settings.form-customisation.DeleteConfirmMessage',
        defaultMessage: 'Are you sure you want to delete the form "{displayName}"?',
    },
    deleteConfirmDescription: {
        id: 'settings.form-customisation.SeleteConfirmDescription',
        defaultMessage: 'It will automatically be replaced by the default form in the rules that mention it',
    },
    deleteConfirmText: {
        id: 'settings.form-customisation.DeleteConfirmText',
        defaultMessage: 'Delete',
    },
    renameAction: {
        id: 'settings.form-customisation.RenameAction',
        defaultMessage: 'Rename',
    },
    deleteAction: {
        id: 'settings.form-customisation.DeleteAction',
        defaultMessage: 'Delete',
    },
    undoTooltip: {
        id: 'settings.form-customisation.toolbar.Undo.Tooltip',
        defaultMessage: 'Nothing to undo',
    },
    redoTooltip: {
        id: 'settings.form-customisation.toolbar.Redo.Tooltip',
        defaultMessage: 'Nothing to redo',
    },
    formEditor: {
        id: 'settings.form-customisation.FormEditor',
        defaultMessage: 'Properties editor',
    },
    fetchingFormPoliciesError: {
        id: 'settings.form-customisation.FetchingFormPoliciesError',
        defaultMessage: 'An error occurred while fetching form policies',
    },
    editPolicyError: {
        id: 'settings.form-customisation.EditPolicyError',
        defaultMessage: 'An error occurred while editing form policies',
    },
    cannotDeleteTooltip: {
        id: 'settings.form-customisation.DeleteErrorDescription',
        defaultMessage: 'This form is used in a form policy. Please remove it from the form policy before deleting it.',
    },
    formCustomisation: {
        id: 'settings.form-customisation.FormCustomisation',
        defaultMessage: 'Form customisation',
    },
    formProperties: {
        id: 'settings.form-customisation.FormItems',
        defaultMessage: 'Properties',
    },
    formPages: {
        id: 'settings.form-customisation.FormPages',
        defaultMessage: 'Pages',
    },
    emptyMessage: {
        id: 'settings.form-customisation.emptyMessage',
        defaultMessage: 'The object must have at least one property to be able to display the form',
    },
    publishMessage: {
        id: 'settings.form-customisation.publishMessage',
        defaultMessage: 'Publish the ontology to activate the form',
    },
    goToPublish: {
        id: 'settings.form-customisation.goToPublish',
        defaultMessage: 'Access publication',
    },
});

export const SCOPE: KeyBindingScopeDescriptor = {
    id: 'settings.forms.Editor',
    name: messages.formEditor,
    extends: GLOBAL_SCOPE,
};

const CLASSNAME = 'settings-form-customisation';

const debug = Debug('settings:form-customisation');

export const DEFAULT_FORM_TEMPLATE_KEY = 'default-form-template';
const LIBRARY_PANEL_MIN_WIDTH = 400;
const FORCE_LOADING = false;
const FORM_NAMING_MODAL_ID = 'form-naming-modal';
const FORM_DELETE_MODAL_ID = 'form-delete-modal';

const SIZING: Pick<ArgToolbarLayoutProps, 'rightPanelMinWidth' | 'leftPanelMinWidth' | 'rightPanelDefaultWidth' | 'leftPanelDefaultWidth'> = {
    rightPanelMinWidth: LIBRARY_PANEL_MIN_WIDTH,
    leftPanelMinWidth: LIBRARY_PANEL_MIN_WIDTH,
    rightPanelDefaultWidth: LIBRARY_PANEL_MIN_WIDTH,
    leftPanelDefaultWidth: LIBRARY_PANEL_MIN_WIDTH,
};

const DEFAULT_TEMPLATE_EFFECT = {
    key: DEFAULT_FORM_TEMPLATE_KEY,
};

type LeftPanelKey = 'formItems' | 'formPages';
type RightPanelKey = 'formProperties';

export interface FormCustomisationProps {
    className?: ClassValue;
}

function _FormCustomisation(props: FormCustomisationProps) {
    const {
        className,
    } = props;

    const classNames = useClassNames(CLASSNAME);
    const notifications = useArgNotifications();

    const { ontologyId, objectName } = useParams<{ ontologyId: string; objectName: string }>();
    const toolbarContext = useToolContext('settings.form-customisation.toolbar');
    const navigate = useNavigate();
    const modalContext = useArgModalContext();

    const [activeTab, setActiveTab] = useState<string | undefined>(DEFAULT_FORM_TEMPLATE_KEY);
    const [formsStateId, setFormsStateId] = useState<number>(0);
    const [selectedLeftPanel, setSelectedLeftPanel] = useState<LeftPanelKey | undefined>('formItems');
    const [selectedRightPanel, setSelectedRightPanel] = useState<RightPanelKey | undefined>('formProperties');
    const [forms, setForms] = useState<FormDisplayTemplates>();
    const [activeTabKeys, setActiveTabKeys] = useState<Record<FormElementId, ArgTabKey>>({});

    const intl = useIntl();

    const [selectionProvider] = useState<SelectionProvider<FormElement>>(() => {
        return new SelectionProvider<FormElement>('form-selection', (formElement: FormElement) => {
            return formElement.id;
        });
    });

    const onFormChange = useCallback(() => {
        setFormsStateId((id) => id + 1);
    }, []);

    const [ontology, , ontologyError] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        if (!ontologyId) {
            return;
        }

        try {
            const ontology = await ontologiesConnector.getFullOntology(ontologyId, progressMonitor);

            return ontology;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.loadingOntologyError }, error as Error);
            throw error;
        }
    }, [ontologyId]);

    const ontologyVertexType = useMemo(() => {
        if (!ontology || !objectName) {
            return undefined;
        }

        return ontology.objectTypes.find((obj) => obj.name === objectName);
    }, [ontology, objectName]);

    const [formPolicies, , formPolicyError] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const universeId = ontology?.universeIds[0];
            if (!universeId) {
                return;
            }

            const ret = await explorationSettingsConnector.getFormPolicies(universeId, progressMonitor);

            return ret;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.fetchingFormPoliciesError }, error as Error);
            throw error;
        }
    }, [ontology]);

    const [handleFormPolicyUpdate] = useCallbackAsync(async (progressMonitor: ProgressMonitor, updatedFormPolicies: FormPolicy[]) => {
        const promises = updatedFormPolicies.map(async (formPolicy) => {
            const sub = new SubProgressMonitor(progressMonitor, 1);

            const ret = explorationSettingsConnector.editFormPolicy(formPolicy.id, formPolicy, sub);

            return ret;
        });

        try {
            await Promise.all(promises);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.editPolicyError }, error as Error);
            throw error;
        }
    }, [notifications]);

    const isloading = (!ontology && !ontologyError) && (!formPolicies && !formPolicyError);

    useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        if (!ontologyId || !objectName) {
            return;
        }

        try {
            const forms = await ontologiesConnector.getForms(ontologyId, 'object', objectName, progressMonitor);
            setForms(forms);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }
            notifications.snackError({ message: messages.loadingOntologyError }, error as Error);
        }
    }, [objectName, ontologyId, formsStateId]);


    const [updateForm] = useCallbackAsync(async (progressMonitor: ProgressMonitor, newForm: FormDisplayTemplate, isDefault?: boolean) => {
        if (!ontologyId || !objectName) {
            return;
        }

        try {
            setForms((prev) => {
                if (!prev) {
                    return {
                        displayTemplates: {
                            [newForm.key]: newForm,
                        },
                        defaultTemplateKey: newForm.key,
                    };
                }

                const newForms = {
                    ...prev,
                    displayTemplates: {
                        ...prev.displayTemplates,
                        [newForm.key]: newForm,
                    },
                };

                return newForms;
            });

            const isFormDefault = isDefault ?? forms?.defaultTemplateKey === newForm.key;

            await ontologiesConnector.putForm(ontologyId, 'object', objectName, newForm.key, newForm, isFormDefault, progressMonitor);
            onFormChange();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.loadingOntologyError }, error as Error);
            throw error;
        }
    }, [forms?.defaultTemplateKey, objectName, onFormChange, ontologyId]);

    const [deleteForm] = useCallbackAsync(async (progressMonitor: ProgressMonitor, formKey: FormDisplayTemplateKey) => {
        if (!ontologyId || !objectName) {
            return;
        }

        try {
            setForms((prev) => {
                if (!prev) {
                    return prev;
                }

                const newForms = {
                    ...prev,
                    displayTemplates: omitBy(prev.displayTemplates, (form) => form.key === formKey),
                };

                return newForms;
            });
            await ontologiesConnector.deleteForm(ontologyId, 'object', objectName, formKey, progressMonitor);
            if (formPolicies) {
                const updatedFormPolicies = updateFormPolicyTarget(formPolicies, formKey);
                handleFormPolicyUpdate(updatedFormPolicies);
            }

            onFormChange();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.loadingOntologyError }, error as Error);
            throw error;
        }
    }, [objectName, onFormChange, ontologyId, formPolicies, handleFormPolicyUpdate, notifications]);

    useEffect(() => {
        if (!forms || forms.displayTemplates[DEFAULT_FORM_TEMPLATE_KEY] || !ontologyVertexType) {
            return;
        }

        const defaultForm: FormDisplayTemplate = {
            key: DEFAULT_FORM_TEMPLATE_KEY,
            displayName: 'Default',
            formDocument: computeInitialDocument(ontologyVertexType),
        };

        updateForm(defaultForm, true).catch((error) => {
            console.error(error);
        });
    }, [forms, ontologyVertexType, onFormChange, ontologyId, updateForm]);

    const computeFormName = useCallback((name: string, formKey: FormDisplayTemplateKey) => {
        const existingNames = forms ? Object.values(forms.displayTemplates).filter((form) => form.key !== formKey).map((form) => form.displayName) : [];

        return computeDisplayName(name, existingNames);
    }, [forms]);

    const handleAddFormTemplate = useCallback(() => {
        if (!forms || !ontologyVertexType) {
            return;
        }

        modalContext.open(FORM_NAMING_MODAL_ID,
            <NamingModal
                title={messages.nameModalTitle}
                label={messages.nameModalLabel}
                onClose={() => modalContext.close(FORM_NAMING_MODAL_ID)}
                onSubmit={async (name: string) => {
                    const newKey = `new-form-${v4()}`;
                    const displayName = computeFormName(name, newKey);
                    const newForm: FormDisplayTemplate = {
                        key: newKey,
                        displayName,
                        formDocument: computeInitialDocument(ontologyVertexType),
                    };
                    updateForm(newForm).catch((error) => {
                        console.error(error);
                    });

                    setActiveTab(newKey);
                }}
            />
        );
    }, [computeFormName, forms, modalContext, ontologyVertexType, updateForm]);

    const handleRenameFormTemplate = useCallback((formKey: FormDisplayTemplateKey) => {
        if (!forms) {
            return;
        }

        modalContext.open(FORM_NAMING_MODAL_ID,
            <NamingModal
                title={messages.renameModalTitle}
                label={messages.renameModalLabel}
                initialValue={forms.displayTemplates[formKey]?.displayName}
                onClose={() => modalContext.close(FORM_NAMING_MODAL_ID)}
                onSubmit={async (name: string) => {
                    const displayName = computeFormName(name, formKey);
                    const newForms: FormDisplayTemplates = {
                        displayTemplates: mapValues(forms.displayTemplates, (form) => {
                            if (form.key === formKey) {
                                return {
                                    ...form,
                                    displayName,
                                };
                            }

                            return form;
                        }),
                        defaultTemplateKey: forms.defaultTemplateKey,
                    };

                    const newForm: FormDisplayTemplate = {
                        ...newForms.displayTemplates[formKey],
                        displayName,
                    };

                    updateForm(newForm).catch((error) => {
                        console.error(error);
                    });
                }}
            />
        );
    }, [computeFormName, forms, modalContext, updateForm]);

    const handleDeleteFormTemplate = useCallback((formKey: FormDisplayTemplateKey) => {
        if (!forms) {
            return;
        }

        modalContext.open(FORM_DELETE_MODAL_ID,
            <ConfirmModal
                type='delete'
                title={messages.deleteConfirmTitle}
                alertMessage={messages.deleteConfirmMessage}
                alertDescription={messages.deleteConfirmDescription}
                confirmText={messages.deleteConfirmText}
                messageValues={{ displayName: forms.displayTemplates[formKey].displayName }}
                onClose={() => modalContext.close(FORM_DELETE_MODAL_ID)}
                onConfirm={async () => {
                    setActiveTab((activeTab) => (activeTab === formKey ? DEFAULT_FORM_TEMPLATE_KEY : activeTab));

                    deleteForm(formKey).catch((error) => {
                        console.error(error);
                    });
                }}
            />
        );
    }, [forms, modalContext, deleteForm]);

    const tabs = useMemo<ArgTab[]>(() => {
        if (!forms) {
            return [];
        }

        const defaultTemplateKey = forms.defaultTemplateKey || DEFAULT_FORM_TEMPLATE_KEY;

        return [
            {
                key: defaultTemplateKey,
                title: messages.defaultFormTemplate,
                closable: false,
            },
            ...Object.entries(forms.displayTemplates)
                .filter(([key]) => key !== defaultTemplateKey)
                .map(([key, form]) => {
                    return {
                        key,
                        title: form.displayName,
                        closable: false,
                        menu: [
                            {
                                key: 'rename',
                                label: messages.renameAction,
                                onClick: () => handleRenameFormTemplate(key),
                            },
                            {
                                key: 'delete',
                                label: messages.deleteAction,
                                onClick: () => handleDeleteFormTemplate(key),
                            },
                        ],
                    };
                }),
        ];
    }, [forms, handleDeleteFormTemplate, handleRenameFormTemplate]);

    const handleTabChange = useCallback((tabKey: string | undefined) => {
        setActiveTab(tabKey);
    }, []);

    const activeTabKey = activeTab || DEFAULT_FORM_TEMPLATE_KEY;

    const handleFormDocumentChange = useCallback((activeTabKey: string, formDocument: FormDocument) => {
        if (!forms) {
            return;
        }
        const newForm = immutableSet(forms.displayTemplates[activeTabKey], 'formDocument', formDocument);

        debug('SetForm=', newForm);

        updateForm(newForm).catch((error) => {
            console.error(error);
        });
    }, [forms, updateForm]);

    const handleFormActiveTabKeyChange = useCallback((formTabs: FormTabs, activeTabKey: ArgTabKey) => {
        setActiveTabKeys((prev) => {
            const ret = {
                ...prev,
                [formTabs.id]: activeTabKey,
            };

            return ret;
        });
    }, []);

    const jobQuitControl = useJobQuitControl();

    const [progressMonitors, createProgressMonitor] = useProgressMonitors();
    const formActionsEnginesRef = useRef<Record<string, FormActionsEngine>>();

    const formActionsEngine = useMemo(() => {
        const _activeTabKey = activeTabKey;

        const displayTemplate = forms?.displayTemplates[activeTabKey];
        if (!displayTemplate) {
            return;
        }

        if (!formActionsEnginesRef.current) {
            formActionsEnginesRef.current = {};
        }

        let formActionEngine = formActionsEnginesRef.current[activeTabKey];
        if (formActionEngine) {
            return formActionEngine;
        }

        formActionEngine = new FormActionsEngine(createProgressMonitor, displayTemplate.formDocument, jobQuitControl);
        formActionsEnginesRef.current[_activeTabKey] = formActionEngine;

        formActionEngine.repository.addListener('*', () => {
            handleFormDocumentChange(_activeTabKey, formActionEngine.repository.formDocument);
        });

        return formActionEngine;
    }, [activeTabKey, createProgressMonitor, forms?.displayTemplates, handleFormDocumentChange, jobQuitControl]);

    const formRepository = useFormRepository(formActionsEngine?.repository);

    const formRenderFactory = useMemo(() => {
        if (!ontologyVertexType || !formActionsEngine) {
            return undefined;
        }

        const context: OntologyFormContext = {
            vertexProperties: ontologyVertexType.properties,
            selectionProvider,
            formActionsEngine,
        };

        const ret = createRenderFactory(context);

        return ret;
    }, [ontologyVertexType, formActionsEngine, selectionProvider]);

    const handleGoBack = useCallback(() => {
        if (!ontologyId) {
            return;
        }

        navigate(computeOntologyUrl(ontologyId));
    }, [navigate, ontologyId]);

    const handleSelectLeftPanel = useCallback((panelId: LeftPanelKey) => {
        setSelectedLeftPanel((prev) => {
            if (prev === panelId) {
                return undefined;
            }

            return panelId;
        });
    }, []);


    const handleSelectRightPanel = useCallback((panelId: RightPanelKey) => {
        setSelectedRightPanel((prev) => {
            if (prev === panelId) {
                return undefined;
            }

            return panelId;
        });
    }, []);

    const handleSelectFormItemsPanel = useCallback(() => {
        handleSelectLeftPanel('formItems');
    }, [handleSelectLeftPanel]);

    const handleSelectFormPagesPanel = useCallback(() => {
        handleSelectLeftPanel('formPages');
    }, [handleSelectLeftPanel]);

    const handleSelectFormPropertiesPanel = useCallback(() => {
        handleSelectRightPanel('formProperties');
    }, [handleSelectRightPanel]);

    useToolItem(toolbarContext, { path: 'center/title', type: 'button' },
        {
            customRender: () => {
                const title = tabs?.find((tab) => tab.key === (activeTab || DEFAULT_FORM_TEMPLATE_KEY))?.title;

                if (!title) {
                    return null;
                }

                return (
                    <span className={classNames('&-title')}>
                        {renderText(title)}
                    </span>
                );
            },
        }
    );

    useToolItem(toolbarContext, {
        path: 'left/formItems',
        icon: 'icon-brush',
        onClick: handleSelectFormItemsPanel,
        type: 'panel',
    }, {
        selected: selectedLeftPanel === 'formItems',
        panelRender: () => {
            if (!ontologyVertexType || !formActionsEngine) {
                return null;
            }

            return (
                <FormItemsPanel
                    formActionsEngine={formActionsEngine}
                    ontologyVertexType={ontologyVertexType}
                    selectionProvider={selectionProvider}
                    activeTabKeys={activeTabKeys}
                />
            );
        },
        tooltip: messages.formCustomisation,
    });

    useToolItem(toolbarContext, {
        path: 'left/formPages',
        icon: 'icon-file',
        onClick: handleSelectFormPagesPanel,
        type: 'panel',
        tooltip: messages.formPages,
    }, {
        selected: selectedLeftPanel === 'formPages',
        panelRender: () => {
            if (!formActionsEngine) {
                return null;
            }

            return (
                <FormPagesPanel
                    formActionsEngine={formActionsEngine}
                    selectionProvider={selectionProvider}
                />
            );
        },
    });

    useToolItem(toolbarContext, {
        path: 'right/formItems',
        icon: 'icon-info',
        onClick: handleSelectFormPropertiesPanel,
        type: 'panel',
        tooltip: messages.formProperties,
    }, {
        selected: selectedRightPanel === 'formProperties',
        panelRender: () => {
            if (!formActionsEngine || !ontologyVertexType) {
                return null;
            }

            return (
                <FormPropertiesPanel
                    formActionsEngine={formActionsEngine}
                    selectionProvider={selectionProvider}
                    ontologyVertexType={ontologyVertexType}
                />
            );
        },
    });

    const [handleUndoClick] = useCallbackAsync(async () => {
        await formActionsEngine!.undo();
    }, [formActionsEngine], undefined, undefined, GLOBAL_PM);

    const [handleRedoClick] = useCallbackAsync(async () => {
        await formActionsEngine!.redo();
    }, [formActionsEngine], undefined, undefined, GLOBAL_PM);

    useToolItem(toolbarContext, {
        path: 'left/history/undo',
        type: 'button',
        icon: 'icon-undo',
        tooltip: messages.undoTooltip,
        keyBinding: KEY_BINDINGS.undo,
    }, {
        disabled: !formActionsEngine?.historyManager.canGoBackwards() || !isEmpty(progressMonitors),
        tooltip: formActionsEngine?.historyManager.getBefore()?.renderEntry?.(true, formActionsEngine, 'right') || messages.undoTooltip,
        onClick: handleUndoClick,
    });

    useToolItem(toolbarContext, {
        path: 'left/history/redo',
        type: 'button',
        icon: 'icon-redo',
        tooltip: messages.redoTooltip,
        keyBinding: KEY_BINDINGS.redo,
    }, {
        disabled: !formActionsEngine?.historyManager.canGoForwards() || !isEmpty(progressMonitors),
        tooltip: formActionsEngine?.historyManager.getAfter()?.renderEntry?.(false, formActionsEngine, 'right') || messages.redoTooltip,
        onClick: handleRedoClick,
    });


    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-header')}>
                <div className={classNames('&-ontology-name')}>
                    <ArgButton
                        className={classNames('&-icon-back')}
                        onClick={handleGoBack}
                        icon='icon-cheveron-left'
                        type='ghost'
                    />
                    {ontologyVertexType && renderItemTag(ontologyVertexType, 'Vertex')}
                </div>
            </div>
            <ArgTabs
                tabs={tabs}
                activeTabKey={activeTab}
                defaultActiveTabKey={DEFAULT_FORM_TEMPLATE_KEY}
                onChange={handleTabChange}
                className={classNames('&-tabs')}
                addTab={
                    !!forms && <div className={classNames('&-tab-chip')}>
                        <ArgButton
                            className={classNames('&-tab-chip-icon')}
                            type='ghost'
                            icon='icon-plus'
                            tooltip={messages.addForm}
                            onClick={handleAddFormTemplate}
                        />
                    </div>
                }
            />
            <ArgToolbarLayout
                className={classNames('&-toolbar')}
                toolbarContext={toolbarContext}
                environmentContext={undefined}
                {...SIZING}
            >
                <div className={classNames('&-body')}>
                    {(ontologyVertexType && formRepository && formRenderFactory && !FORCE_LOADING) ? (
                        <div>
                            <div className={classNames('&-publish-message')}>
                                <span className={classNames('&-publish-message-label')}>
                                    <FormattedMessage {...messages.publishMessage} />
                                </span>
                                <ArgButton
                                    type='ghost'
                                    className={classNames('&-publish-button')}
                                    onClick={handleGoBack}
                                    label={messages.goToPublish}
                                />
                            </div>
                            <EditableForm
                                className={classNames('&-form')}
                                formDocument={formRepository}
                                formRenderFactory={formRenderFactory}
                                onActiveTabKeyChange={handleFormActiveTabKeyChange}
                            />
                        </div>
                    ) : (
                        isloading ? (
                            <LoadingPane className={classNames('&-loading-pane')} />
                        ) : (
                            <EmptyPane
                                className={classNames('&-empty-pane')}
                                message={messages.emptyMessage}
                            />
                        )
                    )}
                </div>
            </ArgToolbarLayout>
        </div>
    );
}

export function FormCustomisation(props: FormCustomisationProps) {
    return (
        <DndProvider backend={HTML5Backend}>
            <DraggingContextProvider>
                <KeyBindingsScope scope={SCOPE}>
                    <_FormCustomisation {...props} />
                </KeyBindingsScope>
            </DraggingContextProvider>
        </DndProvider>
    );
}

function updateFormPolicyTarget(formPolicies: FormPolicy[], formKey: FormDisplayTemplateKey, defaultTemplateEffect?: FormRuleEffectTemplate): FormPolicy[] {
    const updatedFormPolicies: FormPolicy[] = [];

    formPolicies?.forEach((policy) => {
        const statement = policy.statement;
        if (!statement) {
            return;
        }

        const actions = statement.Actions;
        if (!actions) {
            return;
        }

        actions.forEach((action, indexAction) => {
            const effects = action.Effects;
            if (!effects) {
                return;
            }
            effects.forEach((effect, indexEffect) => {
                if (!effect.template || effect.template.key !== formKey) {
                    return;
                }

                const newPolicy = set(
                    structuredClone(policy),
                    `statement.Actions[${indexAction}].Effects[${indexEffect}].template`,
                    defaultTemplateEffect ?? DEFAULT_TEMPLATE_EFFECT
                );
                updatedFormPolicies.push(newPolicy);
            });
        });
    });

    return updatedFormPolicies;
}
