import { isEmpty } from 'lodash';
import { unzip, ZipInfo } from 'unzipit';

import { ArgonosError, ProgressMonitor } from '../components/basic';
import { ConfigurationImportError, ConfigurationImportResponseError } from '../model/configuration';

export const INVALID_ZIP_FORMAT = Symbol('INVALID_ZIP_FORMAT');
const PROGRESS_MONITOR_TASK = 'Unzip';
const EMPTY_ERROR = {};

export async function analyseConfigurationExport(blob: Blob, progressMonitor: ProgressMonitor): Promise<ConfigurationImportResponseError> {
    progressMonitor.beginTask(PROGRESS_MONITOR_TASK, 1);

    let zipInfo: ZipInfo;
    let exportErrors: ConfigurationImportError[] = [];

    try {
        // Unzip configurations
        zipInfo = await unzip(blob);
        const { entries } = zipInfo;

        for (const [key, entry] of Object.entries(entries)) {
            const blob = await entry.blob();
            // Unzip export modules
            const { entries } = await unzip(blob);
            for (const [key, entry] of Object.entries(entries)) {
                if (key === 'errors.json') {
                    const errors = await entry.json();

                    exportErrors = [...exportErrors, ...errors];
                }
            }
        }

        progressMonitor.worked(1);

        if (isEmpty(exportErrors)) {
            return EMPTY_ERROR;
        }

        return {
            errors: exportErrors,
        };
    } catch (error) {
        throw new ArgonosError('Invalid ZIP file', INVALID_ZIP_FORMAT, error as Error);
    }
}
