import React, { useCallback, useContext } from 'react';
import { defineMessages } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ArgButton, ArgInputSearch, ArgModalContainerContext, ClassValue, useClassNames } from 'src/components/basic';
import { CreateFormPolicyModal } from '../create-policy-modal/create-form-policy-modal';

import './table-toolbar.less';

const messages = defineMessages({
    new: {
        id: 'settings.form-policies-page.toolbar.new',
        defaultMessage: 'New',
    },
});

interface TableToolbarProps {
    className?: ClassValue;
    onCreatePolicy: () => void;
    setSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export function TableToolbar(props: TableToolbarProps) {
    const { onCreatePolicy, setSearch, className } = props;

    const { universeId } = useParams<{ ontologyId: string; universeId: string }>();
    const classNames = useClassNames('settings-form-policy-table-toolbar');

    const modalContainer = useContext(ArgModalContainerContext);

    const handleCreationModal = useCallback(() => {
        if (!universeId) {
            return;
        }
        modalContainer.open('create-form-policy-modal', <CreateFormPolicyModal
            closeModal={() => modalContainer.close('create-form-policy-modal')}
            onSuccess={onCreatePolicy}
            universeId={universeId}
        />);
    }, [modalContainer, onCreatePolicy, universeId]);

    return (
        <div className={classNames('&', className)}>
            <ArgInputSearch
                onChange={(newSearchText) => {
                    setSearch(newSearchText ?? undefined);
                }}
                className={classNames('&-search')}
            />
            <ArgButton
                size='medium'
                type='primary'
                icon='icon-plus'
                label={messages.new}
                onClick={handleCreationModal}
                className={classNames('&-plus')}
            />
        </div>
    );
}
