export enum ProceoPermission {
    ACT_TEMPLATE_NATIONAL_EDITION = 'proceo.act.template.national.edition',
    ACT_TEMPLATE_SERVICE_EDITION = 'proceo.act.template.service.edition',
    ARTICULATION_TEMPLATE_NATIONAL_EDITION = 'proceo.articulation.template.national.edition',
    ARTICULATION_TEMPLATE_SERVICE_EDITION = 'proceo.articulation.template.service.edition',
}

export interface ProceoPermissions {
    'proceo.create.folder': true,
    'proceo.act.access': true,
    'proceo.act.edition': true,
    'proceo.act.export': true,
    'proceo.act.template.access': true,
    'proceo.act.template.edition': true,
    'proceo.act.template.service.edition': true,
    'proceo.act.template.national.edition': true,
    'proceo.act.template.export': true,
    'proceo.act.type.access': true,
    'proceo.act.type.edition': true,
    'proceo.act.type.export': true,
    'proceo.articulation.template.service.edition': true,
    'proceo.articulation.template.national.edition': true,
    'proceo.file.collection.access': true,
    'proceo.file.collection.edition': true,
    'proceo.file.collection.export': true,
    'proceo.application.initialize': true,
    'proceo.person.access': true,
    'proceo.person.edition': true,
    'proceo.person.export': true,
    'proceo.offence.reference.access': true,
    'proceo.offence.reference.edition': true,
    'proceo.offence.reference.export': true,
    'proceo.offence.access': true,
    'proceo.offence.edition': true,
    'proceo.offence.export': true,
    'proceo.service.access': true,
    'proceo.service.edition': true,
    'proceo.service.export': true,
    'proceo.group.access': true,
    'proceo.group.membership.access': true,
    'proceo.group.membership.edition': true,
}

export const PROCEO_PERMISSIONS_KEYS: (keyof ProceoPermissions)[] = [
    'proceo.create.folder',
    'proceo.act.access',
    'proceo.act.edition',
    'proceo.act.export',
    'proceo.act.template.access',
    'proceo.act.template.edition',
    'proceo.act.template.service.edition',
    'proceo.act.template.national.edition',
    'proceo.act.template.export',
    'proceo.act.type.access',
    'proceo.act.type.edition',
    'proceo.act.type.export',
    'proceo.file.collection.access',
    'proceo.file.collection.edition',
    'proceo.file.collection.export',
    'proceo.application.initialize',
    'proceo.person.access',
    'proceo.person.edition',
    'proceo.person.export',
    'proceo.offence.reference.access',
    'proceo.offence.reference.edition',
    'proceo.offence.reference.export',
    'proceo.offence.access',
    'proceo.offence.edition',
    'proceo.offence.export',
    'proceo.service.access',
    'proceo.service.edition',
    'proceo.service.export',
    'proceo.group.access',
    'proceo.group.membership.access',
    'proceo.group.membership.edition',
];

export const PROCEO_PERMISSIONS_REQUIRED = PROCEO_PERMISSIONS_KEYS;
