import { useCallback } from 'react';

import { ArgTable2, ArgTable2Column, useClassNames } from 'src/components/basic';
import { TableContextualVariable } from '../views/contextual-variables';

export interface ContextualVariablesTableProps {
    columns: ArgTable2Column<TableContextualVariable>[];
    tableContextualVariables: TableContextualVariable[];
    search: string | undefined;
}

export const ContextualVariablesTable = ({
    columns,
    search,
    tableContextualVariables,
}: ContextualVariablesTableProps) => {
    const classNames = useClassNames('contextual-variables');

    const getContextVariableLabelForSearch = useCallback(
        (tableVariable: TableContextualVariable) => {
            // Search by display name
            let labelForSearch = tableVariable.displayName;
            // Search by description
            if (tableVariable.description) {
                labelForSearch += ` ${tableVariable.description}`;
            }
            // Includes search by children variable name when isGroup, in order to keep variable parent groups
            if (tableVariable.isGroup && tableVariable.groupInfos?.childrenVariableDisplayNames) {
                labelForSearch += tableVariable.groupInfos.childrenVariableDisplayNames.join(' ');
            }

            return labelForSearch;
        },
        []
    );

    return (
        <ArgTable2<TableContextualVariable>
            className={classNames('&-body-table')}
            rowKey={(item) => item.key}
            columns={columns}
            dataSource={tableContextualVariables}
            scrollableBodyClassName={classNames('&-body-table-scrollable')}
            search={search}
            getItemLabel={getContextVariableLabelForSearch}
        />
    );
};
