import Command from '@ckeditor/ckeditor5-core/src/command';
import Editor from '@ckeditor/ckeditor5-core/src/editor/editor';
import * as UUID from 'uuid';

export default class InsertCommand extends Command {
    #pluginName: string;
    #itemAttributes: string[];

    constructor(editor: Editor, itemType: string, attributes: string[]) {
        super(editor);

        this.#pluginName = itemType;
        this.#itemAttributes = attributes;
    }

    execute(options: any) {
        const model = this.editor.model;
        const document = model.document;
        const selection = document.selection;

        const range = options.range || selection.getFirstRange();

        const { detail } = options;

        this.editor.model.change(writer => {
            const model = this.editor.model;

            model.insertContent(writer.createElement(this.#pluginName, {
                id: detail.id,
                uuid: UUID.v4(),
                ...this.#itemAttributes.reduce((acc, attributeName) => {
                    const value = detail[attributeName];
                    if (value === undefined) {
                        return acc;
                    }

                    acc[attributeName] = value;

                    return acc;
                }, {} as Record<string, string | number | boolean>),
            }), range);
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition()!, this.#pluginName);

        this.isEnabled = allowedIn !== null;
    }
}
