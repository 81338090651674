import { Plugin } from '@ckeditor/ckeditor5-core';
import { ScreenshotEditing } from '../screenshot-editing';


export default class ScreenshotResize extends Plugin {
    /**
     * @inheritDoc
     */
    static get requires() {
        return [ScreenshotEditing];
    }

    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'argonos-resource-screenshot:resize';
    }

    /**
     * @inheritDoc
     */
    constructor(editor) {
        super(editor);
    }

    /**
     * @inheritDoc
     */
    init() {
        this._registerSchema();
        this._registerConverters();
    }

    /**
     * @private
     */
    _registerSchema() {
        this.editor.model.schema.extend('argonos-resource-screenshot', { allowAttributes: 'width' });
    }

    /**
     * Registers image resize converters.
     *
     * @private
     * @param {'imageBlock'|'imageInline'} imageType The type of the image.
     */
    _registerConverters() {
        const editor = this.editor;

        // Dedicated converter to propagate image's attribute to the img tag.
        editor.conversion.for('downcast').add(dispatcher =>
            dispatcher.on('attribute:width', (evt, data, conversionApi) => {
                if (!conversionApi.consumable.consume(data.item, evt.name)) {
                    return;
                }

                const viewWriter = conversionApi.writer;
                const figure = conversionApi.mapper.toViewElement(data.item);

                if (data.attributeNewValue !== null) {
                    viewWriter.setStyle('width', data.attributeNewValue, figure);
                    viewWriter.addClass('screenshot_resized', figure);
                } else {
                    viewWriter.removeStyle('width', figure);
                    viewWriter.removeClass('screenshot_resized', figure);
                }
            })
        );

        editor.conversion.for('upcast')
            .attributeToAttribute({
                view: {
                    name: 'div',
                    styles: {
                        width: /.+/,
                    },
                },
                model: {
                    key: 'width',
                    value: viewElement => viewElement.getStyle('width'),
                },
            });
    }
}
