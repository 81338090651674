import { useCallback, useRef } from 'react';
import { defineMessages } from 'react-intl';

import { ArgButton, useClassNames } from 'src/components/basic';
import { useScriptSandbox } from 'src/components/basic/arg-hooks/use-script-sandbox';
import { FormButton } from 'src/components/common/forms/model';

import './form-button-component.less';

const messages = defineMessages({
    buttonName: {
        id: 'common.forms.Button',
        defaultMessage: 'Button',
    },
});

export interface FormButtonComponentProps {
    formButton: FormButton;
    scriptContext?: string;
}

export function FormButtonComponent(props: FormButtonComponentProps) {
    const {
        formButton,
        scriptContext,
    } = props;

    const classNames = useClassNames('form-button-component');
    const containerRef = useRef<HTMLDivElement>(null);
    const { name, icon, buttonType, tooltip, code, size } = formButton;
    const invalid = !code;
    const runScript = useScriptSandbox({ containerRef });

    const onClick = useCallback(() => {
        if (!code) {
            return;
        }

        const context = scriptContext ? `${scriptContext}\n` : '';

        runScript(`${context}${code}`);
    }, [code, runScript, scriptContext]);

    const _label = (name || icon) ? name : messages.buttonName;

    return (
        <div ref={containerRef} className={classNames('&', { invalid })}>
            <ArgButton
                type={buttonType || 'primary'}
                icon={icon}
                label={_label}
                tooltip={tooltip}
                onClick={onClick}
                size={size}
            />
        </div>
    );
}
