import { orderBy } from 'lodash';

import {
    ArticulationStoredObject,
    ArticulationTemplate,
} from 'src/proceo/model/articulation';
import { Folder } from '../../../model/folder';
import { getCaseCustomFieldValue } from '../../../utils/case-utils';
import { mapDate } from '../../../utils/connectors/mappers';
import {
    Act,
    ActCreationPermission,
    ActDeletionPermission,
    ActLight,
    ActTemplate,
    ActType,
} from '../../model/act';
import { ProceoPerson, ProceoPersonRole } from '../../model/person';
import { Offence, OffenceLight, OffenceReference, OffenceStatus, OffenceToCreate } from '../../model/reference';
import { ArgUserInfo } from '../../../components/basic';
import { Service } from 'src/proceo/model/service';
import { ProceoGroup } from '../../model/group';
import { ProceoBaseFolderCustomFieldNames } from '../../constants/base-folder-custom-fields';
import {
    FolderMainOffence,
    FolderMainPerson, FolderMainPersons,
    FolderType,
    LegalFramework,
    LegalRegime,
    ProceoBaseFolder,
    ProceoFolder,
    ProceoFolderStatus,
} from '../../model/folder';
import { ProceoFolderCustomFieldNames, ProceoFolderCustomFields } from '../../constants/folder-custom-fields';
import {
    RawAct,
    RawActTemplate,
    RawActType,
    RawArticulationTemplate,
    RawFullAct,
    RawOffenceFull,
    RawOffenceLight,
    RawProceoFolderAuthorization,
    RawProceoPerson,
    RawService,
} from './raws';
import { ProceoGroupCustomFieldNames } from '../../constants/group-custom-fields';
import { ProceoFolderAuthorization } from 'src/proceo/model/authorization';
import { InterpreterUsage, InterpreterUsageToCreate } from '../../model/interpreter';


export const mapProceoBaseFolder = (folder: Folder) => {
    const ret: ProceoBaseFolder = {
        ...folder,
        folderType: getCaseCustomFieldValue(folder.customFields, ProceoBaseFolderCustomFieldNames._Type) || FolderType.Folder,
    };

    return ret;
};

export const mapProceoFolder = (folder: ProceoBaseFolder) => {
    const ret: ProceoFolder = {
        ...folder,
        folderNumber: getCaseCustomFieldValue(folder.customFields, ProceoFolderCustomFieldNames.FolderNumber),
        justiceNumber: getCaseCustomFieldValue(folder.customFields, ProceoFolderCustomFieldNames.JusticeNumber),
        lastActDate: getCaseCustomFieldValue(folder.customFields, ProceoFolderCustomFieldNames.LastAct),
        legalFramework: getCaseCustomFieldValue(folder.customFields, ProceoFolderCustomFieldNames.LegalFramework) || LegalFramework.PRELIMINARY_INVESTIGATION,
        confidentialityLevel: getCaseCustomFieldValue(folder.customFields, ProceoFolderCustomFieldNames.ConfidentialityLevel),
        legalRegime: getCaseCustomFieldValue(folder.customFields, ProceoFolderCustomFieldNames.LegalRegime) || LegalRegime.COMMON_RIGHT,
        responsible: mapProceoFolderResponsible(folder.customFields),
        mainPersons: mapProceoFolderMainPersons(folder.customFields),
        mainOffence: mapProceoFolderMainOffence(folder.customFields),
        status: getCaseCustomFieldValue(folder.customFields, ProceoFolderCustomFieldNames.Status) || ProceoFolderStatus.Unknown,
        picture: getCaseCustomFieldValue(folder.customFields, ProceoFolderCustomFieldNames.Picture),
        procedureArticulationData: mapProceoFolderArticulationData(folder.customFields),
        articulationTemplate: mapProceoFolderArticulationTemplate(folder.customFields),
    };

    return ret;
};

export function mapProceoFolderResponsible(customFields?: ProceoFolderCustomFields) {
    const rawResponsible: string | undefined = getCaseCustomFieldValue(customFields, ProceoFolderCustomFieldNames.Responsible);
    const responsible: ArgUserInfo | undefined = rawResponsible && JSON.parse(rawResponsible);

    return responsible;
}

export const mapProceoFolderMainPersons = (customFields?: ProceoFolderCustomFields) => {
    const rawMainPersons: string | undefined = getCaseCustomFieldValue(customFields, ProceoFolderCustomFieldNames.MainPersons);
    const mainPersons: Record<ProceoPersonRole, FolderMainPerson> | undefined = rawMainPersons && JSON.parse(rawMainPersons);
    const mapMainPersons: FolderMainPersons = mainPersons && new Map(Object.entries(mainPersons)) || new Map();

    return mapMainPersons;
};

const mapProceoFolderMainOffence = (customFields?: ProceoFolderCustomFields) => {
    const rawMainOffence: string | undefined = getCaseCustomFieldValue(customFields, ProceoFolderCustomFieldNames.MainOffence);
    const mainOffence: FolderMainOffence | undefined = rawMainOffence && JSON.parse(rawMainOffence);

    return mainOffence;
};

const mapProceoFolderArticulationData = (customFields?: ProceoFolderCustomFields) => {
    const rawArticulationData: string | undefined = getCaseCustomFieldValue(customFields, ProceoFolderCustomFieldNames.ProcedureArticulationData);
    const articulationData: ArticulationStoredObject | undefined = rawArticulationData && JSON.parse(rawArticulationData);

    return articulationData;
};

const mapProceoFolderArticulationTemplate = (customFields?: ProceoFolderCustomFields) => {
    const rawArticulationTemplate: ArticulationTemplate | undefined = getCaseCustomFieldValue(customFields, ProceoFolderCustomFieldNames.ArticulationTemplate);

    return rawArticulationTemplate;
};

export const mapPerson = (rawPerson: RawProceoPerson) => {
    const person: ProceoPerson = {
        id: rawPerson.id,
        type: rawPerson.type,
        role: rawPerson.role,
        createdBy: rawPerson.createdBy,
        createdDate: mapDate(rawPerson.createdDate),
        lastUpdatedBy: rawPerson.lastUpdatedBy,
        lastUpdatedDate: mapDate(rawPerson.lastUpdatedDate),
        ...rawPerson.content,
    };

    return person;
};

export const mapLightAct = (rawAct: RawAct) => {
    const ret: ActLight = {
        ...rawAct,
        createdDate: mapDate(rawAct.createdDate),
        lastUpdatedDate: mapDate(rawAct.lastUpdatedDate),
        endActDate: mapDate(rawAct.endActDate),
        startActDate: mapDate(rawAct.startActDate),
    };

    return ret;
};

export const mapFullAct = (rawFullAct: RawFullAct) => {
    const ret: Act = {
        ...mapLightAct(rawFullAct),
        persons: rawFullAct.persons?.map(mapPerson),
        offences: orderBy(rawFullAct.offences?.map(mapOffenceFull), (o) => rawFullAct?.offenceIds?.indexOf(o.id)),
        interpreterUsages: orderBy(rawFullAct.interpreterUsages, (o) => rawFullAct?.interpreterUsageIds?.indexOf(o.id)),
        actTemplate: rawFullAct.actTemplate && mapActTemplate(rawFullAct.actTemplate),
    };

    return ret;
};

export const mapOffenceFull = (rawOffenceFull: RawOffenceFull) => {
    const ret: Offence = {
        ...mapOffenceLight(rawOffenceFull),
    };

    return ret;
};

export const mapOffenceLight = (rawOffenceLight: RawOffenceLight) => {
    const ret: OffenceLight = {
        ...rawOffenceLight,
        createdDate: mapDate(rawOffenceLight.createdDate),
        offenceDate: mapDate(rawOffenceLight.offenceDate),
        lastUpdatedDate: mapDate(rawOffenceLight.lastUpdatedDate),
    };

    return ret;
};

export const mapOffenceFromOffenceReference = (offenceReference: OffenceReference, createdBy: ArgUserInfo) => {
    const ret: Offence = {
        id: '',
        createdDate: new Date(),
        offenceReferenceId: offenceReference.id,
        offenceReference: offenceReference,
        status: OffenceStatus.Committed,
        createdBy: createdBy,
    };

    return ret;
};

export const mapOffenceToCreate = (offence: Offence) => {
    const ret: OffenceToCreate = {
        offenceReferenceId: offence.offenceReferenceId,
        status: offence.status,
        offenceDate: offence.offenceDate,
        offencePlace: offence.offencePlace,
    };

    return ret;
};

export const mapInterpreterToCreate = (interpreterUsage: InterpreterUsage) => {
    const ret: InterpreterUsageToCreate = {
        actId: interpreterUsage.actId,
        interpreterId: interpreterUsage.interpreterId,
        language: interpreterUsage.language,
        courtId: interpreterUsage.courtId,
        isSworn: interpreterUsage.isSworn,
    };

    return ret;
};

export const mapService = (rawService: RawService) => {
    const ret: Service = {
        ...rawService,
        createdDate: mapDate(rawService.createdDate),
        lastUpdatedDate: mapDate(rawService.lastUpdatedDate),
    };

    return ret;
};

export const mapProceoGroup = (folder: ProceoBaseFolder) => {
    const ret: ProceoGroup = {
        ...folder,
        serviceId: getCaseCustomFieldValue(folder.customFields, ProceoGroupCustomFieldNames.ServiceId),
    };

    return ret;
};

export const mapActTemplate = (rawActTemplate: RawActTemplate) => {
    const ret: ActTemplate = {
        ...rawActTemplate,
        custom: rawActTemplate.isCustom,
        document: rawActTemplate.content,
        actType: rawActTemplate.actType,
        createdBy: rawActTemplate.createdBy,
        createdDate: mapDate(rawActTemplate.createdDate),
        lastUpdatedBy: rawActTemplate.lastUpdatedBy,
        lastUpdatedDate: mapDate(rawActTemplate.lastUpdatedDate),
    };

    return ret;
};

export const mapActType = (rawActType: RawActType) => {
    const ret: ActType = {
        actDeletionPermission: ActDeletionPermission.ANY,
        actCreationPermission: ActCreationPermission.ANY,
        ...rawActType,
    };

    return ret;
};

export const mapAuthorization = (rawAuthorzation: RawProceoFolderAuthorization): ProceoFolderAuthorization => {
    const ret: ProceoFolderAuthorization = {
        ...rawAuthorzation,
        createdDate: mapDate(rawAuthorzation.createdDate),
        lastUpdatedDate: mapDate(rawAuthorzation.lastUpdatedDate),
    };

    return ret;
};

export const mapArticulationTemplate = (rawArticulationTemplate: RawArticulationTemplate) => {
    const ret: ArticulationTemplate = {
        ...rawArticulationTemplate,
        articulation: rawArticulationTemplate.content,
        createdDate: mapDate(rawArticulationTemplate.createdDate),
        lastUpdatedDate: mapDate(rawArticulationTemplate.lastUpdatedDate),
    };

    return ret;
};
