import { defineMessages } from 'react-intl';

import { Tool } from '../../components/basic';
import { SettingsEnvironmentContext } from '../envrionment-contexts/settings-environment-context';
import { SETTINGS_ROOT_URL } from '../../components/containers/routes-urls';
import { registerToolItem } from '../../components/basic/arg-toolbar/tools-registry';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME } from '../envrionment-contexts/context-names';
import { SettingsPermissions } from '../permissions/permissions';

export const EXTENSIONS_PATH = `${SETTINGS_ROOT_URL}/extensions`;
export const EXTENSIONS_ROUTE = '/settings/extensions/:extensionsScope';
export type ExtensionScope = 'externalComponents' | 'webhooks';

const messages = defineMessages({
    webhooks: {
        id: 'settings.users.menu.webhooks',
        defaultMessage: 'Webhooks',
    },
    externalComponents: {
        id: 'settings.users.menu.externalComponents',
        defaultMessage: 'External components',
    },
});

export function registerExtensionToolItems(path: string, order: number, extensionsScope: ExtensionScope): void {
    registerToolItem(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path,
        order,
        label: extensionsScope === 'webhooks' ? messages.webhooks : messages.externalComponents,
        icon: 'icon-webhook',
        visible: (environmentContext: SettingsEnvironmentContext) => {
            const hasWebhookAccess = environmentContext.hasAnyPermissions<SettingsPermissions>('admin.webhook.access');

            return !!hasWebhookAccess;
        },
        onClick: (tool: Tool<SettingsEnvironmentContext>, environmentContext: SettingsEnvironmentContext) => {
            const url = `${EXTENSIONS_PATH}/${extensionsScope}`;

            environmentContext.navigate(url);
        },
    });
}

export function setupExtensions() {
    registerExtensionToolItems('global/webhooks', 300, 'webhooks');
}
