import { RawOffenceFull, RawOffenceLight } from '../utils/connectors/raws';

export type OffenceReferenceId = string;
export type OffenceId = string;

export interface OffenceReference {
    id: OffenceReferenceId;
    number: string;
    title: string;
    nature: string;
    definedBy: string;
    punishableBy: string;
}

export enum OffenceReferenceSortBy {
    Number = 'Number',
    Nature = 'Nature',
    Title = 'Title',
}

export enum OffenceStatus {
    Attempted = 'Attempted',
    Committed = 'Committed',
}

export interface OffenceToCreate {
    offenceReferenceId: OffenceReferenceId;
    status: OffenceStatus;
    offenceDate?: Date;
    offencePlace?: string;
}

export interface OffenceLight extends Omit<RawOffenceLight, 'offenceDate' | 'createdDate' | 'lastUpdatedDate'> {
    offenceDate?: Date;
    createdDate: Date;
    lastUpdatedDate?: Date;
}

export interface Offence extends Omit<RawOffenceFull, 'offenceDate' | 'createdDate' | 'lastUpdatedDate'> {
    offenceDate?: Date;
    createdDate: Date;
    lastUpdatedDate?: Date;
}
