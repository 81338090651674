import { useDrag } from 'react-dnd';

import { ARG_BYPASS_DND_DISABLER_CLASSNAME, ArgIcon, ArgMessageRenderer, ArgRenderedText, useClassNames } from 'src/components/basic';
import { FormElement } from 'src/components/common/forms/model';
import { useDraggingContext } from '../../components/dragging-context';

import './form-draggable-panel-item.less';

export interface FormDraggablePanelItemProps {
    label: ArgRenderedText;
    icon: string;
    formElement: FormElement;
    resetForm: () => void;
}

export function FormDraggablePanelItem(props: FormDraggablePanelItemProps) {
    const { label, icon, formElement, resetForm } = props;
    const classNames = useClassNames('form-draggable-panel-item');
    const { setDragging } = useDraggingContext();

    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: formElement.type,
        item: () => {
            setDragging(formElement.id);

            return formElement;
        },
        end(item, monitor) {
            setDragging(undefined);

            if (!monitor.didDrop()) {
                resetForm();
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        isDragging(monitor) {
            return formElement.id === monitor.getItem().id;
        },
    }), [formElement]);

    const cls = { dragging: isDragging };

    return (
        <li ref={preview} className={classNames('&', cls, ARG_BYPASS_DND_DISABLER_CLASSNAME)}>
            <div ref={drag} className='icon-wrapper'>
                <ArgIcon name='icon-6dots' />
            </div>
            <ArgIcon
                className={classNames('&-primary-list-item-type')}
                name={icon}
            />
            <label className={classNames('&-primary-list-item-property')}>
                <ArgMessageRenderer message={label} />
            </label>
        </li>
    );
}
