import {
    AddGroupDTO,
    AddGroupMembersDTO,
    EditGroupDTO,
} from '../models/dtoApi';
import { Group, GroupId, User } from '../../model/user';
import { ArgUserId, ProgressMonitor } from 'src/components/basic';
import { BaseConnector } from '../../utils/connectors/base-connector';
import {
    getSettingsApi,
} from 'src/utils/connectors/api-url';
import {
    mapGroup,
} from './mappers';


class UserGroupsConnector extends BaseConnector {
    private static instance: UserGroupsConnector;

    static getInstance(): UserGroupsConnector {
        if (!UserGroupsConnector.instance) {
            UserGroupsConnector.instance = new UserGroupsConnector('settings.user-groups', getSettingsApi());
        }

        return UserGroupsConnector.instance;
    }

    async editGroup(
        editGroupPayload: EditGroupDTO,
        groupId: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/user-groups/${encodeURIComponent(groupId)}`;
        const options = {
            method: 'PUT',
            json: editGroupPayload,
        };
        await this.request(url, options, progressMonitor);
    }

    async addMembersToGroup(
        addMembersToGroupPayload: AddGroupMembersDTO,
        groupId: GroupId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/user-groups/${encodeURIComponent(groupId)}/members`;
        const options = {
            method: 'POST',
            json: addMembersToGroupPayload,
        };
        await this.request(url, options, progressMonitor);
    }

    async deleteUserFromGroup(
        groupId: GroupId,
        userIdToRemove: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ) {
        const url = `/user-groups/${encodeURIComponent(groupId)}/members/users/${encodeURIComponent(userIdToRemove)}`;
        const options = {
            method: 'DELETE',
        };
        await this.request(url, options, progressMonitor);
    }

    async deleteGroupFromGroup(
        groupId: GroupId,
        groupIdToRemove: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ) {
        const url = `/user-groups/${encodeURIComponent(groupId)}/members/groups/${encodeURIComponent(groupIdToRemove)}`;
        const options = {
            method: 'DELETE',
        };
        await this.request(url, options, progressMonitor);
    }

    async getUserGroups(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Group[]> {
        const url = `/users/${encodeURIComponent(userId)}/memberships`;
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        // TODO OO Map date
        const response: { groups: Group[] } = await this.request(
            url,
            options,
            progressMonitor
        );

        return response.groups;
    }

    async getGroups(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Group[]> {
        const url = '/user-groups';
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };
        const response: { groups: any } = await this.request(
            url,
            options,
            progressMonitor
        );

        const ret = response.groups.map(mapGroup);

        return ret;
    }

    async getGroup(
        groupId: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Group> {
        const url = `/user-groups/${encodeURIComponent(groupId)}`;
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const groupResult = await this.request(url, options, progressMonitor);
        const ret = mapGroup(groupResult);

        return ret;
    }

    async addGroup(
        addGroupPayload: AddGroupDTO,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Group> {
        const url = '/user-groups';
        const options = {
            method: 'POST',
            json: addGroupPayload,
        };
        const newUserGroup = await this.request(url, options, progressMonitor);

        return newUserGroup;
    }

    async addGroupMembers(
        groupId: GroupId,
        addGroupMembersPayload: AddGroupMembersDTO,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/user-groups/${encodeURIComponent(groupId)}/members`;
        const options = {
            method: 'POST',
            json: addGroupMembersPayload,
        };
        await this.request(url, options, progressMonitor);
    }

    async getGroupMembers(
        groupId: GroupId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<{ users: User[]; groups: Group[] }> {
        const url = `/user-groups/${encodeURIComponent(groupId)}/members`;
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };
        const response = await this.request(url, options, progressMonitor);

        return response;
    }

    async getGroupMemberships(
        groupId: GroupId,
        inherited: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<string[]> {
        const url = `/user-groups/${encodeURIComponent(groupId)}/memberships`;
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
            params: {
                inherited,
            },
        };
        const response = await this.request(url, options, progressMonitor);

        const ret = response.groups.map((group: Group) => group.id);

        return ret;
    }

    async deleteGroup(
        groupId: GroupId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/user-groups/${encodeURIComponent(groupId)}`;
        const options = {
            method: 'DELETE',
        };
        await this.request(url, options, progressMonitor);
    }
}

export default UserGroupsConnector.getInstance();
