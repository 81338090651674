import { ReactNode, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { defineMessages } from 'react-intl';

import { ArgExpandState, ArgTable2, ArgTable2Column, ArgTooltip2, ButtonClickEvent, ClassValue, useClassNames } from 'src/components/basic';
import { PageHeader } from 'src/settings/common-components/page-header';
import { ExpandableItemInfo, useExpandableItems } from 'src/hooks/use-expandable-items';
import { ExpandableItem } from 'src/components/common/expandable-item';
import { HierarchyActionsMenu } from './hierarchy-actions-menu';
import { HeriarchyNewGroupBtn } from './hierarchy-new-group-btn';
import { Service } from 'src/proceo/model/service';
import { ProceoGroup } from 'src/proceo/model/group';
import { useGetProceoGroupsAndServices } from '../../../hooks/use-get-groups-services';
import { ErrorPane } from '../../../../components/common/panes/error-pane';
import { useGetServices } from '../../../hooks/use-get-service';
import { LoadingPane } from '../../../../components/common/panes/loading-pane';

import './hierarchy-overview.less';

const messages = defineMessages({
    title: {
        id: 'proceo.services-groups.title',
        defaultMessage: 'Hierarchy',
    },
    nameColumn: {
        id: 'proceo.services-groups.hierarchy.overview.columns.Name',
        defaultMessage: 'Name',
    },
});

const FORCE_ERROR = false;
const FORCE_LOADING = false;

export type UIItem = ExpandableItemInfo<ProceoGroup>

export interface HierarchyOverviewProps {
    className?: ClassValue;
}

export function HierarchyOverview(props: HierarchyOverviewProps) {
    const {
        className,
    } = props;

    const classNames = useClassNames('proceo-hierarchy-overview');

    const { groups, refreshGroups, progressMonitor: groupsProgressMonitor, error: groupsError } = useGetProceoGroupsAndServices();
    const { services, refreshServices, progressMonitor: servicesProgressMonitor, error: servicesError } = useGetServices();

    const handleUpdate = useCallback(() => {
        refreshServices();
        refreshGroups();
    }, [refreshGroups, refreshServices]);

    const dataSourceExpandableItems = useMemo(() => {
        if (!groups) {
            return [];
        }

        const data = getDataSourceExpandableItems(groups);

        return data;
    }, [groups]);

    const {
        displayedItems,
        getItemExpandState,
        onItemExpand,
    } = useExpandableItems(dataSourceExpandableItems, groups, undefined, undefined, undefined, false);

    const handleRowClick = useCallback((row: UIItem) => {
        const state = getItemExpandState(row);
        onItemExpand(row, !state.isExpanded);
    }, [getItemExpandState, onItemExpand]);

    const columns: ArgTable2Column<UIItem>[] = useMemo(() => {
        if (!services) {
            return [];
        }

        const tableColumns = getColumns(
            classNames,
            getItemExpandState,
            onItemExpand,
            handleUpdate,
            services
        );

        return tableColumns;
    }, [classNames, getItemExpandState, handleUpdate, onItemExpand, services]);

    if (FORCE_ERROR || groupsError || servicesError) {
        return (
            <div className={classNames('&', 'error')}>
                <ErrorPane
                    error={groupsError || servicesError}
                    className='fill'
                />
            </div>
        );
    }

    if (FORCE_LOADING || !groups || !services) {
        return (
            <div className={classNames('&', 'loading')}>
                <LoadingPane
                    progressMonitor={groupsProgressMonitor || servicesProgressMonitor}
                    className='fill'
                />
            </div>
        );
    }

    let content: ReactNode;

    if (!dataSourceExpandableItems.length) {
        content = (
            <div className={classNames('&-empty-container')}>
                <HeriarchyNewGroupBtn
                    onGroupCreated={handleUpdate}
                />
            </div>
        );
    } else {
        content = (
            <ArgTable2<UIItem>
                scrollableBodyStyle={{ backgroundColor: '#fff' }}
                scrollableBodyClassName={classNames('&-table-scrollable')}
                columns={columns}
                dataSource={displayedItems}
                className={classNames('&-table')}
                headerClassName={classNames('&-table-header')}
                bordered={true}
                onRowClick={handleRowClick}
                rowKey={(item) => item.id}
            />
        );
    }

    return (
        <div className={classNames('&-main-container', className)}>
            <PageHeader title={messages.title} />
            {content}
        </div>
    );
}

const columnKeys = {
    displayNameColumnKey: 'displayName',
} as const;

function getColumns(
    classNames: (...args: classNames.ArgumentArray) => string,
    getItemExpandState: (item: UIItem) => ArgExpandState,
    onItemExpand: (item: UIItem, isExpanded: boolean, event?: ButtonClickEvent | undefined) => void,
    onSubmit: () => void,
    services: Service[]
): ArgTable2Column<UIItem>[] {
    const displayNameColumn: ArgTable2Column<UIItem> = {
        key: columnKeys.displayNameColumnKey,
        title: messages.nameColumn,
        dataIndex: 'data.displayName',
        ellipsis: true,
        headerClassName: classNames('&-table-header-displayName'),
        render: function ItemExpandable(value: string, item: UIItem) {
            const icon = getUIItemIcon(item);
            const isExpanded = getItemExpandState(item)?.isExpanded;

            return (
                <ArgTooltip2 title={item.data.displayName}>
                    <>
                        <ExpandableItem
                            expandableItem={item}
                            isExpanded={isExpanded}
                            onExpandClick={onItemExpand}
                            label={item.data.displayName}
                            tagIcon={icon}
                        />
                    </>
                </ArgTooltip2>
            );
        },
    };
    const actionButtonColumn: ArgTable2Column<UIItem> = {
        key: 'id',
        dataIndex: 'id',
        width: 60,
        render: function ActionButton(id: string, item: UIItem) {
            return (
                <HierarchyActionsMenu
                    element={item}
                    onSubmit={onSubmit}
                    services={services}
                />
            );
        },
    };

    return [displayNameColumn, actionButtonColumn];
}

function getDataSourceExpandableItems(cases: ProceoGroup[]): UIItem[] {
    const itemByIds: { [caseId: string]: UIItem } = {};

    function getOrCreateItem(id: string): UIItem {
        const ret = itemByIds[id] || {
            id: id,
            children: [],
        };
        itemByIds[id] = ret;

        return ret;
    }

    for (const group of cases) {
        const item: UIItem = getOrCreateItem(group.id);
        item.data = group;
        if (group.parentFolderId) {
            const parentItem: UIItem = getOrCreateItem(group.parentFolderId);
            item.parent = parentItem;
            parentItem.children!.push(item);
        }
    }

    return Object.values(itemByIds).filter(item => !item.parent);
}

export function isGroupService(data: ProceoGroup) {
    return !!data.serviceId;
}

function getUIItemIcon(uiItem: UIItem) {
    const icon = isGroupService(uiItem.data) ? 'icon-office' : 'icon-users';

    return icon;
}
