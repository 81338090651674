import React, { ReactNode } from 'react';
import { isBoolean, isEmpty, isInteger, isNumber, isString, isUndefined } from 'lodash';

import {
    ArgCombo,
    ArgFormLabel,
    ArgInputInteger,
    ArgInputNumber,
    ArgInputText,
    ArgRenderedText,
    ClassValue,
    useClassNames,
} from 'src/components/basic';
import { UserMetadataValue, UserProfileField } from '../../../model/user-metadata';

import './user-metadata-field.less';

const FORCE_MANDATORY = false;

const CLASSNAME = 'common-user-metadata-field';

interface UserMetadataFieldProps {
    className?: ClassValue;

    field: UserProfileField;

    value: UserMetadataValue | undefined;

    onChange: (value: UserMetadataValue | undefined) => void;

    // if true, handle multi-values as comma separated list of values
    joinMultivalue?: boolean;

    fieldError?: ArgRenderedText;

    disabled?: boolean;
}


// Render the control for a user metadata field. Note: currently it does not enforce field.isMandatory option, ie: all fields are made optional.
export function UserMetadataField(props: UserMetadataFieldProps) {
    const {
        className,
        field,
        value,
        onChange,
        joinMultivalue,
        fieldError,
        disabled,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    let editor: ReactNode = null;
    switch (field.typeCompatibilityCode) {
        case 'Int':
            editor = <ArgInputInteger
                onChange={v => onChange(isNumber(v) ? v : undefined)}
                value={isInteger(value) ? (value as number) : undefined}
                disabled={disabled}
            />;
            break;
        case 'Decimal':
            editor = <ArgInputNumber
                onChange={v => onChange(isNumber(v) ? v : undefined)}
                value={isNumber(value) ? value : undefined}
                disabled={disabled}
            />;
            break;
    }

    if (!editor) {
        if (field.possibleValues) {
            const cardinality = field.isMultivalued ? 'zeroMany' : 'optional';

            editor = <ArgCombo
                cardinality={cardinality}
                onChange={v => onChange(field.isMultivalued && joinMultivalue ? v.join(', ') : v)}
                value={field.isMultivalued && joinMultivalue ? toArray(value) : value}
                items={field.possibleValues || []}
                disabled={disabled}
            />;
        } else {
            editor = <ArgInputText
                onChange={v => onChange(v || undefined)}
                value={!isString(value) ? '' : value}
                disabled={disabled}
            />;
        }
    }

    return (
        <ArgFormLabel
            className={classNames('&', className)}
            required={field.isMandatory || FORCE_MANDATORY}
            errorMessage={fieldError}
        >
            {editor}
        </ArgFormLabel>
    );
}

// Convert value to an array. If value is a string, it assumes it is a
function toArray(value: UserMetadataValue | undefined): string[] | number[] | boolean[] {
    if (isUndefined(value) || isEmpty(value)) {
        return [];
    }
    if (Array.isArray(value)) {
        return value;
    }
    if (isBoolean(value)) {
        return [value];
    }
    if (isNumber(value)) {
        return [value];
    }

    return value.split(', ');
}
