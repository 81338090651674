import { useCallback, useContext, useMemo, useState } from 'react';
import { Avatar, Row } from 'antd';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { FormikHelpers, useFormik } from 'formik';
import { isString, reduce } from 'lodash';

import {
    ArgFormLabel,
    ArgInputText,
    ArgModal,
    ArgRenderedText,
    ArgTabsSubLevel,
    ProgressMonitor,
    SubProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
    useMemoDeepEquals,
} from 'src/components/basic';
import { AddUserDTO, RolesScope } from 'src/settings/models/dtoApi';
import { SettingsConnector } from 'src/settings/connectors/settings-connector';
import { computeDigram, computeUserName } from 'src/utils/digram';
import { UsersAndGroupsStateContext } from '../../providers/usersState';
import { getRolesFromRoleIds } from '../../utils';
import { AssignRoles } from 'src/settings/roles/components/assign-roles';
import { UserMetadataTable } from 'src/components/common/user-metadata/user-metadata-table';
import { UserProfileField } from 'src/model/user-metadata';
import { getProblemDetailsType } from '../../../../components/basic/utils/response-error';
import { UsersAdminConnector } from '../../../../utils/connectors/users-admin-connector';

import './create-user-modal.less';

const FORCE_MANDATORY = false;

const USERS_UNIQUE_ERROR_TYPE = 'https://problemdetails.chapsvisioncloud.com/dataplatform/api/users/unique';

const METADATA_PREFIX = '$$$$:';

const PASSWORD_CHARACTERS_COUNT = 16;
const PASSWORD_UPPERCASE_COUNT = 1;
const PASSWORD_SPECIAL_CHARACTER_COUNT = 1;
const PASSWORD_NUMBER_COUNT = 1;

interface FormFields {
    displayName?: string;
    firstName?: string;
    lastName?: string;
    userName?: string;
    password?: string;
    passwordChangeRequired: boolean;
    roleIds: string[];

    [key: string]: any;
}

type FormFieldsErrors = Partial<Record<keyof FormFields, boolean>>;

export const messages = defineMessages({
    title: {
        id: 'settings.create-user-modal.title',
        defaultMessage: 'Create A New User',
    },
    description: {
        id: 'settings.create-user-modal.description',
        defaultMessage: 'Please supply the following information.',
    },
    fieldUsername: {
        id: 'settings.create-user-modal.field.username',
        defaultMessage: 'Username',
    },
    fieldUsernamePlaceholder: {
        id: 'settings.create-user-modal.field.username-placeholder',
        defaultMessage: 'username@domain.ext',
    },
    fieldFirstName: {
        id: 'settings.create-user-modal.field.firstName',
        defaultMessage: 'First Name',
    },
    fieldLastName: {
        id: 'settings.create-user-modal.field.lastName',
        defaultMessage: 'Last Name',
    },
    fieldDisplayName: {
        id: 'settings.create-user-modal.field.displayName',
        defaultMessage: 'Display Name',
    },
    fieldIsAdminLabelChecked: {
        id: 'settings.create-user-modal.field.isAdmin.label.checked',
        defaultMessage: 'Yes',
    },
    fieldIsAdminLabelUnchecked: {
        id: 'settings.create-user-modal.field.isAdmin.label.unchecked',
        defaultMessage: 'No',
    },
    fieldPassword: {
        id: 'settings.create-user-modal.field.password',
        defaultMessage: 'Password',
    },
    fieldRoles: {
        id: 'settings.create-user-modal.field.roles',
        defaultMessage: 'Roles',
    },
    fieldAdmin: {
        id: 'settings.create-user-modal.field.admin',
        defaultMessage: 'Admin',
    },
    fieldChangePassword: {
        id: 'settings.create-user-modal.field.changePassword',
        defaultMessage: 'On first login, the user must change their password',
    },
    fieldPasswordPlaceholder: {
        id: 'settings.create-user-modal.field.fieldChangePassword',
        defaultMessage: 'Ex: eCz89Tf1Lf!ez8i?',
    },
    fieldPasswordDescription: {
        id: 'settings.create-user-modal.field.passwordDescription',
        defaultMessage: 'The password must adhere to the complexity criteria of your organisation.',
    },
    fieldPasswordRule: {
        id: 'settings.create-user-modal.field.passwordRule',
        defaultMessage: 'At least',
    },
    fieldPasswordCharacter: {
        id: 'settings.create-user-modal.field.passwordCharacter',
        defaultMessage: '{count} characters',
    },
    fieldPasswordUppercase: {
        id: 'settings.create-user-modal.field.passwordUppercase',
        defaultMessage: '{count} uppercase',
    },
    fieldPasswordSpecialCharacter: {
        id: 'settings.create-user-modal.field.passwordSpecialCharacter',
        defaultMessage: '{count} special character',
    },
    fieldPasswordNumber: {
        id: 'settings.create-user-modal.field.passwordNumber',
        defaultMessage: '{count} number',
    },
    submit: {
        id: 'settings.create-user-modal.submitButton',
        defaultMessage: 'Create',
    },
    cancel: {
        id: 'settings.create-user-modal.cancelButton',
        defaultMessage: 'Cancel',
    },
    addUserErrorMsg: {
        id: 'settings.error-message.adding-user',
        defaultMessage: 'Something went wrong while adding the user',
    },
    addUserUniqueErrorMsg: {
        id: 'settings.error-message.adding-user-unique-error',
        defaultMessage: 'A user with this username already exists',
    },
    avatar: {
        id: 'settings.create-user-modal.avatar',
        defaultMessage: 'Avatar',
    },
    fieldFullName: {
        id: 'settings.create-user-modal.fullName',
        defaultMessage: 'Full name',
    },
    required: {
        id: 'settings.create-user-modal.required',
        defaultMessage: 'Required',
    },
    accountTabTitle: {
        id: 'settings.create-user-modal.accountTabTitle',
        defaultMessage: 'Account',
    },
    propertiesTabTitle: {
        id: 'settings.create-user-modal.propertiesTabTitle',
        defaultMessage: 'Properties',
    },
    mandatory: {
        id: 'settings.create-user-modal.Mandatory',
        defaultMessage: 'Mandatory',
    },
    submitTooltip: {
        id: 'settings.create-user-modal.submitTooltip',
        defaultMessage: 'All required fields must be completed',
    },
});

const TABS = [{
    key: 'account',
    title: messages.accountTabTitle,
}, {
    key: 'properties',
    title: messages.propertiesTabTitle,
}];

export interface CreateUserModalProps {
    closeModal: () => void;
    userProfilesFields?: UserProfileField[];
}

export function CreateUserModal(props: CreateUserModalProps) {
    const {
        closeModal,
        userProfilesFields,
    } = props;

    const intl = useIntl();
    const notifications = useArgNotifications();

    const { setUsers, allRoles } = useContext(UsersAndGroupsStateContext);

    const [submitForm, submitProgressMonitor, submitError] = useCallbackAsync(async (progressMonitor: ProgressMonitor, values: FormFields, { resetForm }: FormikHelpers<FormFields>) => {
        if (!values.displayName || !values.userName || !values.password) {
            return;
        }

        const addUserPayload: AddUserDTO = {
            displayName: values.displayName,
            firstName: values.firstName,
            lastName: values.lastName,
            userName: values.userName,
            isActive: true,
            password: values.password,
            passwordChangeRequired: values.passwordChangeRequired,
            profile: userProfilesFields?.reduce((obj, field) => {
                let v = values[`${METADATA_PREFIX}${field.id}`];
                if (v === undefined) {
                    return obj;
                }

                if (field.isMultivalued && isString(v)) {
                    v = v.split(',').map((s: string) => s.trim()).filter((s: string) => s);
                }

                obj[field.id] = v;

                return obj;
            }, {} as Record<string, any>),
        };

        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            const { id: userId } = await UsersAdminConnector.getInstance().addUser(addUserPayload, sub1);

            const roles = getRolesFromRoleIds(allRoles, values.roleIds);
            const rolesScopes = [...new Set(roles.map(role => role.scope as RolesScope))];

            const apiCalls = rolesScopes.map((scope) => {
                const sub2 = new SubProgressMonitor(progressMonitor, 1);
                const scopedRoleIds = roles.filter(role => role.scope === scope).map(role => role.id);

                return SettingsConnector.getInstance().editUserRoles(userId, scopedRoleIds, scope, sub2);
            });

            await Promise.all(apiCalls);

            const users = await UsersAdminConnector.getInstance().getUsers(false);

            setUsers(users);
            resetForm();
            closeModal();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            const errorMessage = getProblemDetailsType(error) === USERS_UNIQUE_ERROR_TYPE
                ? messages.addUserUniqueErrorMsg
                : messages.addUserErrorMsg;
            notifications.snackError({ message: errorMessage }, error as Error);
            throw error;
        }
    }, [userProfilesFields, allRoles, setUsers, closeModal, notifications]);

    const validateForm = useCallback((values: FormFields) => {
        const errors: FormFieldsErrors = {};
        if (!values.displayName) {
            errors.displayName = true;
        }
        if (!values.password) {
            errors.password = true;
        } else if (!values.password?.match(/(?=.*[!?@#$%^&*])(?=.*[A-Z])(?=.*[0-9])(?=.{16,})/)) {
            errors.password = true;
        }
        if (!values.userName) {
            errors.userName = true;
        } else if (!values.userName?.match(/^(.+)@(.+)$/)) {
            errors.userName = true;
        }
        if (!(values.roleIds.length > 0)) {
            errors.roleIds = true;
        }

        if (userProfilesFields) {
            userProfilesFields.forEach((f: UserProfileField) => {
                if (!f.isMandatory && !FORCE_MANDATORY) {
                    return;
                }
                if (values[METADATA_PREFIX + f.id]) {
                    return;
                }

                errors[METADATA_PREFIX + f.id] = true;
            });
        }

        return errors;
    }, [userProfilesFields]);

    const classNames = useClassNames('settings-create-user-modal');
    const {
        handleSubmit,
        handleChange,
        setFieldValue,
        values: formValues,
        errors: formErrors,
        resetForm,
    } = useFormik<FormFields>({
        initialValues: { passwordChangeRequired: true, roleIds: [] },
        validateOnChange: false,
        validate: validateForm,
        onSubmit: submitForm,
    });

    const userMetadata = useMemoDeepEquals<Record<string, any>>(() => {
        const ret = reduce(formValues, (acc: Record<string, any>, value: any, key: string) => {
            if (!key.startsWith(METADATA_PREFIX)) {
                return acc;
            }
            acc[key.substring(METADATA_PREFIX.length)] = value;

            return acc;
        }, {} as Record<string, any>);

        return ret;
    }, [formValues]);

    const userProfilesErrors = useMemo<Record<string, ArgRenderedText>>(() => {
        const ret = reduce(formErrors, (acc: Record<string, ArgRenderedText>, value, key) => {
            if (!key.startsWith(METADATA_PREFIX)) {
                return acc;
            }
            const k = key.substring(METADATA_PREFIX.length);
            acc[k] = messages.mandatory;

            return acc;
        }, {} as Record<string, ArgRenderedText>);

        return ret;
    }, [formErrors]);


    const handleMetaChange = useCallback((field: UserProfileField, value: any) => {
        setFieldValue(`${METADATA_PREFIX}${field.id}`, value);
    }, [setFieldValue]);

    const [activeTab, setActiveTab] = useState<string | undefined>('account');

    const okButtonDisabled = !formValues.displayName || !formValues.password || !formValues.userName || !(formValues.roleIds.length > 0);

    const clsPasswordCharacter = useMemo(() => {
        const passwordMatchCharactersRule = formValues.password?.match(/(?=.{16,})/);

        const cls = {
            'password-rule-error': formValues.password && !passwordMatchCharactersRule,
            'password-rule-match': passwordMatchCharactersRule,
        };

        return cls;
    }, [formValues.password]);

    const clsPasswordUppercase = useMemo(() => {
        const passwordMatchUppercaseRule = formValues.password?.match(/(?=.*[A-Z])/);

        const cls = {
            'password-rule-error': formValues.password && !passwordMatchUppercaseRule,
            'password-rule-match': passwordMatchUppercaseRule,
        };

        return cls;
    }, [formValues.password]);

    const clsPasswordSpecialCharacter = useMemo(() => {
        const passwordMatchSpecialCharacterRule = formValues.password?.match(/(?=.*[!?@#$%^&*])/);

        const cls = {
            'password-rule-error': formValues.password && !passwordMatchSpecialCharacterRule,
            'password-rule-match': passwordMatchSpecialCharacterRule,
        };

        return cls;
    }, [formValues.password]);

    const clsPasswordNumber = useMemo(() => {
        const passwordMatchNumberRule = formValues.password?.match(/(?=.*[0-9])/);

        const cls = {
            'password-rule-error': formValues.password && !passwordMatchNumberRule,
            'password-rule-match': passwordMatchNumberRule,
        };

        return cls;
    }, [formValues.password]);

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            okText={messages.submit}
            onClose={closeModal}
            onCancel={closeModal}
            okDisabled={submitProgressMonitor?.isRunning || okButtonDisabled}
            okButtonTooltip={okButtonDisabled ? messages.submitTooltip : ''}
            loading={submitProgressMonitor?.isRunning}
            cancelText={messages.cancel}
            onOk={() => handleSubmit()}
            className={classNames('&')}
        >
            <div className={classNames('&-form')}>
                <ArgTabsSubLevel tabs={TABS} onChange={setActiveTab} activeTabKey={activeTab} />

                <div className={classNames('&-scrollable-container')}>
                    <div className={classNames({ hide: activeTab !== 'account' })}>
                        <p><FormattedMessage {...messages.description} /></p>
                        <Row className={classNames('&-name-and-photo')}>
                            <div className={classNames('&-avatar-and-label')}>
                                {intl.formatMessage(messages.avatar)}
                                <Avatar
                                    key={formValues.firstName}
                                    alt={formValues.firstName?.split('')[0]}
                                    className={classNames('&-avatar')}
                                    size={80}
                                >
                                    {computeDigram(computeUserName({ ...formValues }))}
                                </Avatar>
                            </div>
                            <div className={classNames('&-first-name-last-name')}>
                                <ArgFormLabel
                                    propertyName={messages.fieldLastName}
                                    addedRow={true}
                                    className={classNames('&-label')}
                                >
                                    <ArgInputText
                                        value={formValues.lastName}
                                        onInputChange={handleChange('lastName')}
                                        data-testid='lastName'
                                        state={formErrors.lastName ? 'invalid' : undefined}
                                    />
                                </ArgFormLabel>
                                <ArgFormLabel
                                    propertyName={messages.fieldFirstName}
                                    addedRow={true}
                                    className={classNames('&-label')}
                                >
                                    <ArgInputText
                                        value={formValues.firstName}
                                        onInputChange={handleChange('firstName')}
                                        data-testid='firstName'
                                    />
                                </ArgFormLabel>
                            </div>
                        </Row>
                        <ArgFormLabel
                            propertyName={messages.fieldFullName}
                            className={classNames('&-label')}
                            required={messages.required}
                        >
                            <ArgInputText
                                value={formValues.displayName}
                                onInputChange={handleChange('displayName')}
                                data-testid='displayName'
                                state={formErrors.displayName ? 'invalid' : undefined}
                            />
                        </ArgFormLabel>
                        <ArgFormLabel
                            propertyName={messages.fieldUsername}
                            addedRow={true}
                            className={classNames('&-label')}
                            required={messages.required}
                        >
                            <ArgInputText
                                value={formValues.userName}
                                autoFocus={true}
                                state={formErrors.userName ? 'invalid' : undefined}
                                onInputChange={handleChange('userName')}
                                placeholder={messages.fieldUsernamePlaceholder}
                                data-testid='userName'
                            />
                        </ArgFormLabel>

                        <ArgFormLabel
                            propertyName={messages.fieldRoles}
                            addedRow={true}
                            className={classNames('&-label')}
                            required={messages.required}
                        >
                            <AssignRoles
                                allRoles={allRoles}
                                value={getRolesFromRoleIds(allRoles, formValues.roleIds)}
                                onChange={(rolesSelected) =>
                                    setFieldValue('roleIds', rolesSelected.map((role) => role.id))
                                }
                                state={formErrors.roleIds ? 'invalid' : undefined}
                            />
                        </ArgFormLabel>

                        <ArgFormLabel
                            propertyName={messages.fieldPassword}
                            addedRow={true}
                            className={classNames('&-label')}
                            required={messages.required}
                        >
                            <div className={classNames('&-field-description')}>
                                {intl.formatMessage(messages.fieldPasswordDescription)}
                            </div>
                            <ArgInputText
                                value={formValues.password}
                                onInputChange={handleChange('password')}
                                state={formErrors.password ? 'invalid' : undefined}
                                inputType='password'
                                data-testid='password'
                                placeholder={messages.fieldPasswordPlaceholder}
                            />
                            <div className={classNames('&-field-rule')}>
                                <FormattedMessage {...messages.fieldPasswordRule} />
                                <ul>
                                    <li className={classNames('&', clsPasswordCharacter)}>
                                        <FormattedMessage {...messages.fieldPasswordCharacter}
                                                          values={{ count: PASSWORD_CHARACTERS_COUNT }} /></li>
                                    <li className={classNames('&', clsPasswordUppercase)}>
                                        <FormattedMessage {...messages.fieldPasswordUppercase}
                                                          values={{ count: PASSWORD_UPPERCASE_COUNT }} /></li>
                                    <li className={classNames('&', clsPasswordSpecialCharacter)}>
                                        <FormattedMessage {...messages.fieldPasswordSpecialCharacter}
                                                          values={{ count: PASSWORD_SPECIAL_CHARACTER_COUNT }} /></li>
                                    <li className={classNames('&', clsPasswordNumber)}>
                                        <FormattedMessage {...messages.fieldPasswordNumber}
                                                          values={{ count: PASSWORD_NUMBER_COUNT }} /></li>
                                </ul>
                            </div>
                        </ArgFormLabel>
                    </div>

                    <div className={classNames({ hide: activeTab !== 'properties' })}>
                        <div className={classNames('&-properties-container')}>
                            <UserMetadataTable
                                className={classNames('&-properties')}
                                metadata={userMetadata}
                                onChange={handleMetaChange}
                                joinMultivalue={true}
                                userProfilesFields={userProfilesFields}
                                formErrors={userProfilesErrors}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ArgModal>
    );
}
