import React, { ReactNode, useCallback, useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import { isFunction } from 'lodash';

import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ToolTreeNode } from './tool-context';
import { getToolIcon, getToolLabel, isToolDisabled, isToolSelected, isToolVisible, Tool } from './tool';
import { ArgChangeReason } from '../types';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgMenuItem } from '../arg-menu/arg-menu-item';
import { ArgMenuItemDivider } from '../arg-menu/arg-menu-item-divider';
import { ArgMenu } from '../arg-menu/arg-menu';
import { ArgSubMenu } from '../arg-menu/arg-sub-menu';

export interface ArgToolbarComboProps<T> extends ToolTreeNode<T> {
    environmentContext: T;
}

export function ArgToolbarCombo<T = undefined>(props: ArgToolbarComboProps<T>) {
    const {
        className,
        icon,
        children,
        tooltip,
        keyBinding,
        onClick,
        tooltipPlacement,
        buttonType,
        environmentContext,
    } = props;

    const classNames = useClassNames('arg-toolbar-combo');

    const [popoverVisible, setPopoverVisible] = useState<boolean>();

    const handleToolClick = useCallback((tool: Tool<T>, reason: ArgChangeReason, event?: ButtonClickEvent) => {
        setPopoverVisible(false);

        onClick?.(tool, environmentContext, event);

        tool.onClick?.(tool, environmentContext, event);
    }, [environmentContext, onClick]);

    const computeItemLabel = useCallback((toolItem: Tool<T>) => {
        const { label, keyBinding } = toolItem;
        if (label !== undefined) {
            return renderText(getToolLabel(toolItem, environmentContext));
        }

        if (keyBinding) {
            return keyBinding.name;
        }

        return undefined;
    }, [environmentContext]);

    const computeMenu = useCallback(() => {
        const _children = children?.filter((child: ToolTreeNode<T>) => {
            if (!isToolVisible(child, environmentContext)) {
                return false;
            }

            return true;
        });

        if (!_children?.length) {
            return null;
        }

        const _menuItems: ReactNode[] = [];

        let needSeparator = false;

        function renderMenuItems(menuItems: ReactNode[], children: ToolTreeNode<T>[]) {
            children.forEach((node: ToolTreeNode<T>) => {
                const { path, type } = node;

                if (type === 'separator') {
                    menuItems.push(<ArgMenuItemDivider key={path} />);
                    needSeparator = false;

                    return;
                }

                if (type === 'group') {
                    if (node.children?.length) {
                        if (needSeparator) {
                            menuItems.push(<ArgMenuItemDivider key={path} />);
                        }
                        renderMenuItems(menuItems, node.children);
                        needSeparator = true;
                    }

                    return;
                }

                const _disabled = isToolDisabled(node, environmentContext);
                const _label = computeItemLabel(node);

                if (type === 'combo' && node.children?.length) {
                    needSeparator = true;

                    const subMenuItems: ReactNode[] = [];
                    renderMenuItems(subMenuItems, node.children);

                    const sub = <ArgSubMenu
                        key={path}
                        className={classNames('&-menu-item', '&-submenu')}
                        popupClassName={classNames('&-submenu-popup')}
                        disabled={_disabled}
                        label={_label}
                        icon={getToolIcon(node, environmentContext)}
                    >
                        {subMenuItems}
                    </ArgSubMenu>;

                    menuItems.push(sub);

                    return;
                }

                if (type !== 'button') {
                    console.error('*** Unsupported type=', type, 'in menuItem for tool=', node);

                    return;
                }

                needSeparator = true;
                const selected = isToolSelected(node, environmentContext);
                const menuItem = <ArgMenuItem
                    key={path}
                    id={path}
                    className={classNames('&-menu-item', { selected })}
                    disabled={_disabled}
                    label={_label}
                    icon={getToolIcon(node, environmentContext)}
                    onClick={(event) => handleToolClick(node, 'selection', event)}
                />;

                menuItems.push(menuItem);
            });
        }

        renderMenuItems(_menuItems, _children);

        return <ArgMenu className={classNames('&-menu')}>
            {_menuItems}
        </ArgMenu>;
    }, [children, classNames, computeItemLabel, environmentContext, handleToolClick]);

    let _tooltip: MessageDescriptor | React.ReactNode = null;
    if (tooltip === undefined && keyBinding) {
        _tooltip = keyBinding.name;
    } else if (isFunction(tooltip)) {
        _tooltip = tooltip(props, environmentContext);
    } else {
        _tooltip = tooltip;
    }

    const _disabled = isToolDisabled(props, environmentContext);

    const _children = children?.filter((child: ToolTreeNode<T>) => {
        if (!isToolVisible(child, environmentContext)) {
            return false;
        }

        return true;
    });

    if (!_children?.length) {
        return null;
    }

    return <ArgButton
        className={classNames('&', className, 'arg-toolbar-item')}
        icon={getToolIcon(props, environmentContext)}
        label={getToolLabel(props, environmentContext)}
        tooltip={_tooltip}
        popover={computeMenu}
        popoverPlacement='bottomLeft'
        disabled={_disabled}
        type={buttonType || 'ghost'}
        size='medium'
        right={icon !== 'icon-options' ? 'dropdown' : undefined}
        tooltipClassName={classNames('&-tooltip')}
        keyBinding={keyBinding}
        popoverVisible={popoverVisible}
        popoverClassName={classNames('&-popover')}
        onPopoverVisibleChange={setPopoverVisible}
        tooltipPlacement={tooltipPlacement}
        data-testid='toolbar-combo-button'
    />;
}
