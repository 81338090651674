import { useCallback, useContext, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
    ArgButton,
    ArgMenu,
    ArgMenuItem,
    ArgModalContainerContext,
    ClassValue,
    ProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { downloadBlob } from '../../../../utils/file';
import { ExternalComponent } from '../../../models/external-component';
import { CreateExternalComponentModal } from '../create-external-component-modal/create-external-component-modal';
import { ExternalComponentDeletionModal } from '../external-component-deletion-modal/external-component-deletion-modal';
import { useHasPermission } from '../../../../contexts/user-permission';
import { PreparationPermissions } from '../../../../preparation/permissions/permissions';
import { SettingsConnector } from '../../../connectors/settings-connector';

import './external-component-actions-menu.less';

const CLASSNAME = 'settings-external-component-actions-menu';
const messages = defineMessages({
    delete: {
        id: 'settings.external-component.menu.delete',
        defaultMessage: 'Delete',
    },
    edit: {
        id: 'settings.external-component.menu.edit',
        defaultMessage: 'Edit',
    },
    export: {
        id: 'settings.external-component.menu.export',
        defaultMessage: 'Export',
    },
    exportError: {
        id: 'settings.external-component.menu.exportError',
        defaultMessage: 'An error occurred while exporting the external component',
    },
    externalComponentExportPrefix: {
        id: 'settings.external-component.menu.externalComponentExportPrefix',
        defaultMessage: 'external-component',
    },
});

export interface ExternalComponentActionsMenuProps {
    className?: ClassValue;
    externalComponent: ExternalComponent;
    onActionSuccess?: () => void;
}

export function ExternalComponentActionsMenu(props: ExternalComponentActionsMenuProps) {
    const { className, externalComponent, onActionSuccess } = props;
    const classNames = useClassNames(CLASSNAME);
    const notifications = useArgNotifications();

    const intl = useIntl();

    const [visible, setVisible] = useState(false);

    const modalContainer = useContext(ArgModalContainerContext);

    const canExportExternalComponent = useHasPermission<PreparationPermissions>('preparation.remote.component.export');

    const handleEditExternalComponent = useCallback(() => {
        modalContainer.open('settings-external-component-create', <CreateExternalComponentModal
            externalComponent={externalComponent}
            onClose={() => modalContainer.close('settings-external-component-create')}
            onSuccess={onActionSuccess}
        />);
    }, [modalContainer, onActionSuccess, externalComponent]);

    const [handleExportExternalComponent] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const rawExternalComponent: Blob = await SettingsConnector.getInstance().exportExternalComponent([externalComponent.key], progressMonitor);

            downloadBlob(`${intl.formatMessage(messages.externalComponentExportPrefix)}-${new Date().getTime()}.zip`, rawExternalComponent);
            onActionSuccess?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }
            notifications.snackError({ message: messages.exportError }, error as Error);
        }
    }, [externalComponent.key, intl, onActionSuccess, notifications]);

    const handleDeleteExternalComponent = useCallback(() => {
        modalContainer.open('settings-external-component-delete', <ExternalComponentDeletionModal
            externalComponents={[externalComponent]}
            onClose={() => modalContainer.close('settings-external-component-delete')}
            onSuccess={onActionSuccess}
        />);
    }, [externalComponent, onActionSuccess, modalContainer]);

    const actions = useMemo(() => {
        const newActions = [
            {
                key: 'edit',
                label: messages.edit,
                onClick: handleEditExternalComponent,
            },
        ];

        if (canExportExternalComponent) {
            newActions.push({
                key: 'export',
                label: messages.export,
                onClick: handleExportExternalComponent,
            });
        }

        newActions.push({
            key: 'delete',
            label: messages.delete,
            onClick: handleDeleteExternalComponent,
        });

        return newActions;
    }, [canExportExternalComponent, handleDeleteExternalComponent, handleEditExternalComponent, handleExportExternalComponent]);

    const actionsMenu = (
        <ArgMenu>
            {actions.map((action) => {
                return (
                    <ArgMenuItem
                        key={action.key}
                        onClick={async () => {
                            await action.onClick();
                            setVisible(!visible);
                        }}
                        label={action.label}
                    />
                );
            })}
        </ArgMenu>
    );

    return (
        <ArgButton
            className={classNames(className, '&-button')}
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={visible}
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
        />
    );
}
