import { defineMessages } from 'react-intl';
import { useCallback, useEffect, useState } from 'react';

import { ProgressMonitor, useArgNotifications, useCallbackAsync, useMemoAsync } from '../../components/basic';
import { ProceoGroupsConnector } from '../utils/connectors/proceo-groups-connector';
import { ProceoGroup } from '../model/group';


const messages = defineMessages({
    loadingGroups: {
        id: 'proceo.hooks.use-get-groups.loadingGroups',
        defaultMessage: 'Loading groups {threeDotsLoading}',
    },
    loadingGroupsError: {
        id: 'proceo.hooks.use-get-groups.loadingGroupsError',
        defaultMessage: 'Failed to load groups',
    },
});

export interface UseGetProceoGroupsAndServicesReturnType {
    groups?: ProceoGroup[];
    progressMonitor?: ProgressMonitor;
    refreshGroups(): void;
    error?: Error;
}

export function useGetProceoGroupsAndServices() {
    const notifications = useArgNotifications();
    const [stateId, setStateId] = useState<number>(0);

    const [groups, groupsProgressMonitor, groupsError] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const _groups = await ProceoGroupsConnector.getInstance().getFolders(undefined, false, false, undefined, progressMonitor);

            return _groups;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }
            notifications.snackError({ message: messages.loadingGroupsError }, error as Error);
            throw error;
        }
    }, [stateId], messages.loadingGroups);

    const refreshGroups = useCallback(() => {
        setStateId(prev => prev + 1);
    }, []);

    const ret: UseGetProceoGroupsAndServicesReturnType = {
        groups: groups,
        progressMonitor: groupsProgressMonitor,
        error: groupsError,
        refreshGroups: refreshGroups,
    };

    return ret;
}
