import { renderText } from 'src/components/basic/utils/message-descriptor-formatters';
import { ClassValue, useClassNames } from 'src/components/basic/arg-hooks/use-classNames';
import { ArgMessageValues, ArgRenderedText, ArgSize } from 'src/components/basic/types';

import './arg-message-renderer.less';

const CLASSNAME = 'arg-message-renderer';

export interface ArgMessageRendererProps {
    className?: ClassValue;
    message: ArgRenderedText;
    messageValues?: ArgMessageValues;
    size?: ArgSize;
    searchToken?: string;
}

export function ArgMessageRenderer(props: ArgMessageRendererProps) {
    const {
        className,
        message,
        messageValues,
        size = 'medium',
        searchToken,
        ...otherProps
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const messageAsReactNode = renderText(message, messageValues, searchToken);

    const cls = {
        [`size-${size}`]: true,
    };

    return (
        <div className={classNames('&', className, cls)} data-testid='arg-message-renderer' {...otherProps}>
            {messageAsReactNode}
        </div>
    );
}
