import EventEmitter from 'eventemitter3';
import Debug from 'debug';
import { forEach, remove } from 'lodash';

import { APIConnectorRequestInit, Connector } from './connector';

type URL = string;
type BlobURI = string;
export type BlobURIs = Record<URL, BlobURI>;

export interface BlobImagesProviderEventTypes {
    Change: (url: URL) => void;
}

const debug = Debug('common:utils:BlobImagesProvider');

const LOADING_URI = '**LOADING**';
const ERROR_URI = '**ERROR**';
const DESTROYED_URI = '**DESTROYED**';

export class BlobImagesProvider extends EventEmitter<BlobImagesProviderEventTypes> {
    #blobUris: BlobURIs = {};
    #destroyed = false;
    #stateId = 0;
    #runningFetchs: AbortController[] = [];

    constructor() {
        super();
    }

    async _fetch(url: URL, serverApi: string): Promise<BlobURI> {
        const myAbortController = new AbortController();
        this.#runningFetchs.push(myAbortController);

        debug('fetch', 'url=', url);

        const signal = myAbortController.signal;
        let blob: Blob;

        try {
            const fetchOptions: APIConnectorRequestInit =
                serverApi && url.includes(serverApi) ? {
                    credentials: 'include',
                    signal,
                    api: serverApi,
                } : {
                    signal,
                    api: serverApi,
                };
            blob = await Connector.getInstance().request(url, fetchOptions);
        } catch (error) {
            if (!signal.aborted) {
                throw error;
            }

            console.error('fetch aborted url=', url);

            throw error;
        } finally {
            remove(this.#runningFetchs, (f) => f === myAbortController);
        }

        if (this.#destroyed) {
            return DESTROYED_URI;
        }

        const blobURI = URL.createObjectURL(blob);
        this.#blobUris[url] = blobURI;

        return blobURI;
    }

    get blobURIs(): BlobURIs {
        return this.#blobUris;
    }

    get stateId(): number {
        return this.#stateId;
    }

    getBlobURI(url: URL, serverApi: string): string | undefined {
        if (this.#destroyed) {
            return undefined;
        }

        const blobUrl = this.#blobUris[url];
        if (blobUrl) {
            if (blobUrl === LOADING_URI || blobUrl === ERROR_URI || blobUrl === DESTROYED_URI) {
                return undefined;
            }

            return blobUrl;
        }

        this.#blobUris[url] = LOADING_URI;
        this._fetch(url, serverApi).then((blobURI: BlobURI) => {
            if (this.#destroyed) {
                return;
            }

            this.#stateId++;
            this.emit('Change', url);
        }, (err) => {
            if (this.#destroyed) {
                return;
            }

            this.#blobUris[url] = ERROR_URI;
            console.error(err);
        });

        return undefined;
    }

    destroy() {
        if (this.#destroyed) {
            throw new Error('Already destroyed !');
        }

        this.#destroyed = true;
        const blobUris = this.#blobUris;
        const runningFetchs = this.#runningFetchs;

        this.#blobUris = {};
        this.#runningFetchs = [];

        forEach(blobUris, (url) => {
            if (url === DESTROYED_URI || url === LOADING_URI || url === ERROR_URI) {
                return;
            }
            URL.revokeObjectURL(url);
        });
        forEach(runningFetchs, (runningFetch) => runningFetch.abort());
    }
}
