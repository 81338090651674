import { OntologyPropertyBaseType } from '../universes/ontology/types';

export function getPropertyIconName(propertyType: OntologyPropertyBaseType, possibleValues?: boolean) {
    switch (propertyType) {
        case OntologyPropertyBaseType.Bool:
            return 'icon-boolean';

        case OntologyPropertyBaseType.Date:
        case OntologyPropertyBaseType.DateTime:
            return 'icon-calendar';

        case OntologyPropertyBaseType.String:
        case OntologyPropertyBaseType.MultiString:
            if (!possibleValues) {
                return 'icon-text';
            }

            return 'icon-list';

        case OntologyPropertyBaseType.Text:
        case OntologyPropertyBaseType.Char:
        case OntologyPropertyBaseType.Guid:
        case OntologyPropertyBaseType.Email:
        case OntologyPropertyBaseType.Url:
        case OntologyPropertyBaseType.PhoneNumber:
        case OntologyPropertyBaseType.Address:
        case OntologyPropertyBaseType.Country:
            return 'icon-text';

        case OntologyPropertyBaseType.Geoshape:
            return 'icon-globe';

        case OntologyPropertyBaseType.Byte:
        case OntologyPropertyBaseType.Short:
        case OntologyPropertyBaseType.Int:
        case OntologyPropertyBaseType.Long:
        case OntologyPropertyBaseType.Double:
            return 'icon-numeric';

        case OntologyPropertyBaseType.Float:
            return 'icon-decimal';
    }

    return '';
}
