import { defineMessages } from 'react-intl';
import React, { lazy, Suspense } from 'react';
import { RouteProps } from 'react-router';
import { Navigate, Route } from 'react-router-dom';

import { registerArgonosModule } from '../components/application/modules-manager';
import { PREPARATION_ROOT_URL } from '../components/containers/routes-urls';
import { Environment } from '../utils/environment';
import { PREPARATION_PERMISSIONS_REQUIRED } from './permissions/permissions';
import { ArgonosModuleRouterProps } from '../components/containers/authenticated-apps-router';
import { LoadingSuspense } from '../components/containers/loading-suspense';
import { ArgonosModule } from '../components/application/modules';
import { getDataPreparationApi } from '../utils/connectors/api-url';
import { setupSettings } from './settings';

const FORCE_SUSPENSE_LOADING = false;

const LazyPreparationRouter = lazy(() => import('./preparation-router'));

const messages = defineMessages({
    dataPreparationApplication: {
        id: 'common.applications.DataPreparation',
        defaultMessage: 'Data Preparation',
    },
    loadingPreparation: {
        id: 'common.applications.LoadingDataPreparation',
        defaultMessage: 'Loading Data Preparation {threeDotsLoading}',
    },
});


function PreparationApp(props: ArgonosModuleRouterProps) {
    const { className } = props;

    if (FORCE_SUSPENSE_LOADING) {
        return <LoadingSuspense message={messages.loadingPreparation} />;
    }

    return (
        <Suspense fallback={<LoadingSuspense message={messages.loadingPreparation} />}>
            <LazyPreparationRouter
                className={className}
            />
        </Suspense>
    );
}


const PREPARATION_MODULE: ArgonosModule = {
    id: 'chapsVision.DataPreparation',
    subType: 'DataPreparation',
    enabled: Environment.isDataPreparationEnabled(),

    routeURL: PREPARATION_ROOT_URL,
    requiredPermissions: PREPARATION_PERMISSIONS_REQUIRED,
    apiURL: getDataPreparationApi(),
    testURL: '/folders',

    iconURL: Environment.dataPreparationIcon,
    name: messages.dataPreparationApplication,
    color: '#36646C',
    documentationURL: Environment.docServerUrlDataPrep,

    getRoutes(): React.ReactElement<RouteProps>[] {
        return [
            <Route
                key='preparation'
                path='preparation'
                element={<Navigate to='./folders' replace={true} />}
            />,
            <Route
                key='preparation/*'
                path='preparation/*'
                element={(
                    <PreparationApp argonosModule={PREPARATION_MODULE} />
                )}
            />,
        ];
    },

    hasRoles: true,
    hasUserRoles: true,
    hasGroupRoles: true,
    hasUserPermissions: true,
    scope: 'data_preparation',
    hasEnvironments: true,
};

registerArgonosModule(PREPARATION_MODULE);

setupSettings(PREPARATION_MODULE);
