import { ClassValue, renderText, useClassNames } from 'src/components/basic';
import { ConfigurationImportError } from '../../../../model/configuration';
import { DEFINITION_ERRORS_MESSAGE, DEFINITION_ERRORS_TYPE_MESSAGE, DEFINITION_ERRORS_CODE_MESSAGE, configurationImportErrorsMessages } from '../../configuration-import-errors-messages';

import './configuration-error-notification-description.less';

const CLASSNAME = 'settings-configuration-error-notification-description';

export interface ConfigurationErrorNotificationDescriptionProps {
    className?: ClassValue;
    errors: ConfigurationImportError[];
}

export function ConfigurationErrorNotificationDescription(props: ConfigurationErrorNotificationDescriptionProps) {
    const { className, errors } = props;
    const classNames = useClassNames(CLASSNAME);

    return (
        <div className={classNames('&', className)}>
            {errors.map((error: ConfigurationImportError) => {
                const errorType = error.type && renderText(DEFINITION_ERRORS_TYPE_MESSAGE[error.type]);

                return (
                    <div key={error.id} className={classNames('&-import-error-container')}>
                        <span className={classNames('&-import-error-type')}>{errorType}</span>

                        {error.configurationError && <div className={classNames('&-import-error')}>
                            {error.id && renderText(configurationImportErrorsMessages.configurationItemId, { id: error.id })}
                            {renderText(DEFINITION_ERRORS_MESSAGE[error.configurationError], { type: errorType })}
                            {error.code && renderText(DEFINITION_ERRORS_CODE_MESSAGE[error.code])}
                        </div>}
                    </div>
                );
            })}
        </div>
    );
}
