import React, { ReactNode } from 'react';
import { FormattedMessage, IntlShape, MessageDescriptor } from 'react-intl';
import { isArray, isFunction, isString } from 'lodash';

import { isMessageDescriptor } from './is-message-descriptor';
import { ThreeDotsLoading } from '../arg-loading/three-dots-loading';
import { ArgIcon } from '../arg-icon/arg-icon';
import { isPromise, PromiseComponent } from './promise';
import { ArgMessageValues, ArgRenderedText, ArgRenderFunction } from '../types';
import { highlightSplit } from '../utils';

import './message-descriptor-formatters.less';

const $$MERGED_MESSAGE_DESCRIPTOR = '$$mergedMessageDescriptor$$';

export const MESSAGE_DESCRIPTOR_FORMATTERS: ArgMessageValues = {
    [$$MERGED_MESSAGE_DESCRIPTOR]: true,

    grey: function RenderGrey(content: ReactNode) {
        return (
            <span className='arg-message-descriptor-grey'>
                {content}
            </span>
        );
    },
    underline: function RenderUnderline(content: ReactNode) {
        return (
            <span className='arg-message-descriptor-underline'>
                {content}
            </span>
        );
    },
    newline: renderNewLine,
    br: renderNewLine,
    regular: function RenderRegular(content: ReactNode) {
        return (
            <span className='arg-message-descriptor-regular'>
                {content}
            </span>
        );
    },
    medium: function RenderMedium(content: ReactNode) {
        return (
            <span className='arg-message-descriptor-medium'>
                {content}
            </span>
        );
    },
    p: function renderParagraph(content: ReactNode) {
        return <p>{content}</p>;
    },
    bold: RenderBold,
    b: RenderBold,
    i: RenderItalic,
    ul: RenderUl,
    li: RenderLi,
    ol: RenderOl,
    italic: RenderItalic,
    oblique: RenderOblique,
    threeDotsLoading: <ThreeDotsLoading />,
    ThreeDotsLoading: function RenderThreeDotsLoading(content: ReactNode) {
        return (
            <ThreeDotsLoading />
        );
    },
    icon: renderIcon,
    highlight: function renderHighlight(content: ReactNode) {
        return (
            <span className='arg-message-descriptor-highlight'>
                {content}
            </span>
        );
    },
    error: RenderError,
    warning: RenderWarning,
    info: RenderInfo,
    valid: RenderValid,
    required: RenderRequired,
    primary: RenderPrimary,
    secondary: RenderSecondary,
    smaller: RenderSmaller,
};

export function renderNewLine(): ReactNode {
    return <br />;
}

export function computeText(intl: IntlShape, text?: string | MessageDescriptor, messageValues?: ArgMessageValues): string | undefined {
    if (text === undefined) {
        return undefined;
    }

    if (isString(text)) {
        return text;
    }

    const ret = intl.formatMessage(text, messageValues);

    return ret;
}

export function renderText(text: ArgRenderedText, messageValues?: ArgMessageValues, searchToken?: string): ReactNode {
    if (isPromise(text)) {
        const msg = <PromiseComponent
            promise={text.then(val => renderText(val, messageValues))}
        />;

        return msg;
    }

    if (isFunction(text)) {
        const msg = (text as ArgRenderFunction)();

        return renderText(msg, messageValues, searchToken);
    }


    if (isMessageDescriptor(text)) {
        const msgValues = getMessageValuesWithFormatters(messageValues);

        const msg = <FormattedMessage {...text} values={msgValues} />;

        return msg;
    }

    if (isString(text)) {
        if (searchToken === undefined) {
            return text;
        }

        const msg = highlightSplit(text, searchToken);

        return msg;
    }

    return text;
}


function RenderError(content: ReactNode) {
    return (
        <span className='arg-message-descriptor-error'>
            {content}
        </span>
    );
}

function RenderWarning(content: ReactNode) {
    return (
        <span className='arg-message-descriptor-warning'>
            {content}
        </span>
    );
}

function RenderInfo(content: ReactNode) {
    return (
        <span className='arg-message-descriptor-info'>
            {content}
        </span>
    );
}

function RenderValid(content: ReactNode) {
    return (
        <span className='arg-message-descriptor-valid'>
            {content}
        </span>
    );
}

function RenderRequired(content: ReactNode) {
    return (
        <span className='arg-message-descriptor-required'>
            {content}
        </span>
    );
}

function RenderPrimary(content: ReactNode) {
    return (
        <span className='arg-message-descriptor-primary'>
            {content}
        </span>
    );
}

function RenderSecondary(content: ReactNode) {
    return (
        <span className='arg-message-descriptor-secondary'>
            {content}
        </span>
    );
}

function RenderBold(content: ReactNode) {
    return (
        <span className='arg-message-descriptor-bold'>
            {content}
        </span>
    );
}

function RenderItalic(content: ReactNode) {
    return (
        <span className='arg-message-descriptor-italic'>
            {content}
        </span>
    );
}

function RenderUl(content: ReactNode) {
    return (
        <ul className='arg-message-descriptor-ul'>
            {content}
        </ul>
    );
}

function RenderOl(content: ReactNode) {
    return (
        <ol className='arg-message-descriptor-ol'>
            {content}
        </ol>
    );
}

function RenderLi(content: ReactNode) {
    return (
        <li className='arg-message-descriptor-li'>
            {content}
        </li>
    );
}

function RenderOblique(content: ReactNode) {
    return (
        <span className='arg-message-descriptor-oblique'>
            {content}
        </span>
    );
}

function RenderSmaller(content: ReactNode) {
    return (
        <span className='arg-message-descriptor-smaller'>
            {content}
        </span>
    );
}

function renderIcon(iconName: ReactNode) {
    if (isArray(iconName) && iconName.length === 1) {
        iconName = iconName[0];
    }
    console.log('Icon name=', iconName);
    if (!isString(iconName)) {
        return '***Invalid Icon name***';
    }

    return <ArgIcon
        name='icon-error'
        className='arg-message-descriptor-icon'
    />;
}

export function getMessageValuesWithFormatters(messageValues?: ArgMessageValues): ArgMessageValues {
    if (!messageValues?.[$$MERGED_MESSAGE_DESCRIPTOR]) {
        return { ...MESSAGE_DESCRIPTOR_FORMATTERS, ...messageValues };
    }

    return messageValues;
}
