import { DrawMap } from 'leaflet';
import { useCallback, useRef, useState } from 'react';
import { Chart } from 'regraph';

import { ChartTool } from 'src/exploration/model/chart-tool';

type UseGraphPanningReturn = [
    boolean,
    (event: React.MouseEvent<Chart, MouseEvent>) => void,
    (event: React.MouseEvent<Chart, MouseEvent>) => void,
    (event: React.KeyboardEvent<Chart>) => void,
    (event: React.KeyboardEvent<Chart>) => void,
];

export function useGraphPanning(
    activeTool: ChartTool,
    setActiveTool?: (tool: ChartTool) => void,
    mapControl?: DrawMap | undefined
): UseGraphPanningReturn {
    // Remember the tool before force pan
    const toolBeforePan = useRef<ChartTool>();
    const [forcePan, setForceSpan] = useState(false);

    const handleMouseDown = useCallback((event: React.MouseEvent<Chart, MouseEvent>) => {
        // middle mouse click
        if (event.button === 1 && !toolBeforePan.current) {
            toolBeforePan.current = activeTool;
            setForceSpan(true);
            setActiveTool?.(ChartTool.Hand);
            // Redispatch event cause leaflet detach / atach event on enable/disable
            mapControl?.getContainer().dispatchEvent(new MouseEvent('mousedown', event.nativeEvent));
        }
    }, [activeTool, setActiveTool, mapControl]);

    const handleMouseUp = useCallback((event: React.MouseEvent<Chart, MouseEvent>) => {
        if (event.button === 1 && toolBeforePan.current) {
            setForceSpan(false);
            setActiveTool?.(toolBeforePan.current);
            toolBeforePan.current = undefined;
        }
    }, [toolBeforePan, setActiveTool]);

    const handleKeyDown = useCallback((event: React.KeyboardEvent<Chart>) => {
        if (event.code === 'Space' && !toolBeforePan.current) {
            toolBeforePan.current = activeTool;
            setForceSpan(true);
            setActiveTool?.(ChartTool.Hand);
        }
    }, [activeTool, setActiveTool]);

    const handleKeyUp = useCallback((event: React.KeyboardEvent<Chart>) => {
        if (event.code === 'Space' && toolBeforePan.current) {
            setForceSpan(false);
            setActiveTool?.(toolBeforePan.current);
            toolBeforePan.current = undefined;
        }
    }, [toolBeforePan, setActiveTool]);

    return [forcePan, handleMouseDown, handleMouseUp, handleKeyDown, handleKeyUp];
}
