import { useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { FormikHelpers, useFormik } from 'formik';

import {
    ArgButton,
    ArgInputSearch,
    ArgInputText,
    ArgInputTextArea,
    ArgModal,
    ArgTabsSubLevel,
    ArgUserId,
    ProgressMonitor,
    SubProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { UsersList } from '../users-list';
import { GroupsList } from '../groups-list';
import { EditGroupDTO, Role, RolesScope } from 'src/settings/models/dtoApi';
import { getRolesFromRoleIds } from 'src/settings/users/utils';
import { Group, User } from 'src/model/user';
import { ArgFormLabel } from '../../../../components/basic';
import { AssignRoles } from 'src/settings/roles/components/assign-roles';
import userGroupsConnector from 'src/settings/connectors/user-groups-connector';
import { SettingsConnector } from '../../../connectors/settings-connector';

import './edit-group-modal.less';

export const messages = defineMessages({
    title: {
        id: 'settings.edit-group-modal.title',
        defaultMessage: 'Edit group',
    },
    description: {
        id: 'settings.edit-group-modal.description',
        defaultMessage:
            'Please supply the following information. The fields with an asterisk are required',
    },
    fieldName: {
        id: 'settings.edit-group-modal.field.name',
        defaultMessage: 'Name',
    },
    required: {
        id: 'settings.edit-group-modal.required',
        defaultMessage: 'Required',
    },
    fieldDescription: {
        id: 'settings.edit-group-modal.field.description',
        defaultMessage: 'Description',
    },
    fieldRoles: {
        id: 'settings.edit-group-modal.field.roles',
        defaultMessage: 'Roles',
    },
    submit: {
        id: 'settings.edit-group-modal.submitButton',
        defaultMessage: 'Modify',
    },
    cancel: {
        id: 'settings.edit-group-modal.cancelButton',
        defaultMessage: 'Cancel',
    },
    editGroupErrorMsg: {
        id: 'settings.edit-group-modal.error-message.editing-group',
        defaultMessage: 'Something went wrong while editing the group',
    },
    fetchingGroupMembersError: {
        id: 'settings.edit-group-modal.error-message.fetching-group-members-error',
        defaultMessage: 'Something went wrong while fetching group members',
    },
    tabMembers: {
        id: 'settings.edit-group-modal.tab-members',
        defaultMessage: 'Members',
    },
    tabGroups: {
        id: 'settings.edit-group-modal.tab-groups',
        defaultMessage: 'Groups',
    },
    nameTaken: {
        id: 'settings.edit-group-modal.nameTaken',
        defaultMessage: 'Group names must be unique.',
    },
    loadRolesError: {
        id: 'settings.edit-group-modal.loadRolesError',
        defaultMessage: 'An error has occured while loading roles',
    },
});

interface FormFields {
    name?: string;
    description?: string;
    roleIds?: string[];
    groupIds: string[];
    userIds: ArgUserId[];
    ownerIds: string[];
}

type FormFieldsErrors = Partial<Record<keyof FormFields, boolean>>;

export interface EditModalProps {
    closeModal: () => void;
    group: Group;
    users: User[];
    groups: Group[];
    userRoles: Role[];
    setGroups: React.Dispatch<React.SetStateAction<Group[]>>;
}

enum Tabs {
    Users = 'users',
    Groups = 'groups',
}

export const EditGroupModal = (props: EditModalProps) => {
    const {
        group,
        closeModal,
        users,
        groups,
        userRoles,
        setGroups,
    } = props;

    const intl = useIntl();
    const notifications = useArgNotifications();

    const [activeTab, setActiveTab] = useState<string>('users');
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState<string>();

    const handleSearch = useCallback((value: string) => {
        setSearch(value);
    }, []);

    const classNames = useClassNames('settings-edit-group-modal');

    const [currentGroupIds, setCurrentGroupIds] = useState<Array<string>>([]);
    const [currentUserIds, setCurrentUserIds] = useState<Array<ArgUserId>>([]);
    const [fetchingMembers, setFetchingMembers] = useState(true);
    const [groupRoles, setGroupRoles] = useState<Role[]>();
    const [ancestorIds, setAncestorIds] = useState<Array<string>>([]);

    const [loadRoles, progressMonitorRoles] = useCallbackAsync(async (progressMonitor?: ProgressMonitor) => {
        if (!group) {
            return;
        }

        try {
            const groupRoles = await SettingsConnector.getInstance().getAllGroupRoles(group.id, progressMonitor);
            setGroupRoles(groupRoles);
        } catch (error) {
            if (progressMonitor?.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.loadRolesError }, error as Error);
        }
    }, [group, notifications]);

    useEffect(() => {
        if (group) {
            loadRoles();
        }
    }, [group, loadRoles]);

    useEffect(() => {
        Promise.all([
            userGroupsConnector.getGroupMemberships(group.id, true),
            userGroupsConnector.getGroupMemberships(group.id, false),
        ]).then(([inheritedAncestors, directAncestors]) => {
            setAncestorIds([...inheritedAncestors, ...directAncestors]);
        });
    }, []);

    useEffect(() => {
        userGroupsConnector
            .getGroupMembers(group.id)
            .then((res) => {
                setCurrentGroupIds(res.groups.map((group) => group.id));
                setCurrentUserIds(res.users.map((user) => user.id));
            })
            .catch((e: Error) => {
                notifications.snackError({ message: messages.fetchingGroupMembersError }, e as Error);
            });
        setFetchingMembers(false);
    }, []);

    const validateForm = (values: FormFields) => {
        const errors: FormFieldsErrors = {};
        if (!values.name) {
            errors.name = true;
        }

        return errors;
    };

    const [submitForm] = useCallbackAsync(async (progressMonitor: ProgressMonitor, values: FormFields, { resetForm }: FormikHelpers<FormFields>) => {
        if (!values.name) {
            return;
        }
        setLoading(true);
        const editGroupPayload: EditGroupDTO = {
            name: values.name,
            description: values.description,
            ownerIds: [],
        };
        try {
            const groupIdsToAdd = values.groupIds.filter((id) => !currentGroupIds.includes(id));
            const groupIdsToRemove = currentGroupIds.filter((id) => !values.groupIds.includes(id));
            const userIdsToAdd = values.userIds.filter((id) => !currentUserIds.includes(id));
            const userIdsToRemove = currentUserIds.filter((id) => !values.userIds.includes(id));

            const groupApiCalls = [];

            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            await userGroupsConnector.editGroup(editGroupPayload, group.id, sub1);

            if (groupIdsToAdd.length > 0 || userIdsToAdd.length > 0) {
                const sub2 = new SubProgressMonitor(progressMonitor, 1);
                groupApiCalls.push(
                    userGroupsConnector.addMembersToGroup(
                        { groupIds: groupIdsToAdd, userIds: userIdsToAdd },
                        group.id,
                        sub2
                    )
                );
            }
            if (groupIdsToRemove.length > 0) {
                const sub3 = new SubProgressMonitor(progressMonitor, 1);
                groupApiCalls.push(
                    ...groupIdsToRemove.map((groupId) => {
                        return userGroupsConnector.deleteGroupFromGroup(group.id, groupId, sub3);
                    })
                );
            }
            if (userIdsToRemove.length > 0) {
                const sub4 = new SubProgressMonitor(progressMonitor, 1);
                groupApiCalls.push(
                    ...groupApiCalls,
                    ...userIdsToRemove.map((userId) => {
                        return userGroupsConnector.deleteUserFromGroup(group.id, userId, sub4);
                    })
                );
            }

            const roleIdsToAdd = groupRoles ? values.roleIds?.filter(roleId => groupRoles.findIndex(role => role.id === roleId) < 0) : values.roleIds;
            const rolesToAdd = getRolesFromRoleIds(userRoles, roleIdsToAdd);
            const rolesToDelete = groupRoles?.filter(role => !values.roleIds?.includes(role.id));
            const rolesToUpdate = rolesToAdd ? rolesToDelete ? [...rolesToAdd, ...rolesToDelete] : rolesToAdd : rolesToDelete;

            if (!rolesToUpdate) {
                return;
            }

            const rolesScopes = [...new Set(rolesToUpdate.map(role => role.scope as RolesScope))];

            groupApiCalls.push(...rolesScopes.map((scope) => {
                const sub5 = new SubProgressMonitor(progressMonitor, 1);
                const scopedRoleIds = rolesToUpdate.filter(role => role.scope === scope).map(role => role.id);

                return SettingsConnector.getInstance().editGroupRoles(group.id, scopedRoleIds, scope, sub5);
            }));

            await Promise.all(groupApiCalls);
            const sub6 = new SubProgressMonitor(progressMonitor, 1);
            const newGroup = await userGroupsConnector.getGroup(group.id, sub6);
            setGroups((currentGroups) =>
                currentGroups.map((existingGroup) => {
                    return existingGroup.id === group.id ? newGroup : existingGroup;
                })
            );
            resetForm();
            closeModal();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.editGroupErrorMsg }, error as Error);
        }
        setLoading(false);
    }, [closeModal, currentGroupIds, currentUserIds, setGroups, group.id, userRoles, notifications, groupRoles]);

    const {
        handleSubmit,
        handleChange,
        setFieldValue,
        values: formValues,
        errors: formErrors,
    } = useFormik<FormFields>({
        initialValues: {
            name: group.name,
            description: group.description,
            roleIds: groupRoles?.map((groupRole) => groupRole.id),
            ownerIds: [],
            groupIds: currentGroupIds,
            userIds: currentUserIds,
        },
        validateOnChange: false,
        validate: validateForm,
        onSubmit: submitForm,
        enableReinitialize: true,
    });

    const tabs = [
        {
            key: Tabs.Users,
            title: intl.formatMessage(messages.tabMembers),
        },
        {
            key: Tabs.Groups,
            title: intl.formatMessage(messages.tabGroups),
        },
    ];

    const duplicateName =
        !!formValues.name &&
        !!groups
            .filter((groupInArray) => groupInArray.id !== group.id)
            .map((group) => group.name)
            .includes(formValues.name);

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={closeModal}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={closeModal}
                        label={messages.cancel}
                        disabled={loading}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        label={messages.submit}
                        data-testid='edit'
                        onClick={() => handleSubmit()}
                        disabled={duplicateName || loading}
                        loading={loading}
                        tooltip={duplicateName ? intl.formatMessage(messages.nameTaken) : undefined}
                    />
                </div>
            }
        >
            <form autoComplete='off' onSubmit={() => handleSubmit()}>
                <p>{intl.formatMessage(messages.description)}</p>

                <ArgFormLabel
                    propertyName={messages.fieldName}
                    addedRow={true}
                    required={messages.required}
                >
                    <ArgInputText
                        autoFocus={true}
                        data-testid='name'
                        value={formValues.name}
                        state={formErrors.name ? 'invalid' : undefined}
                        onInputChange={handleChange('name')}
                    />
                </ArgFormLabel>
                <ArgFormLabel
                    propertyName={messages.fieldDescription}
                    addedRow={true}
                >
                    <ArgInputTextArea
                        value={formValues.description}
                        state={formErrors.description ? 'invalid' : undefined}
                        onInputChange={handleChange('description')}
                        data-testid='description'
                    />
                </ArgFormLabel>

                <div className={classNames('&-list-top-bar')}>
                    <ArgTabsSubLevel
                        className={classNames('&-tabs')}
                        tabs={tabs}
                        activeTabKey={activeTab}
                        onChange={(tabKey) => {
                            tabKey && setActiveTab(tabKey);
                        }}
                    />
                    <ArgInputSearch
                        onInputChange={handleSearch}
                        className={classNames('&-search-bar')}
                    />
                </div>
                {activeTab === Tabs.Users && !fetchingMembers && (
                    <div className={classNames('&-list')}>
                        <UsersList
                            userIdsSelected={formValues.userIds}
                            handleCheckboxSelection={(checked: boolean, id: string) => {
                                if (checked) {
                                    setFieldValue('userIds', [...formValues.userIds, id]);
                                } else {
                                    setFieldValue(
                                        'userIds',
                                        formValues.userIds.filter((userId) => userId !== id)
                                    );
                                }
                            }}
                            users={users}
                            search={search!}
                        />
                    </div>
                )}
                {activeTab === Tabs.Groups && !fetchingMembers && (
                    <div className={classNames('&-list')}>
                        <GroupsList
                            groupIdsSelected={formValues.groupIds}
                            groupIdsDisabled={[...ancestorIds, group.id]}
                            handleCheckboxSelection={(checked: boolean, id: string) => {
                                if (checked) {
                                    setFieldValue('groupIds', [...formValues.groupIds, id]);
                                } else {
                                    setFieldValue(
                                        'groupIds',
                                        formValues.groupIds.filter((groupId) => groupId !== id)
                                    );
                                }
                            }}
                            groups={groups}
                            search={search!}
                            selectedGroupId={group.id}
                            selectedGroupName={group.name}
                        />
                    </div>
                )}
                <ArgFormLabel propertyName={intl.formatMessage(messages.fieldRoles)} addedRow={true}>
                    {!progressMonitorRoles?.isRunning && <AssignRoles
                        allRoles={userRoles}
                        value={groupRoles || []}
                        onChange={(roleNamesSelected) =>
                            setFieldValue('roleIds', roleNamesSelected.map((role) => role.id))
                        }
                    />}
                </ArgFormLabel>
            </form>
        </ArgModal>
    );
};
