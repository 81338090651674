import { isEqual, size } from 'lodash';
import { defineMessages, FormattedMessage, FormattedNumber } from 'react-intl';
import React, { useCallback, useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { ControlProps } from '../controls-type';
import { UndefinedButton } from '../undefined-button';
import { ArgChangeReason, ThreeDotsLoading, useClassNames } from '../../../basic';
import { RangeDataModel } from './range';
import { AddField } from '../add-property-field';
import { SingleRangePicker } from './single-range-picker-control';
import { SelectionCount } from '../selection-count';
import { RangePickerControlHistogram } from './range-picker-control-histogram';

import './range-picker-control.less';

const messages = defineMessages({
    noData: {
        id: 'common.controls.range-picker.NoData',
        defaultMessage: 'No data',
    },
});

export interface RangePickerDataModel {
    ranges?: RangeDataModel<number>[];
    includeUndefined?: boolean;
}

type RangePickerControlProps = ControlProps<RangePickerDataModel>;

export const RangePickerControl: React.FunctionComponent<RangePickerControlProps> = ({
    value,
    onChange,
    propertyInfo,
    propertyInfoLoading,
    onReset,
    forceRange,
    showUndefined,
    backgroundColor: bgColor,
    acceptsParameters,
    readOnly,
}) => {
    const classNames = useClassNames('arg-RangePickerControl');

    const [isChecked, setChecked] = useState<boolean>(false);

    const rangeLowerBound = propertyInfo?.minValue;
    const rangeUpperBound = propertyInfo?.maxValue;
    const valueSpread = propertyInfo?.valueSpread;

    const handleAddAdditionalRangePicker = useCallback(() => {
        const newRange: RangeDataModel<number> = {
            lowerBound: {
                included: true,
                value: undefined,
            },
            upperBound: {
                included: true,
                value: undefined,
            },
        };

        onChange((prev) => {
            if (!prev?.ranges) {
                return {
                    ranges: [newRange],
                    includeUndefined: prev?.includeUndefined,
                };
            }

            return {
                ...prev,
                ranges: [...prev.ranges, newRange],
            };
        }, 'selection');
    }, [onChange]);

    const handleUndefinedButtonChange = useCallback((newValue: boolean) => {
        onChange((prev) => {
            return {
                ...prev,
                includeUndefined: newValue,
            };
        }, 'selection');

        setChecked(newValue);
    }, [onChange]);

    const handleSingleRangePickerTrashClick = useCallback((index: number) => {
        onChange((prev) => {
            if (!prev) {
                return prev;
            }

            return {
                ...prev,
                ranges: prev.ranges?.filter((r, i) => (index !== i)),
            };
        }, 'selection');
    }, [onChange]);

    const handleRangeItemChange = useCallback((index: number, setValue: (prev: RangeDataModel<number>) => RangeDataModel<number>, reason: ArgChangeReason) => {
        onChange((prev) => {
            let newValue = prev;
            const range = newValue?.ranges?.[index];
            if (!range) {
                newValue = (newValue) ? { ...newValue } : {};
                if (!newValue.ranges) {
                    newValue.ranges = [];
                }
                const newRange = setValue({
                    lowerBound: {
                        included: true,
                        value: undefined,
                    },
                    upperBound: {
                        included: true,
                        value: undefined,
                    },
                });
                newValue.ranges[index] = newRange;

                return newValue;
            }

            const newRange = setValue(range);
            if (isEqual(newRange, range)) {
                return prev;
            }

            if (!newValue) {
                newValue = {};
            }

            newValue = {
                ...newValue,
                ranges: (newValue.ranges ? [...newValue.ranges] : []),
            };
            newValue.ranges![index] = newRange!;

            return newValue;
        }, reason);
    }, [onChange]);

    useEffect(() => {
        if (!value?.ranges) {
            handleAddAdditionalRangePicker();
        }
    }, [handleAddAdditionalRangePicker, value?.ranges]);

    if (propertyInfoLoading?.isRunning) {
        return <div className={classNames('&', '&-waiting')}>
            {propertyInfoLoading?.isRunning &&
                <ThreeDotsLoading className='loading' />
            }
            {!propertyInfoLoading?.isRunning &&
                <FormattedMessage {...messages.noData} />
            }
        </div>;
    }

    const count = propertyInfo?.numberOfMissing ?? 0;
    const totalCount = count + (propertyInfo?.numberOfValues ?? 0);
    const countPercent = totalCount ? ((count || 0) / totalCount * 100) : 0;

    return (
        <TransitionGroup className={classNames('&', '&-transition-group')}>
            {valueSpread && (
                <CSSTransition key='histogram' timeout={200} classNames='arg-universe-filter-anim-item'>
                    <RangePickerControlHistogram key='histogram' valueSpread={valueSpread}
                                                 className={classNames('&-histogram', { 'has-multi-ranges': size(value?.ranges) > 1 })} />
                </CSSTransition>
            )}
            {!!(propertyInfo?.numberOfValues) && propertyInfo.numberOfValues > 0 && (
                <div className={classNames('&-ranges')}>
                    {value?.ranges?.map((rangePickerItem, index) => {
                        return <CSSTransition key={index} timeout={200} classNames='arg-universe-filter-anim-item'>
                            <SingleRangePicker
                                canDelete={(value?.ranges?.length ?? 0) > 1}
                                forceRange={forceRange}
                                rangeLowerBound={rangeLowerBound}
                                rangeUpperBound={rangeUpperBound}
                                onChange={(setValue, reason) => handleRangeItemChange(index, setValue, reason)}
                                value={rangePickerItem}
                                onReset={onReset}
                                onDelete={() => handleSingleRangePickerTrashClick(index)}
                                acceptsParameters={acceptsParameters}
                                readOnly={readOnly}
                            />
                        </CSSTransition>;
                    })}
                    <AddField
                        handleAddPropertyField={handleAddAdditionalRangePicker}
                        className={classNames('&-ranges-add-new-range')}
                        disabled={readOnly}
                    />
                </div>
            )}
            <CSSTransition key='add-range' timeout={200} classNames='arg-universe-filter-anim-item'>
                <div className={classNames('&-add-range', { 'hide-undefined': showUndefined === false })}>
                    <UndefinedButton
                        className={classNames('&-undefined-button')}
                        value={value?.includeUndefined ?? false}
                        onChange={handleUndefinedButtonChange}
                        disabled={readOnly}
                    />
                    <SelectionCount
                        count={count}
                        total={<FormattedNumber value={totalCount ?? 0} notation='compact' />}
                        querySearch=''
                        bgColor={bgColor}
                        hideBar={!isChecked}
                        isLoading={propertyInfoLoading?.isRunning}
                        barClassNames=''
                        countPercent={countPercent}
                    />
                </div>
            </CSSTransition>
        </TransitionGroup>
    );
};
