import React from 'react';
import tinycolor, { ColorFormats } from 'tinycolor2';
import { debounce } from 'lodash';

import {
    ArgChangeReason,
    ArgInputInteger,
    ArgInputText,
    ClassValue,
    useClassNames,
} from '../../../../basic';
import { rgbIndexValueValidator } from '../../../../../utils/validators';

import './color-palette-picker-inputs.less';

interface ColorPalettePickerInputsProps {
    className?: ClassValue;
    hex?: string;
    rgb: ColorFormats.RGBA;

    onHexChange?: (color: string | ColorFormats.RGBA) => void;
    onRgbChange?: (color: string | ColorFormats.RGBA) => void;
}

export function ColorPalettePickerInputs(props: ColorPalettePickerInputsProps) {
    const {
        className,
        hex,
        rgb,
        onHexChange,
        onRgbChange,
    } = props;

    const classNames = useClassNames('color-palette-picker-inputs');

    const onHexInputChange = (color: string | null) => {
        if (color) {
            const clr = tinycolor(color);

            if (onHexChange && clr.isValid()) {
                onHexChange(clr.toHexString());
            }
        }
    };
    const onRGBSingleComponentChange = (rgbColorIndex: 'r' | 'g' | 'b') => {
        return (value: number | null, _: ArgChangeReason) => {
            if (value !== null) {
                const validValue = rgbIndexValueValidator(value);
                const rbgObj = { r: rgb?.r, g: rgb?.g, b: rgb?.b, a: rgb?.a };
                switch (rgbColorIndex) {
                    case 'r':
                        rbgObj.r = validValue;
                        break;
                    case 'g':
                        rbgObj.g = validValue;
                        break;

                    default:
                        rbgObj.b = validValue;
                        break;
                }

                onRgbChange?.(rbgObj);
            }
        };
    };

    return (
        <div className={classNames('&', className)}>
            <ArgInputText
                value={hex}
                clearable={false}
                className={classNames('&-hex-color-code-value')}
                onInputChange={debounce(onHexInputChange, 500)}
            />
            <ArgInputInteger
                value={rgb?.r}
                clearable={false}
                className={classNames('&-rgb-color-code-red-value')}
                displayRightControl={false}
                onChange={onRGBSingleComponentChange('r')}
            />
            <ArgInputInteger
                value={rgb?.g}
                clearable={false}
                className={classNames('&-rgb-color-code-green-value')}
                displayRightControl={false}
                onChange={onRGBSingleComponentChange('g')}
            />
            <ArgInputInteger
                value={rgb?.b}
                clearable={false}
                className={classNames('&-rgb-color-code-blue-value')}
                displayRightControl={false}
                onChange={onRGBSingleComponentChange('b')}
            />
            <span className={classNames('&-hex-color-code-label')}>HEX</span>
            <span className={classNames('&-rgb-color-code-red-label')}>R</span>
            <span className={classNames('&-rgb-color-code-green-label')}>G</span>
            <span className={classNames('&-rgb-color-code-blue-label')}>B</span>
        </div>
    );
}
