export interface PreparationPermissions {
    'preparation.create.folder': true,
    'preparation.folder.export': true,
    'preparation.process.access': true,
    'preparation.process.edition': true,
    'preparation.process.export': true,
    'preparation.process.execution': true,
    'preparation.process.execution.management': true,
    'preparation.process.prioritization.access': true,
    'preparation.process.prioritization.edition': true,
    'preparation.process.active.components.access': true,
    'preparation.composite.component.access': true,
    'preparation.composite.component.edition': true,
    'preparation.composite.component.export': true,
    'preparation.remote.component.access': true,
    'preparation.remote.component.edition': true,
    'preparation.remote.component.export': true,
    'preparation.secret.access': true,
    'preparation.secret.edition': true,
    'preparation.secret.export': true,
    'preparation.code.fragment.access': true,
    'preparation.code.fragment.edition': true,
    'preparation.code.fragment.export': true,
    'preparation.worker.access': true,
    'preparation.worker.edition': true,
    'preparation.worker.administration': true,
}

export const PREPARATION_PERMISSIONS_KEYS: (keyof PreparationPermissions)[] = [
    'preparation.create.folder',
    'preparation.folder.export',
    'preparation.process.access',
    'preparation.process.edition',
    'preparation.process.export',
    'preparation.process.execution',
    'preparation.process.execution.management',
    'preparation.process.prioritization.access',
    'preparation.process.prioritization.edition',
    'preparation.composite.component.access',
    'preparation.composite.component.edition',
    'preparation.composite.component.export',
    'preparation.remote.component.access',
    'preparation.remote.component.edition',
    'preparation.remote.component.export',
    'preparation.secret.access',
    'preparation.secret.edition',
    'preparation.secret.export',
    'preparation.code.fragment.access',
    'preparation.code.fragment.edition',
    'preparation.code.fragment.export',
];

export const PREPARATION_PERMISSIONS_REQUIRED = PREPARATION_PERMISSIONS_KEYS;
