import { defineMessages } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
    ArgButton,
    ArgModal,
    ProgressMonitor,
    SubProgressMonitor,
    useCallbackAsync,
    useClassNames, useArgNotifications,
} from 'src/components/basic';
import { NotifyBox } from 'src/settings/common-modals/notify-box/notify-box';
import { WARNING_ORANGE } from 'src/settings/models/colors';
import ontologiesConnector from '../../../../connectors/ontologies-connector';
import { UNIVERSE_MANAGEMENT_ROUTE } from 'src/settings/universes/ontology/ontology-router';

import './single-universe-delete-modal.less';

export const messages = defineMessages({
    title: {
        id: 'settings.delete-universe-modal.title',
        defaultMessage: 'Delete a universe',
    },
    description: {
        id: 'settings.delete-universe-modal.description',
        defaultMessage: 'Are you sure you want to delete the universe?',
    },
    subDescription: {
        id: 'settings.delete-universe-modal.subDescription',
        defaultMessage: 'The universe "{universeName}" will be permanently deleted.',
    },
    cancel: {
        id: 'settings.delete-universe-modal.button.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.delete-universe-modal.button.delete',
        defaultMessage: 'Delete',
    },
    deleteUniverseErrorMsg: {
        id: 'settings.delete-universe-modal.error-message',
        defaultMessage: 'Something went wrong while deleting the universe',
    },
});


interface DeleteUniverseModalProps {
    onClose: () => void;
    ontologyId: string;
    ontologyName: string;
    onUniverseDeletion: (progressMonitor: ProgressMonitor) => Promise<void>;
}

export function DeleteUniverseModal(props: DeleteUniverseModalProps) {
    const {
        onClose,
        ontologyId,
        ontologyName,
        onUniverseDeletion,
    } = props;

    const notifications = useArgNotifications();
    const classNames = useClassNames('settings-delete-universe-modal');
    const navigate = useNavigate();
    const [handleSubmit, submitting] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            await ontologiesConnector.deleteOntology(ontologyId, sub1);

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            await onUniverseDeletion(sub2);
            navigate(UNIVERSE_MANAGEMENT_ROUTE);

            onClose();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.deleteUniverseErrorMsg }, error as Error);
            throw error;
        }
    }, [ontologyId, onUniverseDeletion, navigate, onClose, notifications]);

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={onClose}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={onClose}
                        label={messages.cancel}
                        disabled={submitting?.isRunning}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        onClick={() => handleSubmit()}
                        label={messages.delete}
                        loading={submitting?.isRunning}
                        disabled={submitting?.isRunning}
                        data-testid='delete'
                    />
                </div>
            }
        >
            <NotifyBox
                text={messages.description}
                subtext={messages.subDescription}
                messageValues={{
                    universeName: ontologyName,
                }}
                icon='icon-exclamation-point'
                color={WARNING_ORANGE}
            />
        </ArgModal>
    );
}
