import { Plugin } from '@ckeditor/ckeditor5-core';
import { ButtonView } from '@ckeditor/ckeditor5-ui';

import { SVG as chartSVG } from './chart-icon.ts';
import { SVG as mapSVG } from './map-icon.ts';
import { SVG as heatmapSVG } from './heatmap-icon.ts';
import Exploration2ModeCommand from './exploration2-mode-command';
import Exploration2Mode from './exploration2-mode';

export default class Exploration2ModeUi extends Plugin {
    /**
     * @inheritDoc
     */
    static get requires() {
        return [Exploration2Mode];
    }

    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'argonos-exploration:mode-ui';
    }

    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;

        editor.commands.add('exploration2Mode', new Exploration2ModeCommand(editor));

        this._createButton({
            name: 'chart',
            title: 'Chart',
            icon: chartSVG,
            isDefault: true,
        });

        this._createButton({
            name: 'map',
            title: 'Map',
            icon: mapSVG,
        });
        this._createButton({
            name: 'heatmap',
            title: 'Heat map',
            icon: heatmapSVG,
        });
    }

    /**
     * Creates a button and stores it in the editor {@link module:ui/componentfactory~ComponentFactory}.
     *
     * @private
     * @param {module:image/imagestyle~ImageStyleOptionDefinition} buttonConfig
     */
    _createButton(buttonConfig) {
        const buttonName = buttonConfig.name;

        this.editor.ui.componentFactory.add(getUIComponentName(buttonName), locale => {
            const command = this.editor.commands.get('exploration2Mode');
            const view = new ButtonView(locale);

            view.set({
                label: buttonConfig.title,
                icon: buttonConfig.icon,
                tooltip: true,
                isToggleable: true,
            });

            view.bind('isEnabled').to(command, 'isEnabled');
            view.bind('isOn').to(command, 'value', value => value === buttonName);
            view.on('execute', this._executeCommand.bind(this, buttonName));

            return view;
        });
    }

    _executeCommand(mode) {
        console.log('Set mode to=', mode);
        this.editor.execute('exploration2Mode', { value: mode });
        this.editor.editing.view.focus();
    }
}
//

// Returns the image style component name with the "imageStyle:" prefix.
//
// @param {String} name
// @returns {String}
function getUIComponentName(name) {
    return `exploration2Mode:${name}`;
}

