import { useCallback, useState } from 'react';

import {
    ArgButton,
    ArgMenu,
    ArgMenuItem,
    ClassValue,
    useClassNames,
} from 'src/components/basic';
import { ITEM_ACTIONS } from '../../../constants/item-actions';
import { ItemActions } from '../../../types/item-actions';

const CLASSNAME = 'actions-menu';

export interface ActionsMenuProps {
    className?: ClassValue;
    actions: ItemActions[];
    onMenuItemClick?: (key: ItemActions) => void;
    isActionDisabled?: (action: ItemActions) => boolean;
}

export function ActionsMenu(props: ActionsMenuProps) {
    const { className, actions, onMenuItemClick, isActionDisabled } = props;

    const classNames = useClassNames(CLASSNAME);
    const [visible, setVisible] = useState<boolean>(false);

    const handleMenuItemClick = useCallback((key: string) => {
        onMenuItemClick?.(key as ItemActions);
        setVisible(false);
    }, [onMenuItemClick]);

    const supportedActions = actions.map(itemAction => ITEM_ACTIONS[itemAction]);

    const actionsMenu = (
        <ArgMenu
            className={classNames('&', className)}
            data-testid='actions-menu'
        >
            {
                supportedActions.map(({ key, label }) => {
                    return (
                        <ArgMenuItem
                            id={key}
                            key={key}
                            onClick={() => handleMenuItemClick(key)}
                            data-testid={`menu-item-${key}`}
                            label={label}
                            disabled={isActionDisabled?.(key as ItemActions)}
                        />
                    );
                })
            }
        </ArgMenu>
    );

    return (
        <ArgButton
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={visible}
            className={classNames('&')}
            data-testid='actions-menu-container'
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
            popoverClassName={classNames('&-popover')}
        />

    );
}
