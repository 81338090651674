import { isEqual } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
    ArgButton,
    ArgChangeReason,
    ArgFilteredMenu,
    ArgIcon,
    ArgInputSearch,
    ArgMessageRenderer,
    ArgTable2,
    ArgTable2Column,
    ArgUserById,
    ArgUserInfo,
    highlightSplit,
    ProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
    useEffectAsync,
} from 'src/components/basic';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';
import { ProceoGroup } from 'src/proceo/model/group';
import { ProceoGroupsConnector } from 'src/proceo/utils/connectors/proceo-groups-connector';
import { UsersAdminConnector } from 'src/utils/connectors/users-admin-connector';
import { stringSorter } from 'src/utils/sorter';

import './hierarchy-edit-members-modal.less';

const CLASS_NAME = 'hierarchy-members-modal';
const DEBOUNCE_AND_ENTER_CHANGE_REASONS: ArgChangeReason[] = ['debounce', 'enter'];
// const SCROLL_POSITION: ScrollToOptions = {
//     top: 0,
//     left: 0,
//     behavior: 'smooth',
// };

export const messages = defineMessages({
    title: {
        id: 'proceo.hierarchy-members-modal.title',
        defaultMessage: 'Members',
    },
    loadPotentialMembersError: {
        id: 'proceo.hierarchy-members-modal.loadPotentialMembersError',
        defaultMessage: 'Failed to load potential collaborators',
    },
    name: {
        id: 'proceo.hierarchy-members-modal.name',
        defaultMessage: 'Name',
    },
    delete: {
        id: 'proceo.hierarchy-members-modal.delete',
        defaultMessage: 'Delete',
    },
    modalDescription: {
        id: 'proceo.hierarchy-members-modal.modalDescription',
        defaultMessage: 'Define the users for the group : \"{ groupName } \".',
    },
    searchPlaceholder: {
        id: 'proceo.hierarchy-members-modal.buttons.search-placeholder',
        defaultMessage: 'Search for and add a user.',
    },
});

export interface HierarchyEditMembersModalProps {
    onClose: () => void;
    group: ProceoGroup,
}

export function HierarchyEditMembersModal(props: HierarchyEditMembersModalProps) {
    const { group, onClose } = props;

    const classNames = useClassNames(CLASS_NAME);
    //    const intl = useIntl();
    const notifications = useArgNotifications();


    useEffectAsync(async () => {
        const users = await ProceoGroupsConnector.getInstance().getGroupUsers(group.id);
        setInitialMemberList(users);
        setMembers(users);
    }, [group]);

    const [initialMemberList, setInitialMemberList] = useState<ArgUserInfo[]>([]);

    const searchCollaborators = useCallback((search: string | undefined, progressMonitor: ProgressMonitor) => {
        return UsersAdminConnector.getInstance().getPublicUsers(search, undefined, progressMonitor);
    }, []);

    const lastAddedCollaborator = useRef<ArgUserInfo>();

    const [members, setMembers] = useState<ArgUserInfo[]>([]);

    const handleConfirm = useCallback(async () => {
        const changes: {[userId: string]: 'Add'|'Delete'} = {};
        for (const c1 of members) {
            if (!initialMemberList.find(c2 => c1.id === c2.id)) {
                changes[c1.id] = 'Add';
            }
        }
        for (const c1 of initialMemberList) {
            if (!members.find(c2 => c1.id === c2.id)) {
                changes[c1.id] = 'Delete';
            }
        }
        await ProceoGroupsConnector.getInstance().saveGroupUsers(group.id, changes);

        return true;
    }, [initialMemberList, group.id, members]);

    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const [suggestionsVisible, setSuggestionsVisible] = useState<boolean>(false);
    const potentialCollaboratorsRef = useRef<ArgUserInfo[]>();

    const [handleInputSearchChange, searchProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor, value: string) => {
        if (!value) {
            return;
        }

        potentialCollaboratorsRef.current = [];

        try {
            const potentialMember = await searchCollaborators(value || undefined, progressMonitor);
            potentialCollaboratorsRef.current = potentialMember.slice();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.loadPotentialMembersError }, error as Error);
            throw error;
        }
    }, [searchCollaborators, notifications]);

    const handleSearchChange = useCallback((value: string | null, reason: ArgChangeReason) => {
        if (!DEBOUNCE_AND_ENTER_CHANGE_REASONS.includes(reason)) {
            return;
        }
        setSearchInputValue(value || '');
        setSuggestionsVisible(!!value);
    }, []);

    const handleDeleteCollaborator = useCallback((deletedCollaborator: ArgUserInfo) => {
        setMembers((prev) => {
            return prev.filter(prevCollaborator => prevCollaborator.id !== deletedCollaborator.id);
        });
    }, [setMembers]);

    const columns = useMemo<ArgTable2Column<ArgUserInfo>[]>(() => {
        const retColumns: ArgTable2Column<ArgUserInfo>[] = [
            {
                key: 'name',
                title: messages.name,
                render: function renderCollaboratorTypeIcon(_value: any, item) {
                    return (
                        <ArgUserById
                            userId={item.id}
                            size='medium'
                            label={true}
                            className={classNames('&-user-avatar')}
                        />
                    );
                },
                dataIndex: '',
                width: '90%',
                ellipsis: true,
                sorter: (a, b) => {
                    return stringSorter<ArgUserInfo>(a, b, (item) => item.displayName);
                },
            },
            {
                key: 'delete',
                title: undefined,
                render: function renderDeleteButton(_value: any, item) {
                    return (
                        <ArgButton
                            type='ghost'
                            icon={<ArgIcon name='icon-trash' size='medium' />}
                            onClick={() => handleDeleteCollaborator(item)}
                            tooltip={messages.delete}
                            tooltipPlacement='top'
                            className={classNames('&-trash-button')}
                        />
                    );
                },
                dataIndex: '',
            },
        ];

        return retColumns;
    }, [classNames, handleDeleteCollaborator]);

    const handleSelectNewCollaborator = useCallback((collaborator: ArgUserInfo) => {
        setMembers((prev) => {
            return [...prev, collaborator];
        });
        setSuggestionsVisible(false);

        lastAddedCollaborator.current = collaborator;
    }, [setMembers]);

    const renderSearchCollaboratorItem = useCallback((user: ArgUserInfo, searchedToken?: string) => {
        const label = highlightSplit(user.displayName || '', searchedToken);

        return (
            <div className={classNames('&-search-item')}>
                <ArgUserById
                    label={label}
                    userId={user}
                    size='medium'
                    className={classNames('&-search-user-avatar')}
                />
                <ArgIcon size='small' name='icon-user' />
            </div>
        );
    }, [classNames]);

    const handleSuggestionsPopover = useCallback(() => {
        const currentMemberIds = members.map(user => user.id);
        const potentialMembers = potentialCollaboratorsRef.current || [];
        const filteredPotentialCollaborators = potentialMembers
            .filter(({ id }) => {
                return !currentMemberIds.includes(id);
            })
            .sort((a, b) => {
                return stringSorter<ArgUserInfo>(a, b, item => item.displayName || '');
            });

        return (
            <ArgFilteredMenu<ArgUserInfo>
                items={filteredPotentialCollaborators}
                getItemKey={(item) => String(item.id)}
                searchedToken={searchInputValue}
                filterSearchedToken={true}
                renderItem={renderSearchCollaboratorItem}
                getItemLabel={(user => user.displayName)}
                onSelect={handleSelectNewCollaborator}
                progressMonitor={searchProgressMonitor}
                className={classNames('&-search-dropdown')}
            />
        );
    }, [classNames,
        searchInputValue,
        handleSelectNewCollaborator,
        renderSearchCollaboratorItem,
        searchProgressMonitor,
        members]);

    return (
        <ConfirmModal
            className={classNames('&')}
            bodyClassName={classNames('&-modal-body')}
            title={messages.title}
            onClose={onClose}
            size='large'
            onConfirm={() => handleConfirm()}
            type='validate'
            confirmDisabled={isEqual(members, initialMemberList)}
            isConfirmButtonVisible={true}
        >
            <div className={classNames('&-header')}>
                <ArgMessageRenderer message={messages.modalDescription} messageValues={{ groupName: group.displayName }} />

                <ArgInputSearch
                    className={classNames('&-header-search')}
                    autoFocus={true}
                    onChange={handleSearchChange}
                    onInputChange={handleInputSearchChange}
                    placeholder={messages.searchPlaceholder}
                    popover={handleSuggestionsPopover}
                    onPopoverVisibleChange={setSuggestionsVisible}
                    popoverVisible={suggestionsVisible}
                    loading={searchProgressMonitor?.isRunning}
                />
            </div>
            <div className={classNames('&-body')}>
                <ArgTable2<ArgUserInfo>
                    key='collaborators'
                    className={classNames('&-body-table', 'scrollable-body')}
                    rowKey='identityId'
                    dataSource={members}
                    columns={columns}
                    highlightedRowCondition={(item) => item === lastAddedCollaborator.current}
                    preventHighlightedRowScroll={false}
                />
            </div>
        </ConfirmModal>
    );
}
