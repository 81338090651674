import { isEmpty, isObject, isString, union, uniqBy } from 'lodash';
import { compare } from 'fast-json-patch';

import { ConnectorRequestInit } from '../../utils/connector';
import { AddRoleDTO, Role, RoleId, RolePermission, RolesScope } from '../models/dtoApi';
import { Group, GroupId } from '../../model/user';
import { ArgUserId, type Configuration, ProgressMonitor } from 'src/components/basic';
import { BaseConnector } from '../../utils/connectors/base-connector';
import {
    getAdministrationApi,
    getDataExplorationApi,
    getDataPreparationApi,
    getProceoApi,
    getSettingsApi,
} from 'src/utils/connectors/api-url';
import { Component } from 'src/preparation/model/component';
import { AppSettingsKey } from 'src/model/application-settings';
import { mapExternalComponent, mapRole } from './mappers';
import { ExternalComponent, ExternalComponentDto, ExternalComponentKey } from '../models/external-component';
import { listArgonosModules } from '../../components/application/modules-manager';
import { ApplicationVersion } from '../../components/common/applications/applications-version';

export class SettingsConnector extends BaseConnector {
    private static instance: SettingsConnector;

    static getInstance(): SettingsConnector {
        if (!SettingsConnector.instance) {
            SettingsConnector.instance = new SettingsConnector('settings', getSettingsApi());
        }

        return SettingsConnector.instance;
    }

    async applicationVersion(progressMonitor:ProgressMonitor = ProgressMonitor.empty()): Promise<ApplicationVersion> {
        const url = '/version';
        const options = { verifyJSONResponse: true };

        const result = await this.request(url, options, progressMonitor);

        return result;
    }

    static getRoleScopeApi(roleScope: RolesScope | undefined): string {
        let api: string | undefined = undefined;

        if (roleScope === 'admin') {
            api = getAdministrationApi();
        } else if (roleScope === 'data_exploration') {
            api = getDataExplorationApi();
        } else if (roleScope === 'data_preparation') {
            api = getDataPreparationApi();
        } else if (roleScope === 'proceo') {
            api = getProceoApi();
        } else {
            api = getSettingsApi();
        }

        if (api) {
            return api;
        }

        throw new Error(`API URL for role scope for ${roleScope} is undefined`);
    }


    async editUserRoles(
        userId: ArgUserId,
        roleIds: RoleId[],
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}/roles`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'PUT',
            api,
            json: {
                userId,
                roleIds,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async getUserRoles(
        userId: ArgUserId,
        scope?: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Role[]> {
        const url = `/users/${encodeURIComponent(userId)}/roles`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'GET',
            api,
            verifyJSONResponse: true,
        };

        const response = await this.request(url, options, progressMonitor);

        const ret: Role[] = response?.roles.map(mapRole);

        return ret;
    }

    async editGroupRoles(
        groupId: GroupId,
        roleIds: RoleId[],
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/user-groups/${encodeURIComponent(groupId)}/roles`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'PUT',
            api,
            json: {
                groupId,
                roleIds,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    private async getGroupRoles(
        groupId: GroupId,
        scope?: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Role[]> {
        const api = SettingsConnector.getRoleScopeApi(scope);
        const url = `/user-groups/${encodeURIComponent(groupId)}/roles`;
        const options = {
            api,
            method: 'GET',
            verifyJSONResponse: true,
        };

        const response = await this.request(url, options, progressMonitor);

        const ret: Role[] = response.roles.map(mapRole);

        return ret;
    }

    async restoreUser(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}/restore`;
        const options = {
            method: 'POST',
        };

        await this.request(url, options, progressMonitor);
    }

    async addRole(
        role: AddRoleDTO,
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<RoleId> {
        const url = '/user-roles';
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'POST',
            api: api,
            json: {
                role: {
                    name: role.name,
                    description: role.description,
                    scope: role.scope,
                },
            },
        };
        const res = await this.request(url, options, progressMonitor);

        return res?.role?.id;
    }

    async editRole(
        role: Role,
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<RoleId> {
        const url = `/user-roles/${encodeURIComponent(role.id)}`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'PUT',
            api,
            json: {
                role: role,
            },
        };

        const res = await this.request(url, options, progressMonitor);

        return res?.role?.id;
    }

    async deleteRole(
        roleId: RoleId,
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/user-roles/${encodeURIComponent(scope)}/${encodeURIComponent(roleId)}`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'DELETE',
            api: api,
        };
        await this.request(url, options, progressMonitor);
    }

    async getAllRoles(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Role[]> {
        const apiCalls: Promise<Role[]>[] = [];

        listArgonosModules().forEach((argonosModule) => {
            if (argonosModule.hasRoles && argonosModule.scope && argonosModule.enabled) {
                apiCalls.push(this.getRoles(false, argonosModule.scope, progressMonitor));
            }
        }).value();

        const response = await Promise.all(apiCalls);

        const ret = uniqBy(union(...response), (role) => role.id);

        return ret;
    }

    async getAllUserRoles(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Role[]> {
        const apiCalls: Promise<Role[]>[] = [];

        listArgonosModules().forEach((argonosModule) => {
            if (argonosModule.hasUserRoles && argonosModule.scope && argonosModule.enabled) {
                apiCalls.push(this.getUserRoles(userId, argonosModule.scope, progressMonitor));
            }
        }).value();

        const response = await Promise.all(apiCalls);

        const ret = uniqBy(union(...response), (role) => role.id);

        return ret;
    }

    async getAllGroupRoles(
        groupId: GroupId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Role[]> {
        const apiCalls: Promise<Role[]>[] = [];

        listArgonosModules().forEach((argonosModule) => {
            if (argonosModule.hasGroupRoles && argonosModule.scope && argonosModule.enabled) {
                apiCalls.push(this.getGroupRoles(groupId, argonosModule.scope, progressMonitor));
            }
        }).value();

        const response = await Promise.all(apiCalls);

        const ret = uniqBy(union(...response), (role) => role.id);

        return ret;
    }

    async getRoles(
        includeDraft: boolean,
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Role[]> {
        const url = '/user-roles';
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'GET',
            api,
            params: {
                includeDraft,
            },
            verifyJSONResponse: true,
        };
        const response = await this.request(url, options, progressMonitor);

        const ret: Role[] = response?.roles.map(mapRole);

        return ret;
    }

    async getRolePermissions(
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<RolePermission[]> {
        const url = '/user-roles/permissions';
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'GET',
            api: api,
            verifyJSONResponse: true,
        };
        const response = await this.request(url, options, progressMonitor);

        return response;
    }

    async editRolePermission(
        permission: RolePermission,
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/user-roles/${encodeURIComponent(permission.roleId)}/permission/${encodeURIComponent(permission.key)}`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'PUT',
            api: api,
            json: {
                permission: permission,
            },
        };
        await this.request(url, options, progressMonitor);
    }

    async getComponentsPermissions(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<RolePermission[]> {
        const api = getDataPreparationApi();
        const options = {
            method: 'GET',
            api: api,
            verifyJSONResponse: true,
        };
        const result = await this.request('/components', options, progressMonitor);

        const permissions = [...result.components]
            .sort((component1: Component, component2: Component) => component1.category.localeCompare(component2.category))
            .map((component: Component) => {
                const permission: RolePermission = {
                    id: '',
                    roleId: '',
                    key: component.key,
                    name: component.category ? `${component.category} - ${component.name}` : component.name,
                    isAccess: false,
                    scope: 'data_preparation',
                    section: 'components',
                    type: component.type,
                };

                return permission;
            });

        return permissions;
    }

    async publishUserRoles(
        scope: RolesScope,
        progressMonitor: ProgressMonitor
    ): Promise<void> {
        const url = `/user-roles/${encodeURIComponent(scope)}/publish`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'POST',
            api: api,
        };
        await this.request(url, options, progressMonitor);
    }

    async resetUserRoles(
        scope: RolesScope,
        progressMonitor: ProgressMonitor
    ): Promise<void> {
        const url = `/user-roles/${encodeURIComponent(scope)}/clean`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'POST',
            api: api,
        };
        await this.request(url, options, progressMonitor);
    }

    async getUserGroups(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Group[]> {
        const url = `/users/${encodeURIComponent(userId)}/memberships`;
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        // TODO OO Map date
        const response: { groups: Group[] } = await this.request(
            url,
            options,
            progressMonitor
        );

        return response.groups;
    }

    async getExternalComponents(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ExternalComponent[]> {
        const url = '/remote-components';
        const api = SettingsConnector.getRoleScopeApi('data_preparation');
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
            api: api,
        };

        const response = await this.request(url, options, progressMonitor);

        return response.remoteComponents.map((rawExternalComponent: ExternalComponentDto) => mapExternalComponent(rawExternalComponent));
    }

    async createExternalComponent(
        externalComponent: ExternalComponent,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/remote-components';
        const api = SettingsConnector.getRoleScopeApi('data_preparation');
        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                ...externalComponent,
            },
            verifyJSONResponse: true,
            api: api,
        };

        await this.request(url, options, progressMonitor);
    }

    async deleteExternalComponent(
        externalComponentKey: ExternalComponentKey,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/remote-components/${encodeURIComponent(externalComponentKey)}`;
        const api = SettingsConnector.getRoleScopeApi('data_preparation');
        const options: ConnectorRequestInit = {
            method: 'DELETE',
            api: api,
        };

        await this.request(url, options, progressMonitor);
    }

    async deleteExternalComponents(
        externalComponentKeys: ExternalComponentKey[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/remote-components';
        const api = SettingsConnector.getRoleScopeApi('data_preparation');
        const options: ConnectorRequestInit = {
            method: 'DELETE',
            api: api,
            json: {
                remoteComponentsIds: externalComponentKeys,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async exportExternalComponent(
        externalComponentKeys: ExternalComponentKey[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Blob> {
        const url = '/remote-components/export';
        const api = SettingsConnector.getRoleScopeApi('data_preparation');
        const options: ConnectorRequestInit = {
            method: 'POST',
            api: api,
            json: {
                remoteComponentsIds: externalComponentKeys,
            },
        };

        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }

    async importExternalComponent(
        file: Blob,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ExternalComponent> {
        const url = '/remote-components/import';
        const api = SettingsConnector.getRoleScopeApi('data_preparation');

        const options: ConnectorRequestInit = {
            method: 'POST',
            body: file,
            api: api,
            verifyJSONResponse: true,
        };

        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }

    async putExternalComponent(
        externalComponentKey: ExternalComponentKey,
        externalComponent: ExternalComponent,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/remote-components/${encodeURIComponent(externalComponentKey)}`;
        const api = SettingsConnector.getRoleScopeApi('data_preparation');
        const options = {
            method: 'PUT',
            json: externalComponent,
            api: api,
        };

        await this.request(url, options, progressMonitor);
    }

    computeAppSettingsURL(key: AppSettingsKey): string {
        const url = `${this.api}/application/settings/${encodeURIComponent(key)}`;

        return url;
    }

    async getAppSettingsBlob(
        key: AppSettingsKey,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Blob> {
        const url = this.computeAppSettingsURL(key);

        const options: ConnectorRequestInit = {
            method: 'GET',
            noCache: false,
        };

        const result = await this.request(url, options, progressMonitor);

        if (!(result instanceof Blob)) {
            throw new Error('Invalid response type (not a BLOB)');
        }

        return result;
    }

    async getAppSettingsJSON(
        key: AppSettingsKey,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<any> {
        const url = this.computeAppSettingsURL(key);

        const options: ConnectorRequestInit = {
            method: 'GET',
            verifyJSONResponse: true,
            noCache: false,
        };

        const json = await this.request(url, options, progressMonitor);

        return json;
    }

    async setAppSettings(
        key: AppSettingsKey,
        settingsValue: Blob | string | any,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        if (settingsValue === undefined) {
            await this.deleteAppSettings(key, progressMonitor);

            return;
        }

        const url = this.computeAppSettingsURL(key);

        const data = new FormData();
        if (settingsValue instanceof Blob) {
            data.append('file', settingsValue);
        } else if (isObject(settingsValue)) {
            const jsonBLOB = new Blob([JSON.stringify(settingsValue)], {
                type: 'application/json',
            });
            data.append('file', jsonBLOB);
        } else if (isString(settingsValue)) {
            data.append('text', String(settingsValue));
        }

        const options = {
            method: 'PUT',
            body: data,
        };

        await this.request(url, options, progressMonitor);
    }

    async patchAppSettings(
        key: AppSettingsKey,
        settings: Configuration,
        previousSettings: Configuration,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const operations = compare(previousSettings, settings);
        if (isEmpty(operations)) {
            return;
        }

        const url = this.computeAppSettingsURL(key);

        await this.request(
            url,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json-patch+json' },
                json: {
                    comment: 'Modify settings',
                    changes: operations,
                },
            },
            progressMonitor
        );
    }

    async deleteAppSettings(
        key: AppSettingsKey,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = this.computeAppSettingsURL(key);

        const options = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }
}
