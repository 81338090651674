import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { first } from 'lodash';

import { Action } from '../../../../utils/actions/action';
import { HistoryManager } from '../../../../utils/history-manager';
import { ActionsEngine } from '../../../../utils/actions/actions-engine';
import { ProgressMonitor, SelectionProvider } from '../../../../components/basic';
import { FormContainer, FormDocument, FormElement, FormTab } from '../../../../components/common/forms/model';
import { FormRepository } from './form-actions-engine';

export const ADD_FORM_PAGE_ELEMENTS_ACTION = Symbol('Form:AddFormPageElements');

export const messages = defineMessages({
    addFormPageElements: {
        id: 'exploration.forms.actions.add-form-page-elements.TaskName',
        defaultMessage: '{count, plural, =1 {Add a page} other {Add {count} pages}}',
    },
    undoAddFormPageElements: {
        id: 'exploration.forms.actions.add-form-page-elements.Undo',
        defaultMessage: 'Cancel {count, plural, =1 {add a page} other {add {count} pages}}',
    },
});

export function createFormAddPageElements(
    container: FormContainer,
    formElements: FormTab[],
    index: number | undefined,
    selectionProvider: SelectionProvider<FormElement> | undefined,
    defaultPageName: string
): Action<FormRepository, void> {
    let previousValue: FormDocument;

    return {
        type: ADD_FORM_PAGE_ELEMENTS_ACTION,

        renderEntry: function ChangeStepActionTitle(undo: boolean) {
            return (
                <FormattedMessage
                    {...(undo) ? messages.undoAddFormPageElements : messages.addFormPageElements}
                    values={{
                        count: formElements.length,
                    }}
                />
            );
        },
        action: async (
            historyManager: HistoryManager<ActionsEngine<FormRepository>>,
            actionsEngine: ActionsEngine<FormRepository>,
            progressMonitor: ProgressMonitor
        ) => {
            progressMonitor.beginTask(messages.addFormPageElements, 1, {
                count: formElements.length,
            });

            if (!formElements.length) {
                return;
            }

            previousValue = actionsEngine.repository.formDocument;

            actionsEngine.repository.addPages(container, formElements, index, defaultPageName);
            selectionProvider?.set(first(formElements)!, 'form-add-page-elements-action');
        },
        reverseAction: async (
            historyManager: HistoryManager<ActionsEngine<FormRepository>>,
            actionsEngine: ActionsEngine<FormRepository>,
            progressMonitor: ProgressMonitor
        ) => {
            progressMonitor.beginTask(messages.undoAddFormPageElements, 1, {
                count: formElements.length,
            });

            actionsEngine.repository.setDocument(previousValue);

            selectionProvider?.clear('form-add-page-elements-action');
        },
    };
}
