import { isEmpty } from 'lodash';
import { Navigation } from 'react-router';
import { Location } from 'react-router-dom';

import { ToolContextId } from './tool-context';
import { Tool } from './tool';

const mimeTypeToolContextIdPrefix = `$MimeType_${(new Date).getTime()}:`;

const registeredTools: Record<ToolContextId, Tool<any>[]> = {};

export function registerToolItem<T>(toolContextId: ToolContextId, tool: Tool<T> /*, toolPropsGenerator?: ToolPropsGenerator<T>*/) {
    /* if (toolPropsGenerator) {
        const toolWithPropsGenerator: ToolItemWithPropsGenerator<any> = {
            ...tool,
            __internal_propsGenerator: toolPropsGenerator,
        };
        tool = toolWithPropsGenerator;
    } */

    let ts = registeredTools[toolContextId];
    if (ts) {
        ts.push(tool);

        return;
    }
    ts = [tool];
    registeredTools[toolContextId] = ts;
}

/**
 * INTERNAL USE ONLY !
 */
export function appendRegisteredToolItems<T>(tools: Tool<T>[], toolContextId: ToolContextId) {
    const ts = registeredTools[toolContextId];
    if (isEmpty(ts)) {
        return;
    }

    tools.push(...ts);
}

export interface MimeTypeTargetEnvironmentContext<M> {
    navigation: Navigation;
    location: Location;

    target: M[]|M;
}

function constructToolContextId(mimeType: string) {
    const toolContextId = `${mimeTypeToolContextIdPrefix}${mimeType}`;

    return toolContextId;
}

export function registerToolItemForMimeType<M>(mimeType: string, tool: Tool<MimeTypeTargetEnvironmentContext<M>>) {
    const toolContextId = constructToolContextId(mimeType);

    registerToolItem(toolContextId, tool);
}

/**
 * INTERNAL USE ONLY !
 */
export function appendRegisteredToolItemsForMimeType<T>(tools: Tool<MimeTypeTargetEnvironmentContext<T>>[], mimeType: string) {
    const toolContextId = constructToolContextId(mimeType);

    appendRegisteredToolItems<any>(tools, toolContextId);
}
