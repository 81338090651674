import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ArgButton, ArgModal, useArgNotifications, useClassNames } from 'src/components/basic';
import { User } from 'src/model/user';
import { NotifyBox } from 'src/settings/common-modals/notify-box/notify-box';
import { WARNING_ORANGE } from 'src/settings/models/colors';
import { UsersAdminConnector } from '../../../../utils/connectors/users-admin-connector';

import './reset-user-password-modal.less';

export const messages = defineMessages({
    title: {
        id: 'settings.reset-password-modal.title',
        defaultMessage: 'Reset password',
    },
    description: {
        id: 'settings.reset-password-modal.description',
        defaultMessage: 'Are you sure you want to reset this password?',
    },
    cancel: {
        id: 'settings.reset-password-modal.button.cancel',
        defaultMessage: 'Cancel',
    },
    reset: {
        id: 'settings.reset-password-modal.button.reset',
        defaultMessage: 'Reset',
    },
    resetPasswordErrorMsg: {
        id: 'settings.reset-password-modal.error-message',
        defaultMessage: 'Something went wrong while resetting the password',
    },
    close: {
        id: 'settings.reset-password-modal.close',
        defaultMessage: 'Close',
    },
    warningMessage: {
        id: 'settings.reset-password-modal.warning-message',
        defaultMessage: 'This action is irreversible',
    },
    resetSuccessful: {
        id: 'settings.reset-password-modal.reset-successful',
        defaultMessage: 'The password has been reset',
    },
    newPassword: {
        id: 'settings.reset-password-modal.new-password',
        defaultMessage: 'New password: ',
    },
});

interface ResetPasswordModalProps {
    closeModal: () => void;
    user: User;
}

export function ResetPasswordModal(props: ResetPasswordModalProps) {
    const {
        closeModal,
        user,
    } = props;

    const intl = useIntl();
    const classNames = useClassNames('settings-reset-password-modal');
    const notifications = useArgNotifications();

    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await UsersAdminConnector.getInstance().resetPassword(user.id);
            setNewPassword(response.password);
            setLoading(false);
        } catch (error) {
            notifications.snackError({ message: messages.resetPasswordErrorMsg }, error as Error);
            setLoading(false);
        }
    };

    const text = newPassword
        ? intl.formatMessage(messages.resetSuccessful)
        : intl.formatMessage(messages.description);

    const subtext = newPassword ? (
        <span className={classNames('&-subtext')}>
            {intl.formatMessage(messages.newPassword)}
            <div className={classNames('&-new-password')}>{newPassword}</div>
        </span>
    ) : (
        intl.formatMessage(messages.warningMessage)
    );

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={() => {
                closeModal();
                setNewPassword('');
            }}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type={newPassword ? 'primary' : 'secondary'}
                        onClick={() => {
                            closeModal();
                            setNewPassword('');
                        }}
                        label={newPassword ? messages.close : messages.cancel}
                        disabled={loading}
                    />
                    {!newPassword && (
                        <ArgButton
                            className={classNames('&-footer-button')}
                            type='primary'
                            label={messages.reset}
                            data-testid='reset'
                            onClick={handleSubmit}
                            disabled={loading}
                        />
                    )}
                </div>
            }
        >
            <NotifyBox
                text={text}
                subtext={subtext}
                color={WARNING_ORANGE}
                icon='icon-exclamation-point'
            />
        </ArgModal>
    );
}
