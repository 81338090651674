import { useContext, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
    ArgButton,
    ArgMenu,
    ArgMenuItem,
    ClassValue,
    ProgressMonitor,
    useArgModalContext,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { ConfirmModal } from '../../../components/common/modal2/confirm-modal/confirm-modal';
import { UserProfileField } from '../../../model/user-metadata';
import { UsersAndGroupsStateContext } from '../../users/providers/usersState';
import { EditPropertyModal } from './edit-property-modal';
import { UsersConnector } from '../../../utils/connectors/users-connector';

const CLASSNAME = 'settings-property-action-menu';
const PROPERTY_DELETE_MODAL_ID = 'PROPERTY_DELETE_MODAL_ID';
const PROPERTY_EDIT_MODAL_ID = 'PROPERTY_EDIT_MODAL_ID';

const messages = defineMessages({
    delete: {
        id: 'settings-property-action-menu.delete',
        defaultMessage: 'Delete',
    },
    deleteConfirmTitle: {
        id: 'settings-property-action-menu.deleteConfirmTitle',
        defaultMessage: 'Delete this property',
    },
    deleteConfirmMessage: {
        id: 'settings-property-action-menu.deleteConfirmMessage',
        defaultMessage: 'Are you sure you want to delete "{name}"?',
    },
    deletePropertyErrorMsg: {
        id: 'settings-property-action-menu.deletePropertyErrorMsg',
        defaultMessage: 'An error occurred while deleting the property',
    },
    edit: {
        id: 'settings-property-action-menu.edit',
        defaultMessage: 'Edit',
    },
});

export interface PropertyActionMenuProps {
    className?: ClassValue;
    property: UserProfileField;
}

export function PropertyActionMenu(props: PropertyActionMenuProps) {
    const { className, property } = props;
    const classNames = useClassNames(CLASSNAME);
    const { properties, setProperties } = useContext(UsersAndGroupsStateContext);

    const [visible, setVisible] = useState(false);
    const modalContext = useArgModalContext();

    const notifications = useArgNotifications();

    const [handleDeleteProperty] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            await UsersConnector.getInstance().deleteUserProfileField(property.id, progressMonitor);
            setProperties((currentProperties) =>
                currentProperties.filter((existingProperty) => existingProperty.id !== property.id)
            );
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.deletePropertyErrorMsg }, error as Error);
        }
    }, [property, notifications, setProperties]);

    const actionsMenu = (
        <ArgMenu>
            <ArgMenuItem
                key={messages.edit.id}
                data-testid='edit'
                onClick={() => {
                    modalContext.open(PROPERTY_EDIT_MODAL_ID, (
                        <EditPropertyModal
                            closeModal={() => modalContext.close(PROPERTY_EDIT_MODAL_ID)}
                            properties={properties}
                            property={property}
                            setProperties={setProperties}
                        />
                    ));
                    setVisible(false);
                }}
            >
                <span>
                    <FormattedMessage {...messages.edit} />
                </span>
            </ArgMenuItem>
            <ArgMenuItem
                key={messages.delete.id}
                data-testid='delete'
                onClick={() => {
                    modalContext.open(PROPERTY_DELETE_MODAL_ID, (
                        <ConfirmModal
                            type='delete'
                            title={messages.deleteConfirmTitle}
                            alertMessage={messages.deleteConfirmMessage}
                            messageValues={{ name: property.displayName }}
                            onClose={() => modalContext.close(PROPERTY_DELETE_MODAL_ID)}
                            onConfirm={handleDeleteProperty}
                        />
                    ));
                    setVisible(false);
                }}
            >
                <span>
                    <FormattedMessage {...messages.delete} />
                </span>
            </ArgMenuItem>
        </ArgMenu>
    );

    return (
        <ArgButton
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={visible}
            data-testid='actions-users-menu'
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
        />
    );
}
