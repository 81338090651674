import { Command } from '@ckeditor/ckeditor5-core';

const DEFAULT_MODE = 'chart';

export default class Exploration2ModeCommand extends Command {
    /**
     * @inheritDoc
     */
    refresh() {
        const editor = this.editor;
        const element = getClosestSelectedExploration2Element(editor.model.document.selection);

        this.isEnabled = !!element;

        //        console.log('===>', this.isEnabled, element?.getAttribute('width'));

        if (!element || !element.hasAttribute('mode')) {
            this.value = DEFAULT_MODE;
        } else {
            this.value = element.getAttribute('mode');
        }
    }

    /**
     * Executes the command.
     *
     *        // Sets the width to 50%:
     *        editor.execute( 'resizeImage', { width: '50%' } );
     *
     *        // Removes the width attribute:
     *        editor.execute( 'resizeImage', { width: null } );
     *
     * @param {Object} options
     * @param {String} options.value The new width of the image.
     * @fires execute
     */
    execute(options) {
        const editor = this.editor;
        const model = editor.model;
        const explorationModel = getClosestSelectedExploration2Element(editor.model.document.selection);

        this.value = options.value;

        if (!explorationModel) {
            return;
        }

        model.change((writer) => {
            writer.setAttribute('mode', options.value, explorationModel);
            writer.removeAttribute('offsetX', explorationModel);
            writer.removeAttribute('offsetY', explorationModel);
            writer.removeAttribute('zoom', explorationModel);
        });
    }
}

export function getClosestSelectedExploration2Element(selection) {
    const selectedElement = selection.getSelectedElement();

    if (selectedElement?.name === 'argonos-exploration') {
        return selectedElement;
    }

    return undefined;
}
