import { createContext, ReactNode, useCallback, useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Debug from 'debug';
import EventEmitter3 from 'eventemitter3';

import { ClassValue } from '../arg-hooks/use-classNames';

const debug = Debug('basic:ArgIconRepository');


export interface IconNode {
    render: (className?: ClassValue) => ReactNode;
}

interface InternalIconNode extends IconNode {
    linkCount: number;
    status?: string;
    clear?: () => void;
}

type IconNodeRepository = Record<string, InternalIconNode>;
type SvgNodeCache = Record<string, Node | false | EventEmitter3>;

export class ArgIconRepository {
    #iconNodeRepository: IconNodeRepository = {};
    #svgNodeCache: SvgNodeCache = {};

    link(data: string): IconNode {
        const node = this.#iconNodeRepository[data];
        if (node) {
            node.linkCount++;

            return node;
        }

        const reg = /^data:([^;]+);(base64|utf8),(.*)$/.exec(data);
        if (reg) {
            const [_, mimeType, format, buffer] = reg;
            switch (mimeType) {
                case 'image/svg+xml': {
                    const iconNode = this.svgIconNode(data, this.#svgNodeCache);
                    this.#iconNodeRepository[data] = iconNode;

                    return iconNode;
                }

                case 'image/png':
                case 'image/gif': {
                    const iconNode = this.imgBase64IconNode(data);
                    this.#iconNodeRepository[data] = iconNode;

                    return iconNode;
                }
            }
            throw new Error(`Unknown type ${reg[1]} format ${reg[2]}`);
        }

        if (data.charAt(0) === '<') {
            const iconNode = this.svgIconNode(`data:image/svg+xml;utf8,${data}`, this.#svgNodeCache);
            this.#iconNodeRepository[data] = iconNode;

            return iconNode;
        }

        const iconNode: InternalIconNode = {
            render() {
                return null;
            },
            linkCount: 1,
            status: 'Unknown type',
        };
        this.#iconNodeRepository[data] = iconNode;

        return iconNode;
    }

    unlink(data: string) {
        const node = this.#iconNodeRepository[data];
        if (!node) {
            console.warn('IconNode data=', data, ' is unknown ?');

            return;
        }

        node.linkCount--;
        if (node.linkCount) {
            return;
        }

        delete this.#iconNodeRepository[data];

        node.clear?.();
    }

    svgIconNode(data: string, svgDocumentCache: SvgNodeCache): InternalIconNode {
        const result: InternalIconNode = {
            render(className?: ClassValue) {
                const component = <IconSvgComponent
                    data={data}
                    svgNodeCache={svgDocumentCache}
                    className={className}
                />;

                return component;
            },
            clear() {
                delete svgDocumentCache[data];
            },
            linkCount: 1,
            status: 'SVG type',
        };

        return result;
    }

    imgBase64IconNode(buffer: string): InternalIconNode {
        const result: InternalIconNode = {
            render(className?: ClassValue) {
                const component = <img
                    className={classNames(className, 'arg-icon-inline', 'arg-icon-inline-image')}
                    src={buffer}
                />;

                return component;
            },
            linkCount: 1,
            status: 'Image type',
        };

        return result;
    }

    clear() {
        Object.values(this.#iconNodeRepository).forEach((icon) => {
            icon.clear?.();
        });
        this.#iconNodeRepository = {};
    }
}

/**
 * The Context
 */

const ArgIconRepositoryContext = createContext<ArgIconRepository | null>(null);


export function useIconRepository(): ArgIconRepository | undefined {
    const repository = useContext(ArgIconRepositoryContext);

    return repository || undefined;
}


/**
 * RepositoryProvider
 */

interface ArgIconRepositoryProps {
    children: ReactNode;
}

export function ArgIconRepositoryProvider(props: ArgIconRepositoryProps) {
    const {
        children,
    } = props;

    const iconRepositoryRef = useRef<ArgIconRepository>();
    if (!iconRepositoryRef.current) {
        iconRepositoryRef.current = new ArgIconRepository();
    }

    useEffect(() => {
        return () => {
            iconRepositoryRef.current?.clear();
        };
    }, []);

    return <ArgIconRepositoryContext.Provider value={iconRepositoryRef.current}>
        {children}
    </ArgIconRepositoryContext.Provider>;
}

/**
 * SVG Component
 */

interface IconSVGComponentProps {
    data: string;
    svgNodeCache: SvgNodeCache;
    className?: ClassValue;
}

function IconSvgComponent(props: IconSVGComponentProps) {
    const {
        data,
        svgNodeCache,
        className,
    } = props;

    const unmountedRef = useRef<boolean>(false);

    useEffect(() => {
        return () => {
            unmountedRef.current = true;
        };
    }, []);

    const ref = useCallback((ref: HTMLDivElement | null) => {
        if (!ref || unmountedRef.current) {
            return;
        }

        let svgNode: Node | false | EventEmitter3 | undefined = svgNodeCache[data];
        if (svgNode === false) {
            // Document is errored
            return;
        }

        if (svgNode instanceof Node) {
            ref.appendChild(svgNode.cloneNode(true));

            return;
        }

        if (!svgNode) {
            const emitter = new EventEmitter3();
            svgNode = emitter;
            svgNodeCache[data] = svgNode;

            debug('loadData', 'data=', data);

            const xhr = new XMLHttpRequest();
            xhr.open('GET', data);
            xhr.addEventListener('load', () => {
                debug('dataLoaded', 'unmountedRef=', unmountedRef.current);

                if (unmountedRef.current) {
                    return;
                }

                const svgNode = (xhr.responseXML?.documentElement) || false;
                if (!svgNode) {
                    console.error('Can not load content=', data);
                }

                svgNodeCache[data] = svgNode;
                emitter.emit('loaded', svgNode);
            });
            xhr.send(null);
        }

        svgNode.once('loaded', () => {
            if (unmountedRef.current) {
                return;
            }

            const svgNode = svgNodeCache[data];
            if (svgNode === false) {
                // Document is errored
                return;
            }
            if (svgNode instanceof Node) {
                ref.appendChild(svgNode.cloneNode(true));

                return;
            }
        });
    }, []);

    return <div className={classNames(className, 'arg-icon-inline', 'arg-icon-inline-svg')} ref={ref} />;
}
