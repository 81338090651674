import { FormikHelpers, useFormik } from 'formik';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'lodash';

import retentionPolicyConnector from 'src/settings/connectors/retention-policy-connector';
import {
    ArgButton,
    ArgCombo,
    ArgInputInteger,
    ArgModal,
    ArgRadio,
    ClassValue,
    useClassNames, useArgNotifications,
    useProgressMonitor,
} from 'src/components/basic';
import { FullOntology, FullOntologyLinkType, FullOntologyObjectType, OntologyMetaProperty } from '../../types';
import {
    RententionPolicyEffectFrom,
    RententionPolicyEffectUnit,
    RententionPolicyExpireAtProperty,
    RententionPolicyTimeToLive,
    RententionPolicyTimeToLiveBasedOnDelay,
    RententionPolicyTimeToLiveBasedOnProperty,
    RetentionPolicyActionEffectOnObject,
    RetentionPolicyActionEffectOnProperty,
    RetentionPolicyActionTargetKind,
    RetentionPolicyLinkKind,
    RetentionPolicyVertexEdge,
} from 'src/settings/universes/retention/types';
import { AddEditEdgeVertextRetentionDTO } from 'src/settings/models/dtoApi';

import './automatic-object-deletion-modal.less';

const CLASSNAME = 'automatic-object-deletion-modal';
const messages = defineMessages({
    title: {
        id: 'settings.automatic-object-deletion-modal.title',
        defaultMessage: 'Configure automatic deletion',
    },
    object: {
        id: 'settings.automatic-object-deletion-modal.object',
        defaultMessage: 'object',
    },
    relation: {
        id: 'settings.automatic-object-deletion-modal.relation',
        defaultMessage: 'relation',
    },
    description: {
        id: 'settings.automatic-object-deletion-modal.description',
        defaultMessage: '{type, select, Vertex {The values of the object type} Edge {The values of the relationship type} other {The value of the property}} type \"{name}\" {rule, select, Property {will be deleted} other {will be removed}} automatically according to the following rule',
    },
    submit: {
        id: 'settings.automatic-object-deletion-modal.submitButton',
        defaultMessage: 'Save',
    },
    cancel: {
        id: 'settings.automatic-object-deletion-modal.cancelButton',
        defaultMessage: 'Cancel',
    },
    notDeletion: {
        id: 'settings.automatic-object-deletion-modal.notDeletion',
        defaultMessage: 'No deletion',
    },
    delete: {
        id: 'settings.automatic-object-deletion-modal.delete',
        defaultMessage: 'Delete',
    },
    deleteAfter: {
        id: 'settings.automatic-object-deletion-modal.deleteAfter',
        defaultMessage: 'Delete after',
    },
    deleteOn: {
        id: 'settings.automatic-object-deletion-modal.deleteOn',
        defaultMessage: 'Delete on',
    },
    afterPeriod: {
        id: 'settings.automatic-object-deletion-modal.afterPeriod',
        defaultMessage: 'After a period',
    },
    onDate: {
        id: 'settings.automatic-object-deletion-modal.onDate',
        defaultMessage: 'On a date',
    },
    defined: {
        id: 'settings.automatic-object-deletion-modal.defined',
        defaultMessage: 'Defined',
    },
    propertyContent: {
        id: 'settings.automatic-object-deletion-modal.propertyContent',
        defaultMessage: 'Contained in the property',
    },
    hourLabel: {
        id: 'settings.automatic-object-deletion-modal.hourLabel',
        defaultMessage: 'Hour(s)',
    },
    dayLabel: {
        id: 'settings.automatic-object-deletion-modal.dayLabel',
        defaultMessage: 'Day(s)',
    },
    weekLabel: {
        id: 'settings.automatic-object-deletion-modal.weekLabel',
        defaultMessage: 'Week(s)',
    },
    monthLabel: {
        id: 'settings.automatic-object-deletion-modal.monthLabel',
        defaultMessage: 'Month(s)',
    },
    yearLabel: {
        id: 'settings.automatic-object-deletion-modal.yearLabel',
        defaultMessage: 'Year(s)',
    },
    placeholder: {
        id: 'settings.automatic-object-deletion-modal.placeholder',
        defaultMessage: 'Select',
    },
    editRetentionErrorMsg: {
        id: 'admin.automatic-object-deletion-modal.error-message.editing-retention',
        defaultMessage: 'Something went wrong while editing the retention',
    },
    sendingRetentionError: {
        id: 'settings.automatic-object-deletion-modal.error',
        defaultMessage: 'An error occurred while setting the retention',
    },
    noValueAvailable: {
        id: 'settings.automatic-object-deletion-modal.noValueAvailable',
        defaultMessage: 'No value available',
    },
    fromLabel: {
        id: 'settings.automatic-object-deletion-modal.fromLabel',
        defaultMessage: 'As of the date of',
    },
    fromCreated: {
        id: 'settings.automatic-object-deletion-modal.fromCreated',
        defaultMessage: 'Creation',
    },
    fromLastUpdated: {
        id: 'settings.automatic-object-deletion-modal.fromLastUpdated',
        defaultMessage: 'Last updated',
    },
});

type MessageType = { id: string; defaultMessage: string; };

type UnitType = RententionPolicyEffectUnit | undefined;

type RetentionPolicySelection = 'NO_DELETION' | 'DELETE_AFTER_DELAY' | 'DELETE_AFTER_PROPERTY' | 'DELETE_AT_DATE';

enum TypesList {
    VERTEX = 'Vertex',
    EDGE = 'Edge',
    PROPERTY = 'Property'
}

interface FormFields {
    selectOption: RetentionOptions;
    from: RententionPolicyEffectFrom;
    delay: number;
    deleteAfterUnit: MessageType;
    deleteRetentionProperty: FullOntologyObjectType | FullOntologyLinkType | OntologyMetaProperty | undefined;
    deleteRetentionUnit: MessageType;
    deleteOnDateProperty: FullOntologyObjectType | FullOntologyLinkType | OntologyMetaProperty | undefined;
}

interface RetentionPayload {
    choiceRetention: RetentionPolicySelection | undefined,
    unit: RententionPolicyEffectUnit | undefined,
    delay?: number,
    delayProperty?: string,
    expirePropertyName?: string,
    from: RententionPolicyEffectFrom;
}

type FormFieldsErrors = Partial<Record<keyof FormFields, boolean>>;

enum RetentionOptions {
    NO_DELETION = 'NO_DELETION',
    DELETE_AFTER_DELAY = 'DELETE_AFTER_DELAY',
    DELETE_AFTER_PROPERTY = 'DELETE_AFTER_PROPERTY',
    DELETE_AT_DATE = 'DELETE_AT_DATE'
}

const periodOptions = [
    messages.dayLabel,
    messages.weekLabel,
    messages.monthLabel,
    messages.yearLabel,
];

const PERIOD_LABEL = [
    'Day',
    'Week',
    'Month',
    'Year',
];

const isDate = (param: string) => param === 'Date' || param === 'DateTime';

const isNumeric = (param: string) => param === 'Byte' || param === 'Int' || param === 'Long';

const getUnit = (choice: RetentionOptions, unitAfterDelay: MessageType | undefined, unitAfterProperty: MessageType | undefined): UnitType => {
    switch (choice) {
        case RetentionOptions.DELETE_AFTER_DELAY:
            return unitAfterDelay && PERIOD_LABEL[periodOptions.findIndex(item => item.id === unitAfterDelay.id)] as UnitType;
        case RetentionOptions.DELETE_AFTER_PROPERTY:
            return unitAfterProperty && PERIOD_LABEL[periodOptions.findIndex(item => item.id === unitAfterProperty.id)] as UnitType;
    }
};

const revertUnit = (value: UnitType): MessageType => {
    switch (value) {
        case 'Month':
            return periodOptions[2];
        case 'Week':
            return periodOptions[1];
        case 'Day':
            return periodOptions[0];
        default:
            return periodOptions[3];
    }
};

function formatRetentionData(oldRetention: RetentionPolicyVertexEdge, data: RetentionPayload, retentionTarget: RetentionTarget = 'Vertex', property?: string): RetentionPolicyVertexEdge {
    const { delay, delayProperty, choiceRetention, unit, expirePropertyName, from } = data;

    let dataFomatted: RetentionPolicyVertexEdge = {
        effects: [],
    };

    const objectRetention = oldRetention.effects.find(item => item?.hasOwnProperty('object')) as RetentionPolicyActionEffectOnObject;
    let propertiesRetention = oldRetention.effects.filter(item => item?.hasOwnProperty('property')) as Array<RetentionPolicyActionEffectOnProperty>;

    if (choiceRetention === RetentionOptions.NO_DELETION) {
        if (retentionTarget === 'Property' && property) {
            const indexProperty = propertiesRetention.findIndex(item => item.property?.targetProperty === property);
            if (indexProperty >= 0) {
                propertiesRetention.splice(indexProperty, 1);
            }

            if (objectRetention) {
                dataFomatted = {
                    effects: [
                        objectRetention,
                        ...propertiesRetention,
                    ],
                } as RetentionPolicyVertexEdge;
            } else {
                dataFomatted = {
                    effects: [
                        ...propertiesRetention,
                    ],
                } as RetentionPolicyVertexEdge;
            }
        } else {
            dataFomatted = {
                effects: [
                    ...oldRetention.effects.filter(item => !item?.hasOwnProperty('object')),
                ],
            } as RetentionPolicyVertexEdge;
        }

        return dataFomatted;
    } else {
        const delayOrProperty = choiceRetention === RetentionOptions.DELETE_AFTER_DELAY ? {
            delay,
        } : {
            delayProperty,
        };

        const retention = choiceRetention === RetentionOptions.DELETE_AFTER_DELAY || choiceRetention === RetentionOptions.DELETE_AFTER_PROPERTY ? {
            timeToLive: {
                from,
                ...delayOrProperty,
                unit,
            } as unknown as RententionPolicyTimeToLive,
        } : {
            expireAtProperty: expirePropertyName,
        } as RententionPolicyExpireAtProperty;

        if (retentionTarget === 'Property' && property) {
            const newPropertyPolicy: RetentionPolicyActionEffectOnProperty = {
                property: {
                    targetProperty: property,
                    retention: retention as RententionPolicyTimeToLive | RententionPolicyExpireAtProperty,
                },
            };

            const indexPolicy = propertiesRetention.find(item => item.property?.targetProperty === property);

            if (indexPolicy) {
                propertiesRetention = propertiesRetention.map(item => {
                    if (item.property?.targetProperty === property) {
                        return newPropertyPolicy;
                    }

                    return item;
                });
            } else {
                propertiesRetention.push(newPropertyPolicy);
            }

            if (objectRetention) {
                dataFomatted = {
                    effects: [objectRetention, ...propertiesRetention],
                } as RetentionPolicyVertexEdge;
            } else {
                dataFomatted = {
                    effects: [...propertiesRetention],
                } as RetentionPolicyVertexEdge;
            }
        } else if (retentionTarget === 'Edge' || retentionTarget === 'Vertex') {
            const newObjectPolicy: RetentionPolicyActionEffectOnObject = {
                object: {
                    retention: retention as RententionPolicyTimeToLive | RententionPolicyExpireAtProperty,
                },
            };

            if (objectRetention) {
                const newPolicy = oldRetention.effects.map((item) => {
                    if (item?.hasOwnProperty('object')) {
                        return newObjectPolicy;
                    }

                    return item;
                });

                dataFomatted = {
                    effects: [...newPolicy],
                } as RetentionPolicyVertexEdge;
            } else {
                dataFomatted = {
                    effects: [...oldRetention.effects.map((item) => item), newObjectPolicy],
                } as RetentionPolicyVertexEdge;
            }
        }
    }

    return dataFomatted;
}

function isFieldDisabled(field: string, selected: string): boolean {
    return field !== selected;
}

const haveProperties = (properties: any[]): boolean => {
    return !isEmpty(properties);
};

export type RetentionTarget = RetentionPolicyActionTargetKind | 'Property';

interface AutomaticObjectDeletionModalProps {
    className?: ClassValue;
    closeModal: () => void;
    ontology: FullOntology;
    edgeOrVertex: FullOntologyObjectType | FullOntologyLinkType;
    retentionTarget: RetentionTarget;
    retentionLink: RetentionPolicyLinkKind;
    propertyName?: string;
    retention: RetentionPolicyVertexEdge;
    onChange?: Dispatch<SetStateAction<RetentionPolicyVertexEdge>>;
    property?: string;
}

export function AutomaticObjectDeletionModal(props: AutomaticObjectDeletionModalProps) {
    const {
        className,
        closeModal,
        ontology,
        edgeOrVertex,
        retentionTarget,
        retentionLink,
        propertyName,
        retention,
        onChange,
        property,
    } = props;

    const classNames = useClassNames(CLASSNAME);
    const notifications = useArgNotifications();

    const intl = useIntl();

    const [postRetentionMonitor, createPostRetentionMonitor] = useProgressMonitor();

    const name = retentionTarget === TypesList.PROPERTY ? propertyName : edgeOrVertex.displayName;

    const [retentionPropertiesOptions, datePropertiesOptions] = useMemo(() => {
        if (retentionTarget === TypesList.PROPERTY) {
            return [ontology.metaProperties.filter(p => isNumeric(p.baseType)), ontology.metaProperties.filter(p => isDate(p.baseType))];
        } else {
            return [edgeOrVertex.properties.filter(p => isNumeric(p.baseType)), edgeOrVertex.properties.filter(p => isDate(p.baseType))];
        }
    }, [edgeOrVertex.properties, ontology.metaProperties, retentionTarget]);

    const [_delay, _delayProperty, _unit, _expireAtProperty, _selectOption, _from] = useMemo(() => {
        let delay = undefined;
        let delayProperty = undefined;
        let unit = undefined;
        let expireAtProperty = undefined;
        let from: RententionPolicyEffectFrom = '_created_';

        if (retentionTarget === 'Property') {
            const propertiesRetention = retention.effects?.filter(item => item?.hasOwnProperty('property')) as Array<RetentionPolicyActionEffectOnProperty>;
            const propertyRetention = propertiesRetention.find(item => item.property?.targetProperty === property) as RetentionPolicyActionEffectOnProperty;
            if (propertyRetention) {
                expireAtProperty = (propertyRetention?.property?.retention as RententionPolicyExpireAtProperty)?.expireAtProperty;
                delay = ((propertyRetention?.property?.retention as RententionPolicyTimeToLive)?.timeToLive as RententionPolicyTimeToLiveBasedOnDelay)?.delay;
                unit = (propertyRetention?.property?.retention as RententionPolicyTimeToLive)?.timeToLive?.unit;
                delayProperty = ((propertyRetention?.property?.retention as RententionPolicyTimeToLive)?.timeToLive as RententionPolicyTimeToLiveBasedOnProperty)?.delayProperty;
                from = ((propertyRetention?.property?.retention as RententionPolicyTimeToLive)?.timeToLive as RententionPolicyTimeToLiveBasedOnProperty)?.from;
            }
        } else {
            const objectRetention = retention.effects?.find(item => item?.hasOwnProperty('object')) as RetentionPolicyActionEffectOnObject;
            if (objectRetention) {
                expireAtProperty = (objectRetention.object?.retention as RententionPolicyExpireAtProperty)?.expireAtProperty;
                delay = ((objectRetention.object?.retention as RententionPolicyTimeToLive)?.timeToLive as RententionPolicyTimeToLiveBasedOnDelay)?.delay;
                unit = (objectRetention?.object?.retention as RententionPolicyTimeToLive)?.timeToLive?.unit;
                delayProperty = ((objectRetention?.object?.retention as RententionPolicyTimeToLive)?.timeToLive as RententionPolicyTimeToLiveBasedOnProperty)?.delayProperty;
                from = ((objectRetention?.object?.retention as RententionPolicyTimeToLive)?.timeToLive as RententionPolicyTimeToLiveBasedOnProperty)?.from;
            }
        }

        const selectOption = delay && delay > 0 ?
            RetentionOptions.DELETE_AFTER_DELAY : delayProperty ?
                RetentionOptions.DELETE_AFTER_PROPERTY : expireAtProperty ?
                    RetentionOptions.DELETE_AT_DATE : RetentionOptions.NO_DELETION;

        return [delay, delayProperty, unit, expireAtProperty, selectOption, from];
    }, [property, retention, retentionTarget]);

    const getProperty = useCallback((name: string, choice: RetentionOptions): FullOntologyObjectType | FullOntologyLinkType | OntologyMetaProperty | undefined => {
        switch (choice) {
            case RetentionOptions.DELETE_AFTER_PROPERTY:
                return retentionPropertiesOptions[retentionPropertiesOptions.findIndex(item => item.name === name)];
            case RetentionOptions.DELETE_AT_DATE:
                return datePropertiesOptions[datePropertiesOptions.findIndex(item => item.name === name)];
        }
    }, [datePropertiesOptions, retentionPropertiesOptions]);

    const validateFrom = (values: FormFields) => {
        const errors: FormFieldsErrors = {};
        const {
            selectOption,
            delay,
            deleteAfterUnit,
            deleteRetentionProperty,
            deleteRetentionUnit,
            deleteOnDateProperty,
        } = values;

        if (!selectOption) {
            errors.selectOption = true;
        }

        switch (selectOption) {
            case RetentionOptions.DELETE_AFTER_DELAY:
                if (!delay || delay < 1) {
                    errors.delay = true;
                }
                if (!deleteAfterUnit) {
                    errors.deleteAfterUnit = true;
                }
                break;
            case RetentionOptions.DELETE_AFTER_PROPERTY:
                if (!deleteRetentionProperty) {
                    errors.deleteRetentionProperty = true;
                }
                if (!deleteRetentionUnit) {
                    errors.deleteRetentionUnit = true;
                }
                break;

            case RetentionOptions.DELETE_AT_DATE:
                if (!deleteOnDateProperty) {
                    errors.deleteOnDateProperty = true;
                }
                break;
        }

        return errors;
    };

    const submitForm = async (values: FormFields, { resetForm }: FormikHelpers<FormFields>) => {
        const {
            selectOption,
            delay,
            deleteAfterUnit,
            deleteRetentionProperty,
            deleteRetentionUnit,
            deleteOnDateProperty,
            from,
        } = values;

        const progressMonitor = createPostRetentionMonitor(messages.sendingRetentionError, 1, {
            global: true,
        });

        let dataFormatted: RetentionPolicyVertexEdge = {
            effects: [],
        };

        const data: RetentionPayload = {
            from,
            choiceRetention: selectOption,
            unit: selectOption && getUnit(selectOption, deleteAfterUnit, deleteRetentionUnit),
            delay,
            delayProperty: deleteRetentionProperty?.name,
            expirePropertyName: deleteOnDateProperty?.name,
        };
        dataFormatted = formatRetentionData(retention, data, retentionTarget, property);

        const payload: AddEditEdgeVertextRetentionDTO = {
            ontologyId: ontology.id,
            edgeOrVertexName: edgeOrVertex.name,
            retentionLinkKind: retentionLink,
            retention: dataFormatted,
        };

        try {
            await retentionPolicyConnector.addEditEdgeVertexRetention(payload);
            progressMonitor.done();
            onChange?.(dataFormatted);
            resetForm();
            closeModal();
        } catch (error) {
            if (progressMonitor.isCancelled) return;
            notifications.snackError({ message: messages.editRetentionErrorMsg }, error as Error);
        } finally {
            progressMonitor.done();
        }
    };

    const {
        handleSubmit,
        setFieldValue,
        values: formValues,
        isValid,
    } = useFormik<FormFields>({
        initialValues: {
            selectOption: _selectOption,
            from: _from || '_created_',
            delay: _delay || 0,
            deleteAfterUnit: _selectOption === RetentionOptions.DELETE_AFTER_DELAY ? revertUnit(_unit) : periodOptions[3],
            deleteRetentionProperty: _delayProperty ? getProperty(_delayProperty, _selectOption) : undefined,
            deleteRetentionUnit: _selectOption === RetentionOptions.DELETE_AFTER_PROPERTY ? revertUnit(_unit) : periodOptions[3],
            deleteOnDateProperty: _expireAtProperty ? getProperty(_expireAtProperty, _selectOption) : undefined,
        },
        validateOnMount: true,
        validateOnChange: true,
        validate: validateFrom,
        onSubmit: submitForm,
    });

    return (
        <div className={classNames('&', className)}>
            <ArgModal
                size='medium'
                title={<span className={classNames('&-title')}>{intl.formatMessage(messages.title)}</span>}
                onClose={closeModal}
                cancelText={messages.cancel}
                okText={messages.submit}
                onOk={() => handleSubmit()}
                okDisabled={!isValid || postRetentionMonitor?.isRunning}
                className={classNames('&-modal')}
            >
                <form onSubmit={handleSubmit} autoComplete='off'>
                    <label className={classNames('&-description')}>
                        <FormattedMessage {...messages.description} values={{
                            type: retentionTarget,
                            name: <span className='name'>{name}</span>,
                            rule: retentionTarget,
                        }} />
                    </label>
                    <div className={classNames('&-flex', '&-align-center')}>
                        <label className={classNames('&-fromLabel')}>
                            <FormattedMessage {...messages.fromLabel} />
                        </label>

                        <div className={classNames('&-element-switcher')}>
                            <ArgButton
                                className={classNames('&-element-switcher-from-created', {
                                    inactive: formValues.from !== '_created_',
                                })}
                                size='medium'
                                label={messages.fromCreated}
                                type={formValues.from === '_created_' ? 'primary' : 'ghost'}
                                onClick={() => setFieldValue('from', '_created_')}
                            />
                            <ArgButton
                                className={classNames('&-element-switcher-from-updated', {
                                    inactive: formValues.from !== '_updated_',
                                })}
                                size='medium'
                                label={messages.fromLastUpdated}
                                type={formValues.from === '_updated_' ? 'primary' : 'ghost'}
                                onClick={() => setFieldValue('from', '_updated_')}
                            />
                        </div>
                    </div>

                    <ArgRadio
                        className={classNames('&-radio')}
                        value={formValues.selectOption === RetentionOptions.NO_DELETION}
                        label={messages.notDeletion}
                        onChange={() => setFieldValue('selectOption', RetentionOptions.NO_DELETION)}
                        id={RetentionOptions.NO_DELETION}
                    />

                    <div className={classNames('&-flex', '&-row')}>
                        <ArgRadio
                            className={classNames('&-radio')}
                            value={formValues.selectOption === RetentionOptions.DELETE_AFTER_DELAY}
                            label={messages.deleteAfter}
                            onChange={() => setFieldValue('selectOption', RetentionOptions.DELETE_AFTER_DELAY)}
                        />
                        <ArgInputInteger
                            className={classNames('&-value-number', '&-bordered')}
                            min={1}
                            value={formValues.delay || 0}
                            onChange={(value) => setFieldValue('delay', value)}
                            displayRightControl={false}
                            clearable={false}
                            disabled={isFieldDisabled(formValues.selectOption, RetentionOptions.DELETE_AFTER_DELAY)}
                        />
                        <ArgCombo
                            className={classNames('&-value-unit', '&-bordered')}
                            items={periodOptions}
                            getItemLabel={(item) => intl.formatMessage(item)}
                            getItemKey={(item) => intl.formatMessage(item)}
                            value={formValues.deleteAfterUnit}
                            onChange={(value) => setFieldValue('deleteAfterUnit', value)}
                            placeholder={messages.yearLabel}
                            disabled={isFieldDisabled(formValues.selectOption, RetentionOptions.DELETE_AFTER_DELAY)}
                        />
                    </div>

                    <div className={classNames('&-flex', '&-row')}>
                        <ArgRadio
                            className={classNames('&-radio')}
                            value={formValues.selectOption === RetentionOptions.DELETE_AFTER_PROPERTY}
                            label={messages.deleteAfter}
                            onChange={() => setFieldValue('selectOption', RetentionOptions.DELETE_AFTER_PROPERTY)}
                            disabled={!haveProperties(retentionPropertiesOptions)}
                        />
                        {haveProperties(retentionPropertiesOptions) ? (<ArgCombo
                            className={classNames('&-value-retention', '&-bordered')}
                            items={retentionPropertiesOptions}
                            value={formValues.deleteRetentionProperty}
                            onChange={(value) => setFieldValue('deleteRetentionProperty', value)}
                            getItemLabel={(item) => item.displayName}
                            getItemKey={(item) => item.displayName}
                            placeholder={messages.placeholder}
                            disabled={isFieldDisabled(formValues.selectOption, RetentionOptions.DELETE_AFTER_PROPERTY) || !haveProperties(retentionPropertiesOptions)}
                        />) : (
                            <span
                                className={classNames('&-noValue')}>{intl.formatMessage(messages.noValueAvailable)}</span>
                        )}

                        <ArgCombo
                            className={classNames('&-value-unit', '&-bordered')}
                            items={periodOptions}
                            getItemLabel={(item) => intl.formatMessage(item)}
                            getItemKey={(item) => item.id}
                            value={formValues.deleteRetentionUnit}
                            onChange={(value) => setFieldValue('deleteRetentionUnit', value)}
                            placeholder={messages.yearLabel}
                            disabled={isFieldDisabled(formValues.selectOption, RetentionOptions.DELETE_AFTER_PROPERTY) || !haveProperties(retentionPropertiesOptions)}
                        />
                    </div>

                    <div className={classNames('&-flex', '&-row')}>
                        <ArgRadio
                            className={classNames('&-radio')}
                            value={formValues.selectOption === RetentionOptions.DELETE_AT_DATE}
                            label={messages.deleteOn}
                            onChange={() => setFieldValue('selectOption', RetentionOptions.DELETE_AT_DATE)}
                            id={RetentionOptions.DELETE_AT_DATE}
                            disabled={!haveProperties(datePropertiesOptions)}
                        />
                        {haveProperties(datePropertiesOptions) ? (<ArgCombo
                            className={classNames('&-value-date', '&-bordered')}
                            items={datePropertiesOptions}
                            value={formValues.deleteOnDateProperty}
                            onChange={(value) => setFieldValue('deleteOnDateProperty', value)}
                            getItemLabel={(item) => item.displayName}
                            getItemKey={(item) => item.displayName}
                            placeholder={messages.placeholder}
                            disabled={isFieldDisabled(formValues.selectOption, RetentionOptions.DELETE_AT_DATE) || !haveProperties(datePropertiesOptions)}
                        />) : (<span
                            className={classNames('&-noValue')}>{intl.formatMessage(messages.noValueAvailable)}</span>)}

                    </div>

                </form>
            </ArgModal>
        </div>
    );
}
