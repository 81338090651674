import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';
import { isNil } from 'lodash';
import { NumberSize, Resizable, ResizeDirection, Size } from 're-resizable';

import {
    ArgButton,
    ArgInputSearch,
    ArgUploaderButton,
    ArgUserInfo,
    LEFT_ENABLE,
    normalizeText,
    ProgressMonitor,
    SubProgressMonitor,
    useArgModalContext,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { Role, RoleId, RolesScope } from 'src/settings/models/dtoApi';
import { RolePermissions } from './role-permissions';
import { RolesList } from './roles-list';
import { DateByUser } from 'src/components/common/date-by-user';
import { ExportRolesModal } from '../components/export-roles-modal';
import { useHasPermission } from 'src/contexts/user-permission';
import { SettingsPermissions } from 'src/settings/permissions/permissions';
import { ImportAction } from 'src/model/configuration';
import { ConfigurationType } from 'src/settings/configuration/configuration-type';
import { booleanSorter, stringSorter } from 'src/utils/sorter';
import { LoadingPane } from '../../../components/common/panes/loading-pane';
import { ErrorPane } from '../../../components/common/panes/error-pane';
import { ExplorationPermissions } from '../../../exploration/model/permissions';
import { useUserConfiguration } from '../../../hooks/use-user-configuration';
import configurationConnector from 'src/utils/connectors/configuration-connector';
import { SettingsConnector } from '../../connectors/settings-connector';

import './roles-manager.less';

const FORCE_LOADING = false;
const FORCE_ERROR = false;

const messages = defineMessages({
    publish: {
        id: 'settings.roles-manager.publish',
        defaultMessage: 'Publish',
    },
    reset: {
        id: 'settings.roles-manager.reset',
        defaultMessage: 'Reset',
    },
    published: {
        id: 'settings.roles-manager.published',
        defaultMessage: 'Published',
    },
    import: {
        id: 'settings.roles-manager.import',
        defaultMessage: 'Import',
    },
    export: {
        id: 'settings.roles-manager.export',
        defaultMessage: 'Export',
    },
    invalidFileType: {
        id: 'settings.roles-manager.invalidFileType',
        defaultMessage: 'Invalid file type',
    },
    importRolesError: {
        id: 'settings.roles-manager.importRolesError',
        defaultMessage: 'An error occurred while importing the roles',
    },
    loadRolesError: {
        id: 'settings.roles-manager.loadRolesError',
        defaultMessage: 'An error occurred while loading the roles',
    },
    publishRolesError: {
        id: 'settings.roles-manager.publishRolesError',
        defaultMessage: 'An error occurred while publishing the roles',
    },
    resetRolesError: {
        id: 'settings.roles-manager.resetRolesError',
        defaultMessage: 'An error occurred while resetting the roles',
    },
});

const LEFT_DEFAULT_SIZE: Size = {
    width: '20%',
    height: 'auto',
};

const SUPPORTED_IMPORT_MIME_TYPES = [
    'application/x-zip-compressed', 'application/zip',
];

interface GlobalRolesLastUpdate {
    lastUpdateDate: Date;
    lastUpdateBy: ArgUserInfo;
}

export interface RolesManagerProps {
    selectedRole: Role | undefined;
    setSelectedRole: Dispatch<SetStateAction<Role | undefined>>;
    rolesScope: RolesScope;
}

export function RolesManager(props: RolesManagerProps) {
    const {
        rolesScope,
        selectedRole,
        setSelectedRole,
    } = props;

    const classNames = useClassNames('settings-role-manager');
    const notifications = useArgNotifications();

    const [roles, setRoles] = useState<Role[]>();
    const [permissionFilter, setPermissionFilter] = useState<string>();
    const [roleFilter, setRoleFilter] = useState<string>();
    const modalContainer = useArgModalContext();

    const canEditRoles = useHasPermission<SettingsPermissions>('admin.user.role.edition');
    const canImportExportAdmin = useHasPermission<SettingsPermissions>('admin.import.export.settings');
    const canImportExportExplo = useHasPermission<ExplorationPermissions>('exploration.import.export.settings');

    const [leftPanelWidth, setLeftPanelWidth] = useUserConfiguration<string | number>(
        `settings.ui.roles-manager.panel.width.${rolesScope}`,
        LEFT_DEFAULT_SIZE.width
    );

    const [loadRoles, loadingRoles, errorRoles] = useCallbackAsync(async (progressMonitor: ProgressMonitor, roleId?: RoleId) => {
        try {
            const userRoles = await SettingsConnector.getInstance().getRoles(true, rolesScope, progressMonitor);
            setRoles(userRoles
                .filter((role) => role.scope === rolesScope)
                .sort((a, b) => {
                    const sortResult = booleanSorter<Role>(a, b, (item) => item.readOnly);

                    return sortResult === 0 ? stringSorter<Role>(a, b, (item) => item.displayName) : sortResult;
                }));

            if (roleId) {
                setSelectedRole(userRoles.find((role) => role.id === roleId));
            }
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.loadRolesError }, error as Error);
            throw error;
        }
    }, [notifications, rolesScope, setSelectedRole]);

    const [publishRoles, progressMonitorPublish] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            await SettingsConnector.getInstance().publishUserRoles(rolesScope, progressMonitor);

            await loadRoles();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.publishRolesError }, error as Error);
            throw error;
        }
    }, [loadRoles, notifications, rolesScope]);

    const [resetRoles, progressMonitorReset] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            await SettingsConnector.getInstance().resetUserRoles(rolesScope, progressMonitor);

            await loadRoles(selectedRole?.id);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.resetRolesError }, error as Error);
            throw error;
        }
    }, [loadRoles, notifications, rolesScope, selectedRole?.id]);

    const handlePermissionUpdate = useCallback((roleId: RoleId, progressMonitor: ProgressMonitor) => {
        if (!roles) {
            return;
        }

        const updatedRoleIndex = roles.findIndex((role) => role.id === roleId);
        if (updatedRoleIndex >= 0) {
            const updatedRoles = [...roles];
            updatedRoles[updatedRoleIndex] = {
                ...updatedRoles[updatedRoleIndex],
                hasDraft: true,
            };
            setRoles(updatedRoles);
        }
    }, [roles]);

    useEffect(() => {
        if (!rolesScope) {
            return;
        }

        loadRoles().catch((error) => {
            console.error(error);
        });
    }, [rolesScope]);

    const canImportExport = useMemo(() => {
        if (rolesScope === 'admin') {
            return canImportExportAdmin && canEditRoles;
        }

        if (rolesScope === 'data_exploration') {
            return canImportExportExplo;
        }
    }, [canEditRoles, canImportExportAdmin, canImportExportExplo, rolesScope]);

    const handlePermissionFilter = useCallback((filter: string) => {
        setPermissionFilter(filter);
    }, []);

    const handleRoleFilter = useCallback((filter: string) => {
        setRoleFilter(filter);
    }, []);

    const filteredRoleList = useMemo(() => {
        const filteredList = roles?.filter((role) => {
            return !(roleFilter && normalizeText(role.displayName).indexOf(normalizeText(roleFilter)) === -1);
        });

        return filteredList;
    }, [roleFilter, roles]);

    const [loadImportedRoles] = useCallbackAsync(async (progressMonitor: ProgressMonitor, file: Blob) => {
        if (!file || !SUPPORTED_IMPORT_MIME_TYPES.includes(file.type)) {
            notifications.snackError({ message: messages.invalidFileType });

            throw new Error('Invalid type');
        }

        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            const manifest = await configurationConnector.importManifest(file, rolesScope, sub1);
            const confType = manifest.configurations.find((conf) => conf.type === ConfigurationType.EnvironmentRole);

            const configurationTypeKeys = confType?.configurations.map((conf) => {
                return conf.id;
            });

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            await configurationConnector.importConfigurations(
                file,
                {
                    options: [
                        {
                            type: ConfigurationType.EnvironmentRole,
                            configurationKeys: configurationTypeKeys?.length ? configurationTypeKeys : [],
                            options: {},
                        },
                    ],
                },
                ImportAction.RenameConflicts,
                rolesScope,
                sub2
            );

            await loadRoles();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.importRolesError }, error as Error);
            throw error;
        }
    }, [loadRoles, notifications, rolesScope]);

    const globalLastUpdate: GlobalRolesLastUpdate | undefined = useMemo(() => {
        if (!roles) {
            return undefined;
        }

        const sortedUpdatedRoles = [...roles].sort((role1, role2) => {
            return (role2.publishDate?.getTime() ?? 0) - (role1.publishDate?.getTime() ?? 0);
        });

        const lastUpdatedRole = sortedUpdatedRoles[0];

        if (!lastUpdatedRole || isNil(lastUpdatedRole.publishDate) || lastUpdatedRole.publishDate?.getFullYear() === 1) {
            return undefined;
        }

        const lastUpdate: GlobalRolesLastUpdate = {
            lastUpdateDate: lastUpdatedRole.publishDate,
            lastUpdateBy: lastUpdatedRole.lastPublishBy,
        };

        return lastUpdate;
    }, [roles]);

    const handleLeftPanelResized = useCallback((event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLElement, delta: NumberSize) => {
        const bounds = elementRef.getBoundingClientRect();

        setLeftPanelWidth(bounds.width);
    }, [setLeftPanelWidth]);

    if (errorRoles || FORCE_ERROR) {
        return <div className={classNames('&', 'error')}>
            <ErrorPane className={classNames('&-error')} />
        </div>;
    }
    if (loadingRoles?.isRunning || FORCE_LOADING) {
        return <div className={classNames('&', 'loading')}>
            <LoadingPane className={classNames('&-loading')} />
        </div>;
    }

    return <div className={classNames('&')}>
        <div className={classNames('&-top-bar')}>
            {canImportExport && <div className={classNames('&-import-export')}>
                <ArgUploaderButton
                    type='ghost'
                    size='large'
                    className={classNames('&-import-button')}
                    icon='icon-download'
                    label={messages.import}
                    acceptedFiles='.zip'
                    method={(file: Blob) => loadImportedRoles(file)}
                />
                <ArgButton
                    className={classNames('&-export-button')}
                    icon='icon-upload'
                    size='large'
                    type='ghost'
                    onClick={() => modalContainer.open('settings-export-roles', <ExportRolesModal
                        rolesScope={rolesScope}
                        onClose={() => modalContainer.close('settings-export-roles')}
                    />)}
                    label={messages.export}
                />
            </div>}
            {globalLastUpdate &&
                <DateByUser
                    date={globalLastUpdate.lastUpdateDate}
                    user={globalLastUpdate.lastUpdateBy}
                    datePrefixMessage={messages.published}
                />}
            {canEditRoles && (
                <>
                    <ArgButton
                        className={classNames('&-publish-button')}
                        type='primary'
                        onClick={publishRoles}
                        label={messages.publish}
                        loading={progressMonitorPublish?.isRunning || progressMonitorReset?.isRunning}
                    />
                    <ArgButton
                        className={classNames('&-reset-button')}
                        type='secondary'
                        onClick={resetRoles}
                        label={messages.reset}
                        loading={progressMonitorPublish?.isRunning || progressMonitorReset?.isRunning}
                    />
                </>
            )}
        </div>
        <div className={classNames('&-container')}>
            <Resizable
                key='left-panel' className={classNames('&-body-left-panel')}
                defaultSize={{
                    width: leftPanelWidth,
                    height: 'auto',
                }}
                onResizeStop={handleLeftPanelResized}
                minWidth='20%'
                maxWidth='50%'
                enable={LEFT_ENABLE}
            >
                <div className={classNames('&-list')}>
                    <ArgInputSearch
                        onInputChange={handleRoleFilter}
                        className={classNames('&-list-search-bar')}
                    />
                    <RolesList
                        className={classNames('&-list-roles')}
                        searchToken={roleFilter}
                        rolesScope={rolesScope}
                        selectedRole={selectedRole}
                        setSelectedRole={setSelectedRole}
                        roles={filteredRoleList}
                        handleOnRoleChange={
                            // Fix loadRoles not handling passed in progressMonitor
                            (_progressMonitor: ProgressMonitor, roleId?: RoleId) => loadRoles(roleId)
                        }
                    />
                </div>
            </Resizable>
            <div className={classNames('&-permissions')}>
                <ArgInputSearch
                    onInputChange={handlePermissionFilter}
                    className={classNames('&-permissions-search-bar')}
                />
                <RolePermissions
                    className={classNames('&-permissions-tree')}
                    rolesScope={rolesScope}
                    selectedRole={selectedRole}
                    setSelectedRole={setSelectedRole}
                    filter={permissionFilter}
                    onPermissionUpdate={handlePermissionUpdate}
                />
            </div>
        </div>
    </div>;
}
