import { MutableRefObject } from 'react';

import {
    ArgInputInteger,
    ArgInputNumber,
    ArgInputText,
    ArgSwitch,
    messages as argSwitchMessages,
} from 'src/components/basic';
import { ContextualVariable, ContextualVariableType } from 'src/exploration/model/contextual-variable';
import {
    HourInput,
} from '../../universes/common/application-conditions/application-condition-expression-of-logic/hour-input';

interface VariableValueControlProps {
    variableRef: MutableRefObject<ContextualVariable>;
    refreshRef: () => void;
}

export function VariableValueControl(props: VariableValueControlProps) {
    const {
        variableRef,
        refreshRef,
    } = props;

    switch (variableRef.current.type) {
        case ContextualVariableType.bool:
            return (
                <ArgSwitch
                    checked={variableRef.current.value as boolean}
                    onChange={(value) => {
                        variableRef.current.value = value;
                        refreshRef();
                    }}
                    size='medium'
                    label={
                        variableRef.current.value
                            ? argSwitchMessages.active
                            : argSwitchMessages.inactive
                    }
                />
            );
        case ContextualVariableType.string:
            return (
                <ArgInputText
                    size='medium'
                    value={variableRef.current.value as string}
                    onInputChange={(value) => (variableRef.current.value = value)}
                />
            );
        case ContextualVariableType.hour:
            return (
                <HourInput
                    value={variableRef.current.value as number | undefined}
                    onChange={(value) => (variableRef.current.value = value || undefined)}
                />
            );

        case ContextualVariableType.int:
            return (
                <ArgInputInteger
                    size='medium'
                    numberType='number'
                    value={variableRef.current.value as number}
                    displayRightControl={true}
                    onChange={(value) => {
                        variableRef.current.value = value !== null ? value : undefined;
                        refreshRef();
                    }}
                />
            );
        case ContextualVariableType.decimal:
            return (
                <ArgInputNumber
                    size='medium'
                    numberType='number'
                    value={variableRef.current.value as number}
                    displayRightControl={true}
                    step={0.1}
                    onChange={(value) => {
                        variableRef.current.value = value !== null ? value : undefined;
                        refreshRef();
                    }}
                />
            );
    }
}
