import { useCallback, useRef, useState } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgFormLabel,
    ArgInputText,
    ArgResourcesConfiguration,
    ArgResourcesContext,
    ClassValue,
    ProgressMonitor,
    useClassNames,
} from 'src/components/basic';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';
import InputImageManager from 'src/proceo/common/input/input-image-manager';
import { Service } from '../../../model/service';
import { ProceoResourceConnector } from '../../../utils/connectors/proceo-resource-connector';
import { getProceoApi } from '../../../../utils/connectors/api-url';
import { AddressFormLabel } from '../../../../components/common/address-form-label';
import { Address } from '../../../../model/address';

import './hierarchy-service-modal.less';

const CLASSNAME = 'proceo-hierarchy-service-modal';

const messages = defineMessages({
    createServiceModalTitle: {
        id: 'proceo.hierarchy.modal.service.form.CreateServiceModalTitle',
        defaultMessage: 'Create a service',
    },
    editServiceModalTitle: {
        id: 'proceo.hierarchy.modal.service.form.EditServiceModalTitle',
        defaultMessage: 'Edit a service',
    },
    createServiceHierarchyError: {
        id: 'proceo.hierarchy.modal.service.form.CreateServiceHierarchyError',
        defaultMessage: 'Failed to create the service',
    },

    createNameTitle: {
        id: 'proceo.hierarchy.modal.service.form.CreateNameTitle',
        defaultMessage: 'Name',
    },
    createNamePlaceholder: {
        id: 'proceo.hierarchy.modal.service.form.CreateNamePlaceholder',
        defaultMessage: 'name',
    },

    createCodeTitle: {
        id: 'proceo.hierarchy.modal.service.form.CreateCodeTitle',
        defaultMessage: 'Code',
    },
    createCodePlaceholder: {
        id: 'proceo.hierarchy.modal.service.form.CreateCodePlaceholder',
        defaultMessage: 'XXXXX-Code',
    },

    createPhoneNumberTitle: {
        id: 'proceo.hierarchy.modal.form.CreatePhoneNumberTitle',
        defaultMessage: 'Phone',
    },
    createPhoneNumberPlaceholder: {
        id: 'proceo.hierarchy.modal.form.CreateServicePhoneNumberPlaceholder',
        defaultMessage: '+44 XX XX XX XX',
    },
    createFaxNumberTitle: {
        id: 'proceo.hierarchy.modal.form.CreateFaxNumberTitle',
        defaultMessage: 'Fax number',
    },
    createFaxNumberPlaceholder: {
        id: 'proceo.hierarchy.modal.form.CreateServiceFaxNumberPlaceholder',
        defaultMessage: '+44 161 999 8888',
    },

    createStampTitle: {
        id: 'proceo.hierarchy.modal.form.CreateStampTitle',
        defaultMessage: 'Stamp',
    },
});

const API_CONFIGURATION: ArgResourcesConfiguration = {
    getServerApi: () => getProceoApi(),
};

export type HierarchyServiceModalSubmitType = (
    progressMonitor: ProgressMonitor,
    name: string,
    code: string,
    phoneNumber: string,
    address: Address,
    newImage: Blob | undefined | null,
    faxNumber?: string) => Promise<void>;

interface HierarchyServiceModalProps {
    className?: ClassValue;
    service?: Service;
    loadingProgressMonitor?: ProgressMonitor;
    onClose: () => void;
    onSubmit: HierarchyServiceModalSubmitType;
}

export function HierarchyServiceModal(props: HierarchyServiceModalProps) {
    const {
        className,
        onClose,
        onSubmit,
        service,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const [name, setName] = useState(service?.name || '');
    const [code, setCode] = useState(service?.code || '');
    const [phoneNumber, setPhoneNumber] = useState(service?.phoneNumber || '');
    const [faxNumber, setFaxNumber] = useState(service?.faxNumber);
    const [streetNumber, setStreeNumber] = useState(service?.address?.streetNumber || '');
    const [streetName, setStreetName] = useState(service?.address?.streetName || '');
    const [streetType, setStreetType] = useState(service?.address?.streetType || '');
    const [additionalAddress, setAdditionalAddress] = useState(service?.address?.additionalAddress || '');
    const [zipCode, setZipCode] = useState(service?.address?.zipCode || '');
    const [city, setCity] = useState(service?.address?.city || '');
    const [country, setCountry] = useState(service?.address?.country || '');
    const isEditMode = !!service;

    const newImageRef = useRef<Blob | null>();

    const handleImageChange = useCallback((image?: Blob) => {
        newImageRef.current = image || null;
    }, []);

    const modalContent = (
        <ArgResourcesContext.Provider value={API_CONFIGURATION}>
            <div className={classNames('&-fields')}>
                <ArgFormLabel
                    key='name'
                    className={classNames('&-name-field')}
                    size='medium'
                    propertyName={messages.createNameTitle}
                >
                    <ArgInputText
                        value={name}
                        onInputChange={(val) => setName(val)}
                        className={classNames('&-header-search')}
                        placeholder={messages.createNamePlaceholder}
                    />
                </ArgFormLabel>
                <ArgFormLabel
                    key='code'
                    className={classNames('&-code-field')}
                    size='medium'
                    propertyName={messages.createCodeTitle}
                >
                    <ArgInputText
                        value={code}
                        onInputChange={(val) => setCode(val)}
                        className={classNames('&-header-search')}
                        placeholder={messages.createCodePlaceholder}
                    />
                </ArgFormLabel>
                <ArgFormLabel
                    key='phone-number'
                    className={classNames('&-phone-number-field')}
                    size='medium'
                    propertyName={messages.createPhoneNumberTitle}
                >
                    <ArgInputText
                        value={phoneNumber}
                        onInputChange={(val) => setPhoneNumber(val)}
                        placeholder={messages.createPhoneNumberPlaceholder}
                        className={classNames('&-header-search')}
                    />
                </ArgFormLabel>
                <AddressFormLabel
                    className={classNames('&-address-field')}
                    streetName={streetName}
                    setStreetName={setStreetName}
                    streetNumber={streetNumber}
                    setStreetNumber={setStreeNumber}
                    streetType={streetType}
                    setStreetType={setStreetType}
                    city={city}
                    setCity={setCity}
                    zipCode={zipCode}
                    setZipCode={setZipCode}
                    country={country}
                    setCountry={setCountry}
                    additionalAddress={additionalAddress}
                    setAdditionalAddress={setAdditionalAddress}
                />
                <ArgFormLabel
                    key='faxNumber'
                    className={classNames('&-faxNumber-field')}
                    size='medium'
                    propertyName={messages.createFaxNumberTitle}
                >
                    <ArgInputText
                        value={faxNumber}
                        onInputChange={(val) => setFaxNumber(val)}
                        placeholder={messages.createFaxNumberPlaceholder}
                        className={classNames('&-header-search')}
                    />
                </ArgFormLabel>
                <ArgFormLabel
                    key='stampId'
                    className={classNames('&-stampId-field')}
                    size='medium'
                    propertyName={messages.createStampTitle}>
                    <InputImageManager
                        initialImageBlobUrl={service?.stampId ? ProceoResourceConnector.getInstance().computeResourceUrl(service.stampId) : undefined}
                        onImageChange={handleImageChange}
                    />
                </ArgFormLabel>
            </div>
        </ArgResourcesContext.Provider>
    );

    const handleOnConfirm = useCallback(async (progressMonitor: ProgressMonitor) => {
        const address: Address = {
            streetName: streetName,
            streetNumber: streetNumber,
            streetType: streetType,
            city: city,
            zipCode: zipCode,
            country: country,
            additionalAddress: additionalAddress,
        };
        await onSubmit(progressMonitor, name, code, phoneNumber, address, newImageRef.current, faxNumber);
    }, [streetName, streetNumber, streetType, city, zipCode, country, additionalAddress, onSubmit, name, code, phoneNumber, faxNumber]);

    return <ConfirmModal
        size='xlarge'
        title={isEditMode ? messages.editServiceModalTitle : messages.createServiceModalTitle}
        type='validate'
        onClose={onClose}
        onConfirm={handleOnConfirm}
        bodyClassName={classNames('&-confirm-modal-body')}
        confirmDisabled={!(name && code && phoneNumber && streetName && zipCode && city && country)}
    >
        <div className={classNames('&', className)}>
            {modalContent}
        </div>
    </ConfirmModal>;
}
