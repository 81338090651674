import { isEmpty, isNil } from 'lodash';
import { useCallback, useState } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgFormLabel,
    ArgInputText,
    ArgInputTextArea,
    ArgModal,
    ButtonClickEvent,
    ProgressMonitor,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { RoleId, RolesScope } from 'src/settings/models/dtoApi';
import { SettingsConnector } from '../../connectors/settings-connector';

import './add-role-modal.less';

const messages = defineMessages({
    title: {
        id: 'settings.add-role-modal.title',
        defaultMessage: 'Add role',
    },
    name: {
        id: 'settings.add-role-modal.name',
        defaultMessage: 'Role name',
    },
    description: {
        id: 'settings.add-role-modal.description',
        defaultMessage: 'Description',
    },
    invalidNameDescription: {
        id: 'settings.add-role-modal.invalidName',
        defaultMessage: 'Name already exists',
    },
    validate: {
        id: 'settings.add-role-modal.okButton',
        defaultMessage: 'Validate',
    },
});

export interface AddRoleModalProps {
    rolesScope: RolesScope;
    onClose: () => void;
    handleOnRoleCreated: (roleId: RoleId, progressMonitor: ProgressMonitor) => void;
    isRoleNameInvalid?: (value?: string) => boolean;
}

export const AddRoleModal = ((props: AddRoleModalProps) => {
    const { rolesScope, onClose, handleOnRoleCreated, isRoleNameInvalid } = props;

    const classNames = useClassNames('settings-add-role-modal');
    const [name, setName] = useState<string>();
    const [description, setDescription] = useState<string>();

    const isNameInvalid = isRoleNameInvalid?.(name);

    const [callSubmit, progressMonitor] = useCallbackAsync(
        async (progressMonitor: ProgressMonitor) => {
            if (isNil(name) || !name.trim().length) {
                return;
            }

            try {
                const roleId = await SettingsConnector.getInstance().addRole({
                    name,
                    description,
                    scope: rolesScope,
                }, rolesScope, progressMonitor);

                if (roleId) {
                    handleOnRoleCreated(roleId, progressMonitor);
                }
            } catch (err) {
                console.error(err);
            }

            onClose();
        },
        [name, onClose, description, rolesScope, handleOnRoleCreated]
    );

    const handleSubmit = useCallback(
        (event: ButtonClickEvent | React.FormEvent | undefined) => {
            event?.preventDefault();
            event?.stopPropagation();

            callSubmit().catch((error) => {
                console.error(error);
            });
        },
        [callSubmit]
    );

    return <ArgModal
        size='medium'
        title={messages.title}
        onClose={onClose}
        onCancel={onClose}
        onOk={handleSubmit}
        className={classNames('&')}
        progressMonitor={progressMonitor}
        okDisabled={progressMonitor?.isRunning || isNameInvalid ||
            (
                isNil(name) ||
                isEmpty(name) ||
                name.trim().length === 0
            )}
        okText={messages.validate}
    >
        <form onSubmit={handleSubmit} id='settings-add-role-modal-form'>
            <ArgFormLabel
                propertyName={messages.name}
                description={isNameInvalid ? messages.invalidNameDescription : undefined}
                className={classNames('&-form-label', { 'has-error': isNameInvalid })}
                required={true}
            >
                <ArgInputText
                    value={name}
                    autoFocus={true}
                    state={isNameInvalid ? 'invalid' : undefined}
                    onInputChange={setName}
                    className={classNames('&-name-input')}
                    placeholder={messages.name}
                    data-testid='settings-add-role-modal-name'
                />
            </ArgFormLabel>
            <ArgFormLabel
                propertyName={messages.description}
                className={classNames('&-form-label')}
            >
                <ArgInputTextArea
                    value={description}
                    resizable={false}
                    onInputChange={setDescription}
                    className={classNames('&-description-input')}
                    placeholder={messages.description}
                    data-testid='settings-add-role-modal-description'
                />
            </ArgFormLabel>
        </form>
    </ArgModal>;
});
