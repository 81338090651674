import Debug from 'debug';

import {
    BriefTemplate,
    BriefTemplateContent,
    ExplorationStyleTemplate,
    ExplorationStyleTemplateContent,
    TemplateType,
} from '../../model/template';
import { ProgressMonitor } from '../../../components/basic';
import { CreationTemplate, TemplateId } from '../../../model/template';
import { ExplorationTemplateConnector } from './exploration-template-connector';
import { RawTemplate } from 'src/framework/templates/templates-types';
import { mapTemplate } from 'src/framework/templates/mappers';
import { JsonChange } from '../../../utils/connector';

import rawTemplates from '../../features/templates/data/templates.json';

let fakeTemplates = mapSettingsTemplates(rawTemplates as RawTemplate<any>[])
    .filter(template => template.type === TemplateType.Brief) as BriefTemplate[];
const debug = Debug('argonode:exploration:utils:TemplateConnector');

interface IBriefTemplateConnector {
    listBriefTemplate(progressMonitor: ProgressMonitor): Promise<BriefTemplate[]>;

    getBriefTemplate(id: TemplateId, progressMonitor: ProgressMonitor): Promise<BriefTemplate | undefined>;

    createBriefTemplate(templateToCreate: BriefTemplate): Promise<BriefTemplate>;

    deleteBriefTemplate(id: TemplateId): Promise<void>;

    updateBriefTemplate(templateToUpdate: BriefTemplate): Promise<void>;

    patchBriefTemplate(templateId: TemplateId, changes: JsonChange[], comment?: string, progressMonitor?: ProgressMonitor): Promise<void>;
}

class FakeTemplateConnector implements IBriefTemplateConnector {
    async listBriefTemplate(progressMonitor: ProgressMonitor): Promise<BriefTemplate[]> {
        console.debug('listBriefTemplate');

        return fakeTemplates;
    }

    async getBriefTemplate(id: string, progressMonitor: ProgressMonitor): Promise<BriefTemplate | undefined> {
        console.debug('getBriefTemplate');

        return fakeTemplates.find((template) => template.id == id);
    }

    async createBriefTemplate(templateToCreate: BriefTemplate): Promise<BriefTemplate> {
        console.debug('createBriefTemplate');
        fakeTemplates = [...fakeTemplates, templateToCreate];

        return templateToCreate;
    }

    async deleteBriefTemplate(id: string): Promise<void> {
        console.debug('deleteBriefTemplate');
        fakeTemplates = fakeTemplates.filter((template) => template.id !== id);
    }

    async updateBriefTemplate(templateToUpdate: BriefTemplate): Promise<void> {
        console.debug('updateBriefTemplate');
        const templateIndex = fakeTemplates.findIndex((template) => template.id == templateToUpdate.id);
        if (templateIndex >= 0) {
            fakeTemplates = [...fakeTemplates];
            fakeTemplates[templateIndex] = templateToUpdate;
        }
    }

    patchBriefTemplate(templateId: TemplateId, changes: JsonChange[], comment?: string, progressMonitor?: ProgressMonitor): Promise<void> {
        throw new Error('Method not implemented.');
    }
}


export const fakeTemplateConnector = new FakeTemplateConnector();

export class BriefTemplateConnector {
    private static instance: BriefTemplateConnector;
    private static explorationTemplateConnectorInstance: ExplorationTemplateConnector;

    static getInstance(): BriefTemplateConnector {
        if (!BriefTemplateConnector.instance) {
            // TODO: could implement using `new TypedTemplateConnector<BriefTemplateContent>(TemplateType.Brief, ...)`
            BriefTemplateConnector.instance = new BriefTemplateConnector();
        }
        if (!BriefTemplateConnector.explorationTemplateConnectorInstance) {
            BriefTemplateConnector.explorationTemplateConnectorInstance = ExplorationTemplateConnector.getInstance();
        }

        return BriefTemplateConnector.instance;
    }

    async listBriefTemplate(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<BriefTemplate[]> {
        const templates = await BriefTemplateConnector.explorationTemplateConnectorInstance.listTemplates<BriefTemplateContent>(TemplateType.Brief, undefined, progressMonitor);

        return templates;
    }

    async listPublicBriefTemplate(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<BriefTemplate[]> {
        const templates = await BriefTemplateConnector.explorationTemplateConnectorInstance.listPublicTemplates<BriefTemplateContent>(TemplateType.Brief, undefined, progressMonitor);

        return templates;
    }

    async getBriefTemplate(id: TemplateId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<BriefTemplate> {
        const template = await BriefTemplateConnector.explorationTemplateConnectorInstance.getTemplate<BriefTemplateContent>(id, progressMonitor);

        return template;
    }

    async createBriefTemplate(templateToCreate: CreationTemplate<BriefTemplateContent>, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<BriefTemplate> {
        const template = await BriefTemplateConnector.explorationTemplateConnectorInstance.createTemplate(templateToCreate, false, progressMonitor);

        return template;
    }

    async deleteBriefTemplate(id: TemplateId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        await BriefTemplateConnector.explorationTemplateConnectorInstance.deleteTemplate(id, progressMonitor);
    }

    async updateBriefTemplate(templateToUpdate: BriefTemplate, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        await BriefTemplateConnector.explorationTemplateConnectorInstance.updateTemplate(templateToUpdate, progressMonitor);
    }

    async patchBriefTemplate(templateId: TemplateId, changes: JsonChange[], comment?: string, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        await BriefTemplateConnector.explorationTemplateConnectorInstance.patchTemplate(templateId, changes, comment, progressMonitor);
    }
}

function mapSettingsTemplates(rawTemplates: RawTemplate<any>[]) {
    const ret = rawTemplates.map(rawTemplate => {
        switch (rawTemplate.type) {
            case TemplateType.Brief:
                return mapTemplate<BriefTemplateContent>(rawTemplate) as BriefTemplate;
            case TemplateType.ExplorationStyle:
                return mapTemplate<ExplorationStyleTemplateContent>(rawTemplate) as ExplorationStyleTemplate;
            default:
                break;
        }

        return undefined;
    })
        .filter(template => !!template)
        .map(template => template!);

    return ret;
}
