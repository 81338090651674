import { useContext } from 'react';
import { defineMessages } from 'react-intl';

import { ProgressMonitor, SubProgressMonitor, useArgNotifications, useCallbackAsync } from 'src/components/basic';
import { User } from 'src/model/user';
import { UsersAndGroupsStateContext } from '../users/providers/usersState';
import { UsersAdminConnector } from '../../utils/connectors/users-admin-connector';

const messages = defineMessages({
    editActivationErrorMessage: {
        id: 'settings.users.menu.edit-activation-error-message',
        defaultMessage: 'Unable to activate user',
    },
    editDesactivationErrorMessage: {
        id: 'settings.users.menu.edit-desactivation-error-message',
        defaultMessage: 'Unable to desactivate user',
    },
});

export function useUsers() {
    const { setUsers } = useContext(UsersAndGroupsStateContext);
    const notifications = useArgNotifications();

    const [changeStatus] = useCallbackAsync(async (progressMonitor: ProgressMonitor, user: User) => {
        const isActive = user.isActive;

        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            if (isActive) {
                await UsersAdminConnector.getInstance().disableUser(user.id, sub1);
            } else {
                await UsersAdminConnector.getInstance().enableUser(user.id, sub1);
            }
            const sub2 = new SubProgressMonitor(progressMonitor, 2);
            const newUser = await UsersAdminConnector.getInstance().getUser(user.id, sub2);
            setUsers((currentUsers) =>
                currentUsers.map((existingUser) => {
                    return existingUser.id === user.id ? newUser : existingUser;
                })
            );
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({
                message: isActive ? messages.editActivationErrorMessage : messages.editDesactivationErrorMessage,
            }, error as Error);
        }
    }, [setUsers, notifications]);

    return changeStatus;
}
