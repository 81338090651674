import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Avatar } from 'antd';

import {
    ArgButton,
    ArgCombo,
    ArgIcon,
    ArgInputText,
    ArgModal,
    useArgNotifications,
    useClassNames,
} from 'src/components/basic';
import { DEFAULT_VERTEX_STYLE } from 'src/exploration/constants/default-vertex-style';
import { FullOntology, FullOntologyObjectType } from '../../types';
import { ArgFormLabel } from '../../../../../components/basic';
import ontologiesConnector from '../../../../connectors/ontologies-connector';

import './create-edge-modal.less';

enum ArrowType {
    left = 'left',
    right = 'right',
}

type ArrowTypeDetails = {
    label:string;
    icon:string;
};
const ARROWS:Record<ArrowType, ArrowTypeDetails> = {
    left: { label: 'left', icon: 'icon-long-arrow-left' },
    right: { label: 'right', icon: 'icon-long-arrow-right' },
};

const arrows = Object.keys(ARROWS) as ArrowType[];

export const messages = defineMessages({
    title: {
        id: 'settings.create-edge-modal.title',
        defaultMessage: 'Create new edge',
    },
    fieldName: {
        id: 'settings.create-edge-modal.field.name',
        defaultMessage: 'Edge name',
    },
    required: {
        id: 'settings.create-edge-modal.required',
        defaultMessage: 'Required',
    },
    connection: {
        id: 'settings.create-edge-modal.field.connection',
        defaultMessage: 'Connection',
    },
    submit: {
        id: 'settings.create-edge-modal.submitButton',
        defaultMessage: 'Create',
    },
    cancel: {
        id: 'settings.create-edge-modal.cancelButton',
        defaultMessage: 'Cancel',
    },
    error: {
        id: 'settings.create-edge-modal.error',
        defaultMessage: 'An error occurred while trying to create the new edge.',
    },
});

export interface CreateEdgeModalProps {
    closeModal:() => void;
    vertex:FullOntologyObjectType;
    ontology?:FullOntology;
    setOntology:Dispatch<SetStateAction<FullOntology | undefined>>;
}

export function CreateEdgeModal(props:CreateEdgeModalProps) {
    const {
        closeModal,
        vertex,
        ontology,
        setOntology,
    } = props;

    const intl = useIntl();
    const classNames = useClassNames('create-edge-modal');
    const notifications = useArgNotifications();

    const [edgeName, setEdgeName] = useState<string>('');
    const [selectedArrow, setSelectedArrow] = useState<ArrowType>(ArrowType.right);
    const [selectedToVertex, setSelectedToVertex] = useState<FullOntologyObjectType>(vertex);

    useEffect(() => {
        setSelectedToVertex(vertex);
    }, [vertex]);

    const handleSubmit = async (
        displayName:string,
        sourceName:string,
        destName:string
    ):Promise<void> => {
        if (ontology) {
            try {
                await ontologiesConnector.createEdge(ontology?.id, displayName, sourceName, destName);
                setOntology(await ontologiesConnector.getFullOntology(ontology?.id));
                setEdgeName('');
            } catch (error) {
                notifications.snackError({ message: messages.error }, error as Error);
            }
        }
    };

    const getVertexFromName = (name:string) => {
        return ontology?.objectTypes.find((vertex) => vertex.name === name);
    };

    return (
        <ArgModal
            size='large'
            title={ intl.formatMessage(messages.title) }
            // visible={visible}
            onClose={ () => {
                setEdgeName('');
                closeModal();
            } }
            footer={
                <div>
                    <ArgButton
                        className={ classNames('&-footer-button') }
                        type='secondary'
                        onClick={ closeModal }
                        label={ messages.cancel }
                    />
                    <ArgButton
                        className={ classNames('&-footer-button') }
                        type='primary'
                        label={ messages.submit }
                        data-testid='create'
                        disabled={ !edgeName }
                        onClick={ () => {
                            handleSubmit(
                                edgeName,
                                selectedArrow === ArrowType.right
                                    ? vertex.name
                                    : selectedToVertex.name,
                                selectedArrow === ArrowType.right
                                    ? selectedToVertex.name
                                    : vertex.name
                            );
                            closeModal();
                        } }
                    />
                </div>
            }
        >
            <form autoComplete='off'>
                <ArgFormLabel
                    propertyName={ messages.fieldName }
                    required={ messages.required }
                >
                    <ArgInputText
                        value={ edgeName }
                        onChange={ (input) => {
                            setEdgeName(input ?? '');
                        } }
                    />
                </ArgFormLabel>
                <div className={ classNames('&-connection-row') }>
                    <div className={ classNames('&-connection-element') }>
                        <Avatar
                            icon={
                                <ArgIcon
                                    name={ vertex.style?.iconName || DEFAULT_VERTEX_STYLE.iconName! }
                                    size='small'
                                />
                            }
                            style={ {
                                backgroundColor:
                                    vertex.style?.fillColor ||
                                    DEFAULT_VERTEX_STYLE.fillColor,
                            } }
                            className={ classNames('&-avatar') }
                        />
                        <span>{ vertex.displayName }</span>
                    </div>
                    <ArgFormLabel
                        className={ classNames('&-connection-arrow') }
                        propertyName={ messages.connection }
                    >
                        <ArgCombo<ArrowType>
                            className={ classNames('&-connection-arrow-input') }
                            items={ arrows }
                            value={ selectedArrow }
                            onChange={ (arrow) => {
                                setSelectedArrow(arrow);
                            } }
                            renderItem={ (item) => {
                                const arrow = ARROWS[item];

                                return <ArgIcon name={ arrow.icon } />;
                            } }
                            renderInput={ () => {
                                const arrow = ARROWS[selectedArrow];

                                return <ArgIcon name={ arrow.icon } style={ { marginLeft: '10px' } } />;
                            } }
                        />
                    </ArgFormLabel>
                    <ArgCombo<FullOntologyObjectType>
                        className={ classNames('&-connection-element') }
                        items={ ontology?.objectTypes || [] }
                        value={ selectedToVertex }
                        onChange={ (chosenVertex:FullOntologyObjectType | undefined) => {
                            chosenVertex ? setSelectedToVertex(chosenVertex) : null;
                        } }
                        cardinality='one'
                        getItemKey={ (item) => item.name }
                        getItemLabel={ (item) => item.displayName }
                        getItemIcon={ (item:FullOntologyObjectType) => {
                            const vertex = getVertexFromName(item.name);

                            return vertex?.style?.iconName || DEFAULT_VERTEX_STYLE.iconName;
                        } }

                        renderInput={ () => {
                            return (
                                <>
                                    <Avatar
                                        icon={ <ArgIcon
                                            name={ selectedToVertex?.style?.iconName || DEFAULT_VERTEX_STYLE.iconName! }
                                            size='small'
                                            className={ classNames('&-connection-element-item-icon') }
                                        /> }
                                        style={ {
                                            backgroundColor:
                                                vertex.style?.fillColor ||
                                                DEFAULT_VERTEX_STYLE.fillColor,
                                        } }
                                        className={ classNames('&-avatar') }
                                    />
                                    <span
                                        className={ classNames('&-connection-element-item-label') }
                                    >
                                        { selectedToVertex.displayName }
                                    </span>
                                </>
                            );
                        } }
                    />
                </div>
            </form>
        </ArgModal>
    );
}
