import { GetOntologyDTO, GetOntologySchemaDTO, GetOntologyStyleDTO } from 'src/settings/models/dtoApi';
import { RuleSet, UserDefinedContent } from 'src/components/common/graph/customisation/graph-style';
import { VertexStyle } from 'src/exploration/model/vertex';
import {
    UniverseEdgeTypeName,
    UniversePropertyName,
    UniverseVertexTypeName,
} from '../../../exploration/model/universe';
import { DataType } from 'src/components/common/data-types';

export type OntologyId = string;

export type OntologyOperationType = string;

export interface PropertyConstraint {
    inputFormat?: string;
    fixedValues?: { value: string }[] | null;
    hideFullPath?: boolean;
}

// Export both type and values to extend CommonDataTypes enum
export type OntologyPropertyBaseType = DataType;
export const OntologyPropertyBaseType = { ...DataType };

export interface OntologyProperty {
    name: UniversePropertyName;
    displayName: string;
    description?: string;
    isMandatory?: boolean;
    isTitle: boolean;
    isContinuous: boolean;
    clientMetadata?: Record<string, any>;
    constraint?: PropertyConstraint;
    baseType: OntologyPropertyBaseType;
    customDataType?: string;
    isHeadProperty?: boolean;
    isMultivalued?: boolean;
    weight: number;
    pathDefinition: {
        separator: string;
    };
}

export interface OntologyObjectType {
    name: UniverseVertexTypeName; // unique identifier (does not change after creation)
    displayName: string;
    properties: OntologyProperty[];
}

export interface OntologyLinkType {
    name: UniverseEdgeTypeName; // unique identifier (does not change after creation)
    displayName: string;
    sourceObjectName: string;
    destinationObjectName: string;
    properties: OntologyProperty[];
}

export type OntologyMetaPropertyBaseType = OntologyPropertyBaseType;

export interface OntologyMetaProperty {
    name: string; // unique identifier (does not change after creation)
    displayName: string;
    description?: string;
    clientMetadata?: Record<string, any>;
    constraint?: PropertyConstraint;
    baseType: OntologyMetaPropertyBaseType;
    customDataType?: string;
    pathDefinition: {
        separator: string;
    };
}

export interface OntologyItemStyle {
    titleProperty?: string;
    userDefinedContent?: UserDefinedContent; //VertexStyle;
    ruleSets?: Record<string, RuleSet[]>;
}

export interface FullOntologyItemStyle extends VertexStyle {
    ruleSets?: Record<string, RuleSet[]>;
    titleProperty?: string,
}

export interface FullOntologyObjectType extends OntologyObjectType {
    style: FullOntologyItemStyle;
}

export interface FullOntologyLinkType extends OntologyLinkType {
    style: FullOntologyItemStyle;
}

export interface FullOntology {
    id: OntologyId;
    name: GetOntologyDTO['name'];
    description?: GetOntologyDTO['description'];
    universeIds: GetOntologyDTO['universeIds'];
    lastPublishedBy: GetOntologyDTO['lastPublishedBy'];
    lastPublishedDate: Date; //GetOntologyDTO['lastPublishedDate'];
    objectTypes: FullOntologyObjectType[];
    linkTypes: FullOntologyLinkType[];
    metaProperties: GetOntologySchemaDTO['metaProperties'];
    fullStyle: GetOntologyStyleDTO;
}

export interface OntologyBaseSchema {
    objectTypes: OntologyObjectType[];
    linkTypes: OntologyLinkType[];
    metaProperties: OntologyMetaProperty[];
}
