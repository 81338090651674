import React, { useCallback, useMemo, useState } from 'react';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil, RegionCode } from 'google-libphonenumber';
import { orderBy } from 'lodash';
import Debug from 'debug';

import { ArgCombo, ArgInputText, ArgPlaceholderText, ClassValue, useClassNames } from '..';

import './arg-phone-number.less';

const debug = Debug('basic:arg-phone-number');

export interface CountryPhone {
    id: RegionCode,
    label: string
}

export interface ArgPhoneNumberProps {
    className?: ClassValue;
    initialValue?: string;
    placeholder?: ArgPlaceholderText
    onChange: (value: string, valid: boolean) => void;
}

export function ArgPhoneNumber(props: ArgPhoneNumberProps) {
    const {
        className,
        initialValue,
        placeholder,
        onChange,
    } = props;

    const classNames = useClassNames('arg-phone-number');

    // Set initial values
    const [initialPhoneCountry, initialPhone] = useMemo(() => {
        let _initialPhoneCountry: CountryPhone = {
            id: 'FR',
            label: regionCodeToLabel('FR'),
        };
        let _initialPhone = '';
        if (initialValue){
            try {
                const number = PhoneNumberUtil.getInstance().parse(initialValue);
                const region = PhoneNumberUtil.getInstance().getRegionCodeForNumber(number) as RegionCode;
                _initialPhoneCountry = {
                    id: region,
                    label: regionCodeToLabel(region),
                };
                _initialPhone = `${number.getNationalNumber()}`;
            } catch (e) {
                debug('Invalid phone number', initialValue);
            }
        }

        return [_initialPhoneCountry, _initialPhone];
    }, [initialValue]);

    // Init states
    const [invalid, setInvalid] = useState<boolean>(false);
    const [phoneRegion, setPhoneRegion] = useState<RegionCode>(initialPhoneCountry.id);
    const [phoneNumber, setPhoneNumber] = useState<string>(initialPhone);

    // Init countries list
    const countries: CountryPhone[] = useMemo(() => {
        const countriesPhones: CountryPhone[] = orderBy(PhoneNumberUtil.getInstance().getSupportedRegions().map((elt: RegionCode) => {
            const countryPhone: CountryPhone = {
                id: elt,
                label: regionCodeToLabel(elt),
            };

            return countryPhone;
        }), (country) => country.label);

        return countriesPhones;
    }, []);

    const checkValue = useCallback((phoneNumber: string, region: RegionCode) => {
        try {
            const number = PhoneNumberUtil.getInstance().parse(phoneNumber, region);
            const isValid = isPhoneValid(region, number);
            if (isValid){
                setInvalid(false);
                onChange(PhoneNumberUtil.getInstance().format(number, PhoneNumberFormat.E164), true);

                return;
            }
        } catch (e) {
            debug('Invalid phone number', initialValue);
        }

        if (!phoneNumber){
            setInvalid(false);
            onChange('', true);
        } else {
            setInvalid(true);
            onChange(`+${PhoneNumberUtil.getInstance().getCountryCodeForRegion(region)}${phoneNumber}`, false);
        }
    }, [setInvalid, onChange, debug]);

    // Handle region change event
    const handleCountryCodeChange = useCallback((value: CountryPhone) => {
        setPhoneRegion(value.id);
        checkValue(phoneNumber, value.id);
    }, [checkValue, phoneNumber]);

    // Handle phone change event
    const handlePhoneChange = useCallback((value: string) => {
        setPhoneNumber(value);
        checkValue(value, phoneRegion);
    }, [checkValue, phoneRegion]);

    return <div className={classNames('&', className)}>
        <ArgCombo<CountryPhone>
            initialValue={initialPhoneCountry}
            onChange={(value) => handleCountryCodeChange(value)}
            items={countries}
            getItemKey={(item) => item.id}
            getItemLabel={(item) => item.label}
        />

        <ArgInputText initialValue={initialPhone}
                      onInputChange={(value) => handlePhoneChange(value)}
                      placeholder={placeholder}
                      state={invalid ? 'invalid' : undefined}
        />
    </div>;
}

function regionCodeToLabel(code: RegionCode): string {
    return `+${PhoneNumberUtil.getInstance().getCountryCodeForRegion(code)} (${code})`;
}

function isPhoneValid(region: string, number: PhoneNumber): boolean {
    return PhoneNumberUtil.getInstance().isValidNumberForRegion(number, region);
}
