import { DataFilter, getPredicate, SortableArrayDataProvider } from '../../../components/basic';
import { Group } from '../../../model/user';

export interface GroupsDataFilter extends DataFilter {
    search?: string;
}

export class GroupsDataProvider extends SortableArrayDataProvider<Group, GroupsDataFilter> {
    filterData(data: Group[], filter?: GroupsDataFilter): Group[] {
        let filteredData = [...data];
        if (filter?.search) {
            filteredData = filteredData.filter((item) =>
                Object.values(item).find(getPredicate(filter.search!))
            );
        }

        return filteredData;
    }
}
