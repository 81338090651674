import { defineMessages, FormattedMessage } from 'react-intl';

import { Action } from '../../../../utils/actions/action';
import { HistoryManager } from '../../../../utils/history-manager';
import { ActionsEngine } from '../../../../utils/actions/actions-engine';
import { ProgressMonitor, SelectionProvider } from '../../../../components/basic';
import { FormDocument, FormElement, FormTab } from '../../../../components/common/forms/model';
import { FormRepository } from './form-actions-engine';

export const RENAME_FORM_PAGE_ELEMENTS_ACTION = Symbol('Form:RenameFormPageElements');

export const messages = defineMessages({
    renameFormPageElement: {
        id: 'exploration.forms.actions.rename-form-page-element.TaskName',
        defaultMessage: 'Rename a page',
    },
    undoRenameFormPageElement: {
        id: 'exploration.forms.actions.rename-form-page-element.Undo',
        defaultMessage: 'Cancel rename a page',
    },
});

export function createFormRenamePageElement(formTab: FormTab,
    newName: string,
    selectionProvider: SelectionProvider<FormElement> | undefined
): Action<FormRepository, void> {
    let previousValue: FormDocument;

    return {
        type: RENAME_FORM_PAGE_ELEMENTS_ACTION,

        renderEntry: function ChangeStepActionTitle(undo: boolean) {
            return (
                <FormattedMessage
                    {...(undo) ? messages.undoRenameFormPageElement : messages.renameFormPageElement}
                />
            );
        },
        action: async (
            historyManager: HistoryManager<ActionsEngine<FormRepository>>,
            actionsEngine: ActionsEngine<FormRepository>,
            progressMonitor: ProgressMonitor
        ) => {
            progressMonitor.beginTask(messages.renameFormPageElement, 1);

            previousValue = actionsEngine.repository.formDocument;
            const newFormTab = {
                ...formTab,
                name: newName,
            };
            actionsEngine.repository.changeElement(formTab, newFormTab);
            selectionProvider?.set(formTab, 'form-rename-page-elements-action');
        },
        reverseAction: async (
            historyManager: HistoryManager<ActionsEngine<FormRepository>>,
            actionsEngine: ActionsEngine<FormRepository>,
            progressMonitor: ProgressMonitor
        ) => {
            progressMonitor.beginTask(messages.undoRenameFormPageElement, 1);

            actionsEngine.repository.setDocument(previousValue);

            selectionProvider?.clear('form-rename-page-elements-action');
        },
    };
}
