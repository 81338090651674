import React, { ReactNode, useCallback } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';

import { isMessageDescriptor, useClassNames } from '../basic';

import './validation-summary.less';

const messages = defineMessages({
    formErrors: {
        id: 'common.validation-summary.error.title',
        defaultMessage: 'The form contains errors',
    },
});

export interface ValidationDetail {
    fieldName: string;
    message: MessageDescriptor | ReactNode;
    fieldElement?: HTMLElement | null;
}

interface ValidationSummaryProps {
    type?: 'warning' | 'error';
    title?: MessageDescriptor;
    details: ValidationDetail[];
    scrollContainer?: HTMLElement | null;
}

export const ValidationSummary: React.FunctionComponent<ValidationSummaryProps> = (
    {
        details,
        title = messages.formErrors as MessageDescriptor,
        type = 'warning',
        scrollContainer = document.body,
    }
) => {
    const classNames = useClassNames('validation-summary');
    const iconClass = 'exclamation-point';

    const handleFieldNameClick = useCallback((detail: ValidationDetail) => {
        if (!scrollContainer || !detail.fieldElement) {
            return;
        }

        scrollContainer.scrollTo({
            top: detail.fieldElement.offsetTop,
            left: detail.fieldElement.offsetLeft,
            behavior: 'smooth',
        });
    }, [scrollContainer]);

    return <div className={classNames('&', `&-${type}`)}>
        <div className={classNames('&-icon')}>
            <div className={classNames('&-icon-container', `&-icon-container-${type}`)}>
                <i
                    className={classNames(
                        '&-icon',
                        `&-${type}-icon`,
                        `&-icon-${iconClass}`,
                        `&-${type}-icon-${iconClass}`,
                        `icon-${iconClass}`
                    )}
                />
            </div>
        </div>
        <div className={classNames('&-body')}>
            <div className={classNames('&-body-title')}>{<FormattedMessage {...title} />}</div>
            <div className={classNames('&-body-details')}>
                <ul className={classNames('&-body-details-list')}>
                    {details.map((detail: ValidationDetail, index) => {
                        let itemContent: ReactNode;
                        if (isMessageDescriptor(detail.message)) {
                            itemContent = (
                                <FormattedMessage
                                    {...detail.message}
                                    values={{
                                        fieldName: detail.fieldElement ?
                                            (
                                                <a className={classNames('&-body-details-list-item-field-name')}
                                                   onClick={() => handleFieldNameClick(detail)}>
                                                    {detail.fieldName}
                                                </a>
                                            ) : detail.fieldName,
                                    }}
                                />);
                        } else {
                            itemContent = detail.message as ReactNode;
                        }

                        return <li
                            className={classNames('&-body-details-list-item')}
                            data-testid={`validation-summary-error-item-${index}`}
                            key={detail.fieldName}>
                            {itemContent}
                        </li>;
                    })}
                </ul>
            </div>
        </div>
    </div>;
};
