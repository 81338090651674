
import { Plugin } from '@ckeditor/ckeditor5-core';
import ResizeDashboardCommand from './resize-dashboard-command';
import { DashboardEditing } from '../dashboard-editing';

export default class DashboardResizeui extends Plugin {
    /**
     * @inheritDoc
     */
    static get requires() {
        return [DashboardEditing];
    }

    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'argonos-dashboard:UI';
    }

    /**
     * @inheritDoc
     */
    constructor(editor) {
        super(editor);

        editor.config.define('dashboard', {
            resizeUnit: '%',
            resizeOptions: [
                {
                    name: 'resizeDashboard:original',
                    value: '100',
                    icon: 'original',
                },
                {
                    name: 'resizeDashboard:50',
                    value: '50',
                    icon: 'large',
                },
                {
                    name: 'resizeDashboard:25',
                    value: '25',
                    icon: 'medium',
                },
                {
                    name: 'resizeDashboard:10',
                    value: '10',
                    icon: 'small',
                }],
        });
    }

    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;
        const resizeImageCommand = new ResizeDashboardCommand(editor);

        editor.commands.add('resizeDashboard', resizeImageCommand);
    }
}
