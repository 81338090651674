import { useCallback, useContext, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { DetailedWebHook } from 'src/settings/models/detailed-webhooks';
import { ArgButton, ArgMenu, ArgMenuItem, ArgModalContainerContext, useClassNames } from '../../../../components/basic';
import { WebhooksActivationModal } from '../webhooks-activation-modal/webhooks-activation-modal';
import { WebhooksDeletionModal } from '../webhooks-deletion-modal/webhooks-deletion-modal';

const messages = defineMessages({
    actions: {
        id: 'settings.webhooks-actions-button.menu.actions',
        defaultMessage: 'Actions',
    },
    activate: {
        id: 'settings.webhooks-actions-button.menu.activate',
        defaultMessage: 'Activate',
    },
    deactivate: {
        id: 'settings.webhooks-actions-button.menu.deactivate',
        defaultMessage: 'Deactivate',
    },
    delete: {
        id: 'settings.webhooks-actions-button.menu.delete',
        defaultMessage: 'Delete',
    },
});

interface WebhookActionsButtonMenuProps {
    selectedWebhooks: DetailedWebHook[];
    onActionSuccess?: () => void;
}

export function WebhookActionsButtonMenu(props: WebhookActionsButtonMenuProps) {
    const {
        selectedWebhooks,
        onActionSuccess,
    } = props;

    const [visible, setVisible] = useState<boolean>(false);

    const classNames = useClassNames('settings-webhooks-actions-button-menu');

    const modalContainer = useContext(ArgModalContainerContext);

    const handleDeleteWebhook = useCallback(() => {
        modalContainer.open('settings-webhook-delete', <WebhooksDeletionModal
          webhooks={selectedWebhooks}
          onClose={() => modalContainer.close('settings-webhook-delete')}
          onSuccess={onActionSuccess}
        />);
    }, [modalContainer, onActionSuccess, selectedWebhooks]);

    const handleToggleWebhook = useCallback((targetStatus: boolean) => {
        modalContainer.open('settings-webhook-activation', <WebhooksActivationModal
          webhooks={selectedWebhooks}
          targetStatus={targetStatus}
          onClose={() => modalContainer.close('settings-webhook-activation')}
          onSuccess={onActionSuccess}
        />);
    }, [modalContainer, onActionSuccess, selectedWebhooks]);

    const handleActivateWebhook = useCallback(() => {
        handleToggleWebhook(true);
    }, [handleToggleWebhook]);

    const handleDeactivateWebhook = useCallback(() => {
        handleToggleWebhook(false);
    }, [handleToggleWebhook]);

    const actions = useMemo(() => [
        {
            key: 'activate',
            label: messages.activate,
            onClick: handleActivateWebhook,
            disabled: selectedWebhooks.every((webhook) => webhook.isEnabled),
        },
        {
            key: 'deactivate',
            label: messages.deactivate,
            onClick: handleDeactivateWebhook,
            disabled: selectedWebhooks.every((webhook) => !webhook.isEnabled),
        },
        {
            key: 'delete',
            label: messages.delete,
            onClick: handleDeleteWebhook,
        },
    ], [handleActivateWebhook, handleDeactivateWebhook, handleDeleteWebhook, selectedWebhooks]);

    const actionsMenu = (
        <ArgMenu>
            {actions.map((action) => (
                <ArgMenuItem
                    key={action.key}
                    onClick={() => {
                        action.onClick();
                        setVisible(!visible);
                    }}
                    disabled={action.disabled}
                >
                    <FormattedMessage {...action.label} />
                </ArgMenuItem>
            ))}
        </ArgMenu>
    );

    return (
        <ArgButton
            size='medium'
            type='secondary'
            right='dropdown'
            label={messages.actions}
            disabled={selectedWebhooks.length === 0}
            popover={visible && (
                actionsMenu
            )}
            popoverTrigger='click'
            popoverVisible={visible}
            className={classNames('&')}
            data-testid='actions-menu'
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
            popoverClassName={classNames('&-popover')}
        />
    );
}
