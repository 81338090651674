import { defineMessages } from 'react-intl';

import { ArgCreateModal, ProgressMonitor, useCallbackAsync, useClassNames, useArgNotifications } from 'src/components/basic';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';

import './create-form-policy-modal.less';

export const messages = defineMessages({
    modalTitle: {
        id: 'settings.create-form-policy-modal.title',
        defaultMessage: 'New form policy',
    },
    nameField: {
        id: 'settings.create-form-policy-modal.name',
        defaultMessage: 'Name',
    },
    descriptionField: {
        id: 'settings.create-form-policy-modal.field.description',
        defaultMessage: 'Description',
    },
    creationError: {
        id: 'settings.create-form-policy-modal.error',
        defaultMessage: 'An error occurred while creating the form policy',
    },
});

export interface CreateFormPolicyModalProps {
    closeModal: () => void;
    onSuccess: () => void;
    universeId: string;
}

export const CreateFormPolicyModal = ({
    closeModal,
    onSuccess,
    universeId,
}: CreateFormPolicyModalProps) => {
    const classNames = useClassNames('settings-create-form-policy-modal');
    const notifications = useArgNotifications();

    const [handleConfirm] = useCallbackAsync(async (progressMonitor: ProgressMonitor, name: string, description: string) => {
        try {
            await explorationSettingsConnector.createFormPolicy({
                universeId,
                name,
                description,
                scopes: [],
                enabled: true,
                statement: { Actions: [] },
                type: 'data.display-template.vector',
            }, progressMonitor);

            onSuccess();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.creationError }, error as Error);

            throw error;
        }
    }, [onSuccess, universeId]);

    return (
        <ArgCreateModal
            className={classNames('&')}
            onClose={closeModal}
            onConfirm={handleConfirm}
            title={messages.modalTitle}
            nameFieldLabel={messages.nameField}
            descriptionFieldLabel={messages.descriptionField}
        />
    );
};
