import { ConnectorRequestInit } from '../connector';
import { ProgressMonitor } from 'src/components/basic';
import { BaseConnector } from './base-connector';
import {
    ConfigurationImportResponseError,
    ImportAction,
    ImportExportManifest,
    ImportExportOptions,
} from '../../model/configuration';
import {
    getAdministrationApi,
    getDataExplorationApi,
    getDataPreparationApi,
    getProceoApi,
    getSettingsApi,
} from './api-url';
import { ConfigurationsScope } from '../../settings/configuration/configuration-type';

const EMPTY_ERROR = {};

export class ConfigurationConnector extends BaseConnector {
    private static instance:ConfigurationConnector;

    static getInstance():ConfigurationConnector {
        if (!ConfigurationConnector.instance) {
            ConfigurationConnector.instance = new ConfigurationConnector('settings', undefined);
        }

        return ConfigurationConnector.instance;
    }

    static getConfigurationsScopeApi(configurationsScope:ConfigurationsScope):string | undefined {
        switch (configurationsScope) {
            case 'data_exploration':
                return getDataExplorationApi();
            case 'data_preparation':
                return getDataPreparationApi();
            case 'proceo':
                return getProceoApi();
            case 'admin':
                return getAdministrationApi();
            case 'settings':
                return getSettingsApi();
            default:
                throw new Error(`${configurationsScope} scope have no api`);
        }
    }

    async importManifest(
        file:Blob,
        configurationsScope:ConfigurationsScope,
        progressMonitor:ProgressMonitor = ProgressMonitor.empty()
    ):Promise<ImportExportManifest> {
        const url = '/configurations/import/manifest';
        const api = ConfigurationConnector.getConfigurationsScopeApi(configurationsScope);
        const options:ConnectorRequestInit = {
            method: 'POST',
            api,
            body: file,
            verifyJSONResponse: true,
            headers: {
                'Content-Type': 'application/zip',
            },
        };

        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }

    async importConfigurations(
        file:Blob,
        configOptions:ImportExportOptions,
        importAction:ImportAction,
        configurationsScope:ConfigurationsScope,
        progressMonitor:ProgressMonitor = ProgressMonitor.empty()
    ):Promise<ConfigurationImportResponseError> {
        const url = '/configurations/import';
        const api = ConfigurationConnector.getConfigurationsScopeApi(configurationsScope);
        const formattedConfigOption = ConfigurationConnector.removeConfigurationScopeIfNotSettings(configOptions, configurationsScope);

        const data = new FormData();
        data.append('action', importAction);
        data.append('formFile', new Blob([file], { type: 'application/zip' }));
        data.append('settings', JSON.stringify({ requests: formattedConfigOption.options }));

        const options:ConnectorRequestInit = {
            method: 'POST',
            api,
            body: data,
            verifyJSONResponse: true,
        };

        const ret = await this.request(url, options, progressMonitor);

        if (!ret) {
            return EMPTY_ERROR;
        }

        return ret;
    }

    async exportConfigurations(
        configOptions:ImportExportOptions,
        configurationsScope:ConfigurationsScope,
        progressMonitor:ProgressMonitor = ProgressMonitor.empty()
    ):Promise<Blob> {
        const url = '/configurations/export';
        const api = ConfigurationConnector.getConfigurationsScopeApi(configurationsScope);
        const formattedConfigOption = ConfigurationConnector.removeConfigurationScopeIfNotSettings(configOptions, configurationsScope);

        const options:ConnectorRequestInit = {
            method: 'POST',
            api,
            json: {
                requests: formattedConfigOption.options,
            },
        };

        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }

    async getConfigurationsExportManifest(
        configurationsScope:ConfigurationsScope,
        progressMonitor:ProgressMonitor = ProgressMonitor.empty()
    ):Promise<ImportExportManifest> {
        const url = '/configurations/export/manifest';
        const api = ConfigurationConnector.getConfigurationsScopeApi(configurationsScope);

        const ret = await this.request(url, {
            api,
            verifyJSONResponse: true,
        }, progressMonitor);

        return ret;
    }

    /**
     * Global import/export (settings api) need a scope indicating the module name
     * But when importing/exporting directly in a module (i.e data-exploration) the scope have to be removed
     */
    private static removeConfigurationScopeIfNotSettings(configOptions:ImportExportOptions, scope:ConfigurationsScope) {
        if (scope === 'settings') {
            return configOptions;
        }

        const newConfigOptions:ImportExportOptions = {
            options: configOptions.options.map((option) => ({
                type: ConfigurationConnector.withoutScope(option.type),
                configurationKeys: option.configurationKeys,
                options: option.options,
            })),
        };

        return newConfigOptions;
    }

    private static withoutScope(type:string) {
        const parts = type.split('.');

        if (parts.length === 1) {
            return type;
        }

        const typeWithoutScope = parts.slice(1).join('.');

        return typeWithoutScope;
    }
}

export default ConfigurationConnector.getInstance();
