import { keyBy } from 'lodash';

import { normalizeText } from 'src/components/basic';
import { Country } from 'src/model/country';

export const COUNTRIES: Country[] = [
    {
        name: 'Afghanistan',
        code: 'af',
        frenchName: 'Afghanistan',
        code3: 'afg',
    },
    {
        name: 'Åland Islands',
        code: 'ax',
        frenchName: 'Îles Åland',
        code3: 'ala',
    },
    {
        name: 'Albania',
        code: 'al',
        frenchName: 'Albanie',
        code3: 'alb',
    },
    {
        name: 'Algeria',
        code: 'dz',
        frenchName: 'Algérie',
        code3: 'dza',
    },
    {
        name: 'American Samoa',
        code: 'as',
        frenchName: 'Samoa Américaines',
        code3: 'asm',
    },
    {
        name: 'Andorra',
        code: 'ad',
        frenchName: 'Andorre',
        code3: 'and',
    },
    {
        name: 'Angola',
        code: 'ao',
        frenchName: 'Angola',
        code3: 'ago',
    },
    {
        name: 'Anguilla',
        code: 'ai',
        frenchName: 'Anguilla',
        code3: 'aia',
    },
    {
        name: 'Antarctica',
        code: 'aq',
        frenchName: 'Antarctique',
        code3: 'ata',
    },
    {
        name: 'Antigua and Barbuda',
        code: 'ag',
        frenchName: 'Antigua-Et-Barbuda',
        code3: 'atg',
    },
    {
        name: 'Argentina',
        code: 'ar',
        frenchName: 'Argentine',
        code3: 'arg',
    },
    {
        name: 'Armenia',
        code: 'am',
        frenchName: 'Arménie',
        code3: 'arm',
    },
    {
        name: 'Aruba',
        code: 'aw',
        frenchName: 'Aruba',
        code3: 'abw',
    },
    {
        name: 'Australia',
        code: 'au',
        frenchName: 'Australie',
        code3: 'aus',
    },
    {
        name: 'Austria',
        code: 'at',
        frenchName: 'Autriche',
        code3: 'aut',
    },
    {
        name: 'Azerbaijan',
        code: 'az',
        frenchName: 'Azerbaïdjan',
        code3: 'aze',
    },
    {
        name: 'Bahamas',
        code: 'bs',
        frenchName: 'Bahamas',
        code3: 'bhs',
    },
    {
        name: 'Bahrain',
        code: 'bh',
        frenchName: 'Bahreïn',
        code3: 'bhr',
    },
    {
        name: 'Bangladesh',
        code: 'bd',
        frenchName: 'Bangladesh',
        code3: 'bgd',
    },
    {
        name: 'Barbados',
        code: 'bb',
        frenchName: 'Barbad',
        code3: 'brb',
    },
    {
        name: 'Belarus',
        code: 'by',
        frenchName: 'Biélorussie',
        code3: 'blr',
    },
    {
        name: 'Belgium',
        code: 'be',
        frenchName: 'Belgique',
        code3: 'bel',
    },
    {
        name: 'Belize',
        code: 'bz',
        frenchName: 'Belize',
        code3: 'blz',
    },
    {
        name: 'Benin',
        code: 'bj',
        frenchName: 'Bénin',
        code3: 'ben',
    },
    {
        name: 'Bermuda',
        code: 'bm',
        frenchName: 'Bermudes',
        code3: 'bmu',
    },
    {
        name: 'Bhutan',
        code: 'bt',
        frenchName: 'Bhoutan',
        code3: 'btn',
    },
    {
        name: 'Bolivia, Plurinational State of',
        code: 'bo',
        frenchName: 'État Plurinational De Bolivie',
        code3: 'bol',
    },
    {
        name: 'Bonaire, Sint Eustatius and Saba',
        code: 'bq',
        frenchName: 'Bonaire, Saint-Eustache Et Saba',
        code3: 'bes',
    },
    {
        name: 'Bosnia and Herzegovina',
        code: 'ba',
        frenchName: 'Bosnie-Herzégovine',
        code3: 'bih',
    },
    {
        name: 'Botswana',
        code: 'bw',
        frenchName: 'Botswana',
        code3: 'bwa',
    },
    {
        name: 'Bouvet Island',
        code: 'bv',
        frenchName: 'Île Bouvet',
        code3: 'bvt',
    },
    {
        name: 'Brazil',
        code: 'br',
        frenchName: 'Brésil',
        code3: 'bra',
    },
    {
        name: 'British Indian Ocean Territory',
        code: 'io',
        frenchName: 'Territoire Britannique De L\'océan Indien',
        code3: 'iot',
    },
    {
        name: 'Brunei Darussalam',
        code: 'bn',
        frenchName: 'Brunei Darussalam',
        code3: 'brn',
    },
    {
        name: 'Bulgaria',
        code: 'bg',
        frenchName: 'Bulgarie',
        code3: 'bgr',
    },
    {
        name: 'Burkina Faso',
        code: 'bf',
        frenchName: 'Burkina Faso',
        code3: 'bfa',
    },
    {
        name: 'Burundi',
        code: 'bi',
        frenchName: 'Burundi',
        code3: 'bdi',
    },
    {
        name: 'Cambodia',
        code: 'kh',
        frenchName: 'Cambodge',
        code3: 'khm',
    },
    {
        name: 'Cameroon',
        code: 'cm',
        frenchName: 'Cameroun',
        code3: 'cmr',
    },
    {
        name: 'Canada',
        code: 'ca',
        frenchName: 'Canada',
        code3: 'can',
    },
    {
        name: 'Cape Verde',
        code: 'cv',
        frenchName: 'Cap-Vert',
        code3: 'cpv',
    },
    {
        name: 'Catalonia',
        code: 'es-ct',
        frenchName: 'Catalogne',
        code3: 'es-ct',
    },
    {
        name: 'Cayman Islands',
        code: 'ky',
        frenchName: 'Îles Caïmans',
        code3: 'cym',
    },
    {
        name: 'Central African Republic',
        code: 'cf',
        frenchName: 'République Centrafricaine',
        code3: 'caf',
    },
    {
        name: 'Chad',
        code: 'td',
        frenchName: 'Tchad',
        code3: 'tcd',
    },
    {
        name: 'Chile',
        code: 'cl',
        frenchName: 'Chili',
        code3: 'chl',
    },
    {
        name: 'China',
        code: 'cn',
        frenchName: 'Chine',
        code3: 'chn',
    },
    {
        name: 'Christmas Island',
        code: 'cx',
        frenchName: 'Île Christmas',
        code3: 'cxr',
    },
    {
        name: 'Cocos (Keeling) Islands',
        code: 'cc',
        frenchName: 'Îles Cocos',
        code3: 'cck',
    },
    {
        name: 'Colombia',
        code: 'co',
        frenchName: 'Colombie',
        code3: 'col',
    },
    {
        name: 'Comoros',
        code: 'km',
        frenchName: 'Comores',
        code3: 'com',
    },
    {
        name: 'Congo',
        code: 'cg',
        frenchName: 'Congo',
        code3: 'cog',
    },
    {
        name: 'Congo, the Democratic Republic of the',
        code: 'cd',
        frenchName: 'République Démocratique Du Congo',
        code3: 'cod',
    },
    {
        name: 'Cook Islands',
        code: 'ck',
        frenchName: 'Îles Cook',
        code3: 'cok',
    },
    {
        name: 'Costa Rica',
        code: 'cr',
        frenchName: 'Costa Rica',
        code3: 'cri',
    },
    {
        name: 'Côte d\'Ivoire',
        code: 'ci',
        frenchName: 'Côte D\'Ivoire',
        code3: 'civ',
    },
    {
        name: 'Croatia',
        code: 'hr',
        frenchName: 'Croatie',
        code3: 'hrv',
    },
    {
        name: 'Cuba',
        code: 'cu',
        frenchName: 'Cuba',
        code3: 'cub',
    },
    {
        name: 'Curaçao',
        code: 'cw',
        frenchName: 'Curaçao',
        code3: 'cuw',
    },
    {
        name: 'Cyprus',
        code: 'cy',
        frenchName: 'Chypre',
        code3: 'cyp',
    },
    {
        name: 'Czech Republic',
        code: 'cz',
        frenchName: 'République Tchèque',
        code3: 'cze',
    },
    {
        name: 'Denmark',
        code: 'dk',
        frenchName: 'Denmark',
        code3: 'dnk',
    },
    {
        name: 'Djibouti',
        code: 'dj',
        frenchName: 'Djibouti',
        code3: 'dji',
    },
    {
        name: 'Dominica',
        code: 'dm',
        frenchName: 'Dominique',
        code3: 'dma',
    },
    {
        name: 'Dominican Republic',
        code: 'do',
        frenchName: 'République Dominicaine',
        code3: 'dom',
    },
    {
        name: 'Ecuador',
        code: 'ec',
        frenchName: 'Équateur',
        code3: 'ecu',
    },
    {
        name: 'Egypt',
        code: 'eg',
        frenchName: 'Égypte',
        code3: 'egy',
    },
    {
        name: 'El Salvador',
        code: 'sv',
        frenchName: 'République Du Salvador',
        code3: 'slv',
    },
    {
        name: 'Equatorial Guinea',
        code: 'gq',
        frenchName: 'Guinée Équatoriale',
        code3: 'gnq',
    },
    {
        name: 'Eritrea',
        code: 'er',
        frenchName: 'Érythrée',
        code3: 'eri',
    },
    {
        name: 'Estonia',
        code: 'ee',
        frenchName: 'Estonie',
        code3: 'est',
    },
    {
        name: 'Ethiopia',
        code: 'et',
        frenchName: 'Éthiopie',
        code3: 'eth',
    },
    {
        name: 'European Union',
        code: 'eu',
        frenchName: 'Europe',
        code3: 'eur',
    },
    {
        name: 'Falkland Islands (Malvinas)',
        code: 'fk',
        frenchName: 'Îles Malouines',
        code3: 'flk',
    },
    {
        name: 'Faroe Islands',
        code: 'fo',
        frenchName: 'Îles Féroé',
        code3: 'fro',
    },
    {
        name: 'Fiji',
        code: 'fj',
        frenchName: 'Fidji',
        code3: 'fji',
    },
    {
        name: 'Finland',
        code: 'fi',
        frenchName: 'Finlande',
        code3: 'fin',
    },
    {
        name: 'France',
        code: 'fr',
        frenchName: 'France',
        code3: 'fra',
    },
    {
        name: 'French Guiana',
        code: 'gf',
        frenchName: 'Guyane',
        code3: 'guf',
    },
    {
        name: 'French Polynesia',
        code: 'pf',
        frenchName: 'Polynésie Française',
        code3: 'pyf',
    },
    {
        name: 'French Southern Territories',
        code: 'tf',
        frenchName: 'Terres Australes Françaises',
        code3: 'atf',
    },
    {
        name: 'Gabon',
        code: 'ga',
        frenchName: 'Gabon',
        code3: 'gab',
    },
    {
        name: 'Gambia',
        code: 'gm',
        frenchName: 'Gambie',
        code3: 'gmb',
    },
    {
        name: 'Georgia',
        code: 'ge',
        frenchName: 'Géorgie',
        code3: 'geo',
    },
    {
        name: 'Germany',
        code: 'de',
        frenchName: 'Allemagne',
        code3: 'deu',
    },
    {
        name: 'Ghana',
        code: 'gh',
        frenchName: 'Ghana',
        code3: 'gha',
    },
    {
        name: 'Gibraltar',
        code: 'gi',
        frenchName: 'Gibraltar',
        code3: 'gib',
    },
    {
        name: 'Greece',
        code: 'gr',
        frenchName: 'Grèce',
        code3: 'grc',
    },
    {
        name: 'Greenland',
        code: 'gl',
        frenchName: 'Groenland',
        code3: 'grl',
    },
    {
        name: 'Grenada',
        code: 'gd',
        frenchName: 'Grenade',
        code3: 'grd',
    },
    {
        name: 'Guadeloupe',
        code: 'gp',
        frenchName: 'Guadeloupe',
        code3: 'glp',
    },
    {
        name: 'Guam',
        code: 'gu',
        frenchName: 'Guam',
        code3: 'gum',
    },
    {
        name: 'Guatemala',
        code: 'gt',
        frenchName: 'Guatemala',
        code3: 'gtm',
    },
    {
        name: 'Guernsey',
        code: 'gg',
        frenchName: 'Guernesey',
        code3: 'ggy',
    },
    {
        name: 'Guinea',
        code: 'gn',
        frenchName: 'Guinée',
        code3: 'gin',
    },
    {
        name: 'Guinea-Bissau',
        code: 'gw',
        frenchName: 'Guinée-Bissau',
        code3: 'gnb',
    },
    {
        name: 'Guyana',
        code: 'gy',
        frenchName: 'Guyana',
        code3: 'guy',
    },
    {
        name: 'Haiti',
        code: 'ht',
        frenchName: 'Haïti',
        code3: 'hti',
    },
    {
        name: 'Heard Island and McDonald Islands',
        code: 'hm',
        frenchName: 'Îles Heard-Et-MacDonald',
        code3: 'hmd',
    },
    {
        name: 'Holy See (Vatican City State)',
        code: 'va',
        frenchName: 'Saint-Siège (État De La Cité Du Vatican)',
        code3: 'vat',
    },
    {
        name: 'Honduras',
        code: 'hn',
        frenchName: 'Honduras',
        code3: 'hnd',
    },
    {
        name: 'Hong Kong',
        code: 'hk',
        frenchName: 'Hong Kong',
        code3: 'hkg',
    },
    {
        name: 'Hungary',
        code: 'hu',
        frenchName: 'Hongrie',
        code3: 'hun',
    },
    {
        name: 'Iceland',
        code: 'is',
        frenchName: 'Islande',
        code3: 'isl',
    },
    {
        name: 'India',
        code: 'in',
        frenchName: 'Inde',
        code3: 'ind',
    },
    {
        name: 'Indonesia',
        code: 'id',
        frenchName: 'Indonésie',
        code3: 'idn',
    },
    {
        name: 'Iran, Islamic Republic of',
        code: 'ir',
        frenchName: 'République Islamique D\'Iran',
        code3: 'irn',
    },
    {
        name: 'Iraq',
        code: 'iq',
        frenchName: 'Irak',
        code3: 'irq',
    },
    {
        name: 'Ireland',
        code: 'ie',
        frenchName: 'Irlande',
        code3: 'irl',
    },
    {
        name: 'Isle of Man',
        code: 'im',
        frenchName: 'Île De Man',
        code3: 'imn',
    },
    {
        name: 'Israel',
        code: 'il',
        frenchName: 'Israël',
        code3: 'isr',
    },
    {
        name: 'Italy',
        code: 'it',
        frenchName: 'Italie',
        code3: 'ita',
    },
    {
        name: 'Jamaica',
        code: 'jm',
        frenchName: 'Jamaïque',
        code3: 'jam',
    },
    {
        name: 'Japan',
        code: 'jp',
        frenchName: 'Japon',
        code3: 'jpn',
    },
    {
        name: 'Jersey',
        code: 'je',
        frenchName: 'Jersey',
        code3: 'jey',
    },
    {
        name: 'Jordan',
        code: 'jo',
        frenchName: 'Jordanie',
        code3: 'jor',
    },
    {
        name: 'Kazakhstan',
        code: 'kz',
        frenchName: 'Kazakhstan',
        code3: 'kaz',
    },
    {
        name: 'Kenya',
        code: 'ke',
        frenchName: 'Kenya',
        code3: 'ken',
    },
    {
        name: 'Kiribati',
        code: 'ki',
        frenchName: 'Kiribati',
        code3: 'kir',
    },
    {
        name: 'Korea, Democratic People\'s Republic of',
        code: 'kp',
        frenchName: 'République Populaire Démocratique De Corée',
        code3: 'prk',
    },
    {
        name: 'Korea, Republic of',
        code: 'kr',
        frenchName: 'République De Corée',
        code3: 'kor',
    },
    {
        name: 'Kuwait',
        code: 'kw',
        frenchName: 'Koweït',
        code3: 'kwt',
    },
    {
        name: 'Kyrgyzstan',
        code: 'kg',
        frenchName: 'Kirghizistan',
        code3: 'kgz',
    },
    {
        name: 'Lao People\'s Democratic Republic',
        code: 'la',
        frenchName: 'République Démocratique Populaire Lao',
        code3: 'lao',
    },
    {
        name: 'Latvia',
        code: 'lv',
        frenchName: 'Lettonie',
        code3: 'lva',
    },
    {
        name: 'Lebanon',
        code: 'lb',
        frenchName: 'Liban',
        code3: 'lbn',
    },
    {
        name: 'Lesotho',
        code: 'ls',
        frenchName: 'Lesotho',
        code3: 'lso',
    },
    {
        name: 'Liberia',
        code: 'lr',
        frenchName: 'Liberia',
        code3: 'lbr',
    },
    {
        name: 'Libya',
        code: 'ly',
        frenchName: 'Libye',
        code3: 'lby',
    },
    {
        name: 'Liechtenstein',
        code: 'li',
        frenchName: 'Liechtenstein',
        code3: 'lie',
    },
    {
        name: 'Lithuania',
        code: 'lt',
        frenchName: 'Lituanie',
        code3: 'ltu',
    },
    {
        name: 'Luxembourg',
        code: 'lu',
        frenchName: 'Luxembourg',
        code3: 'lux',
    },
    {
        name: 'Macao',
        code: 'mo',
        frenchName: 'Macao',
        code3: 'mac',
    },
    {
        name: 'Macedonia, the Former Yugoslav Republic of',
        code: 'mk',
        frenchName: 'Macédoine',
        code3: 'mkd',
    },
    {
        name: 'Madagascar',
        code: 'mg',
        frenchName: 'Madagascar',
        code3: 'mdg',
    },
    {
        name: 'Malawi',
        code: 'mw',
        frenchName: 'Malawi',
        code3: 'mwi',
    },
    {
        name: 'Malaysia',
        code: 'my',
        frenchName: 'Malaisie',
        code3: 'mys',
    },
    {
        name: 'Maldives',
        code: 'mv',
        frenchName: 'Maldives',
        code3: 'mdv',
    },
    {
        name: 'Mali',
        code: 'ml',
        frenchName: 'Mali',
        code3: 'mli',
    },
    {
        name: 'Malta',
        code: 'mt',
        frenchName: 'Malte',
        code3: 'mlt',
    },
    {
        name: 'Marshall Islands',
        code: 'mh',
        frenchName: 'Îles Marshall',
        code3: 'mhl',
    },
    {
        name: 'Martinique',
        code: 'mq',
        frenchName: 'Martinique',
        code3: 'mtq',
    },
    {
        name: 'Mauritania',
        code: 'mr',
        frenchName: 'Mauritanie',
        code3: 'mrt',
    },
    {
        name: 'Mauritius',
        code: 'mu',
        frenchName: 'Maurice',
        code3: 'mus',
    },
    {
        name: 'Mayotte',
        code: 'yt',
        frenchName: 'Mayotte',
        code3: 'myt',
    },
    {
        name: 'Mexico',
        code: 'mx',
        frenchName: 'Mexique',
        code3: 'mex',
    },
    {
        name: 'Micronesia, Federated States of',
        code: 'fm',
        frenchName: 'États Fédérés De Micronésie',
        code3: 'fsm',
    },
    {
        name: 'Moldova, Republic of',
        code: 'md',
        frenchName: 'République De Moldavie',
        code3: 'mda',
    },
    {
        name: 'Monaco',
        code: 'mc',
        frenchName: 'Monaco',
        code3: 'mco',
    },
    {
        name: 'Mongolia',
        code: 'mn',
        frenchName: 'Mongolie',
        code3: 'mng',
    },
    {
        name: 'Montenegro',
        code: 'me',
        frenchName: 'Monténégro',
        code3: 'mne',
    },
    {
        name: 'Montserrat',
        code: 'ms',
        frenchName: 'Montserrat',
        code3: 'msr',
    },
    {
        name: 'Morocco',
        code: 'ma',
        frenchName: 'Maroc',
        code3: 'mar',
    },
    {
        name: 'Mozambique',
        code: 'mz',
        frenchName: 'Mozambique',
        code3: 'moz',
    },
    {
        name: 'Myanmar',
        code: 'mm',
        frenchName: 'Birmanie',
        code3: 'mmr',
    },
    {
        name: 'Namibia',
        code: 'na',
        frenchName: 'Namibie',
        code3: 'nam',
    },
    {
        name: 'Nauru',
        code: 'nr',
        frenchName: 'Nauru',
        code3: 'nru',
    },
    {
        name: 'Nepal',
        code: 'np',
        frenchName: 'Népal',
        code3: 'npl',
    },
    {
        name: 'Netherlands',
        code: 'nl',
        frenchName: 'Pays-Bas',
        code3: 'nld',
    },
    {
        name: 'New Caledonia',
        code: 'nc',
        frenchName: 'Nouvelle-Calédonie',
        code3: 'ncl',
    },
    {
        name: 'New Zealand',
        code: 'nz',
        frenchName: 'Nouvelle-Zélande',
        code3: 'nzl',
    },
    {
        name: 'Nicaragua',
        code: 'ni',
        frenchName: 'Nicaragua',
        code3: 'nic',
    },
    {
        name: 'Niger',
        code: 'ne',
        frenchName: 'Niger',
        code3: 'ner',
    },
    {
        name: 'Nigeria',
        code: 'ng',
        frenchName: 'Nigéria',
        code3: 'nga',
    },
    {
        name: 'Niue',
        code: 'nu',
        frenchName: 'Niue',
        code3: 'niu',
    },
    {
        name: 'Norfolk Island',
        code: 'nf',
        frenchName: 'Île Norfolk',
        code3: 'nfk',
    },
    {
        name: 'Northern Mariana Islands',
        code: 'mp',
        frenchName: 'Îles Mariannes Du Nord',
        code3: 'mnp',
    },
    {
        name: 'Norway',
        code: 'no',
        frenchName: 'Norvège',
        code3: 'nor',
    },
    {
        name: 'Oman',
        code: 'om',
        frenchName: 'Oman',
        code3: 'omn',
    },
    {
        name: 'Pakistan',
        code: 'pk',
        frenchName: 'Pakistan',
        code3: 'pak',
    },
    {
        name: 'Palau',
        code: 'pw',
        frenchName: 'Palaos',
        code3: 'plw',
    },
    {
        name: 'Palestine, State of',
        code: 'ps',
        frenchName: 'Territoires Palestiniens Occupés',
        code3: 'pse',
    },
    {
        name: 'Panama',
        code: 'pa',
        frenchName: 'Panama',
        code3: 'pan',
    },
    {
        name: 'Papua New Guinea',
        code: 'pg',
        frenchName: 'Papouasie-Nouvelle-Guinée',
        code3: 'png',
    },
    {
        name: 'Paraguay',
        code: 'py',
        frenchName: 'Paraguay',
        code3: 'pry',
    },
    {
        name: 'Peru',
        code: 'pe',
        frenchName: 'Pérou',
        code3: 'per',
    },
    {
        name: 'Philippines',
        code: 'ph',
        frenchName: 'Philippines',
        code3: 'phl',
    },
    {
        name: 'Pitcairn',
        code: 'pn',
        frenchName: 'Pitcairn',
        code3: 'pcn',
    },
    {
        name: 'Poland',
        code: 'pl',
        frenchName: 'Pologne',
        code3: 'pol',
    },
    {
        name: 'Portugal',
        code: 'pt',
        frenchName: 'Portugal',
        code3: 'prt',
    },
    {
        name: 'Puerto Rico',
        code: 'pr',
        frenchName: 'Porto Rico',
        code3: 'pri',
    },
    {
        name: 'Qatar',
        code: 'qa',
        frenchName: 'Qatar',
        code3: 'qat',
    },
    {
        name: 'Réunion',
        code: 're',
        frenchName: 'Réunion',
        code3: 'reu',
    },
    {
        name: 'Romania',
        code: 'ro',
        frenchName: 'Roumanie',
        code3: 'rou',
    },
    {
        name: 'Russian Federation',
        code: 'ru',
        frenchName: 'Fédération De Russie',
        code3: 'rus',
    },
    {
        name: 'Rwanda',
        code: 'rw',
        frenchName: 'Rwanda',
        code3: 'rwa',
    },
    {
        name: 'Saint Barthélemy',
        code: 'bl',
        frenchName: 'Saint-Barthélemy',
        code3: 'blm',
    },
    {
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        code: 'sh',
        frenchName: 'Sainte-Hélène',
        code3: 'shn',
    },
    {
        name: 'Saint Kitts and Nevis',
        code: 'kn',
        frenchName: 'Saint-Christophe-et-Niévès',
        code3: 'kna',
    },
    {
        name: 'Saint Lucia',
        code: 'lc',
        frenchName: 'Sainte-Lucie',
        code3: 'lca',
    },
    {
        name: 'Saint Martin (French part)',
        code: 'mf',
        frenchName: 'Saint-Martin (Partie Française)',
        code3: 'maf',
    },
    {
        name: 'Saint Pierre and Miquelon',
        code: 'pm',
        frenchName: 'Saint-Pierre-Et-Miquelon',
        code3: 'spm',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        code: 'vc',
        frenchName: 'Saint-Vincent-Et-Les Grenadines',
        code3: 'vct',
    },
    {
        name: 'Samoa',
        code: 'ws',
        frenchName: 'Samoa',
        code3: 'wsm',
    },
    {
        name: 'San Marino',
        code: 'sm',
        frenchName: 'Saint-Marin',
        code3: 'smr',
    },
    {
        name: 'Sao Tome and Principe',
        code: 'st',
        frenchName: 'Sao Tomé-Et-Principe',
        code3: 'stp',
    },
    {
        name: 'Saudi Arabia',
        code: 'sa',
        frenchName: 'Arabie Saoudite',
        code3: 'sau',
    },
    {
        name: 'Senegal',
        code: 'sn',
        frenchName: 'Sénégal',
        code3: 'sen',
    },
    {
        name: 'Serbia',
        code: 'rs',
        frenchName: 'Serbie',
        code3: 'srb',
    },
    {
        name: 'Seychelles',
        code: 'sc',
        frenchName: 'Seychelles',
        code3: 'syc',
    },
    {
        name: 'Sierra Leone',
        code: 'sl',
        frenchName: 'Sierra Leone',
        code3: 'sle',
    },
    {
        name: 'Singapore',
        code: 'sg',
        frenchName: 'Singapour',
        code3: 'sgp',
    },
    {
        name: 'Sint Maarten (Dutch part)',
        code: 'sx',
        frenchName: 'Saint-Martin (Partie Néerlandaise)',
        code3: 'sxm',
    },
    {
        name: 'Slovakia',
        code: 'sk',
        frenchName: 'Slovaquie',
        code3: 'svk',
    },
    {
        name: 'Slovenia',
        code: 'si',
        frenchName: 'Slovénie',
        code3: 'svn',
    },
    {
        name: 'Solomon Islands',
        code: 'sb',
        frenchName: 'Îles Salomon',
        code3: 'slb',
    },
    {
        name: 'Somalia',
        code: 'so',
        frenchName: 'Somalie',
        code3: 'som',
    },
    {
        name: 'South Africa',
        code: 'za',
        frenchName: 'Afrique Du Sud',
        code3: 'zaf',
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        code: 'gs',
        frenchName: 'Géorgie Du Sud-Et-Les Îles Sandwich Du Sud',
        code3: 'sgs',
    },
    {
        name: 'South Sudan',
        code: 'ss',
        frenchName: 'Soudan Du Sud',
        code3: 'ssd',
    },
    {
        name: 'Spain',
        code: 'es',
        frenchName: 'Espagne',
        code3: 'esp',
    },
    {
        name: 'Sri Lanka',
        code: 'lk',
        frenchName: 'Sri Lanka',
        code3: 'lka',
    },
    {
        name: 'Sudan',
        code: 'sd',
        frenchName: 'Soudan',
        code3: 'sdn',
    },
    {
        name: 'Suriname',
        code: 'sr',
        frenchName: 'Suriname',
        code3: 'sur',
    },
    {
        name: 'Svalbard and Jan Mayen',
        code: 'sj',
        frenchName: 'Svalbard Et Jan Mayen',
        code3: 'sjm',
    },
    {
        name: 'Swaziland',
        code: 'sz',
        frenchName: 'Swaziland',
        code3: 'swz',
    },
    {
        name: 'Sweden',
        code: 'se',
        frenchName: 'Suède',
        code3: 'swe',
    },
    {
        name: 'Switzerland',
        code: 'ch',
        frenchName: 'Suisse',
        code3: 'che',
    },
    {
        name: 'Syrian Arab Republic',
        code: 'sy',
        frenchName: 'République Arabe Syrienne',
        code3: 'syr',
    },
    {
        name: 'Taiwan, Province of China',
        code: 'tw',
        frenchName: 'Taïwan',
        code3: 'twn',
    },
    {
        name: 'Tajikistan',
        code: 'tj',
        frenchName: 'Tadjikistan',
        code3: 'tjk',
    },
    {
        name: 'Tanzania, United Republic of',
        code: 'tz',
        frenchName: 'République-Unie De Tanzanie',
        code3: 'tza',
    },
    {
        name: 'Thailand',
        code: 'th',
        frenchName: 'Thaïlande',
        code3: 'tha',
    },
    {
        name: 'Timor-Leste',
        code: 'tl',
        frenchName: 'Timor-Leste',
        code3: 'tls',
    },
    {
        name: 'Togo',
        code: 'tg',
        frenchName: 'Togo',
        code3: 'tgo',
    },
    {
        name: 'Tokelau',
        code: 'tk',
        frenchName: 'Tokelau',
        code3: 'tkl',
    },
    {
        name: 'Tonga',
        code: 'to',
        frenchName: 'Tonga',
        code3: 'ton',
    },
    {
        name: 'Trinidad and Tobago',
        code: 'tt',
        frenchName: 'Trinité-Et-Tobago',
        code3: 'tto',
    },
    {
        name: 'Tunisia',
        code: 'tn',
        frenchName: 'Tunisie',
        code3: 'tun',
    },
    {
        name: 'Turkey',
        code: 'tr',
        frenchName: 'Turquie',
        code3: 'tur',
    },
    {
        name: 'Turkmenistan',
        code: 'tm',
        frenchName: 'Turkménistan',
        code3: 'tkm',
    },
    {
        name: 'Turks and Caicos Islands',
        code: 'tc',
        frenchName: 'Îles Turks-Et-Caïcos',
        code3: 'tca',
    },
    {
        name: 'Tuvalu',
        code: 'tv',
        frenchName: 'Tuvalu',
        code3: 'tuv',
    },
    {
        name: 'Uganda',
        code: 'ug',
        frenchName: 'Ouganda',
        code3: 'uga',
    },
    {
        name: 'Ukraine',
        code: 'ua',
        frenchName: 'Ukraine',
        code3: 'ukr',
    },
    {
        name: 'United Arab Emirates',
        code: 'ae',
        frenchName: 'Émirats Arabes Unis',
        code3: 'are',
    },
    {
        name: 'United Kingdom',
        code: 'gb',
        frenchName: 'Royaume-Uni',
        code3: 'gbr',
    },
    {
        name: 'England',
        code: 'gb-eng',
        frenchName: 'Angleterre',
        code3: 'gb-eng',
    },
    {
        name: 'Northern Ireland',
        code: 'gb-nir',
        frenchName: 'Irelande du Nord',
        code3: 'gb-nir',
    },
    {
        name: 'Scotland',
        code: 'gb-sct',
        code3: 'gb-sct',
        frenchName: 'Ecosse',
    },
    {
        name: 'Wales',
        code: 'gb-wls',
        code3: 'gb-wls',
        frenchName: 'Pays de Galle',
    },
    {
        name: 'United Nations',
        code: 'un',
        code3: 'uno',
        frenchName: 'Nations Unies',
    },
    {
        name: 'United States',
        code: 'us',
        frenchName: 'États-Unis d\'Amérique',
        code3: 'usa',
    },
    {
        name: 'United States Minor Outlying Islands',
        code: 'um',
        frenchName: 'Îles Mineures Éloignées Des États-Unis',
        code3: 'umi',
    },
    {
        name: 'Uruguay',
        code: 'uy',
        frenchName: 'Uruguay',
        code3: 'ury',
    },
    {
        name: 'Uzbekistan',
        code: 'uz',
        frenchName: 'Ouzbékistan',
        code3: 'uzb',
    },
    {
        name: 'Vanuatu',
        code: 'vu',
        frenchName: 'Vanuatu',
        code3: 'vut',
    },
    {
        name: 'Venezuela, Bolivarian Republic of',
        code: 've',
        frenchName: 'Venezuela',
        code3: 'ven',
    },
    {
        name: 'Viet Nam',
        code: 'vn',
        frenchName: 'Viet Nam',
        code3: 'vnm',
    },
    {
        name: 'Virgin Islands, British',
        code: 'vg',
        frenchName: 'Îles Vierges Britanniques',
        code3: 'vgb',
    },
    {
        name: 'Virgin Islands, U.S.',
        code: 'vi',
        frenchName: 'Îles Vierges Des États-Unis',
        code3: 'vir',
    },
    {
        name: 'Wallis and Futuna',
        code: 'wf',
        frenchName: 'Wallis Et Futuna',
        code3: 'wlf',
    },
    {
        name: 'Western Sahara',
        code: 'eh',
        frenchName: 'Sahara Occidental',
        code3: 'esh',
    },
    {
        name: 'Yemen',
        code: 'ye',
        frenchName: 'Yémen',
        code3: 'yem',
    },
    {
        name: 'Zambia',
        code: 'zm',
        frenchName: 'Zambie',
        code3: 'zmb',
    },
    {
        name: 'Zimbabwe',
        code: 'zw',
        frenchName: 'Zimbabwe',
        code3: 'zwe',
    },
];

export const COUNTRIES_BY_CODE = keyBy(COUNTRIES, country => country.code.toLowerCase());
export const COUNTRIES_BY_CODE3 = keyBy(COUNTRIES, country => country.code3.toLowerCase());
export const COUNTRIES_BY_NAME = keyBy(COUNTRIES, country => normalizeText(country.name.toLowerCase()));
export const COUNTRIES_BY_FRENCH_NAME = keyBy(COUNTRIES, country => normalizeText(country.frenchName.toLowerCase()));
