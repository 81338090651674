import { useCallback, useState } from 'react';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgAceLanguage } from './arg-input-expression-editor';
import { ArgInputExpressionSnippetDetail } from './arg-input-expression-snippet-detail';
import { ArgInputExpressionSnippetsList } from './arg-input-expression-snippets-list';
import { Snippet } from './types';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { LoadingPane } from '../../common/panes/loading-pane';
import { ErrorPane } from '../../common/panes/error-pane';

import './arg-input-expression-snippets-panel.less';

const CLASSNAME = 'arg-input-expression-snippets-panel';

const FORCE_LOADING = false;
const FORCE_ERROR = false;

export interface ArgInputExpressionSnippetsPanelProps {
    className?: ClassValue;
    language: string | ArgAceLanguage;
    snippets: Snippet[];
    onInsertSnippet?: (snippet: Snippet) => void;
    progressMonitor?: ProgressMonitor;
    error?: Error;
}

export function ArgInputExpressionSnippetsPanel(props: ArgInputExpressionSnippetsPanelProps) {
    const {
        className,
        snippets,
        language,
        progressMonitor,
        error,
        onInsertSnippet,
    } = props;

    const classNames = useClassNames(CLASSNAME);
    const [currentSnippet, setCurrentSnippet] = useState<Snippet>();

    const handleViewSnippet = useCallback((snippet: Snippet) => {
        setCurrentSnippet(snippet);
    }, []);

    const handleDoubleClickSnippet = useCallback((snippet: Snippet) => {
        onInsertSnippet?.(snippet);
    }, [onInsertSnippet]);

    const handleBackToList = useCallback(() => {
        setCurrentSnippet(undefined);
    }, []);

    if (FORCE_LOADING || progressMonitor) {
        return (
            <div className={classNames('&', 'loading')} data-testid='loading-pane'>
                <LoadingPane progressMonitor={progressMonitor} />
            </div>
        );
    }

    if (FORCE_ERROR || error) {
        return (
            <div className={classNames('&', 'error')} data-testid='error-pane'>
                <ErrorPane error={error} />
            </div>
        );
    }

    return (
        <div className={classNames('&', className)} data-testid='arg-input-expression-snippets-panel'>
            {currentSnippet ? (
                <ArgInputExpressionSnippetDetail
                    className={classNames('&-detail')}
                    snippet={currentSnippet}
                    language={language}
                    onBackToList={handleBackToList}
                    onInsertSnippet={onInsertSnippet}
                />
            ) : (
                <ArgInputExpressionSnippetsList
                    className={classNames('&-list')}
                    snippets={snippets}
                    onViewSnippet={handleViewSnippet}
                    onDoubleClickSnippet={onInsertSnippet ? handleDoubleClickSnippet : undefined}
                />
            )}
        </div>
    );
}
