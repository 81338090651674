import { useState } from 'react';

import { ToolContext } from './tool-context';

export function useToolContext<T = undefined>(identifier: string, defaultToolContext?: ToolContext<T>): ToolContext<T> {
    const [toolbarContext] = useState<ToolContext<T>>(
        () => new ToolContext<T>(identifier)
    );

    return defaultToolContext || toolbarContext;
}
