export const GRAPH_NODE_MIN_SIZE = 0.1;

export const DEFAULT_BADGE_STYLE = {
    defaultSize: 1.2,
    minSize: 0.1,
};

export const DEFAULT_OBJECT_TYPE_COLORS = [
    '#007E78',
    '#39646C',
    '#606060',
    '#664614',
    '#9C6203',
    '#C7A4A4',
    '#AD1700',
    '#915993',
    '#34009C',
    '#094D7F',
    '#5765B4',
    '#6300F0',
];
