import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgButton,
    ArgChangeReason,
    ArgInputText,
    ArgModal,
    useClassNames,
    useArgNotifications,
} from 'src/components/basic';
import { FullOntology } from '../../types';
import { ArgFormLabel } from '../../../../../components/basic';
import ontologiesConnector from '../../../../connectors/ontologies-connector';

import './create-object-modal.less';

export const messages = defineMessages({
    title: {
        id: 'settings.create-object-modal.title',
        defaultMessage: 'Create an object',
    },
    fieldName: {
        id: 'settings.create-object-modal.field.name',
        defaultMessage: 'Object name',
    },
    submit: {
        id: 'settings.create-object-modal.submitButton',
        defaultMessage: 'Create',
    },
    cancel: {
        id: 'settings.create-object-modal.cancelButton',
        defaultMessage: 'Cancel',
    },
    error: {
        id: 'settings.create-object.modal.error',
        defaultMessage: 'An error occurred while trying to create the new object.',
    },
    required: {
        id: 'settings.create-object-modal.required',
        defaultMessage: 'Required',
    },
});

export interface CreateObjectModalProps {
    closeModal: () => void;
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
}

export function CreateObjectModal(props: CreateObjectModalProps) {
    const {
        closeModal,
        ontology,
        setOntology,
    } = props;

    const classNames = useClassNames('create-object-modal');
    const notifications = useArgNotifications();

    const [objectName, setObjectName] = useState<string>('');

    const createObject = useCallback(async (objectName: string) => {
        try {
            await ontologiesConnector.createObject(objectName, ontology.id);
            setOntology(await ontologiesConnector.getFullOntology(ontology.id));
        } catch (e) {
            notifications.snackError({ message: messages.error });
        }
    }, [ontology.id, setOntology]);

    const handleObjectNameChange = useCallback((name: string | null, reason: ArgChangeReason) => {
        setObjectName(name || '');

        if (reason === 'enter' && name) {
            createObject(name).then(() => {
                closeModal();
                setObjectName('');
            }, (error) => {
                console.error(error);
            });
        }
    }, [closeModal, createObject]);

    return (
        <ArgModal
            size='large'
            title={messages.title}
            className={classNames('&')}
            onClose={() => {
                closeModal();
                setObjectName('');
            }}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={() => {
                            closeModal();
                            setObjectName('');
                        }}
                        label={messages.cancel}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        label={messages.submit}
                        data-testid='create'
                        disabled={objectName === ''}
                        onClick={() => {
                            createObject(objectName).then(() => {
                                closeModal();
                                setObjectName('');
                            }, (error) => {
                                console.error(error);
                            });
                        }}
                    />
                </div>
            }
        >
            <ArgFormLabel
                propertyName={messages.fieldName}
                required={messages.required}
            >
                <ArgInputText
                    value={objectName}
                    onChange={handleObjectNameChange}
                />
            </ArgFormLabel>
        </ArgModal>
    );
}
