import { defineMessages } from 'react-intl';

import { defineKeyBindings, KeyBindingScopeDescriptor } from '../../components/basic';
import { SCOPE as FOLDER_SCOPE } from './folder';

const messages = defineMessages({
    wranglerScope: {
        id: 'preparation.keybindings.WranglerScope',
        defaultMessage: 'Wrangler Page',
    },
    toggleStepsList: {
        id: 'preparation.keybindings.ToggleStepsList',
        defaultMessage: 'Toggle steps panel',
    },
    toggleStepsConfiguration: {
        id: 'preparation.keybindings.ToggleStepsConfiguration',
        defaultMessage: 'Toggle steps configuration',
    },
    searchInLibrary: {
        id: 'preparation.keybindings.SearchInLibrary',
        defaultMessage: 'Search in library',
    },
    refreshWranglerData: {
        id: 'preparation.keybindings.RefreshWranglerData',
        defaultMessage: 'Refresh data',
    },
    openColumnConfiguration: {
        id: 'preparation.keybindings.OpenColumnConfiguration',
        defaultMessage: 'Open columns configuration',
    },
    toggleStatistics: {
        id: 'preparation.keybindings.ToggleStatistics',
        defaultMessage: 'Toggle statistics',
    },
    toggleDisplayMode: {
        id: 'preparation.keybindings.ToggleDisplayMode',
        defaultMessage: 'Toggle display mode',
    },
    backToProcess: {
        id: 'preparation.keybindings.BackToProcess',
        defaultMessage: 'Back to process',
    },
    backToHomePage: {
        id: 'preparation.keybindings.BackToHomeFromWrangler',
        defaultMessage: 'Back to home page',
    },
});

export const SCOPE: KeyBindingScopeDescriptor = {
    id: 'preparation.Wrangler',
    name: messages.wranglerScope,
    extends: FOLDER_SCOPE,
};

export const KEY_BINDINGS = defineKeyBindings({
    toggleStepsList: {
        id: 'preparation.process.ToggleStepsList',
        scope: SCOPE,
        name: messages.toggleStepsList,
        defaultKeys: 'alt+shift+S',
    },
    toggleStepsConfiguration: {
        id: 'preparation.process.ToggleStepsConfiguration',
        scope: SCOPE,
        name: messages.toggleStepsConfiguration,
        defaultKeys: 'alt+shift+C',
    },
    searchInLibrary: {
        id: 'preparation.process.SearchInLibrary',
        scope: SCOPE,
        name: messages.searchInLibrary,
        defaultKeys: 'alt+shift+R',
    },
    refreshWranglerData: {
        id: 'preparation.process.RefreshWranglerData',
        scope: SCOPE,
        name: messages.refreshWranglerData,
        defaultKeys: 'alt+R',
    },
    openColumnsConfiguration: {
        id: 'preparation.process.OpenColumnsConfiguration',
        scope: SCOPE,
        name: messages.openColumnConfiguration,
        defaultKeys: 'alt+C',
    },
    toggleStatistics: {
        id: 'preparation.process.ToggleStatistics',
        scope: SCOPE,
        name: messages.toggleStatistics,
        defaultKeys: 'alt+S',
    },
    toggleDisplayMode: {
        id: 'preparation.process.ToggleDisplayMode',
        scope: SCOPE,
        name: messages.toggleDisplayMode,
        defaultKeys: 'alt+D',
    },
    backToProcess: {
        id: 'preparation.process.BackToProcess',
        scope: SCOPE,
        name: messages.backToProcess,
        defaultKeys: 'alt+shift+ArrowLeft',
    },
    backToHomePage: {
        id: 'preparation.process.BackToHomeFromWrangler',
        scope: SCOPE,
        name: messages.backToHomePage,
        defaultKeys: 'alt+ArrowLeft',
    },
});

