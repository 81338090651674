import React, { ReactNode, useCallback, useMemo } from 'react';
import Slider from 'antd/lib/slider';
import { useIntl } from 'react-intl';

import { ArgInputDate, ArgInputNumber, useClassNames } from '../../../../basic';
import { GRAPH_NODE_MIN_SIZE } from '../../constants';
import { computeLocale } from '../../../controls/locales-mapping';
import { Messages } from './range-date-control';
import { formatSize, handleIntervalOverlapValues } from './utils';
import { internalisationDateFormat } from '../../../../../utils/dates/internalisation-date-format';
import { dayjs } from '../../../../basic/utils/dayjs';

import './gradient-date-control.less';

interface GradientDateControlProps {
    sliderLeftBound?: dayjs.Dayjs;
    sliderRightBound?: dayjs.Dayjs;
    minPropertyValue?: dayjs.Dayjs;
    maxPropertyValue?: dayjs.Dayjs;
    minSizeValue?: number;
    maxSizeValue?: number;
    onGradientPropertyRangeValueChange: (
        type: 'property' | 'size',
        side: 'left' | 'right',
        value: number | dayjs.Dayjs | null
    ) => Promise<void>;
    messages?: Messages;
}

export const GradientDateControl = ({
    sliderLeftBound,
    sliderRightBound,
    minPropertyValue,
    maxPropertyValue,
    minSizeValue,
    maxSizeValue,
    onGradientPropertyRangeValueChange,
    messages,
}: GradientDateControlProps) => {
    const classNames = useClassNames('gradient-date-control');
    const intl = useIntl();
    const onLeftPropertyValueChange = useCallback(
        (value: dayjs.Dayjs | null) => {
            const _value = handleIntervalOverlapValues('left', value, maxPropertyValue, 'date', [
                sliderLeftBound,
                sliderRightBound,
            ]);
            onGradientPropertyRangeValueChange('property', 'left', _value);
        },
        [onGradientPropertyRangeValueChange, maxPropertyValue, maxPropertyValue, sliderLeftBound, sliderRightBound]
    );

    const onRightPropertyValueChange = useCallback(
        (value: dayjs.Dayjs | null) => {
            const _value = handleIntervalOverlapValues('right', value, minPropertyValue, 'number', [
                sliderLeftBound,
                sliderRightBound,
            ]);

            onGradientPropertyRangeValueChange('property', 'right', _value);
        },
        [onGradientPropertyRangeValueChange, maxPropertyValue, minPropertyValue, sliderLeftBound, sliderRightBound]
    );
    const onLeftSizeChange = useCallback(
        (value: number | null) => {
            const formattedSize = formatSize(value);

            onGradientPropertyRangeValueChange('size', 'left', formattedSize);
        },
        [onGradientPropertyRangeValueChange]
    );
    const onRightSizeChange = useCallback(
        (value: number | null) => {
            const formattedSize = formatSize(value);

            onGradientPropertyRangeValueChange('size', 'right', formattedSize);
        },
        [onGradientPropertyRangeValueChange]
    );

    const sliderValue = useMemo<[number, number] | undefined>(() => {
        if (sliderLeftBound === undefined || sliderRightBound === undefined) {
            return undefined;
        }

        let _minPropertyValue: dayjs.Dayjs | undefined = minPropertyValue;
        let _maxPropertyValue: dayjs.Dayjs | undefined = maxPropertyValue;

        if (!_minPropertyValue || _minPropertyValue.isBefore(sliderLeftBound)) {
            _minPropertyValue = sliderLeftBound;
        }
        if (!_maxPropertyValue || _maxPropertyValue.isAfter(sliderRightBound)) {
            _maxPropertyValue = sliderRightBound;
        }

        const sliderValue = [_minPropertyValue.valueOf(), _maxPropertyValue.valueOf()];

        return sliderValue as [number, number];
    }, [minPropertyValue, maxPropertyValue, sliderLeftBound, sliderRightBound]);

    const dateFormat = useMemo(() => {
        const dateFormat = internalisationDateFormat(intl);

        return dateFormat;
    }, [intl]);

    const locale = useMemo(() => {
        const locale = computeLocale(intl);

        return locale;
    }, [intl]);

    const sliderToolTip = useCallback((value: number | undefined): ReactNode =>
        <span>
            {dayjs.utc(value).format(dateFormat)}
        </span>
    , [dateFormat]);


    if (sliderLeftBound === undefined || sliderRightBound === undefined) {
        return null;
    }

    return (
        <div className={classNames('&')}>
            <Slider
                className={classNames('&-slider')}
                tipFormatter={sliderToolTip}
                range={{ draggableTrack: true }}
                value={sliderValue}
                min={sliderLeftBound.valueOf()}
                max={sliderRightBound.valueOf()}
                onChange={(value: [number, number]) => {
                    const left = dayjs.utc(value?.[0]);
                    const right = dayjs.utc(value?.[1]);
                    if (!left.isSame(minPropertyValue)) {
                        onLeftPropertyValueChange(left);
                    }
                    if (!right.isSame(maxPropertyValue)) {
                        onRightPropertyValueChange(right);
                    }
                }}
            />
            <div className={classNames('&-property')}>
                <ArgInputDate
                    className={classNames('&-property-min')}
                    value={minPropertyValue}
                    format={dateFormat}
                    locale={locale}
                    onChange={onLeftPropertyValueChange}
                    placeholder={messages?.startDatePlaceholder}
                />
                <ArgInputDate
                    className={classNames('&-property-max')}
                    value={maxPropertyValue}
                    format={dateFormat}
                    locale={locale}
                    onChange={onRightPropertyValueChange}
                    placeholder={messages?.endDatePlaceholder}
                />
            </div>

            <div className={classNames('&-size')}>
                <ArgInputNumber
                    className={classNames('&-size-min')}
                    step={GRAPH_NODE_MIN_SIZE}
                    min={GRAPH_NODE_MIN_SIZE}
                    value={minSizeValue}
                    displayRightControl={true}
                    onChange={onLeftSizeChange}
                    placeholder={messages?.minPlaceholder}
                />
                <ArgInputNumber
                    className={classNames('&-size-max')}
                    step={GRAPH_NODE_MIN_SIZE}
                    min={GRAPH_NODE_MIN_SIZE}
                    value={maxSizeValue}
                    displayRightControl={true}
                    onChange={onRightSizeChange}
                    placeholder={messages?.maxPlaceholder}
                />
            </div>
        </div>
    );
};
