import { defineMessages } from 'react-intl';

import { ArgMessageRenderer, useClassNames } from 'src/components/basic';

import './form-base-property.less';

const messages = defineMessages({
    required: {
        id: 'common.forms.Required',
        defaultMessage: 'Required',
    },
});

export interface FormBasePropertyProps {
    isMandatory?: boolean;
    children: React.ReactNode;
}

export function FormBaseProperty(props: FormBasePropertyProps) {
    const { isMandatory, children } = props;
    const classNames = useClassNames('form-base-property');

    return (
        <div className={classNames('&', { required: isMandatory })}>
            {(isMandatory) && (
                <ArgMessageRenderer
                    size='small'
                    className={classNames('&-required')}
                    message={messages.required}
                />
            )}
            {children}
        </div>
    );
}
