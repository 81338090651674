import { defineMessages, IntlShape } from 'react-intl';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';

const messages = defineMessages({
    datePlaceholder: {
        id: 'common.data-picker.locale.DatePlaceholder',
        defaultMessage: 'Select date',
    },
    rangePlaceholderStartDate: {
        id: 'common.data-picker.locale.rangePlaceholderStartDate',
        defaultMessage: 'Start date',
    },
    rangePlaceholderEndDate: {
        id: 'common.data-picker.locale.rangePlaceholderEndDate',
        defaultMessage: 'End date',
    },
    today: {
        id: 'common.data-picker.locale.today',
        defaultMessage: 'Today',
    },
    now: {
        id: 'common.data-picker.locale.now',
        defaultMessage: 'Now',
    },
    backToToday: {
        id: 'common.data-picker.locale.backToToday',
        defaultMessage: 'Back to today',
    },
    ok: {
        id: 'common.data-picker.locale.ok',
        defaultMessage: 'Ok',
    },
    clear: {
        id: 'common.data-picker.locale.clear',
        defaultMessage: 'Clear',
    },
    month: {
        id: 'common.data-picker.locale.month',
        defaultMessage: 'Month',
    },
    year: {
        id: 'common.data-picker.locale.year',
        defaultMessage: 'Year',
    },
    timeSelect: {
        id: 'common.data-picker.locale.timeSelect',
        defaultMessage: 'Select time',
    },
    dateSelect: {
        id: 'common.data-picker.locale.dateSelect',
        defaultMessage: 'Select date',
    },
    monthSelect: {
        id: 'common.data-picker.locale.monthSelect',
        defaultMessage: 'Choose a month',
    },
    yearSelect: {
        id: 'common.data-picker.locale.yearSelect',
        defaultMessage: 'Choose a year',
    },
    decadeSelect: {
        id: 'common.data-picker.locale.decadeSelect',
        defaultMessage: 'Choose a decade',
    },
    previousMonth: {
        id: 'common.data-picker.locale.previousMonth',
        defaultMessage: 'Previous month (PageUp)',
    },
    nextMonth: {
        id: 'common.data-picker.locale.nextMonth',
        defaultMessage: 'Next month (PageDown)',
    },
    previousYear: {
        id: 'common.data-picker.locale.previousYear',
        defaultMessage: 'Last year (Control + left)',
    },
    nextYear: {
        id: 'common.data-picker.locale.nextYear',
        defaultMessage: 'Next year (Control + right)',
    },
    previousDecade: {
        id: 'common.data-picker.locale.previousDecade',
        defaultMessage: 'Last decade',
    },
    nextDecade: {
        id: 'common.data-picker.locale.nextDecade',
        defaultMessage: 'Next decade',
    },
    previousCentury: {
        id: 'common.data-picker.locale.previousCentury',
        defaultMessage: 'Last century',
    },
    nextCentury: {
        id: 'common.data-picker.locale.nextCentury',
        defaultMessage: 'Next century',
    },
    timePlaceholder: {
        id: 'common.data-picker.locale.TimePlaceholder',
        defaultMessage: 'Select time',
    },
});


export const computeLocale = (intl: IntlShape): PickerLocale => {
    const f = intl.formatDate(new Date(2000, 2, 1));
    const dateFormat = f.replace('2000', 'YYYY').replace(/3|03/, 'MM').replace(/1|01/, 'DD');

    const f2 = intl.formatTime(new Date(2000, 2, 1, 4, 5, 6), {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    });
    const timeFormat = f2.replace('4', 'HH').replace('05', 'mm').replace('06', 'ss');

    return {
        lang: {
            locale: 'react-intl',

            placeholder: intl.formatMessage(messages.datePlaceholder),
            rangePlaceholder: [
                intl.formatMessage(messages.rangePlaceholderStartDate),
                intl.formatMessage(messages.rangePlaceholderEndDate),
            ],
            today: intl.formatMessage(messages.today),
            now: intl.formatMessage(messages.now),
            backToToday: intl.formatMessage(messages.backToToday),
            ok: intl.formatMessage(messages.ok),
            clear: intl.formatMessage(messages.clear),
            month: intl.formatMessage(messages.month),
            year: intl.formatMessage(messages.year),
            timeSelect: intl.formatMessage(messages.timeSelect),
            dateSelect: intl.formatMessage(messages.dateSelect),
            monthSelect: intl.formatMessage(messages.monthSelect),
            yearSelect: intl.formatMessage(messages.yearSelect),
            decadeSelect: intl.formatMessage(messages.decadeSelect),
            previousMonth: intl.formatMessage(messages.previousMonth),
            nextMonth: intl.formatMessage(messages.nextMonth),
            previousYear: intl.formatMessage(messages.previousYear),
            nextYear: intl.formatMessage(messages.nextYear),
            previousDecade: intl.formatMessage(messages.previousDecade),
            nextDecade: intl.formatMessage(messages.nextDecade),
            previousCentury: intl.formatMessage(messages.previousCentury),
            nextCentury: intl.formatMessage(messages.nextCentury),

            yearFormat: 'YYYY',
            dateFormat,
            'dayFormat': 'D',
            'dateTimeFormat': `${dateFormat} ${timeFormat}`,
            monthFormat: 'MMMM',
            monthBeforeYear: true,
        },
        timePickerLocale: {
            placeholder: intl.formatMessage(messages.timePlaceholder),
        },

        dateFormat,
        dateTimeFormat: `${dateFormat} ${timeFormat}`,
        'weekFormat': 'YYYY-wo',
        'monthFormat': 'YYYY-MM',
    };
};
