import { defineMessages } from 'react-intl';

import { defineKeyBindings, KeyBindingScopeDescriptor } from '../../components/basic/keybindings/keybinding';
import { SCOPE as CASE_SCOPE } from './case';

const messages = defineMessages({
    briefScope: {
        id: 'exploration.keybindings.BriefScope',
        defaultMessage: 'Brief Page',
    },
});

export const SCOPE: KeyBindingScopeDescriptor = {
    id: 'exploration.Brief',
    name: messages.briefScope,
    extends: CASE_SCOPE,
};

export const KEY_BINDINGS = defineKeyBindings({

    /*
    multipleSelection: {
        id: 'exploration.global.MultipleSelection',
        scope: SCOPES.global,
        name: messages.multipleSelection,
        defaultKeys: 'ctrl+🖰',
        locked: true,
    },
  */
});

