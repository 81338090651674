import { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useArgI18n } from 'src/i18n/arg-locales/use-arg-i18n';
import { IntlContextProvider } from './intl-provider';
import { ApplicationSessionIdProvider } from 'src/contexts/application-session-context';
import { AntdConfigProvider } from './antd-provider';
import { UserLocaleProvider } from 'src/contexts/user-locale-context';
import { JobQuitControlProvider } from 'src/contexts/job-quit-control-context';
import {
    ArgIconRepositoryProvider,
    ArgNotificationsProvider,
    ArgPageProvider,
    ArgUploaderProvider,
    DisableDndContainer,
    GlobalProgressProvider,
    useClassNames,
} from 'src/components/basic';
import { Environment } from 'src/utils/environment';
import { CheckVersion } from 'src/components/common/check-version';
import { BasicPane } from 'src/components/common/panes/basic-pane';
import { ArgonosApplicationBrandingProvider } from '../components/application/argonos-application-branding';
import { ArgonosModulesBrandingProvider } from 'src/components/application/argonos-modules-branding';

import 'src/styles/application.less';

const DEFAULT_FAVICON = '/favicon.ico';
const FORCE_LOADING = false;

interface AppGlobalProviderProps {
    children: ReactNode;
}

export function AppGlobalProvider(props: AppGlobalProviderProps) {
    const {
        children,
    } = props;

    const {
        userLocale,
        messages,
        userLocaleConfig,
        antdLocale,
    } = useArgI18n();

    const classNames = useClassNames('arg-application');

    if (FORCE_LOADING || !userLocale || messages === undefined) {
        return (
            <div className={classNames('&', 'loading')}>
                <BasicPane className='fill' backgroundAnimation='wave' icon='icon-loading' />
            </div>
        );
    }

    return (
        <BrowserRouter>
            <IntlContextProvider messages={messages} userLocale={userLocale}>
                <ApplicationSessionIdProvider>
                    <AntdConfigProvider antdLocale={antdLocale}>
                        <UserLocaleProvider userLocaleConfig={userLocaleConfig}>
                            <ArgonosApplicationBrandingProvider>
                                <JobQuitControlProvider>
                                    <ArgIconRepositoryProvider>
                                        <ArgPageProvider
                                            pageTitle={Environment.appName}
                                            iconURL={DEFAULT_FAVICON}
                                            subTitlePosition='start'
                                        >
                                            <DisableDndContainer>
                                                <ArgNotificationsProvider>
                                                    <ArgonosModulesBrandingProvider>
                                                        {Environment.checkVersionMs > 0 &&
                                                            <CheckVersion intervalMs={Environment.checkVersionMs} />}
                                                        <ArgUploaderProvider>
                                                            <GlobalProgressProvider>
                                                                {children}
                                                            </GlobalProgressProvider>
                                                        </ArgUploaderProvider>
                                                    </ArgonosModulesBrandingProvider>
                                                </ArgNotificationsProvider>
                                            </DisableDndContainer>
                                        </ArgPageProvider>
                                    </ArgIconRepositoryProvider>
                                </JobQuitControlProvider>
                            </ArgonosApplicationBrandingProvider>
                        </UserLocaleProvider>
                    </AntdConfigProvider>
                </ApplicationSessionIdProvider>
            </IntlContextProvider>
        </BrowserRouter>
    );
}
