import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ArgTab, ArgTabsSubLevel, useClassNames } from 'src/components/basic';
import { VertexOrEdge } from 'src/exploration/hooks/use-graph-style-customisation';
import { VertexPanel } from './vertex-panel';
import { EdgePanel } from './edge-panel';
import { FullOntology, FullOntologyLinkType, FullOntologyObjectType } from '../../types';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { useDesignItemComputation } from './use-design-item-computation';

import 'src/exploration/common/graph-customiser/graph-customiser.less';
import './design-properties-panel.less';

interface DesignPropertiesPanelProps {
    ontology?: FullOntology;
    vertexSelected: FullOntologyObjectType | undefined;
    edgeSelected: FullOntologyLinkType | undefined;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
    setVertexSelected: Dispatch<SetStateAction<FullOntologyObjectType | undefined>>;
    setEdgeSelected: Dispatch<SetStateAction<FullOntologyLinkType | undefined>>;
}

const messages = defineMessages({
    objects: {
        id: 'settings.design-properties-panel.objects',
        defaultMessage: 'Objects',
    },
    relations: {
        id: 'settings.design-properties-panel.relations',
        defaultMessage: 'Relations',
    },
    designPanel: {
        id: 'settings.design-properties-panel.designPanel',
        defaultMessage: 'Design Panel',
    },
    noSelection: {
        id: 'settings.design-properties-panel.NoSelection',
        defaultMessage: 'No selection',
    },
});

export function DesignPropertiesPanel(props: DesignPropertiesPanelProps) {
    const {
        ontology,
        vertexSelected,
        edgeSelected,
        setOntology,
        setVertexSelected,
        setEdgeSelected,
    } = props;

    const classNames = useClassNames('graph-customiser');
    const intl = useIntl();

    const { item: vertex } = useDesignItemComputation(ontology?.objectTypes, vertexSelected);

    const { item: edge } = useDesignItemComputation(ontology?.linkTypes, edgeSelected);

    const tabs: ArgTab[] = [
        {
            key: VertexOrEdge.Vertex,
            title: messages.objects,
            children: (
                ontology && (
                    <VertexPanel
                        ontology={ontology}
                        setOntology={setOntology}
                        vertexSelected={vertex}
                        setVertexSelected={setVertexSelected}
                    />
                )
            )
            ,
        },
        {
            key: VertexOrEdge.Edge,
            title: messages.relations,
            children: (
                ontology && (
                    <EdgePanel
                        ontology={ontology}
                        setOntology={setOntology}
                        edgeSelected={edge}
                        setEdgeSelected={setEdgeSelected}
                    />
                )),
        },
    ];

    const [activeTab, setActiveTab] = useState(VertexOrEdge.Vertex);

    useEffect(() => {
        if (vertexSelected) {
            setActiveTab(VertexOrEdge.Vertex);
        }
        if (edgeSelected) {
            setActiveTab(VertexOrEdge.Edge);
        }
    }, [edgeSelected, vertexSelected]);

    const handleTabChange = useCallback((tabKey: string | undefined) => {
        if (tabKey === VertexOrEdge.Vertex || tabKey === VertexOrEdge.Edge) {
            setActiveTab(tabKey);
        }
    }, []);

    if (!ontology) {
        return (
            <div className={classNames('&', 'empty')}>
                <EmptyPane
                    message={messages.noSelection}
                    icon='icon-info'
                    size='medium'
                />
            </div>
        );
    }

    return (
        <div className={classNames('design-properties-panel')}>
            <div className={classNames('&')}>
                <h4 className={classNames('design-properties-panel-title')}>
                    {intl.formatMessage(messages.designPanel)}
                </h4>
                <ArgTabsSubLevel
                    tabs={tabs}
                    activeTabKey={activeTab}
                    onChange={handleTabChange}
                />
            </div>
        </div>
    );
}
