import { useCallback, useMemo } from 'react';

import { ArgChangeReason, ArgInputTime, ArgSize, ClassValue, dayjs } from 'src/components/basic';

interface HourInputProps {
    className?: ClassValue;
    value?: number;
    onChange: (value: number | null) => void;
    size?: ArgSize;
}

export function HourInput(props: HourInputProps) {
    const {
        value,
        onChange,
        className,
        size,
    } = props;

    const d = useMemo<dayjs.Dayjs | undefined>(() => {
        if (value === undefined) {
            return undefined;
        }
        const h = Math.floor(value / 3600) % 24;
        const m = Math.floor(value / 60) % 60;
        const s = value % 60;

        const ret = dayjs().second(s).minute(m).hour(h).millisecond(0);

        return ret;
    }, [value]);

    const handleChange = useCallback((value: dayjs.Dayjs | null, reason: ArgChangeReason) => {
        if (!value) {
            onChange(value);

            return;
        }

        const h = value.hour();
        const m = value.minute();
        const s = value.second();

        const ret = s + 60 * (m + 60 * h);
        onChange(ret);
    }, [onChange]);

    return <ArgInputTime
        className={className}
        size={size}
        value={d}
        onChange={handleChange}
    />;
}
