import { useEffect, useState } from 'react';

import { FormRepository } from './actions/form-actions-engine';
import { $yield } from '../../../components/basic';
import { FormDocument } from '../../../components/common/forms/model';

export function useFormRepository(repository: undefined): undefined;
export function useFormRepository(repository: FormRepository): FormDocument;
export function useFormRepository(repository: FormRepository | undefined): FormDocument | undefined;

export function useFormRepository(repository: FormRepository | undefined): FormDocument | undefined {
    const [, setStateId] = useState<number>(0);

    useEffect(() => {
        if (!repository) {
            return;
        }

        function repositoryChanged() {
            $yield(() => {
                setStateId((prev) => (prev + 1));
            });
        }

        repository.addListener('*', repositoryChanged);

        return () => {
            repository.removeListener('*', repositoryChanged);
        };
    }, [repository]);

    return repository?.formDocument;
}

