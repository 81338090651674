import { createDragDropSupport, createTypePlugin } from '../type-editing-plugin';
import { postEditingDowncast } from './post-editing-downcast'; // Remove it for CKEditor build

export const EXPLORATION_VIEW_DND_TYPE = 'application/arg-exploration';

export const ExplorationEditing = createTypePlugin('argonos-exploration',
    ['explorationId', 'caseId', 'universeId', 'explorationOrigin', 'width', 'height', 'mode', 'zoom', 'offsetX', 'offsetY'],
    {
        defineClipboardInputOutput: createDragDropSupport(EXPLORATION_VIEW_DND_TYPE), // Remove it for CKEditor build
        containerElement: 'div',
        containerElementClassName: 'regraph',
        hasSelectionHandle: true,
        isBlock: true,
        customProperty: 'exploration2',
        attributeTypes: {
            zoom: 'number',
            offsetX: 'number',
            offsetY: 'number',
        },

        postEditingDowncast, // Remove it for CKEditor build
    });
