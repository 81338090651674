import { defineMessages, FormattedMessage, IntlShape, MessageDescriptor } from 'react-intl';
import React, { ReactNode } from 'react';

import { ArgUserInfo, isMessageDescriptor, MESSAGE_DESCRIPTOR_FORMATTERS, renderText } from 'src/components/basic';
import { DATE_FORMAT } from '../components/common/date-by-user';

const messages = defineMessages({
    anonymous: {
        id: 'utils.formatter.formatUser.Anonymous',
        defaultMessage: 'anonymous',
    },
    me: {
        id: 'utils.formatter.formatUser.Me',
        defaultMessage: 'me',
    },
    dateAndUser: {
        id: 'utils.formatter.formatUser.DateAndUser',
        defaultMessage: '{date} by {user}',
    },
    unknownUser: {
        id: 'utils.formatter.formatUser.UnknownUser',
        defaultMessage: '<grey><i>Unknown</i></grey><',
    },
});

export function formatUser(intl: IntlShape, user?: ArgUserInfo, currentUser?: ArgUserInfo): string {
    if (currentUser && currentUser.id === user?.id) {
        return intl.formatMessage(messages.me);
    }

    if ((user as ArgUserInfo)?.displayName) {
        return (user as ArgUserInfo).displayName!;
    }

    return intl.formatMessage(messages.anonymous);
}

export function formatMessage(message: ReactNode | MessageDescriptor, intl?: IntlShape, messageValues?: Record<string, any>) {
    if (intl && isMessageDescriptor(message)) {
        return intl.formatMessage(message as MessageDescriptor, messageValues);
    }

    return message;
}


export function formatPartialCount(totalCount: number, isPartialDisplay: boolean, partialCount?: number): string {
    if (!partialCount || !isPartialDisplay || totalCount <= partialCount) {
        return String(totalCount);
    }

    return `${partialCount}/${totalCount}`;
}

export function formatDateAndUser(
    intl: IntlShape,
    actionDate?: Date,
    actionResponsible?: ArgUserInfo,
    currentUser?: ArgUserInfo,
    messagesDescriptor: MessageDescriptor = messages.dateAndUser,
    noDate: ReactNode = null
) {
    if (!actionDate) {
        return noDate;
    }

    let user: ReactNode;
    if (actionResponsible) {
        user = formatUser(intl, actionResponsible, currentUser);
    } else {
        user = renderText(messages.unknownUser);
    }

    const message = <FormattedMessage
        {...messagesDescriptor}
        values={{
            ...MESSAGE_DESCRIPTOR_FORMATTERS,
            date: intl.formatDate(actionDate, DATE_FORMAT),
            user,
        }}
    />;

    return message;
}
