/**
 * Use a custom dragging context with react-dnd instead of useDragLayer or canDrop
 * useDragLayer cause some sibling to rerender and drag stop directly after starting
 */
import React, { createContext, useContext, useState } from 'react';

export type DraggingId = string;

interface DraggingContextProps {
    dragging: DraggingId | undefined;
    setDragging: (dragging: DraggingId | undefined) => void;
}

const DraggingContext = createContext<DraggingContextProps | undefined>(undefined);

export function DraggingContextProvider({ children }: { children: React.ReactNode }) {
    const [dragging, _setDragging] = useState<DraggingId>();

    const setDragging = (dragging: DraggingId | undefined) => {
        // https://github.com/react-dnd/react-dnd/issues/2177#issuecomment-607171231
        setTimeout(() => {
            _setDragging(dragging);
        });
    };

    return (
        <DraggingContext.Provider value={{ dragging, setDragging }}>
            {children}
        </DraggingContext.Provider>
    );
}

export function useDraggingContext(): DraggingContextProps {
    const context = useContext(DraggingContext);

    if (!context) {
        throw new Error('Using DraggingContext context without provider');
    }

    return context;
}

export function useDragging(): DraggingId | undefined {
    const context = useDraggingContext();

    return context.dragging;
}
