import { OntologyFeedSources } from 'src/settings/models/feed-sources';
import { ProgressMonitor, SubProgressMonitor } from 'src/components/basic';
import { downloadBlob } from 'src/utils/file';
import { FullOntology } from '../ontology/types';
import { ConfigurationOption, ImportAction } from 'src/model/configuration';
import configurationConnector from 'src/utils/connectors/configuration-connector';
import { ConfigurationsScope } from '../../configuration/configuration-type';

const CONFIGURATION_SCOPE:ConfigurationsScope = 'data_exploration';

interface FeedSourcesExportModel {
    feedSources:OntologyFeedSources;
    name:string;
    displayName:string;
}

export interface OntologyExportModel extends Pick<FullOntology, 'name' | 'description' | 'universeIds' | 'objectTypes' | 'linkTypes' | 'metaProperties' | 'fullStyle'> {
    objectsFeedSources:FeedSourcesExportModel[];
    linksFeedSources:FeedSourcesExportModel[];
}

export const exportOntology = async (ontology:FullOntology, progressMonitor:ProgressMonitor) => {
    const ret:Blob = await configurationConnector.exportConfigurations({
        options: [
            {
                type: 'ontologies',
                configurationKeys: [ontology.id],
                options: {},
            },
        ],
    }, CONFIGURATION_SCOPE, progressMonitor);
    downloadBlob(`${ontology.name}-${new Date().getTime()}.zip`, ret);
};

export const importOntology = async (file:Blob, progressMonitor:ProgressMonitor = ProgressMonitor.empty()) => {
    const sub1 = new SubProgressMonitor(progressMonitor, 1);
    const manifest = await configurationConnector.importManifest(file, CONFIGURATION_SCOPE, sub1);
    const ontologyConfiguration = manifest.configurations.find((option) => option.type === 'ontologies');
    const universeConfiguration = manifest.configurations.find((option) => option.type === 'universes');
    if (!ontologyConfiguration && !universeConfiguration) {
        throw new Error('No ontology or universe configuration found in the file.');
    }
    const ontologyConfigurationKeys = ontologyConfiguration?.configurations.map((configuration) => configuration.id);
    const universeConfigurationKeys = universeConfiguration?.configurations.map((configuration) => configuration.id);

    const universeOptions:ConfigurationOption[] = universeConfigurationKeys ? [{
        type: 'universes',
        configurationKeys: universeConfigurationKeys,
        options: {},
    }] : [];

    const ontologyOptions:ConfigurationOption[] = ontologyConfigurationKeys ? [{
        type: 'ontologies',
        configurationKeys: ontologyConfigurationKeys,
        options: {},
    }] : [];

    const sub2 = new SubProgressMonitor(progressMonitor, 1);
    await configurationConnector.importConfigurations(
        file,
        {
            options: [
                ...universeOptions,
                ...ontologyOptions,
            ],
        },
        ImportAction.RenameConflicts,
        CONFIGURATION_SCOPE,
        sub2
    );
};
