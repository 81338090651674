import moment from 'moment';
import { DataGroupCollectionType, DataItemCollectionType } from 'vis-timeline';
import { isString } from 'lodash';

import { ArgUserId, ProgressMonitor, SubProgressMonitor } from '../../../components/basic';
import { CasePieceType } from '../../../model/case-piece-type';
import {
    ConnectorRequestInit,
    createPatchRequest,
    ETaggedObject,
    isNotChangedEtagError,
    JsonChange,
    RangeResponse,
} from '../../../utils/connector';
import { getProceoApi } from '../../../utils/connectors/api-url';
import { BaseConnector } from '../../../utils/connectors/base-connector';
import {
    ActId,
    ActTemplate,
    ActTemplateId,
    ActTemplateSortBy,
    ActTemplateToCreate,
    ActToCreate,
    ActType,
} from '../../model/act';
import { PersonId, ProceoPerson } from '../../model/person';
import {
    mapActTemplate,
    mapActType,
    mapArticulationTemplate,
    mapFullAct,
    mapLightAct,
    mapOffenceFull,
    mapOffenceLight,
    mapPerson,
    mapService,
} from './mappers';
import {
    RawAct,
    RawActTemplate,
    RawActType,
    RawArticulationTemplate,
    RawFullAct,
    RawOffenceFull,
    RawOffenceLight,
    RawProceoPerson,
    RawService,
} from './raws';
import { FolderId } from '../../../model/folder';
import {
    OffenceId,
    OffenceReference,
    OffenceReferenceId,
    OffenceReferenceSortBy,
    OffenceToCreate,
} from '../../model/reference';
import { ProceoPieceConnector } from './proceo-piece-connector';
import {
    Interpreter,
    InterpreterSortBy,
    InterpreterToCreate, InterpreterUsage, InterpreterUsageId, InterpreterUsageToCreate,
    LanguageByCode,
    LightInterpreter,
} from '../../model/interpreter';
import { NewService, Service, ServiceId } from 'src/proceo/model/service';
import {
    ArticulationTemplate,
    ArticulationTemplateId,
    ArticulationTemplateSortBy,
    ArticulationTemplateToCreate,
} from '../../model/articulation';
import { Court, CourtId, CourtSortBy } from '../../model/court';

export class ProceoConnector extends BaseConnector {
    private static instance: ProceoConnector;
    private static readonly ACTS_URL = '/acts';
    private static readonly ACT_TEMPLATES_URL = '/act-templates';
    private static readonly ARTICULATION_TEMPLATES_URL = '/articulation-templates';
    private static readonly ACT_TYPES_URL = '/act-types';
    private static readonly OFFENCE_REF_URL = '/offence-references';
    private static readonly OFFENCES_URL = '/offences';
    private static readonly INTERPRETERS_URL = '/interpreters';
    private static readonly SERVICES_URL = '/services';
    private static readonly GROUPS_URL = '/groups';
    private static readonly COMMON_URL = '/common';
    private static readonly COURTS_URL = '/courts';
    private static readonly INTERPRETER_USAGES_URL = '/interpreter-usages';

    static getInstance(): ProceoConnector {
        if (!ProceoConnector.instance) {
            ProceoConnector.instance = new ProceoConnector('proceo', getProceoApi());
        }

        return ProceoConnector.instance;
    }

    async getIsolatedActs(progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const url = '/acts/isolated';

        const result: { acts: RawFullAct[] } = await this.request(url, {
            verifyJSONResponse: true,
            params: {
                withDependenciesLoading: true,
            },
        }, progressMonitor);

        const ret = result.acts.map(mapFullAct);

        return ret;
    }

    /**
     * Returns all offence references
     * @param search
     * @param ids
     * @param skip start
     * @param top count
     * @param sortBy column sort OffenceReferenceSortBy
     * @param sortDirection direction of the sort 'ascending' | 'descending'
     * @param progressMonitor
     */
    async getOffencesReferences(
        search?: string,
        ids?: OffenceReferenceId[],
        skip?: number, // start,
        top?: number, // count
        sortBy?: OffenceReferenceSortBy,
        sortDirection?: 'ascending' | 'descending',
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<RangeResponse<OffenceReference>> {
        const results = await this.requestResults<OffenceReference>(ProceoConnector.OFFENCE_REF_URL, 'offenceReferences', {
            params: {
                search,
                ids,
                top,
                skip,
                sortingColumn: sortBy,
                sortingDirection: sortDirection ? ((sortDirection === 'ascending') ? 'Ascending' : 'Descending') : undefined,
            },
            verifyJSONResponse: true,
        }, progressMonitor);

        const ret: RangeResponse<OffenceReference> = {
            ...results,
            data: results.data,
        };

        return ret;
    }

    async getGroups(): Promise<DataGroupCollectionType> {
        const groups: DataGroupCollectionType = [];
        const mockedGroups: DataGroupCollectionType = [
            {
                id: 0,
                content: 'Initial',
            },
            {
                id: 10,
                content: 'Garde à vue M. Filou',
            },
            {
                id: 11,
                content: 'Garde à vue M. Filou',
            },
            {
                id: 12,
                content: 'Garde à vue M. Filou',
            },
            {
                id: 13,
                content: 'Garde à vue M. Filou',
            },
            {
                id: 20,
                content: 'Garde à vue L. Méchant',
            },
            {
                id: 21,
                content: 'Garde à vue L. Méchant',
            },
            {
                id: 22,
                content: 'Garde à vue L. Méchant',
            },
            {
                id: 23,
                content: 'Garde à vue L. Méchant',
            },
            {
                id: 24,
                content: 'Garde à vue L. Méchant',
            },
        ];
        groups.push(...mockedGroups);

        return groups;
    }

    async getItems(): Promise<DataItemCollectionType> {
        const date = new Date();
        const items: DataItemCollectionType = [];
        const mockedItems: DataItemCollectionType = [
            {
                id: 10,
                group: 10,
                start: moment(date).add(5, 'hours').toDate(),
                end: moment(date).add(11, 'hours').toDate(),
                content: 'Audition M. Filou',
                className: 'tl-item-person',
            },
            {
                id: 11,
                group: 11,
                start: moment(date).add(5, 'hours').toDate(),
                end: moment(date).add(11, 'hours').toDate(),
                content: 'Garde à vue (initiale)',
                className: 'tl-item-green',
            },
            {
                id: 12,
                group: 12,
                start: moment(date).add(5, 'hours').toDate(),
                end: moment(date).add(11, 'hours').toDate(),
                content: 'Assistant avocat - Garde à vue',
                className: 'tl-item-default',
            },
            {
                id: 13,
                group: 13,
                start: moment(date).add(8, 'hours').toDate(),
                end: moment(date).add(9, 'hours').toDate(),
                content: 'Avis',
                className: 'tl-item-default',
            },
            {
                id: 20,
                group: 20,
                start: moment(date).add(-10, 'hours').toDate(),
                end: moment(date).add(-5, 'hours').toDate(),
                content: 'Audition L. Mechant',
                className: 'tl-item-person',
            },
            {
                id: 21,
                group: 21,
                start: moment(date).add(-10, 'hours').toDate(),
                end: moment(date).add(-5, 'hours').toDate(),
                content: 'Garde à vue (initiale)',
                className: 'tl-item-done',
            },
            {
                id: 22,
                group: 21,
                start: moment(date).add(-5, 'hours').toDate(),
                end: moment(date).add(11, 'hours').toDate(),
                content: 'Prolongation - Garde à vue',
                className: 'tl-item-pink',
            },
            {
                id: 23,
                group: 22,
                start: moment(date).add(-10, 'hours').toDate(),
                end: moment(date).add(-5, 'hours').toDate(),
                content: 'Assistant avocat - Garde à vue',
                className: 'tl-item-done',
            },
            {
                id: 24,
                group: 22,
                start: moment(date).add(-5, 'hours').toDate(),
                end: moment(date).add(11, 'hours').toDate(),
                content: 'Assistant avocat - Garde à vue',
                className: 'tl-item-default',
            },
            {
                id: 25,
                group: 23,
                start: moment(date).add(-7, 'hours').toDate(),
                end: moment(date).add(-6, 'hours').toDate(),
                content: 'Avis',
                className: 'tl-item-done',
            },
            {
                id: 26,
                group: 24,
                start: moment(date).add(-7, 'hours').toDate(),
                end: moment(date).add(-6, 'hours').toDate(),
                content: 'Avis',
                className: 'tl-item-done',
            },
        ];
        items.push(...mockedItems);

        return items;
    }

    async createFolderAct(act: ActToCreate, folderId?: FolderId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const p1 = new SubProgressMonitor(progressMonitor, 1);
        const options: ConnectorRequestInit = {
            method: 'POST',
            json: act,
            verifyJSONResponse: true,
        };

        const rawAct: RawAct = await this.request(ProceoConnector.ACTS_URL, options, p1);

        const result = mapLightAct(rawAct);
        if (folderId && result) {
            const p2 = new SubProgressMonitor(progressMonitor, 1);
            await ProceoPieceConnector.getInstance().addPiece(folderId, result.id, CasePieceType.Act, p2);
        }

        return result;
    }

    async getLightAct(actId: ActId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
        };

        const rawAct: RawAct = await this.request(`${ProceoConnector.ACTS_URL}/${encodeURIComponent(actId)}`, options, progressMonitor);

        const result = mapLightAct(rawAct);

        return result;
    }

    async getFullAct(actId: ActId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
            params: {
                withDependenciesLoading: true,
            },
        };

        const rawAct: RawFullAct = await this.request(`${ProceoConnector.ACTS_URL}/${encodeURIComponent(actId)}`, options, progressMonitor);

        const result = mapFullAct(rawAct);

        return result;
    }

    async getActToken(actId: ActId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: false,
        };
        const jwtToken: string = await this.request(`${ProceoConnector.ACTS_URL}/${encodeURIComponent(actId)}/token`, options, progressMonitor);

        if (!isString(jwtToken) || !jwtToken) {
            throw new Error('Invalid JWT token');
        }

        return jwtToken;
    }

    async saveActContent(actId: ActId, content: string, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                content: content,
            },
        };

        await this.request(`${ProceoConnector.ACTS_URL}/${encodeURIComponent(actId)}/save`, options, progressMonitor);
    }

    async getActContent(actId: ActId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: false,
        };
        let template: string;
        try {
            template = await this.request(`${ProceoConnector.ACTS_URL}/${encodeURIComponent(actId)}/content`, options, progressMonitor);
        } catch (error) {
            template = '';
        }

        return template;
    }

    async patchAct(actId: ActId, changes: JsonChange[], targetFolderId?: FolderId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        if (targetFolderId) {
            const p2 = new SubProgressMonitor(progressMonitor, 1);
            await ProceoPieceConnector.getInstance().addPiece(targetFolderId, actId, CasePieceType.Act, p2);
        }

        if (!changes.length) {
            return;
        }

        const url = `${ProceoConnector.ACTS_URL}/${encodeURIComponent(actId)}`;

        const options = createPatchRequest('Change act properties', ...changes);

        await this.request(url, options, progressMonitor);
    }

    async createActTemplate(actTemplate: ActTemplateToCreate, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<ActTemplate> {
        const options: ConnectorRequestInit = {
            method: 'POST',
            json: actTemplate,
        };

        const rawActTemplate: RawActTemplate = await this.request(`${ProceoConnector.ACT_TEMPLATES_URL}`, options, progressMonitor);

        const result = mapActTemplate(rawActTemplate);

        return result;
    }

    async saveActTemplate(actTemplateId: ActTemplateId, content: string, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                content: content,
            },
        };

        await this.request(`${ProceoConnector.ACT_TEMPLATES_URL}/${encodeURIComponent(actTemplateId)}/save`, options, progressMonitor);
    }

    async patchActTemplate(actTemplateId: ActTemplateId, changes: JsonChange[], progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        if (!changes.length) {
            return;
        }

        const url = `${ProceoConnector.ACT_TEMPLATES_URL}/${encodeURIComponent(actTemplateId)}`;

        const options = createPatchRequest('Change act template properties', ...changes);

        await this.request(url, options, progressMonitor);
    }

    async getActTemplate(actTemplateId: ActTemplateId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
        };

        const rawActTemplate: RawActTemplate = await this.request(`${ProceoConnector.ACT_TEMPLATES_URL}/${encodeURIComponent(actTemplateId)}`, options, progressMonitor);

        const result = mapActTemplate(rawActTemplate);

        return result;
    }

    async getArticulationTemplate(articulationTemplateId: ArticulationTemplateId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
        };

        const rawArticulationTemplate: RawArticulationTemplate = await this.request(`${ProceoConnector.ARTICULATION_TEMPLATES_URL}/${encodeURIComponent(articulationTemplateId)}`, options, progressMonitor);

        const result = mapArticulationTemplate(rawArticulationTemplate);

        return result;
    }

    async getActTypes(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<ActType[]> {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
        };

        const data = await this.request(`${ProceoConnector.ACT_TYPES_URL}`, options, progressMonitor);
        const result: ActType[] = data.actTypes.map((item: RawActType) => mapActType(item));

        return result;
    }

    async getArticulationTemplates(
        search?: string,
        skip?: number, // start,
        top?: number, // count
        sortBy?: ArticulationTemplateSortBy,
        sortDirection?: 'ascending' | 'descending',
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<RangeResponse<ArticulationTemplate>> {
        const results = await this.requestResults<RawArticulationTemplate>(ProceoConnector.ARTICULATION_TEMPLATES_URL, 'articulationTemplates', {
            params: {
                search,
                top,
                skip,
                sortingColumn: sortBy,
                sortingDirection: sortDirection ? ((sortDirection === 'ascending') ? 'Ascending' : 'Descending') : undefined,
            },
            verifyJSONResponse: true,
        }, progressMonitor);

        const ret: RangeResponse<ArticulationTemplate> = {
            ...results,
            data: results.data.map(mapArticulationTemplate),
        };

        return ret;
    }

    async deleteArticulationTemplate(articulationTemplateId: ArticulationTemplateId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const url = `${ProceoConnector.ARTICULATION_TEMPLATES_URL}/${encodeURIComponent(articulationTemplateId)}`;
        const options: ConnectorRequestInit = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    async createArticulationTemplate(articulationTemplate: ArticulationTemplateToCreate, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<ArticulationTemplate> {
        const options: ConnectorRequestInit = {
            method: 'POST',
            json: articulationTemplate,
        };

        const rawArticulationTemplate: RawArticulationTemplate = await this.request(`${ProceoConnector.ARTICULATION_TEMPLATES_URL}`, options, progressMonitor);

        const result = mapArticulationTemplate(rawArticulationTemplate);

        return result;
    }

    async patchArticulationTemplate(articulationTemplateId: ArticulationTemplateId, changes: JsonChange[], progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        if (!changes.length) {
            return;
        }

        const url = `${ProceoConnector.ARTICULATION_TEMPLATES_URL}/${encodeURIComponent(articulationTemplateId)}`;

        const options = createPatchRequest('Change articulation template properties', ...changes);

        await this.request(url, options, progressMonitor);
    }

    async getPerson(personId: PersonId,
        previousPerson: ProceoPerson | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ProceoPerson> {
        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        try {
            const options: ConnectorRequestInit = {
                verifyJSONResponse: true,
                etag: (previousPerson as ETaggedObject)?.etag,
            };

            const rawPerson: RawProceoPerson = await this.request(`/persons/${encodeURIComponent(personId)}`, options, sub1);

            const ret = mapPerson(rawPerson);

            return ret;
        } catch (error) {
            if (isNotChangedEtagError(error)) {
                return previousPerson!;
            }

            throw error;
        }
    }

    async createFolderPerson(
        folderId: FolderId | undefined,
        newPerson: ProceoPerson,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ProceoPerson> {
        const url = '/persons';
        const content: any = { ...newPerson };
        delete content.id;
        delete content.type;
        delete content.role;
        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                type: newPerson.type,
                role: newPerson.role,
                content: content,
            },
            verifyJSONResponse: true,
        };

        const p1 = new SubProgressMonitor(progressMonitor, 1);
        const rawPerson: RawProceoPerson = await this.request(url, options, p1);

        const ret = mapPerson(rawPerson);

        if (folderId && ret) {
            const p2 = new SubProgressMonitor(progressMonitor, 1);
            await ProceoPieceConnector.getInstance().addPiece(folderId, ret.id, CasePieceType.Person, p2);
        }

        return ret;
    }

    async updatePerson(personId: PersonId, newPerson: ProceoPerson, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const url = `/persons/${encodeURIComponent(personId)}`;
        const content: any = { ...newPerson };
        delete content.id;
        delete content.type;
        delete content.role;
        const options: ConnectorRequestInit = {
            method: 'PUT',
            json: {
                type: newPerson.type,
                role: newPerson.role,
                content: content,
            },
            verifyJSONResponse: true,
        };

        await this.request(url, options, progressMonitor);
    }

    async deletePerson(personId: PersonId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const url = `/persons/${encodeURIComponent(personId)}`;
        const options: ConnectorRequestInit = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    async deleteAct(actId: ActId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const url = `/acts/${encodeURIComponent(actId)}`;
        const options: ConnectorRequestInit = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    async getFullOffence(offenceId: OffenceId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
            params: {
                withDependenciesLoading: true,
            },
        };

        const rawOffenceFull: RawOffenceFull = await this.request(`${ProceoConnector.OFFENCES_URL}/${encodeURIComponent(offenceId)}`, options, progressMonitor);

        const result = mapOffenceFull(rawOffenceFull);

        return result;
    }

    async getLightOffence(offenceId: OffenceId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
            params: {
                withDependenciesLoading: false,
            },
        };

        const rawOffenceLight: RawOffenceLight = await this.request(`${ProceoConnector.OFFENCES_URL}/${encodeURIComponent(offenceId)}`, options, progressMonitor);

        const result = mapOffenceLight(rawOffenceLight);

        return result;
    }

    async createFolderOffence(folderId: FolderId | undefined, offence: OffenceToCreate, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const url = `${ProceoConnector.OFFENCES_URL}`;
        const options: ConnectorRequestInit = {
            method: 'POST',
            json: offence,
            verifyJSONResponse: true,
        };

        const p1 = new SubProgressMonitor(progressMonitor, 1);
        const rawOffenceLight: RawOffenceLight = await this.request(url, options, p1);

        const ret = mapOffenceLight(rawOffenceLight);

        if (folderId && ret) {
            const p2 = new SubProgressMonitor(progressMonitor, 1);
            await ProceoPieceConnector.getInstance().addPiece(folderId, ret.id, CasePieceType.Offence, p2);
        }

        return ret;
    }

    async deleteOffence(offenceId: OffenceId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const url = `${ProceoConnector.OFFENCES_URL}/${encodeURIComponent(offenceId)}`;
        const options = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    /**
     * Returns interpreters
     * @param search
     * @param skip start
     * @param top count
     * @param sortBy column sort InterpreterSortBy
     * @param sortDirection direction of the sort 'ascending' | 'descending'
     * @param progressMonitor
     */
    async getInterpreters(
        search?: string,
        skip?: number, // start,
        top?: number, // count
        sortBy?: InterpreterSortBy,
        sortDirection?: 'ascending' | 'descending',
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<RangeResponse<Interpreter>> {
        const results = await this.requestResults<Interpreter>(ProceoConnector.INTERPRETERS_URL, 'interpreters', {
            params: {
                search,
                top,
                skip,
                sortingColumn: sortBy,
                sortingDirection: sortDirection ? ((sortDirection === 'ascending') ? 'Ascending' : 'Descending') : undefined,
                withDependenciesLoading: true,
            },
            verifyJSONResponse: true,
        }, progressMonitor);

        const ret: RangeResponse<Interpreter> = {
            ...results,
            data: results.data,
        };

        return ret;
    }

    async createInterpreter(interpreter: InterpreterToCreate, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<LightInterpreter> {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
            method: 'POST',
            json: interpreter,
        };

        const result: LightInterpreter = await this.request(`${ProceoConnector.INTERPRETERS_URL}`, options, progressMonitor);

        return result;
    }

    async getAllServices(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Service[]> {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
        };

        const data = await this.request(`${ProceoConnector.SERVICES_URL}`, options, progressMonitor);
        const result: Service[] = data.services.map(mapService);

        return result;
    }


    async createService(service: NewService, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Service> {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
            method: 'POST',
            json: service,
        };

        const rawServiceFull: RawService = await this.request(`${ProceoConnector.SERVICES_URL}`, options, progressMonitor);
        const result = mapService(rawServiceFull);

        return result;
    }

    async deleteService(serviceId: ServiceId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const url = `${ProceoConnector.SERVICES_URL}/${encodeURIComponent(serviceId)}`;
        const options = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    async patchService(serviceId: ServiceId, changes: JsonChange[], progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        if (!changes.length) {
            return;
        }

        const url = `${ProceoConnector.SERVICES_URL}/${encodeURIComponent(serviceId)}`;

        const options = createPatchRequest('Change services properties', ...changes);

        await this.request(url, options, progressMonitor);
    }


    async getServiceOfGroup(groupId: FolderId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Service | null> {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
        };

        const data = await this.request(`${ProceoConnector.GROUPS_URL}/${encodeURIComponent(groupId)}/service`, options, progressMonitor);
        if (data) {
            const result: Service = mapService(data);

            return result;
        }

        return null;
    }

    async getGroupsOfUser(userId: ArgUserId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<string[]> {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
        };

        const data = await this.request(`${ProceoConnector.GROUPS_URL}/users/${encodeURIComponent(userId)}`, options, progressMonitor);
        if (data.groupIds) {
            return data.groupIds;
        }

        return [];
    }

    async getServiceOfUser(userId: ArgUserId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Service | null> {
        const _groups = await this.getGroupsOfUser(userId, progressMonitor);
        let result = null;
        if (_groups.length) { //FIXME for now just get the first group
            result = await this.getServiceOfGroup(_groups[0], progressMonitor);
        }

        return result;
    }

    /**
     * Returns actTemplates
     */
    async getActTemplates(
        search?: string,
        skip?: number, // start,
        top?: number, // count
        sortBy?: ActTemplateSortBy,
        sortDirection?: 'ascending' | 'descending',
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<RangeResponse<ActTemplate>> {
        const results = await this.requestResults<RawActTemplate>(ProceoConnector.ACT_TEMPLATES_URL, 'actTemplates', {
            params: {
                search,
                top,
                skip,
                sortingColumn: sortBy,
                sortingDirection: sortDirection ? ((sortDirection === 'ascending') ? 'Ascending' : 'Descending') : undefined,
            },
            verifyJSONResponse: true,
        }, progressMonitor);

        const ret: RangeResponse<ActTemplate> = {
            ...results,
            data: results.data.map(mapActTemplate),
        };

        return ret;
    }

    async getLanguages(sortByName = true, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<LanguageByCode> {
        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
            params: {
                sortByName: sortByName,
            },
        };

        const data = await this.request(`${ProceoConnector.COMMON_URL}/languages`, options, progressMonitor);
        if (data.languages) {
            return data.languages;
        }

        return {};
    }

    async getCourts(
        search?: string,
        ids?: CourtId[],
        skip?: number, // start,
        top?: number, // count
        sortBy?: CourtSortBy,
        sortDirection?: 'ascending' | 'descending',
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<RangeResponse<Court>> {
        const results = await this.requestResults<Court>(ProceoConnector.COURTS_URL, 'courts', {
            params: {
                search,
                ids,
                top,
                skip,
                sortingColumn: sortBy,
                sortingDirection: sortDirection ? ((sortDirection === 'ascending') ? 'Ascending' : 'Descending') : undefined,
            },
            verifyJSONResponse: true,
        }, progressMonitor);

        const ret: RangeResponse<Court> = {
            ...results,
            data: results.data,
        };

        return ret;
    }

    async createInterpreterUsage(interpreterUsage: InterpreterUsageToCreate, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const options: ConnectorRequestInit = {
            method: 'POST',
            json: interpreterUsage,
            verifyJSONResponse: true,
        };

        const result: InterpreterUsage = await this.request(ProceoConnector.INTERPRETER_USAGES_URL, options, progressMonitor);

        return result;
    }

    async deleteInterpreterUsage(interpreterUsageId: InterpreterUsageId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const url = `${ProceoConnector.INTERPRETER_USAGES_URL}/${encodeURIComponent(interpreterUsageId)}`;
        const options: ConnectorRequestInit = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }
}
