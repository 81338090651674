import { defineMessages } from 'react-intl';
import { useCallback, useState } from 'react';

import { ProgressMonitor, useArgNotifications, useMemoAsync } from '../../components/basic';
import { ProceoConnector } from '../utils/connectors/proceo-connector';
import { Service } from '../model/service';

const messages = defineMessages({
    loadingService: {
        id: 'proceo.hooks.use-get-service.loadingService',
        defaultMessage: 'Loading service {threeDotsLoading}',
    },
    loadingServiceError: {
        id: 'proceo.hooks.use-get-service.loadingServiceError',
        defaultMessage: 'Failed to load service',
    },
});

interface UseGetServicesReturnType {
    services?: Service[];
    progressMonitor?: ProgressMonitor;
    error?: Error;
    refreshServices: () => void;
}

export function useGetServices() {
    const notifications = useArgNotifications();
    const [stateId, setStateId] = useState<number>(0);

    const [services, progressMonitor, error] = useMemoAsync<Service[]>(async (progressMonitor: ProgressMonitor, prev: Service[] | undefined) => {
        try {
            const _services = await ProceoConnector.getInstance().getAllServices(progressMonitor);

            return _services;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return prev;
            }
            notifications.snackError({ message: messages.loadingServiceError }, error as Error);
            throw error;
        }
    }, [stateId], messages.loadingService);

    const refreshServices = useCallback(() => {
        setStateId(prev => prev + 1);
    }, []);

    const ret: UseGetServicesReturnType = {
        services,
        progressMonitor,
        error,
        refreshServices,
    };

    return ret;
}
