import React, { useState } from 'react';
import { defineMessages } from 'react-intl';

import { ArgButton, ArgIcon, ArgInputText, ArgModal, useClassNames } from '../../../../basic';
import { forbiddenIcons } from './icon-displayer';
import { filterAndSortArrayByKeyword } from '../../../../../utils/filter-string-array';

import './icons-modal.less';

// z-index must be superior to popover
const MODAL_Z_INDEX = 2001;

const messages = defineMessages({
    searchIcon: {
        id: 'exploration.icon.modal.search.input.placeholder',
        defaultMessage: 'Search',
    },
    selectIcon: {
        id: 'exploration.icon.modal.search.input.label',
        defaultMessage: 'Select icon',
    },
});

interface IconsModalProps {
    icons: string[];
    selectedIcon?: string;
    onIconChange: ((iconName: string) => void) | undefined;
    onClose: () => void;
    visible: boolean;
}

export function IconsModal(props: IconsModalProps) {
    const {
        icons,
        selectedIcon,
        onIconChange,
        onClose,
        visible,
    } = props;
    const classNames = useClassNames('icons-modal');
    const [searchText, setSearchText] = useState('');
    const allowedIcons = icons.filter((icon) => !forbiddenIcons.includes(icon));
    const [filterdIcons, setFilterdIcons] = useState(allowedIcons);

    const onSearchTextChange = (value: string | null) => {
        if (value === null) {
            setFilterdIcons(allowedIcons);

            return;
        }

        const filteredAndSortedIcons = filterAndSortArrayByKeyword(allowedIcons, value);
        setFilterdIcons(filteredAndSortedIcons);
    };

    const cls = (iconName: string) => ({
        selected: selectedIcon === iconName,
    });

    const handleIconClick = (formattedIconName: string) => {
        onIconChange?.(formattedIconName);
        onClose();
    };

    return (
        <ArgModal
            visible={visible}
            footer={false}
            title={messages.selectIcon}
            centered={true}
            onClose={onClose}
            zIndex={MODAL_Z_INDEX}
            wrapClassName={classNames('&-wrap')}
        >
            <ArgInputText
                className={classNames('&-search-bar')}
                left='magnifier'
                value={searchText}
                onInputChange={setSearchText}
                placeholder={messages.searchIcon}
                onChange={onSearchTextChange}
            />
            <div className={classNames('&-icons-container')}>
                {filterdIcons.map((iconName) => (
                    <ArgButton
                        key={iconName}
                        type='ghost'
                        size='medium'
                        icon={
                            <ArgIcon
                                key={iconName}
                                className={classNames('&-item', cls(iconName))}
                                name={iconName}
                                size='20px'
                            />
                        }
                        onClick={() => handleIconClick(iconName)}
                    />
                ))}
            </div>
        </ArgModal>
    );
}

