import { createContext, useContext } from 'react';

import { GetOntologiesDTO } from 'src/settings/models/dtoApi';
import { ProgressMonitor } from '../../../../components/basic';

interface OntologiesStateContextType {
    ontologies: GetOntologiesDTO[];
    updateOntologies: (progressMonitor: ProgressMonitor) => Promise<void>;
}

export const OntologiesStateContext = createContext<OntologiesStateContextType | undefined>(undefined);

export function useOntologiesState(): OntologiesStateContextType {
    const ontologiesState = useContext(OntologiesStateContext);

    if (ontologiesState === undefined) {
        throw new Error('useOntologiesState must be used within a OntologiesStateContext');
    }

    return ontologiesState;
}
