export enum ConfigurationType {
    Preferences = 'Preferences',
    Administration = 'administration',
    Exploration = 'exploration',
    Preparation = 'preparation',
    ExplorationKeyBinding = 'exploration.ui.KEY_BINDINGS',
    PreparationKeyBinding = 'preparation.ui.KEY_BINDINGS',
    GlobalAdminRole = 'global-administration.roles',
    Users = 'global-administration.users',
    ApplicationSettings = 'all-application-settings',
    EnvironmentRole = 'environment.roles',
    Groups = 'environment.user-groups',
    RemoteComponents = 'data-preparation.remote-components',
    Secrets = 'data-preparation.secrets',
    PreparationRole = 'data-preparation.roles',
    Composites = 'data-preparation.composites',
    Processes = 'data-preparation.processes',
    ExplorationStyle = 'data-exploration.ExplorationStyle',
    ExplorationRole = 'data-exploration.roles',
    UserProfiles = 'global-administration.user-profiles-fields-bundle',
    BriefTemplate = 'data-exploration.Brief',
    Universe = 'data-exploration.Universe',
    ContextualVariable = 'data-exploration.contextual-variable',
    Ontologies = 'data-exploration.ontologies',
}

export const USER_PROFILES_CONF_KEY = 'user-profiles-fields-bundle';

export const CONFIGURATION_POLICIES_OPTIONS = 'misc';

export type ConfigurationsScope = string;
/*    | 'data_exploration'
    | 'data_preparation'
    | 'data_collection'
    | 'proceo'
    | 'admin'
    | 'settings'
    ;
*/
