import React from 'react';

import { Role } from 'src/settings/models/dtoApi';
import { Group, User } from 'src/model/user';
import { ProgressMonitor } from '../../../components/basic';
import { UserProfileField } from '../../../model/user-metadata';

interface UsersAndGroupsStateContextType {
    users: User[];
    setUsers: React.Dispatch<React.SetStateAction<User[]>>;
    deletedUsers: User[];
    setDeletedUsers: React.Dispatch<React.SetStateAction<User[]>>;
    allRoles: Role[];
    groups: Group[];
    setGroups: React.Dispatch<React.SetStateAction<Group[]>>;
    properties: UserProfileField[];
    setProperties: React.Dispatch<React.SetStateAction<UserProfileField[]>>;

    loadingUsers?: ProgressMonitor;
    errorUsers?: Error;
}

export const UsersAndGroupsStateContext = React.createContext<UsersAndGroupsStateContextType>({
    users: [],
    setUsers: () => {
    },
    deletedUsers: [],
    setDeletedUsers: () => {
    },
    allRoles: [],
    groups: [],
    setGroups: () => {
    },
    properties: [],
    setProperties: () => {
    },
});
