import { TemplateConnector } from 'src/framework/templates/templates-connector';
import { getDataExplorationApi } from '../../../utils/connectors/api-url';

export class ExplorationTemplateConnector extends TemplateConnector {
    private static instance: ExplorationTemplateConnector;

    static getInstance(): ExplorationTemplateConnector {
        if (!ExplorationTemplateConnector.instance) {
            ExplorationTemplateConnector.instance = new ExplorationTemplateConnector('exploration.templates', getDataExplorationApi());
        }

        return ExplorationTemplateConnector.instance;
    }
}
