import { OntologyPropertyBaseType } from '../../types';
import { TypeItem } from './const';

export const getBaseType = (type: TypeItem | undefined): OntologyPropertyBaseType | null => {
    if (type === TypeItem.boolean) {
        return OntologyPropertyBaseType.Bool;
    }
    if (type === TypeItem.character) {
        return OntologyPropertyBaseType.Char;
    }
    if (type === TypeItem.date) {
        return OntologyPropertyBaseType.Date;
    }
    if (type === TypeItem.dateAndTime) {
        return OntologyPropertyBaseType.DateTime;
    }
    if (type === TypeItem.string) {
        return OntologyPropertyBaseType.String;
    }
    if (type === TypeItem.text) {
        return OntologyPropertyBaseType.Text;
    }
    if (type === TypeItem.integer32b) {
        return OntologyPropertyBaseType.Int;
    }
    if (type === TypeItem.integer64b) {
        return OntologyPropertyBaseType.Long;
    }
    if (type === TypeItem.integer8b) {
        return OntologyPropertyBaseType.Byte;
    }
    if (type === TypeItem.float) {
        return OntologyPropertyBaseType.Float;
    }
    if (type === TypeItem.geoCoordinates) {
        return OntologyPropertyBaseType.Geoshape;
    }
    if (type === TypeItem.email) {
        return OntologyPropertyBaseType.Email;
    }
    if (type === TypeItem.url) {
        return OntologyPropertyBaseType.Url;
    }
    if (type === TypeItem.phoneNumber) {
        return OntologyPropertyBaseType.PhoneNumber;
    }
    if (type === TypeItem.address) {
        return OntologyPropertyBaseType.Address;
    }
    if (type === TypeItem.country) {
        return OntologyPropertyBaseType.Country;
    }

    return null;
};

export const getTypeItem = (baseType: OntologyPropertyBaseType): TypeItem | null => {
    if (baseType === 'Bool') {
        return TypeItem.boolean;
    } else if (baseType === 'Int') {
        return TypeItem.integer32b;
    } else if (baseType === 'Char') {
        return TypeItem.character;
    } else if (baseType === 'Date') {
        return TypeItem.date;
    } else if (baseType === 'DateTime') {
        return TypeItem.dateAndTime;
    } else if (baseType === 'String') {
        return TypeItem.string;
    } else if (baseType === 'Text') {
        return TypeItem.text;
    } else if (baseType === 'Long') {
        return TypeItem.integer64b;
    } else if (baseType === 'Byte') {
        return TypeItem.integer8b;
    } else if (baseType === 'Float') {
        return TypeItem.float;
    } else if (baseType === 'Geoshape') {
        return TypeItem.geoCoordinates;
    } else if (baseType === 'Email') {
        return TypeItem.email;
    } else if (baseType === 'Url') {
        return TypeItem.url;
    } else if (baseType === 'PhoneNumber') {
        return TypeItem.phoneNumber;
    } else if (baseType === 'Address') {
        return TypeItem.address;
    } else if (baseType === 'Country') {
        return TypeItem.country;
    }

    return null;
};
