import * as uuid from 'uuid';

import { Scope, ScopeProcessed } from '../../../models/policy';
import { FormPolicy, FormPolicyProcessed } from 'src/settings/models/form-policy';

export const getFormPolicyWithActionsIds = (policy: FormPolicy): FormPolicyProcessed => {
    return {
        ...policy,
        statement: {
            ...policy.statement,
            Actions: policy.statement?.Actions.map((action) => {
                return { ...action, id: uuid.v4() };
            }) ?? [],
        },
        scopes: policy.scopes?.map((scope) => {
            return { ...convertPolicyScopeSchema(scope), id: uuid.v4() };
        }) ?? [],
    };
};

/**
 * A scope AND/OR can be described in 2 schemas:
 * Schema1:
 *   {and: [LIST_OF_SCOPES]}
 * Schema2:
 *   {Type: "and", Data: [LIST_OF_SCOPES]}
 * The frontend handles and works with Schema1, so a potential Schema2 will have to be converted first
 */

const convertPolicyScopeSchema = (scope: Scope | ScopeProcessed): Scope | ScopeProcessed => {
    if ('Type' in scope && scope.Type === 'and' && Array.isArray(scope.Data)) {
        return { and: scope.Data.map((scope) => convertPolicyScopeSchema(scope)) };
    }
    if ('Type' in scope && scope.Type === 'or' && Array.isArray(scope.Data)) {
        return { or: scope.Data.map((scope) => convertPolicyScopeSchema(scope)) };
    }
    if ('and' in scope) {
        return { and: scope.and.map((scope) => convertPolicyScopeSchema(scope)) };
    }
    if ('or' in scope) {
        return { and: scope.or.map((scope) => convertPolicyScopeSchema(scope)) };
    }

    return scope;
};
