import { MessageDescriptor } from 'react-intl';

import { ArgMessageValues } from '../types';
import { renderText } from '../utils/message-descriptor-formatters';

interface ArgFormattedMessageProps {
    message: MessageDescriptor;
    messageValues?: ArgMessageValues;
}

export function ArgFormattedMessage(props: ArgFormattedMessageProps) {
    const {
        message,
        messageValues,
    } = props;

    const messageAsReactNode = renderText(message, messageValues);

    return (
        <>{messageAsReactNode}</>
    );
}
