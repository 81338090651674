import { useCallback, useState } from 'react';
import { defineMessages } from 'react-intl';

import { PageHeader } from 'src/settings/common-components/page-header';
import { useClassNames } from 'src/components/basic';
import { TableToolbar } from '../components/table-toolbar/table-toolbar';
import { UniverseTable } from '../components/universe-table/universe-table';

import './universes-management-view.less';

const messages = defineMessages({
    pageTitle: {
        id: 'settings.universes-management-page.title',
        defaultMessage: 'Universes management',
    },
    fetchingUniverses: {
        id: 'settings.uni.fetch-universes.message',
        defaultMessage: 'Loading universes...',
    },
    fetchingUniversesError: {
        id: 'settings.policy.fetch-universes.error',
        defaultMessage: 'An error occurred while fetching the universes',
    },
});

export function UniversesManagementView() {
    const classNames = useClassNames('settings-universes-management-view');

    const [search, setSearch] = useState<string>();

    const handleSearch = useCallback((value: string) => {
        setSearch(value);
    }, []);

    return (
        <div className={classNames('&')}>
            <PageHeader
                className={classNames('&-header')}
                title={messages.pageTitle}
            />
            <div className={classNames('&-body')}>
                <TableToolbar
                    handleSearch={handleSearch}
                    className={classNames('&-body-search')}
                />
                <div className={classNames('&-body-table-container')}>
                    <UniverseTable
                        search={search}
                        className={classNames('&-body-table')}
                    />
                </div>
            </div>
        </div>
    );
}
