import { defineMessages, MessageDescriptor } from 'react-intl';

import { ImportConfigurationError, ImportConfigurationErrorCode, ImportConfigurationErrorType } from '../../model/configuration';

export const configurationImportErrorsMessages = defineMessages({
    configurationItemId: {
        id: 'settings.configuration-import.errors.configurationItemId',
        defaultMessage: 'Id: {id}. ',
    },
    badIdentifierError: {
        id: 'settings.configuration-import.errors.badIdentifierError',
        defaultMessage: 'Bad identifier. ',
    },
    fileNotFoundError: {
        id: 'settings.configuration-import.errors.fileNotFoundError',
        defaultMessage: 'File not found. ',
    },
    manifestNotFoundError: {
        id: 'settings.configuration-import.errors.manifestNotFoundError',
        defaultMessage: 'Manifest not found. ',
    },
    requestKeyNotFoundError: {
        id: 'settings.configuration-import.errors.requestKeyNotFoundError',
        defaultMessage: 'Request key not found. ',
    },
    configurationNotFoundError: {
        id: 'settings.configuration-import.errors.configurationNotFoundError',
        defaultMessage: 'Configuration not found. ',
    },
    importFailedError: {
        id: 'settings.configuration-import.errors.importFailedError',
        defaultMessage: 'Import failed. ',
    },
    importActionNotAllowedError: {
        id: 'settings.configuration-import.errors.importActionNotAllowedError',
        defaultMessage: 'Renaming the {type} is not authorized. ',
    },
    exportFailedError: {
        id: 'settings.configuration-import.errors.exportFailedError',
        defaultMessage: 'Export failed. ',
    },
    exportActionNotAllowedError: {
        id: 'settings.configuration-import.errors.exportActionNotAllowedError',
        defaultMessage: 'Export action not allowed. ',
    },
    providerNotFoundError: {
        id: 'settings.configuration-import.errors.providerNotFoundError',
        defaultMessage: 'Provider not found. ',
    },
    configurationListError: {
        id: 'settings.configuration-import.errors.configurationListError',
        defaultMessage: 'Configuration List. ',
    },
    allApplicationSettings: {
        id: 'settings.configuration-import.errors.allApplicationSettings',
        defaultMessage: 'Application Settings',
    },
    users: {
        id: 'settings.configuration-import.errors.users',
        defaultMessage: 'Users',
    },
    cases: {
        id: 'settings.configuration-import.errors.cases',
        defaultMessage: 'Cases',
    },
    composites: {
        id: 'settings.configuration-import.errors.composites',
        defaultMessage: 'Composites',
    },
    contextualVariable: {
        id: 'settings.configuration-import.errors.contextualVariable',
        defaultMessage: 'Contextual Variable',
    },
    dataAccessPolicies: {
        id: 'settings.configuration-import.errors.dataAccessPolicies',
        defaultMessage: 'Data Access Policies',
    },
    displayTemplatePolicies: {
        id: 'settings.configuration-import.errors.displayTemplatePolicies',
        defaultMessage: 'Display Template Policies',
    },
    generic: {
        id: 'settings.configuration-import.errors.generic',
        defaultMessage: 'Generic',
    },
    ontologies: {
        id: 'settings.configuration-import.errors.ontologies',
        defaultMessage: 'Ontologies',
    },
    processes: {
        id: 'settings.configuration-import.errors.processes',
        defaultMessage: 'Processes',
    },
    remoteComponents: {
        id: 'settings.configuration-import.errors.remoteComponents',
        defaultMessage: 'Remote Components',
    },
    role: {
        id: 'settings.configuration-import.errors.role',
        defaultMessage: 'Role',
    },
    secrets: {
        id: 'settings.configuration-import.errors.secrets',
        defaultMessage: 'Secrets',
    },
    template: {
        id: 'settings.configuration-import.errors.template',
        defaultMessage: 'Template',
    },
    universes: {
        id: 'settings.configuration-import.errors.universes',
        defaultMessage: 'Universes',
    },
    userGroups: {
        id: 'settings.configuration-import.errors.userGroups',
        defaultMessage: 'User Groups',
    },
    userProfilesFieldsBundle: {
        id: 'settings.configuration-import.errors.userProfilesFieldsBundle',
        defaultMessage: 'Users profiles',
    },
    valuationPolicies: {
        id: 'settings.configuration-import.errors.valuationPolicies',
        defaultMessage: 'Valuation Policies',
    },
    mapUniverseFailed: {
        id: 'settings.configuration-import.errors.mapUniverseFailed',
        defaultMessage: 'Map Universe Failed.',
    },
    metaPropertyCreationFailed: {
        id: 'settings.configuration-import.errors.metaPropertyCreationFailed',
        defaultMessage: 'Meta Property Creation Failed.',
    },
    metaPropertyFeedSourcesUpdateFailed: {
        id: 'settings.configuration-import.errors.metaPropertyFeedSourcesUpdateFailed',
        defaultMessage: 'Meta Property Feed Sources Update Failed.',
    },
    metaPropertyUpdateFailed: {
        id: 'settings.configuration-import.errors.metaPropertyUpdateFailed',
        defaultMessage: 'Meta Property Update Failed.',
    },
    objectDisplayConfigurationUpdateFailed: {
        id: 'settings.configuration-import.errors.objectDisplayConfigurationUpdateFailed',
        defaultMessage: 'Object Display Configuration Update Failed.',
    },
    objectFeedSourcesUpdateFailed: {
        id: 'settings.configuration-import.errors.objectFeedSourcesUpdateFailed',
        defaultMessage: 'Object Feed Sources Update Failed.',
    },
    objectTypeCreationFailed: {
        id: 'settings.configuration-import.errors.objectTypeCreationFailed',
        defaultMessage: 'Object Type Creation Failed.',
    },
    objectTypeUpdateFailed: {
        id: 'settings.configuration-import.errors.objectTypeUpdateFailed',
        defaultMessage: 'Object Type Update Failed.',
    },
    ontologyNotFound: {
        id: 'settings.configuration-import.errors.ontologyNotFound',
        defaultMessage: 'Ontology Not Found.',
    },
    ontologyPublishFailed: {
        id: 'settings.configuration-import.errors.ontologyPublishFailed',
        defaultMessage: 'Ontology Publish Failed.',
    },
    ontologyStyleUpdateFailed: {
        id: 'settings.configuration-import.errors.ontologyStyleUpdateFailed',
        defaultMessage: 'Ontology Style Update Failed.',
    },
    ontologyUpdateFailed: {
        id: 'settings.configuration-import.errors.ontologyUpdateFailed',
        defaultMessage: 'Ontology Update Failed.',
    },
    relationDisplayConfigurationUpdateFailed: {
        id: 'settings.configuration-import.errors.relationDisplayConfigurationUpdateFailed',
        defaultMessage: 'Relation Display Configuration Update Failed.',
    },
    relationFeedSourcesUpdateFailed: {
        id: 'settings.configuration-import.errors.relationFeedSourcesUpdateFailed',
        defaultMessage: 'Relation Feed Sources Update Failed.',
    },
    relationTypeCreationFailed: {
        id: 'settings.configuration-import.errors.relationTypeCreationFailed',
        defaultMessage: 'Relation Type Creation Failed.',
    },
    relationTypeUpdateFailed: {
        id: 'settings.configuration-import.errors.relationTypeUpdateFailed',
        defaultMessage: 'Relation Type Update Failed.',
    },
    retentionPolicyPublicationFailed: {
        id: 'settings.configuration-import.errors.retentionPolicyPublicationFailed',
        defaultMessage: 'Retention Policy Publication Failed.',
    },
    retentionPolicyUpdateFailed: {
        id: 'settings.configuration-import.errors.retentionPolicyUpdateFailed',
        defaultMessage: 'Retention Policy Update Failed.',
    },
});

export const DEFINITION_ERRORS_TYPE_MESSAGE: Record<ImportConfigurationErrorType, MessageDescriptor> = {
    [ImportConfigurationErrorType.AllApplicationSettings]: configurationImportErrorsMessages.allApplicationSettings,
    [ImportConfigurationErrorType.Users]: configurationImportErrorsMessages.users,
    [ImportConfigurationErrorType.Cases]: configurationImportErrorsMessages.cases,
    [ImportConfigurationErrorType.Composites]: configurationImportErrorsMessages.composites,
    [ImportConfigurationErrorType.ContextualVariable]: configurationImportErrorsMessages.contextualVariable,
    [ImportConfigurationErrorType.DataAccessPolicies]: configurationImportErrorsMessages.dataAccessPolicies,
    [ImportConfigurationErrorType.DisplayTemplatePolicies]: configurationImportErrorsMessages.displayTemplatePolicies,
    [ImportConfigurationErrorType.Generic]: configurationImportErrorsMessages.generic,
    [ImportConfigurationErrorType.Ontologies]: configurationImportErrorsMessages.ontologies,
    [ImportConfigurationErrorType.Processes]: configurationImportErrorsMessages.processes,
    [ImportConfigurationErrorType.RemoteComponents]: configurationImportErrorsMessages.remoteComponents,
    [ImportConfigurationErrorType.Role]: configurationImportErrorsMessages.role,
    [ImportConfigurationErrorType.Secrets]: configurationImportErrorsMessages.secrets,
    [ImportConfigurationErrorType.Template]: configurationImportErrorsMessages.template,
    [ImportConfigurationErrorType.Universes]: configurationImportErrorsMessages.universes,
    [ImportConfigurationErrorType.UserGroups]: configurationImportErrorsMessages.userGroups,
    [ImportConfigurationErrorType.UserProfilesFieldsBundle]: configurationImportErrorsMessages.userProfilesFieldsBundle,
    [ImportConfigurationErrorType.ValuationPolicies]: configurationImportErrorsMessages.valuationPolicies,
};

export const DEFINITION_ERRORS_MESSAGE: Record<ImportConfigurationError, MessageDescriptor> = {
    [ImportConfigurationError.BadIdentifier]: configurationImportErrorsMessages.badIdentifierError,
    [ImportConfigurationError.FileNotFound]: configurationImportErrorsMessages.fileNotFoundError,
    [ImportConfigurationError.ManifestNotFound]: configurationImportErrorsMessages.manifestNotFoundError,
    [ImportConfigurationError.RequestKeyNotFound]: configurationImportErrorsMessages.requestKeyNotFoundError,
    [ImportConfigurationError.ConfigurationNotFound]: configurationImportErrorsMessages.configurationNotFoundError,
    [ImportConfigurationError.ImportFailed]: configurationImportErrorsMessages.importFailedError,
    [ImportConfigurationError.ImportActionNotAllowed]: configurationImportErrorsMessages.importActionNotAllowedError,
    [ImportConfigurationError.ExportFailed]: configurationImportErrorsMessages.exportFailedError,
    [ImportConfigurationError.ExportActionNotAllowed]: configurationImportErrorsMessages.exportActionNotAllowedError,
    [ImportConfigurationError.ProviderNotFound]: configurationImportErrorsMessages.providerNotFoundError,
    [ImportConfigurationError.ConfigurationList]: configurationImportErrorsMessages.configurationListError,
};

export const DEFINITION_ERRORS_CODE_MESSAGE: Record<ImportConfigurationErrorCode, MessageDescriptor> = {
    [ImportConfigurationErrorCode.MapUniverseFailed]: configurationImportErrorsMessages.mapUniverseFailed,
    [ImportConfigurationErrorCode.MetaPropertyCreationFailed]: configurationImportErrorsMessages.metaPropertyCreationFailed,
    [ImportConfigurationErrorCode.MetaPropertyFeedSourcesUpdateFailed]: configurationImportErrorsMessages.metaPropertyFeedSourcesUpdateFailed,
    [ImportConfigurationErrorCode.MetaPropertyUpdateFailed]: configurationImportErrorsMessages.metaPropertyUpdateFailed,
    [ImportConfigurationErrorCode.ObjectDisplayConfigurationUpdateFailed]: configurationImportErrorsMessages.objectDisplayConfigurationUpdateFailed,
    [ImportConfigurationErrorCode.ObjectFeedSourcesUpdateFailed]: configurationImportErrorsMessages.objectFeedSourcesUpdateFailed,
    [ImportConfigurationErrorCode.ObjectTypeCreationFailed]: configurationImportErrorsMessages.objectTypeCreationFailed,
    [ImportConfigurationErrorCode.ObjectTypeUpdateFailed]: configurationImportErrorsMessages.objectTypeUpdateFailed,
    [ImportConfigurationErrorCode.OntologyNotFound]: configurationImportErrorsMessages.ontologyNotFound,
    [ImportConfigurationErrorCode.OntologyPublishFailed]: configurationImportErrorsMessages.ontologyPublishFailed,
    [ImportConfigurationErrorCode.OntologyStyleUpdateFailed]: configurationImportErrorsMessages.ontologyStyleUpdateFailed,
    [ImportConfigurationErrorCode.OntologyUpdateFailed]: configurationImportErrorsMessages.ontologyUpdateFailed,
    [ImportConfigurationErrorCode.RelationDisplayConfigurationUpdateFailed]: configurationImportErrorsMessages.relationDisplayConfigurationUpdateFailed,
    [ImportConfigurationErrorCode.RelationFeedSourcesUpdateFailed]: configurationImportErrorsMessages.relationFeedSourcesUpdateFailed,
    [ImportConfigurationErrorCode.RelationTypeCreationFailed]: configurationImportErrorsMessages.relationTypeCreationFailed,
    [ImportConfigurationErrorCode.RelationTypeUpdateFailed]: configurationImportErrorsMessages.relationTypeUpdateFailed,
    [ImportConfigurationErrorCode.RetentionPolicyPublicationFailed]: configurationImportErrorsMessages.retentionPolicyPublicationFailed,
    [ImportConfigurationErrorCode.RetentionPolicyUpdateFailed]: configurationImportErrorsMessages.retentionPolicyUpdateFailed,
};
