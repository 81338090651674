import { ReactNode, useCallback, useMemo } from 'react';

import { FormTab, FormTabs } from '../model';
import { ArgTab, ArgTabKey, ArgTabsSubLevel, useClassNames } from 'src/components/basic';
import { FormRenderContext } from '../render-factory';
import { CLASSNAME } from '../editable-form';

export interface FormTabsComponentProps {
    formTabs: FormTabs;
    childrenByTab: Record<string, ReactNode[]>;
    formRenderContext: FormRenderContext;
    wrapChildren?: (children: ReactNode, formTab: FormTab) => ReactNode;
}

export function FormTabsComponent(props: FormTabsComponentProps) {
    const {
        formTabs,
        childrenByTab,
        formRenderContext,
        wrapChildren,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const tabs = useMemo<ArgTab[]>(() => {
        const tabElements = formTabs.children.filter((child) => child.type === 'tab') as FormTab[];

        const tabs = tabElements.map((tabElement) => {
            const tab: ArgTab = {
                key: tabElement.id,
                title: tabElement.name,
                icon: tabElement.icon,
                children: () => (wrapChildren ? wrapChildren(childrenByTab[tabElement.id], tabElement) : childrenByTab[tabElement.id]),
            };

            return tab;
        });

        return tabs;
    }, [childrenByTab, formTabs.children, wrapChildren]);

    const handleTabChange = useCallback((tabKey: ArgTabKey | undefined) => {
        if (!tabKey) {
            return;
        }

        formRenderContext.onActiveTabKeyChange(formTabs, tabKey);
    }, [formRenderContext, formTabs]);

    const activeTabKey = formRenderContext.activeTabKeys[formTabs.id];

    return (
        <ArgTabsSubLevel
            tabs={tabs}
            activeTabKey={activeTabKey}
            bodyClassName={classNames('&-nodes')}
            onChange={handleTabChange}
        />
    );
}
