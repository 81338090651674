import { isObject } from 'lodash';

interface WindowLayout {
    top: number;
    left: number;
    width: number;
    height: number;
}

export function openInNewWindow(url?: string | URL | undefined, layout?: WindowLayout): void {
    const _layout = layout ?? {
        height: window.innerHeight * 0.9,
        top: window.innerHeight * 0.05,
        width: window.innerWidth * 0.9,
        left: window.innerWidth * 0.05,
    };

    let _url = url;
    if (url && isObject(url)) {
        _url = url.toString();
    }

    const features = `popup,width=${_layout.width},height=${_layout.height},left=${_layout.left},top=${_layout.top}`;

    // Using timeout to be sure that a new window is opened (not a tab)
    setTimeout(() => {
        return window.open(_url, '_blank', features);
    }, 500);
}

export function makeValidUrl(url: string): string {
    let _url: string = url;
    if (!url.startsWith('http')) {
        _url = `https://${url}`;
    }

    return _url;
}
