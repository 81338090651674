import { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { EmptyPane } from 'src/components/common/panes/empty-pane';
import {
    ArgButton,
    ArgInputSearch,
    ArgTable3,
    ArgTableColumn3,
    ClassValue,
    SelectionProvider,
    useClassNames,
} from '../../../components/basic';
import { Group } from '../../../model/user';
import { GroupsDataFilter, GroupsDataProvider } from '../providers/groups-data-provider';
import { useTableColumns } from './columns';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';

import './groups-table.less';

export interface GroupsTableProps {
    groups: GroupsDataProvider;
    className?: ClassValue;
    selectionProvider: SelectionProvider<Group>;
    handleAddGroup: () => void;
}

const messages = defineMessages({
    newGroup: {
        id: 'settings.groups.active.table.new',
        defaultMessage: 'New',
    },
    searchPlaceHolder: {
        id: 'settings.groups.active.table.search',
        defaultMessage: 'Search...',
    },
    noGroups: {
        id: 'settings.groups.noGroups',
        defaultMessage: 'No groups',
    },
    groupsEmpty: {
        id: 'settings.groups.groupsEmpty',
        defaultMessage: 'Create a new group.',
    },
});

export const GroupsTable = (props: GroupsTableProps) => {
    const {
        className,
        groups,
        selectionProvider,
        handleAddGroup,
    } = props;

    const classNames = useClassNames('groups-table');

    const canEditGroups = useHasPermission<SettingsPermissions>('admin.user.group.edition');
    const columns: ArgTableColumn3<Group>[] = useTableColumns();
    const [filter, setFilter] = useState<GroupsDataFilter>({});

    return (
        <>
            <div className={classNames('&-actions')}>
                <ArgInputSearch
                    onInputChange={(search) => {
                        setFilter((prevState) => ({
                            ...prevState,
                            search,
                        }));
                    }}
                    placeholder={messages.searchPlaceHolder}
                    className={classNames('&-actions-search')}
                />
                {canEditGroups && (
                    <ArgButton
                        size='medium'
                        type='primary'
                        icon='icon-plus'
                        label={messages.newGroup}
                        onClick={handleAddGroup}
                    />
                )}
            </div>

            {groups.getData().length === 0 ? (
                <EmptyPane className={classNames('&-body-content')}>
                    <div className={classNames('&-body-content-empty')}>
                        <div className={classNames('&-body-content-empty-title')}>
                            <FormattedMessage {...messages.noGroups} />
                        </div>
                        <div className={classNames('&-body-content-empty-details')}>
                            <FormattedMessage {...messages.groupsEmpty} />
                        </div>
                    </div>
                </EmptyPane>
            ) : (
                <ArgTable3<Group>
                    className={classNames('&', className)}
                    columns={columns}
                    initialItemsCount={groups.rowCount!}
                    headerHeight={45}
                    rowHeight={45}
                    dataProvider={groups}
                    filter={filter}
                    adjustColumns={true}
                    selectionProvider={canEditGroups ? selectionProvider : undefined}
                />
            )}
        </>
    );
};
